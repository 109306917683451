import cn from 'classnames';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { NavItem, WebLayoutVariants } from '@monorepo/types';

import { useIsMobile } from '../../../../lib/hooks';
import { LayoutGrid } from '../../../Misc';
import { NavBarMenuSection } from './NavBarMenuSection/NavBarMenuSection';
import { NavBarMobileMenu } from './NavBarMobileMenu/NavBarMobileMenu';
import { TopNavBar } from './TopNavBar/TopNavBar';

export type NavBarProps = {
	items: NavItem[];
	isLoggedIn?: boolean;
	variant: WebLayoutVariants;
	className?: string;
	isB2C?: boolean;
};

export const NavBar: FC<NavBarProps> = ({ items = [], isLoggedIn = false, variant, isB2C, className }) => {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const sortedItems = [...items].sort((a, b) => a.sequence - b.sequence);
	const { isSmallTablet } = useIsMobile();
	const { events } = useRouter();

	useEffect(() => {
		if (isMobileMenuOpen && !isSmallTablet) {
			setIsMobileMenuOpen(false);
		}
	}, [isMobileMenuOpen, isSmallTablet]);

	useEffect(() => {
		const handleRouteComplete = () => {
			setIsMobileMenuOpen(false);
		};

		events.on('routeChangeComplete', handleRouteComplete);

		return () => {
			events.off('routeChangeComplete', handleRouteComplete);
		};
	}, [events]);

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	return (
		<nav className={cn(className, 'bg-grayscale0 relative z-50 w-full py-8 md:py-24')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-20 max-md:justify-center'>
					<TopNavBar
						variant={variant}
						mobileMenuActive={isMobileMenuOpen}
						isLoggedIn={isLoggedIn}
						isB2C={isB2C}
						toggleMobileMenu={toggleMobileMenu}
					/>
					{variant === 'default' && <NavBarMenuSection items={sortedItems} />}
				</div>
			</LayoutGrid>
			{variant === 'default' && (
				<NavBarMobileMenu items={sortedItems} isMobileMenuOpen={isMobileMenuOpen} isB2C={isB2C} />
			)}
		</nav>
	);
};

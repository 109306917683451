import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';
import { GetReviewLinkResponse } from '@monorepo/types';

export const reviewLinkApi = createApi({
	reducerPath: 'reviewLinkApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/prospect`,
	}),
	tagTypes: ['ReviewLink'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		getReviewLink: builder.query<GetReviewLinkResponse, string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/review-link`,
					method: 'GET',
				};
			},
			providesTags: ['ReviewLink'],
		}),
	}),
});

export const { useGetReviewLinkQuery } = reviewLinkApi;

// These are used serverside in the sales funnel
export const { getReviewLink } = reviewLinkApi.endpoints;

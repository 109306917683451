import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getLastPartOfUrl } from '@monorepo/shared/lib/utils';

import { selectTheme } from '@common/store';
import { paths } from '@common/utils';
import { selectObjectType, useGetObjectsQuery } from '@consumer/store';
import { selectCartCalculationTypes, selectOptionalProducts, selectRiderProducts } from '@funnel/store';

export const useComposePageManager = () => {
	const {
		pathname,
		query: { slug, cartGuid },
		isReady,
		asPath,
	} = useRouter();

	const isConsumer = pathname.includes('/particulier');
	const [nextPage, setNextPage] = useState<string | boolean>();
	const { isLoading: isLoadingObjects } = useGetObjectsQuery(`${cartGuid}`, { skip: !cartGuid });
	const objectType = useSelector(selectObjectType);

	const { calculations: optionalProducts } = useSelector(selectOptionalProducts);
	const theme = useSelector(selectTheme);
	const { calculations: riderProducts } = useSelector(selectRiderProducts);
	const calculationType = useSelector(selectCartCalculationTypes);
	const hasBundles = calculationType?.bundles;

	useEffect(() => {
		if (isReady) {
			const slug = getLastPartOfUrl(asPath);

			if (isConsumer) {
				switch (slug) {
					case 'samenstellen':
						if (objectType === 'pet') {
							setNextPage(paths.consumerFunnel.compose.optionalProducts);
						} else {
							setNextPage(paths.consumerFunnel.compose.configure);
						}
						break;
					case 'configureren':
						setNextPage(paths.consumerFunnel.compose.optionalProducts);
						break;
					case 'optionele-producten':
						setNextPage(paths.consumerFunnel.compose.overview);
						break;
					case 'overview':
						setNextPage(paths.consumerFunnel.compose.addLicensePlate);
						break;
				}
			} else if (theme === 'anko' || hasBundles) {
				switch (slug) {
					case 'bundels':
						setNextPage(paths.funnel.compose.configure_bundle);
						break;
					case 'bundel-configureren':
						setNextPage(paths.funnel.compose[optionalProducts?.length > 0 ? 'optional_products' : 'overview']);
						break;
					case 'optionele-producten':
						setNextPage(paths.funnel.compose.overview);
						break;
					case 'overzicht':
						setNextPage(paths.funnel.preconditions.root);
						break;
				}
			} else {
				switch (slug) {
					case 'extra-producten':
						setNextPage(paths.funnel.preconditions.root);
						break;
					default:
						setNextPage(
							riderProducts?.length > 0 ? paths.funnel.compose.rider_products : paths.funnel.preconditions.root
						);
				}
			}
		}
	}, [
		optionalProducts,
		theme,
		slug,
		isReady,
		asPath,
		riderProducts?.length,
		hasBundles,
		isConsumer,
		isLoadingObjects,
		objectType,
	]);

	return {
		nextPage,
	};
};

import { rootApi } from '../../../../common/store/root/root.api';

export const checkoutApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		checkout: builder.mutation<
			{ existing_customer: boolean },
			{ cart_guid: string; effective_date: string; terms_agreement: boolean; iban?: string }
		>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/checkout`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['Checkout'],
		}),
	}),
	overrideExisting: true,
});

export const { useCheckoutMutation } = checkoutApi;

export const { checkout } = checkoutApi.endpoints;

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { IBAN_REGEX, PHONE_REGEX } from '../utils';

export const accountSchema = (t: TranslationType) =>
	z.object({
		email: z
			.string({ required_error: t('validation.required') })
			.email(t('validation.email'))
			.optional(),
		phone: z
			.string({ required_error: t('validation.required') })
			.regex(PHONE_REGEX, t('validation.phone'))
			.optional(),
		iban: z
			.string({ required_error: t('validation.required') })
			.regex(IBAN_REGEX, t('validation.format'))
			.optional(),
		terms_agreement: z.boolean({ required_error: t('validation.required') }).optional(),
	});

export type AccountSchema = z.infer<ReturnType<typeof accountSchema>>;

import { PortalClaim, PortalClaimRequest, PortalDocument } from '@monorepo/types';
import { IDeleteClaimMessageAttachment } from '@monorepo/types/src/portal/PortalAttachment.type';
import {
	IGetPortalClaimUpdateRequest,
	IGetPortalClaimUpdateResponse,
} from '@monorepo/types/src/portal/PortalClaimUpdate.type';
import {
	IGetPortalClaimMessagesRequest,
	IGetPortalClaimMessagesResponse,
	IPostPortalClaimMessagesRequest,
} from '@monorepo/types/src/portal/PortalMessage.type';

import { rootApi } from '../../../../common/store/root/root.api';

const claimApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		/**
		 * 🤕 Claims
		 */
		getClaims: builder.query<PortalClaim[], void>({
			query() {
				return {
					url: `/claims`,
					method: 'GET',
				};
			},
			providesTags: ['Claims'],
		}),
		getClaim: builder.query<PortalClaim, { claimGuid: string }>({
			query({ claimGuid }) {
				return {
					url: `/claims/${claimGuid}`,
					method: 'GET',
				};
			},
			providesTags: ['Claims'],
		}),
		addNewClaim: builder.mutation<{ claim_guid: string }, { claim: Partial<PortalClaimRequest> }>({
			query({ claim }) {
				return {
					url: `/organizations/%%organizationGuid%%/claims`,
					method: 'POST',
					body: {
						...claim,
					},
				};
			},
			invalidatesTags: ['Claims'],
		}),
		updateExistingClaim: builder.mutation<PortalClaim, Omit<PortalClaim, 'status'>>({
			query(payload) {
				return {
					url: `/organizations/%%organizationGuid%%/claims/${payload.guid}`,
					method: 'PUT',
					body: payload,
				};
			},
			invalidatesTags: ['Claim'],
		}),
		getClaimMessages: builder.query<IGetPortalClaimMessagesResponse, IGetPortalClaimMessagesRequest>({
			query({ claimGuid }) {
				return {
					url: `/claims/${claimGuid}/handling/messages`,
					method: 'GET',
				};
			},
			providesTags: ['Messages'],
		}),
		getClaimUpdates: builder.query<IGetPortalClaimUpdateResponse, IGetPortalClaimUpdateRequest>({
			query({ claimGuid }) {
				return {
					url: `/claims/${claimGuid}/handling/updates`,
					method: 'GET',
				};
			},
			providesTags: ['Claims'],
		}),
		addClaimMessage: builder.mutation<void, IPostPortalClaimMessagesRequest>({
			query({ claimGuid, ...payload }) {
				return {
					url: `/claims/${claimGuid}/handling/messages`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: ['Messages'],
		}),
		deleteClaimMessageAttachment: builder.mutation<void, IDeleteClaimMessageAttachment>({
			query(payload) {
				return {
					url: `/claims/${payload.claim_guid}/handling/messages/${payload.message_guid}/attachments/${payload.attachment_guid}`,
					method: 'DELETE',
					body: payload,
				};
			},
			invalidatesTags: ['Messages'],
		}),
		downloadClaimAttachment: builder.mutation<
			PortalDocument,
			{ claimGuid: string; messageGuid: string; attachmentGuid: string }
		>({
			query({ claimGuid, messageGuid, attachmentGuid }) {
				return {
					url: `/claims/${claimGuid}/handling/messages/${messageGuid}/attachments/${attachmentGuid}`,
					method: 'POST',
				};
			},
			invalidatesTags: ['Invoice', 'Claims'],
		}),
	}),
	overrideExisting: true,
});

export const {
	useAddNewClaimMutation,
	useDownloadClaimAttachmentMutation,
	useGetClaimQuery,
	useGetClaimsQuery,
	useGetClaimMessagesQuery,
	useAddClaimMessageMutation,
	useGetClaimUpdatesQuery,
	useDeleteClaimMessageAttachmentMutation,
} = claimApi;

export const { addNewClaim, downloadClaimAttachment, getClaim, getClaims, updateExistingClaim, getClaimUpdates } =
	claimApi.endpoints;

import cn from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';

import { ButtonIcon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { BaseButton } from '../BaseButton/BaseButton';

export type ButtonSemanticMiniVariant = 'error' | 'info' | 'success' | 'warning';

type SemanticMiniButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	label: string;
	variant?: ButtonSemanticMiniVariant;
	isLoading?: boolean;
	iconLeft?: string;
	iconRight?: string;
};

const buttonClassNames: Record<ButtonSemanticMiniVariant, string> = {
	info: 'bg-info300 hover:bg-info200 active:bg-info400',
	error: 'bg-error300 hover:bg-error200 active:bg-error400',
	success: 'bg-success300 hover:bg-success200 active:bg-success400',
	warning: 'bg-warning300 hover:bg-warning200 active:bg-warning400',
};

export const SemanticMiniButton: FC<SemanticMiniButtonProps> = ({
	label,
	className,
	disabled,
	variant = 'info',
	isLoading,
	iconLeft,
	iconRight,
	...props
}) => {
	return (
		<BaseButton
			className={cn(
				className,
				buttonClassNames[variant],
				'rounded-6 disabled:bg-grayscale200 min-h-32 whitespace-nowrap p-8'
			)}
			disabled={disabled || isLoading}
			{...props}>
			{iconLeft && (
				<ButtonIcon icon={iconLeft} size='md' iconPosition='left' iconColor='grayscale0' isLoading={isLoading} />
			)}

			<Text variant='body-xs' as='span' color='grayscale0' weight='semibold'>
				{label}
			</Text>

			{(iconRight || (!iconLeft && isLoading)) && (
				<ButtonIcon icon={iconRight} size='md' iconPosition='right' iconColor='grayscale0' isLoading={isLoading} />
			)}
		</BaseButton>
	);
};

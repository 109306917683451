import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, ISectionCtaCard } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { SectionCtaCards } from '@marketing/components';

export const handleSectionCtaCards = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const ctaCardsEle = elements.filter(({ identifier }) => identifier === 'cta-card') as CmsElement[];

	const cards: ISectionCtaCard[] = ctaCardsEle.map((ctaCard) => {
		const titleSubTextEle = ctaCard.child_elements.find(
			({ identifier }) => identifier === 'title-subtext'
		) as CmsElement;
		const title = getProperty('title', titleSubTextEle.custom_fields),
			subtext = getProperty('subtext', titleSubTextEle.custom_fields);
		const [link] = ctaCard.links;

		return {
			title: `${title}`,
			description: `${subtext}`,
			link,
		};
	});

	return (props) => <SectionCtaCards {...props} cards={cards} />;
};

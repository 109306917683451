import { IHeroWithVideo } from '@monorepo/types';

export const mockHeroWithVideo: IHeroWithVideo = {
	title: 'Schade gemaakt? **Samen lossen we het op.**',
	subtext: 'Subtext',
	thumbnail: {
		path: '/images/marketing/video-thumbnail.png',
		alt: 'hero-desktop-image',
	},
	video: {
		path: '/images/marketing/video.mp4',
		alt: 'hero-desktop-image',
	},
};

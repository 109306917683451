import cn from 'classnames';
import { motion } from 'framer-motion';
import parse, { HTMLReactParserOptions, Text as ParserText } from 'html-react-parser';
import Image from 'next/image';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { IHeroWithImage } from '@monorepo/types';

export type HeroWithImageProps = IHeroWithImage & {
	className?: string;
};

export const HeroWithImage: FC<HeroWithImageProps> = ({ title, image, className }) => {
	const drawnLineOption: HTMLReactParserOptions = {
		replace: (domNode) => {
			if (domNode instanceof ParserText) {
				const parts = domNode.data.split('*');

				return (
					<Text variant='display-3' weight='semibold' color='primaryMain'>
						{parts[0]}{' '}
						<div className='text-info300 relative inline-block'>
							{parts[1]}
							<motion.div
								initial={{ width: 0, y: '50%' }}
								animate={{ width: '100%', y: '50%' }}
								transition={{ duration: 0.5, delay: 0.75, ease: 'easeOut' }}
								className='absolute inset-0'>
								<Image
									sizes={generateSizesString()}
									src='/images/marketing/annotation-highlight.png'
									alt='annotation highlight'
									className='object-contain'
									fill
								/>
							</motion.div>
						</div>{' '}
						{parts[2]}
					</Text>
				);
			}

			return domNode;
		},
	};

	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing' className='overflow-hidden'>
				<div className='md:gap-124 col-start-2 grid grid-cols-2 gap-48 sm:gap-64'>
					<div className='col-span-full pt-48 md:col-span-1 md:py-96'>{parse(title, drawnLineOption)}</div>
					<div className='col-span-full max-md:h-[360px] md:col-span-1 md:mt-80'>
						<div className='relative h-full w-full'>
							<Image src={image.path} alt={image.alt} className='object-cover' fill />
							<Image
								sizes={generateSizesString()}
								src='/images/marketing/drawn-star-green.png'
								alt='Drawn heart vector'
								className='absolute left-0 top-0 z-10 -translate-x-1/2 -translate-y-1/2 object-contain max-md:hidden'
								width={90}
								height={77}
							/>

							<Image
								sizes={generateSizesString()}
								src='/images/marketing/drawn-heart-green.png'
								alt='Drawn heart vector'
								className='absolute right-0 top-0 z-10 -translate-y-1/2 translate-x-1/2 object-contain max-md:hidden'
								width={81}
								height={109}
							/>
						</div>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

import { GetCountriesResponse } from '@monorepo/types/src/funnel/countries.type';

import { dataRootApi } from '@funnel/store/root/root';

const countriesApi = dataRootApi.injectEndpoints({
	endpoints: (builder) => ({
		getCountries: builder.query<GetCountriesResponse, void>({
			query() {
				return {
					url: `/countries`,
					method: 'GET',
				};
			},
			providesTags: ['Countries'],
		}),
	}),
	overrideExisting: true,
});

export const { useGetCountriesQuery } = countriesApi;

export const { getCountries } = countriesApi.endpoints;

import { FieldValues } from 'react-hook-form';

import { FormBaseInput, FormBaseInputDefaultProps, FormBaseInputProps } from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormTextInputProps<TFormValues extends FieldValues> = {
	hint?: string;
	hideError?: boolean;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<FormBaseInputProps, 'name' | 'errors' | 'type'>;

export const FormSearchInput = <TFormValues extends Record<string, unknown>>({
	name,
	register,
	rules,
	errors,
	hint,
	hideError,
	...props
}: FormTextInputProps<TFormValues>): JSX.Element => {
	return (
		<FormHelper errors={errors} hint={hint} hideError={hideError}>
			{({ isError }) => (
				<FormBaseInput
					type='search'
					isError={isError}
					name={name}
					{...props}
					{...(register && register(name, rules))}
				/>
			)}
		</FormHelper>
	);
};

import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ChatBubble, ChatIcon } from '@support/components';
import { selectOptionsIsOpen } from '@support/store';

type Props = {
	alignment?: 'start' | 'end';
	className?: string;
};

export const Chat: FC<Props> = ({ alignment = 'start', className }) => {
	const optionsOpen = useSelector(selectOptionsIsOpen);

	return (
		<div
			className={cn(
				className,
				alignment === 'start' && 'items-start',
				alignment === 'end' && 'items-end',
				'z-50 flex max-w-[300px] flex-col gap-12'
			)}>
			<AnimatePresence>{optionsOpen && <ChatBubble alignment={alignment} />}</AnimatePresence>
			<ChatIcon />
		</div>
	);
};

import { ClassificationForCart, ThemeNames } from '@monorepo/types';

const pathMap: Record<string, string> = {
	onderhoudnl: './classifications_bouw.json',
	anko: './classifications_beauty.json',
	default: './classifications_advies-en-consultancy.json',
};

export const getMockClassifications = async (salesChannel: ThemeNames): Promise<ClassificationForCart[]> => {
	const filePath = pathMap[salesChannel] || pathMap['default'];
	const { default: data } = await import(`${filePath}`);
	return data;
};

import { FC } from 'react';

import { TrustpilotRating } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';

export type TestimonialCardProps = {
	testimonial: string;
	name: string;
	rating: number;
	// company: string;
	// image: {
	// 	source: string;
	// 	alt: string;
	// };
};

export const TestimonialCard: FC<TestimonialCardProps> = ({ testimonial, name, rating }) => {
	return (
		<div className='max-sm:flex-0 inline-flex w-full flex-col space-y-24 sm:min-w-[375px]'>
			<div className='shadow-1 bg-grayscale0 rounded-br-24 rounded-tr-24 rounded-tl-24 flex flex-1 flex-col gap-y-24 p-32'>
				<TrustpilotRating rating={rating} />
				<Text variant='body-l' color='grayscale400' weight='regular'>
					{testimonial}
				</Text>
			</div>
			<div className='flex flex-row space-x-20'>
				{/* <div className='relative h-56 w-56 overflow-hidden rounded-full'>
					<Image sizes={generateSizesString()} src={image.source} alt={image.alt} fill className='object-cover' />
				</div> */}
				<div className='flex flex-col space-y-4'>
					<Text variant='body-m' color='primaryMain' weight='semibold'>
						{name}
					</Text>
					{/* <Text variant='body-m' color='grayscale400' weight='regular'>
						{company}
					</Text> */}
				</div>
			</div>
		</div>
	);
};

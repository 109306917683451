import type { ComponentPropsWithoutRef, FC, ReactElement } from 'react';

import { PageTitleAndIntro } from '@monorepo/components/TextComponents';

type PageHeaderProps = {
	title: string | ReactElement;
	description?: string | ReactElement;
} & Omit<ComponentPropsWithoutRef<'header'>, 'title'>;

export const PageHeader: FC<PageHeaderProps> = ({ title, description, ...rest }) => (
	<header {...rest}>
		<PageTitleAndIntro title={title} intro={description} />
	</header>
);

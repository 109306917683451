import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { isMocking } from '@monorepo/shared/lib/utils';

import { setIdToken } from '@portal/store';
import { useFirebase } from '../FirebaseProvider/FirebaseProvider';

type Props = {
	children: ReactNode;
};

export const AuthContext = createContext<Auth | null>(null);
export const useFirebaseAuth = () => {
	return useContext(AuthContext);
};

export const FirebaseAuthProvider: FC<Props> = ({ children }) => {
	const firebaseApp = useFirebase();
	const dispatch = useDispatch();
	const [auth, setAuth] = useState<Auth | null>(null);

	useEffect(() => {
		if (firebaseApp) {
			const instance = getAuth(firebaseApp);
			setAuth(instance);
		}
	}, [firebaseApp]);

	const devServer = useCallback(async () => {
		if (auth) {
			connectAuthEmulator(auth, 'http://localhost:9099');
		}
	}, [auth]);

	useEffect(() => {
		if (isMocking && auth) {
			devServer();
		}
	}, [devServer, auth]);

	useEffect(() => {
		const unsubscribe = (auth as Auth)?.onIdTokenChanged(async (user) => {
			if (!user) {
				dispatch(setIdToken(null));
			} else {
				const token = await user.getIdToken();
				dispatch(setIdToken(token));
			}
		});
		return () => unsubscribe && unsubscribe();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth]);

	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

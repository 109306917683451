import { createElement, ElementType, ReactElement, useEffect, useId, useRef } from 'react';

import { ReactTag, TabProps } from '../Tab';
import { useActions, useData } from '../Tab.store';

const DEFAULT_TAB_PANEL_TAG = 'div' as const;

type TabPanelProps<Tag extends ReactTag, Slot> = { tabIndex?: number } & TabProps<Tag, Slot>;

export const TabPanel = <PanelElement extends ElementType = typeof DEFAULT_TAB_PANEL_TAG>(
	props: TabPanelProps<PanelElement, { selected: boolean; selectedIndex: number }>
) => {
	const internalId = useId();
	const { selectedIndex, panels, tabs } = useData('Tab.Panel');

	const { as: ComponentFromProps, tabIndex = 0, ...rest } = props;

	const id = `maia-ui-tab-panel-${internalId}`;

	const actions = useActions('Tab.Panel');
	const panelRef = useRef<HTMLElement | null>(null);

	const myIndex = panels.indexOf(panelRef);
	const selected = myIndex === selectedIndex;

	const slot = { selected, selectedIndex };
	const Component = ComponentFromProps || DEFAULT_TAB_PANEL_TAG;

	const resolvedChildren = (typeof props.children === 'function' ? props.children(slot) : props.children) as
		| ReactElement
		| ReactElement[];

	const controlledProps = {
		ref: panelRef,
		id,
		role: 'tabpanel',
		'aria-labelledby': tabs[myIndex]?.current?.id,
		tabIndex: selected ? tabIndex : -1,
	};

	useEffect(() => {
		actions.registerPanel(panelRef);

		return () => actions.unregisterPanel(panelRef);
	}, [actions, panelRef]);

	if (!selected) {
		return createElement(
			'span',
			{ 'aria-hidden': true, style: { display: 'none' }, ...controlledProps },
			resolvedChildren
		);
	}
	return createElement(Component, { ...rest, ...controlledProps }, resolvedChildren);
};

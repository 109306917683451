import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { type Cart } from '@monorepo/types';

import { AppState } from '@common/store';
import { consumerCartApi } from '@consumer/store';
import { cartApi } from '@funnel/store';

export type SelectedCartState = {
	details: Cart;
};

// Initial state
const initialState: SelectedCartState = {
	details: {} as Cart,
};

// Actual Slice
export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		// Action to set the cart
		setCart(state, action: PayloadAction<Cart>) {
			state.details = {
				...state.details,
				...action.payload,
			};
		},
		updateExpiredCart(state, action: PayloadAction<boolean>) {
			state.details = {
				...state.details,
				is_expired: action.payload,
			};
		},
	},
	extraReducers: (builder) => {
		// B2B Cart Query
		builder.addMatcher(cartApi.endpoints.getCart.matchFulfilled, (state, action) => {
			state.details = {
				...state.details,
				...action.payload,
			};
		});
		// B2C Cart Query
		builder.addMatcher(consumerCartApi.endpoints.getConsumerCart.matchFulfilled, (state, action) => {
			state.details = {
				...state.details,
				...action.payload,
			};
		});
	},
});

export const { setCart, updateExpiredCart } = cartSlice.actions;

export const selectBillingUnit = (state: AppState) => state.cart.details.billing_unit;
export const selectCartState = (state: AppState) => state.cart.details;

export const selectIsCartConverted = (state: AppState) => state.cart.details.converted;
export const selectCartIsExpired = (state: AppState) => state.cart.details.is_expired;
export const selectCartCalculationTypes = (state: AppState) => state.cart.details.calculation_types;

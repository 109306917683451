import { FC } from 'react';

import { BlogSectionHero } from '@monorepo/components/Misc';
import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleArticleSiteSectionHero = <P extends object>({ component, rootContext }: HandlerProps): FC<P> => {
	const title = getProperty('title', component.custom_fields) as string;

	return () => (
		<BlogSectionHero
			title={title}
			subTitle={`${component.markdown}`}
			author={`${rootContext?.author}`}
			lastUpdate={`${rootContext?.publishDate}`}
			avatarImageSrc={'/images/landing/makelaars/testimonial-1.png'}
		/>
	);
};

import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { CaseBlock, CaseBlockProps } from '@monorepo/components/TextComponents';
import { getProperty } from '@monorepo/shared/lib/cms';

import { mdComponents } from '@cms/componentHandlers/handleMarkDown/handleMarkDown';
import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleCaseBlock = ({ component }: HandlerProps): FC<CaseBlockProps> => {
	const title = getProperty('title', component.custom_fields);

	return (props) => (
		<CaseBlock {...props} title={`${title}`}>
			<ReactMarkdown components={mdComponents}>{`${component.markdown}`}</ReactMarkdown>
		</CaseBlock>
	);
};

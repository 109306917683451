import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { PHONE_REGEX } from '../utils';

export const sendPremiumSchema = (t: TranslationType, includeInitials: boolean = false) => {
	if (includeInitials) {
		return z.object({
			first_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
			last_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
			email: z.string({ required_error: t('validation.required') }).email(t('validation.email')),
			phone: z.string({ required_error: t('validation.required') }).regex(PHONE_REGEX, t('validation.phone')),
			initials: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		});
	} else {
		return z.object({
			first_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
			last_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
			email: z.string({ required_error: t('validation.required') }).email(t('validation.email')),
			phone: z.string({ required_error: t('validation.required') }).regex(PHONE_REGEX, t('validation.phone')),
			initials: z.string({ required_error: t('validation.required') }).optional(),
		});
	}
};

export type SendPremiumSchema = z.infer<ReturnType<typeof sendPremiumSchema>>;

import cn from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';

import { ThemeTypographyWeight } from '@monorepo/types';

import { ButtonIcon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { BaseButton } from '../BaseButton/BaseButton';

type ButtonVariant = 'standard' | 'critical' | 'error';
type ButtonSize = 'xs' | 'sm' | 'lg' | 'inherit';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
	label?: string;
	variant?: ButtonVariant;
	size?: ButtonSize;
	isLoading?: boolean;
	iconLeft?: string;
	fontWeight?: ThemeTypographyWeight;
	iconRight?: string;
};

const buttonClassNames: Record<ButtonVariant, string> = {
	standard: `text-info300
	hover:enabled:text-info400
	active:enabled:text-info500
	disabled:text-grayscale300`,
	critical: `text-error300 md:text-info300
	hover:enabled:text-error300
	active:enabled:text-error500
	disabled:text-grayscale300`,
	error: `text-error300 
	hover:enabled:text-error300
	active:enabled:text-error500
	disabled:text-grayscale300`,
};

const buttonSizes: Record<ButtonSize, string> = {
	xs: 'h-24 p-4',
	sm: 'h-32 space-x-4 p-4',
	lg: 'h-40 space-x-4 p-8',
	inherit: '',
};

export const TextButton: FC<Props> = ({
	label,
	className = '',
	disabled,
	fontWeight = 'semibold',
	variant = 'standard',
	size = 'lg',
	isLoading,
	iconLeft,
	iconRight,
	...props
}) => {
	return (
		<BaseButton
			disabled={disabled || isLoading}
			className={cn(className, buttonClassNames[variant], buttonSizes[size], 'group')}
			title={props?.title ?? label}
			{...props}>
			{iconLeft && (
				<ButtonIcon
					icon={iconLeft}
					className={cn(iconLeft === 'arrow-left' && !disabled && 'transition-transform group-hover:-translate-x-4')}
					size={size === 'xs' || size === 'inherit' ? 'sm' : size}
					iconPosition='left'
					isLoading={isLoading}
					mode='dark'
				/>
			)}

			{label && (
				<Text
					variant={size === 'lg' ? 'body-m' : size === 'sm' ? 'body-s' : 'body-xs'}
					as='span'
					color='inherit'
					weight={fontWeight}>
					{label}
				</Text>
			)}

			{iconRight && (
				<ButtonIcon
					className={cn(iconLeft === 'arrow-right' && 'transition-transform group-hover:translate-x-4')}
					size={size === 'xs' || size === 'inherit' ? 'sm' : size}
					icon={iconRight}
					iconPosition='right'
					isLoading={isLoading}
					mode='dark'
				/>
			)}
		</BaseButton>
	);
};

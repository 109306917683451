import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ClaimSchema } from '@monorepo/shared/lib/schemas';
import { CreateHistoricalClaimRequest } from '@monorepo/types';

import { hideModal, showModal } from '@common/store';
import {
	useCreateHistoricalClaimMutation,
	useDeleteHistoricalClaimMutation,
	useGetClaimableProductsQuery,
	useGetHistoricalClaimsQuery,
	useUpdateHistoricalClaimMutation,
} from '@funnel/store';

export const useClaims = (cart_guid: string) => {
	const [selectedClaim, setSelectedClaim] = useState<ClaimSchema>();
	const dispatch = useDispatch();

	const [saveHistoricalClaimRequest, saveClaimResponse] = useCreateHistoricalClaimMutation();
	const [updateHistoricalClaimRequest, updateClaimResponse] = useUpdateHistoricalClaimMutation();
	const [deleteHistoricalClaimRequest, deleteClaimResponse] = useDeleteHistoricalClaimMutation();

	const { data: claims = [] } = useGetHistoricalClaimsQuery(cart_guid);
	const { data: claimableProducts = [] } = useGetClaimableProductsQuery(cart_guid);

	const isLoading = saveClaimResponse.isLoading || updateClaimResponse.isLoading || deleteClaimResponse.isLoading;

	/**
	 * Open the modal to add a new claim
	 */
	const handleAdd = () => {
		setSelectedClaim(undefined);
		dispatch(showModal('add-or-edit-claim-modal'));
	};

	/**
	 * Open the edit modal that is prefilled with the clicked claim fields
	 */
	const handleEdit = (claim: ClaimSchema) => {
		setSelectedClaim(claim);
		dispatch(showModal('add-or-edit-claim-modal'));
	};

	/**
	 * Cancels the changes made to a claim
	 */
	const handleCancelEdit = () => {
		setSelectedClaim(undefined);
		dispatch(hideModal('add-or-edit-claim-modal'));
	};

	/**
	 * Open the prompt to delete the claim
	 */
	const handleDeleteRequest = (claim: ClaimSchema) => {
		setSelectedClaim(claim);
		dispatch(showModal('delete-claim-prompt'));
	};

	/**
	 * Close the prompt to delete the claim
	 */
	const handleCancelDelete = () => {
		setSelectedClaim(undefined);
		dispatch(hideModal('delete-claim-prompt'));
	};

	/**
	 * Deletes a claim
	 */
	const handleDelete = async () => {
		await deleteHistoricalClaimRequest({ cart_guid, guid: `${selectedClaim?.guid}` });
		dispatch(hideModal('delete-claim-prompt'));
	};

	/**
	 * Saves a new claim or updates an existing claim
	 */
	const handleSubmit = async (claim: ClaimSchema) => {
		if (claim.guid) {
			await updateHistoricalClaimRequest({
				cart_guid,
				guid: claim.guid,
				product_guid: `${
					claimableProducts?.find((transferableProduct) => transferableProduct.guid === claim.product_guid)?.guid
				}`,
				year: claim.year,
				amount: claim.amount,
				explanation: claim.explanation,
			});
			dispatch(hideModal('add-or-edit-claim-modal'));
		} else {
			const historicalClaims: CreateHistoricalClaimRequest = {
				cart_guid,
				historical_claims: [
					{ product_guid: claim.product_guid, year: claim.year, amount: claim.amount, explanation: claim.explanation },
				],
			};

			await saveHistoricalClaimRequest(historicalClaims).unwrap();

			dispatch(hideModal('add-or-edit-claim-modal'));
		}
	};

	return {
		claims,
		isLoading,
		selectedClaim,
		handleAdd,
		handleEdit,
		handleCancelEdit,
		handleDelete,
		handleDeleteRequest,
		handleCancelDelete,
		handleSubmit,
	};
};

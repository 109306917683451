import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Form, SearchSuggestionCompany, StandardButton } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { useAnalytics, useIsMobile } from '@monorepo/shared/lib/hooks';
import { searchCompanySchemaSplash, SearchCompanySchemaSplash } from '@monorepo/shared/lib/schemas';
import { CmsLink } from '@monorepo/types';

import { selectTheme } from '@common/store';
import { paths } from '@common/utils';
import { useCompanySearch } from '@funnel/hooks';

type Props = {
	label?: string; // the title of the search box
	link?: CmsLink;
	className?: string;
};

export const SearchBox: FC<Props> = ({ label, link, className = '' }) => {
	const { push } = useRouter();
	const { t } = useTranslation(['common']);
	const { isMobile } = useIsMobile();
	const [cursor, setCursor] = useState<number>(-1);
	const { trackEvent } = useAnalytics();
	const { setSearchQuery, companies, searchIsFetching } = useCompanySearch({ num: 5, type: '&type=hoofdvestiging' });

	const searchBoxForm = useForm<SearchCompanySchemaSplash>({
		mode: 'onBlur',
		resolver: zodResolver(searchCompanySchemaSplash()),
		defaultValues: {
			query: '',
		},
	});
	const searchInput = searchBoxForm.watch('query');
	const theme = useSelector(selectTheme);

	const passedUrl = link?.href ? new URL(link.href, process.env.NEXT_PUBLIC_WEBAPP_URL) : undefined;
	const workfieldGuid = passedUrl ? passedUrl.searchParams.get('workfieldGuid') : undefined;
	const productGuid = passedUrl ? passedUrl.searchParams.get('productGuid') : undefined;

	const handleKeyDown = (e: KeyboardEvent) => {
		// Move up in the list of autocomplete items. Go to last item when top is reached
		if (e.key === 'ArrowUp') {
			setCursor((prevState) => {
				if (prevState - 1 < 0) {
					return companies.length - 1;
				}

				return prevState - 1;
			});
		} else if (e.key === 'ArrowDown') {
			// Move down in the list of autocomplete items. Go to first item when top is reached
			setCursor((prevState) => {
				if (prevState + 1 === companies.length) {
					return 0;
				}
				return prevState + 1;
			});
		} else if (e.key === 'Enter') {
			// When we have an active cursor, invoke the onClick
			// Otherwise the form is just submitted
			if (cursor !== -1) {
				e.preventDefault();
				handleClick(companies[cursor].kvkNummer);
			}
		}
	};
	const handleClick = async (dossierNumber: string) => {
		await push({
			pathname: paths.funnel.company.check,
			query: { dossierNumber, ...(workfieldGuid && { workfieldGuid }), ...(productGuid && { productGuid }) },
		}).then(() => {
			trackEvent('start_funnel', { sales_channel: theme });
		});
	};

	const handleQuery = async ({ query }: SearchCompanySchemaSplash) => {
		await push({
			pathname: passedUrl && passedUrl.pathname ? passedUrl.pathname : paths.funnel.company.search,
			query: { ...(query && { query }), ...(workfieldGuid && { workfieldGuid }), ...(productGuid && { productGuid }) },
		}).then(() => {
			trackEvent('start_funnel', { sales_channel: theme });
		});
	};

	useEffect(() => {
		setSearchQuery(searchInput ?? '');
	}, [setSearchQuery, searchInput]);

	return (
		<div
			className={cn(
				className,
				'rounded-12 bg-grayscale600 flex flex-col p-16 sm:space-y-20 sm:p-24 md:p-20 xl:space-y-24 xl:p-40'
			)}>
			<Text variant='display-6' color='grayscale0' weight='semibold' className='max-sm:hidden'>
				{label || t('components.searchBox.label')}
			</Text>

			<FormProvider {...searchBoxForm}>
				<Form.Context<SearchCompanySchemaSplash>>
					{({ register, handleSubmit }) => {
						return (
							<Form id='searchbox' onSubmit={handleSubmit(handleQuery)}>
								<Form.Group className='flex w-full flex-col items-center space-y-16 sm:space-y-20 md:flex-row md:space-x-20 md:space-y-0 xl:space-x-24'>
									<div className='relative w-full md:basis-2/3'>
										<Form.SearchInput
											className={cn(companies && companies.length > 0 && 'rounded-b-none border-b-0', 'h-48')}
											name='query'
											startIcon='search'
											endIcon={searchIsFetching ? 'loader' : undefined}
											autoComplete='off'
											onKeyDown={handleKeyDown}
											placeholder={t(
												isMobile ? 'components.searchBox.placeholder' : 'components.searchBox.placeholderLarge'
											)}
											register={register}
											data-testid='searchbox-input'
										/>

										{companies && companies.length > 0 && (
											<div className='border-primaryMain rounded-b-6 absolute w-full overflow-hidden border'>
												{companies.map(({ kvkNummer, naam, adres }, index) => {
													let location = '';
													if (adres.binnenlandsAdres?.straatnaam) {
														location += adres.binnenlandsAdres?.straatnaam;
													}
													if (adres.binnenlandsAdres?.plaats) {
														location += `, ${adres.binnenlandsAdres?.plaats}`;
													}

													return (
														<SearchSuggestionCompany
															cursorActive={cursor === index}
															onMouseOver={() => setCursor(index)}
															onClick={() => handleClick(kvkNummer)}
															key={`search-suggestion-${kvkNummer}`}
															company={naam}
															location={location}
															data-testid={`searchbox-result-${naam}`}
														/>
													);
												})}
											</div>
										)}
									</div>

									<StandardButton
										type='submit'
										title={link?.title || t('components.searchBox.cta')}
										className='w-full md:basis-1/3'
										label={link?.content || link?.title || t('components.searchBox.cta')}
										variant='cta'
										iconRight='angle-right'
									/>
								</Form.Group>
							</Form>
						);
					}}
				</Form.Context>
			</FormProvider>
		</div>
	);
};

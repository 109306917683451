import parse, { HTMLReactParserOptions, Text } from 'html-react-parser';
import reactStringReplace from 'react-string-replace';

import { QuestionToolTip } from '@monorepo/types';

import { slugify } from '../helpers/helpers';

export const parseStatement = (textToBeParsed: string, tooltips?: QuestionToolTip[]) => {
	const options: HTMLReactParserOptions = {
		replace: (domNode) => {
			if (domNode instanceof Text && tooltips) {
				for (const { string } of tooltips) {
					return (
						<>
							{reactStringReplace(domNode.data, string, (_, i) => (
								<span
									key={`span-${i}`}
									className='text-primaryMain pointer-events-auto cursor-pointer font-bold'
									data-tooltip-id={`tooltip-${slugify(string)}`}>
									{string}
								</span>
							))}
						</>
					);
				}
			}
		},
	};

	return parse(textToBeParsed, options);
};

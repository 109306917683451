import { FC } from 'react';

import { mockNicheOverview, mockServiceHeader } from '@monorepo/mocks/marketing';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { HeaderSection, SupportFaq } from '@marketing/components';

export const handleKnowledgeBaseSection = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const content = elements.find((element) => element.identifier === 'content') as CmsElement;

	return () => (
		<>
			{/* // TODO: Remove mock once test is updated with correct content */}
			<HeaderSection {...mockServiceHeader} title={`${content?.markdown}`} variant='simple' />
			{/* // TODO: Remove mock once test is updated with correct content */}
			<SupportFaq {...mockNicheOverview} />
		</>
	);
};

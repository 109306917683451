import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ProgressBar } from '@monorepo/components/BaseComponents';
import { ActionButton, StandardButton } from '@monorepo/components/DataEntry';

import { useSendPremium } from '@funnel/hooks';
import { SendPremiumModal } from '@funnel/modals';
import { selectProgressState } from '@funnel/store';
import { Chat } from '@support/components';

type SidedrawerProps = {
	cartGuid: string;
	isOpen: boolean;
	funnelProgressSteps: string[];
	handleProceedLater?: () => void;
	onClose: () => void;
};

export const Sidedrawer: FC<SidedrawerProps> = ({
	cartGuid,
	isOpen,
	funnelProgressSteps,
	handleProceedLater,
	onClose,
}) => {
	const { t } = useTranslation(['common']);
	const activeStep = useSelector(selectProgressState);
	const sendPremium = useSendPremium(cartGuid);

	return (
		<aside className='max-w-md:hidden pointer-events-none fixed inset-0 z-50'>
			<div
				className={cn(
					'p-24-safe bg-grayscale0 border-grayscale100 pointer-events-auto fixed right-0 top-0 z-50 flex h-full w-9/12 flex-col border-l duration-300 ease-in-out sm:w-3/5',
					isOpen ? 'shadow-2 translate-x-0' : 'shadow-0 translate-x-full'
				)}>
				<ActionButton icon='times' className='absolute right-32 top-32' onClick={onClose} />

				<div className='mt-56 flex-1 space-y-24'>
					<ProgressBar steps={funnelProgressSteps} activeStep={activeStep} />
					{handleProceedLater && (
						<StandardButton
							iconLeft='envelope-share'
							label={t('common.buttonProceedLater')}
							onClick={handleProceedLater}
						/>
					)}
				</div>

				<div className='mt-auto w-full space-y-12'>
					<Chat />
				</div>
			</div>
			<div
				className={cn(
					'bg-overlayColor fixed inset-0 -z-10 transition-all duration-300 ease-in-out',
					isOpen ? 'pointer-events-auto cursor-pointer opacity-100' : 'pointer-events-none opacity-0'
				)}
				onClick={onClose}
			/>

			<SendPremiumModal
				parent='sidedrawer'
				email={sendPremium.email}
				isSend={sendPremium.isSend}
				isLoading={sendPremium.isLoading}
				onSubmit={sendPremium.onSend}
			/>
		</aside>
	);
};

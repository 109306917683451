import { FC } from 'react';

import { mockNicheCollaborateSection } from '@monorepo/mocks/marketing';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, INicheCard } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { NicheCollaborateSection } from '@marketing/components';

export const handleSectionWorkfieldActivities = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubTextElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement,
		workfieldActivities = elements.filter(({ identifier }) => identifier === 'workfield-activities');

	const title = getProperty('title', titleSubTextElement.custom_fields),
		subtext = getProperty('subtext', titleSubTextElement.custom_fields);

	const niches: INicheCard[] = workfieldActivities.map((workfieldActivity, index) => {
		const title = getProperty('title', workfieldActivity.custom_fields),
			icon = getProperty('icon_name', workfieldActivity.custom_fields),
			activities = workfieldActivity.custom_fields.filter(({ key }) => key === 'activity').map(({ value }) => value),
			link = workfieldActivity.links[0]?.href
				? workfieldActivity.links[0]
				: mockNicheCollaborateSection.niches[index].link,
			image = workfieldActivity.media[0]?.path
				? workfieldActivity.media[0]
				: mockNicheCollaborateSection.niches[index].image;

		return {
			image,
			title: `${title}`,
			icon: `${icon}`,
			bulletpoints: activities,
			link,
		};
	});

	return (props) => (
		<NicheCollaborateSection
			{...props}
			className='bg-primary50 py-48 md:pb-96 md:pt-64'
			title={`${title}`}
			subtitle={`${subtext}`}
			niches={niches}
		/>
	);
};

import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IHeaderSection } from '@monorepo/types';

import { ServiceSearchForm } from '@marketing/components/HeaderSection/ServiceSearchForm/ServiceSearchForm';

type HeaderProps = IHeaderSection & {
	className?: string;
};

export const HeaderSection: FC<HeaderProps> = ({ title, variant = 'simple', className }) => {
	return (
		<header className={cn(className, 'bg-primary50 py-104')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col items-center space-y-40 md:space-y-48'>
					<Text as='h1' variant='display-2' weight='semibold' color='primaryMain' className='text-center'>
						{title}
					</Text>

					{variant === 'search' && <ServiceSearchForm />}
				</div>
			</LayoutGrid>
		</header>
	);
};

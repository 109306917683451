import type { UpsellResponse } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

const portalUpsellApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getUpsellProducts: builder.query<UpsellResponse, void>({
			query() {
				return {
					url: `/upsellproducts`,
					method: 'GET',
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetUpsellProductsQuery } = portalUpsellApi;

import classNames from 'classnames';
import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

import { Icon, IconButton } from '../../../Misc';

type FormBaseInputWrapperProps = {
	isDisabled?: boolean;
	isDirty?: boolean;
	isError?: boolean;
	isEmpty?: boolean;
	fieldSize?: 'sm' | 'md' | 'lg';
	showValidationIcon?: boolean;
	startIcon?: string;
	endIcon?: string;
	endIconMoreSpacing?: boolean; // Add more spacing between browser added icons and our icons
	onEndIconClick?: () => void;
	numberControls?: boolean;
	onNumberControlsClick?: (type: 'increment' | 'decrement') => void;
};
export const FormBaseInputWrapper: FC<PropsWithChildren<FormBaseInputWrapperProps>> = ({
	isDisabled,
	isDirty,
	isError,
	isEmpty,
	showValidationIcon = true,
	startIcon,
	endIcon,
	fieldSize = 'md',
	endIconMoreSpacing = false,
	onEndIconClick,
	numberControls,
	onNumberControlsClick,
	children,
}) => {
	return (
		<div className={classNames('relative flex-1', isDisabled && 'cursor-not-allowed')}>
			{children}

			{startIcon && (
				<div className={classNames('absolute left-0 top-1/2 -translate-y-1/2', fieldSize === 'lg' ? 'ml-20' : 'ml-12')}>
					<Icon
						name={startIcon}
						color={isEmpty || isDisabled ? 'grayscale300' : 'grayscale500'}
						className='transition-colors'
					/>
				</div>
			)}

			{!endIcon && numberControls && onNumberControlsClick && (
				<div className='absolute right-0 top-1/2 flex -translate-y-1/2 flex-row gap-24 p-20'>
					<IconButton
						title='Decrement'
						name='decrement'
						disabled={isDisabled}
						iconName='minus'
						color={isDisabled ? 'grayscale300' : 'info300'}
						onClick={() => onNumberControlsClick('decrement')}
					/>
					<div className='bg-grayscale200 w-[1px]' />
					<IconButton
						title='Increment'
						name='nncrement'
						disabled={isDisabled}
						iconName='plus'
						color={isDisabled ? 'grayscale300' : 'info300'}
						onClick={() => onNumberControlsClick('increment')}
					/>
				</div>
			)}

			{(!isDisabled || endIcon) && !numberControls && (
				<div
					className={cn(
						endIconMoreSpacing ? 'mr-48' : 'mr-12',
						'absolute right-0 top-1/2 flex -translate-y-1/2 items-center space-x-4'
					)}>
					{!isDisabled && ((!isEmpty && isDirty) || isError) && showValidationIcon && (
						<Icon name={isError ? 'times' : 'check'} color={isError ? 'error300' : 'success300'} size='md' />
					)}

					{endIcon && (
						<button
							type='button'
							className={cn(
								endIcon === 'loader' && 'animate-spin',
								'text-primaryMain cursor-pointer leading-none outline-0 focus:outline-1 disabled:cursor-not-allowed'
							)}
							disabled={isDisabled}
							onClick={onEndIconClick}>
							<Icon
								name={endIcon === 'loader' ? 'spinner' : endIcon}
								color={isDisabled ? 'grayscale300' : 'info300'}
								className='transition-colors'
							/>
						</button>
					)}
				</div>
			)}
		</div>
	);
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';

export const rootConsumerApi = createApi({
	reducerPath: 'rootConsumerApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/b2c/prospect`,
	}),
	tagTypes: [
		'Cart',
		'Stakeholders',
		'PremiumQuestions',
		'Compositions',
		'Contact',
		'ProductStructure',
		'ProductQuestions',
		'CurrentInsurances',
		'AcceptanceQuestion',
		'AcceptanceState',
		'Objects',
		'HistoricalClaims',
	],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: () => ({}),
});

import cn from 'classnames';
import { useInView } from 'framer-motion';
import parse from 'html-react-parser';
import dynamic from 'next/dynamic';
import { FC, useEffect, useRef, useState } from 'react';

import { Text } from '@monorepo/components/TextComponents';
import { SwagItem } from '@monorepo/types';

export type SwagItemProps = SwagItem & {
	className?: string;
};
const AnimateNumber = dynamic(() => import('@monorepo/components/Misc').then((mod) => mod.AnimateNumber), {
	ssr: false,
});

export const SingleSwag: FC<SwagItemProps> = ({ sequence, suffix, prefix, value, content, className }) => {
	const [animateValue, setAnimateValue] = useState<number>(0.0);
	const ref = useRef<HTMLLIElement>(null);
	const isVisible = useInView(ref, { margin: '0px 0px -100px 0px' });

	useEffect(() => {
		if (isVisible) {
			setTimeout(() => {
				setAnimateValue(value);
			}, 150);
		}
	}, [isVisible, value]);

	return (
		<li
			role='listitem'
			ref={ref}
			key={`swag-section-item-${sequence}`}
			className={cn(className, 'md:bg-40 bg-24 max-w-[140px] bg-[right_2rem_top] bg-no-repeat md:bg-right-top')}>
			<Text
				variant='display-2'
				color='primaryMain'
				weight='semibold'
				className='flex flex-row items-center justify-center'>
				{prefix && <span className='pr-[1px]'>{prefix}</span>}
				<AnimateNumber className='inline-block pr-[-1px]' value={animateValue} />
				{suffix && <span className='pl-[1px]'>{suffix}</span>}
			</Text>

			<Text as='p' variant='body-l' weight='regular' color='primaryMain' className='text-center [&>span]:font-medium'>
				{typeof content === 'string' ? parse(content) : content}
			</Text>
		</li>
	);
};

import cn from 'classnames';
import { FC } from 'react';

import { Icon } from '../Icon/Icon';

type Props = {
	name: string;
	className?: string;
};

export const ProductIcon: FC<Props> = ({ name, className = '' }) => {
	return (
		<div className={cn(className, 'bg-primary50 rounded-12 flex h-[56px] w-[56px] items-center justify-center p-8')}>
			<Icon name={name} size='xl' className='leading-none' />
		</div>
	);
};

import { useCallback } from 'react';

import {
	Bundle,
	CalculationProduct,
	CalculationProductOption,
	CalculationProductOptionSpecWithGuid,
	ProductFormControl,
	ProductFormControlsTypes,
} from '@monorepo/types';

import { isSameSpec } from '../../utils';

export function useProductFormControls() {
	const getProductFormControlsFunc = useCallback((product: CalculationProduct, bundles: Bundle[]) => {
		return getProductFormControls(product, bundles);
	}, []);

	const hasControls = useCallback((product: CalculationProduct, bundles: Bundle[]) => {
		return getProductFormControls(product, bundles).some(
			({ specs, risk_address, precondition }) =>
				(specs && specs.length > 1) || typeof risk_address !== 'undefined' || typeof precondition !== 'undefined'
		);
	}, []);

	/**
	 * Check if a product is in a bundle
	 */
	function isProductInBundle(product: CalculationProduct, bundles: Bundle[]): boolean {
		return bundles.flatMap(({ products }) => products).some(({ code }) => product.code === code);
	}

	function getProductFormControls(product: CalculationProduct, bundles: Bundle[]): ProductFormControl[] {
		const productFormControls: ProductFormControl[] = [];

		addRiskAddressesControl(product, productFormControls);
		addPreconditionsControls(product, productFormControls);

		if (product.options.length === 1) {
			productFormControls.push(createSingleOptionControl(product.options[0]));
			return productFormControls.sort((a, b) => getControlWeight(a.controlType) - getControlWeight(b.controlType));
		}

		const flattenedSpecs = flattenSpecs(product, bundles);
		const radioSpecsGroupedByOption = groupBy(flattenedSpecs, ({ is_insured_amount }) => !!is_insured_amount);
		const toggleSpecsGroupedByOption = groupBy(
			flattenedSpecs,
			(spec) => spec.reference === 'additional_cyber_system-failures'
		);
		const toggleButtonSpecs = groupBy(flattenedSpecs, ({ is_deductible }) => !!is_deductible);

		addFormControl(product, productFormControls, radioSpecsGroupedByOption, 'radio', 'text');
		addFormControl(product, productFormControls, toggleSpecsGroupedByOption, 'toggle', 'text');

		// For product bundles we shouldn't show deductibles as these will be shown in another form
		if (!isProductInBundle(product, bundles)) {
			addToggleButtonFormControl(product, productFormControls, toggleButtonSpecs);
		}

		return productFormControls.sort((a, b) => getControlWeight(a.controlType) - getControlWeight(b.controlType));
	}

	/**
	 * Add a risk address control if there are any risk addresses.
	 */
	function addRiskAddressesControl(product: CalculationProduct, controls: ProductFormControl[]) {
		if (product.risk_addresses && product.risk_addresses.length > 0) {
			for (const risk_address of product.risk_addresses) {
				controls.push({
					controlType: 'address',
					risk_address,
				});
			}
		}
	}

	/**
	 * Add preconditions controls based on product's preconditions.
	 */
	function addPreconditionsControls(product: CalculationProduct, controls: ProductFormControl[]) {
		if (product.preconditions && product.preconditions.length > 0) {
			for (const precondition of product.preconditions) {
				controls.push({
					controlType:
						precondition.reference === 'gross-wage' || precondition.reference === 'rebuild-value'
							? 'valutaInput'
							: precondition.reference === 'employees' ||
								  precondition.reference === 'owners' ||
								  precondition.reference === 'AANTTMW'
								? 'numberInput'
								: 'text',
					precondition,
				});
			}
		}
	}

	/**
	 * Create a form control for a single option.
	 */
	function createSingleOptionControl(option: CalculationProductOption): ProductFormControl {
		return {
			controlType: 'text',
			specs: [option.specs.map((spec) => ({ ...spec, option_guid: option.guid }))],
		};
	}

	/**
	 * Flatten the specs for a given product.
	 * When dealing with bundles, we should handle all products likewise.
	 * If not, Bedrijfsaansprakelijkheid is a special case which is default part of a product
	 * and therefore shouldn't be handled as a separate option.
	 */
	function flattenSpecs(product: CalculationProduct, bundles: Bundle[]) {
		const filteredOptions = isProductInBundle(product, bundles)
			? product.options
			: product.options.filter(({ name }) => name !== 'Bedrijfsaansprakelijkheid');

		return filteredOptions.map(({ guid, specs }) => specs.map((spec) => ({ ...spec, option_guid: guid }))).flat();
	}

	/**
	 * Group a list of specs based on a given predicate.
	 */
	function groupBy(
		specs: CalculationProductOptionSpecWithGuid[],
		predicate: (spec: CalculationProductOptionSpecWithGuid) => boolean
	) {
		const grouped: { [key: string]: CalculationProductOptionSpecWithGuid[] } = {};
		const serializedSpecsSet = new Set<string>();

		for (const spec of specs) {
			if (predicate(spec)) {
				const serializedSpec = serializeSpec(spec);
				if (!serializedSpecsSet.has(serializedSpec)) {
					serializedSpecsSet.add(serializedSpec);
					grouped[spec.option_guid] = grouped[spec.option_guid] || [];
					grouped[spec.option_guid].push(spec);
				}
			}
		}

		return grouped;
	}

	/**
	 * Add a formcontrol to the list of form controls if there are any specs.
	 */
	function addFormControl(
		product: CalculationProduct,
		productFormControls: ProductFormControl[],
		groupedSpecs: { [key: string]: CalculationProductOptionSpecWithGuid[] },
		controlType: ProductFormControlsTypes,
		fallbackControlType: ProductFormControlsTypes
	) {
		const filteredSpecs = Object.values(groupedSpecs).filter((specs) => filterRedundantSpecs(product, specs));
		const control = createFormControl(filteredSpecs, controlType, fallbackControlType);

		if (control && control.specs && control.specs.length > 1) {
			productFormControls.push(control);
		}
	}

	/**
	 * Create a formcontrol to the list of form controls if there are any specs.
	 */
	function createFormControl(
		specs: CalculationProductOptionSpecWithGuid[][],
		controlType: ProductFormControlsTypes,
		fallbackControlType: ProductFormControlsTypes
	): ProductFormControl {
		return {
			controlType: specs.length === 1 ? fallbackControlType : controlType,
			specs,
		};
	}

	/**
	 * Add a toggle button formcontrol to the list of form controls if there are any specs.
	 */
	function addToggleButtonFormControl(
		product: CalculationProduct,
		productFormControls: ProductFormControl[],
		groupedSpecs: { [key: string]: CalculationProductOptionSpecWithGuid[] }
	) {
		const filteredSpecs = Object.values(groupedSpecs).filter((specs) => filterRedundantSpecs(product, specs));

		const control = createToggleButtonControl(filteredSpecs);
		if (control && control?.specs?.length) {
			productFormControls.push(control);
		}
	}

	/**
	 * Create a togglebuttoncontrol and deduplicate deductibles
	 */
	function createToggleButtonControl(specs: CalculationProductOptionSpecWithGuid[][]): ProductFormControl {
		const uniqueDeductibles = filterUniqueDeductibles(specs);
		const defaultDeductibles = uniqueDeductibles.filter(({ deductible_type }) => deductible_type === 'case');
		const uniqueDeductiblesSet = new Set(defaultDeductibles.map(({ value }) => value));
		return {
			controlType: uniqueDeductiblesSet.size > 1 ? 'toggleButton' : 'text',
			specs: uniqueDeductibles.length === 1 ? [uniqueDeductibles] : [defaultDeductibles],
		};
	}

	/**
	 * Filter unique deductibles
	 */
	function filterUniqueDeductibles(
		specs: CalculationProductOptionSpecWithGuid[][]
	): CalculationProductOptionSpecWithGuid[] {
		const addedToggleButtonSpecsSet = new Set<string>();
		const uniqueSpecs: CalculationProductOptionSpecWithGuid[] = [];

		specs.flat().forEach((spec) => {
			if (spec.is_deductible) {
				const serializedSpec = JSON.stringify({
					reference: spec.reference,
					deductible_type: spec.deductible_type,
					value: spec.value,
				});

				if (!addedToggleButtonSpecsSet.has(serializedSpec)) {
					addedToggleButtonSpecsSet.add(serializedSpec);
					uniqueSpecs.push(spec);
				}
			}
		});

		return uniqueSpecs;
	}

	/**
	 * Serialize a spec to a lookup string
	 */
	function serializeSpec(spec: CalculationProductOptionSpecWithGuid) {
		return JSON.stringify({
			reference: spec.reference,
			insured_amount_type: spec.insured_amount_type,
			deductible_type: spec.deductible_type,
			value: spec.value,
			guid: spec.option_guid,
		});
	}

	function getControlWeight(controlType: ProductFormControlsTypes): number {
		switch (controlType) {
			case 'radio':
				return 1;
			case 'toggleButton':
				return 2;
			case 'toggle':
				return 3;
			case 'address':
				return 4;
			case 'valutaInput':
				return 5;
			case 'text':
				return 6;
			default:
				return 7; // for unexpected control types, place them at the end
		}
	}

	/**
	 * Filters out the specs which shouldnt be rendered and presented in the client
	 */
	const filterRedundantSpecs = (
		product: CalculationProduct,
		specs: CalculationProductOptionSpecWithGuid[]
	): boolean => {
		const activeOption = product.options.find(({ is_active }) => is_active);
		const productGuidsThatCanBeShown = product.options
			.filter((option) => {
				const remainingOptionSpecs = option.specs.filter(
					(optionSpec) => !specs.some((spec) => isSameSpec(spec, optionSpec))
				);
				return remainingOptionSpecs.every((remainingOptionSpec) =>
					activeOption?.specs.some((_spec) => isSameSpec(_spec, remainingOptionSpec))
				);
			})
			.map((matchingOption) => matchingOption.guid);

		return productGuidsThatCanBeShown.includes(specs[0].option_guid);
	};

	return {
		getControls: getProductFormControlsFunc,
		hasControls: hasControls,
	};
}

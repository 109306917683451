import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '@monorepo/components/TextComponents';

import { DeveloperMenu } from '@common/components';
import { selectIsDeveloperMenuOpened, toggleDeveloperMenu } from '@common/store';

export const DeveloperMenuTrigger = () => {
	const dispatch = useDispatch();
	const isDeveloperMenuOpen = useSelector(selectIsDeveloperMenuOpened);

	useEffect(() => {
		const handleKeyPress = (e: KeyboardEvent) => {
			if (e.key === '~') {
				dispatch(toggleDeveloperMenu());
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<>
			<motion.button
				className='bg-primaryMain shadow-1 rounded-12 fixed bottom-12 right-12 z-[99999] p-12'
				onClick={() => dispatch(toggleDeveloperMenu())}
				whileHover={{ scale: 1.08 }}
				whileTap={{ scale: 0.92 }}
				tabIndex={-1}>
				<Text variant='body-m' color='grayscale0' weight='semibold' className='flex items-center space-x-8'>
					<Text
						as='div'
						variant='body-m'
						color='grayscale0'
						weight='semibold'
						className='border-grayscale0 rounded-4 flex h-[24px] w-[24px] items-center justify-center border'>
						~
					</Text>
					<span>Developer menu</span>
				</Text>
			</motion.button>
			{isDeveloperMenuOpen && <DeveloperMenu />}
		</>
	);
};

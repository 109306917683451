import { FC } from 'react';

import { BaseCard } from '@monorepo/components/DataDisplay';
import { Skeleton } from '@monorepo/components/Misc';

export const InsuranceOverviewCardSkeleton: FC = () => {
	return (
		<BaseCard
			as='div'
			className='h-full min-h-[135px] p-20 max-md:space-y-16 md:min-h-[300px] md:p-32'
			enableEffects={false}>
			<div className='flex w-full flex-col md:flex-1 md:space-y-12'>
				<div className='flex w-full flex-row items-center max-md:gap-16 md:justify-between'>
					<div className='flex h-[48px] items-center justify-center md:h-[60px]'>
						<Skeleton variant='circular' className='h-32 w-32' />
					</div>

					<Skeleton variant='circular' containerClassName='max-md:hidden' className='h-[38px] w-[130px]' />

					<Skeleton variant='text' size='display-5' containerClassName='md:hidden w-full' />
				</div>

				<Skeleton variant='text' size='display-5' containerClassName='max-md:hidden w-1/2' className='' />

				<div className='flex flex-1 flex-col space-y-4'>
					<Skeleton variant='text' size='body-l' containerClassName='max-md:hidden w-10/12' className='' />
					<Skeleton variant='text' size='body-l' containerClassName='max-md:hidden w-8/12' className='' />
				</div>

				<Skeleton variant='circular' containerClassName='md:hidden' className='h-[30px] w-[120px]' />

				<Skeleton variant='text' size='body-m' containerClassName='max-md:hidden w-[200px]' className='' />
			</div>
		</BaseCard>
	);
};

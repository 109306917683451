import { IFeatureImageHighlightSection } from '@monorepo/types';

export const mockFeatureImageHighlightSection: IFeatureImageHighlightSection = {
	title: 'Waarom heb ik als ZZP Installateur een zakelijke verzekering nodig?',
	description:
		'Een zakelijke verzekering als installatiebedrijf biedt je de juiste bescherming tegen een breed scala aan onverwachte gebeurtenissen en ongevallen. Van schade aan materie, persoonlijk letsel, brand, diefstal, claims van klanten en nog veel meer. Onverwachte fouten kunnen flinke kosten met zich meebrengen, een ongelukje zit immers in een klein hoekje.',
	image: {
		path: '/images/marketing/cta-search-background.png',
		alt: 'alt',
	},
};

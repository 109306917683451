import { IUsp, IUspSection } from '@monorepo/types';

export const mockUsp: IUsp = {
	icon: 'edit-alt',
	title: 'Verzekeringen voor jou op maat',
	description:
		'Māia heeft ervaring in verschillende werkvelden met samenwerkingen met brancheverenigingen in veel sectoren. Voor ieder werkveld hebben wij speciale verzekeringen ontwikkeld die aansluiten op het risico dat jij loopt.',
};

export const mockUspSection: IUspSection = {
	title: 'Dit doet Māia voor jou en jouw bedrijf',
	subtitle: 'We zetten ons vol in om verzekeren simpel, begrijpelijk en makkelijk te maken',
	usps: [mockUsp, mockUsp, mockUsp, mockUsp, mockUsp, mockUsp],
};

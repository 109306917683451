import { faker } from '@faker-js/faker/locale/nl';

import { Address } from '@monorepo/types';

export function mockGetAddress(withAppendix = false): Address {
	return {
		street: faker.location.street(),
		house_number: 100,
		house_number_appendix: withAppendix ? faker.location.secondaryAddress() : undefined,
		postal_code: faker.location.zipCode(),
		city: faker.location.city(),
		country: faker.location.country(),
	};
}

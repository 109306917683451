import { AnswerPreconditionsRequest, AnswerPreconditionsResponse } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const preconditionsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		answerPreconditions: builder.mutation<AnswerPreconditionsResponse, AnswerPreconditionsRequest>({
			query({ cart_guid, questions, product_guid }) {
				return {
					url: `/carts/${cart_guid}/preconditions`,
					method: 'PUT',
					body: { questions, product_guid },
				};
			},
			invalidatesTags: ['Calculations', 'Bundles'],
		}),
	}),
});

export const { useAnswerPreconditionsMutation } = preconditionsApi;

export const { answerPreconditions } = preconditionsApi.endpoints;

import cn from 'classnames';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { Tab } from '@monorepo/components/Headless/Tab/Tab';
import { Icon, LayoutGrid } from '@monorepo/components/Misc';
import { SvgCircle } from '@monorepo/components/Misc/Doodles/Circle/Circle';
import { Text } from '@monorepo/components/TextComponents';
import { useIsMobile } from '@monorepo/shared/lib/hooks';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { IPhoneFeatureCarouselSection } from '@monorepo/types';

export type PhoneFeatureCarouselSectionProps = IPhoneFeatureCarouselSection & {
	className?: string;
};
const CAROUSEL_SLIDE_DURATION = 5000;

const panelTransition = {
	hidden: {},
	show: {
		transition: {
			staggerChildren: 0.15,
		},
	},
};

const panelContentTransition = {
	hidden: {
		opacity: 0,
		y: 12,
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			ease: [0.04, 0.62, 0.23, 0.98],
		},
	},
};

const phoneContentTransition = {
	hidden: {
		opacity: 0,
		y: 12,
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.3,
			ease: [0.04, 0.62, 0.23, 0.98],
			delay: 0.3,
		},
	},
};

const mobilePhoneContentTransition = {
	hidden: {
		opacity: 0,
		x: '100%',
	},
	exit: {
		opacity: 0,
		x: '-100%',
	},
	show: {
		opacity: 1,
		x: 0,
		transition: {
			duration: 0.3,
			ease: [0.04, 0.62, 0.23, 0.98],
		},
	},
};
const mobileIndicatorTransition = {
	hidden: {
		width: 12,
		height: 12,
	},
	show: {
		width: 48,
		height: 12,
		opacity: 1,
		transition: {
			duration: 0.3,
			ease: [0.04, 0.62, 0.23, 0.98],
		},
	},
};

const mobileIndicatorProgressTransition = {
	hidden: {
		width: 0,
	},
	show: {
		width: '100%',
		transition: {
			duration: CAROUSEL_SLIDE_DURATION / 1000,
			ease: 'linear',
		},
	},
};

const highlight = (props: ReactMarkdownProps) => (
	<Text as='strong' variant='display-3' color='info300' weight='semibold' className='block'>
		{props.children}
	</Text>
);

const mdComponents: Components = {
	strong: highlight,
};

export const PhoneFeatureCarouselSection: FC<PhoneFeatureCarouselSectionProps> = ({
	title,
	subtitle,
	features,
	className,
}) => {
	const { isSmallTablet } = useIsMobile();
	const [tabIndex, setTabIndex] = useState<number>(0);

	const mobileCarouselTimer = useRef<number>(0);

	useEffect(() => {
		if (isSmallTablet) {
			setTabIndex(0);
			clearInterval(mobileCarouselTimer.current);
			mobileCarouselTimer.current = window.setInterval(() => {
				// window.setInterval to ensure browser context
				setTabIndex((previousStep) => (previousStep < features.length - 1 ? previousStep + 1 : 0));
			}, CAROUSEL_SLIDE_DURATION);
		} else {
			clearInterval(mobileCarouselTimer.current);
		}

		return () => clearInterval(mobileCarouselTimer.current);
	}, [isSmallTablet, features.length]);

	return (
		<section className={cn('flex flex-col gap-y-64', className)}>
			<LayoutGrid layout='marketing' className='max-md:hidden'>
				<div className='col-start-2'>
					<Text variant='display-3' weight='semibold' color='primaryMain' className='[&>b]:text-info300 text-center'>
						<ReactMarkdown components={mdComponents}>{title}</ReactMarkdown>
					</Text>
				</div>
			</LayoutGrid>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2'>
					<Tab
						as='div'
						selectedIndex={tabIndex}
						className='grid grid-cols-3 gap-40 md:gap-96'
						onChange={(_) => setTabIndex(_)}
						vertical>
						<div className='col-span-full flex flex-col justify-center space-y-40 max-md:text-center md:col-span-1'>
							<Text variant='display-4' weight='semibold' color='primaryMain'>
								{subtitle}
							</Text>
							<Tab.Menu as='ul' className='flex flex-col space-y-8 max-md:hidden'>
								{({ selectedIndex }) => (
									<>
										{features.map(({ selector }, key) => (
											<Tab.MenuItem
												as='li'
												key={`phone-feature-carousel-selector-${key}`}
												className={classNames(
													'bg-grayscale0 rounded-12 flex cursor-pointer flex-row items-center space-x-12 border px-20 py-16 transition-all',
													selectedIndex === key && 'shadow-1 border-info300 z-10',
													selectedIndex !== key && 'border-transparent'
												)}>
												<div className='relative w-fit'>
													<SvgCircle
														theme='maia'
														className='text-secondaryMain w-h-8 absolute bottom-8 left-0 h-8 md:bottom-4 md:h-8 md:w-8'
													/>
													<Icon name={selector.icon} size='lg' color='info300' className='relative z-10' />
													<SvgCircle
														theme='maia'
														className='text-secondaryMain absolute -right-4 top-12 h-16 w-16 md:right-0 md:top-8 md:h-8 md:w-8'
													/>
												</div>
												<Text variant='body-s' weight='medium' color='primaryMain'>
													{selector.title}
												</Text>
											</Tab.MenuItem>
										))}
									</>
								)}
							</Tab.Menu>
						</div>
						<Tab.Panels className='col-span-full md:col-span-2'>
							{({ selectedIndex }) => (
								<div className='grid grid-cols-2 gap-32 md:gap-96'>
									<div className='col-span-full flex items-center justify-center md:col-span-1'>
										<motion.div className='shadow-1 bg-grayscale0 relative h-[545px] w-[270px] overflow-hidden rounded-[43px]'>
											<Image
												sizes={generateSizesString()}
												src='/images/marketing/iphone-mock.png'
												alt='iPhone mock'
												className='pointer-events-none z-10 object-cover'
												fill
											/>

											<div className='absolute inset-0 m-4 max-md:flex max-md:flex-row max-md:overflow-hidden'>
												<AnimatePresence initial={false}>
													{features.map(({ image }, key) => (
														<motion.img
															key={`phone-feature-carousel-image-${key}`}
															variants={isSmallTablet ? mobilePhoneContentTransition : phoneContentTransition}
															initial='hidden'
															animate={
																(!isSmallTablet && selectedIndex === key) || (isSmallTablet && tabIndex === key)
																	? 'show'
																	: 'hidden'
															}
															exit={isSmallTablet ? 'exit' : 'hidden'}
															className='absolute h-full w-full'
															src={image.path}
															alt={image.alt}
														/>
													))}
												</AnimatePresence>
											</div>
										</motion.div>
									</div>
									<div className='col-span-full flex items-center max-md:flex max-md:flex-col max-md:justify-center max-md:gap-24 md:col-span-1'>
										{features.map(({ details, selector }, key) => (
											<Tab.Panel key={`phone-feature-carousel-panel-${key}`}>
												<motion.div
													variants={panelTransition}
													initial='hidden'
													animate='show'
													exit='hidden'
													className='flex flex-col gap-y-12 max-md:items-center max-md:text-center md:gap-y-24'>
													<motion.div variants={panelContentTransition}>
														<div className='relative w-fit'>
															<SvgCircle
																theme='maia'
																className='text-secondaryMain w-h-8 absolute bottom-8 left-0 h-8'
															/>
															<Icon name={selector.icon} size='xxl' color='info300' className='relative z-10' />
															<SvgCircle
																theme='maia'
																className='text-secondaryMain absolute -right-4 top-12 h-16 w-16'
															/>
														</div>
													</motion.div>
													<motion.div variants={panelContentTransition}>
														<Text variant='display-5' weight='semibold' color='primaryMain'>
															{details.title}
														</Text>
													</motion.div>
													<motion.div variants={panelContentTransition}>
														<Text variant='body-l' weight='regular' color='grayscale400'>
															{details.description}
														</Text>
													</motion.div>
												</motion.div>
											</Tab.Panel>
										))}
										<div className='flex flex-row space-x-8 md:hidden'>
											<AnimatePresence>
												{features.map((_, key) => (
													<motion.div
														initial='hidden'
														animate={selectedIndex === key ? 'show' : 'hidden'}
														variants={mobileIndicatorTransition}
														className='bg-grayscale100 relative overflow-hidden rounded-full'
														key={`phone-feature-carousel-indicator-${key}`}>
														<motion.div
															initial='hidden'
															animate={selectedIndex === key ? 'show' : 'hidden'}
															variants={mobileIndicatorProgressTransition}
															className='bg-primaryMain absolute bottom-0 left-0 top-0 h-full w-0 rounded-full'
															key={`phone-feature-carousel-indicator-progress-${key}`}
														/>
													</motion.div>
												))}
											</AnimatePresence>
										</div>
									</div>
								</div>
							)}
						</Tab.Panels>
					</Tab>
				</div>
			</LayoutGrid>
		</section>
	);
};

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { MagicLinkRequest, MagicLinkResponse } from '@monorepo/types';

import { rootAuthApi } from '../../root/root.api';

const magicLinkApi = rootAuthApi.injectEndpoints({
	endpoints: (builder) => ({
		magicLink: builder.mutation<MagicLinkResponse, MagicLinkRequest>({
			query(payload) {
				return {
					url: `/magic-link`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: ['Auth'],
			transformErrorResponse: (error: FetchBaseQueryError) => {
				/* istanbul ignore next */
				return {
					status: error.status,
					data: error.data,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useMagicLinkMutation } = magicLinkApi;

export const { magicLink } = magicLinkApi.endpoints;

import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

import { Tab } from '@monorepo/components/Headless/Tab/Tab';
import { LayoutGrid } from '@monorepo/components/Misc';

import { useGetFaqCategoriesQuery, useGetFaqsQuery } from '@cms/store';
import { BlurbCard } from '@marketing/components/BlurbCard/BlurbCard';
import { CustomerServiceSkeletonCard } from '../CustomerServiceSkeletonCard/CustomerServiceSkeletonCard';
import { panelContentTransition, panelTransition, TabbedOverview } from '../TabbedOverview';

type Props = {
	className?: string;
};

export const TabbedFaqSection: FC<Props> = ({ className }) => {
	const { data: categories = [], isLoading } = useGetFaqCategoriesQuery({});
	const { data: items = [], isLoading: faqsIsLoading } = useGetFaqsQuery({ type: 'FAQ' });

	return (
		<section
			className={cn(
				className,
				'before:bg-primary50 relative pb-64 before:absolute before:left-0 before:right-0 before:top-0 before:-z-10 before:h-[170px] md:pb-48'
			)}>
			<LayoutGrid as='div' layout='marketing' gutter='48'>
				<TabbedOverview
					menuLinks={categories.map(({ icon_name, description }) => ({ icon: icon_name, description }))}
					isLoading={isLoading || faqsIsLoading}
					skeletonProps={{
						skeleton: <CustomerServiceSkeletonCard />,
					}}>
					{({ isAnimatedOnce }) => (
						<>
							{categories.map(({ guid }) => {
								const faqItems = items.filter(({ category_guid }) => category_guid === guid);

								return (
									<Tab.Panel
										as={motion.div}
										variants={panelTransition}
										initial={isAnimatedOnce ? 'hidden' : 'show'}
										animate='show'
										className='grid grid-cols-1 gap-24 md:grid-cols-2 md:gap-48'
										key={`support-faq-section-tab-panel-${guid}`}>
										{({ selected }) => (
											<AnimatePresence>
												{selected &&
													faqItems.map((card, key2) => (
														<motion.div
															key={`support-faq-section-tab-panel-${guid}-content-${key2}`}
															variants={panelContentTransition}
															className='col-span-full md:col-span-1'>
															<BlurbCard
																className='pointer-events-auto'
																enableEffects={false}
																title={card.title}
																description={card.content}
																icon={card.icon_name}
															/>
														</motion.div>
													))}
											</AnimatePresence>
										)}
									</Tab.Panel>
								);
							})}
						</>
					)}
				</TabbedOverview>
			</LayoutGrid>
		</section>
	);
};

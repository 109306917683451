import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';
import { FC } from 'react';

import { Text } from '../../../../TextComponents';
import { TrustpilotStar } from '../../../Illustrations/TrustpilotStar/TrustpilotStar';
import { mapRatingToTrustpilotText, trustpilotCompanyUrl } from './../../../../../lib/utils';

type TrustpilotRatingProps = {
	rating: number;
	includeReviewsOn?: boolean;
	totalReviews?: number;
};

const ratingBackgroundColorMap: Record<number, string> = {
	1: 'bg-trustpilot-rating-1',
	2: 'bg-trustpilot-rating-2',
	3: 'bg-trustpilot-rating-3',
	4: 'bg-trustpilot-rating-4',
	5: 'bg-trustpilot-rating-5',
};

export const TrustpilotRating: FC<TrustpilotRatingProps> = ({ rating, totalReviews, includeReviewsOn }) => {
	const { t } = useTranslation(['common']);

	const readableRating = mapRatingToTrustpilotText(rating, t);

	return (
		<a href={trustpilotCompanyUrl} target='_blank' className='flex flex-row items-center gap-x-12'>
			<Text variant='body-xs' weight='bold' color='grayscale600'>
				{readableRating}
			</Text>

			<div className='flex items-center space-x-[2px]'>
				{[1, 2, 3, 4, 5].map((star) => (
					<div
						key={`trustpilot-star-${star}`}
						className='bg-grayscale200 relative flex h-20 w-20 min-w-20 items-center justify-center'>
						<div
							className={classNames(
								'absolute bottom-0 left-0 top-0',
								star <= Math.ceil(rating) && ratingBackgroundColorMap[Math.ceil(rating)]
							)}
							style={{ width: `${rating % 1 === 0 || star < rating ? 100 : Math.floor((rating % 1) * 100)}%` }}></div>
						<TrustpilotStar theme='maia' className='z-10 h-[14px] w-[14px]' />
					</div>
				))}
			</div>

			{includeReviewsOn && typeof totalReviews === 'number' && (
				<Text variant='body-xs' weight='regular' color='grayscale600' className='max-md:hidden'>
					<Trans
						i18nKey='components.trustpilot.reviewsOn'
						components={{
							reviews: <Text as='span' variant='body-xs' weight='bold' color='grayscale600' />,
						}}
						tOptions={{
							reviews: totalReviews,
						}}
					/>
				</Text>
			)}

			<div className='relative mb-[1px] h-[26px] w-[71px]'>
				<Image src='/images/trustpilot/logo.png' alt='Trustpilot logo' className='object-contain object-center' fill />
			</div>
		</a>
	);
};

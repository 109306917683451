import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';
import { GetTrustpilotReviewsRequest, GetTrustpilotReviewsResponse, GetTrustpilotScoreResponse } from '@monorepo/types';

export const trustpilotApi = createApi({
	reducerPath: 'trustpilotApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/trustpilot`,
	}),
	tagTypes: ['Reviews'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		getTrustpilotReviews: builder.query<GetTrustpilotReviewsResponse, GetTrustpilotReviewsRequest>({
			query({ stars }) {
				return {
					url: `/get-reviews`,
					method: 'GET',
					params: {
						...(stars && { stars }),
					},
				};
			},

			keepUnusedDataFor: 1800, // in seconds, half hour 60*30
		}),
		getTrustpilotScore: builder.query<GetTrustpilotScoreResponse, void>({
			query() {
				return {
					url: '/get-score',
					method: 'GET',
				};
			},

			keepUnusedDataFor: 1800, // in seconds, half hour 60*30
		}),
	}),
});

export const { useGetTrustpilotReviewsQuery, useGetTrustpilotScoreQuery } = trustpilotApi;

export const { getTrustpilotReviews, getTrustpilotScore } = trustpilotApi.endpoints;

import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { DataEditCard } from '@monorepo/components/DataDisplay';
import { Text } from '@monorepo/components/TextComponents';
import { Address, CalculationProduct, CalculationRiskAddress } from '@monorepo/types';

import { Modal } from '@common/components';
import { hideModal, showModal } from '@common/store';
import { AddressForm } from '@funnel/forms';

type Props = {
	product: CalculationProduct;
	risk_address: CalculationRiskAddress;
	onChangeAddress: (address: Address) => void;
	addressIsLoading?: boolean;
};

export const ProductFormAddress: FC<Props> = ({ product, risk_address, onChangeAddress, addressIsLoading = false }) => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();
	const modalId = `change_company_${product.guid}`;

	const handleOpenChangeCompanyAddressModal = () => {
		dispatch(showModal(modalId));
	};

	const handleCloseChangeCompanyAddressModal = () => {
		dispatch(hideModal(modalId));
	};

	return (
		<div className='space-y-12'>
			<Text variant='body-s' color='grayscale500' weight='medium'>
				{product.name === 'Omzetverlies'
					? t('components.productForm.address.oneOffs.omzetVerlies')
					: t('components.productForm.address.default')}
			</Text>
			<div>
				<DataEditCard
					isLoading={addressIsLoading}
					variant='outlined'
					onEditClick={handleOpenChangeCompanyAddressModal}
					address={risk_address}
				/>
			</div>
			<Modal id={modalId} title={t('page.funnel.company.change.title')}>
				<AddressForm
					onSubmit={(address) => {
						handleCloseChangeCompanyAddressModal();
						onChangeAddress(address);
					}}
					onCancel={handleCloseChangeCompanyAddressModal}
				/>
			</Modal>
		</div>
	);
};

import classNames from 'classnames';
import { FC, ReactNode } from 'react';

type ModalActionsProps = {
	buttonsFullWidth?: boolean;
	cancelButton?: ReactNode;
	submitButton?: ReactNode;
	className?: string;
};

export const ModalActions: FC<ModalActionsProps> = ({ buttonsFullWidth, cancelButton, submitButton, className }) => (
	<div
		className={classNames(
			className,
			buttonsFullWidth ? '[&>button]:flex-1' : '',
			!cancelButton && submitButton && 'justify-end',
			cancelButton && submitButton && 'justify-between',
			'mt-32 flex w-full flex-col-reverse items-center gap-8 sm:flex-row md:gap-16 max-sm:[&>button]:w-full'
		)}>
		{cancelButton && cancelButton}
		{submitButton && submitButton}
	</div>
);

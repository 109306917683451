import cn from 'classnames';
import { FC } from 'react';

import { MarketingFooterItems, WebLayoutVariants } from '@monorepo/types';

import { useLogin } from '@auth/hooks';
import { SubFooter } from './SubFooter/SubFooter';
import { TopFooter } from './TopFooter/TopFooter';

export type MarketingFooterProps = MarketingFooterItems & {
	variant: WebLayoutVariants;
	className?: string;
};

export const MarketingFooter: FC<MarketingFooterProps> = ({ columns, links, socials, proofs, variant, className }) => {
	const { userLoggedIn } = useLogin();

	return (
		<footer className={cn(className, 'text-grayscale0 flex flex-col')}>
			{variant === 'default' && <TopFooter isLoggedIn={userLoggedIn} columns={columns} />}

			<SubFooter variant={variant} socials={socials} links={links} proofs={proofs} />
		</footer>
	);
};

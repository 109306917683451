import {
	CmsPreviewContainerRequest,
	CmsPreviewContainerResponse,
	CmsPreviewContainersResponse,
	CmsPreviewElementRequest,
	CmsPreviewElementResponse,
	CmsPreviewElementsResponse,
	CmsPreviewWebpageRequest,
	CmsPreviewWebpageResponse,
	CmsPreviewWebpagesResponse,
} from '@monorepo/types';

import { rootCmsApi } from '../../root/root.api';

const cmsPreviewApi = rootCmsApi.injectEndpoints({
	endpoints: (builder) => ({
		getPreviewWebpages: builder.query<CmsPreviewWebpagesResponse, void>({
			query() {
				return {
					url: `/preview/webpages`,
					method: 'GET',
				};
			},
		}),
		getPreviewWebpage: builder.query<CmsPreviewWebpageResponse, CmsPreviewWebpageRequest>({
			query({ identifier, token }) {
				return {
					url: `/preview/webpages/${identifier}`,
					method: 'GET',
					params: { token },
				};
			},
			providesTags: (result, _, { identifier }) => {
				if (result) {
					return [{ type: 'webpage', id: identifier }];
				}

				/* istanbul ignore next */
				return ['webpage'];
			},
		}),

		getPreviewContainers: builder.query<CmsPreviewContainersResponse, void>({
			query() {
				return {
					url: `/preview/containers`,
					method: 'GET',
				};
			},
		}),
		getPreviewContainer: builder.query<CmsPreviewContainerResponse, CmsPreviewContainerRequest>({
			query({ identifier, token }) {
				return {
					url: `/preview/containers/${identifier}`,
					method: 'GET',
					params: { token },
				};
			},
			providesTags: (result, _, { identifier }) => {
				if (result) {
					return [{ type: 'container', id: identifier }];
				}

				/* istanbul ignore next */
				return ['container'];
			},
		}),

		getPreviewElements: builder.query<CmsPreviewElementsResponse, void>({
			query() {
				return {
					url: `/preview/elements`,
					method: 'GET',
				};
			},
		}),
		getPreviewElement: builder.query<CmsPreviewElementResponse, CmsPreviewElementRequest>({
			query({ identifier, token }) {
				return {
					url: `/preview/elements/${identifier}`,
					method: 'GET',
					params: { token },
				};
			},
			providesTags: (result, _, { identifier }) => {
				if (result) {
					return [{ type: 'element', id: identifier }];
				}

				/* istanbul ignore next */
				return ['element'];
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetPreviewContainerQuery,
	useGetPreviewContainersQuery,
	useGetPreviewWebpageQuery,
	useGetPreviewWebpagesQuery,
	useLazyGetPreviewContainerQuery,
	useLazyGetPreviewContainersQuery,
	useLazyGetPreviewWebpageQuery,
	useLazyGetPreviewWebpagesQuery,
	useGetPreviewElementQuery,
	useGetPreviewElementsQuery,
	useLazyGetPreviewElementQuery,
	useLazyGetPreviewElementsQuery,
} = cmsPreviewApi;

export const {
	getPreviewContainer,
	getPreviewContainers,
	getPreviewWebpage,
	getPreviewWebpages,
	getPreviewElement,
	getPreviewElements,
} = cmsPreviewApi.endpoints;

import cn from 'classnames';
import { ComponentPropsWithoutRef, createElement, ElementType, PropsWithChildren } from 'react';

type TextProps<C extends ElementType> = {
	as?: C;
};

type Props<C extends ElementType> = PropsWithChildren<TextProps<C>> & ComponentPropsWithoutRef<C>;

export const ContentGrid = <C extends ElementType>({ as, className, ...props }: Props<C>) => {
	return createElement(as || 'div', {
		className: cn(
			className,
			'lg:grid-cols-24 grid grid-cols-4 gap-x-24 sm:grid-cols-8 md:grid-cols-12 lg:gap-x-20 2xl:gap-24'
		),
		...props,
	});
};

import { AnimatePresence, motion } from 'framer-motion';
import React, { FC } from 'react';

import { NavItem } from '@monorepo/types';

import { NavBarItem } from '../NavBarItem/NavBarItem';

export type MenuProps = {
	item: NavItem;
	isOpen?: boolean;
};

export const NavbarMenu: FC<MenuProps> = ({ item, isOpen = false }) => {
	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className='bg-grayscale0 md:shadow-2 rounded-b-12 relative z-50 mt-24 w-fit space-y-16 overflow-hidden md:absolute md:mt-12 md:space-y-24 md:pb-24 md:pl-24 md:pr-40 md:pt-24'
					initial={{ opacity: 0, maxHeight: 0 }}
					animate={{ opacity: 1, maxHeight: 400 }}
					exit={{ opacity: 0, maxHeight: 0 }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}>
					{item.subItems?.map((subItem, index) => <NavBarItem item={subItem} key={`nav-bottom-item-${index}`} />)}
				</motion.div>
			)}
		</AnimatePresence>
	);
};

import { FC } from 'react';

import { CheckItem, Text } from '@monorepo/components/TextComponents';
import { List } from '@monorepo/components/TextComponents/Lists/List/List';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { AddonSection } from '@marketing/components/AddonSection/AddonSection';

export const handleSectionAddons = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubTextElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const addons = elements.filter(({ identifier }) => identifier === 'addons');

	const title = getProperty('title', titleSubTextElement.custom_fields);
	const subtext = getProperty('subtext', titleSubTextElement.custom_fields);

	const _addons = addons.map((addon) => {
		const title = getProperty('title', addon.custom_fields);
		const icon = getProperty('icon', addon.custom_fields);
		const description = getProperty('description', addon.custom_fields);
		let content = <></>;

		// TODO: This should come from the cms
		if (title === 'Rechtsbijstand') {
			content = (
				<div className='flex flex-col space-y-20'>
					<Text>
						Met de extra dekking rechtsbijstand, heb je recht op hulp van juridische experts als het misgaat. Er geldt
						een minimaal bedrag van €110 om voor juridische bijstand in aanmerking te komen.{' '}
					</Text>

					<div className='flex flex-col space-y-12'>
						<Text weight='semibold'>De dekking rechtsbijstand is er voor:</Text>

						<List>
							<CheckItem
								color='grayscale600'
								item={{
									included: true,
									content:
										'Het verhalen van schade aan de motor, ontstaan tijdens deelname aan het wegverkeer. Het gaat om schade aan en waardevermindering van de motor, huur van een vervangende motor en schade aan jouw persoonlijke bagage.',
								}}
							/>
							<CheckItem
								color='grayscale600'
								item={{
									included: true,
									content:
										'Het verhalen van schade en letselschade ontstaan tijdens deelname aan het wegverkeer met de motor.',
								}}
							/>
							<CheckItem
								color='grayscale600'
								item={{
									included: true,
									content:
										'Rechtsbijstand bij een meningsverschil over de vergoeding of reparatie van een schade die verhaald wordt.',
								}}
							/>
							<CheckItem
								color='grayscale600'
								item={{
									included: true,
									content: 'Rechtsbijstand in een strafzaak, die noodzakelijk is voor het verhalen van schade.',
								}}
							/>
						</List>
					</div>
				</div>
			);
		} else if (title === 'Opzittendenverzekering') {
			content = (
				<div className='flex flex-col space-y-20'>
					<Text>
						Op zoek naar een aanvullende verzekering waarmee je ook je passagier beschermt? Een verkeersongeval zit in
						een klein hoekje en soms is het zo dat er geen aansprakelijke partij is.
					</Text>
					<Text>
						Wat doe je dan als er schade ontstaat aan de spullen van opzittenden, of erger nog aan de opzittenden zelf?
						Met de extra dekking voor opzittenden vergoeden wij in beide gevallen tot € 1.000.000. Het maakt hierbij
						niet uit wie er aansprakelijk is.
					</Text>
					<Text>
						Let er wel op dat opzittenden altijd een verplichte helm dragen. Gebeurt dit niet, dan vergoeden wij 75% van
						het schadebedrag.
					</Text>
				</div>
			);
		} else if (title === 'Persoonlijke ongevallen') {
			content = (
				<div className='flex flex-col space-y-20'>
					<Text>
						Je denkt er als motorrijder liever niet aan, maar een {'(zwaar)'} ongeluk zit in een klein hoekje. Met de
						dekking van persoonlijke ongevallen voor opzittenden ontvang je een uitkering bij overlijden of blijvende
						invaliditeit van opzittenden. Hierbij geldt dat het overlijden of blijvende invaliditeit het gevolg is van
						een verkeersongeval met de motor.
					</Text>
					<Text>
						De dekking geldt ook als het misgaat tijdens het op- of afstappen, uitvoeren van noodreparaties, bij het
						tanken en zelfs bij het verlenen van hulp aan medeweggebruikers.
					</Text>
					<Text>
						De verzekerde bedragen zijn €5.000 tot €10.000 bij overlijden en €10.000 tot €25.000 bij blijvende
						invaliditeit. Deze bedragen gelden per zitplaats.
					</Text>
				</div>
			);
		} else if (title === 'Pechhulp') {
			content = (
				<div className='flex flex-col space-y-20'>
					<Text>
						Met deze dekking krijg je hulp als je motor niet verder kan rijden door pech. De pechhulp is overal van
						toepassing conform de landen op de Groene Kaart, in heel Nederland en dus ook je eigen woonplaats. Er geldt
						daarbij geen maximum voor het aantal keer dat je gebruik maakt van deze hulp.
					</Text>
				</div>
			);
		}

		return {
			title: `${title}`,
			icon: `${icon}`,
			description: `${description}`,
			content,
		};
	});

	return (props) => (
		<AddonSection
			{...props}
			className='bg-primary50 py-48 md:pb-96 md:pt-64'
			title={`${title}`}
			subtitle={`${subtext}`}
			addons={_addons}
		/>
	);
};

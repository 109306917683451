import { Organization } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const cartOrganizationApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getCartOrganization: builder.query<Organization, string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/organization`,
					method: 'GET',
				};
			},
			providesTags: (result) => {
				if (result && result.guid) {
					return [{ type: 'Organization', id: result.guid }];
				}

				/* istanbul ignore next */
				return ['Organization'];
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetCartOrganizationQuery } = cartOrganizationApi;

export const { getCartOrganization } = cartOrganizationApi.endpoints;

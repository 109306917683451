import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { anchorClassName, underlineClassName } from '@monorepo/shared/lib/utils';

import { selectTheme } from '@common/store';
import { paths } from '@common/utils';

type FooterProps = {
	includeBackground?: boolean;
	includePadding?: boolean;
	className?: string;
};
export const Footer: FC<FooterProps> = ({ includeBackground = false, includePadding = false, className = '' }) => {
	const { t } = useTranslation(['common']);
	const theme = useSelector(selectTheme);

	return (
		<footer
			className={classNames(
				className,
				includeBackground && 'bg-grayscale0',
				includePadding && 'py-24',
				'flex w-full flex-wrap items-start md:bottom-0 md:flex-row md:items-center'
			)}>
			<div className='flex items-center space-x-4 sm:mr-12'>
				<Icon name='copyright' color='grayscale300' size='sm' />
				<Text variant='body-xs' color='grayscale300'>
					{['maia', 'cybermeister', 'meestersgilde'].includes(theme)
						? t('legal.maia', { year: new Date().getFullYear() })
						: t(`legal.${theme}`, { year: new Date().getFullYear() })}
				</Text>
			</div>
			<div className='flex items-start space-x-12 max-md:ml-8 md:items-center'>
				<div className='flex flex-row space-x-12'>
					<Text variant='body-xs' color='grayscale300' className='max-md:hidden'>
						•
					</Text>
					<div className='w-fit'>
						<Link href={paths.content.terms} target='_blank' className={anchorClassName}>
							<Text as='span' variant='body-xs' color='inherit'>
								{t('common.terms')}
							</Text>
							<span className={underlineClassName}></span>
						</Link>
					</div>
				</div>

				<div className='flex flex-row space-x-12'>
					<Text variant='body-xs' color='grayscale300' className='max-md:hidden'>
						•
					</Text>
					<div className='w-fit'>
						<Link href={paths.content.privacy_statement} target='_blank' className={anchorClassName}>
							<Text as='span' variant='body-xs' color='inherit'>
								{t('common.privacyPolicy')}
							</Text>
							<span className={underlineClassName}></span>
						</Link>
					</div>
				</div>
			</div>
		</footer>
	);
};

import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { TextButton } from '../../../DataEntry';
import { Skeleton } from '../../../Misc';
import { Text } from '../../../TextComponents';

type DashboardCardProps = {
	title: string;
	to: string;
	className?: string;
	isLoading?: boolean;
	isError?: boolean;
	total?: number;
	footer?: ReactNode;
	emptyText: string;
	errorText: string;
};

export const DashboardCard: FC<PropsWithChildren<DashboardCardProps>> = ({
	title,
	to,
	children,
	className,
	isLoading,
	isError,
	total,
	footer,
	emptyText,
	errorText,
}) => {
	const { t } = useTranslation(['common']);

	return (
		<div
			className={classNames(
				'sm:bg-grayscale0 sm:rounded-12 sm:shadow-1 flex flex-col space-y-16 sm:space-y-24 sm:p-24',
				className
			)}>
			<div className='flex flex-1 flex-col space-y-16 sm:space-y-20'>
				<div className='flex items-center'>
					<Skeleton
						isLoading={isLoading}
						variant='text'
						size='body-m'
						containerClassName='flex-1'
						className='w-[125px]'>
						<Text variant='body-m' weight='semibold' color='grayscale600'>
							{title}
							{typeof total === 'number' && (
								<Text as='span' variant='body-m' weight='semibold' color='grayscale300' className='pl-4'>
									{`(${total})`}
								</Text>
							)}
						</Text>
					</Skeleton>
					<Skeleton isLoading={isLoading} variant='rounded' className='h-[24px] w-[75px]'>
						<Link href={to}>
							<TextButton
								label={t('common.viewAll')}
								iconRight='angle-right'
								color='primary'
								size='xs'
								className='px-0'
							/>
						</Link>
					</Skeleton>
				</div>
				<Skeleton
					isLoading={isLoading}
					variant='custom'
					skeleton={children}
					containerClassName='flex flex-1 [&>*]:w-full'>
					{(typeof total === 'number' && total === 0) || isError ? (
						<div className='border-grayscale100 rounded-6 flex items-center justify-center border px-40 py-32 text-center'>
							<Text variant='body-s' weight='regular' color='grayscale400'>
								{isError ? errorText : emptyText}
							</Text>
						</div>
					) : (
						children
					)}
				</Skeleton>
			</div>

			{footer && !isError && footer}
		</div>
	);
};

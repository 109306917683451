import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Toggle } from '../../DataEntry';
import { Text } from '../../TextComponents';

type Props = {
	isToggled: boolean;
	onToggleBillingUnit: () => void;
	isLoading?: boolean;
	className?: string;
};

export const ToggleBillingUnit: FC<Props> = ({ isToggled, onToggleBillingUnit, isLoading, className = '' }) => {
	const { t } = useTranslation(['common']);

	return (
		<div className={cn(className, 'flex flex-col space-y-4', isLoading && 'cursor-progress')}>
			<div className='flex flex-row items-center space-x-12'>
				<Text as='span' variant='body-xs' weight='medium' color={isLoading ? 'grayscale400' : 'grayscale600'}>
					{t('common.monthly')}
				</Text>
				<div>
					<Toggle onChange={onToggleBillingUnit} isDisabled={isLoading} isLoading={isLoading} isToggled={isToggled} />
				</div>
				<Text as='span' variant='body-xs' weight='medium' color={isLoading ? 'grayscale400' : 'grayscale600'}>
					{t('common.yearly')}
				</Text>
			</div>
			<Text as='span' variant='body-xxs' color='grayscale300' className='text-right'>
				* {t('common.includingTax')}
			</Text>
		</div>
	);
};

import { FC, SyntheticEvent } from 'react';

import { BaseAccordion } from '@monorepo/components/DataDisplay/Accordions/BaseAccordion/BaseAccordion';
import type { Classification } from '@monorepo/types';

import { ActivitySubGroup } from '../ActivitySubGroup/ActivitySubGroup';

type ActivityGroupProps = {
	classification: Classification;
	selectedClassificationGuids: string[];
	openActivityGroupGuid?: string;
	openActivitySubgroup?: string;
	isLoading?: boolean;
	handleOpenActivityModal: (guid: string) => void;
	handleToggleOpenActivityGroup: (guid: string) => void;
	handleToggleOpenActivitySubgroup: (guid: string, event: SyntheticEvent) => void;
	handleToggleActivity: (guid: string) => void;
};

export const ActivityGroup: FC<ActivityGroupProps> = ({
	openActivityGroupGuid,
	openActivitySubgroup,
	classification: { guid, icon, name, children },
	selectedClassificationGuids,
	handleToggleOpenActivityGroup,
	handleToggleOpenActivitySubgroup,
	handleToggleActivity,
	handleOpenActivityModal,
	isLoading,
}) => {
	const allActiveGroupActivities = children
		.flatMap((a) => a.children)
		.filter((a) => selectedClassificationGuids.some((s) => s === a.guid)).length;

	return (
		<BaseAccordion
			icon={icon}
			title={
				allActiveGroupActivities > 0 ? (
					<span>
						{name} <span className='text-info300'>{`(${allActiveGroupActivities})`}</span>
					</span>
				) : (
					name
				)
			}
			key={guid}
			isDefaultOpen={openActivityGroupGuid === guid}
			onToggle={() => handleToggleOpenActivityGroup(guid)}>
			<div className='gap-y-12'>
				{children.map((subgroup) => (
					<ActivitySubGroup
						variant='sub'
						key={`activity-sub-group-${subgroup.guid}`}
						classification={subgroup}
						selectedClassificationGuids={selectedClassificationGuids}
						openActivitySubgroup={openActivitySubgroup}
						handleOpenActivityModal={handleOpenActivityModal}
						handleToggleOpenActivitySubgroup={handleToggleOpenActivitySubgroup}
						handleToggleActivity={handleToggleActivity}
						isLoading={isLoading}
					/>
				))}
			</div>
		</BaseAccordion>
	);
};

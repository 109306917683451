import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { FC, ReactNode } from 'react';

import { CmsLink, CmsMedia, ThemeNames } from '@monorepo/types';

import { ANALYTICS_ID, generateSizesString } from '../../../../../lib/utils';
import { StandardButton } from '../../../../DataEntry';
import { LayoutGrid } from '../../../../Misc/UI';
import { Text } from '../../../../TextComponents';

type LandingSectionHeroProps = {
	title: string | string[] | ReactNode;
	subTitle: string;
	className?: string;
	theme?: ThemeNames;
	image: CmsMedia;
	link: CmsLink;
	buttonLabel: string;
};

export const LandingSectionHero: FC<LandingSectionHeroProps> = ({
	title,
	subTitle,
	buttonLabel,
	theme,
	image,
	link,
	className = '',
}) => {
	return (
		<header className={cn(className, 'bg-primary50')}>
			<LayoutGrid layout='marketing'>
				<div className='relative col-start-2 grid grid-cols-2 py-80'>
					<div className='col-span-full flex flex-col justify-center space-y-40 md:col-span-1'>
						<div className='space-y-24'>
							<Text
								as='h1'
								variant='display-2'
								color={theme === 'onderhoudnl' ? 'grayscale600' : 'primaryMain'}
								weight='semibold'>
								{title}
							</Text>
							<Text as='p' variant='body-l' color='grayscale400'>
								{subTitle}
							</Text>
						</div>

						<Link className='w-fit' href={link.href} title={link.title}>
							<StandardButton
								name={ANALYTICS_ID.landing_calculate_premium_cta}
								size='lg'
								variant={theme === 'onderhoudnl' ? 'ctaSecondary' : 'cta'}
								label={buttonLabel}
								iconRight='angle-right'
							/>
						</Link>
					</div>
					<div className='absolute bottom-0 right-0 col-span-1 h-full w-1/2 max-md:hidden'>
						<Image
							src={image.path}
							fill
							sizes={generateSizesString()}
							alt={image.alt}
							className='object-contain object-left-bottom'
						/>
					</div>
				</div>
			</LayoutGrid>
		</header>
	);
};

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { InsuranceDocs, ProductDocument } from '@monorepo/types';

import { useNavigation } from '@common/hooks';
import { useGetCompositionsForObjectQuery } from '@consumer/store';
import { selectCalculationState, useGetInsuranceDocsQuery } from '@funnel/store';

type Props = {
	cartGuid?: string;
	productGuid?: string;
	objectGuid?: string;
};

export const useDocumentsForProduct = ({ cartGuid, productGuid, objectGuid }: Props) => {
	const { data: compositions = [] } = useGetCompositionsForObjectQuery(
		{
			cartGuid: `${cartGuid}`,
			objectGuid: `${objectGuid}`,
		},
		{
			skip: !objectGuid || !cartGuid,
		}
	);

	const { data: documents = {} as InsuranceDocs } = useGetInsuranceDocsQuery(
		cartGuid && cartGuid !== 'undefined' ? cartGuid : skipToken
	);

	const calculations = useSelector(selectCalculationState);
	const { isB2BFunnel, isB2CFunnel } = useNavigation();

	const [productDocument, setProductDocument] = useState<ProductDocument>();

	useEffect(() => {
		if (isB2BFunnel) {
			const selectedProduct = calculations?.find((calculation) => calculation.product.guid === productGuid);

			if (selectedProduct) {
				setProductDocument(documents?.products?.find((product) => product.code === selectedProduct.product.code));
			}
		}

		if (isB2CFunnel) {
			const selectedComposition = compositions?.find((composition) => composition.guid === productGuid);

			if (selectedComposition) {
				setProductDocument(
					documents?.products?.find((product) => product.composition?.guid === selectedComposition.guid)
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productGuid, documents, isB2BFunnel, isB2CFunnel]);

	return {
		productDocument,
	};
};

import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import { useAnalytics } from '@monorepo/shared/lib/hooks';
import { KvKCompany, KvKSearchRequest } from '@monorepo/types';

import { useSearchQuery } from '@common/store';

type UseSearchProps = {
	passedQuery?: string;
	num?: number;
	type?: '&type=hoofdvestiging&type=nevenvestiging' | '&type=hoofdvestiging' | '&type=nevenvestiging';
};

export const useCompanySearch = ({
	passedQuery,
	num = 10,
	type = '&type=hoofdvestiging&type=nevenvestiging',
}: UseSearchProps) => {
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [request, setRequest] = useState<KvKSearchRequest | typeof skipToken>(skipToken);
	const {
		data: searchResponse,
		error: searchError,
		isFetching: searchIsFetching,
		isLoading: searchIsLoading,
	} = useSearchQuery(request, {
		skip: passedQuery?.length === 0,
	});
	const [companies, setCompanies] = useState<KvKCompany[]>([]);
	const { trackEvent } = useAnalytics();

	const page = async () => {
		if (typeof request === 'object' && request.page) {
			setRequest({ q: request.q, num: request?.num || num, page: request.page + 1, type });
		}
	};

	const hasMoreResults = (): boolean => {
		return !!(typeof request === 'object' && searchResponse?.volgende);
	};

	const [] = useDebounce(
		() => {
			if (passedQuery && passedQuery !== (request as KvKSearchRequest)?.q && passedQuery.length >= 2) {
				setRequest({ q: passedQuery, page: 1, num, type });
			} else if (searchQuery && searchQuery !== (request as KvKSearchRequest)?.q && searchQuery.length >= 2) {
				setCompanies([]);
				setRequest({ q: searchQuery, page: 1, num, type });
			} else if (!searchQuery) {
				setCompanies([]);
			}
		},
		650,
		[searchQuery]
	);

	useEffect(() => {
		if (searchError) {
			setCompanies([]);
			trackEvent('search', { search_term: (request as KvKSearchRequest).q });
		} else if (searchResponse && !searchIsFetching) {
			setCompanies((companies) => [...companies, ...searchResponse.resultaten]);
			trackEvent('search', { search_term: (request as KvKSearchRequest).q });
		}
	}, [searchResponse, searchError, searchIsFetching, request, trackEvent]);

	return {
		searchQuery,
		setSearchQuery,
		request,
		setRequest,
		searchResponse,
		companies,
		searchError,
		searchIsLoading,
		searchIsFetching,
		page,
		hasMoreResults,
	};
};

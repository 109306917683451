import classNames from 'classnames';
import parse from 'html-react-parser';
import Link from 'next/link';
import { FC } from 'react';

import { News } from '@monorepo/types';

import { TextButton } from '../../../DataEntry';
import { Icon, Skeleton } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { BaseCard } from '../BaseCard/BaseCard';

type NewsCardProps = {
	href: string;
	isLoading?: boolean;
	news?: News;
};

export const NewsCard: FC<NewsCardProps> = ({ href, news, isLoading }) => (
	<Link href={isLoading ? {} : href} aria-disabled={isLoading} className={classNames(isLoading && 'cursor-default')}>
		<BaseCard isElevated={false} className='flex-col space-y-16 p-20 md:p-24'>
			<div className='flex flex-row space-x-16'>
				<Skeleton isLoading={isLoading} variant='rectangular' className='rounded-18 h-[60px] w-[60px]'>
					<div className='bg-primary100 rounded-18 flex h-[60px] w-[60px] items-center justify-center'>
						<Icon name='megaphone' size='md' />
					</div>
				</Skeleton>
				<div>
					<Skeleton isLoading={isLoading} variant='text' size='body-xs' className='w-[75px]'>
						<Text variant='body-xs' color='success300' weight='semibold'>
							{news?.category}
						</Text>
					</Skeleton>
					<div className='space-y-8 pt-4'>
						<Skeleton isLoading={isLoading} variant='text' size='body-s' className='w-[150px]'>
							<Text variant='body-m' weight='semibold' color='grayscale600'>
								{news?.content.title}
							</Text>
						</Skeleton>
						<Skeleton isLoading={isLoading} variant='text' size='body-xxs' className='w-[100px]'>
							<Text variant='body-xxs' weight='regular' color='grayscale400'>
								{news?.publish_date}
							</Text>
						</Skeleton>
					</div>
				</div>
			</div>
			<Skeleton isLoading={isLoading} variant='text' size='body-s' className='w-[400px]'>
				<Text variant='body-s' color='grayscale500' weight='regular' className='line-clamp-3'>
					{parse(`${news?.content.body}`)}
				</Text>
			</Skeleton>

			<Skeleton isLoading={isLoading} variant='rounded' className='h-[24px] w-[100px]'>
				<TextButton size='xs' label='Lees meer' iconRight='arrow-right' />
			</Skeleton>
		</BaseCard>
	</Link>
);

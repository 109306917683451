import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NewClaimStep1Schema } from '@monorepo/shared/lib/schemas';

import { AppState } from '@common/store';

export type ClaimState = {
	newClaimStep: number;
	claim: Partial<NewClaimStep1Schema>;
	newClaimGuid: string | null;
};

// Initial state
const initialState: ClaimState = {
	newClaimStep: 0,
	newClaimGuid: null,
	claim: {
		claim_date: '',
		industrial_classification_guid: null,
		insurance_guid: null,
	},
};

// Claim slice
export const claimSlice = createSlice({
	name: 'claim',
	initialState,
	reducers: {
		saveClaim(state, action: PayloadAction<Partial<NewClaimStep1Schema>>) {
			state.claim = { ...state.claim, ...action.payload };
		},
		setNewClaimStep(state, action: PayloadAction<number>) {
			state.newClaimStep = action.payload;
		},
		resetClaim() {
			return initialState;
		},
		setClaimGuid(state, action: PayloadAction<string>) {
			state.newClaimGuid = action.payload;
		},
	},
});

export const { saveClaim, setNewClaimStep, setClaimGuid, resetClaim } = claimSlice.actions;

// Export selectors
export const selectClaimState = (state: AppState) => state.claim.claim;
export const selectNewClaimStep = (state: AppState) => state.claim.newClaimStep;
export const selectClaimGuid = (state: AppState) => state.claim.newClaimGuid;

import { FC } from 'react';

import { CartVehicle } from '@monorepo/types';

import { LicensePlate } from '../../../Misc';
import { Text } from '../../../TextComponents';

export type SummaryCardVehicleProps = {
	vehicle: CartVehicle;
};

export const SummaryCardVehicle: FC<SummaryCardVehicleProps> = ({ vehicle }) => {
	return (
		<li className='flex flex-row gap-16'>
			<LicensePlate license={vehicle.license} size='small' />
			<div className='flex flex-col gap-y-4'>
				<Text variant='body-m' weight='medium' color='grayscale600'>
					{vehicle.make}
				</Text>
				<Text variant='body-xs' weight='regular' color='grayscale300'>
					{vehicle.model}
				</Text>
			</div>
		</li>
	);
};

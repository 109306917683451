import { ICustomerSupportCta } from '@monorepo/types';

export const mockCustomerSupportCta: ICustomerSupportCta = {
	supportItems: [
		{
			type: 'email',
			icon: 'envelope',
			title: 'E-mail met support',
			description: 'Ons support team staat voor je klaar ',
			email: 'support@maia.insure',
		},
		{
			type: 'chat',
			icon: 'comments',
			title: 'Chat met support',
			description: 'Ons support team staat voor je klaar ',
			text: 'Start nieuwe chat',
		},
		{
			type: 'phone',
			icon: 'mobile-android',
			title: 'Bel met support',
			description: 'Ons support team staat voor je klaar ',
			phoneNumber: '+31620442192',
		},
	],
};

import { Bundle } from '@monorepo/types';

export const ALL_BUNDLES: Bundle[] = [
	{
		guid: '75e5ed7d-9c9e-41f8-ba66-ddda37b98c81',
		sequence: 10,
		name: 'Flex',
		code: 'ANKO-0001',
		is_active: false,
		minimum_price: 17.52,
		products: [
			{
				name: 'Inventaris/goederen + huurdersbelang',
				is_optional: false,
				code: 'M-BEA-BR-0001',
			},
			{
				name: 'Bedrijfsaansprakelijkheid',
				is_optional: false,
				code: 'M-BEA-AB-0001',
			},
			{
				name: 'Eigen vervoer',
				is_optional: false,
				code: 'M-BEA-TR-0001',
			},
			{
				name: 'Gebouw',
				is_optional: true,
				code: 'M-BEA-BR-0005',
			},
		],
	},
	{
		guid: '05ad97e1-2dfb-40e0-83c1-6a917167d6de',
		sequence: 20,
		name: 'Basis',
		code: 'ANKO-0002',
		is_active: false,
		minimum_price: 32.69,
		products: [
			{
				name: 'Bedrijfsaansprakelijkheid',
				is_optional: false,
				code: 'M-BEA-AB-0001',
			},
			{
				name: 'Inventaris/goederen + huurdersbelang',
				is_optional: false,
				code: 'M-BEA-BR-0001',
			},
			{
				name: 'Bedrijfsschade',
				is_optional: false,
				code: 'M-BEA-BR-0002',
			},
			{
				name: 'Eigen vervoer',
				is_optional: false,
				code: 'M-BEA-TR-0001',
			},
			{
				name: 'WEGAM',
				is_optional: false,
				code: 'M-BEA-AB-0002',
			},
			{
				name: 'Gebouw',
				is_optional: true,
				code: 'M-BEA-BR-0005',
			},
		],
	},
	{
		guid: '00dcc531-5f66-43ae-b2b6-486167d75044',
		sequence: 30,
		name: 'Team',
		code: 'ANKO-0003',
		is_active: true,
		minimum_price: 42.36,
		products: [
			{
				name: 'Bedrijfsaansprakelijkheid',
				is_optional: false,
				code: 'M-BEA-AB-0001',
			},
			{
				name: 'Inventaris/goederen + huurdersbelang',
				is_optional: false,
				code: 'M-BEA-BR-0001',
			},
			{
				name: 'Elektronica',
				is_optional: false,
				code: 'M-BEA-BR-0003',
			},
			{
				name: 'WEGAM',
				is_optional: false,
				code: 'M-BEA-AB-0002',
			},
			{
				name: 'Bedrijfsschade',
				is_optional: false,
				code: 'M-BEA-BR-0002',
			},
			{
				name: 'Geld',
				is_optional: false,
				code: 'M-BEA-VB-0001',
			},
			{
				name: 'Glas en lichtreclame',
				is_optional: false,
				code: 'M-BEA-BR-0004',
			},
			{
				name: 'Eigen vervoer',
				is_optional: false,
				code: 'M-BEA-TR-0001',
			},
			{
				name: 'Gebouw',
				is_optional: true,
				code: 'M-BEA-BR-0005',
			},
		],
	},
];

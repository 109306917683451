import { INicheOverviewCard, INicheOverviewSection } from '@monorepo/types';

const cards: INicheOverviewCard[] = [
	{
		icon: 'apps',
		title: 'Voor alle ZZPers & MKBers',
		featured: true,
		description:
			'* Lorem ipsum dolor sit amet, consectetur adipiscing elit. * Suspendisse varius enim in eros elementum tristique. * Duis cursus, mi quis viverra ornare.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
	{
		icon: 'constructor',
		title: 'Werknemersschade',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
	{
		icon: 'band-aid',
		title: 'Ongevallen verzekering',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
	{
		icon: 'globe',
		title: 'Cyber',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
	{
		icon: 'car-sideview',
		title: 'Zakelijke auto',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
	{
		icon: 'university',
		title: 'Rechtsbijstand',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
];

export const mockNicheOverview: INicheOverviewSection = {
	moreLink: {
		content: 'Bekijk alle niches',
		href: '/werkvelden',
		title: 'Bekijk alle niches',
		target: '_self',
	},
	items: [
		{
			category: {
				icon: 'apps',
				text: 'Alles',
			},
			cards,
		},
		{
			category: {
				icon: 'constructor',
				text: 'Vastgoed & taxatie',
			},
			cards,
		},
		{
			category: {
				icon: 'globe',
				text: 'Haar & Beauty',
			},
			cards,
		},
		{
			category: {
				icon: 'car-sideview',
				text: 'Afwerking & onderhoud',
			},
			cards,
		},
		{
			category: {
				icon: 'university',
				text: 'Bouw & aannemers',
			},
			cards,
		},
	],
};

import classNames from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { Icon, LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { IWhyInsureThroughMaiaSection } from '@monorepo/types';

import { CtaSearchForm } from '../CtaSearchFormSection/CtaSearchForm/CtaSearchForm';

export type WhyInsureThroughMaiaSectionProps = IWhyInsureThroughMaiaSection & {
	className?: string;
};

export const WhyInsureThroughMaiaSection: FC<WhyInsureThroughMaiaSectionProps> = ({
	title,
	bulletpoints,
	image,
	children,
	className,
}) => {
	return (
		<section className={classNames(className)}>
			<LayoutGrid layout='marketing'>
				<div className='gap-104 col-start-2 grid grid-cols-2'>
					<div className='col-span-full flex flex-col space-y-48 md:col-span-1'>
						<div className=' flex flex-col space-y-24'>
							<Text as='h2' variant='display-2' color='primaryMain' weight='semibold' className='max-md:text-center'>
								{title}
							</Text>

							{bulletpoints ? (
								<ul className='flex flex-col space-y-24'>
									{bulletpoints.map((bulletpoint, key) => (
										<li
											className='flex flex-row items-start space-x-24'
											key={`why-insure-through-maia-bulletpoint-${key}`}>
											<Icon name='check' color='success300' size='xl' />
											<div className='flex flex-col space-y-4'>
												<Text variant='body-l' weight='medium' color='primaryMain'>
													{bulletpoint.title}
												</Text>
												<Text variant='body-s' weight='regular' color='grayscale400'>
													{bulletpoint.description}
												</Text>
											</div>
										</li>
									))}
								</ul>
							) : (
								<div className='flex flex-col space-y-24'>{children}</div>
							)}
						</div>
						<CtaSearchForm id={''} className='shadow-1 rounded-18 p-12 grayscale-0 md:p-32' />
					</div>

					<div className='rounded-12 relative col-span-1 h-[600px] w-full overflow-hidden max-md:hidden'>
						<Image
							sizes={generateSizesString()}
							src={image.path}
							alt={image.alt}
							className='object-cover object-center'
							fill
						/>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

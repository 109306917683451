import { useRouter } from 'next/router';
import Script from 'next/script';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isBrowser } from '@monorepo/shared/lib/utils';

import { useFlags } from '@common/hooks';
import { selectCartState } from '@funnel/store';
import {
	closeSupportChat,
	closeSupportOptions,
	openSupportOptions,
	selectChatIsOpen,
	setUnreadMessages,
} from '@support/store';

const ZE_CARTGUID_FIELD_ID = '20911682823057';

export const ChatScript = () => {
	const [isMounted, setIsMounted] = useState(false);
	const { pathname } = useRouter();
	const zendeskApiKey = process.env.NEXT_PUBLIC_ZENDESK_KEY;
	const dispatch = useDispatch();
	const isChatOpen = useSelector(selectChatIsOpen);
	const { guid: cartGuid } = useSelector(selectCartState);
	const { getNumber } = useFlags();
	const openOptionsAfter = getNumber('zendeskOpenOptionsAfter');

	const initializeZendesk = useCallback(() => {
		if (window.zE) {
			setIsMounted(true);
			// Event listeners
			window.zE('messenger:on', 'close', () => {
				dispatch(closeSupportChat());
				dispatch(closeSupportOptions());
			});

			window.zE('messenger:on', 'open', () => {
				dispatch(setUnreadMessages(0));
			});

			window.zE('messenger:on', 'unreadMessages', (count) => {
				dispatch(setUnreadMessages(count));
			});
		}
	}, [dispatch]);

	useEffect(() => {
		if (!zendeskApiKey || !isBrowser) {
			return;
		}

		const scriptElement = window.document.getElementById('ze-snippet');

		if (scriptElement) {
			scriptElement.addEventListener('load', initializeZendesk);

			// Cleanup function
			return () => {
				scriptElement.removeEventListener('load', initializeZendesk);
			};
		}
	}, [zendeskApiKey, initializeZendesk]);

	useEffect(() => {
		if (isMounted) {
			// Handle global chatopen/close
			if (isChatOpen) {
				window.zE('messenger', 'open');
			} else {
				window.zE('messenger', 'close');
			}

			// Assign cartguid to zendesk when it's available
			if (cartGuid) {
				window?.zE('messenger:set', 'conversationFields', [{ id: ZE_CARTGUID_FIELD_ID, value: `${cartGuid}` }]);
			}
		}
	}, [isChatOpen, cartGuid, isMounted]);

	// Open options after a certain amount of time
	useEffect(() => {
		let timerId: NodeJS.Timeout;
		// Open chatoptions after configured seconds (Firebase Remote Config) and only when user is in a salesfunnel
		if (openOptionsAfter && pathname.includes('/aanvragen')) {
			timerId = setTimeout(() => {
				dispatch(openSupportOptions());
			}, openOptionsAfter * 1000);
		}

		// Cleanup function
		return () => {
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, [openOptionsAfter, pathname, dispatch]);

	// TODO: Uncomment when we need to use cookie consent of user.
	//  In the case the user opts out of cookies, Zendesk doesn't work so we should properly handle that
	// useEffect(() => {
	// 	if (isMounted) {
	// 		window.zE('messenger:set', 'cookies', chatCookies);
	// 	}
	// }, [chatCookies, isMounted]);

	if (!zendeskApiKey) {
		return null;
	}

	return (
		<Script
			id='ze-snippet'
			src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskApiKey}`}
			data-test-id='zendesk_script'
			strategy='afterInteractive'
		/>
	);
};

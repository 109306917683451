import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IWhatWeDoForYouSection } from '@monorepo/types';

import { BlurbCard } from '../BlurbCard/BlurbCard';

type WhatWeDoForYouSectionProps = IWhatWeDoForYouSection & {
	className?: string;
};

export const WhatWeDoForYouSection: FC<WhatWeDoForYouSectionProps> = ({ title, subtitle, items, className }) => {
	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col items-center space-y-48'>
					<div className='flex flex-col space-y-24'>
						<Text as='h2' variant='display-3' color='primaryMain' weight='semibold' className='text-center'>
							{title}
						</Text>
						<Text variant='body-l' color='primaryMain' weight='regular' className='text-center'>
							{subtitle}
						</Text>
					</div>
					<div className='grid w-full grid-cols-6 gap-24'>
						{items.map(({ icon, title, description }, key) => (
							<BlurbCard
								icon={icon}
								title={title}
								description={description}
								className='col-span-full sm:col-span-3 md:col-span-2'
								key={`what-we-do-for-you-${key}`}
							/>
						))}
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

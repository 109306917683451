import cn from 'classnames';
import { FC, useEffect, useState } from 'react';

import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';

type Toggle = {
	label: string;
	icon?: string;
	notificationCount?: number;
};

type FilterTogglesProps = {
	toggles: Toggle[];
	onChange: (index: number) => void;
	defaultActiveToggle?: number;
	className?: string;
};

export const FilterToggles: FC<FilterTogglesProps> = ({ toggles, defaultActiveToggle, onChange, className }) => {
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [activeToggle, setActiveToggle] = useState<number>(
		typeof defaultActiveToggle === 'number' ? defaultActiveToggle : 0
	);

	useEffect(() => {
		if (isDirty) {
			onChange(activeToggle);
		}
	}, [activeToggle]);

	const handleChange = (index: number) => {
		setActiveToggle(index);
		setIsDirty(true);
	};

	return (
		<div
			className={cn(
				'rounded-6 border-grayscale100 bg-grayscale50 flex w-fit flex-row items-center space-x-2 border p-[2px]',
				className
			)}>
			{toggles.map((toggle, index) => (
				<FilterToggle
					isActive={index === activeToggle}
					key={`filter-toggle-${index}`}
					index={index}
					onChange={handleChange}
					{...toggle}
				/>
			))}
		</div>
	);
};

type FilterToggleProps = {
	index: number;
	isActive: boolean;
	onChange: (index: number) => void;
} & Toggle;

const FilterToggle: FC<FilterToggleProps> = ({ isActive, index, icon, label, notificationCount, onChange }) => {
	return (
		<div
			onClick={() => onChange(index)}
			className={cn(
				'rounded-4 flex cursor-pointer flex-row items-center space-x-8 px-12 py-8 transition-all',
				isActive && 'bg-grayscale0 shadow-1',
				!isActive &&
					'hover:bg-grayscale100 text-grayscale400 focus:bg-grayscale100 active:text-grayscale600 hover:text-grayscale400 focus:text-grayscale400 active:bg-grayscale100 '
			)}>
			{icon && <Icon name={icon} color='inherit' size='sm' />}

			<Text as='span' variant='body-xs' color='inherit' weight='semibold' className='select-none'>
				{label}
			</Text>

			{typeof notificationCount === 'number' && notificationCount > 0 && (
				<div className='bg-error300 flex h-16 w-16 items-center justify-center rounded-full'>
					<Text as='span' variant='body-xxs' color='grayscale0' weight='semibold'>
						{notificationCount}
					</Text>
				</div>
			)}
		</div>
	);
};

import cn from 'classnames';
import Image from 'next/image';
import { ChangeEvent, FC, FocusEvent, forwardRef, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

import { formatLicenseplate, generateSizesString, getSidecodeLicenseplate } from '../../../lib/utils';
import { Text } from './../../TextComponents';

type LicensePlatePropsSize = 'default' | 'medium' | 'small';

type LicensePlateBaseProps = {
	size?: LicensePlatePropsSize;
	className?: string;
};

export type LicensePlateProps = {
	license: string;
	isLoading?: boolean;
} & LicensePlateBaseProps;

export const LicensePlateWrapper: FC<PropsWithChildren<LicensePlateBaseProps>> = ({
	children,
	size = 'default',
	className,
}) => (
	<div className={cn('rounded-4 flex flex-row overflow-hidden', className)}>
		<div
			className={cn(
				'flex items-center justify-center bg-[#2B4C9C]',
				size === 'small' && 'w-16',
				(size === 'default' || size === 'medium') && 'w-40'
			)}>
			<div
				className={cn(
					'relative flex items-center justify-center',
					size === 'small' && 'h-20 w-12',
					(size === 'default' || size === 'medium') && 'h-32 w-16 md:h-40 md:w-20'
				)}>
				<Image
					sizes={generateSizesString()}
					src='/images/funnel/dutch-license-plate.png'
					alt='Dutch license plate'
					className='object-contain'
					fill
				/>
			</div>
		</div>

		{children}
	</div>
);

export const LicensePlate: FC<LicensePlateProps> = ({ isLoading, license, size = 'default', className }) => {
	return (
		<LicensePlateWrapper size={size} className={className}>
			<Text
				variant={size === 'default' ? 'display-4' : size === 'medium' ? 'display-5' : 'body-m'}
				weight='semibold'
				className={cn(
					'whitespace-nowrap bg-[#ECA80A] text-center',
					size === 'small' && 'w-[110px] px-16 py-8',
					(size === 'default' || size === 'medium') && 'w-full px-32 py-16'
				)}>
				{isLoading || !license ? '. .-. .-. .' : license?.replace(/(\w{2})(?=\w)/g, '$1-').slice(0, 8)}
			</Text>
		</LicensePlateWrapper>
	);
};

export type LicensePlateInputProps = {
	name: string;
	placeholder: string;
	isDisabled?: boolean;
} & LicensePlateBaseProps;

export const LicensePlateInput: FC<LicensePlateInputProps> = forwardRef<HTMLInputElement, LicensePlateInputProps>(
	({ name, placeholder, isDisabled = false, size = 'default', className }, ref) => {
		const { register, setValue, trigger } = useFormContext();

		const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
			const input = event.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');

			if (input.length <= 6) {
				setValue(name, input, { shouldValidate: true });
				event.target.value = input;
			} else {
				const inputMinusOneCharacter = `${input.slice(0, -1)}`;
				setValue(name, inputMinusOneCharacter, { shouldValidate: true });
				event.target.value = inputMinusOneCharacter;
			}
			trigger();
		};

		const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
			if (event.target.value.length == 6) {
				const input = event.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
				event.target.value = formatLicenseplate(input, getSidecodeLicenseplate(input));
			}
		};

		return (
			<LicensePlateWrapper size={size} className={className}>
				<Text
					as='input'
					autoComplete='off'
					type='text'
					variant={size === 'default' ? 'display-4' : size === 'medium' ? 'display-5' : 'body-m'}
					weight='semibold'
					id={name}
					disabled={isDisabled}
					{...register(name)}
					maxLength={8}
					placeholder={placeholder}
					className={cn(
						'placeholder:text-grayscale600 w-full whitespace-nowrap border-transparent bg-[#ECA80A] text-center placeholder:opacity-20 focus:border-transparent focus:ring-0',
						size === 'small' && 'max-w-[110px] px-16 py-8',
						size === 'default' && 'px-32 py-16',
						size === 'medium' && 'px-32 py-12'
					)}
					onBlur={handleBlur}
					onChange={handleChange}
					/* @ts-ignore - force ref to be set */
					ref={ref}
					data-testid='input-license-plate'
				/>
			</LicensePlateWrapper>
		);
	}
);

LicensePlateInput.displayName = 'LicensePlateInput';

import { AccountSchema } from '@monorepo/shared/lib/schemas';
import { PortalAccount, ThemeNames } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

const accountApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getProfile: builder.query<PortalAccount, void>({
			query() {
				return {
					url: `/account`,
					method: 'GET',
				};
			},
			providesTags: ['Profile'],
		}),
		updateProfile: builder.mutation<PortalAccount, AccountSchema>({
			query({ ...payload }) {
				return {
					url: `/account`,
					method: 'PUT',
					body: { ...payload },
				};
			},
			invalidatesTags: ['Profile'],
		}),
		updateIban: builder.mutation<void, { iban: string; terms_agreement: boolean; code: ThemeNames }>({
			query({ ...payload }) {
				return {
					url: `/iban`,
					method: 'PUT',
					body: payload,
				};
			},
			invalidatesTags: ['Contact', 'Profile'],
		}),
		getProfiles: builder.query<PortalAccount[], void>({
			query() {
				return {
					url: `/profiles`,
					method: 'GET',
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetProfilesQuery, useGetProfileQuery, useUpdateProfileMutation, useUpdateIbanMutation } = accountApi;

export const { getProfile, getProfiles, updateProfile, updateIban } = accountApi.endpoints;

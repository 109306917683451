import { zodResolver } from '@hookform/resolvers/zod';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { ButtonGroup, LookupSuggestion } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { addCompanyDataSchema, AddCompanydataSchema } from '@monorepo/shared/lib/schemas';
import { capitalizeFirstLetter } from '@monorepo/shared/lib/utils';
import type { PostalCodeLookupRequest } from '@monorepo/types';

import { selectNavigationState, setNavForward, useLookupQuery } from '@common/store';
import { paths } from '@common/utils';
import { setCompany } from '@funnel/store';

export const AddCompanyDataForm: FC = () => {
	const { t } = useTranslation(['common']);
	const {
		push,
		query: { company },
	} = useRouter();

	const { isNavigating, navDirection } = useSelector(selectNavigationState);
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors, isValid },
		trigger,
	} = useForm<AddCompanydataSchema>({
		mode: 'onBlur',
		resolver: zodResolver(addCompanyDataSchema(t)),
	});

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [request, setRequest] = useState<PostalCodeLookupRequest>();
	const { data, isFetching, isError, isSuccess, isUninitialized } = useLookupQuery(request ?? skipToken);

	const handleOnBlur = () => {
		const {
			address: { postal_code, house_number },
		} = getValues();

		if (postal_code && house_number) {
			setRequest({ postal_code: postal_code.replaceAll(' ', ''), house_number });
		}
	};

	const onSubmit = async (form: AddCompanydataSchema) => {
		dispatch(setCompany(form));
		dispatch(setNavForward());
		await push(paths.funnel.company.confirm);
	};

	useEffect(() => {
		setValue('address.street', isError ? '' : `${data?.street}`);
		setValue('address.city', isError ? '' : `${data?.city}`);
		setIsEdit(false);

		if (!isError && !isUninitialized) {
			trigger();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, setValue, isError]);

	useEffect(() => {
		if (company) {
			setValue('name', capitalizeFirstLetter(decodeURIComponent(`${company}`)));
		}
	}, [company, setValue]);

	return (
		<Form.Context<AddCompanydataSchema>>
			{({}) => {
				return (
					<Form id='add-company-data-form' onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className='space-y-32'>
							<div className='grid grid-cols-4 gap-x-16 gap-y-24 md:gap-y-32'>
								<Form.Group className='col-span-full'>
									<Form.Label>{t('page.funnel.company.addData.form.dossierNumberLabel')}</Form.Label>
									<Form.NumberInput
										fieldSize='lg'
										autoComplete='off'
										name='dossierNumber'
										placeholder={t('page.funnel.company.addData.form.dossierNumberPlaceholder')}
										errors={errors.dossierNumber}
										register={register}
									/>
								</Form.Group>
								<Form.Group className='col-span-full'>
									<Form.Label>{t('page.funnel.company.addData.form.nameLabel')}</Form.Label>
									<Form.TextInput
										fieldSize='lg'
										autoComplete='organization'
										name='name'
										placeholder={t('page.funnel.company.addData.form.namePlaceholder')}
										errors={errors.name}
										register={register}
									/>
								</Form.Group>
								<Form.Group className='col-span-full md:col-span-2'>
									<Form.Label>{t('common.postalCode')}</Form.Label>
									<Form.TextInput
										fieldSize='lg'
										autoComplete='postal-code'
										name='address.postal_code'
										placeholder='____ __'
										errors={errors?.address?.postal_code}
										register={register}
										rules={{ onBlur: handleOnBlur }}
									/>
								</Form.Group>

								<Form.Group className='col-span-2 md:col-span-1'>
									<Form.Label>{t('common.houseNumber')}</Form.Label>
									<Form.NumberInput
										fieldSize='lg'
										name='address.house_number'
										autoComplete='off'
										placeholder={t('common.houseNumber')}
										errors={errors.address?.house_number}
										register={register}
										rules={{ onBlur: handleOnBlur }}
									/>
								</Form.Group>

								<Form.Group className='col-span-2 md:col-span-1'>
									<Form.Label>{t('common.houseNumberAddition')}</Form.Label>
									<Form.TextInput
										fieldSize='lg'
										name='address.house_number_appendix'
										autoComplete='off'
										placeholder={t('common.houseNumberAddition')}
										errors={errors.address?.house_number_appendix}
										register={register}
										rules={{ onBlur: handleOnBlur }}
									/>
								</Form.Group>

								{(isError || isEdit) && (
									<>
										{isError && (
											<div className='col-span-full'>
												<Text>{t('page.funnel.company.change.suggestionNotFound')}</Text>
											</div>
										)}
										<Form.Group className='col-span-full'>
											<Form.Label>{t('common.street')}</Form.Label>
											<Form.TextInput
												autoComplete='street-address'
												name='address.street'
												placeholder={t('common.street')}
												errors={errors.address?.street}
												register={register}
												autoFocus
											/>
										</Form.Group>

										<Form.Group className='col-span-full'>
											<Form.Label>{t('common.city')}</Form.Label>
											<Form.TextInput
												autoComplete='address-level2'
												name='address.city'
												placeholder={t('common.city')}
												errors={errors.address?.city}
												register={register}
											/>
										</Form.Group>
									</>
								)}

								{isSuccess && data && !isEdit && (
									<div className='col-span-full'>
										<LookupSuggestion
											city={data.city}
											number={data.number}
											postcode={data.postcode}
											street={data.street}
											onEdit={() => setIsEdit(true)}
											addition={getValues().address.house_number_appendix}
											isLoading={isFetching}
										/>
									</div>
								)}
							</div>

							<ButtonGroup>
								<StandardButton
									className=''
									type='submit'
									iconRight='angle-right-b'
									variant='primary'
									label={t('common.proceed')}
									title={t('common.proceed')}
									isLoading={isFetching || (isNavigating && navDirection === 'forward')}
									disabled={isFetching || !isValid || isUninitialized || isNavigating}
								/>
							</ButtonGroup>
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';
import {
	IGetProfessionRequest,
	IGetProfessionResponse,
	IPostCalculateAovRequest,
	IPostCalculateAovResponse,
} from '@monorepo/types';

export const directdoorgaanApi = createApi({
	reducerPath: 'directdoorgaan',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/directdoorgaan`,
	}),
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		calculateAov: builder.mutation<IPostCalculateAovResponse, IPostCalculateAovRequest>({
			query(body) {
				return {
					url: `/calculate-aov`,
					method: 'POST',
					body,
				};
			},
		}),
		searchProfession: builder.query<IGetProfessionResponse, IGetProfessionRequest>({
			query(body) {
				return {
					url: `/profession?search=${body.profession}`,
				};
			},
		}),
	}),
});

export const { useCalculateAovMutation, useLazySearchProfessionQuery } = directdoorgaanApi;

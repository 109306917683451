import cn from 'classnames';
import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { getProperty } from '@monorepo/shared/lib/cms';
import { ITextImageSectionVariant, TextImageChecklistImagePosition } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { TextImageChecklistSection } from '@marketing/components/TextImageSectionChecklist/TextImageSectionChecklist';

const Paragraph = (props: ReactMarkdownProps) => <p color='inherit' {...props} />;

export const mdComponents: Components = {
	p: Paragraph,
};

export const handleTextImageChecklist = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const [element] = elements;
	const title = getProperty('title', element.custom_fields);
	const subtext = getProperty('subtext', element.custom_fields);

	const imagePosition: TextImageChecklistImagePosition =
		(getProperty('image-position', element.custom_fields) as TextImageChecklistImagePosition) || 'left';
	const [link] = element.links;
	const [image] = element.media;

	const variant: ITextImageSectionVariant = rootContext?.path?.includes('landing/') ? 'inverted' : 'default';

	const Content = () => (
		<div className='space-y-16'>
			<ReactMarkdown components={mdComponents}>{`${subtext}`}</ReactMarkdown>
		</div>
	);

	const checklists = element.child_elements
		.filter((child_elemend) => child_elemend.identifier === 'checklist')
		.map((child_element) => {
			const title = getProperty('title', child_element.custom_fields);

			return {
				title: `${title}`,
				description: `${child_element.markdown}`,
			};
		});

	return (props) => (
		<TextImageChecklistSection
			{...props}
			checklist={checklists}
			content={<Content />}
			className={cn(rootContext?.path?.includes('over-maia') && 'bg-primary50')}
			title={`${title}`}
			link={link}
			image={image}
			variant={variant}
			imagePosition={imagePosition}
		/>
	);
};

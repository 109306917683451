import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import Link from 'next/link';
import { FC } from 'react';

import { StandardButton } from '@monorepo/components/DataEntry';
import { Icon } from '@monorepo/components/Misc';
import { SvgCircle } from '@monorepo/components/Misc/Doodles/Circle/Circle';
import { Text } from '@monorepo/components/TextComponents';
import { INicheOverviewCard } from '@monorepo/types';

type NicheOverviewCardFeaturedProps = INicheOverviewCard;

export const NicheOverviewCardFeatured: FC<NicheOverviewCardFeaturedProps> = ({ icon, title, description, link }) => {
	return (
		<div className='bg-primaryMain md:p-50 rounded-16 relative grid grid-cols-3 overflow-hidden px-24 py-32'>
			<div className='col-span-full md:col-span-2'>
				<div className='space-y-32 md:space-y-40'>
					<div className='space-y-12 md:space-y-16'>
						<div className='relative w-fit md:hidden'>
							<SvgCircle theme='maia' className='text-info300 absolute bottom-4 left-0 h-12 w-12' />
							<Icon name={icon} size='xxl' color='secondaryMain' className='relative z-10' />
							<SvgCircle theme='maia' className='w-2h-20 text-info300 absolute right-0 top-4 h-20' />
						</div>
						<Text as='h3' variant='display-4' weight='semibold' color='grayscale0'>
							{parse(title)}
						</Text>
						<div className='space-y-8'>{typeof description === 'string' ? parse(description) : description}</div>
					</div>
					<Link href={link.href} title={link.title} target={link.target} className='inline-block'>
						<StandardButton variant='ctaInfo' label={link.content || link.title} className='w-full md:w-fit' />
					</Link>
				</div>
			</div>
			<div className='col-span-full max-md:hidden'>
				<motion.div
					key={icon}
					initial={{ scale: 0, rotate: 0 }}
					animate={{ scale: 1, rotate: 15 }}
					transition={{ duration: 0.3, delay: 0.2 }}
					className='rotate-15 absolute -right-[10%] -top-[20%] flex h-[530px] w-[530px] items-center justify-center'>
					<Icon name={icon} size='custom' color='secondaryMain' className='text-[520px]' />
				</motion.div>
			</div>
		</div>
	);
};

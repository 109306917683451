import cn from 'classnames';
import { FC, HTMLProps, PropsWithChildren } from 'react';

type FormGroupProps = {
	isRendered?: boolean;
	isShown?: boolean;
} & HTMLProps<HTMLDivElement>;

export const FormGroup: FC<PropsWithChildren<FormGroupProps>> = ({
	children,
	className,
	isRendered = true,
	isShown = true,
	...props
}) => {
	return isRendered ? (
		<div className={cn('w-full', !isShown && 'hidden', className)} {...props}>
			{children}
		</div>
	) : null;
};

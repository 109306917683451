import { ComponentProps, ComponentPropsWithoutRef, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Checkbox } from '../../atoms/checkbox/checkbox';
import { card, CardVariantProps } from '../../patterns/card';

type CheckboxListProps = {
	items: Array<ComponentProps<typeof Checkbox>>;
	hierarchy?: CardVariantProps['hierarchy'];
} & ComponentPropsWithoutRef<'ul'>;

export function CheckboxList(props: CheckboxListProps) {
	const { items, hierarchy, className, ...rest } = props;

	if (!items.length) null;

	return (
		<ul className={twMerge(card({ hierarchy }), className)} {...rest}>
			{items.map((item) => {
				return (
					<li>
						<Checkbox {...item} />
					</li>
				);
			})}
		</ul>
	);
}

import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

import { NavItem } from '@monorepo/types';

import { NavbarMenuItem } from '../NavBarMenuItem/NavbarMenuItem';
import { NavbarMenuLink } from '../NavbarMenuLink/NavbarMenuLink';

type MenuSectionProps = {
	items: NavItem[];
};

const variants = {
	hidden: { opacity: 0, y: -20 },
	visible: (i: number) => ({
		opacity: 1,
		y: 0,
		transition: {
			delay: i * 0.1, // Staggered delay for each item
		},
	}),
};

export const NavBarMenuSection: FC<MenuSectionProps> = ({ items }) => {
	return (
		<AnimatePresence>
			<motion.div className='relative flex flex-row space-x-64 pb-20 max-md:hidden' initial='hidden' animate='visible'>
				{items.map((item, index) => (
					<motion.div
						key={`nav-bottom-item-${index}`}
						custom={index} // Used for staggered delay
						variants={variants}>
						{item.type === 'menuItem' && <NavbarMenuItem item={item} />}
						{item.type === 'link' && <NavbarMenuLink item={item} />}
					</motion.div>
				))}
			</motion.div>
		</AnimatePresence>
	);
};

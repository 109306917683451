import classNames from 'classnames';
import cn from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { HeadlessStepper } from '@monorepo/components/Headless/Stepper/Stepper';
import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { IWhyChooseMaia } from '@monorepo/types';

type WhyChooseMaiaProps = IWhyChooseMaia & {
	className?: string;
};

export const WhyChooseMaia: FC<WhyChooseMaiaProps> = ({ title, description, mobileImage, items, className }) => {
	return (
		<section className={cn(className, 'md:pb-104 py-48')}>
			<LayoutGrid as='div' layout='marketing'>
				<div className='col-start-2 grid grid-cols-4 gap-48'>
					<div className='col-span-full flex flex-col items-center space-y-8 md:col-span-1 md:items-start md:space-y-16'>
						<Text variant='display-4' weight='semibold' color='primaryMain'>
							{title}
						</Text>
						<Text variant='body-l' weight='regular' color='grayscale400'>
							{description}
						</Text>
					</div>
					<div className='col-span-full flex justify-center md:hidden'>
						<HeadlessStepper activeStep={3} className='flex flex-col space-y-24'>
							{items.map(({ text }, index) => (
								<HeadlessStepper.Step step={index} key={`why-choose-maia-mobile-${index}`}>
									<div className='relative flex items-center space-x-24'>
										<div
											className={classNames(
												index < items.length - 1 &&
													'before:bg-primaryMain before:absolute before:left-1/2 before:top-[23px] before:h-24 before:w-[1px] before:-translate-x-[0.5px]',
												'border-primaryMain bg-grayscale0 relative z-10 flex h-24 w-24 items-center justify-center rounded-full border'
											)}>
											{index + 1}
										</div>
										<Text variant='body-l' weight='medium' color='primaryMain'>
											{text}
										</Text>
									</div>
								</HeadlessStepper.Step>
							))}
						</HeadlessStepper>
					</div>
					<div className='col-span-full md:hidden'>
						<div className='relative h-[250px] w-full'>
							<Image sizes={generateSizesString()} src={mobileImage.image} alt={mobileImage.alt} fill />
						</div>
					</div>
					{items.map((item, key) => (
						<div
							className='col-span-1 flex flex-col items-center justify-start space-y-12 text-center max-md:hidden'
							key={`why-choose-maia-${key}`}>
							<div className='relative h-[250px] w-full'>
								<Image sizes={generateSizesString()} src={item.image} alt={item.alt} fill />
							</div>

							<div className='border-primaryMain bg-grayscale0 flex h-40 w-40 items-center justify-center rounded-full border'>
								<Text variant='display-5' weight='semibold' color='primaryMain'>
									{key + 1}
								</Text>
							</div>
							<Text variant='display-5' weight='regular' color='primaryMain'>
								{item.text}
							</Text>
						</div>
					))}
				</div>
			</LayoutGrid>
		</section>
	);
};

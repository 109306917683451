import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

import { TextButton } from '../../../DataEntry';
import { Text } from '../../../TextComponents';

type DetailsCardProps = {
	title: string;
	hideTitle?: boolean;
	isDisabled?: boolean;
	isLoading?: boolean;
	className?: string;
	isCard?: boolean;
	onEdit?: () => void;
};

export const DetailsCard: FC<PropsWithChildren<DetailsCardProps>> = ({
	title,
	hideTitle = false,
	isCard = true,
	children,
	className = '',
	isDisabled,
	isLoading,
	onEdit,
}) => {
	return (
		<div className={cn(className, 'flex flex-col gap-y-8')}>
			{(!hideTitle || onEdit) && (
				<div className='flex items-center justify-between'>
					{!hideTitle && (
						<Text variant='body-s' weight='medium' color='grayscale600'>
							{title}
						</Text>
					)}
					{onEdit && (
						<TextButton
							label='Wijzig'
							iconRight='pen'
							size='xs'
							onClick={onEdit}
							disabled={isDisabled}
							isLoading={isLoading}
						/>
					)}
				</div>
			)}

			{isCard ? (
				<div className='md:rounded-12 border-grayscale100 rounded-6 space-y-12 border p-20'>{children}</div>
			) : (
				children
			)}
		</div>
	);
};

import { CalculationProductOptionSpecWithGuid } from '@monorepo/types';

export const buildSpecKey = (spec: Partial<CalculationProductOptionSpecWithGuid>, includeValue: boolean = true) => {
	const baseKey = `${spec.is_insured_amount}-${spec.insured_amount_type}-${spec.is_deductible}-${spec.deductible_type}-${spec.reference}`;
	return includeValue ? `${baseKey}-${spec.value}` : baseKey;
};

export const isSameSpec = (
	specA: Partial<CalculationProductOptionSpecWithGuid>,
	specB: Partial<CalculationProductOptionSpecWithGuid>
) => buildSpecKey(specA) === buildSpecKey(specB);

export const isSameSpecWithoutValue = (
	specA: Partial<CalculationProductOptionSpecWithGuid>,
	specB: Partial<CalculationProductOptionSpecWithGuid>
) => buildSpecKey(specA, false) === buildSpecKey(specB, false);

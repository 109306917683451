import cn from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';

import { NavItem } from '@monorepo/types';

import { anchorClassName, underlineClassName } from '../../../../../lib/utils';
import { ActionButton } from '../../../../DataEntry';
import { Text } from '../../../../TextComponents';

type NavBarItemProps = {
	item: NavItem;
	className?: string;
};

/**
 * The component used within the NavbarMenu (which appear on hover or on click)
 */
export const NavBarItem: FC<NavBarItemProps> = ({ item, className }) => {
	return (
		<Link
			href={item.href}
			title={item.value}
			className={cn(anchorClassName, className, 'flex w-fit !flex-row items-center space-x-16 md:space-x-12')}>
			{item?.icon && <ActionButton icon={item.icon} />}
			<div className='relative flex flex-col'>
				<Text variant='body-s' color='primaryMain' weight='medium'>
					{item.value}
				</Text>

				{item?.subtext && (
					<Text variant='body-xs' color='grayscale300'>
						{item.subtext}
					</Text>
				)}

				<span className={underlineClassName}></span>
			</div>
		</Link>
	);
};

import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { BaseCard } from '@monorepo/components/DataDisplay';
import { TextButton } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { useAnalytics } from '@monorepo/shared/lib/hooks';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { DetailInfoItem } from '@monorepo/types';

import { selectTheme } from '@common/store';

type DetailInfoCardProps = DetailInfoItem & {
	className?: string;
};

export const DetailInfoCard: FC<DetailInfoCardProps> = ({ title, description, link, image, className }) => {
	const { trackEvent } = useAnalytics();
	const theme = useSelector(selectTheme);

	const onClick = () => {
		trackEvent('start_funnel', { sales_channel: theme });
	};

	return (
		<BaseCard className={cn(className, 'bg-grayscale0')}>
			<Link
				onClick={onClick}
				href={link.href}
				title={link.title}
				className={cn('flex w-full flex-col space-y-24 p-24')}>
				<div className='relative h-80 w-80'>
					<Image sizes={generateSizesString()} src={image.path} alt={image.alt} fill className='object-contain' />
				</div>
				<Text as='h3' variant='display-6' color='primaryMain' weight='semibold'>
					{title}
				</Text>
				<Text as='p' variant='body-s' color='grayscale400' className='grow'>
					{description}
				</Text>
				<div>
					<TextButton label={link.content || link.title} iconRight='arrow-right' />
				</div>
			</Link>
		</BaseCard>
	);
};

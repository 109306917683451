import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { FC, ReactNode } from 'react';

import { generateSizesString } from '../../../../../lib/utils';
import { Text } from '../../../../TextComponents';

type BlogSectionHeroProps = {
	title: string | string[] | ReactNode;
	subTitle: string;
	avatarImageSrc: string;
	className?: string;
	author: string;
	lastUpdate: string;
};

export const BlogSectionHero: FC<BlogSectionHeroProps> = ({
	title,
	subTitle,
	avatarImageSrc,
	author,
	lastUpdate,
	className = '',
}) => {
	const { t } = useTranslation(['common']);

	return (
		<section
			className={cn(
				className,
				'before:bg-primary50 col-span-full mx-auto mb-16 flex h-[350px] w-full max-w-[1110px] flex-col space-x-64 pt-32 before:absolute before:left-0 before:top-[0px] before:-z-10 before:h-[inherit] before:w-full sm:mb-24 sm:h-[300px] sm:before:top-[80px] md:h-[337px] md:flex-row md:pt-64'
			)}>
			<div className='z-10 flex basis-full flex-col space-y-40'>
				<div className='space-y-24'>
					<Text as='h1' variant='display-2' color='grayscale600' weight='semibold'>
						{title}
					</Text>
					<Text as='p' variant='body-l' color='grayscale400' className='max-md:line-clamp-3'>
						{subTitle}
					</Text>
				</div>

				<div className='bg-grayscale0 rounded-t-12 !mt-auto flex w-fit flex-row items-center gap-x-8 px-24 pt-12'>
					<div className='relative h-[44px] min-h-[44px] w-[44px]'>
						<Image
							sizes={generateSizesString()}
							src={avatarImageSrc}
							priority={true}
							quality={100}
							alt={'references'}
							className='rounded-full'
							fill
						/>
					</div>
					<div className='flex flex-col'>
						<Text as='p' variant='body-m' weight='semibold' color='grayscale300'>
							{author}
						</Text>
						<Text as='span' variant='body-s' weight='regular' color='grayscale300'>
							{t('common.updatedAt', { lastUpdate })}
						</Text>
					</div>
				</div>
			</div>
		</section>
	);
};

import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

type FormRadioListElevation = 'flat' | 'elevated';
type FormRadioListRadius = 'main' | 'embedded';

type FormRadioListProps = {
	elevation?: FormRadioListElevation;
	radius?: FormRadioListRadius;
	className?: string;
};

export const FormRadioList: FC<PropsWithChildren<FormRadioListProps>> = ({
	elevation = 'flat',
	radius = 'main',
	children,
	className,
}) => {
	return (
		<div
			className={classNames(
				'space-y-24 px-16 py-20 sm:p-20',
				elevation === 'flat' && 'border-grayscale200 border',
				elevation === 'elevated' && 'shadow-1',
				radius === 'main' && 'rounded-18',
				radius === 'embedded' && 'rounded-12',
				className
			)}>
			{children}
		</div>
	);
};

import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { BillingUnits } from '@monorepo/types';

import { useIsMobile } from '../../../../lib/hooks';
import { transformToCurrency } from '../../../../lib/utils';
import { StandardButton } from '../../../DataEntry';
import { Divider, Icon } from '../../../Misc';
import { EmphasizedPriceFormat, Text } from '../../../TextComponents';
import { BaseCard } from '../BaseCard/BaseCard';

type Props = {
	price: number;
	tax?: number;
	ctaText: string;
	onCtaClick: () => void;
	ctaLoading?: boolean;
	billingUnit?: BillingUnits;
	className?: string;
};

export const UspCard: FC<Props> = ({
	price,
	tax,
	ctaText,
	onCtaClick,
	ctaLoading = false,
	billingUnit = 'monthly',
	className = '',
}) => {
	const { t } = useTranslation(['common']);
	const { isSmallTablet } = useIsMobile();
	const uspItems = [
		{
			title: t('components.uspCard.usps.1'),
			icon: 'pen',
		},
		{
			title: t('components.uspCard.usps.2'),
			icon: 'clock-eight',
		},
		{
			title: t('components.uspCard.usps.3'),
			icon: 'user-minus',
		},
	];

	return (
		<BaseCard
			className={cn(
				className,
				'bg-grayscale0 pointer-events-none flex flex-col space-y-16 p-20 max-md:rounded-b-none md:p-24'
			)}>
			{!isSmallTablet && (
				<>
					<div className='flex flex-col space-y-16 max-md:hidden'>
						{uspItems.map(({ title, icon }, index) => (
							<div key={`uspcard-item-${index}`} className='flex flex-row items-center space-x-8'>
								<Icon name={icon} color='grayscale500' size='sm' className='leading-none' />
								<Text variant='body-xs' weight='medium' color='grayscale500'>
									{title}
								</Text>
							</div>
						))}
					</div>
					<Divider className='max-md:hidden' />
				</>
			)}
			<div className='flex flex-col space-y-8'>
				<EmphasizedPriceFormat
					leadingText={t('components.emphasizedPriceFormat.emphasizedPriceLeadingTextFrom')}
					price={price}
					afterText={t(billingUnit === 'monthly' ? 'common.perMonthShort' : 'common.perYearShort')}
				/>
				{tax && (
					<Text variant='body-xs' color='grayscale400'>
						{t('components.uspCard.inclTax', { tax: transformToCurrency(tax) })}
					</Text>
				)}
			</div>

			<StandardButton
				isLoading={ctaLoading}
				disabled={ctaLoading}
				iconRight='angle-right-b'
				className='pointer-events-auto w-full'
				variant='cta'
				label={ctaText}
				onClick={onCtaClick}
			/>
		</BaseCard>
	);
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@common/store';
import { cartOrganizationApi } from '@funnel/store';
import { checkupApi } from '@portal/store/slices/checkup/checkup.api';

type SelectedClassification = {
	name: string;
	guid: string;
};

export type ClassificationsSliceState = {
	selectedClassifications: SelectedClassification[];
};

// Initial state
const initialState: ClassificationsSliceState = {
	selectedClassifications: [],
};

// Actual Slice
export const classificationsSlice = createSlice({
	name: 'classifications',
	initialState,
	reducers: {
		// Action to set the cart
		setClassifications(state, action: PayloadAction<SelectedClassification[]>) {
			state.selectedClassifications = action.payload;
		},
		addClassification(state, action: PayloadAction<SelectedClassification>) {
			state.selectedClassifications = [...state.selectedClassifications, action.payload];
		},
		removeClassification(state, action: PayloadAction<string>) {
			state.selectedClassifications = [...state.selectedClassifications].filter((c) => c.guid !== action.payload);
		},
	},
	extraReducers: (builder) => {
		// B2B Cart Query
		builder.addMatcher(cartOrganizationApi.endpoints.getCartOrganization.matchFulfilled, (state, action) => {
			const copy: SelectedClassification[] = [...action.payload.classifications].map((classification) => ({
				guid: classification.guid,
				name: classification.name,
			}));

			state.selectedClassifications = [...copy];
		});

		builder.addMatcher(checkupApi.endpoints.getRecalculationClassifications.matchFulfilled, (state, action) => {
			const copy: SelectedClassification[] = [...action.payload].map((classification) => ({
				guid: classification.guid,
				name: classification.description,
			}));

			state.selectedClassifications = [...copy];
		});
	},
});

export const { setClassifications, addClassification, removeClassification } = classificationsSlice.actions;

export const selectClassifications = (state: AppState) => state.classifications.selectedClassifications;

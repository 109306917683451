import { ISchoutenSection } from '@monorepo/types';

export const mockSchoutenSection: ISchoutenSection = {
	title: 'a schouten zekerheid company',
	images: [
		{
			alt: 'slide-1-alt',
			path: '/images/marketing/cta-search-background.png',
		},
		{
			alt: 'slide-2-alt',
			path: '/images/marketing/cta-search-background.png',
		},
		{
			alt: 'slide-3-alt',
			path: '/images/marketing/cta-search-background.png',
		},
		{
			alt: 'slide-4-alt',
			path: '/images/marketing/cta-search-background.png',
		},
		{
			alt: 'slide-5-alt',
			path: '/images/marketing/cta-search-background.png',
		},
		{
			alt: 'slide-6-alt',
			path: '/images/marketing/cta-search-background.png',
		},
	],
	subtitle: 'Een innovatieve insurtech ontstaan vanuit Schouten Zekerheid',
	description:
		'Māia is in 2022 opgericht en is ontstaan vanuit Schouten Zekerheid, met meer dan 70 jaar aan waardevolle ervaring. Het Māia-team staat iedere dag met een glimlach voor je klaar om de wereld van verzekeren simpeler, begrijpelijker en makkelijker te maken. Ons doel is helder: het herdefiniëren van zakelijke verzekeringen door ervaring te combineren met vernieuwing.',
	bulletList: [
		'Meer dan 70 jaar ervaring in het verzekeringsvak',
		'Onze klanten vertrouwen ons met 100.000 verzekeringen',
		'Experts in verzekeringen voor MKB’ers en ZZP’ers',
	],
	logo: {
		alt: 'logo-alt',
		path: '/images/marketing/logos/schouten-zekerheid.svg',
	},
};

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { CreateUser } from '@monorepo/types';

import { rootAuthApi } from '../../root/root.api';

const userApi = rootAuthApi.injectEndpoints({
	endpoints: (builder) => ({
		saveUser: builder.mutation<void, CreateUser>({
			query(payload) {
				return {
					url: `/save-user`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: ['Auth'],
			transformErrorResponse: (error: FetchBaseQueryError) => {
				/* istanbul ignore next */
				return {
					status: error.status,
					data: error.data,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useSaveUserMutation } = userApi;

export const { saveUser } = userApi.endpoints;

import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, memo } from 'react';

import { InsuranceBlurb } from '@monorepo/components/DataDisplay';
import { StandardButton, TextButton } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { useAnalytics } from '@monorepo/shared/lib/hooks';
import { CmsLink } from '@monorepo/types';

import { paths } from '@common/utils';

type Props = {
	title: string;
	coverages: { icon: string; title: string }[];
	link: CmsLink;
	className?: string;
};

export const DynamicOverviewCompositionCard: FC<Props> = memo(({ title, coverages, link, className }) => {
	const { t } = useTranslation(['common']);

	const { push } = useRouter();
	const { trackEvent } = useAnalytics();

	const handleChooseProduct = () => {
		trackEvent('start_funnel', { sales_channel: 'maia' });
		push(paths.consumerFunnel.root('motorverzekering'));
	};

	const handleMoreInformation = () => {
		push(link.href);
	};

	return (
		<div className={classNames('rounded-18 shadow-2 space-y-20 p-24 md:space-y-24', className)}>
			<Text as='h3' variant='display-5' weight='semibold' color='grayscale600' className='flex-grow'>
				{title}
			</Text>

			<div className='space-y-8'>
				{coverages && coverages.length > 0 && (
					<>
						<Text variant='body-s' weight='regular' color='grayscale400'>
							{t('components.MultipleProductCard.compositionContains')}
						</Text>

						<ul className='space-y-8'>
							{coverages &&
								coverages.map((feature) => (
									<li key={`insurance-blurb-required-${title}-${feature.title}`}>
										<InsuranceBlurb
											name={feature.title}
											guid={'feature.title'}
											icon_name={feature.icon}
											isConsumer={true}
										/>
									</li>
								))}
						</ul>
					</>
				)}
			</div>

			<div className='flex flex-col gap-y-8'>
				<StandardButton
					variant='cta'
					iconRight='angle-right'
					label={'Bereken je premie'}
					title={'Bereken je premie'}
					className='mt-16 w-full'
					onClick={handleChooseProduct}
				/>
				<TextButton
					iconRight='angle-right'
					label={link.content || link.title}
					title={link.title}
					className='w-full'
					onClick={handleMoreInformation}
				/>
			</div>
		</div>
	);
});

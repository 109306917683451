import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';

export type BenefitListCardProps = {
	className?: string;
};
export const BenefitListCard: FC<BenefitListCardProps> = () => {
	const { t } = useTranslation(['common']);
	const uspItems = [
		{
			title: t('components.benefitListCard.items.1'),
			icon: 'award-alt',
		},
		{
			title: t('components.benefitListCard.items.2'),
			icon: 'clock-eight',
		},
		{
			title: t('components.benefitListCard.items.3'),
			icon: 'calendar-alt',
		},
	];

	return (
		<div className='flex w-full flex-col justify-between space-y-8 sm:flex-row sm:space-x-4 sm:space-y-0'>
			{uspItems.map(({ title, icon }, index) => (
				<div key={`benefit-list-card-item-${index}`} className='flex flex-row items-center space-x-8 sm:items-start'>
					<Icon name={icon} color='grayscale300' size='md' className='leading-none' />
					<Text variant='body-s' weight='regular' color='grayscale300'>
						{title}
					</Text>
				</div>
			))}
		</div>
	);
};

import { INicheCollaborateSection } from '@monorepo/types';

export const mockNicheCollaborateSection: INicheCollaborateSection = {
	title: 'In samenwerking met professionals ontwikkeld',
	subtitle:
		'Verzekeringen op maat, samen ontwikkeld met branche verenigingen voor de beste bescherming van jouw vakmanschap.',
	niches: [
		{
			image: {
				path: '/images/marketing/cta-search-background.png',
				alt: 'alt-1',
			},
			title: 'Vastgoed & Taxatie',
			icon: 'edit-alt',
			bulletpoints: [
				'Makelaar',
				'Taxateur',
				'Vastgoedbeheerder',
				'VVE beheerder',
				'Rentmeester',
				'Bouwkundig inspecteur',
			],
			link: {
				content: 'Ontdek meer',
				href: 'https://maia.insure',
				title: 'Ontdek meer',
			},
		},
		{
			image: {
				path: '/images/marketing/cta-search-background.png',
				alt: 'alt-1',
			},
			title: 'Haar & Beauty',
			icon: 'edit-alt',
			bulletpoints: ['Kapper', 'Barbier', 'Manicure & pedicure', 'Schoonheidsspecialist', 'Zonnebankstudio'],
			link: {
				content: 'Ontdek meer',
				href: 'https://maia.insure',
				title: 'Ontdek meer',
			},
		},
		{
			image: {
				path: '/images/marketing/cta-search-background.png',
				alt: 'alt-1',
			},
			title: 'Onderhoud & Afwerking',
			icon: 'edit-alt',
			bulletpoints: ['Behanger', 'Stukadoor', 'Glaszetter', 'Schilder'],
			link: {
				content: 'Ontdek meer',
				href: 'https://maia.insure',
				title: 'Ontdek meer',
			},
		},
		{
			image: {
				path: '/images/marketing/cta-search-background.png',
				alt: 'alt-1',
			},
			title: 'Bouw & aannemers',
			icon: 'edit-alt',
			bulletpoints: ['???', '???', '???', '???', '???', '???'],
			link: {
				content: 'Ontdek meer',
				href: 'https://maia.insure',
				title: 'Ontdek meer',
			},
		},
		{
			image: {
				path: '/images/marketing/cta-search-background.png',
				alt: 'alt-5',
			},
			title: 'Algemene ondernemers',
			icon: 'edit-alt',
			bulletpoints: ['???', '???', '???', '???', '???', '???'],
			link: {
				content: 'Ontdek meer',
				href: 'https://maia.insure',
				title: 'Ontdek meer',
			},
		},
	],
};

import {
	applyActionCode,
	Auth,
	checkActionCode,
	getAdditionalUserInfo,
	GoogleAuthProvider,
	isSignInWithEmailLink,
	OAuthProvider,
	signInWithEmailLink,
	signInWithPopup,
	updateEmail,
} from 'firebase/auth';
import { useLocalStorage } from 'react-use';

import { useFirebaseAuth } from '@common/providers';
import { formatError, logger } from '@common/utils';

export const useLogin = () => {
	const LOGIN_EMAIL_LOCAL_STORAGE_KEY = 'user_email';

	const auth = useFirebaseAuth();
	const [email, setEmail] = useLocalStorage(LOGIN_EMAIL_LOCAL_STORAGE_KEY);

	const isLoginLink = (url = window.location.href) => {
		return isSignInWithEmailLink(auth as Auth, url);
	};

	const loginUserWithLink = async (email: string) => {
		return signInWithEmailLink(auth as Auth, email).then((cred) => getAdditionalUserInfo(cred));
	};

	const loginUserWithGoogle = async () => {
		const provider = new GoogleAuthProvider();
		await signInWithPopup(auth as Auth, provider);
	};

	const loginUserWithMicrosoft = async () => {
		const provider = new OAuthProvider('microsoft.com');
		await signInWithPopup(auth as Auth, provider);
	};

	const changeEmail = async (newEmail: string) => {
		const user = auth?.currentUser;

		if (user) {
			try {
				await updateEmail(user, newEmail);
			} catch (error) {
				logger.error('Error changing user email', { error: formatError(error) });
				throw new Error('Error changing user email');
			}
		}
	};

	const setLoginEmailForMagicLink = (newEmail: string) => {
		setEmail(newEmail);
	};

	const removeLoginEmailForMagicLink = () => {
		localStorage.removeItem(LOGIN_EMAIL_LOCAL_STORAGE_KEY);
	};

	const isActionCode = (code: string) => {
		return checkActionCode(auth as Auth, code);
	};

	const verifyEmail = async (code: string) => {
		return applyActionCode(auth as Auth, code);
	};

	return {
		userLoggedIn: !!auth?.currentUser,
		email,
		changeEmail,
		isLoginLink,
		loginUserWithLink,
		loginUserWithGoogle,
		loginUserWithMicrosoft,
		setLoginEmailForMagicLink,
		removeLoginEmailForMagicLink,
		isActionCode,
		verifyEmail,
	};
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const objectOwnershipSchema = (t: TranslationType) =>
	z.object({
		'object-first-owner': z.enum(['J', 'N'], {
			required_error: t('validation.required'),
		}),
	});

export type ObjectOwnershipSchema = z.infer<ReturnType<typeof objectOwnershipSchema>>;

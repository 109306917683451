import { faker } from '@faker-js/faker/locale/nl';

import { HistoricalClaim } from '@monorepo/types';

export function mockGetHistoricalClaim(): HistoricalClaim {
	return {
		guid: faker.string.alphanumeric(16),
		// @ts-ignore - Add this here for mocks?
		product: {
			guid: faker.string.alphanumeric(16),
			name: faker.commerce.productName(),
			code: faker.string.alphanumeric(),
		},
		product_guid: faker.string.alphanumeric(16),
		year: 2022,
		amount: 1500,
		explanation: faker.lorem.words(10),
	};
}

export function mockGetHistoricalClaims(num = 2): HistoricalClaim[] {
	const claims: HistoricalClaim[] = [];
	for (let i = 0; i < num; i++) {
		claims.push(mockGetHistoricalClaim());
	}
	return claims;
}

import { z } from 'zod';

import { TranslationType } from '@monorepo/types'; // Adjust the import path as needed

export const cancelCurrentInsuranceSchema = (t: TranslationType) =>
	z.object({
		guid: z.string().optional(),
		product_guid: z.string().min(1, t('validation.required')),
		insurer_code: z.string().min(1, t('validation.required')),
		policy_identifier: z.string().min(1, t('validation.required')),
	});

export type CancelCurrentInsuranceSchema = z.infer<ReturnType<typeof cancelCurrentInsuranceSchema>>;

import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, Fragment, ReactNode, useEffect } from 'react';

import { MenuItem } from '@monorepo/types';

import { slugify } from '../../../../lib/utils';
import { ActionButton } from '../../../DataEntry';
import { Divider, Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { MyMaiaMenuItem } from '../MyMaiaMenuItem/MyMaiaMenuItem';

type MobilePortalMenuPanelProps = {
	isOpen: boolean;
	onClose: () => void;
	adminMenuItems?: MenuItem[];
	menuItemGroups: MenuItem[][];
	logoutHref: string;
	chat?: ReactNode;
};

export const MobilePortalMenuPanel: FC<MobilePortalMenuPanelProps> = ({
	isOpen,
	onClose,
	menuItemGroups,
	adminMenuItems = [],
	chat,
}) => {
	const { t } = useTranslation(['common']);
	const router = useRouter();

	useEffect(() => {
		onClose();
	}, [router.pathname]);

	return (
		<div className='max-w-md:hidden pointer-events-none fixed inset-0 z-50'>
			<div
				className={cn(
					'shadow-2 p-24-safe bg-grayscale0 border-grayscale100 pointer-events-auto fixed right-0 top-0 z-50 flex h-full w-9/12 flex-col border-l duration-300 ease-in-out sm:w-3/5',
					isOpen ? 'translate-x-0' : 'translate-x-full'
				)}>
				<ActionButton title={t('close')} icon='times' className='absolute right-24 top-24' onClick={onClose} />

				<div className='mt-56 w-full flex-1 space-y-3'>
					{menuItemGroups.map((menuItemGroup, key) => (
						<Fragment key={`mobile-menu-group-${key}`}>
							<ul className='space-y-12'>
								{menuItemGroup.map((menuItem) => (
									<li key={`mobile-menu-group-${key}-${menuItem.pathname}`}>
										<MyMaiaMenuItem to={menuItem.pathname} text={menuItem.title} icon={menuItem.icon} />
									</li>
								))}
							</ul>
							{key < menuItemGroups.length - 1 && <Divider className='my-20 mr-32' />}
						</Fragment>
					))}
					{adminMenuItems && adminMenuItems.length > 0 && (
						<>
							<Divider className='my-20 mr-32' />
							<ul className='space-y-12'>
								<li>
									<div className='group flex w-full flex-row items-center py-4 2xl:relative 2xl:overflow-hidden 2xl:pr-32'>
										<div className='flex w-full items-center space-x-12 2xl:flex-row-reverse 2xl:space-x-reverse'>
											<Icon name='user-md' size='md' color='grayscale600' />
											<Text weight='medium' variant='body-s'>
												Super Admin
											</Text>
										</div>
									</div>
								</li>
								{adminMenuItems.map(({ title, pathname, icon }, index) => (
									<li key={`superadmin-mobile-menu-item-${slugify(pathname)}-${index}`}>
										<MyMaiaMenuItem to={pathname} text={title} icon={icon} />
									</li>
								))}
							</ul>
						</>
					)}
				</div>

				{chat && <div className='mt-auto w-full space-y-12'>{chat}</div>}
			</div>
			<div
				className={cn(
					'bg-overlayColor fixed inset-0 -z-10 transition-all duration-300 ease-in-out',
					isOpen
						? 'pointer-events-auto cursor-pointer opacity-100 backdrop-blur'
						: 'pointer-events-none opacity-0 backdrop-blur-0'
				)}
				onClick={onClose}
			/>
		</div>
	);
};

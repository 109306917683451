import { SupportedInsurance, TaskUrgency } from '@monorepo/types';

import { insuranceOrder } from '../insurance/insurance';
import { taskUrgencyOrder } from '../task/task';

export const sortAlphabetically = (a: string, b: string): number => {
	const lowerA = a.toLowerCase();
	const lowerB = b.toLowerCase();

	if (lowerA < lowerB) {
		return -1;
	}

	if (lowerA > lowerB) {
		return 1;
	}

	return 0;
};

export const sortAscending = (a: number, b: number): number => {
	if (a < b) {
		return -1;
	}

	if (a > b) {
		return 1;
	}

	return 0;
};

export const sortDate = (a: string | Date, b: string | Date, direction: 'asc' | 'desc' = 'asc'): number => {
	if ((direction === 'asc' && a < b) || (direction === 'desc' && a > b)) {
		return -1;
	}

	if ((direction === 'asc' && a > b) || (direction === 'desc' && a < b)) {
		return 1;
	}

	return 0;
};

export const sortDescending = (a: number, b: number): number => {
	if (a > b) {
		return -1;
	}

	if (a < b) {
		return 1;
	}

	return 0;
};

export const sortProducts = (nameA: SupportedInsurance, nameB: SupportedInsurance) => {
	return insuranceOrder[nameA] - insuranceOrder[nameB];
};

export const sortTasks = (urgency1: TaskUrgency, urgency2: TaskUrgency) => {
	return taskUrgencyOrder[urgency2] - taskUrgencyOrder[urgency1];
};

export const sortReviewsByReviewLength = (reviewA: string, reviewB: string) => {
	return reviewB.length - reviewA.length;
};

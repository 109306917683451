import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { NicheCtaItem } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { NicheCtaSection } from '@marketing/components';

export const handleSectionWorkfields = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const [element] = elements,
		title = getProperty('title', element.custom_fields),
		audiences = getProperty('audiences', element.custom_fields),
		niches = `${audiences}`.trim().split('|'),
		items: NicheCtaItem[] = element.child_elements.map((item) => {
			const itemTitle = getProperty('title', item.custom_fields);
			return {
				link: {
					value: `${itemTitle}`,
					href: `${item.links?.[0].href}`,
					title: `${item.links?.[0].title}`,
				},
				image: {
					src: `${item.media?.[0].path}`,
					alt: `${item.media?.[0].alt}`,
				},
			};
		});

	const showSearchCta = rootContext?.path === 'home';

	return (props) => (
		<NicheCtaSection {...props} showSearchCta={showSearchCta} title={`${title}`} niches={niches} items={items} />
	);
};

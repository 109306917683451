import { ICtaImageList } from '@monorepo/types';

export const mockCtaImageListSection: ICtaImageList = {
	title: 'Met een gerust hart gewoon jouw ding doen',
	items: Array.from({ length: 3 }).map(() => ({
		content: 'Wereldwijd verzekerd tot wel € 2.500.000',
	})),
	image: {
		path: '/images/marketing/insurance-coverage-image.png',
		alt: 'Coverage image',
	},
	link: {
		href: '/aanvragen/bedrijf/zoeken',
		content: 'Bereken premie',
		title: 'Bereken premie',
	},
};

import { GetTasksResponse } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

const taskApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getTasks: builder.query<GetTasksResponse, void>({
			query() {
				return {
					url: `/tasks`,
					method: 'GET',
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetTasksQuery } = taskApi;

export const { getTasks } = taskApi.endpoints;

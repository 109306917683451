import { BundleProduct, Calculation, CmsDynamicBundle, IComposition, SupportedInsurance } from '@monorepo/types';

import {
	MultipleProductCard,
	MultipleProductCardFeature,
	MultipleProductCardProps,
} from '../../../components/DataDisplay';
import { sortProducts } from '../../../lib/utils';

type MultipleProductCardFactoryProps = (
	| {
			type: 'cms';
			payload: {
				products: CmsDynamicBundle[];
			};
	  }
	| {
			type: 'calculation';
			payload: {
				products: BundleProduct[];
				calculations: Calculation[];
			};
	  }
	| {
			type: 'composition';
			payload: {
				compositions: IComposition[];
			};
	  }
) & {
	props: Omit<MultipleProductCardProps, 'requiredFeatures' | 'optionalFeatures' | 'compositionIncludes'>;
};

export const MultipleProductCardFactory = ({ type, payload, props }: MultipleProductCardFactoryProps) => {
	const separateFeaturesBasedOnCms = (
		products: CmsDynamicBundle[],
		isRequired: boolean = true
	): MultipleProductCardFeature[] => {
		return products
			.filter((product) => product.is_optional === !isRequired)
			.sort(({ name: nameA }, { name: nameB }) =>
				sortProducts(nameA as SupportedInsurance, nameB as SupportedInsurance)
			)
			.map((product) => ({ guid: product.link_guid, name: product.name, icon: product.icon_name }));
	};

	const separateFeaturesBasedOnCalculations = (
		products: BundleProduct[],
		calculations: Calculation[],
		isRequired: boolean = true
	): MultipleProductCardFeature[] => {
		return calculations
			.map((calculation) => calculation.product)
			.filter((product) =>
				products.some(({ code, is_optional }) => code === product.code && is_optional === !isRequired)
			)
			.sort(({ name: nameA }, { name: nameB }) =>
				sortProducts(nameA as SupportedInsurance, nameB as SupportedInsurance)
			)
			.map((product) => ({ guid: product.guid, name: product.name, icon: product.icon_name }));
	};

	const separateFeaturesBasedOnCompositions = (compositions: IComposition[]): MultipleProductCardFeature[] =>
		compositions
			.find(({ guid }) => guid === props.guid)
			?.insured_activities.map(({ guid, description, icon }) => ({
				guid,
				name: description,
				icon,
			})) as MultipleProductCardFeature[];

	switch (type) {
		case 'cms':
			return (
				<MultipleProductCard
					requiredFeatures={separateFeaturesBasedOnCms(payload.products)}
					optionalFeatures={separateFeaturesBasedOnCms(payload.products, false)}
					{...props}
				/>
			);
		case 'calculation':
			return (
				<MultipleProductCard
					requiredFeatures={separateFeaturesBasedOnCalculations(payload.products, payload.calculations)}
					optionalFeatures={separateFeaturesBasedOnCalculations(payload.products, payload.calculations, false)}
					{...props}
				/>
			);

		case 'composition':
			return (
				<MultipleProductCard
					compositionIncludes={separateFeaturesBasedOnCompositions(payload.compositions)}
					isConsumer={true}
					{...props}
				/>
			);

		default:
			return null;
	}
};

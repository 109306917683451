import { faker } from '@faker-js/faker/locale/nl';

import { ClassificationContent, ClassificationForCart, OrganizationClassification } from '@monorepo/types';

export function mockGetOrganizationClassification(): OrganizationClassification {
	return {
		guid: faker.string.alphanumeric(16),
		name: faker.lorem.words(3),
		group: {
			description: 'Advies en Consultancy',
			code: 'advies-consultancy',
			guid: faker.string.alphanumeric(16),
		},
		suggested: faker.datatype.boolean(),
		content: mockGetClassificationContents(),
	};
}

export function mockGetOrganizationClassifications(num = 3): OrganizationClassification[] {
	const classifications: OrganizationClassification[] = [];
	for (let i = 0; i < num; i++) {
		classifications.push(mockGetOrganizationClassification());
	}
	return classifications;
}

export function mockGetClassificationContent(): ClassificationContent {
	return {
		sequence: 10,
		content: faker.lorem.words(10),
		included: faker.datatype.boolean(),
	};
}

export function mockGetClassificationContents(num = 3): ClassificationContent[] {
	const contents: ClassificationContent[] = [];
	for (let i = 0; i < num; i++) {
		contents.push(mockGetClassificationContent());
	}
	return contents;
}

export function mockGetClassificationOfCart(): ClassificationForCart {
	return {
		guid: faker.string.alphanumeric(16),
		name: faker.lorem.words(3),
		group: {
			description: 'Advies en Consultancy',
			code: 'advies-consultancy',
			guid: faker.string.alphanumeric(16),
		},
		suggested: faker.datatype.boolean(),
		content: mockGetClassificationContents(6),
	};
}

export function mockGetClassifcationsOfCart(num = 3): ClassificationForCart[] {
	const classifications: ClassificationForCart[] = [];
	for (let i = 0; i < num; i++) {
		classifications.push(mockGetClassificationOfCart());
	}
	return classifications;
}

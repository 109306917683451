import {
	CmsElement,
	CmsLink,
	MarketingFooterColumn,
	MarketingFooterProof,
	MarketingFooterSocialItem,
	NavItem,
} from '@monorepo/types';

type CmsAcc = Record<string, string>;
type CmsField = {
	key: string;
	value: string;
};

function reduceCustomFields(fields: CmsField[]): CmsAcc {
	return fields.reduce((acc: CmsAcc, field: CmsField) => {
		acc[field.key] = field.value;
		return acc;
	}, {});
}

function mapLinks(links: CmsLink[], defaultHref = '/', defaultTitle = 'Title'): CmsLink[] {
	return links.map((link: CmsLink) => ({
		content: link.content || link.title || 'Content',
		href: link.href || defaultHref,
		target: link.target || '_self',
		title: link.title || defaultTitle,
	}));
}

export function transformGlobalsToNavItems(jsonElement: CmsElement, sequence: number = 0): NavItem {
	const hasChildren = jsonElement.child_elements && jsonElement.child_elements.length > 0;
	const customFields = reduceCustomFields(jsonElement.custom_fields);

	return {
		type: hasChildren ? 'menuItem' : 'link',
		href: jsonElement.links?.[0]?.href || '',
		value: customFields.title || '',
		subtext: customFields.subtitle || '',
		icon: customFields.icon_name || '',
		sequence,
		subItems: hasChildren
			? jsonElement.child_elements.map((child, index) => transformGlobalsToNavItems(child, index))
			: undefined,
	};
}

export function transformGlobalsToFooterColumns(jsonElement: CmsElement): MarketingFooterColumn {
	const customFields = reduceCustomFields(jsonElement.custom_fields);
	const items = mapLinks(jsonElement.links);

	return {
		title: customFields.title || '',
		items,
	};
}

export function transformGlobalsToSocialItems(jsonElement: CmsElement): MarketingFooterSocialItem {
	const firstLink = jsonElement.links?.[0];
	const firstMedia = jsonElement.media?.[0];

	return {
		link: firstLink || { title: '', href: '/', content: '', target: '_self' },
		image: firstMedia,
	};
}

export function transformGlobalsToSocialProof(jsonElement: CmsElement): MarketingFooterProof {
	const firstMedia = jsonElement.media?.[0];

	return {
		image: firstMedia,
	};
}

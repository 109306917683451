import cn from 'classnames';
import { type FC } from 'react';

import { Text } from '../../TextComponents';

type TotalFteProps = {
	label: string;
	fte: number | string;
	className?: string;
};

export const TotalFte: FC<TotalFteProps> = ({ label, fte, className }) => {
	return (
		<div className={cn(className, 'bg-primary50 rounded-6 px-32 py-12')}>
			<Text variant='body-m'>
				{label}: <span className='text-primaryMain font-semibold'>{fte}</span>
			</Text>
		</div>
	);
};

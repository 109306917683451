import cn from 'classnames';
import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { FaqSection, TabbedFaqSection } from '@marketing/components';

export const handleSectionFaq = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const [element] = elements,
		title = getProperty('title', element.custom_fields),
		subtext = getProperty('subtext', element.custom_fields);

	return () =>
		rootContext?.path === 'klantenservice' ? (
			<TabbedFaqSection />
		) : (
			<FaqSection
				/* Only on insurance detail pages we need a background-color on this component */
				className={cn(rootContext?.path?.includes('verzekeringen/') && 'bg-primary50')}
				title={`${title}`}
				subtext={`${subtext}`}
				path={rootContext?.path}
			/>
		);
};

import { FC, HTMLAttributes } from 'react';

import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';

type Props = {
	activity: string;
} & Omit<HTMLAttributes<HTMLDivElement>, 'role' | 'className'>;

export const SearchSuggestionActivity: FC<Props> = ({ activity, ...props }) => {
	return (
		<div role='button' className='hover:bg-primary50 bg-grayscale0 group px-12 py-8 transition-colors' {...props}>
			<div className='flex items-center'>
				<div className='text-grayscale300 group-hover:text-primaryMain mr-12 transition-colors'>
					<Icon name='arrow-right' size='md' color='inherit' />
				</div>
				<Text variant='body-l' weight='semibold' color='grayscale600'>
					{activity}
				</Text>
			</div>
		</div>
	);
};

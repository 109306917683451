import { FC, ReactNode } from 'react';

import { Text } from '../../TextComponents';
import { List } from '../List/List';

type NumberedItemProps = {
	item: string | ReactNode;
	number: number;
};

const NumberedItem: FC<NumberedItemProps> = ({ item, number }) => {
	return (
		<Text as='li' variant='body-m' weight='regular' className='flex flex-row items-center' color='grayscale500'>
			<Text as='span' weight='semibold' className='mr-8 self-start'>
				{number}.
			</Text>
			{item}
		</Text>
	);
};

type NumberedListProps = {
	items: string[] | ReactNode[];
	className?: string;
};

export const NumberedList: FC<NumberedListProps> = ({ items, className = '' }) => {
	return (
		<List as='ol' className={className}>
			{items.map((item, index) => (
				<NumberedItem key={`numbered-item-${index}`} item={item} number={index + 1} />
			))}
		</List>
	);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types/src/common/translation.type';

import { D_DATE_FORMAT } from '../utils';

const minDate = new Date(new Date().getFullYear() - 150, new Date().getMonth(), new Date().getDay());

export const aovProductSchema = (t: TranslationType) =>
	z.object({
		search: z.string().optional(),
		dateOfBirth: z.string({ required_error: t('validation.required') }).superRefine((value, ctx) => {
			if (!value) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.required'),
				});
			}

			const parts = value.replaceAll(' ', '').split('-');

			if (parts.join('').length !== 8) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.formatSuggestion', { format: D_DATE_FORMAT.toLowerCase() }),
				});
			}

			const rearrangedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
			const parsedDate = new Date(rearrangedDate);

			const todayDate = new Date();
			todayDate.setHours(0, 0, 0, 0);

			if (isNaN(parsedDate.getTime())) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.formatSuggestion', { format: D_DATE_FORMAT.toLowerCase() }),
				});
			}

			if (parsedDate >= todayDate) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.dateValidBirthday'),
				});
			}

			if (parsedDate <= minDate) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.dateValidBirthday'),
				});
			}
		}),
		insuredAmount: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		remainingDays: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		duration: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
	});

export type AovProductSchema = z.infer<ReturnType<typeof aovProductSchema>>;

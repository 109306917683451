import Head from 'next/head';
import { type FC } from 'react';

export const HeadMeestersgilde: FC = () => (
	<Head>
		<link rel='apple-touch-icon' sizes='120x120' href='/label/meestersgilde/apple-touch-icon.png' />
		<link rel='icon' type='image/png' sizes='32x32' href='/label/meestersgilde/favicon-32x32.png' />
		<link rel='icon' type='image/png' sizes='16x16' href='/label/meestersgilde/favicon-16x16.png' />
		<link rel='manifest' href='/label/meestersgilde/site.webmanifest' />
		<meta name='msapplication-TileColor' content='#da532c' />
		<meta name='theme-color' content='#ffffff' />
	</Head>
);

import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { transformToCurrency } from '../../../../lib/utils';
import { Skeleton } from '../../../Misc';
import { Text } from '../Text/Text';

export type TotalPriceFormatSize = 'small' | 'medium' | 'large';

export type TotalPriceFormatProps = {
	taxPrice: number;
	totalPrice: number;
	size?: TotalPriceFormatSize;
	isLoading?: boolean;
};

export const TotalPriceFormat: FC<TotalPriceFormatProps> = ({ taxPrice, totalPrice, size = 'medium', isLoading }) => {
	const { t } = useTranslation(['common']);

	return (
		<div className='flex w-full items-center space-x-16'>
			<div
				className={classNames(
					'flex flex-1 flex-col',
					size === 'large' && 'space-y-4',
					size === 'small' && 'space-y-[2px]'
				)}>
				<Skeleton
					variant='text'
					size={size === 'large' ? 'body-l' : size === 'medium' ? 'body-s' : 'body-xs'}
					className='w-[175px]'
					color='primary100'
					isLoading={isLoading}>
					<Text
						variant={size === 'large' ? 'body-l' : size === 'medium' ? 'body-s' : 'body-xs'}
						weight='medium'
						color='grayscale600'>
						{t('components.totalPriceFormat.total')}
					</Text>
				</Skeleton>
				<Skeleton
					variant='text'
					size={size === 'large' ? 'body-s' : 'body-xs'}
					color='primary100'
					className='w-[125px]'
					isLoading={isLoading}>
					<Text variant={size === 'large' ? 'body-s' : 'body-xs'} weight='regular' color='grayscale400'>
						{t('components.totalPriceFormat.insuranceTax', { price: transformToCurrency(taxPrice) })}
					</Text>
				</Skeleton>
			</div>

			<Skeleton
				isLoading={isLoading}
				variant='text'
				size={size === 'large' ? 'display-5' : size === 'medium' ? 'body-m' : 'body-s'}
				className='w-[125px]'
				color='primary100'>
				<Text
					variant={size === 'large' ? 'display-5' : size === 'medium' ? 'body-m' : 'body-s'}
					weight='semibold'
					color='grayscale500'>
					{transformToCurrency(totalPrice)}
				</Text>
			</Skeleton>
		</div>
	);
};

import * as RadixSlider from '@radix-ui/react-slider';
import { ComponentProps } from 'react';

type DialogProps = ComponentProps<typeof RadixSlider.Root>;

export function Slider(props: DialogProps) {
	return (
		<RadixSlider.Root className='relative flex h-12 w-full touch-none select-none items-center' {...props}>
			<RadixSlider.Track className='before:bg-primary400 relative h-4 grow rounded-full before:absolute before:inset-0 before:rounded-full before:opacity-30'>
				<RadixSlider.Range className='bg-primary400 absolute h-full rounded-full' />
			</RadixSlider.Track>
			<RadixSlider.Thumb
				className='bg-primary400 shadow-1 block h-12 w-12 cursor-pointer rounded-full'
				aria-label='Volume'
			/>
		</RadixSlider.Root>
	);
}

import cn from 'classnames';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Icon, LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IAskQuestionSection } from '@monorepo/types';

import { openSupportChat } from '@support/store';

type AskQuestionSectionProps = IAskQuestionSection & {
	className?: string;
};

export const AskQuestionSection: FC<AskQuestionSectionProps> = ({ title, icon, className }) => {
	const dispatch = useDispatch();

	const handleOpenChat = () => {
		dispatch(openSupportChat());
	};

	return (
		<section className={cn(className, 'py-48')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 w-full'>
					<button
						role='button'
						type='button'
						className='group flex w-fit flex-row items-center space-x-24 md:space-x-48'
						onClick={handleOpenChat}>
						<div className='rounded-12 bg-primaryMain flex h-[50px] min-w-[50px] items-center justify-center md:h-[78px] md:min-w-[78px]'>
							<Icon
								name={icon}
								size='custom'
								color='grayscale0'
								className='text-display-4-desktop md:text-display-2-desktop'
							/>
						</div>
						<Text
							variant='display-3'
							weight='semibold'
							color='primaryMain'
							className='transition-transform group-hover:translate-x-8'>
							{title}
						</Text>
					</button>
				</div>
			</LayoutGrid>
		</section>
	);
};

import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { DynamicOverview } from '@marketing/components';

const productContainers = ['section-products', 'section-products-landing'];

const Paragraph = (props: ReactMarkdownProps) => <p color='inherit' {...props} />;
const Break = (props: ReactMarkdownProps) => <br {...props} />;

export const mdComponents: Components = {
	p: Paragraph,
	br: Break,
};

export const handleDynamicOverview = <P extends object>({
	elements,
	identifier,
	rootContext,
}: HandlerPropsV2): FC<P> => {
	const variant = productContainers.includes(identifier)
		? 'products'
		: identifier.includes('compositions')
			? 'compositions'
			: 'bundles';

	const titleSubtextEle = elements.find(
		({ identifier: elementIdentifier }) => elementIdentifier === 'title-subtext'
	) as CmsElement;

	const productCardGeneralEle = elements.find(
			({ identifier }) => identifier === `${variant === 'products' ? 'product' : 'bundle'}-card-general`
		) as CmsElement,
		linkOptionalSubtextEle = elements.find(({ identifier }) => identifier === 'link-optional-subtext') as CmsElement,
		link = productCardGeneralEle?.links?.[0] || linkOptionalSubtextEle?.links?.[0];

	const title = getProperty('title', titleSubtextEle?.custom_fields),
		subtext = getProperty('subtext', titleSubtextEle?.custom_fields);

	const Content = () => (
		<div className='space-y-16'>
			<ReactMarkdown components={mdComponents}>{`${subtext}`}</ReactMarkdown>
		</div>
	);

	return (props) => (
		<DynamicOverview
			{...props}
			title={`${title}`}
			subtext={<Content />}
			variant={variant}
			path={rootContext?.path}
			link={link}
		/>
	);
};

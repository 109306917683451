import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Text } from '@monorepo/components/TextComponents';
import { IDetailHero } from '@monorepo/types';

export const DetailHeroPrice: FC<Pick<IDetailHero, 'price'> & { className?: string }> = ({ price, className }) => {
	const { t } = useTranslation(['common']);
	return (
		<div className={cn(className, 'flex flex-col space-y-12')}>
			<div className='rounded-6 bg-info300 flex w-fit flex-row items-center p-12 max-md:space-x-8 md:flex-col md:items-start md:p-24'>
				<Text as='span' variant='body-s' color='grayscale0'>
					{price?.pretext || t('common.from')}
				</Text>
				<Text as='span' variant='display-4' weight='semibold' color='grayscale0'>
					{price?.amount}
				</Text>
			</div>
			<Text variant='body-xs' as='span' color='grayscale0'>
				{price?.subtext || t('common.inclTax')}
			</Text>
		</div>
	);
};

import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Icon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';

import { CtaSearchForm } from './CtaSearchForm/CtaSearchForm';

type CtaSearchFormSectionProps = {
	id: string;
	className?: string;
};
export const CtaSearchFormSection: FC<CtaSearchFormSectionProps> = ({ id, className }) => {
	const { t } = useTranslation(['common']);
	return (
		<div
			className={cn(
				className,
				'rounded-18 shadow-1 flex w-full flex-col self-center p-16 max-md:space-y-12 md:w-4/5 md:flex-row md:items-center md:space-x-48 md:p-32 lg:w-2/3'
			)}>
			<div className='flex flex-row items-center space-x-12 md:space-x-24'>
				<Icon name='search' size='xl' color='info300' className='leading-none' />
				<Text as='h3' variant='display-4' weight='semibold'>
					{t('components.searchBox.label')}
				</Text>
			</div>
			<div className='grow max-md:w-full'>
				<CtaSearchForm id={id} />
			</div>
		</div>
	);
};

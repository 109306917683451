import cn from 'classnames';
import { FC } from 'react';

import { ThemeColors } from '@monorepo/types';

export type StatusCircleStatus = 'success' | 'warning' | 'error';
export type StatusCircleBg = `bg-${ThemeColors}`;

type StatusCircleProps = {
	status?: StatusCircleStatus;
	className?: string;
};

const StatusCircleClasses: Record<StatusCircleStatus, StatusCircleBg> = {
	success: 'bg-success300',
	warning: 'bg-warning300',
	error: 'bg-error300',
};

export const StatusCircle: FC<StatusCircleProps> = ({ status = 'success', className = '' }) => (
	<div className={cn(className, StatusCircleClasses[status], 'h-[6px] w-[6px] rounded-full')} />
);

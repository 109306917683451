import { ILogoList } from '@monorepo/types';

const logos = ['vbo', 'nvm', 'anko', 'cybermeister', 'onderhoudnl', 'schouten-zekerheid'];

export const mockLogoListSection: ILogoList = {
	title: 'In samenwerking met',
	logos: logos.map((logo) => ({
		alt: logo,
		src: `/images/marketing/logos/${logo}.svg`,
		link: {
			href: `https://www.${logo}.nl/`,
			content: logo,
			target: '_blank',
			title: logo,
		},
	})),
};

import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { AskQuestionSection } from '@marketing/components/AskQuestionSection/AskQuestionSection';

export const handleStartChatSection = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const startChatElement = elements.find((element) => element.identifier === 'start-chat') as CmsElement;
	const title = getProperty('title', startChatElement?.custom_fields);
	const icon = getProperty('icon_name', startChatElement?.custom_fields);

	return () => <AskQuestionSection className='bg-primary50' icon={`${icon}`} title={`${title}`} />;
};

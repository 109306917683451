import cn from 'classnames';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';

import { useProductFormControls } from '@monorepo/shared/lib/hooks';
import {
	Address,
	Bundle,
	CalculationProduct,
	CalculationProductOptionSpecWithGuid,
	CalculationRiskAddress,
} from '@monorepo/types';

import {
	ProductFormAddress,
	ProductFormRadio,
	ProductFormText,
	ProductFormToggle,
	ProductFormToggleButton,
} from '@common/components';
import { selectTheme } from '@common/store';

type ProductFormProps = {
	product: CalculationProduct;
	bundles: Bundle[];
	onChangeOption: (guid: string) => void;
	onChangeAddress: (address: Address) => void;
	addressIsLoading?: boolean;
	className?: string;
};

/**
 * @deprecated
 */
export const LegacyProductForm: FC<ProductFormProps> = ({
	product,
	bundles,
	onChangeOption,
	onChangeAddress,
	addressIsLoading = false,
	className = '',
}) => {
	const { getControls } = useProductFormControls();
	const formControls = getControls(product, bundles);
	const theme = useSelector(selectTheme);

	// One of for Cybermeister to not show subtexts for radio buttons
	const showRadioSubText = !(theme === 'cybermeister' && product.name === 'Alles-in-één aansprakelijkheidsverzekering');

	if (!formControls || formControls.length === 0) return null;

	return (
		<div className={cn(className, 'flex flex-col space-y-24')}>
			{formControls
				.filter(({ precondition }) => !precondition)
				.map(({ controlType, specs, risk_address }, index) => {
					return (
						<Fragment key={`formcontrol-${controlType}-${index}`}>
							{controlType === 'radio' && (
								<ProductFormRadio
									product={product}
									specs={specs as CalculationProductOptionSpecWithGuid[][]}
									onChangeOption={onChangeOption}
									showSubtext={showRadioSubText}
								/>
							)}

							{controlType === 'text' && (
								<ProductFormText product={product} specs={specs as CalculationProductOptionSpecWithGuid[][]} />
							)}

							{controlType === 'toggleButton' && (
								<ProductFormToggleButton
									product={product}
									specs={specs as CalculationProductOptionSpecWithGuid[][]}
									onChangeOption={onChangeOption}
								/>
							)}

							{controlType === 'toggle' && (
								<ProductFormToggle
									product={product}
									specs={specs as CalculationProductOptionSpecWithGuid[][]}
									onChangeOption={onChangeOption}
								/>
							)}

							{controlType === 'address' && (
								<ProductFormAddress
									product={product}
									risk_address={risk_address as CalculationRiskAddress}
									onChangeAddress={onChangeAddress}
									addressIsLoading={addressIsLoading}
								/>
							)}
						</Fragment>
					);
				})}
		</div>
	);
};

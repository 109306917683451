import classNames from 'classnames';
import { Children, FC, isValidElement, PropsWithChildren } from 'react';

export const FormChoiceList: FC<PropsWithChildren> = ({ children }) => {
	const childrenCount = Children.count(children);

	return (
		<div
			className={classNames(
				'grid grid-cols-1',
				childrenCount >= 3 && 'sm:grid-cols-3',
				childrenCount === 2 && 'sm:grid-cols-2',
				childrenCount >= 2 && 'gap-12'
			)}>
			{Children.map(children, (child, key) =>
				!isValidElement(child) ? null : (
					<div key={`form-choice-list-${key}`} className='col-span-1'>
						{child}
					</div>
				)
			)}
		</div>
	);
};

import { FC } from 'react';

import { Form } from '@monorepo/components/DataEntry';
import { RevenueSchema } from '@monorepo/shared/lib/schemas';

type RevenueFormProps = {
	defaultValue?: number;
	onSubmit: ({ revenue }: RevenueSchema) => void;
};

export const RevenueForm: FC<RevenueFormProps> = ({ defaultValue, onSubmit }) => {
	return (
		<Form.Context<RevenueSchema>>
			{({ handleSubmit, control }) => {
				return (
					<Form id='revenue-form' onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.BigNumberInput
								control={control}
								decimalScale={0}
								name='revenue'
								defaultValue={defaultValue}
								placeholder='€ 100.000'
								data-testid='revenue-form-input'
								autoFocus
							/>
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};

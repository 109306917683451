import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { isGivenAnswerDifferentThanExistingAnswer, toAnswerRequestObject } from '@monorepo/shared/lib/utils';
import { AnswerQuestionsRequest, PremiumQuestion } from '@monorepo/types';

import { useNavigation } from '@common/hooks';
import { selectPlatformState, setNavForward } from '@common/store';
import { useQuestionPageManager } from '@funnel/hooks';
import {
	insuranceDocsApi,
	resetProducts,
	setHasMessage,
	useAnswerPremiumQuestionsMutation,
	useGetPremiumQuestionsQuery,
} from '@funnel/store';

export const usePremiumQuestions = () => {
	const {
		push,
		query: { cartGuid, slug },
	} = useRouter();
	const dispatch = useDispatch();
	const { isB2BFunnel } = useNavigation();
	const { data: premiumQuestions = [] as PremiumQuestion[], isSuccess } = useGetPremiumQuestionsQuery(
		isB2BFunnel && cartGuid ? (cartGuid as string) : skipToken
	);

	const { isPortal } = useSelector(selectPlatformState);
	const questions = premiumQuestions?.filter(({ reference: lookupRef }) => lookupRef === slug);
	const { nextPage } = useQuestionPageManager({ isPremiumQuestions: true });
	const [answerPremiumQuestion, answerPremiumQuestionResponse] = useAnswerPremiumQuestionsMutation();

	const mapToAnswer = (answer: number | number[] | string | string[]): AnswerQuestionsRequest => {
		const answerArray = Array.isArray(answer) ? answer : [answer];
		const formattedAnswers: string[] = answerArray.map((singleAnswer) => singleAnswer.toString());
		return toAnswerRequestObject(`${cartGuid}`, questions, formattedAnswers);
	};

	const submitPremiumQuestions = async (answer: AnswerQuestionsRequest, toNextPage = nextPage): Promise<void> => {
		dispatch(setNavForward());

		// Reset products state so we always fetch new stuff after premium questions
		dispatch(resetProducts());
		dispatch(insuranceDocsApi.util.invalidateTags([{ type: 'Documents' }]));

		// Check if we have matches in the given answers and the existing answers
		if (isPortal) {
			const answerDiffers = isGivenAnswerDifferentThanExistingAnswer(answer, questions);
			if (answerDiffers) {
				dispatch(setHasMessage(true));
			}
		}

		await answerPremiumQuestion(answer).unwrap();
		await push({
			pathname: `${toNextPage}`,
			query: { cartGuid },
		});
	};

	return {
		isSuccess,
		mapToAnswer,
		submit: submitPremiumQuestions,
		response: answerPremiumQuestionResponse,
	};
};

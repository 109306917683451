import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { CtaSearchSection } from '@marketing/components';

const Paragraph = (props: ReactMarkdownProps) => <>{props.children}</>;
const highlight = (props: ReactMarkdownProps) => <span {...props} />;

const mdComponents: Components = {
	p: Paragraph,
	strong: highlight,
};

export const handleSectionCtaImageCentered = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubTextElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement,
		imageElement = elements.find(({ identifier }) => identifier === 'image') as CmsElement;

	const title = getProperty('title', titleSubTextElement.custom_fields),
		subtext = getProperty('subtext', titleSubTextElement.custom_fields);

	const image = {
		src: `${imageElement.media?.[0].path}`,
		alt: `${imageElement.media?.[0].alt}`,
	};

	const Title = () => <ReactMarkdown components={mdComponents}>{`${title}`}</ReactMarkdown>;

	return (props) => <CtaSearchSection {...props} title={<Title />} subtext={`${subtext}`} image={image} />;
};

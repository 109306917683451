import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';

type MyMaiaMenuItemProps = {
	to: string;
	text: string;
	icon: string;
	notificationCount?: number;
	isDisabled?: boolean;
};

export const MyMaiaMenuItem: FC<MyMaiaMenuItemProps> = ({ to, icon, text, isDisabled, notificationCount }) => {
	const router = useRouter();
	const isActive = router.pathname.includes(to);

	return (
		<Link
			href={to}
			className={classNames(
				'group flex w-full flex-row items-center py-8 2xl:relative 2xl:overflow-hidden 2xl:pr-32',
				isDisabled && 'cursor-not-allowed'
			)}
			aria-disabled={isDisabled}>
			<div className='flex w-full items-center 2xl:justify-end'>
				<div className='flex items-center space-x-12 transition-all duration-300 ease-in-out max-2xl:group-hover:translate-x-8 max-2xl:group-focus:translate-x-8 2xl:flex-row-reverse 2xl:space-x-reverse 2xl:group-hover:-translate-x-8 2xl:group-focus:-translate-x-8'>
					<Icon
						size='md'
						name={icon}
						color='grayscale400'
						className={classNames(
							'transition-colors',
							!isDisabled &&
								'group-hover:text-grayscale500 group-active:text-grayscale600 group-focus:text-grayscale500'
						)}
					/>

					<Text
						variant='body-xs'
						weight={isActive ? 'semibold' : 'regular'}
						color='grayscale400'
						className={classNames(
							'whitespace-nowrap transition-colors',
							!isDisabled &&
								'group-hover:text-grayscale500 group-active:text-grayscale600 group-focus:text-grayscale500'
						)}>
						{text}
					</Text>

					<AnimatePresence>
						{notificationCount && (
							<motion.div
								initial={{ scale: 0, opacity: 0 }}
								animate={{ scale: 1, opacity: 1 }}
								transition={{
									type: 'spring',
									damping: 10,
									stiffness: 100,
									delay: 0.5,
									repeat: 4,
									repeatType: 'reverse',
									repeatDelay: 1,
								}}>
								<Text
									variant='body-xxs'
									weight='semibold'
									color='grayscale0'
									className={classNames(
										'flex h-[16px] w-[16px] items-center justify-center rounded-full transition-colors',
										isDisabled && 'opacity-0',
										!isDisabled && 'bg-error300 opacity-100'
									)}>
									{notificationCount}
								</Text>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>

			{/* Indicator */}
			<div
				className={classNames(
					'max-2xl:rounded-br-6 max-2xl:rounded-tr-6 2xl:rounded-bl-6 2xl:rounded-tl-6 absolute h-[40px] w-[6px] transition-all md:fixed 2xl:absolute',
					isDisabled && 'invisible',
					!isDisabled && 'group-disabled:bg-grayscale50',
					isActive && 'bg-primaryMain left-0 translate-x-0 opacity-100 2xl:left-auto 2xl:right-0',
					!isActive &&
						'bg-primary100 left-0 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 max-2xl:-translate-x-full 2xl:left-auto 2xl:right-0 2xl:translate-x-full'
				)}
			/>
		</Link>
	);
};

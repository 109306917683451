import { IObjectDetailsSpecs, IObjectDetailsTransformed, ObjectKind } from '@monorepo/types';

export function determineObjectType(objects: Array<IObjectDetailsTransformed>): ObjectKind {
	return objects.some((object) => typeof object?.specs?.species !== 'undefined') ? 'pet' : 'motor';
}

export function determinePetType(specs: IObjectDetailsSpecs): 'cat' | 'dog' | undefined {
	const foundSpecies = Object.entries(specs).find(([key]) => key === 'species');

	if (!foundSpecies) return undefined;

	return foundSpecies[1].multiple_choice === 'cat' ? 'cat' : 'dog';
}

export function getAllPetBreedSpecs(specs: IObjectDetailsSpecs): IObjectDetailsSpecs {
	const breedReferences = [
		'primary_cat_breed',
		'secondary_cat_breed',
		'primary_dog_breed',
		'secondary_dog_breed',
		'cat_breed',
		'dog_breed',
	];
	const specsWithOnlyBreeds: IObjectDetailsSpecs = {};

	for (const [key, value] of Object.entries(specs)) {
		if (breedReferences.some((reference) => reference === key) && value.multiple_choice) {
			specsWithOnlyBreeds[key] = value;
		}
	}

	return specsWithOnlyBreeds;
}

export function concatAllPetBreeds(specs: IObjectDetailsSpecs, separator = ', '): string {
	const concatenatedBreeds = Object.values(specs)
		.filter((answer) => answer?.multiple_choice)
		.map((answer) => answer.multiple_choice)
		.join(separator);

	return concatenatedBreeds;
}

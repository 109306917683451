import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, useTranslation } from 'next-i18next';
import Link from 'next/link';
import { type FC } from 'react';
import { useForm } from 'react-hook-form';

import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { sendMailSchema, SendMailSchema } from '@monorepo/shared/lib/schemas';

import { ModalActions } from '@common/components';
import { paths } from '@common/utils';

type SendMailFormProps = {
	onSubmit: (fields: SendMailSchema) => void;
	isLoading?: boolean;
};

export const SendMailForm: FC<SendMailFormProps> = ({ onSubmit, isLoading }) => {
	const { t } = useTranslation(['common']);

	const sendMailForm = useForm<SendMailSchema>({
		mode: 'onBlur',
		resolver: zodResolver(sendMailSchema(t)),
	});

	return (
		<Form onSubmit={sendMailForm.handleSubmit(onSubmit)} className='grid grid-cols-12 gap-24'>
			<Form.Group className='col-span-12'>
				<Form.Label>{t('common.firstName')}</Form.Label>
				<Form.TextInput
					fieldSize='lg'
					name='first_name'
					autoComplete='first-name'
					errors={sendMailForm.formState.errors.first_name}
					register={sendMailForm.register}
					placeholder={t('common.firstName')}
				/>
			</Form.Group>

			<Form.Group className='col-span-12 '>
				<Form.Label>{t('common.lastName')}</Form.Label>
				<Form.TextInput
					fieldSize='lg'
					name='last_name'
					autoComplete='family-name'
					errors={sendMailForm.formState.errors.last_name}
					register={sendMailForm.register}
					placeholder={t('common.lastName')}
				/>
			</Form.Group>

			<Form.Group className='col-span-12'>
				<Form.Label>{t('common.email')}</Form.Label>
				<Form.EmailInput
					fieldSize='lg'
					name='email'
					autoCapitalize='off'
					startIcon='envelope'
					errors={sendMailForm.formState.errors.email}
					register={sendMailForm.register}
					placeholder={t('common.email')}
				/>
			</Form.Group>

			<Form.Group className='col-span-12'>
				<Form.Checkbox
					name='terms_agreement'
					errors={sendMailForm.formState.errors.terms_agreement}
					register={sendMailForm.register}
					label={
						<Trans
							i18nKey='forms.sendMailForm.acceptTermsLabel'
							components={{
								terms: (
									<Link
										href={paths.content.terms}
										rel='noreferrer'
										target='_blank'
										className='text-info300 underline'
									/>
								),
								privacy: (
									<Link
										href={paths.content.privacy_statement}
										rel='noreferrer'
										target='_blank'
										className='text-info300 underline'
									/>
								),
							}}
						/>
					}
				/>
			</Form.Group>
			<Form.Group className='col-span-12'>
				<ModalActions
					submitButton={
						<StandardButton
							variant='primary'
							type='submit'
							disabled={!sendMailForm.formState.isValid || isLoading}
							isLoading={isLoading}
							label={t('common.send')}
						/>
					}
				/>
			</Form.Group>
		</Form>
	);
};

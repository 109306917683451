import { z } from 'zod';

import { TranslationType } from '@monorepo/types'; // Adjust the import path as needed

export const closingStatementSchema = (t: TranslationType) =>
	z.object({
		explanation: z
			.string()
			.max(200, t('validation.max', { max: 200 }))
			.min(1, t('validation.required')),
	});

export type ClosingStatementSchema = z.infer<ReturnType<typeof closingStatementSchema>>;

import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { INicheUspSection } from '@monorepo/types';

import { BlurbCard } from '../BlurbCard/BlurbCard';

type NicheUspSectionProps = INicheUspSection & {
	className?: string;
};

export const NicheUspSection: FC<NicheUspSectionProps> = ({ title, usps, className }) => {
	return (
		<section className={cn(className, 'py-48 md:py-96')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col gap-y-40 md:gap-y-64'>
					<Text as='h2' variant='display-3' weight='semibold' color='primaryMain' className='text-center'>
						{title}
					</Text>

					<div className='grid w-full grid-cols-6 gap-24 md:gap-80'>
						{usps.map(({ image, title, description }, key) => (
							<BlurbCard
								title={title}
								description={description}
								image={image}
								isElevated={false}
								enableEffects={false}
								className='pointer-events-auto col-span-full sm:col-span-3 md:col-span-2'
								key={`whniche-usp-section-${key}`}
							/>
						))}
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

import type { ReactElement } from 'react';
import { useFormContext, type FieldValues, type UseFormReturn } from 'react-hook-form';

type FormContextProps<TFieldValues extends FieldValues> = {
	children(children: UseFormReturn<TFieldValues>): ReactElement;
};

export const FormContext = <TFieldValues extends FieldValues>({ children }: FormContextProps<TFieldValues>) => {
	const methods = useFormContext<TFieldValues>();

	return children({ ...methods });
};

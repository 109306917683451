import cn from 'classnames';
import { FC } from 'react';

import { Icon } from '../../../Icon/Icon';

type TestimonialRatingsProps = {
	rating: number | string;
};

export const TestimonialRating: FC<TestimonialRatingsProps> = ({ rating }) => {
	const _rating = parseInt(`${rating}`);

	return (
		<div className='flex items-center space-x-[2px]'>
			{[...Array(5).keys()].map((key) => {
				const star = key + 1 <= _rating ? (_rating > 5 ? 5 : _rating) : -1;
				return (
					<div
						key={`testimonial-rating-${key}`}
						className={cn(
							'flex h-[16px] w-[16px] items-center justify-center',
							star === -1 && 'bg-grayscale200',
							star === 1 && 'bg-error300',
							star === 2 && 'bg-warning200',
							star === 3 && 'bg-warning300',
							star === 4 && 'bg-success200',
							star === 5 && 'bg-success300'
						)}>
						<Icon name='star' type='solid' color='grayscale0' size='xs' />
					</div>
				);
			})}
		</div>
	);
};

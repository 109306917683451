import { Question } from '@monorepo/types';

export const isQuestionAnswered = (question: Question): boolean => {
	const answers = Object.entries(question).filter(([key]) => key.startsWith('answer_'));

	const checkbox_answer = answers.find(([key]) => key === 'answer_checkbox');
	if (checkbox_answer && checkbox_answer?.[1] !== 0) {
		return true;
	}

	const rest_answers = answers.filter(([key]) => key !== 'answer_checkbox');
	return rest_answers.some(([_, answer]) => !!answer);
};

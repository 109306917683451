import { AcceptanceQuestion, PremiumQuestion } from '@monorepo/types';

import { preconditionsPageMap } from '../pageMaps/preconditionsPageMap';
import { premiumPageMap } from '../pageMaps/premiumPageMap';
import { sortAscending, sortDescending } from '../sort/sort';

function findPageReference(
	currentQuestion: PremiumQuestion | AcceptanceQuestion,
	questions: PremiumQuestion[] | AcceptanceQuestion[],
	sortMethod: (a: number, b: number) => number
) {
	if (!currentQuestion || !Object.keys(currentQuestion)?.length || !questions || questions?.length === 0) {
		return false;
	}

	let nextOrPrevQuestion = currentQuestion;

	// Find the right question:
	// - Sort with he sortMethod (ascending or descending);
	// - Filter out questions with the same reference as the current question
	// - Filter in questions which exist in one of the pagemaps
	// - Find the question with a higher or lower sequence
	const foundQuestion = Object.assign([], questions)
		.sort(({ sequence: sequence1 }, { sequence: sequence2 }) => sortMethod(sequence1, sequence2))
		.filter(
			({ reference }) =>
				reference !== currentQuestion.reference &&
				(premiumPageMap.has(reference) || preconditionsPageMap.has(reference))
		)
		.find(({ sequence }) => {
			if (sortMethod === sortAscending) {
				return sequence > currentQuestion.sequence;
			}
			if (sortMethod === sortDescending) {
				return sequence < currentQuestion.sequence;
			}
		});

	if (foundQuestion) {
		nextOrPrevQuestion = foundQuestion;
	}

	if (nextOrPrevQuestion.sequence === currentQuestion.sequence) {
		return false;
	}

	return nextOrPrevQuestion.reference;
}

export function getPreviousQuestionPageReference(
	currentQuestion: PremiumQuestion | AcceptanceQuestion,
	questions: PremiumQuestion[] | AcceptanceQuestion[] = []
): boolean | string {
	return findPageReference(currentQuestion, questions, sortDescending);
}

export function getNextQuestionPageReference(
	currentQuestion: PremiumQuestion | AcceptanceQuestion,
	questions: PremiumQuestion[] | AcceptanceQuestion[] = []
): boolean | string {
	return findPageReference(currentQuestion, questions, sortAscending);
}

export function getFirstQuestionPageReference(
	questions: PremiumQuestion[] | AcceptanceQuestion[] = []
): boolean | string {
	return (
		[...questions].sort(({ sequence: sequenceA }, { sequence: sequenceB }) => sortAscending(sequenceA, sequenceB))[0]
			?.reference || false
	);
}

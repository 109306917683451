import cn from 'classnames';
import { FC } from 'react';

import { Text } from '../Text/Text';

type Props = {
	title: string;
	text?: string;
	className?: string;
};
export const SubtitleAndText: FC<Props> = ({ title, text, className = '' }) => {
	return (
		<div className={cn(className, 'flex flex-col')}>
			<Text as='h3' variant='body-m' color='grayscale600' weight='medium'>
				{title}
			</Text>
			{text && (
				<Text as='p' variant='body-m' color='grayscale500'>
					{text}
				</Text>
			)}
		</div>
	);
};

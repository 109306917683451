import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, DetailInfoItem } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { DetailInfoSection } from '@marketing/components';

export const handleSectionWorkfieldSpecial = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextEle: CmsElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement,
		linksEle: CmsElement = elements.find(({ identifier }) => identifier === 'links') as CmsElement,
		coveragesEle: CmsElement = elements.find(({ identifier }) => identifier === 'coverages') as CmsElement;

	const title = getProperty('title', titleSubtextEle.custom_fields),
		subtext = getProperty('subtext', titleSubtextEle.custom_fields);

	const { links } = linksEle;

	const items: DetailInfoItem[] = coveragesEle.child_elements.map((element) => {
		const title = getProperty('title', element.custom_fields),
			content = getProperty('content', element.custom_fields),
			[link] = element.links,
			[image] = element.media;

		return {
			title: `${title}`,
			description: `${content}`,
			link,
			image: { path: `${image.path}`, alt: `${image.alt}` },
		};
	});

	return (props) => (
		<DetailInfoSection {...props} title={`${title}`} subtext={`${subtext}`} links={links} items={items} />
	);
};

import { AnswerOption, YesNo } from '@monorepo/types';

export function mockGetAnswerOption(answer: YesNo): AnswerOption {
	return {
		key: answer,
		value: answer === 'J' ? 'Ja' : ' Nee',
	};
}

export function mockGetAnswerOptions(num = 2): AnswerOption[] {
	const options: AnswerOption[] = [];
	for (let i = 0; i < num; i++) {
		options.push(mockGetAnswerOption(i === 0 ? 'J' : 'N'));
	}
	return options;
}

import { faker } from '@faker-js/faker/locale/nl';

import { Contact } from '@monorepo/types';

export function mockGetContact(): Contact {
	const firstName = faker.person.firstName(),
		lastName = faker.person.lastName();
	return {
		first_name: firstName,
		last_name: lastName,
		email: faker.internet.email({ firstName, lastName }),
		phone: '06########'.replace(/#+/g, (m) => faker.string.numeric(m.length)),
	};
}

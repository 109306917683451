import { staticMapUrl } from 'static-google-map';

import { MapsColorFormat, type Address, type HEX, type Theme, type ThemeNames } from '@monorepo/types';

import { themes } from '../theme/themeDefinitions';

/**
 * Return a URL to fetch a static Google Map image for a given address
 * @see https://mapstyle.withgoogle.com/ to style the map
 * @param address
 * @param label used to fetch theme color
 */
export function getStaticMapUrl(address: Address, label: ThemeNames): string {
	const theme: Theme = themes[label];

	const style = `style=element:geometry|color:${toMapsColorFormat(theme.colors.grayscale600)}
	&style=element:geometry.fill|color:${toMapsColorFormat(theme.colors.grayscale200)}
	&style=element:geometry.stroke|color:${toMapsColorFormat(theme.colors.grayscale100)}|visibility:on
	&style=element:labels.icon|visibility:off
	&style=element:labels.text|color:${toMapsColorFormat(theme.colors.grayscale600)}
	&style=element:labels.text.fill|color:${toMapsColorFormat(theme.colors.grayscale600)}
	&style=element:labels.text.stroke|color:${toMapsColorFormat(theme.colors.grayscale0)}
	&style=feature:administrative.land_parcel|element:labels|visibility:off
	&style=feature:poi|element:geometry|color:${toMapsColorFormat(theme.colors.grayscale100)}
	&style=feature:poi|element:labels.text|visibility:off
	&style=feature:poi.business|visibility:off
	&style=feature:poi.park|element:geometry|color:${toMapsColorFormat(theme.colors.grayscale300)}
	&style=feature:poi.park|element:labels.text.fill|color:${toMapsColorFormat(theme.colors.primaryMain)}
	&style=feature:road|element:geometry|color:${toMapsColorFormat(theme.colors.grayscale0)}
	&style=feature:road|element:labels.icon|visibility:off
	&style=feature:road.arterial|element:labels|visibility:off
	&style=feature:road.highway|element:labels|visibility:off
	&style=feature:road.highway|element:geometry|color:${toMapsColorFormat(theme.colors.grayscale100)}
	&style=feature:road.local|visibility:off
	&style=feature:road.local|element:labels|visibility:off
	&style=feature:transit|visibility:off
	&style=feature:water|element:geometry|color:${toMapsColorFormat(theme.colors.grayscale300)}
	&style=feature:water|element:labels.text.fill|color:${toMapsColorFormat(theme.colors.grayscale400)}`;

	return staticMapUrl({
		key: process.env.NEXT_PUBLIC_MAPS_API_KEY,
		scale: 4,
		size: '1000x300',
		zoom: 13,
		format: 'png',
		maptype: 'roadmap',
		style,
		markers: [
			{
				location: `${address?.street}+${address?.house_number}+${address?.postal_code}+${address?.city}`,
				color: toMapsColorFormat(theme.colors.primaryMain),
				scale: 4,
			},
		],
	});
}

export function toMapsColorFormat(color: HEX): MapsColorFormat {
	return `0x${color.replaceAll('#', '')}`;
}

import z from 'zod';

import { TranslationType } from '@monorepo/types';

import { D_DATE_FORMAT } from '../utils';

export const uboConsentSchema = (t: TranslationType) =>
	z.object({
		consent: z.literal<boolean>(true, { required_error: t('validation.required') }),
	});

export type UboConsentSchema = z.infer<ReturnType<typeof uboConsentSchema>>;

export const uboSchema = (t: TranslationType) => {
	const minDate = new Date(new Date().getFullYear() - 150, new Date().getMonth(), new Date().getDay());

	return z.object({
		initials: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		surname: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		date_of_birth: z.string({ required_error: t('validation.required') }).superRefine((value, ctx) => {
			if (!value) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.required'),
				});
			}

			const parts = value.replaceAll(' ', '').split('-');

			if (parts.join('').length !== 8) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.formatSuggestion', { format: D_DATE_FORMAT.toLowerCase() }),
				});
			}

			const rearrangedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
			const parsedDate = new Date(rearrangedDate);

			const todayDate = new Date();
			todayDate.setHours(0, 0, 0, 0);

			if (isNaN(parsedDate.getTime())) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.formatSuggestion', { format: D_DATE_FORMAT.toLowerCase() }),
				});
			}

			if (parsedDate >= todayDate) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.dateValidBirthday'),
				});
			}

			if (parsedDate <= minDate) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t('validation.dateValidBirthday'),
				});
			}
		}),
		city: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		country: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
	});
};

export type UbosSchema = z.infer<ReturnType<typeof uboSchema>>;

export const emptyUbo: UbosSchema = {
	initials: '',
	surname: '',
	date_of_birth: '',
	city: '',
	country: 'NL',
};
// export type UbosSchemaSingle = InferType<ReturnType<typeof ubosSchemaSingle>>;

export const ubosSchemaArray = (t: TranslationType) =>
	z.object({
		ubos: z.array(uboSchema(t), { required_error: t('validation.required') }),
	});

export type UbosSchemaArray = z.infer<ReturnType<typeof ubosSchemaArray>>;

import classNames from 'classnames';
import { animate, motion, useMotionValue } from 'framer-motion';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { useIsMobile } from '@monorepo/shared/lib/hooks';
import { getReviewsSortedByLength } from '@monorepo/shared/lib/utils';

import { useGetTrustpilotReviewsQuery } from '@common/store';
import { FindCompanyActionButton } from '../FindCompanyActionButton/FindCompanyActionButton';
import { TestimonialCard } from './Testimonial/TestimonialCard';

export type TestimonialMultiSliderSectionProps = {
	title?: string;
	className?: string;
};

export const TestimonialMultiSliderSection: FC<TestimonialMultiSliderSectionProps> = ({ title, className }) => {
	const { data } = useGetTrustpilotReviewsQuery({ stars: [4, 5] });

	const reviews = useMemo(() => {
		return data?.reviews && data.reviews.length > 0 ? getReviewsSortedByLength(data.reviews) : [];
	}, [data]);

	const { isMobile } = useIsMobile();
	const x = useMotionValue(0);
	const containerRef = useRef<HTMLDivElement>(null);
	const [index, setIndex] = useState<number>(0);

	const WIDTH_OF_TESTIMONIAL = 375;
	const GAP_BETWEEN_TESTIMONIALS = 32;

	const isPreviousDisabled = useMemo(() => {
		return index === 0;
	}, [index]);

	const isNextDisabled = useMemo(() => {
		if (isMobile) {
			return index === reviews.length - 1;
		}
		const totalWidthOfContainer = containerRef.current?.clientWidth || 0;

		const totalWidthOfTestimonials = WIDTH_OF_TESTIMONIAL * reviews.length + GAP_BETWEEN_TESTIMONIALS * reviews.length;

		const currentElapsedWidth = index * (GAP_BETWEEN_TESTIMONIALS + WIDTH_OF_TESTIMONIAL);

		return totalWidthOfTestimonials - currentElapsedWidth < totalWidthOfContainer + GAP_BETWEEN_TESTIMONIALS;
	}, [isMobile, index, reviews.length]);

	const calculateNewX = () => {
		if (isMobile) {
			return -index * ((containerRef.current?.clientWidth || 0) + GAP_BETWEEN_TESTIMONIALS);
		}

		return -index * (WIDTH_OF_TESTIMONIAL + GAP_BETWEEN_TESTIMONIALS);
	};

	const handleNext = () => {
		setIndex(index + 1 === reviews.length ? index : index + 1);
	};

	const handlePrevious = () => {
		setIndex(index - 1 < 0 ? 0 : index - 1);
	};

	useEffect(() => {
		const controls = animate(x, calculateNewX());

		return () => controls.stop();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index]);

	return (
		<section className={classNames('overflow-x-hidden', className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-48'>
					{title && (
						<div>
							<Text variant='display-4' weight='semibold' color='primaryMain'>
								{title}
							</Text>
						</div>
					)}
					<motion.div ref={containerRef} style={{ x }} className='flex flex-row gap-32'>
						{reviews.map((review, key) => (
							<TestimonialCard
								key={`testimonial-card-${key}`}
								testimonial={review.text}
								name={review.consumer.displayName}
								rating={review.stars}
							/>
						))}
					</motion.div>
					<div className='flex flex-row justify-end space-x-24'>
						<FindCompanyActionButton icon='arrow-left' onClick={handlePrevious} disabled={isPreviousDisabled} />
						<FindCompanyActionButton icon='arrow-right' onClick={handleNext} disabled={isNextDisabled} />
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

/**
 * Checks if app runs on production
 */
export const isProduction: boolean = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';
export const isTest: boolean = process.env.NODE_ENV === 'test';
export const isDevelopment: boolean = process.env.NODE_ENV === 'development';
export const isAcceptance: boolean = process.env.NEXT_PUBLIC_ENVIRONMENT === 'acceptance';
export const isMocking: boolean = process.env.NEXT_PUBLIC_API_MOCKING === 'enabled';

export const serversideRequestUrl = `${process.env.NEXT_PUBLIC_NOVULO_URL}/${process.env.NEXT_PUBLIC_NOVULO_API_PATH}`;

import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Form } from '@monorepo/components/DataEntry';
import { SendPremiumSchema } from '@monorepo/shared/lib/schemas';

type ContactFormProps = {
	includeInititials?: boolean;
	includeDateOfBirth?: boolean;
	onSubmit: (data: SendPremiumSchema) => void;
};

export const ContactForm: FC<ContactFormProps> = ({ includeInititials = false, onSubmit }) => {
	const { t } = useTranslation(['common']);

	return (
		<Form.Context<SendPremiumSchema>>
			{({ handleSubmit, formState: { errors }, register }) => {
				return (
					<Form
						id='contact_details_form'
						onSubmit={handleSubmit(onSubmit)}
						className='grid grid-cols-1 gap-x-20 gap-y-20 sm:gap-y-24 md:grid-cols-4'>
						<Form.Group className='col-span-full md:col-span-1' isRendered={includeInititials}>
							<Form.Label htmlFor='last_name'>{t('common.initials')}</Form.Label>
							<Form.TextInput
								fieldSize='lg'
								autoComplete='additional-name'
								name='initials'
								errors={errors?.initials}
								register={register}
								placeholder={t('common.initials')}
								data-testid='contact-form-initials'
							/>
						</Form.Group>

						<Form.Group className={classNames('col-span-full', includeInititials ? 'md:col-span-3' : 'md:col-span-2')}>
							<Form.Label>{t('common.firstName')}</Form.Label>
							<Form.TextInput
								fieldSize='lg'
								autoComplete='first-name'
								name='first_name'
								title='first_name'
								autoCapitalize='words'
								placeholder={t('common.yourFirstname')}
								errors={errors?.first_name}
								register={register}
								className='w-full'
								data-testid='contact-form-firstname'
							/>
						</Form.Group>

						<Form.Group className='col-span-full md:col-span-2'>
							<Form.Label>{t('common.lastName')}</Form.Label>
							<Form.TextInput
								fieldSize='lg'
								name='last_name'
								autoComplete='first-name'
								title='last_name'
								autoCapitalize='words'
								placeholder={t('common.yourLastname')}
								errors={errors?.last_name}
								register={register}
								className='w-full'
								data-testid='contact-form-lastname'
							/>
						</Form.Group>

						<Form.Group className='col-span-full'>
							<Form.Label>{t('common.email')}</Form.Label>
							<Form.EmailInput
								fieldSize='lg'
								name='email'
								title='email'
								autoCapitalize='off'
								errors={errors?.email}
								startIcon='envelope'
								placeholder={t('common.yourEmail')}
								register={register}
								className='w-full'
								data-testid='contact-form-email'
							/>
						</Form.Group>

						<Form.Group className='col-span-full'>
							<Form.Label>{t('common.mobilePhone')}</Form.Label>
							<Form.PhoneInput
								fieldSize='lg'
								name='phone'
								title='phone'
								autoCapitalize='off'
								placeholder='0642132455'
								startIcon='mobile-android'
								errors={errors?.phone}
								register={register}
								className='w-full'
								data-testid='contact-form-phone'
							/>
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};

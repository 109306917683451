import classNames from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';

import { Icon } from '@monorepo/components/Misc';

type FindCompanyActionButtonProps = { icon: string } & ButtonHTMLAttributes<HTMLButtonElement>;

export const FindCompanyActionButton: FC<FindCompanyActionButtonProps> = ({ icon, className, ...props }) => (
	<button
		type='button'
		className={classNames(
			'bg-info300 hover:bg-info400 active:bg-info500 rounded-12 disabled:bg-grayscale50 group group h-48 w-48 transition-colors md:h-64 md:w-64',
			className
		)}
		{...props}>
		<Icon name={icon} size='xl' color='grayscale0' className='group-disabled:text-grayscale200 transition-colors' />
	</button>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { affinityGroupDataMap } from '@monorepo/shared/lib/utils';
import { ThemeNames } from '@monorepo/types';

import { AppState } from '@common/store';
import { paths } from '@common/utils';

export type ThemeState = {
	theme: ThemeNames;
	displaySalesChannel: string;
	phoneNumber: string;
	phoneNumberLink: `tel:${string}`;
	email: string;
	insuranceProvider: string;
	funnelEntryPoint: string;
};

const initialState: ThemeState = {
	...affinityGroupDataMap['maia'],
	theme: 'maia',
	funnelEntryPoint: '/funnel',
};

export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		setTheme(_, action: PayloadAction<ThemeNames>) {
			return {
				theme: action.payload,
				displaySalesChannel: affinityGroupDataMap[action.payload]?.displaySalesChannel,
				phoneNumber: affinityGroupDataMap[action.payload]?.phoneNumber,
				phoneNumberLink: affinityGroupDataMap[action.payload]?.phoneNumberLink,
				email: affinityGroupDataMap[action.payload]?.email,
				insuranceProvider: affinityGroupDataMap[action.payload]?.insuranceProvider,
				funnelEntryPoint:
					action.payload === 'nvm' || action.payload === 'vbo' || action.payload === 'vastgoedpro'
						? paths.funnel.real_estate_agents
						: paths.funnel.company.search,
			};
		},
	},
});

export const { setTheme } = themeSlice.actions;

export const selectThemeContext = (state: AppState) => state.theme;
export const selectTheme = (state: AppState) => state.theme.theme;

import {
	fetchAndActivate,
	getAll,
	getRemoteConfig,
	isSupported,
	type RemoteConfig,
	type Value,
} from 'firebase/remote-config';
import { FC, ReactNode, useEffect, useState } from 'react';

import { isProduction } from '@monorepo/shared/lib/utils';

import { FlagsContext } from '@common/contexts';
import { useFirebase } from '@common/providers';

type FlagsProps = {
	children: ReactNode;
};

export const FirebaseRemoteConfigProvider: FC<FlagsProps> = ({ children }) => {
	const [config, setConfig] = useState<Record<string, Value>>({});
	const firebaseApp = useFirebase();

	useEffect(() => {
		let remoteConfig: RemoteConfig;

		if (firebaseApp) {
			isSupported()
				.then(() => {
					remoteConfig = getRemoteConfig(firebaseApp);
					remoteConfig.settings = {
						minimumFetchIntervalMillis: isProduction ? 43200000 : 10000,
						fetchTimeoutMillis: isProduction ? 60000 : 10000,
					};
					return remoteConfig;
				})
				.then(() => fetchAndActivate(remoteConfig))
				.then(() => getAll(remoteConfig))
				.then((remoteConfigValues: Record<string, Value>) => {
					setConfig(remoteConfigValues);
				})
				.catch((error) => console.error(error));
		}
	}, [firebaseApp]);

	const getString = (key: string): string => {
		return config[key]?.asString() ?? '';
	};

	const getNumber = (key: string): number => {
		return config[key]?.asNumber() ?? 0;
	};

	const getBoolean = (key: string): boolean => {
		return config[key]?.asBoolean() ?? false;
	};

	return (
		<FlagsContext.Provider
			value={{
				getString,
				getNumber,
				getBoolean,
			}}>
			{children}
		</FlagsContext.Provider>
	);
};

import { faker } from '@faker-js/faker/locale/nl';

import { HistoricalInsurance } from '@monorepo/types';

export function mockGetCurrentInsurance(): HistoricalInsurance {
	return {
		guid: faker.string.alphanumeric(16),
		// @ts-ignore - Add this here for mocks?
		product: {
			guid: faker.string.alphanumeric(16),
			name: faker.commerce.productName(),
			code: faker.string.alphanumeric(),
		},
		product_guid: faker.string.alphanumeric(16),
		insurer: faker.company.name(),
		policy_identifier: faker.string.alphanumeric(6),
	};
}

export function mockGetCurrentInsurances(num = 2): HistoricalInsurance[] {
	const currentInsurances: HistoricalInsurance[] = [];
	for (let i = 0; i < num; i++) {
		currentInsurances.push(mockGetCurrentInsurance());
	}
	return currentInsurances;
}

import { TranslationType, TrustpilotReview } from '@monorepo/types';

import { sortReviewsByReviewLength } from '../sort/sort';

/**
 * Maps a rating to a number
 * Docs:	 https://developers.trustpilot.com/resources-api#get-the-string-representation-of-the-stars
 * Response: https://api.trustpilot.com/v1/resources/strings/stars/1.5?apikey=iogc1mFVmwuxfh6zQWwGCAW9AqqmSTW5
 */
export const mapRatingToTrustpilotText = (number: number, t: TranslationType): string | undefined => {
	const ratingTexts: Record<number, string> = {
		0: t('trustpilot.ratings.0'),
		1: t('trustpilot.ratings.1'),
		1.5: t('trustpilot.ratings.1_5'),
		2: t('trustpilot.ratings.2'),
		2.5: t('trustpilot.ratings.2_5'),
		3: t('trustpilot.ratings.3'),
		3.5: t('trustpilot.ratings.3_5'),
		4: t('trustpilot.ratings.4'),
		4.5: t('trustpilot.ratings.4_5'),
		5: t('trustpilot.ratings.5'),
	};

	let closestKey: number | undefined = undefined;
	let closestDistance: number | undefined = undefined;

	for (const key in ratingTexts) {
		const numericKey = parseFloat(key);
		const distance = Math.abs(number - numericKey);

		if (closestDistance === undefined || distance < closestDistance) {
			closestKey = numericKey;
			closestDistance = distance;
		}
	}

	return closestKey !== undefined ? ratingTexts[closestKey] : undefined;
};

export const getReviewsSortedByLength = (
	reviews: TrustpilotReview[],
	maximumResults: number = 8
): TrustpilotReview[] => {
	return [...reviews].sort((a, b) => sortReviewsByReviewLength(a.text, b.text)).splice(0, maximumResults);
};

export const trustpilotCompanyName = 'maia.insure';
export const trustpilotCompanyUrl = 'https://nl.trustpilot.com/review/maia.insure';
export const trustpilotApiPath = 'https://api.trustpilot.com/v1';

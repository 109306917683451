import { IInsuranceOverview, IInsuranceOverviewCard } from '@monorepo/types';

const cards: IInsuranceOverviewCard[] = [
	{
		featured: true,
		minimumPrice: 69.69,
		icon: 'apps',
		title: 'Voor alle ZZPers & MKBers',
		description:
			'"**Maia, jouw zekerheid** – Ondernemen zonder zorgen. **Jouw keuze, jouw controle** – Verzeker wat, wanneer en hoe jij wilt. **Naaste partner** – Echte support, precies wanneer je het nodig hebt."',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
	{
		minimumPrice: 69.69,
		icon: 'constructor',
		title: 'Werknemersschade',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
	{
		minimumPrice: 69.69,
		icon: 'band-aid',
		title: 'Ongevallen verzekering',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
	{
		minimumPrice: 69.69,
		icon: 'globe',
		title: 'Cyber',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
	{
		minimumPrice: 69.69,
		icon: 'car-sideview',
		title: 'Zakelijke auto',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
	{
		minimumPrice: 69.69,
		icon: 'university',
		title: 'Rechtsbijstand',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.',
		link: {
			content: 'Bekijk niche',
			href: '/404',
			title: 'Bekijk niche',
			target: '_self',
		},
	},
];

export const mockInsuranceOverview: IInsuranceOverview = {
	items: [
		{
			category: {
				icon: 'apps',
				text: 'Alles',
			},
			cards,
		},
		{
			category: {
				icon: 'constructor',
				text: 'Vastgoed & taxatie',
			},
			cards,
		},
		{
			category: {
				icon: 'globe',
				text: 'Haar & Beauty',
			},
			cards,
		},
		{
			category: {
				icon: 'car-sideview',
				text: 'Afwerking & onderhoud',
			},
			cards,
		},
		{
			category: {
				icon: 'university',
				text: 'Bouw & aannemers',
			},
			cards,
		},
	],
};

import 'react-datepicker/dist/react-datepicker.css';

import cn from 'classnames';
import { nl } from 'date-fns/locale/nl';
import { registerLocale } from 'react-datepicker';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

import {
	baseClasses,
	disabledClasses,
	fieldSizeLgClasses,
	fieldSizeMdClasses,
	fieldSizeSmClasses,
	focusClasses,
	FormBaseInputDefaultProps,
	FormBaseInputProps,
	hoverClasses,
} from '../FormBaseInput/FormBaseInput';
import { FormBaseInputWrapper } from '../FormBaseInputWrapper/FormBaseInputWrapper';
import { FormHelper } from '../FormHelper/FormHelper';

registerLocale('nl', nl);

export type FormSimpleDateInputProps<TFormValues extends FieldValues> = {
	hint?: string;
	hideError?: boolean;
	control: Control<TFormValues>;
	onEndIconClick?: () => void;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<FormBaseInputProps, 'name' | 'errors' | 'type'>;

export const FormSimpleDateInput = <TFormValues extends Record<string, unknown>>({
	name,
	fieldSize = 'md',
	errors,
	hint,
	hideError,
	placeholder,
	control,
	onEndIconClick,
	defaultValue,
	startIcon,
	endIcon,
	className,
	...props
}: FormSimpleDateInputProps<TFormValues>): JSX.Element => {
	return (
		<FormHelper errors={errors} hint={hint} hideError={hideError}>
			{({ isError }) => (
				<Controller
					control={control}
					defaultValue={defaultValue as PathValue<TFormValues, Path<TFormValues>>}
					name={name}
					render={({ field: { name, onChange, onBlur, value, ref }, fieldState: { isDirty } }) => (
						<FormBaseInputWrapper
							fieldSize={fieldSize}
							isDisabled={props.disabled}
							isDirty={isDirty}
							isError={isError}
							isEmpty={!value}
							startIcon={startIcon}
							endIcon={endIcon}
							onEndIconClick={() => {
								onEndIconClick && onEndIconClick();
							}}>
							<PatternFormat
								{...props}
								getInputRef={ref}
								format='##-##-####'
								className={cn(
									baseClasses.replace('typography-body-m', 'typography-body-l'),
									disabledClasses,
									hoverClasses,
									focusClasses,
									isError && 'border-error300',
									!isError && 'border-grayscale200',
									startIcon && fieldSize === 'lg' && 'pl-50',
									startIcon && fieldSize === 'md' && 'pl-40',
									startIcon && fieldSize === 'sm' && 'pl-40',
									endIcon && 'pr-10',
									fieldSize === 'sm' && fieldSizeSmClasses,
									fieldSize === 'md' && fieldSizeMdClasses,
									fieldSize === 'lg' && fieldSizeLgClasses,
									'w-full',
									className
								)}
								autoComplete='off'
								onChange={onChange}
								disabled={props.disabled}
								id={name}
								name={name}
								onBlur={onBlur}
								defaultValue={`${value}`}
								value={`${value}`}
								placeholder={placeholder}
							/>
						</FormBaseInputWrapper>
					)}
				/>
			)}
		</FormHelper>
	);
};

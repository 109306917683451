import { z } from 'zod';

import { TranslationType } from '@monorepo/types/src/common/translation.type';

export const oneTimeDepositRange = [0, 500, 1000, 2500, 5000, 10000, 25000, 50000, 100000];
export const depositPerMonthRange = [
	50, 100, 150, 200, 250, 300, 350, 400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000,
];

export const pensionPlanSchema = (t: TranslationType) =>
	z.object({
		age: z.coerce
			.number()
			.min(18, t('validation.min', { min: 18 }))
			.max(61, t('validation.max', { max: 60 })),
		retirementAge: z.coerce
			.number()
			.min(18, t('validation.min', { min: 18 }))
			.max(76, t('validation.max', { max: 75 })),
		oneTimeDeposit: z.coerce
			.number()
			.min(oneTimeDepositRange[0], t('validation.required'))
			.max(oneTimeDepositRange[oneTimeDepositRange.length], t('validation.required')),
		depositPerMonth: z.coerce
			.number()
			.min(depositPerMonthRange[0], t('validation.required'))
			.max(depositPerMonthRange[depositPerMonthRange.length], t('validation.required')),
	});

export type PensionPlanSchema = z.infer<ReturnType<typeof pensionPlanSchema>>;

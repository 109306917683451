import cn from 'classnames';
import React, { FC } from 'react';

import { ThemeColors, ThemeTypography, ThemeTypographyWeight } from '@monorepo/types';

import { Icon } from '../../../Misc';
import { Text } from '../Text/Text';

type UspSize = 'sm' | 'md' | 'lg';
type UspWeight = ThemeTypographyWeight;

type UspProps = {
	text: string;
	iconColor: ThemeColors;
	iconSize?: UspSize;
	size: UspSize;
	uspWeight?: UspWeight;
};

const sizes: Record<UspSize, ThemeTypography> = {
	sm: 'body-s',
	md: 'body-m',
	lg: 'body-l',
};

const Usp: FC<UspProps> = ({ text, iconColor, size, iconSize, uspWeight = 'medium' }) => {
	return (
		<li className='flex w-full flex-1 flex-row items-start space-x-8'>
			<Icon name='check' color={iconColor} size={iconSize} className='leading-none' />
			<Text as='span' color='grayscale500' variant={sizes[size]} weight={uspWeight}>
				{text}
			</Text>
		</li>
	);
};

type UspRowProps = {
	texts: string[];
	iconColor?: ThemeColors;
	iconSize?: UspSize;
	size?: UspSize;
	uspWeight?: UspWeight;
	className?: string;
};

export const UspRow: FC<UspRowProps> = ({
	texts,
	iconColor = 'primaryMain',
	size = 'md',
	iconSize = size,
	uspWeight = 'medium',
	className = '',
}) => {
	return (
		<ul className={cn(className, 'flex flex-col flex-wrap items-center space-y-12 ')}>
			{texts.map((text, index) => (
				<Usp
					key={`usp-${index}`}
					text={text}
					size={size}
					iconSize={iconSize}
					iconColor={iconColor}
					uspWeight={uspWeight}
				/>
			))}
		</ul>
	);
};

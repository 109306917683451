import classNames from 'classnames';
import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { DynamicColumnSection } from '@marketing/components';

/**
 * TODO: The idea is to make this dynamic, meaning the columns are
 * based on the amount of children received and what should be
 * rendered is based on the received children
 *
 * ! Important: Right now this component is only used on the maia.insure/landing/marketing/makelaars page
 * ! When this component will be used more often, we should make it more dynamic, right now it is only made for that page
 */
export const handleSectionColumnDynamic = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const cardElements = elements.filter(({ identifier }) => identifier === 'card') as CmsElement[];
	const cards = cardElements.map((card) => {
		const icon = getProperty('icon', card.custom_fields);
		const title = getProperty('title', card.custom_fields);
		const description = card.markdown;

		return { icon: `${icon}`, title: `${title}`, description: `${description}` };
	});

	return () => (
		<DynamicColumnSection
			blurbs={cards}
			className={classNames(rootContext?.path === 'landing/marketing/makelaars' && 'mt-48')}
		/>
	);
};

import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Icon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { transformToCurrency } from '@monorepo/shared/lib/utils';

export type PensionGraphData = {
	year: number;
	optimistic: number;
	pessimistic: number;
	realistic: number;
	inlay: number;
};

type PensionGraphProps = {
	isLoading?: boolean;
	data: Array<PensionGraphData>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PensionGraphXAxisTick: FC<any> = ({ x, y, payload }) => {
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={16} textAnchor='middle' className='fill-grayscale600 typography-body-xxs font-medium'>
				{payload.value}
			</text>
		</g>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PensionGraphYAxisTick: FC<any> = ({ x, y, payload }) => {
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={3} textAnchor='end' className='fill-grayscale600 typography-body-xxs font-medium'>
				{transformToCurrency(payload.value)}
			</text>
		</g>
	);
};

const PensionGraphLegend: FC<{ className?: string }> = ({ className }) => {
	const { t } = useTranslation(['common']);

	return (
		<div className={classNames('flex flex-row items-center gap-x-12', className)}>
			<div className='flex flex-row items-center gap-8'>
				<div className='border-t-success300 h-[1px] w-12 border-t' />
				<Text variant='body-xxs' weight='medium' color='grayscale600'>
					{t('components.pensionGraph.optimistic')}
				</Text>
			</div>

			<div className='flex flex-row items-center gap-8'>
				<div className='border-t-primaryMain h-[1px] w-12 border-t' />
				<Text variant='body-xxs' weight='medium' color='grayscale600'>
					{t('components.pensionGraph.realistic')}
				</Text>
			</div>

			<div className='flex flex-row items-center gap-8'>
				<div className='border-t-error300 h-[1px] w-12 border-t' />
				<Text variant='body-xxs' weight='medium' color='grayscale600'>
					{t('components.pensionGraph.pessimistic')}
				</Text>
			</div>

			<div className='flex flex-row items-center gap-8'>
				<div className='border-t-grayscale600 h-[1px] w-12 border-t border-dashed' />
				<Text variant='body-xxs' weight='medium' color='grayscale600'>
					{t('components.pensionGraph.inlay')}
				</Text>
			</div>
		</div>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PensionGraphTooltip: FC<any> = ({ active, payload }) => {
	const { t } = useTranslation(['common']);

	if (active && payload?.length) {
		return (
			<div className='bg-grayscale0 rounded-6 shadow-1 p-4'>
				<Text variant='body-xs' weight='medium' color='grayscale600'>
					{t('common.yearX', { year: payload?.[0]?.payload?.year })}
				</Text>

				<ul className='mt-4'>
					<li className='flex flex-row items-center gap-8'>
						<div className='border-t-success300 h-[1px] w-12 border-t' />
						<Text variant='body-xxs' weight='medium' color='grayscale600'>
							{transformToCurrency(payload?.[0]?.payload?.optimistic ?? 0)}
						</Text>
					</li>

					<li className='flex flex-row items-center gap-8'>
						<div className='border-t-primaryMain h-[1px] w-12 border-t' />
						<Text variant='body-xxs' weight='medium' color='grayscale600'>
							{transformToCurrency(payload?.[0]?.payload?.realistic ?? 0)}
						</Text>
					</li>

					<li className='flex flex-row items-center gap-8'>
						<div className='border-t-error300 h-[1px] w-12 border-t' />
						<Text variant='body-xxs' weight='medium' color='grayscale600'>
							{transformToCurrency(payload?.[0]?.payload?.pessimistic ?? 0)}
						</Text>
					</li>

					<li className='flex flex-row items-center gap-8'>
						<div className='border-t-grayscale600 h-[1px] w-12 border-t border-dashed' />
						<Text variant='body-xxs' weight='medium' color='grayscale600'>
							{transformToCurrency(payload?.[0]?.payload?.inlay ?? 0)}
						</Text>
					</li>
				</ul>
			</div>
		);
	}

	return null;
};

export const PensionGraph: FC<PensionGraphProps> = ({ data, isLoading = false }) => {
	const { t } = useTranslation(['common']);

	return (
		<div className='bg-grayscale50 rounded-18 h-full p-24'>
			<div>
				<div className='flex flex-row items-center justify-between gap-12'>
					<Text variant='body-s' weight='medium' color='grayscale600'>
						{t('components.pensionGraph.title')}
					</Text>

					<PensionGraphLegend className='max-sm:hidden' />
				</div>

				<div className='relative mt-16 h-[250px] min-h-[250px]'>
					{isLoading && (
						<div className='rounded-6 absolute inset-0 z-10 flex items-center justify-center overflow-hidden'>
							<Icon name='spinner' size='md' color='inherit' className='animate-spin' />
						</div>
					)}

					{data.length > 0 && (
						<ResponsiveContainer width='100%' height='100%'>
							<LineChart
								width={500}
								height={300}
								data={data}
								margin={{
									top: 10,
									right: 30,
									left: 18,
									bottom: 0,
								}}>
								<XAxis dataKey='year' tick={<PensionGraphXAxisTick />} />
								<YAxis tick={<PensionGraphYAxisTick />} />
								<Tooltip content={<PensionGraphTooltip active={false} payload={[]} />} />
								<Line
									type='linear'
									dataKey='inlay'
									dot={false}
									stroke='var(--colors-grayscale600)'
									strokeDasharray='3 3'
								/>

								<Line type='linear' dataKey='pessimistic' dot={false} strokeWidth={2} stroke='var(--colors-error300)' />
								<Line
									type='linear'
									dataKey='realistic'
									dot={false}
									strokeWidth={2}
									stroke='var(--colors-primaryMain)'
								/>
								<Line
									type='linear'
									dataKey='optimistic'
									dot={false}
									strokeWidth={2}
									stroke='var(--colors-success300)'
								/>
							</LineChart>
						</ResponsiveContainer>
					)}
				</div>

				<div className='mt-12 flex justify-center sm:hidden'>
					<PensionGraphLegend />
				</div>
			</div>
		</div>
	);
};

import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { MenuItem } from '@monorepo/types';

import { animationKeyMap, companySettingsAnimations } from '../../../../lib/utils';
import { Icon, ToggleExpand } from '../../../Misc';
import { Text } from '../../../TextComponents';

type Props = {
	name: string;
	type: 'consumer' | 'company';
	menuLinks: MenuItem[];
};

export const MyMaiaSecondMenu: FC<Props> = ({ menuLinks, name = '', type }) => {
	const { t } = useTranslation(['common']);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const ref = useRef(null);

	useClickAway(ref, () => {
		setIsMenuOpen(false);
	});

	return (
		<div className='relative z-50 flex flex-col' ref={ref}>
			<div className='flex flex-row items-center p-12'>
				<Text as='p' variant='body-m' weight='semibold' color='grayscale500'>
					{t(type === 'company' ? 'common.company' : 'common.name')}:
				</Text>
				<Text as='p' variant='body-m' color='grayscale500' className='ml-4 pr-32'>
					{name}
				</Text>
				<ToggleExpand
					className='ml-auto'
					onClick={() => setIsMenuOpen(!isMenuOpen)}
					isOpen={isMenuOpen}
					initialAngle='down'
					openAngle='up'
				/>
			</div>

			{isMenuOpen && (
				<motion.div {...animationKeyMap} variants={companySettingsAnimations} layout className='relative'>
					<div className='shadow-1 bg-grayscale0 rounded-6 absolute w-full p-12'>
						<ul className='space-y-4'>
							{menuLinks.map(({ title, pathname, icon }) => (
								<li key={title} className='group'>
									<Link href={pathname} className='text-shades400 flex flex-row items-center'>
										<Icon
											name={icon}
											color='grayscale400'
											className='mr-16 transition-transform ease-out group-hover:translate-x-4'
										/>
										<Text
											variant='body-xs'
											weight='regular'
											color='grayscale400'
											className='transition-transform ease-out group-hover:translate-x-4'>
											{title}
										</Text>

										<Icon
											color='primary400'
											name='angle-right-b'
											className='ml-auto transition-all ease-out group-hover:translate-x-4 group-hover:scale-110'
										/>
									</Link>
								</li>
							))}
						</ul>
					</div>
				</motion.div>
			)}
		</div>
	);
};

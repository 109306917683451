import { FC, ReactNode } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { Text } from '@monorepo/components/TextComponents';
import { getProperty } from '@monorepo/shared/lib/cms';
import { INicheOverviewCard } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { DynamicTabbedOverview } from '@marketing/components/DynamicTabbedOverview/DynamicTabbedOverview';
import { InsuranceOverviewCardFeatured } from '@marketing/components/InsuranceOverviewCardFeatured/InsuranceOverviewCardFeatured';

const Paragraph = (props: ReactMarkdownProps) => (
	<Text as='p' variant='body-l' color='grayscale0'>
		{props.children}
	</Text>
);

const highlight = (props: ReactMarkdownProps) => (
	<Text as='span' variant='body-l' color='secondaryMain' weight='medium' {...props} />
);

const mdComponents: Components = {
	p: Paragraph,
	span: Paragraph,
	em: highlight,
};

export const handleDynamicTabbedOverview = <P extends object>({
	elements,
	identifier,
	rootContext,
}: HandlerPropsV2): FC<P> => {
	const workfieldCtaElements = elements.filter(
		({ identifier }) => identifier === 'workfield-cta' || identifier === 'workfield-cta-2'
	);

	const ctaSlots: ReactNode[] = workfieldCtaElements.map(({ custom_fields, links }, index) => {
		const categoryIcon = getProperty('icon_name', custom_fields);
		const headerTitle = getProperty('title', custom_fields);
		const headerContent = getProperty('subtext', custom_fields);
		const workfieldGuid = getProperty('workfield_guid', custom_fields);
		const [link] = links;

		const HeaderContent = () => {
			// Replace single '\n' with two '\n' to create a new paragraph
			const formattedHeaderContent = `${headerContent}`.replace(/\n/g, '\n\n');
			return <ReactMarkdown components={mdComponents}>{formattedHeaderContent}</ReactMarkdown>;
		};

		const headerCard: INicheOverviewCard = {
			icon: `${categoryIcon}`,
			title: `${headerTitle}`,
			description: headerContent && <HeaderContent />,
			link,
			featured: true,
			workfieldGuid: `${workfieldGuid}`,
		};

		return <InsuranceOverviewCardFeatured key={`featured-card-${index}`} {...headerCard} />;
	});

	const hasCtaSlotsTop = identifier === 'section-products-by-workfield';
	const hasCtaSlotsBottom = identifier === 'section-products-by-workfield-price';

	return (props) => (
		<DynamicTabbedOverview
			{...props}
			ctaSlotsTop={hasCtaSlotsTop ? ctaSlots : undefined}
			ctaSlotsBottom={hasCtaSlotsBottom ? ctaSlots : undefined}
			showNicheLink={hasCtaSlotsTop}
			path={rootContext?.path}
		/>
	);
};

import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { slugify } from '@monorepo/shared/lib/utils';
import { CmsLink, MarketingFooterProof, MarketingFooterSocialItem, WebLayoutVariants } from '@monorepo/types';

type SubFooterProps = {
	links: CmsLink[];
	socials: MarketingFooterSocialItem[];
	proofs: MarketingFooterProof[];
	variant: WebLayoutVariants;
	className?: string;
};

export const SubFooter: FC<SubFooterProps> = ({ links, socials, proofs, variant, className }) => {
	return (
		<div className={cn(className, 'bg-[#000000] py-32 md:py-16')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-1 flex-col max-md:space-y-32 max-md:space-y-reverse md:flex-row md:items-center md:space-x-32'>
					<aside className='relative order-3 col-span-full flex md:order-1'>
						<Link href='/' title={'Go to home'} className='flex-1'>
							<Image src='/images/logo/maia/logo-white.svg' alt='Logo' width={64} height={22} />
						</Link>
					</aside>
					<nav className='order-2 col-span-full flex flex-1 flex-col max-md:space-y-16 md:flex-row md:space-x-24'>
						{links.map(({ href, content, title }) => (
							<Link key={`subfooter-link-${href}`} className='underline' href={href} title={title}>
								<Text as='span' color='inherit' variant='body-s'>
									{content || title}
								</Text>
							</Link>
						))}
					</nav>
					<nav className='flex:none order-1 col-span-full flex items-center space-x-12 md:order-3'>
						{variant === 'default' &&
							socials.map(({ image, link }) => (
								<Link key={`social-link-${link.title}`} target={link.target} href={link.href} title={link.title}>
									<Image width={24} height={24} src={image.path} alt={image.alt} />
								</Link>
							))}
						<div className='!ml-auto gap-8 md:!ml-32'>
							{proofs.map(({ image }) => (
								<Image
									key={`subfooter-proof-${slugify(image.path)}`}
									width={68}
									height={30}
									src={image.path}
									alt={image.alt}
								/>
							))}
						</div>
					</nav>
				</div>
			</LayoutGrid>
		</div>
	);
};

import { useRouter } from 'next/router';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { MultipleProductCardFactory } from '@monorepo/shared/lib/factory';
import { useAnalytics } from '@monorepo/shared/lib/hooks';
import { CmsDynamicBundleResponse, CmsLink } from '@monorepo/types';

import { showDynamicModal } from '@common/store';

type Props = {
	bundle: CmsDynamicBundleResponse;
	link: CmsLink;
	className?: string;
};

export const DynamicOverviewBundleCard: FC<Props> = memo(({ bundle, link }) => {
	const dispatch = useDispatch();
	const { push } = useRouter();
	const { trackEvent } = useAnalytics();

	const handleMoreInfoClick = useCallback(
		(guid: string) => {
			dispatch(
				showDynamicModal({
					id: 'dynamic-content-modal',
					payload: {
						guid,
						type: 'container',
					},
				})
			);
		},
		[dispatch]
	);

	const handleChooseProduct = () => {
		trackEvent('start_funnel', { sales_channel: 'maia' });
		push(link?.href || '/aanvragen/bedrijf/zoeken');
	};

	return (
		<MultipleProductCardFactory
			type='cms'
			payload={{ products: bundle.products }}
			props={{
				guid: bundle.bundle_info_container_guid,
				name: bundle.name,
				minimum_price: bundle.minimum_price,
				onChooseProduct: handleChooseProduct,
				onMoreInfoClick: handleMoreInfoClick,
				className: 'h-fit col-span-1',
				cmsOverrides: { chooseProductButton: link },
			}}
		/>
	);
});

import { Calculation, CalculationForm, UpdateActiveCalculationRequest } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const calculationsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getCartCalculations: builder.query<Calculation[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/calculations`,
					method: 'GET',
				};
			},
			providesTags: ['Calculations'],
		}),

		generateCalculations: builder.mutation<void, { cart_guid: string }>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/calculations`,
					method: 'POST',
				};
			},
			invalidatesTags: ['Calculations'],
		}),

		updateActiveCalculation: builder.mutation<void, UpdateActiveCalculationRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/calculations`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['TransferableProduct'],
		}),

		getCartCalculationsForms: builder.query<CalculationForm[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/forms`,
					method: 'GET',
				};
			},
			providesTags: ['CalcuationsForm'],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetCartCalculationsQuery,
	useUpdateActiveCalculationMutation,
	useLazyGetCartCalculationsQuery,
	useGetCartCalculationsFormsQuery,
	useLazyGetCartCalculationsFormsQuery,
} = calculationsApi;

export const { generateCalculations, getCartCalculations, updateActiveCalculation, getCartCalculationsForms } =
	calculationsApi.endpoints;

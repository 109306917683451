import { getCookie, setCookie } from 'cookies-next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isProduction } from '@monorepo/shared/lib/utils';
import { NextPageWithLayout } from '@monorepo/types';

import { selectIsDeveloperMenuEnabled, setIsDeveloperMenuEnabled } from '@common/store';

const DeveloperMenuTrigger = dynamic(() => import('@common/components').then((Mod) => Mod.DeveloperMenuTrigger));

type Props = Record<string, unknown>;

export const withDeveloperTools = <P extends Props>(Component: NextPageWithLayout<P>) => {
	const displayName = Component.displayName || Component.name || 'Component';

	const ComponentWithDeveloperTools = (props: P) => {
		const isDeveloperMenuEnabled = useSelector(selectIsDeveloperMenuEnabled);
		const ENABLE_DEVELOPER_TOOLS = 'enable_developer_tools';
		const dispatch = useDispatch();
		const {
			query: { enabledevelopermenu },
			pathname,
		} = useRouter();

		useEffect(() => {
			if (!isProduction) {
				if (typeof enabledevelopermenu !== 'undefined') {
					dispatch(setIsDeveloperMenuEnabled(enabledevelopermenu === 'true'));
					setCookie(ENABLE_DEVELOPER_TOOLS, enabledevelopermenu === 'true');
				} else {
					const isDeveloperToolsEnabledFromCookie = getCookie(ENABLE_DEVELOPER_TOOLS);

					if (typeof isDeveloperToolsEnabledFromCookie !== 'undefined') {
						dispatch(setIsDeveloperMenuEnabled(isDeveloperToolsEnabledFromCookie === 'true'));
					} else {
						dispatch(setIsDeveloperMenuEnabled(true));
					}
				}
			}
		}, [dispatch, enabledevelopermenu, pathname]);

		return (
			<>
				<Component {...props} />
				{!isProduction && isDeveloperMenuEnabled && <DeveloperMenuTrigger />}
			</>
		);
	};

	ComponentWithDeveloperTools.displayName = `withDeveloperTools(${displayName})`;

	return ComponentWithDeveloperTools;
};

import cn from 'classnames';
import { ComponentPropsWithoutRef, createElement, ElementType, PropsWithChildren } from 'react';

type BaseCardProps<C extends ElementType> = {
	isElevated?: boolean;
	enableEffects?: boolean;
	as?: C;
	overruleRadius?: boolean;
};

type BaseCardWrapperProps<C extends ElementType> = PropsWithChildren<BaseCardProps<C>> & ComponentPropsWithoutRef<C>;

/**
 * Use this component to create a card with styling (roundings, shadows, borders and hover + active effects)
 */
export const BaseCard = <C extends ElementType>({
	as,
	isElevated = true,
	enableEffects = true,
	overruleRadius,
	className,
	...props
}: BaseCardWrapperProps<C>) => {
	return createElement(as || 'section', {
		className: cn(
			className,
			isElevated && 'shadow-1 border-transparent',
			enableEffects && 'hover:border-grayscale100 active:border-grayscale200 select-none cursor-pointer',
			enableEffects && isElevated && 'hover:shadow-none',
			!isElevated && 'border-grayscale100',
			!overruleRadius && 'rounded-12 md:rounded-18',
			'relative group border transition-all flex duration-300 ease-in-out'
		),
		...props,
	});
};

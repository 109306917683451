import { Calculation } from '@monorepo/types';

import { mockGetCalculationProduct } from './calculation-product.mock';

export function mockGetCalculation(guid?: string): Calculation {
	return {
		is_active: true,
		product: mockGetCalculationProduct(guid),
	};
}

import { useEffect, useState } from 'react';

export const useScrollDirection = () => {
	const [scrollDirection, setScrollDirection] = useState('none');
	const [prevScrollY, setPrevScrollY] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY;

			if (scrollY > prevScrollY) {
				setScrollDirection('down');
			} else if (scrollY < prevScrollY) {
				setScrollDirection('up');
			}

			setPrevScrollY(scrollY);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [prevScrollY]);

	return scrollDirection;
};

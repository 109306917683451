import { createElement, ElementType, KeyboardEvent, ReactElement, useEffect, useId, useRef } from 'react';

import { KeyboardKeys } from '../../../../lib/utils';
import { ReactTag, TabProps } from '../Tab';
import { useActions, useData } from '../Tab.store';

const DEFAULT_TAB_MENU_ITEM_TAG = 'button' as const;

type TabMenuItemProps<Tag extends ReactTag, Slot> = TabProps<Tag, Slot>;

export const TabMenuItem = <Tag extends ElementType = typeof DEFAULT_TAB_MENU_ITEM_TAG>(
	props: TabMenuItemProps<Tag, { selected: boolean }>
) => {
	const internalId = useId();
	const { selectedIndex, tabs, panels, orientation } = useData('Tab.MenuItem');
	const actions = useActions('Tab.MenuItem');

	const { id = `maia-ui-tab-menu-item-${internalId}`, as: ComponentFromProps, ...rest } = props;
	const menuItemRef = useRef<HTMLElement | null>(null);
	const myIndex = tabs.indexOf(menuItemRef);
	const selected = myIndex === selectedIndex;
	const ready = useRef(false);
	const slot = { selected };

	const resolvedChildren = (typeof props.children === 'function' ? props.children(slot) : props.children) as
		| ReactElement
		| ReactElement[];

	const handleSelection = () => {
		if (!ready.current) {
			ready.current = true;

			menuItemRef.current?.focus({ preventScroll: true });

			actions.change(myIndex);

			ready.current = false;
		}
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
		event.preventDefault();

		if (event.key === KeyboardKeys.Space || event.key === KeyboardKeys.Enter) {
			event.stopPropagation();

			actions.change(myIndex);
			return;
		}

		if (
			(orientation === 'vertical' && event.key === KeyboardKeys.ArrowDown) ||
			(orientation === 'horizontal' && event.key === KeyboardKeys.ArrowRight)
		) {
			const nextIndex = myIndex + 1;
			const after = [...tabs.slice(nextIndex)].filter((tab) => !tab.current?.hasAttribute('disabled'));

			if (after.length > 0) {
				after[0].current?.focus();

				const indexOfNewTab = tabs.indexOf(after[0]);
				actions.change(indexOfNewTab);
			}
		}

		if (
			(orientation === 'vertical' && event.key === KeyboardKeys.ArrowUp) ||
			(orientation === 'horizontal' && event.key === KeyboardKeys.ArrowLeft)
		) {
			const before = [...tabs.slice(0, myIndex)].filter((tab) => !tab.current?.hasAttribute('disabled')).reverse();

			if (before.length > 0) {
				before[0].current?.focus();
				actions.change(myIndex);

				const indexOfNewTab = tabs.indexOf(before[0]);
				actions.change(indexOfNewTab);
			}
		}
	};

	const Component = ComponentFromProps || DEFAULT_TAB_MENU_ITEM_TAG;
	const controlledProps = {
		ref: menuItemRef,
		onKeyDown: handleKeyDown,
		onClick: handleSelection,
		id,
		role: 'tab',
		type: 'button',
		'aria-controls': panels[myIndex]?.current?.id,
		'aria-selected': selected,
		tabIndex: selected ? 0 : -1,
	};

	useEffect(() => {
		actions.registerTab(menuItemRef);

		return () => actions.unregisterTab(menuItemRef);
	}, [actions, menuItemRef]);

	return createElement(
		Component,
		{
			...rest,
			...controlledProps,
		},
		resolvedChildren
	);
};

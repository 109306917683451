import cn from 'classnames';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { FC, ReactNode, useRef } from 'react';

import { MarketingCtaButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { CheckList, Text } from '@monorepo/components/TextComponents';
import { useIsMobile } from '@monorepo/shared/lib/hooks';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { CmsLink } from '@monorepo/types';

import { DetailHeroTitle } from '../DetailHero/DetailHeroTitle/DetailHeroTitle';

export type HeroWithScollingImageProps = {
	title: string;
	subtext?: string;
	images: {
		src: string;
		alt: string;
	}[];
	items?: {
		content: string;
	}[];
	content?: ReactNode;
	link: CmsLink;
	tagline: string;
	className?: string;
};

const desktopImageHoverTransition = {
	rest: {
		scale: 1,
		transition: { duration: 0.4, damping: 24 },
	},
	hover: {
		scale: 1.25,
		transition: { duration: 0.6 },
	},
};

const desktopImageBlurHoverTransition = {
	rest: {
		opacity: 0.4,
		transition: { duration: 0.4, damping: 24 },
	},
	hover: {
		opacity: 0,
		transition: { duration: 0.6 },
	},
};

export const HeroWithScrollingImage: FC<HeroWithScollingImageProps> = ({
	title,
	images,
	items,
	content,
	link,
	subtext,
	tagline,
	className,
}) => {
	const { isSmallTablet } = useIsMobile();

	const containerRef = useRef<HTMLDivElement>(null);
	const MOBILE_ANIMATION_TIME = images.length * 6;
	const DESKTOP_ANIMATION_TIME = images.length * 7;
	const TOTAL_WIDTH_OF_MOBILE_SLIDER = 216 * images.length + images.length * 16; // calculated by width of slide (216px) * total images + total images & space between slide

	const half = Math.ceil(images.length / 2);

	const desktopImageColumnGroups = [
		[[...images.slice(0, half)], [...images.slice(0, half)]],
		[[...images.slice(half)], [...images.slice(half)]],
	];

	return (
		<header className={cn(className, 'max-md:py-48 md:overflow-y-hidden')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 grid grid-cols-2'>
					<div className='col-span-full md:col-span-1 md:py-64'>
						<div className='col-start-2 flex flex-col space-y-32 overflow-hidden'>
							<div className='flex flex-col md:flex-row md:space-x-48'>
								<DetailHeroTitle className='w-full' title={title} subtext={subtext} />
							</div>
							{items && (
								<CheckList
									color='grayscale0'
									className='text-grayscale0'
									items={items.map((item) => ({ ...item, included: true }))}
								/>
							)}

							{content && content}

							{link && link.href && (
								<div className='flex flex-col space-y-12'>
									<MarketingCtaButton link={link} icon='angle-right' className='w-full md:w-fit' />

									{tagline && (
										<Text variant='body-s' color='grayscale0'>
											{tagline}
										</Text>
									)}
								</div>
							)}
						</div>
					</div>
					{!isSmallTablet && (
						<div className='relative col-span-1 max-md:hidden'>
							<div className='absolute inset-0 grid h-full w-full grid-cols-2 gap-x-16'>
								{desktopImageColumnGroups.map((columnGroup, columngGroupKey) => (
									<div
										key={`vertical-slide-column-group-${columngGroupKey}`}
										className={classNames(
											columngGroupKey === 0 && 'absolute bottom-0 left-0 flex w-[calc(50%-8px)] flex-col',
											columngGroupKey === 1 && 'absolute -bottom-[170px] right-0 flex w-[calc(50%-8px)] flex-col'
										)}>
										{columnGroup.map((column, columnKey) => (
											<motion.div
												key={`vertical-slide-column-${columnKey}-${columngGroupKey}`}
												transition={{ duration: DESKTOP_ANIMATION_TIME, ease: 'linear', repeat: Infinity }}
												initial={{ y: 0 }}
												animate={{ y: '100%' }}
												className='col-span-1 flex flex-col'>
												{column.map((image, imageKey) => (
													<motion.div
														key={`vertical-slide-image-${imageKey}-${columnKey}-${columngGroupKey}`}
														initial='rest'
														whileHover='hover'
														animate='rest'
														className='rounded-18 relative mb-16 h-[340px] w-full overflow-hidden'>
														<motion.div
															variants={desktopImageBlurHoverTransition}
															className='bg-primaryMain absolute inset-0 z-10 h-full w-full opacity-40'
														/>
														<motion.div className='relative h-full w-full' variants={desktopImageHoverTransition}>
															<Image
																sizes={generateSizesString()}
																priority
																src={image.src}
																alt={image.alt}
																fill
																className='object-cover object-center'
															/>
														</motion.div>
													</motion.div>
												))}
											</motion.div>
										))}
									</div>
								))}
							</div>
						</div>
					)}
				</div>

				{isSmallTablet && (
					<div className='col-span-full mt-40 overflow-hidden md:hidden' ref={containerRef}>
						<ul className='flex flex-row space-x-16'>
							{[0, 1].map((_, key1) => (
								<div className='flex flex-row space-x-16' key={`horizontal-slide-${key1}`}>
									{images.map((image, key2) => (
										<motion.div
											transition={{ duration: MOBILE_ANIMATION_TIME, ease: 'linear', repeat: Infinity }}
											initial={{ x: 0 }}
											animate={{ x: -TOTAL_WIDTH_OF_MOBILE_SLIDER }}
											key={`horizontal-slide-${key1}-${key2}`}>
											<div className='rounded-18 relative h-[100px] w-[216px] overflow-hidden'>
												<div className='bg-primaryMain absolute inset-0 z-10 opacity-40' />
												<Image
													sizes={generateSizesString()}
													priority
													src={image.src}
													alt={image.alt}
													className='object-cover'
													fill
												/>
											</div>
										</motion.div>
									))}
								</div>
							))}
						</ul>
					</div>
				)}
			</LayoutGrid>
		</header>
	);
};

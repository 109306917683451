import cn from 'classnames';
import { FC, ReactNode } from 'react';

import { TagStatus, ThemeColors } from '@monorepo/types';

import { Text } from '../../../TextComponents';

export type TagBg = `bg-${ThemeColors}`;

export type TagProps = {
	text?: string | ReactNode;
	status?: TagStatus;
	className?: string;
};

const tagClasses: {
	bgColor: Record<TagStatus, TagBg>;
	textColor: Record<TagStatus, ThemeColors>;
} = {
	bgColor: {
		default: 'bg-primary50',
		success: 'bg-success50',
		error: 'bg-error50',
		warning: 'bg-warning50',
		info: 'bg-info50',
	},
	textColor: {
		default: 'primaryMain',
		success: 'success300',
		error: 'error300',
		warning: 'warning300',
		info: 'info300',
	},
};

export const Tag: FC<TagProps> = ({ text, status = 'default', className = '' }) => {
	return (
		<div
			className={cn(
				className,
				tagClasses.bgColor[status],
				'rounded-12 flex w-fit items-center justify-center whitespace-nowrap px-12 py-4'
			)}>
			<Text as='span' variant='body-xs' weight='medium' color={tagClasses.textColor[status]}>
				{text}
			</Text>
		</div>
	);
};

type TagRowProps = {
	tags: TagProps[];
	className?: string;
	tagClassName?: string;
};

export const TagRow: FC<TagRowProps> = ({ tags, className = '', tagClassName = '' }) => {
	return (
		<div className={cn(className, 'flex flex-wrap gap-4 md:gap-8')}>
			{tags.map(({ text, status = 'default' }, index) => (
				<div className='inline-block' key={`tag-${index}`}>
					<Tag text={text} status={status} className={tagClassName} />
				</div>
			))}
		</div>
	);
};

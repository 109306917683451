import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IUspSection } from '@monorepo/types';

import { BlurbCard } from '../BlurbCard/BlurbCard';

type UspSectionProps = IUspSection & {
	className?: string;
};

export const UspSection: FC<UspSectionProps> = ({ title, subtitle, usps, className }) => {
	return (
		<section className={cn(className, 'py-48')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col items-center space-y-48'>
					<div className='flex flex-col space-y-24'>
						<Text as='h2' variant='display-3' color='primaryMain' weight='semibold' className='text-center'>
							{title}
						</Text>
						<Text variant='body-l' color='primaryMain' weight='regular' className='text-center'>
							{subtitle}
						</Text>
					</div>
					<div className='grid grid-cols-6 gap-24'>
						{usps.map(({ icon, title, description }, key) => (
							<BlurbCard
								enableEffects={false}
								icon={icon}
								title={title}
								description={description}
								className='col-span-full cursor-auto sm:col-span-3 md:col-span-2'
								key={`usp-${key}`}
							/>
						))}
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

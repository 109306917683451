import cn from 'classnames';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { AccordionSkeleton, MarketingFaqAccordion } from '@monorepo/components/DataDisplay';
import { LayoutGrid, Skeleton } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { slugify } from '@monorepo/shared/lib/utils';
import { IFaqSection } from '@monorepo/types';

import { useGetFaqsQuery } from '@cms/store';

export type FaqSectionProps = IFaqSection & {
	path?: string;
	className?: string;
};

export const FaqSection: FC<FaqSectionProps> = ({ title, subtext, path, className }) => {
	const { pathname } = useRouter();
	// TODO: This should not be here, temporary for the motorrijder marketing pages
	const isMotorRijderMarketingPage = pathname.includes('landing/particulier/motorverzekering');

	const { data: items = [], isLoading } = useGetFaqsQuery({ path }, { skip: isMotorRijderMarketingPage });

	return (
		<section className={cn(className, 'py-48 md:py-64')}>
			<LayoutGrid layout='marketing'>
				<div className={classNames('col-start-2 flex flex-col items-center', (title || subtext) && 'space-y-48')}>
					{(title || subtext) && (
						<div className='md-space-y-16 flex flex-col space-y-8 text-center'>
							{title && (
								<Text as='h2' variant='display-4' color='primaryMain' weight='semibold'>
									{title}
								</Text>
							)}
							{subtext && (
								<Text as='p' variant='body-l' color='grayscale300'>
									{subtext}
								</Text>
							)}
						</div>
					)}

					<Skeleton
						variant='custom'
						skeleton={<AccordionSkeleton />}
						containerClassName='flex w-full flex-col space-y-24 md:max-w-[750px]'
						range={4}
						isLoading={isLoading}
						transformAnimation>
						{/* TODO: Remove once cms sends correct faq */}
						{isMotorRijderMarketingPage
							? [
									{
										title: 'Worden de helm en motorkleding meeverzekerd?',
										icon: 'hard-hat',
										content: 'Jouw helm en motorkleding zijn bij beperkt casco en all-risk standaard meeverzekerd.',
									},
									{
										title: 'Wat houdt de aanschafwaarderegeling precies in?',
										icon: 'euro-circle',
										content:
											'Is jouw motor total loss of gestolen? Dan krijg je tot 1 jaar na aankoop de aanschafprijs terug.',
									},
									{
										title: 'Heb ik een bedenktijd na het afsluiten van mijn motorverzekering?',
										icon: 'comment-dots',
										content: 'Zeker. Na het afsluiten van jouw motorverzekering heb je altijd 14 dagen bedenktijd.',
									},
									{
										title: 'Ben ik verzekerd als er iemand anders op mijn motor rijdt?',
										icon: 'user',
										content: 'Ja. Mits het gaat om incidenteel gebruik en je hiervoor toestemming hebt gegeven.',
									},
									{
										title: 'Kan ik meerdere voertuigen toevoegen aan een verzekering?',
										icon: 'motor',
										content:
											'Zeker! Bij het verzekeren van meerdere voertuigen krijg je automatisch korting tot wel 25%',
									},
									{
										title: 'Ben ik in heel Europa verzekerd?',
										icon: 'map-marker',
										content: 'Ja! Je bent verzekerd in alle landen die lid zijn van de Europese Unie.',
									},
								].map((item) => {
									return (
										<MarketingFaqAccordion
											key={`marketing-faq-section-${slugify(item.title)}`}
											question={item.title}
											icon={item.icon}
											answer={item.content}
										/>
									);
								})
							: items.map((item) => {
									return (
										<MarketingFaqAccordion
											key={`marketing-faq-section-${slugify(item.title)}`}
											icon={item.icon_name}
											question={item.title}
											answer={item.content}
										/>
									);
								})}
					</Skeleton>
				</div>
			</LayoutGrid>
		</section>
	);
};

import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Form } from '@monorepo/components/DataEntry';
import { Icon } from '@monorepo/components/Misc';
import { useAnalytics, useIsMobile } from '@monorepo/shared/lib/hooks';
import { searchCompanySchema, SearchCompanySchema } from '@monorepo/shared/lib/schemas';

import { selectTheme } from '@common/store';
import { filterEmptyQuery, paths } from '@common/utils';

type CtaSearchFormProps = {
	id: string;
	className?: string;
};

export const CtaSearchForm: FC<CtaSearchFormProps> = ({ id, className }) => {
	const { t } = useTranslation(['common']);
	const { push, query } = useRouter();
	const { isMobile } = useIsMobile();
	const { trackEvent } = useAnalytics();
	const theme = useSelector(selectTheme);

	const searchCompanyForm = useForm<SearchCompanySchema>({
		mode: 'onTouched',
		resolver: zodResolver(searchCompanySchema(t)),
		defaultValues: {},
	});

	const handleSearch = ({ query: companySearchQuery }: SearchCompanySchema) => {
		trackEvent('start_funnel', { sales_channel: theme });

		push({
			pathname: paths.funnel.company.search,
			query: filterEmptyQuery([{ query: companySearchQuery }, query]),
		});
	};

	return (
		<FormProvider {...searchCompanyForm}>
			<Form.Context<SearchCompanySchema>>
				{({ handleSubmit, register }) => {
					return (
						<Form
							id={id}
							className={classNames('flex w-full flex-row space-x-8 md:space-x-24', className)}
							onSubmit={handleSubmit(handleSearch)}>
							<div className='w-full max-md:h-56'>
								<input
									{...register('query')}
									className='rounded-12 bg-primary50 placeholder:text-primaryMain focus:bg-primary100 h-full w-full flex-1 border-none transition-all placeholder:font-light'
									type='search'
									autoComplete='off'
									placeholder={t(
										isMobile ? 'components.searchBox.placeholder' : 'components.searchBox.placeholderLarge'
									)}
								/>
							</div>

							<button
								title={t('components.searchBox.placeholderLarge')}
								name={'search_company'}
								className='bg-info300 hover:bg-info400 active:bg-info500 rounded-12 group flex h-56 w-56 min-w-[56px] items-center justify-center transition-colors md:h-64 md:w-64 md:min-w-[64px]'>
								<Icon name='arrow-right' size='xxxl' color='grayscale0' />
							</button>
						</Form>
					);
				}}
			</Form.Context>
		</FormProvider>
	);
};

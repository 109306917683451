import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useRef, useState } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IHeroWithVideo } from '@monorepo/types';

export type HeroWithVideoProps = IHeroWithVideo & {
	className?: string;
};

export const HeroWithVideo: FC<HeroWithVideoProps> = ({ title, subtext, thumbnail, video, className }) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);

	const handlePlay = () => {
		if (videoRef) {
			setIsVideoPlaying(true);
			videoRef.current?.play();
		}
	};

	const handlePause = () => {
		if (isVideoPlaying) {
			setIsVideoPlaying(false);
			videoRef.current?.pause();
		}
	};

	const handleEnded = () => {
		setIsVideoPlaying(false);
	};

	return (
		<section className={cn('flex flex-col max-md:space-y-40', className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2'>
					<Text variant='display-3' weight='semibold' color='primaryMain' className='md:hidden'>
						{title}
					</Text>
					<Text variant='display-3' weight='semibold' color='info300' className='md:hidden'>
						{subtext}
					</Text>
				</div>
			</LayoutGrid>
			<LayoutGrid layout='marketing'>
				<div className='col-start-1 col-end-4 md:col-start-2 md:col-end-3'>
					<div className='relative h-[220px] w-full sm:h-[350px] md:h-[600px]'>
						<AnimatePresence>
							{!isVideoPlaying && (
								<motion.button
									type='button'
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									onClick={handlePlay}
									className='absolute left-1/2 top-1/2 z-10 h-[48px] w-[48px] -translate-x-1/2 -translate-y-1/2 cursor-pointer transition-all hover:scale-95 hover:opacity-80 md:h-[180px] md:w-[180px]'>
									<svg viewBox='0 0 151 151' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M105.666 62.4528L67.958 40.6574C65.6662 39.3342 63.0666 38.6373 60.4202 38.6366C57.7739 38.6359 55.1739 39.3315 52.8814 40.6535C50.5889 41.9756 48.6846 43.8775 47.3596 46.1683C46.0347 48.4591 45.3358 51.0581 45.333 53.7045V97.2953C45.3358 99.9404 46.034 102.538 47.3577 104.828C48.6814 107.118 50.584 109.02 52.8747 110.342C55.1677 111.666 57.7687 112.363 60.4164 112.363C63.0641 112.363 65.6651 111.666 67.958 110.342L105.666 88.547C107.952 87.2211 109.849 85.318 111.168 83.0282C112.487 80.7384 113.181 78.1424 113.181 75.4999C113.181 72.8575 112.487 70.2614 111.168 67.9716C109.849 65.6819 107.952 63.7787 105.666 62.4528ZM98.1247 75.4999L60.4164 97.2953V53.7045L98.1247 75.4999ZM75.4997 0.083252C60.5837 0.083252 46.0027 4.50636 33.6005 12.7933C21.1982 21.0801 11.5319 32.8586 5.82379 46.6392C0.115685 60.4198 -1.37782 75.5836 1.53215 90.213C4.44212 104.842 11.6249 118.28 22.1721 128.828C32.7193 139.375 46.1572 146.558 60.7866 149.467C75.416 152.377 90.5798 150.884 104.36 145.176C118.141 139.468 129.919 129.801 138.206 117.399C146.493 104.997 150.916 90.4159 150.916 75.4999C150.916 65.5961 148.966 55.7892 145.176 46.6392C141.386 37.4892 135.83 29.1754 128.827 22.1723C121.824 15.1692 113.51 9.61405 104.36 5.824C95.2104 2.03396 85.4036 0.083252 75.4997 0.083252ZM75.4997 135.833C63.5669 135.833 51.9021 132.295 41.9803 125.665C32.0585 119.036 24.3255 109.613 19.759 98.5885C15.1925 87.564 13.9977 75.433 16.3257 63.7295C18.6536 52.0259 24.3998 41.2756 32.8376 32.8378C41.2754 24.4 52.0257 18.6539 63.7293 16.3259C75.4328 13.9979 87.5638 15.1927 98.5883 19.7592C109.613 24.3257 119.036 32.0587 125.665 41.9805C132.295 51.9023 135.833 63.5671 135.833 75.4999C135.833 91.5013 129.477 106.847 118.162 118.162C106.847 129.477 91.5011 135.833 75.4997 135.833Z'
											className='fill-grayscale0'
										/>
									</svg>
								</motion.button>
							)}
						</AnimatePresence>
						<video
							ref={videoRef}
							className='h-full w-full object-cover'
							poster={thumbnail.path}
							onClick={handlePause}
							onEnded={handleEnded}>
							<source src={video.path} type='video/mp4' />
							Your browser does not support the video tag.
						</video>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

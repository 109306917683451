import { FC, ReactNode } from 'react';

import { Skeleton } from '@monorepo/components/Misc';

export type TabbedOverviewPanelSkeletonProps = {
	includeTopSlot?: boolean;
	includeBottomSlot?: boolean;
	skeleton: ReactNode;
};

export const TabbedOverviewPanelSkeleton: FC<TabbedOverviewPanelSkeletonProps> = ({
	includeBottomSlot = false,
	includeTopSlot = false,
	skeleton,
}) => {
	return (
		<div className='grid grid-cols-2 gap-24 md:gap-48'>
			{includeTopSlot && <Skeleton variant='rounded' containerClassName='col-span-full' className='h-[332px] w-full' />}

			{[1, 2, 3, 4].map((key) => (
				<div className='col-span-full md:col-span-1' key={`tabbed-overview-panel-skeleton-${key}`}>
					{skeleton}
				</div>
			))}

			{includeBottomSlot && (
				<Skeleton variant='rounded' containerClassName='col-span-full' className='h-[332px] w-full' />
			)}
		</div>
	);
};

import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Trans, useTranslation } from 'next-i18next';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';

import { AcceptanceStateStatus, BillingUnits, CartItem, CartVehicle, ThemeNames } from '@monorepo/types';

import { transformToCurrency } from '../../../lib/utils';
import { ActionButton } from '../../DataEntry';
import { Divider, Icon, LicensePlate } from '../../Misc';
import { EmphasizedPriceFormat, Text } from '../../TextComponents';
import { ShoppingCartFooter } from './Footer/ShoppingCartFooter';
import { ShoppingCartItem } from './Item/ShoppingCartItem';

export type ShoppingCartState = 'collapsed' | 'expanded';

type Props = {
	items: CartItem[];
	acceptanceState: AcceptanceStateStatus;
	cartState?: ShoppingCartState;
	showFull?: boolean;
	handleCta: () => void;
	ctaValid: boolean;
	ctaLoading: boolean;
	theme: ThemeNames;
	handleProceedLater?: () => void;
	hideExpand?: boolean;
	className?: string;
	billingUnit?: BillingUnits;
	customFooterLabel?: string;
	customFooterIcon?: string;
	isBundle?: boolean;
};

const cartContentTransition = {
	expanded: {
		height: 'auto',
		opacity: 1,
	},
	collapsed: {
		height: 0,
		opacity: 0,
	},
};

export const ShoppingCart: FC<Props> = ({
	items,
	acceptanceState,
	cartState = 'expanded',
	showFull = true,
	handleCta,
	ctaValid = false,
	ctaLoading = false,
	handleProceedLater,
	isBundle = false,
	hideExpand = false,
	billingUnit = 'monthly',
	className = '',
	customFooterLabel,
	customFooterIcon,
	theme,
}) => {
	const { t } = useTranslation(['common']);
	const [internalState, setInternalState] = useState<ShoppingCartState>();
	const [showFooterPrice, setShowFooterPrice] = useState<boolean>(cartState === 'expanded' && !showFull);
	const [showFooterCta, setShowFooterCta] = useState<boolean>(showFull);
	const [totalPrice, setTotalPrice] = useState<number>(0);
	const [totalTax, setTotalTax] = useState<number>(0);
	const [showFooterProceedLater, setShowFooterProceedLater] = useState<boolean>(showFull ? showFull : false);

	// TODO: Update once api is known and we (hopefully) dont have to group these by vehicle
	const groupedVehicleItems = useMemo(() => {
		const groups: { vehicle: CartVehicle; items: CartItem[] }[] = [];
		items.forEach((item, _, allItems) => {
			if (item.vehicle && !groups.some((group) => group.vehicle.license === item.vehicle?.license)) {
				groups.push({
					vehicle: item.vehicle,
					items: allItems.filter((allItem) => allItem.vehicle?.license === item.vehicle?.license),
				});
			}
		});

		return groups;
		// return items.map((item)=>);
	}, [items]);

	useEffect(() => {
		if (internalState === 'collapsed') {
			setShowFooterPrice(false);
			setShowFooterCta(showFull);

			// Show mail button on bundle pages
			if (!isBundle) {
				setShowFooterProceedLater(false);
			}
		} else if (internalState === 'expanded') {
			setShowFooterPrice(!showFull);
			setShowFooterCta(showFull);
			setShowFooterProceedLater(showFull);
		}
	}, [internalState, showFull]);

	useEffect(() => {
		setInternalState(cartState);
	}, [cartState]);

	useEffect(() => {
		if (!ctaLoading) {
			setTotalPrice(items?.reduce((sum, { price }) => sum + price, 0));
			setTotalTax(items?.reduce((sum, { tax }) => sum + tax, 0));
		}
	}, [items, ctaLoading]);

	return (
		<AnimatePresence>
			<motion.div
				className={cn(
					className,
					'shadow-5 rounded-t-18 md:rounded-b-18 bg-grayscale0 relative z-50 flex max-h-[85vh] flex-col gap-y-20 overflow-hidden py-20 lg:gap-y-24 lg:py-24'
				)}
				animate={{ height: items?.length ? 'auto' : '0px' }} // animate height based on items count
				transition={{ duration: 0.3 }} // transition duration in seconds
			>
				<div className={cn(showFull ? 'items-start' : 'items-center', 'flex flex-row px-32')}>
					{showFull ? (
						<div className='gap-y-8'>
							<EmphasizedPriceFormat
								leadingText={t('components.emphasizedPriceFormat.emphasizedPriceLeadingTextPremium')}
								price={totalPrice}
								afterText={t(billingUnit === 'monthly' ? 'common.perMonthShort' : 'common.perYearShort')}
							/>
							<Text variant='body-xs' color='grayscale400'>
								<Trans
									i18nKey='components.shoppingCartFooter.inclTax'
									tOptions={{
										tax: transformToCurrency(totalTax),
									}}
								/>
							</Text>
						</div>
					) : (
						<>
							<div>
								<Icon name='shopping-bag' color='grayscale400' />
							</div>
							<div className='ml-8 grow'>
								<Text variant='body-xs' color='grayscale400' weight='semibold'>
									<Trans
										i18nKey={'components.shoppingCart.headerAfterCompose'}
										tOptions={{ price: transformToCurrency(totalPrice), num: items?.length }}
										components={{
											amount: <span className='typography-body-s text-primaryMain font-semibold' />,
											num: <span className='typography-body-xs text-grayscale300 font-normal' />,
										}}
									/>
								</Text>
							</div>
						</>
					)}

					{!hideExpand && (
						<div className='ml-auto'>
							<ActionButton
								onClick={() => setInternalState((s) => (s === 'expanded' ? 'collapsed' : 'expanded'))}
								icon='arrow-up'
								rotate={internalState === 'expanded'}
							/>
						</div>
					)}
				</div>

				{showFull && internalState == 'expanded' && <Divider />}

				{!isBundle && (typeof groupedVehicleItems === 'undefined' || groupedVehicleItems?.length === 0) && (
					<motion.ul
						className='flex flex-col gap-y-20 overflow-hidden overflow-y-scroll px-32 lg:gap-y-24'
						variants={cartContentTransition}
						initial={internalState === 'expanded' ? 'expanded' : 'collapsed'}
						animate={internalState === 'expanded' ? 'expanded' : 'collapsed'}>
						<AnimatePresence>
							{items?.map((item) => (
								<motion.li
									className='bg-grayscale0 relative z-20'
									key={`shopping-cart-animate-item-${item.guid}`}
									initial={{ opacity: 0, height: 0, zIndex: 0 }}
									animate={{ opacity: 1, height: 'auto' }}
									exit={{ opacity: 0, height: 0, zIndex: 0 }}
									transition={{ duration: 0.3 }}>
									<ShoppingCartItem billingUnit={billingUnit} theme={theme} {...item} />
								</motion.li>
							))}

							{!showFull && <Divider />}
						</AnimatePresence>
					</motion.ul>
				)}

				{!isBundle && groupedVehicleItems?.length > 0 && (
					<motion.ul
						className='flex flex-col gap-y-20 overflow-hidden overflow-y-scroll px-32 lg:gap-y-24'
						variants={cartContentTransition}
						initial={internalState === 'expanded' ? 'expanded' : 'collapsed'}
						animate={internalState === 'expanded' ? 'expanded' : 'collapsed'}>
						<AnimatePresence>
							{groupedVehicleItems?.map((group) => (
								<Fragment key={`vehicle-${group.vehicle.license}`}>
									<motion.li
										className='bg-grayscale0 relative z-20'
										key={`vehicle-${group.vehicle.license}-header`}
										initial={{ opacity: 0, height: 0, zIndex: 0 }}
										animate={{ opacity: 1, height: 'auto' }}
										exit={{ opacity: 0, height: 0, zIndex: 0 }}
										transition={{ duration: 0.3 }}>
										<LicensePlate license={group.vehicle.license} size='small' />
									</motion.li>
									{group.items.map((item) => (
										<motion.li
											className='bg-grayscale0 relative z-20'
											key={`vehicle-${group.vehicle.license}-item-${item.name}`}
											initial={{ opacity: 0, height: 0, zIndex: 0 }}
											animate={{ opacity: 1, height: 'auto' }}
											exit={{ opacity: 0, height: 0, zIndex: 0 }}
											transition={{ duration: 0.3 }}>
											<ShoppingCartItem billingUnit={billingUnit} theme={theme} {...item} />
										</motion.li>
									))}

									<Divider />
								</Fragment>
							))}
						</AnimatePresence>
					</motion.ul>
				)}

				<ShoppingCartFooter
					className={cn(!showFull && internalState === 'collapsed' && 'hidden')}
					showPrice={showFooterPrice}
					showCta={showFooterCta}
					acceptanceState={acceptanceState}
					showProceedLater={!!handleProceedLater && showFooterProceedLater}
					price={totalPrice}
					handleCta={handleCta}
					ctaValid={ctaValid}
					ctaLoading={ctaLoading}
					handleProceedLater={handleProceedLater}
					customFooterLabel={customFooterLabel}
					customFooterIcon={customFooterIcon}
					tax={totalTax}
				/>
			</motion.div>
		</AnimatePresence>
	);
};

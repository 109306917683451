import { ThemeNames } from '@monorepo/types';

export const affinityGroupDataMap: Record<
	ThemeNames,
	{
		phoneNumber: string;
		phoneNumberLink: `tel:${string}`;
		displaySalesChannel: string;
		email: string;
		insuranceProvider: string;
	}
> = {
	maia: {
		phoneNumber: '010 - 4433 422',
		phoneNumberLink: 'tel:31104433422',
		displaySalesChannel: 'Māia',
		email: 'support@maia.insure',
		insuranceProvider: 'Māia',
	},
	nkc: {
		phoneNumber: '010 - 4433 422',
		phoneNumberLink: 'tel:31104433422',
		displaySalesChannel: 'Māia',
		email: 'support@maia.insure',
		insuranceProvider: 'Māia',
	},
	nvm: {
		phoneNumber: '010 - 288 44 42',
		phoneNumberLink: 'tel:31102884442',
		displaySalesChannel: 'de NVM',
		email: 'info@schoutenverzekeringsdienstnvm.nl',
		insuranceProvider: 'Schouten Verzekeringsdienst NVM',
	},
	vbo: {
		phoneNumber: '010 - 4433 422',
		phoneNumberLink: 'tel:31104433422',
		displaySalesChannel: 'VBO',
		email: 'support@maia.insure',
		insuranceProvider: 'Schouten Zekerheid',
	},
	vastgoedpro: {
		phoneNumber: '010 - 443 34 88',
		phoneNumberLink: 'tel:31104433488',
		displaySalesChannel: 'Vastgoedpro',
		email: 'info@vastgoedproverzekeringen.nl',
		insuranceProvider: 'Vastgoedpro Verzekeringen',
	},
	onderhoudnl: {
		phoneNumber: '010 - 288 45 40',
		phoneNumberLink: 'tel:31102884540',
		displaySalesChannel: 'OnderhoudNL',
		email: 'info@onderhoudnlverzekeringen.nl',
		insuranceProvider: 'OnderhoudNL Verzekeringen',
	},
	anko: {
		phoneNumber: '010 - 288 44 80',
		phoneNumberLink: 'tel:31102884480',
		displaySalesChannel: 'Anko',
		email: 'info@ankoverzekeringsdienst.nl',
		insuranceProvider: 'Anko Verzekeringsdienst',
	},
	cybermeister: {
		phoneNumber: '010 - 4433 422',
		phoneNumberLink: 'tel:31104433422',
		displaySalesChannel: 'Cybermeister',
		email: 'support@maia.insure',
		insuranceProvider: 'Māia',
	},
	meestersgilde: {
		phoneNumber: '010 - 4433 422',
		phoneNumberLink: 'tel:31104433422',
		displaySalesChannel: 'Meestersgilde',
		email: 'support@maia.insure',
		insuranceProvider: 'Māia',
	},
	trustoo: {
		phoneNumber: '010 - 4433 422',
		phoneNumberLink: 'tel:31104433422',
		displaySalesChannel: 'Trustoo',
		email: 'support@maia.insure',
		insuranceProvider: 'Māia',
	},
	deonlinebeautycursussen: {
		phoneNumber: '010 - 443 34 22',
		phoneNumberLink: 'tel:0104433422',
		displaySalesChannel: 'De Online Beauty Cursussen',
		email: 'support@maia.insure',
		insuranceProvider: 'Māia',
	},
	marengo: {
		phoneNumber: '010 - 4433 422',
		phoneNumberLink: 'tel:31104433422',
		displaySalesChannel: 'Māia',
		email: 'support@maia.insure',
		insuranceProvider: 'Marengo',
	},
	zorgzekerpakket: {
		phoneNumber: '010 - 4433 422',
		phoneNumberLink: 'tel:31104433422',
		displaySalesChannel: 'Māia',
		email: 'support@maia.insure',
		insuranceProvider: 'Zorg Zeker Pakket',
	},
};

import { context, DefaultBodyType, MockedRequest } from 'msw';

import { isTest, SALES_CHANNEL_COOKIENAME } from '@monorepo/shared/lib/utils';
import { ThemeNames } from '@monorepo/types';

export const getMockSalesChannel = (req: MockedRequest<DefaultBodyType>): ThemeNames => {
	return (req.cookies[SALES_CHANNEL_COOKIENAME] as ThemeNames) || 'nvm';
};

export const delay = (duration?: number) => {
	if (isTest || !duration) {
		return context.delay(0);
	}

	return context.delay(duration);
};

import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { BillingUnits, ThemeColors } from '@monorepo/types';

import { transformToCurrency } from '../../../../lib/utils';
import { EmphasizedPriceFormat, Text } from '../../../TextComponents';

const PRICE_STICKER_VARIANTS = ['default', 'free', 'inactive'] as const;

type PriceStickerVariantTuple = typeof PRICE_STICKER_VARIANTS;
type PriceStickerVariants = PriceStickerVariantTuple[number];
export type PriceStickerSizes = 'large' | 'small' | 'xs';

type PriceStickerProps = (
	| ({
			variant: PriceStickerVariantTuple[0]; // Default
	  } & PriceStickerDefaultProps)
	| ({
			variant: PriceStickerVariantTuple[1]; // Free
	  } & PriceStickerFreeProps)
	| ({
			variant: PriceStickerVariantTuple[2]; // Default
	  } & PriceStickerDefaultProps)
) &
	PriceStickerBaseProps;

type PriceStickerBaseProps = {
	price: number;
	billingUnit?: BillingUnits;
	alignment?: 'left' | 'right';
	className?: string;
};

const priceStickerClasses: Record<PriceStickerVariants, { background: `bg-${ThemeColors}`; text: ThemeColors }> = {
	default: { background: 'bg-info50', text: 'info300' },
	free: { background: 'bg-success50', text: 'success300' },
	inactive: { background: 'bg-grayscale50', text: 'grayscale300' },
};

export const PriceSticker: FC<PriceStickerProps> = (props) => {
	if (props.variant === 'default') {
		return <PriceStickerDefault {...props} active={true} />;
	}

	if (props.variant === 'free') {
		return <PriceStickerFree {...props} />;
	}

	return <PriceStickerDefault {...props} active={false} />;
};

type PriceStickerDefaultProps = {
	size: PriceStickerSizes;
	excludeFrom?: boolean;
	active?: boolean;
};

export const PriceStickerDefault: FC<PriceStickerDefaultProps & PriceStickerBaseProps> = ({
	size,
	price,
	billingUnit = 'monthly',
	excludeFrom,
	active = true,
	className,
}) => {
	const { t } = useTranslation(['common']);
	const bgColor = active ? priceStickerClasses['default'].background : priceStickerClasses['inactive'].background,
		textColor = active ? priceStickerClasses['default'].text : priceStickerClasses['inactive'].text;

	return (
		<div
			className={cn(
				bgColor,
				className,
				'inline-flex overflow-hidden px-12 py-4',
				size === 'large' && 'rounded-18 flex-col sm:rounded-full md:py-8 md:pl-24 md:pr-32',
				size === 'small' && 'items-baseline space-x-4 rounded-full md:px-12 md:py-8',
				size === 'xs' && 'items-baseline space-x-4 rounded-full'
			)}>
			{!excludeFrom && (
				<Text
					className={cn(size === 'large' && 'md:hidden')}
					as='span'
					variant={size === 'large' ? 'body-xxs' : 'body-xs'}
					weight='regular'
					color={textColor}>
					{t(size === 'large' ? 'components.PriceSticker.from' : 'components.PriceSticker.fromShort')}
				</Text>
			)}

			{size === 'large' && (
				<EmphasizedPriceFormat
					textColor={textColor}
					className='max-md:hidden'
					leadingText={
						!excludeFrom
							? t(size === 'large' ? 'components.PriceSticker.from' : 'components.PriceSticker.fromShort')
							: undefined
					}
					price={price}
					afterText={t(billingUnit === 'monthly' ? 'common.perMonthShort' : 'common.perYearShort')}
				/>
			)}

			<div className='flex flex-row items-baseline space-x-4'>
				<Text
					className={cn(size === 'large' && 'md:hidden')}
					as='span'
					variant={size === 'xs' ? 'body-xs' : size === 'large' ? 'display-4' : 'body-m'}
					weight='semibold'
					color={textColor}>
					{transformToCurrency(price)}
				</Text>
				<Text
					className={cn(size === 'large' && 'md:hidden')}
					as='span'
					variant={size === 'large' ? 'body-xxs' : 'body-xs'}
					weight='regular'
					color={textColor}>
					{t(billingUnit === 'monthly' ? 'common.perMonthShort' : 'common.perYearShort')}
				</Text>
			</div>
		</div>
	);
};

type PriceStickerFreeProps = {
	size: PriceStickerSizes;
};

export const PriceStickerFree: FC<PriceStickerFreeProps & PriceStickerBaseProps> = ({
	size,
	price,
	alignment = 'right',
}) => {
	const { t } = useTranslation(['common']);

	return (
		<div
			className={cn(
				alignment === 'left' ? 'md:items-start' : ' md:items-end',
				'inline-flex items-center space-x-4 md:flex-col md:space-y-4'
			)}>
			<div
				className={cn(
					priceStickerClasses['free'].background,
					'inline-flex space-x-4 rounded-full px-12 py-4',
					size === 'large' && 'flex-col items-center md:px-24 md:py-8',
					size === 'small' && 'flex-row items-baseline space-x-4 md:px-12 md:py-8',
					size === 'xs' && 'items-baseline'
				)}>
				<Text
					as='span'
					variant={size === 'xs' ? 'body-s' : 'body-l'}
					weight='semibold'
					color={priceStickerClasses['free'].text}
					className='whitespace-nowrap'>
					{t('components.PriceSticker.yearsFree', { years: 1 })}
				</Text>
				<Text as='span' variant='body-xxs' weight='regular' color='success300' className='line-through'>
					{t('components.PriceSticker.afterFreeYear', { price: transformToCurrency(price) })}
				</Text>
			</div>
		</div>
	);
};

import { IColumnSection } from '@monorepo/types';

export const mockColumnSection: IColumnSection = {
	title: 'title',
	subTitle: 'subtitle',
	ctaText: 'ctaText',
	pricePretext: 'pricePretext',
	priceSubtext: 'priceSubtext',
	productGuid: '1234',
};

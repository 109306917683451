import { INicheUspSection } from '@monorepo/types';

export const mockNicheUspSection: INicheUspSection = {
	title: "Māia's recept voor betrouwbare zekerheid",
	usps: [
		{
			image: {
				path: '/images/marketing/why-choose-maia-1.svg',
				alt: 'alt-1',
			},
			title: 'Deskundige Bescherming',
			description:
				'Door een onverwachte gebeurtenis, zoals waterschade in jouw salon, kun je tijdelijk niet werken. Deze dekking biedt financiële ondersteuning tijdens de periode dat je bedrijf niet kan opereren, zodat je snel weer op de been bent.',
		},
		{
			image: {
				path: '/images/marketing/why-choose-maia-2.svg',
				alt: 'alt-2',
			},
			title: 'Flexibele Pakketten',
			description:
				'Stel je voor, een klant heeft een allergische reactie op een haarverf of gezichtsbehandeling die jij hebt toegepast. Deze dekking zorgt ervoor dat de medische kosten en eventuele juridische kosten gedekt zijn.',
		},
		{
			image: {
				path: '/images/marketing/why-choose-maia-3.svg',
				alt: 'alt-3',
			},
			title: 'Razendsnelle Claims',
			description:
				'Je hoogwaardige föhn of gespecialiseerde behandelstoel gaat plotseling kapot of wordt gestolen. Met deze dekking ben je beschermd tegen de vervangingskosten van essentiële apparatuur en inventaris.',
		},
	],
};

import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import type { Address } from '@monorepo/types';

import { readableAddress } from '../../../../lib/utils';
import { Icon, IconButton, Skeleton } from '../../../Misc';
import { Text } from '../../../TextComponents';

type DataEditCardVariants = 'default' | 'outlined';

type DataEditCardProps = {
	address?: Address | string;
	onEditClick?: () => void;
	className?: string;
	isLoading?: boolean;
	variant?: DataEditCardVariants;
};

export const DataEditCard: FC<DataEditCardProps> = ({
	address,
	onEditClick,
	className,
	isLoading = false,
	variant = 'default',
}) => {
	const { t } = useTranslation(['common']);

	return (
		<div
			className={cn(
				className,
				variant === 'default' ? 'bg-primary50' : 'border-grayscale200 border',
				'rounded-12 relative flex flex-row items-center space-x-12 p-12 md:p-16'
			)}>
			<Skeleton isLoading={isLoading} variant='circular' color='primary100' className='h-[32px] w-[32px]'>
				<Icon name='map-marker' className='leading-none' color='primaryMain' size='xl' />
			</Skeleton>
			<Skeleton
				isLoading={isLoading}
				variant='text'
				size='body-s'
				color='primary100'
				className='w-[200px]'
				containerClassName='grow'>
				<Text
					as='p'
					variant={variant === 'default' ? 'body-m' : 'body-s'}
					color={variant === 'default' ? 'primaryMain' : 'grayscale500'}
					className='grow'
					data-testid='data-edit-card-address'>
					{typeof address === 'undefined'
						? t('common.noAddress')
						: typeof address === 'string'
							? address
							: readableAddress(address)}
				</Text>
			</Skeleton>
			{onEditClick && !isLoading && (
				<IconButton
					className='pointer-events-auto '
					title={t('common.update')}
					iconName='pen'
					color={variant === 'default' ? 'primaryMain' : 'info300'}
					size='md'
					onClick={() => onEditClick()}
				/>
			)}
		</div>
	);
};

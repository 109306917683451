import { SupportedInsurance } from '@monorepo/types';

export const productIcons: Partial<Record<SupportedInsurance, string>> = {
	Aansprakelijkheidsverzekering: 'balance-scale',
	'Alles-in-één aansprakelijkheidsverzekering': 'balance-scale',
	'Spullen-in-je-opslagverzekering': 'estate',
	'Spullen-in-je-busverzekering': 'truck',
	Cyberverzekering: 'globe',
	Ongevallenverzekering: 'bolt-alt',
	Werknemersschadeverzekering: 'hard-hat',
};

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { AcceptanceStatementSchema } from '@monorepo/shared/lib/schemas';
import { AcceptanceQuestion } from '@monorepo/types';

import { useNavigation } from '@common/hooks';
import { setNavForward } from '@common/store';
import { useAnswerConsumerAcceptanceQuestionsMutation, useGetConsumerAcceptanceQuestionsQuery } from '@consumer/store';
import { useQuestionPageManager } from '@funnel/hooks';
import { useAnswerAcceptanceQuestionsMutation, useGetAcceptanceQuestionsQuery } from '@funnel/store';

export const useAcceptanceStatement = (cartGuid: string) => {
	const {
		push,
		query: { slug },
	} = useRouter();
	const { isB2BFunnel } = useNavigation();
	const dispatch = useDispatch();
	const accQuestionsQuery = isB2BFunnel ? useGetAcceptanceQuestionsQuery : useGetConsumerAcceptanceQuestionsQuery;

	const accQuestionsMutation = isB2BFunnel
		? useAnswerAcceptanceQuestionsMutation
		: useAnswerConsumerAcceptanceQuestionsMutation;

	const { data: acceptanceQuestions = [] } = accQuestionsQuery((cartGuid as string) ?? skipToken);
	const questions = acceptanceQuestions?.filter(({ reference }) => reference === slug) as AcceptanceQuestion[];

	const [answerAcceptanceQuestionMutation, answerAcceptanceQuestionMutationResponse] = accQuestionsMutation();

	const { nextPage } = useQuestionPageManager({ isAcceptanceQuestions: true });

	// Filter the statements to only get the unanswered statements
	const unansweredQuestions = useMemo(() => questions.filter((question) => !question.is_answered), [questions]);

	const totalUnansweredQuestions = unansweredQuestions?.length;

	// When the unanswered questions is more or equal then 5 questions, show the tinder cards, else the regular on or of switches
	const minimumQuestionsForTinderCards = 8;

	// Calculate the current step based on the total statements - the unanswered statements
	const currentStep = useMemo(
		() => questions.length - unansweredQuestions.length + 1,
		[questions, unansweredQuestions]
	);

	const handleAnswerQuestion = async (guid: string, answer: boolean) => {
		answerAcceptanceQuestionMutation({ cart_guid: `${cartGuid}`, questions: [{ guid, answer: answer ? 'J' : 'N' }] });
	};

	const handleAnswerQuestions = async ({ questions }: AcceptanceStatementSchema) => {
		dispatch(setNavForward());

		await answerAcceptanceQuestionMutation({
			cart_guid: `${cartGuid}`,
			questions: questions.map((question) => ({ guid: question.guid, answer: question.checked })),
		});

		await handleGoToNextPage();
	};

	const handleGoToNextPage = async () => {
		await push({
			pathname: `${nextPage}`,
			query: { cartGuid },
		});
	};

	return {
		isLoading: answerAcceptanceQuestionMutationResponse.isLoading,
		isSuccess: answerAcceptanceQuestionMutationResponse.isSuccess,
		currentStep,
		totalSteps: questions.length,
		unansweredQuestions,
		totalUnansweredQuestions,
		minimumQuestionsForTinderCards,
		questions,
		handleAnswerQuestion,
		handleAnswerQuestions,
		handleGoToNextPage,
	};
};

import React from 'react';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';

import { FormBaseInput, FormBaseInputDefaultProps, FormBaseInputProps } from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormNumberWithControlsInputProps<TFormValues extends FieldValues> = {
	hint?: string;
	hideError?: boolean;
	decimalScale?: number;
	control: Control<TFormValues>;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<FormBaseInputProps, 'name' | 'errors' | 'type' | 'numberControls' | 'onNumberControlsClick'>;

export const FormNumberWithControlsInput = <TFormValues extends Record<string, unknown>>({
	name,
	errors,
	hint,
	min = 0,
	step = 1,
	hideError,
	fieldSize = 'md',
	placeholder,
	control,
	defaultValue,
	startIcon,
	className,
	...props
}: FormNumberWithControlsInputProps<TFormValues>): React.JSX.Element => {
	return (
		<FormHelper errors={errors} hint={hint} hideError={hideError}>
			{({ isError }) => (
				<Controller
					control={control}
					defaultValue={defaultValue as PathValue<TFormValues, Path<TFormValues>>}
					disabled={props.disabled}
					name={name}
					render={({ field: { name, onChange, onBlur, value, disabled } }) => {
						/**
						 * Handle the increment and decrement of the input value based on the step and min props
						 */
						const handleValueChange = (type: 'increment' | 'decrement') => {
							const _step = parseInt(`${step}`);

							const numericValue = Number(value) || 0;
							const newValue = type === 'increment' ? numericValue + _step : Math.max(numericValue - _step, +min); // Prevent decrement below 0

							onChange(newValue.toString());
						};

						return (
							<FormBaseInput
								className={className}
								placeholder={placeholder}
								fieldSize={fieldSize}
								numberControls
								onNumberControlsClick={handleValueChange}
								type='number'
								isError={isError}
								onChange={onChange}
								value={value ? Number(value) : undefined}
								disabled={disabled}
								name={name}
								startIcon={startIcon}
								onBlur={onBlur}
								min={min}
								{...props}
							/>
						);
					}}
				/>
			)}
		</FormHelper>
	);
};

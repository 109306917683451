import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { AccountSchema, accountSchema } from '@monorepo/shared/lib/schemas';

import { ModalActions } from '@common/components';

type AccountPhoneNumberFormProps = {
	id?: string;
	defaultValues?: AccountSchema;
	isLoading?: boolean;
	onCancel: () => void;
	onSubmit: (form: AccountSchema) => void;
};

export const AccountPhoneNumberForm: FC<AccountPhoneNumberFormProps> = ({
	id,
	isLoading,
	defaultValues,
	onSubmit,
	onCancel,
}) => {
	const { t } = useTranslation(['common']);

	const accountPhoneNumberForm = useForm<AccountSchema>({
		mode: 'onChange',
		resolver: zodResolver(accountSchema(t)),
		defaultValues,
	});

	return (
		<Form
			id={id}
			className='grid grid-cols-12 gap-x-6 gap-y-32'
			onSubmit={accountPhoneNumberForm.handleSubmit(onSubmit)}>
			<Form.Group className='col-span-12'>
				<Form.Label>{t('common.mobilePhone')}</Form.Label>
				<Form.PhoneInput name='phone' startIcon='mobile-android' register={accountPhoneNumberForm.register} />
			</Form.Group>
			<Form.Group className='col-span-12'>
				<ModalActions
					cancelButton={
						<StandardButton variant='secondary' label={t('common.cancel')} disabled={isLoading} onClick={onCancel} />
					}
					submitButton={
						<StandardButton
							type='submit'
							variant='primary'
							label={defaultValues ? t('common.update') : t('common.add')}
							iconRight='check'
							isLoading={isLoading}
							disabled={isLoading || !accountPhoneNumberForm.formState.isValid}
						/>
					}
				/>
			</Form.Group>
		</Form>
	);
};

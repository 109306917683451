import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Text } from '../../TextComponents';
import { IconButton } from '../Forms';
import { Icon } from '../Icon/Icon';

type LookupSuggestionProps = {
	postcode: string;
	number: string | number;
	street: string;
	city: string;
	onEdit: () => void;
	addition?: string;
	isLoading?: boolean;
};

export const LookupSuggestion: FC<LookupSuggestionProps> = ({
	postcode,
	number,
	street,
	city,
	onEdit,
	addition,
	isLoading = false,
}) => {
	const { t } = useTranslation(['common']);

	return (
		<div className='text-grayscale600 bg-primary50 rounded-12 relative flex gap-3 p-12'>
			<div className='flex w-full flex-row items-center space-x-12 md:items-start'>
				<Icon name='map-marker' color='primaryMain' size='xl' />

				{isLoading ? (
					<>
						<div className='bg-primary200 mb-[12px] h-[18px] w-[85px] rounded-full motion-safe:animate-pulse'></div>
						<div className='bg-primary200 h-[18px] w-[200px] rounded-full motion-safe:animate-pulse'></div>
					</>
				) : (
					<>
						<div className='grow'>
							<Text color='grayscale600' variant='body-m' weight='semibold'>
								{t('page.funnel.company.change.suggestionTitle')}
							</Text>
							<Text color='grayscale600' variant='body-m' className='break-all'>
								{`${street} ${number}${addition ? addition : ''}, ${postcode} ${city}`}
							</Text>
						</div>

						<IconButton iconName='edit-alt' color='info300' onClick={onEdit} />
					</>
				)}
			</div>
		</div>
	);
};

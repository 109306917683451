import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { match, P } from 'ts-pattern';

import { WebLayoutVariants } from '@monorepo/types';

import { MarketingCtaButton, TextButton } from '../../../../DataEntry';
import { IconButton } from '../../../../Misc';
import { HamburgerButton } from '../../../../Misc/HamburgerButton/HamburgerButton';

type TopBarProps = {
	mobileMenuActive: boolean;
	toggleMobileMenu: () => void;
	variant: WebLayoutVariants;
	isLoggedIn: boolean;
	isB2C?: boolean;
};

export const TopNavBar: FC<TopBarProps> = ({
	mobileMenuActive = false,
	toggleMobileMenu,
	variant,
	isB2C,
	isLoggedIn,
}) => {
	const { t } = useTranslation(['common']);
	const { query } = useRouter();

	const adtractionGuid = query.at_gd as string | undefined;
	const href = getCtaUrl(isB2C, adtractionGuid);

	return (
		<div className='relative flex flex-row items-center space-x-16 md:space-x-40'>
			<div className='relative mr-auto'>
				<Link href='/' title={'Go to home'} className='flex-1'>
					<Image src='/images/logo/maia/logo.svg' alt='Logo' width={74} height={24} />
				</Link>
			</div>
			<div>
				<MarketingCtaButton
					className={cn(variant === 'default' && 'max-md:hidden', 'flex-none')}
					link={{
						href,
						title: t('common.ctaCalculatePremium'),
						content: t('common.ctaCalculatePremium'),
						target: '_self',
					}}
					size='sm'
				/>
			</div>
			{variant === 'default' && (
				<>
					<div>
						<Link
							href={isLoggedIn ? '/portaal/overzicht' : '/inloggen'}
							title={t(isLoggedIn ? 'common.portal' : 'common.login')}
							className='flex-none'>
							<IconButton
								title={t(isLoggedIn ? 'common.portal' : 'common.login')}
								color='info300'
								size='lg'
								className='flex items-center justify-center md:hidden'
								iconName='user-circle'
							/>
							<TextButton
								className='max-md:hidden'
								label={t(isLoggedIn ? 'common.portal' : 'common.login')}
								size='sm'
							/>
						</Link>
					</div>

					<HamburgerButton
						className='inline-block md:hidden'
						isActive={mobileMenuActive}
						toggleActive={toggleMobileMenu}
					/>
				</>
			)}
		</div>
	);
};

function getCtaUrl(isB2C: boolean | undefined, adtractionGuid: string | undefined) {
	return match({ isB2C, adtractionGuid })
		.returnType<string>()
		.with(
			{
				isB2C: true,
				adtractionGuid: P.string,
			},
			() => `/particulier/motorverzekering/aanvragen?at_gd=${adtractionGuid}`
		)
		.with(
			{
				isB2C: false,
				adtractionGuid: P.string,
			},
			() => `/aanvragen/bedrijf/zoeken?at_gd=${adtractionGuid}`
		)
		.with(
			{
				isB2C: true,
				adtractionGuid: undefined,
			},
			() => `/particulier/motorverzekering/aanvragen`
		)
		.with(
			{
				isB2C: false,
				adtractionGuid: undefined,
			},
			() => `/aanvragen/bedrijf/zoeken`
		)
		.otherwise(() => '/aanvragen/bedrijf/zoeken');
}

export const sortByDomNode = <T>(
	nodes: T[],
	resolveKey: (item: T) => HTMLElement | null = (i) => i as unknown as HTMLElement | null
): T[] => {
	return nodes.slice().sort((aItem, zItem) => {
		const a = resolveKey(aItem);
		const z = resolveKey(zItem);

		if (a === null || z === null) return 0;

		const position = a.compareDocumentPosition(z);

		if (position & Node.DOCUMENT_POSITION_FOLLOWING) return -1;
		if (position & Node.DOCUMENT_POSITION_PRECEDING) return 1;
		return 0;
	});
};

import { FC } from 'react';

import { InsuredBox, InsuredBoxProps } from '@monorepo/components/DataDisplay';
import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleInsuredBox = ({ component }: HandlerProps): FC<InsuredBoxProps> => {
	const title = getProperty('title', component.custom_fields);

	return (props) => (
		<InsuredBox {...props} usps={component.child_elements.map(({ markdown }) => `${markdown}`)} title={`${title}`} />
	);
};

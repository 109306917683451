import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { PHONE_REGEX } from '../../../lib/utils/validation/validation';

export const stakeholderSchema = (t: TranslationType) =>
	z
		.object({
			connection_type: z.enum(['self', 'partner', 'child'], {
				required_error: t('validation.required'),
			}),
			initials: z
				.string({
					required_error: t('validation.required'),
				})
				.min(1, t('validation.required')),
			first_name: z
				.string({
					required_error: t('validation.required'),
				})
				.min(1, t('validation.required')),
			last_name: z
				.string({
					required_error: t('validation.required'),
				})
				.min(1, t('validation.required')),
			email: z
				.string({ required_error: t('validation.required') })
				.email(t('validation.email'))
				.optional()
				.or(z.literal('')),
			phone: z
				.string({ required_error: t('validation.required') })
				.regex(PHONE_REGEX, t('validation.phone'))
				.optional()
				.or(z.literal('')),
			reporting_code: z
				.string({ required_error: t('validation.required') })
				.min(4, { message: t('validation.min', { min: 4 }) }),
		})
		.superRefine((data, ctx) => {
			if (data.connection_type === 'self') {
				if (!data.email || data.email === '') {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t('validation.required'),
						path: ['email'],
					});
				}
				if (!data.phone || data.phone === '') {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t('validation.required'),
						path: ['phone'],
					});
				}
			}
		});

export const stakeholdersSchema = (t: TranslationType) =>
	z.object({
		stakeholders: stakeholderSchema(t).array(),
	});

export type StakeholderSchema = z.infer<ReturnType<typeof stakeholderSchema>>;
export type StakeholdersSchema = z.infer<ReturnType<typeof stakeholdersSchema>>;

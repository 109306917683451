import { FC } from 'react';

import { Text } from '../../../TextComponents';
import { BaseAccordion } from '../BaseAccordion/BaseAccordion';

export type MarketingFaqAccordionProps = {
	icon?: string;
	question: string;
	answer: string;
};

export const MarketingFaqAccordion: FC<MarketingFaqAccordionProps> = ({ icon, question, answer }) => {
	return (
		<BaseAccordion icon={icon} title={question}>
			<Text as='p' variant='body-m' color='grayscale600'>
				{answer}
			</Text>
		</BaseAccordion>
	);
};

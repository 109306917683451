export const SUPPORTED_INSURANCES = [
	// Products
	'Alles-in-één aansprakelijkheidsverzekering',
	'Cyberverzekering',
	'Werknemersschadeverzekering',
	'Aansprakelijkheidsverzekering',
	'Spullen-in-je-opslagverzekering',
	'Spullen-in-je-busverzekering',
	'Ongevallenverzekering',
	'Vangnet voor verweer',
	'Rechtsbijstand voor Ondernemers',
	// Dekkingen
	'Beroepsaansprakelijkheid',
	'Bedrijfsaansprakelijkheid',
	'Beroeps- en bedrijfsaansprakelijkheid',
	'Inventaris en goederen',
	'Elektronica',
	'Glas en lichtreclame',
	'Geld',
	'Omzetverlies',
	'Spullen onderweg',
	'Gebouw',
	'Werknemersschade',
	'Motorrijtuig verzekering',
] as const;

export type SupportedInsurance = (typeof SUPPORTED_INSURANCES)[number];

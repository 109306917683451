import { cva, VariantProps } from 'class-variance-authority';

export const radio = cva(
	[
		'border-grayscale300',
		' flex',
		'items-center',
		'justify-center',
		'rounded-full',
		'border',
		'hover:bg-primary50',
		'hover:border-primaryMain',
		'active:bg-primary100',
		'state-checked:bg-primary200',
		'disabled:bg-grayscale50',
		'disabled:border-grayscale200',
		'disabled:state-checked:bg-greyscale200',
	],
	{
		variants: {
			size: {
				default: ['h-24', 'w-24'],
				small: ['h-16', 'w-16'],
			},
		},
		defaultVariants: {
			size: 'default',
		},
	}
);

export const radioIndicator = cva(['bg-primaryMain', 'rounded-full', 'data-[disabled]:bg-grayscale300'], {
	variants: {
		size: {
			default: ['h-16', 'w-16'],
			small: ['h-8', 'w-8'],
		},
	},
	defaultVariants: {
		size: 'default',
	},
});

export type RadioVariantProps = VariantProps<typeof radio>;

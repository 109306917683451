import { z } from 'zod';

import { propertyOwnershipOptions, TranslationType } from '@monorepo/types'; // Adjust the import path as needed

export const propertOwnershipSchema = (t: TranslationType) =>
	z.object({
		ownership: z.enum(propertyOwnershipOptions, { required_error: t('validation.required') }),
	});

export type PropertOwnershipSchema = z.infer<ReturnType<typeof propertOwnershipSchema>>;

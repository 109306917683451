export const animationKeyMap = {
	initial: 'in',
	animate: 'inactive',
	exit: 'out',
};

export const pageTransitions = {
	inactive: {
		opacity: 1,
		transition: {
			duration: 0.3,
			ease: 'easeInOut',
		},
	},
	out: {
		opacity: 0,
		transition: {
			duration: 0.3,
			ease: 'easeInOut',
		},
	},
	in: {
		opacity: 0,
		transition: {
			duration: 0.3,
			ease: 'easeInOut',
		},
	},
};

export const searchAnimations = {
	in: {
		y: 100,
	},
	inactive: {
		y: 0,
	},
	out: {
		y: -100,
	},
};

export const cardAnimations = {
	in: {
		y: -100,
	},
	inactive: {
		y: 0,
	},
	out: {
		y: 100,
	},
};

export const cartAnimations = {
	in: {
		x: 100,
	},
	inactive: {
		x: 0,
	},
	out: {
		x: -100,
	},
};

export const companySettingsAnimations = {
	inactive: {
		opacity: 1,
		transition: {
			duration: 0.3,
			ease: 'easeInOut',
		},
	},
	out: {
		opacity: 0,
		transition: {
			duration: 0.3,
			ease: 'easeInOut',
		},
	},
	in: {
		opacity: 0,
		transition: {
			duration: 0.3,
			ease: 'easeInOut',
		},
	},
};

import { faker } from '@faker-js/faker/locale/nl';

import { AcceptanceQuestion, PremiumQuestion } from '@monorepo/types';

import { mockGetAnswerOptions } from './answer-option.mock';

export function mockGetQuestion(reference: string): PremiumQuestion | AcceptanceQuestion {
	return {
		guid: faker.string.alphanumeric(16),
		group: faker.string.alphanumeric(16),
		question_phrasing: 'string',
		replacements: [],
		sequence: 10,
		question: faker.lorem.words(4),
		reference: reference,
		type: 'string',
		is_answered: false,
		answer_readable: 'string',
		answer_amount: 0,
		answer_checkbox: 'string',
		answer_date: 'string',
		answer_multiple_choice: {
			key: 'J',
			value: 'Ja',
		},
		answer_number: 0,
		answer_percentage: 0,
		options: mockGetAnswerOptions(2),
		icon_name: 'edit-alt',
	};
}

export function mockGetQuestions(num = 3, references: string[]): PremiumQuestion[] | AcceptanceQuestion[] {
	const questions: PremiumQuestion[] = [];
	for (let i = 0; i < num; i++) {
		questions.push(mockGetQuestion(references[i]));
	}
	return questions;
}

import { differenceInDays, parse } from 'date-fns';

import { TaskUrgency } from '@monorepo/types';

export const taskUrgencyOrder: Record<TaskUrgency, number> = {
	normal: 0,
	high: 1,
	critical: 2,
};

export const daysUntilTaskDueDate = (targetDate: string): number => {
	// Parse the input date string
	const targetDateTime = parse(targetDate, 'dd-MM-yyyy', new Date());

	// Get the current date and time
	const currentDate = new Date();

	// Calculate the difference in days
	const daysDifference = differenceInDays(targetDateTime, currentDate);

	return daysDifference;
};

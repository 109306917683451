import { IFaqCategorySection } from '@monorepo/types';

export const mockFaqCategorySection: IFaqCategorySection = {
	categories: [
		{
			icon: 'edit-alt',
			title: 'Publieke Aansprakelijkheid',
			description:
				'Beschermt je tegen claims voor persoonlijk letsel en schade aan andermans eigendom tijdens je zakelijke activiteiten.',
			link: {
				title: 'Publieke Aansprakelijkheid',
				content: 'Publieke Aansprakelijkheid',
				href: 'https://www.maia.insure',
			},
		},
		{
			icon: 'edit-alt',
			title: 'Publieke Aansprakelijkheid',
			description:
				'Beschermt je tegen claims voor persoonlijk letsel en schade aan andermans eigendom tijdens je zakelijke activiteiten.',
			link: {
				title: 'Publieke Aansprakelijkheid',
				content: 'Publieke Aansprakelijkheid',
				href: 'https://www.maia.insure',
			},
		},
		{
			icon: 'edit-alt',
			title: 'Publieke Aansprakelijkheid',
			description:
				'Beschermt je tegen claims voor persoonlijk letsel en schade aan andermans eigendom tijdens je zakelijke activiteiten.',
			link: {
				title: 'Publieke Aansprakelijkheid',
				content: 'Publieke Aansprakelijkheid',
				href: 'https://www.maia.insure',
			},
		},
		{
			icon: 'edit-alt',
			title: 'Publieke Aansprakelijkheid',
			description:
				'Beschermt je tegen claims voor persoonlijk letsel en schade aan andermans eigendom tijdens je zakelijke activiteiten.',
			link: {
				title: 'Publieke Aansprakelijkheid',
				content: 'Publieke Aansprakelijkheid',
				href: 'https://www.maia.insure',
			},
		},
		{
			icon: 'edit-alt',
			title: 'Publieke Aansprakelijkheid',
			description:
				'Beschermt je tegen claims voor persoonlijk letsel en schade aan andermans eigendom tijdens je zakelijke activiteiten.',
			link: {
				title: 'Publieke Aansprakelijkheid',
				content: 'Publieke Aansprakelijkheid',
				href: 'https://www.maia.insure',
			},
		},
		{
			icon: 'edit-alt',
			title: 'Publieke Aansprakelijkheid',
			description:
				'Beschermt je tegen claims voor persoonlijk letsel en schade aan andermans eigendom tijdens je zakelijke activiteiten.',
			link: {
				title: 'Publieke Aansprakelijkheid',
				content: 'Publieke Aansprakelijkheid',
				href: 'https://www.maia.insure',
			},
		},
	],
};

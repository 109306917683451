import cn from 'classnames';
import { motion, useInView } from 'framer-motion';
import parse from 'html-react-parser';
import Image from 'next/image';
import { FC, useRef } from 'react';

import { MarketingCtaButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { useIsMobile } from '@monorepo/shared/lib/hooks';
import { generateSizesString, slugify } from '@monorepo/shared/lib/utils';
import { ITextImageSection } from '@monorepo/types';

type TextImageSectionProps = ITextImageSection & {
	className?: string;
};

const containerTransition = {
	hidden: {},
	visible: {
		transition: {
			staggerChildren: 0.3,
		},
	},
};

const leftTransition = {
	hidden: {
		x: -20,
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
		transition: {
			ease: [0.04, 0.62, 0.23, 0.98],
		},
	},
};

const rightTransition = {
	hidden: {
		x: 20,
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
		transition: {
			ease: [0.04, 0.62, 0.23, 0.98],
		},
	},
};

export const TextImageSection: FC<TextImageSectionProps> = ({
	title,
	content,
	image,
	imagePosition,
	link,
	variant = 'default',
	className,
}) => {
	const sectionRef = useRef<HTMLDivElement>(null);
	const isInview = useInView(sectionRef, { amount: 0.7, once: true });
	const { isSmallTablet } = useIsMobile();

	return (
		<section className={cn(className, 'py-48')} ref={sectionRef}>
			<LayoutGrid layout='marketing'>
				<motion.div
					variants={containerTransition}
					initial={isSmallTablet || variant === 'inverted' ? 'visible' : 'hidden'}
					animate={isInview || isSmallTablet || variant === 'inverted' ? 'visible' : 'hidden'}
					className={cn(
						variant === 'inverted' && 'bg-primaryMain rounded-12',
						variant === 'default' && 'gap-24 md:gap-48',
						'col-start-2 grid grid-cols-1 md:grid-cols-2'
					)}
					key={`text-image-section-${slugify(title)}`}>
					<motion.div
						variants={imagePosition === 'left' ? rightTransition : leftTransition}
						className={cn(
							imagePosition === 'right' ? 'md:order-1' : 'md:order-2',
							variant === 'inverted' && 'bg-primaryMain rounded-12 px-24 md:px-48',
							'col-span-full flex flex-col justify-center space-y-32 py-24 md:col-span-1 md:py-48'
						)}>
						<Text
							as='h3'
							variant={variant === 'inverted' ? 'display-4' : 'display-2'}
							color={variant === 'inverted' ? 'grayscale0' : 'primaryMain'}
							weight='semibold'>
							{title}
						</Text>
						<div className='rounded-12 relative h-[328px] w-full overflow-hidden md:hidden'>
							<Image
								sizes={generateSizesString()}
								src={image.path}
								alt={image.alt}
								fill
								className='object-cover object-top'
							/>
						</div>
						<Text variant='body-l' color={variant === 'inverted' ? 'grayscale0' : 'grayscale400'}>
							{typeof content === 'string' ? parse(content) : content}
						</Text>
						{link && <MarketingCtaButton link={link} className='w-full sm:w-fit' />}
					</motion.div>

					<motion.div
						variants={imagePosition === 'right' ? rightTransition : leftTransition}
						className={cn(
							imagePosition === 'right' ? 'md:order-2' : 'md:order-1',
							'rounded-12 relative col-span-full h-[560px] overflow-hidden max-md:hidden md:col-span-1 md:h-full'
						)}>
						<Image
							sizes={generateSizesString()}
							src={image.path}
							alt={image.alt}
							fill
							className='object-cover object-top'
						/>
					</motion.div>
				</motion.div>
			</LayoutGrid>
		</section>
	);
};

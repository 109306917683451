import cn from 'classnames';
import { ButtonHTMLAttributes, forwardRef } from 'react';

import { ThemeColors } from '@monorepo/types';

import { Text } from '../../../TextComponents';
import { Icon, IconSizes } from '../../Icon/Icon';

type IconButtonProps = {
	iconName: string;
	prefix?: string;
	suffix?: string;
	size?: IconSizes;
	color?: ThemeColors;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
	({ iconName, size = 'lg', color, type = 'button', prefix, suffix, className, ...rest }, ref) => {
		const hasPrefixOrSuffix = prefix || suffix;

		return (
			<button
				ref={ref}
				type={type}
				role={rest?.role || 'button'}
				className={cn('flex items-center justify-center', className, hasPrefixOrSuffix && 'space-x-4')}
				{...rest}>
				{prefix && (
					<Text variant='body-s' weight='medium' color='info300' className='underline' as='span'>
						{prefix}
					</Text>
				)}
				<Icon
					name={iconName}
					size={size}
					color={color}
					className={cn(rest.disabled ? 'cursor-not-allowed' : 'cursor-pointer', 'leading-none')}
				/>
				{suffix && (
					<Text variant='body-s' weight='medium' color='info300' className='underline' as='span'>
						{suffix}
					</Text>
				)}
			</button>
		);
	}
);

import Link from 'next/link';
import { FC } from 'react';

import { SupportedInsurance } from '@monorepo/types';

import { ActionButton } from '../../../../DataEntry';
import { Icon } from '../../../../Misc';
import { Text } from '../../../../TextComponents';
import { StatusLabel, StatusLabelStatus } from '../../../TagsAndStatus';
import { BaseCard } from '../../BaseCard/BaseCard';

type DashboardCardItemProps = (
	| {
			variant: 'insurance';
			title: SupportedInsurance;
			description: string;
			status: StatusLabelStatus;
			statusText: string;
			icon: string;
	  }
	| {
			variant: 'claim';
			title: SupportedInsurance;
			description: string;
			status: StatusLabelStatus;
			statusText: string;
			icon: string;
	  }
	| {
			variant: 'todo';
			title: string;
			description: string;
			status: StatusLabelStatus;
			statusText: string;
			icon: string;
	  }
	| {
			variant: 'invoice';
			amount: string;
	  }
) & {
	to: string;
};

export const DashboardCardItem: FC<DashboardCardItemProps> = ({ to, ...props }) => {
	return (
		<BaseCard isElevated={false}>
			<Link href={to} className='flex w-full items-center space-x-8 px-16 py-12'>
				<div className='flex flex-1 items-center space-x-16'>
					<Icon
						name={
							props.variant === 'claim' || props.variant === 'insurance' || props.variant === 'todo'
								? props.icon
								: 'credit-card'
						}
						color='primary200'
						size='md'
					/>
					{(props.variant === 'insurance' || props.variant === 'claim' || props.variant === 'todo') && (
						<div className='flex flex-col'>
							<Text variant='body-s' weight='medium' color='grayscale600' className='line-clamp-1 max-sm:max-w-[100px]'>
								{props.title}
							</Text>
							<Text variant='body-s' weight='regular' color='grayscale400' className='line-clamp-1'>
								{props.description}
							</Text>
						</div>
					)}
				</div>
				<div className='items-row flex items-center'>
					{(props.variant === 'claim' || props.variant === 'todo' || props.variant === 'insurance') && (
						<div className='pr-16'>
							<StatusLabel status={props.status} text={props.statusText} />
						</div>
					)}

					{props.variant === 'insurance' || props.variant === 'claim' || props.variant === 'todo' ? (
						<ActionButton icon='angle-right' size='small' />
					) : (
						<Text variant='body-l' weight='semibold' color='grayscale600'>
							{props.amount}
						</Text>
					)}
				</div>
			</Link>
		</BaseCard>
	);
};

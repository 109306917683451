import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useAnalytics } from '../../../../lib/hooks';
import { Text } from '../../../TextComponents';

type TooltipProps = {
	id: string;
	title?: string;
	content: ReactNode | string;
	className?: string;
};

export const TooltipPopup: FC<TooltipProps> = ({ id, title, content, className }) => {
	const { trackEvent } = useAnalytics();

	return (
		<ReactTooltip
			afterShow={() => trackEvent('select_content', { content_type: 'tooltip', item_id: id })}
			id={id}
			delayHide={150}
			className={cn(
				className,
				'!bg-grayscale0 border-primary100 shadow-4 rounded-6 z-10 w-fit max-w-[500px] border p-16 !text-[inherit] !opacity-100'
			)}>
			<div className='space-y-8'>
				{title && (
					<Text as='p' variant='body-s' weight='semibold' color='grayscale600'>
						{title}
					</Text>
				)}

				<Text
					variant='body-s'
					color='grayscale600'
					className='prose prose-p:m-0 prose-li:m-0 prose-ul:m-0 prose-ul:px-2 prose-ul:list-disc rounded-12 z-10'>
					{content}
				</Text>
			</div>
		</ReactTooltip>
	);
};

import type { DataLayerEventName, DataLayerEventParams, DataLayerObject } from '@monorepo/types';

import { isProduction } from '../environment/environment';

const pushToDataLayer = (obj: DataLayerObject) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(obj);

	if (!isProduction && process.env.NODE_ENV !== 'test') {
		console.debug('Track event.', obj);
	}
};

export const trackEvent = (event: DataLayerEventName, params: DataLayerEventParams) => {
	const obj: DataLayerObject = {
		event,
		...params,
	};

	pushToDataLayer(obj);

	if (isProduction) {
		try {
			window?.heap?.track(event, params);
		} catch (err) {
			console.warn(err);
		}
	}
};

export const resetEcommerce = () => {
	pushToDataLayer({ ecommerce: null });
};

export const trackEcommerceEvent = (event: DataLayerEventName, data: DataLayerEventParams) => {
	const obj: DataLayerObject = {
		event,
		ecommerce: { ...data, currency: 'EUR' },
	};

	pushToDataLayer(obj);

	if (isProduction) {
		try {
			window?.heap?.track(event, data);
		} catch (err) {
			console.warn(err);
		}
	}
};

export const trackPageEvent = (path: string) => {
	const obj: DataLayerObject = {
		event: 'customPage',
		pageData: {
			path,
		},
	};

	pushToDataLayer(obj);
};
export const setUserData = (userId: string) => {
	pushToDataLayer({
		event: 'customUser',
		userData: {
			userId,
		},
	});
};

/**
 * Returns true if process is running in the browser
 */
export const transformToCurrency = (
	number: number | string | undefined | null,
	format: 'en-US' | 'nl-NL' = 'nl-NL',
	currency: 'USD' | 'EUR' = 'EUR'
) => {
	if (typeof number === 'undefined' || typeof number === 'object') {
		return '';
	}

	const _number = +number;

	return new Intl.NumberFormat(format, {
		style: 'currency',
		currency,
		minimumFractionDigits: _number % 1 === 0 ? 0 : 2,
	}).format(_number);
};

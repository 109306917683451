import { MediaTypes, TranslationType } from '@monorepo/types';

/**
 * Convert a base64 string to a Uint8Array which we can use
 */
export const base64StringToUint8Array = (base64String: string) => {
	const binaryString = atob(base64String);
	const arrayBuffer = new ArrayBuffer(binaryString.length);
	const uint8Array = new Uint8Array(arrayBuffer);
	for (let i = 0; i < binaryString.length; i++) {
		uint8Array[i] = binaryString.charCodeAt(i);
	}

	return uint8Array;
};

/**
 * Old fashioned way to create to download a file
 */
export const downloadFile = (blob: Blob, filename: string) => {
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.download = filename;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const getBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			// split the MIME type from the base64 string
			const base64String = reader.result?.toString().split(',')[1];
			resolve(base64String || '');
		};
		reader.onerror = (error) => reject(error);
	});
};

export const getDocName = (type: MediaTypes, t: TranslationType, filename: string) => {
	const docNames: { [key in MediaTypes]?: string } = {
		[MediaTypes.GeneralInsuranceTerms]: t('documents.generalInsuranceTerms'),
		[MediaTypes.GeneralTerms]: t('documents.generalTerms'),
		[MediaTypes.ServiceGuide]: t('documents.serviceGuide'),
		[MediaTypes.Ipid]: t('documents.ipid'),
		[MediaTypes.Condition]: t('documents.terms'),
	};
	return docNames[type] || filename;
};

export const getFileIconSourceFromMimeType = (mimeType: string): string => {
	const iconSourceMapLevelTwo: Record<string, string> = {
		'application/gzip': 'zip.svg',
		'application/json': 'json.svg',
		'application/mp4': 'video.svg',
		'application/pdf': 'pdf.svg',
		'application/x-abiword': 'zip.svg',
		'application/msword': 'doc.svg',
		'application/vnd.ms-powerpoint': 'powerpoint.svg',
		'application/vnd.rar': 'zip.svg',
		'application/rtf': 'txt.svg',
		'application/vnd.ms-excel': 'excel.svg',
		'application/zip': 'zip.svg',

		'audio/aac': 'audio.svg',
		'audio/mp4': 'audio.svg',
		'audio/mpeg': 'audio.svg',
		'audio/ogg': 'audio.svg',
		'audio/wav': 'audio.svg',
		'audio/webm': 'audio.svg',

		'image/apng': 'image.svg',
		'image/avif': 'image.svg',
		'image/png': 'image.svg',
		'image/giff': 'image.svg',
		'image/jpeg': 'image.svg',
		'image/svg+xml': 'svg.svg',
		'image/x-png': 'image.svg',
		'image/tiff': 'image.svg',
		'image/webp': 'image.svg',

		'text/plain': 'txt.svg',
		'text/csv': 'excel.svg',

		'video/ogg': 'video.svg',
	};

	if (mimeType in iconSourceMapLevelTwo) {
		return iconSourceMapLevelTwo[mimeType];
	}

	const iconSourceMapLevelOne: Record<string, string> = {
		application: 'doc.svg',
		audio: 'audio.svg',
		image: 'image.svg',
		text: 'txt.svg',
	};

	const parts = mimeType.split('/');

	if (parts[0] in iconSourceMapLevelOne) {
		return iconSourceMapLevelOne[parts[0]];
	}

	return 'generic.svg';
};

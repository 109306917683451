import { InsuranceDocs, MediaTypes, ProductDocument } from '@monorepo/types';

export const mockGetInsuranceDocuments = (): InsuranceDocs => {
	return {
		products: [
			{
				name: 'Alles-in-één aansprakelijkheidsverzekering',
				code: 'M-MAK-AB-0001',
				documents: [
					{
						type: MediaTypes.Ipid,
						name: '',
						filename: 'Conditions - HDI - AB.pdf',
						mimetype: 'application/pdf',
						path: 'media/10193317-9473-4d0d-87e4-703e0ced9ada/Conditions - HDI - AB.pdf',
					},
				],
				options: [
					{
						name: 'Beroepsaansprakelijkheid',
						code: 'M-MAK-AB-0001-0001',
						documents: [
							{
								type: MediaTypes.Ipid,
								name: 'Verzekeringskaart',
								filename: 'IPID - BAV - HDI.pdf',
								mimetype: 'application/pdf',
								path: 'media/57c1e6d5-86a9-41bf-9a45-76d9ec34125d/IPID - BAV - HDI.pdf',
							},
							{
								type: MediaTypes.Condition,
								name: '',
								filename: 'Conditions - HDI - BAV - 2.pdf',
								mimetype: 'application/pdf',
								path: 'media/8216d007-80ee-4442-9d2e-68a5667afb4b/Conditions - HDI - BAV - 2.pdf',
							},
						],
					},
					{
						name: 'Bedrijfsaansprakelijkheid',
						code: 'M-MAK-AB-0001-0002',
						documents: [
							{
								type: MediaTypes.Ipid,
								name: 'Verzekeringskaart',
								filename: 'IPID - AVB - HDI.pdf',
								mimetype: 'application/pdf',
								path: 'media/9fd210ab-b729-404e-ad2b-87375a60aabd/IPID - AVB - HDI.pdf',
							},
							{
								type: MediaTypes.Condition,
								name: '',
								filename: 'Conditions - HDI - AVB.pdf',
								mimetype: 'application/pdf',
								path: 'media/363af0b5-9349-44d7-b8b4-67d3323ab93b/Conditions - HDI - AVB.pdf',
							},
						],
					},
				],
				addons: [],
			},
			{
				name: 'Vangnet voor verweer',
				code: 'M-ALG-AB-0003',
				documents: [
					{
						type: MediaTypes.Condition,
						name: '',
						filename: 'DAS - Vangnet voor verweer.pdf',
						mimetype: 'application/pdf',
						path: 'media/ea62fec1-75a1-4ff5-9f34-d710f24ad2e1/DAS - Vangnet voor verweer.pdf',
					},
				],
				options: [
					{
						name: 'Vangnet voor verweer',
						code: 'M-ALG-AB-0003-0001',
						documents: [
							{
								type: MediaTypes.Ipid,
								name: 'Verzekeringskaart',
								filename: 'IPID - Vangnet voor Verweer - DAS.pdf',
								mimetype: 'application/pdf',
								path: 'media/cecbfdd8-e664-4594-9c3d-7d4c5172e0b4/IPID - Vangnet voor Verweer - DAS.pdf',
							},
						],
					},
				],
				addons: [],
			},
			{
				name: 'Werknemersschadeverzekering',
				code: 'M-ALG-AB-0002',
				documents: [
					{
						type: MediaTypes.Condition,
						name: 'Verzekeringsvoorwaarden (algemeen)',
						filename: 'ALG.AV.1.pdf',
						mimetype: 'application/pdf',
						path: 'media/62909e96-afb1-4617-8df7-a8865b3eb9f7/ALG.AV.1.pdf',
					},
					{
						type: MediaTypes.Condition,
						name: '',
						filename: 'WSV.AV.1.pdf',
						mimetype: 'application/pdf',
						path: 'media/c2741990-2406-48f8-a81e-ba04316cc36a/WSV.AV.1.pdf',
					},
					{
						type: MediaTypes.Ipid,
						name: '',
						filename: 'IPID - Werknemersschadeverzekering - HDI.pdf',
						mimetype: 'application/pdf',
						path: 'media/086ec8ef-2f6c-4f41-8a5b-2cc4a71c0b9e/IPID - Werknemersschadeverzekering - HDI.pdf',
					},
				],
				options: [],
				addons: [],
			},
			{
				name: 'Rechtsbijstandsverzekering',
				code: 'VPI-ALG-VAR-0002',
				documents: [
					{
						type: MediaTypes.Condition,
						name: null,
						filename: 'Algemene polisvoorwaarden rechtsbijstandverzekeringen DAS.pdf',
						mimetype: 'application/pdf',
						path: 'media/6a9584c5-e41b-4ca6-968d-def44214b8ed/Algemene polisvoorwaarden rechtsbijstandverzekeringen DAS.pdf',
					},
					{
						type: MediaTypes.Condition,
						name: null,
						filename: 'Bijzondere voorwaarden DAS voor Ondernemers.pdf',
						mimetype: 'application/pdf',
						path: 'media/320fb0b0-c533-4075-8a58-f1113cbde489/Bijzondere voorwaarden DAS voor Ondernemers.pdf',
					},
					{
						type: MediaTypes.Ipid,
						name: 'Verzekeringskaart',
						filename: 'IPID DAS Rechtsbijstand voor ondernemers.pdf',
						mimetype: 'application/pdf',
						path: 'media/a892de08-4325-4f83-a8ac-197ec04406c3/IPID DAS Rechtsbijstand voor ondernemers.pdf',
					},
				],
				options: [],
				addons: [],
			},
			{
				name: 'Cyberverzekering',
				code: 'M-ALG-AB-0001',
				documents: [],
				options: [
					{
						name: 'Cyberverzekering',
						code: 'M-ALG-AB-0001-0001',
						documents: [
							{
								type: MediaTypes.Condition,
								name: '',
								filename: 'Conditions - Cyber - AIG.pdf',
								mimetype: 'application/pdf',
								path: 'media/4cedbdc3-2e2b-4545-ab42-93e766b2894c/Conditions - Cyber - AIG.pdf',
							},
							{
								type: MediaTypes.Ipid,
								name: 'Verzekeringskaart',
								filename: 'IPID - Cyber - AIG.pdf',
								mimetype: 'application/pdf',
								path: 'media/10fef6d2-72af-4181-8c92-c6de54eaa508/IPID - Cyber - AIG.pdf',
							},
							{
								type: MediaTypes.Condition,
								name: '',
								filename: 'CyberEdge First Response - AIG.pdf',
								mimetype: 'application/pdf',
								path: 'media/719b7bb8-77bf-4f70-bfe6-b92789fcfc7a/CyberEdge First Response - AIG.pdf',
							},
						],
					},
				],
				addons: [],
			},
		],
		documents: [
			{
				type: MediaTypes.GeneralTerms,
				filename: 'Algemene voorwaarden - NVM.pdf',
				mimetype: 'application/pdf',
				path: 'media/decf36fb-660f-4387-8006-eb929b39c68a/Algemene voorwaarden - NVM.pdf',
			},
			{
				type: MediaTypes.ServiceGuide,
				filename: 'Dienstenwijzer - NVM.pdf',
				mimetype: 'application/pdf',
				path: 'media/fe70d985-7f6f-4059-89aa-91ee6683d4ea/Dienstenwijzer - NVM.pdf',
			},
		],
	};
};

export const mockProductDocuments = (): ProductDocument => {
	return {
		name: 'Alles-in-één aansprakelijkheidsverzekering',
		code: 'M-MAK-AB-0001',
		documents: [
			{
				type: MediaTypes.Ipid,
				name: '',
				filename: 'Conditions - HDI - AB.pdf',
				mimetype: 'application/pdf',
				path: 'media/10193317-9473-4d0d-87e4-703e0ced9ada/Conditions - HDI - AB.pdf',
			},
		],
		options: [
			{
				name: 'Beroepsaansprakelijkheid',
				code: 'M-MAK-AB-0001-0001',
				documents: [
					{
						type: MediaTypes.Ipid,
						name: 'Verzekeringskaart',
						filename: 'IPID - BAV - HDI.pdf',
						mimetype: 'application/pdf',
						path: 'media/57c1e6d5-86a9-41bf-9a45-76d9ec34125d/IPID - BAV - HDI.pdf',
					},
					{
						type: MediaTypes.Condition,
						name: '',
						filename: 'Conditions - HDI - BAV - 2.pdf',
						mimetype: 'application/pdf',
						path: 'media/8216d007-80ee-4442-9d2e-68a5667afb4b/Conditions - HDI - BAV - 2.pdf',
					},
				],
			},
			{
				name: 'Bedrijfsaansprakelijkheid',
				code: 'M-MAK-AB-0001-0002',
				documents: [
					{
						type: MediaTypes.Ipid,
						name: 'Verzekeringskaart',
						filename: 'IPID - AVB - HDI.pdf',
						mimetype: 'application/pdf',
						path: 'media/9fd210ab-b729-404e-ad2b-87375a60aabd/IPID - AVB - HDI.pdf',
					},
					{
						type: MediaTypes.Condition,
						name: '',
						filename: 'Conditions - HDI - AVB.pdf',
						mimetype: 'application/pdf',
						path: 'media/363af0b5-9349-44d7-b8b4-67d3323ab93b/Conditions - HDI - AVB.pdf',
					},
				],
			},
		],
		addons: [],
	};
};

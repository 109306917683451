import classNames from 'classnames';
import { FC } from 'react';

export type RectangularSkeletonProps = {
	isRounded?: boolean;
	className?: string;
};

export const RectangularSkeleton: FC<RectangularSkeletonProps> = ({ isRounded, className }) => (
	<div role='status' className={classNames('animate-pulse select-none', isRounded && 'rounded-6', className)}>
		<span className='sr-only'>Loading...</span>
	</div>
);

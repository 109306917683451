import cn from 'classnames';
import { FC, HTMLProps, PropsWithChildren } from 'react';

import { ThemeTypographyWeight } from '@monorepo/types';

import { Text } from '../../../TextComponents';

type FormLabelProps = {
	isSmallMargin?: boolean;
	weight?: ThemeTypographyWeight;
} & PropsWithChildren<Omit<HTMLProps<HTMLLabelElement>, 'as' | 'color' | 'ref'>>;

export const FormLabel: FC<FormLabelProps> = ({
	children,
	className,
	isSmallMargin = false,
	weight = 'regular',
	...props
}) => {
	return (
		<Text
			as='label'
			variant='body-m'
			color='grayscale500'
			weight={weight}
			className={cn('block w-full', isSmallMargin ? 'mb-8' : 'mb-16', className)}
			{...props}>
			{children}
		</Text>
	);
};

import cn from 'classnames';
import Link from 'next/link';
import { FC, Fragment } from 'react';

import { TextButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IBlurbSection } from '@monorepo/types';

import { BlurbCard } from '../BlurbCard/BlurbCard';

export type BlurbSectionProps = IBlurbSection & {
	className?: string;
};

export const BlurbSection: FC<BlurbSectionProps> = ({ title, subtitle, blurbs, className }) => {
	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-48'>
					<div className='flex flex-col space-y-12'>
						<Text as='h3' variant='display-4' weight='semibold' color='primaryMain'>
							{title}
						</Text>
						<Text as='p' variant='display-6' weight='regular' color='primaryMain'>
							{subtitle}
						</Text>
					</div>
					{blurbs.length > 0 && (
						<div className='grid grid-cols-3 gap-24 md:gap-48'>
							{blurbs.map((blurb, key) => (
								<Fragment key={`centered-blurb-section-card-${key}`}>
									{blurb.link ? (
										<Link
											href={blurb.link.href}
											target={blurb.link.target}
											title={blurb.link.title}
											className='col-span-full h-full md:col-span-1'>
											<BlurbCard
												{...blurb}
												action={
													blurb.link ? (
														<TextButton
															size='sm'
															label={blurb.link.content || blurb.link.title}
															className='w-fit'
															iconRight='angle-right'
														/>
													) : undefined
												}
											/>
										</Link>
									) : (
										<BlurbCard className='col-span-full md:col-span-1' {...blurb} />
									)}
								</Fragment>
							))}
						</div>
					)}
				</div>
			</LayoutGrid>
		</section>
	);
};

import cn from 'classnames';
import Link from 'next/link';
import { FC } from 'react';

import { TextButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { useAnalytics } from '@monorepo/shared/lib/hooks';
import { ISectionCtaCards } from '@monorepo/types';

import { BlurbCard } from '@marketing/components/BlurbCard/BlurbCard';

type Props = ISectionCtaCards & {
	className?: string;
};

export const SectionCtaCards: FC<Props> = ({ cards, className }) => {
	const { trackEvent } = useAnalytics();

	const handleClick = () => {
		trackEvent('start_funnel', { sales_channel: 'maia' });
	};

	return (
		<section className={cn(className, 'pb-48')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 grid grid-cols-1 gap-32 md:grid-cols-3 md:gap-48'>
					{cards.map(({ link, title, description }, index) => (
						<Link
							onClick={handleClick}
							key={`most-ugly-link-impl-${index}`}
							href={link.href}
							title={link.title}
							target={link.target}>
							<BlurbCard
								title={title}
								description={description}
								action={
									<TextButton className='w-full md:w-fit' label={link.content || link.title} iconRight='angle-right' />
								}
							/>
						</Link>
					))}
				</div>
			</LayoutGrid>
		</section>
	);
};

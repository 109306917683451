import classNames from 'classnames';
import { FC, forwardRef, InputHTMLAttributes, useState } from 'react';
import { FieldError, FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';

import { FormBaseInputWrapper } from '../FormBaseInputWrapper/FormBaseInputWrapper';

export type FormBaseInputDefaultProps<TFormValues extends FieldValues> = {
	name: Path<TFormValues>;
	rules?: RegisterOptions;
	register?: UseFormRegister<TFormValues>;
	errors?: FieldError;
};

export type FormBaseInputProps = {
	startIcon?: string;
	endIcon?: string;
	fieldSize?: 'sm' | 'md' | 'lg';
	onEndIconClick?: () => void;
	isError?: boolean;
	isUppercase?: boolean;
	numberControls?: boolean;
	onNumberControlsClick?: (type: 'increment' | 'decrement') => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'aria-invalid'>;

export const baseClasses =
	'typography-body-m border peer text-grayscale500 transition-colors duration-300 ease-out placeholder:font-light placeholder:text-grayscale300';
export const disabledClasses =
	'disabled:border-grayscale200 disabled:bg-grayscale50 disabled:text-grayscale300 disabled:cursor-not-allowed disabled:font-light';
export const hoverClasses = 'hover:border-primaryMain';
export const focusClasses = 'focus:border-primaryMain focus:bg-primary50 focus:ring-0';

export const fieldSizeSmClasses = 'rounded-6 py-8 px-12',
	fieldSizeMdClasses = 'rounded-6 py-8 px-12',
	fieldSizeLgClasses = 'rounded-12 p-20';

export const FormBaseInput: FC<FormBaseInputProps> = forwardRef<HTMLInputElement, FormBaseInputProps>(
	(
		{
			startIcon,
			endIcon,
			fieldSize = 'md',
			placeholder,
			isError,
			isUppercase,
			className,
			onBlur,
			onChange,
			onEndIconClick,
			numberControls = false,
			onNumberControlsClick,
			...props
		},
		ref
	) => {
		const [isDirty, setIsDirty] = useState<boolean>(false);
		const [cachedValue, setCachedValue] = useState<string>('');

		return (
			<FormBaseInputWrapper
				isDisabled={props.disabled}
				isDirty={isDirty}
				fieldSize={fieldSize}
				isError={isError}
				isEmpty={cachedValue?.length === 0}
				showValidationIcon={props?.type !== 'search' || !numberControls}
				startIcon={startIcon}
				endIcon={endIcon}
				numberControls={numberControls}
				onNumberControlsClick={onNumberControlsClick}
				endIconMoreSpacing={props?.type === 'search'} // For Search Inputs we add more spacing between browser icons and our icon
				onEndIconClick={onEndIconClick}>
				<input
					ref={ref}
					placeholder={placeholder}
					aria-invalid={isError}
					className={classNames(
						baseClasses,
						disabledClasses,
						hoverClasses,
						focusClasses,
						// Error
						isError && 'border-error300',
						!isError && 'border-grayscale200',
						// Adornments
						startIcon && fieldSize === 'lg' && '!pl-50',
						startIcon && fieldSize === 'md' && '!pl-40',
						startIcon && fieldSize === 'sm' && '!pl-40',
						endIcon && 'pr-10',
						// Other
						isUppercase && 'uppercase',
						'w-full',
						fieldSize === 'sm' && fieldSizeSmClasses,
						fieldSize === 'md' && fieldSizeMdClasses,
						fieldSize === 'lg' && fieldSizeLgClasses,
						// Overrides
						className
					)}
					onChange={(e) => {
						onChange && onChange(e);
						setCachedValue(e.target.value);
					}}
					onBlur={(e) => {
						if (props.type === 'number') {
							let newValue = Number(e.target.value);
							if (props?.min && newValue < +props.min) {
								newValue = +props?.min; // Set to min if the new value is less than min
							} else if (props?.max && newValue > +props.max) {
								newValue = +props?.max; // Set to max if the new value is greater than max
							}
							e.target.value = newValue.toString();
						}
						onBlur && onBlur(e);
						setIsDirty(true);
						e.target.value && setCachedValue(e.target.value);
					}}
					{...props}
				/>
			</FormBaseInputWrapper>
		);
	}
);
FormBaseInput.displayName = 'FormBaseInput';

import { FC } from 'react';

import { Skeleton } from '../../../../Misc';

type DashboardCardItemSkeletonProps = {
	variant: 'insurance' | 'claim' | 'todo' | 'invoice';
};

export const DashboardCardItemSkeleton: FC<DashboardCardItemSkeletonProps> = ({ variant }) => {
	return (
		<div className='bg-grayscale0 rounded-6 border-grayscale100 flex items-center space-x-16 border px-16 py-12 transition-colors'>
			<div className='flex flex-1 items-center space-x-16'>
				<div>
					<Skeleton variant='circular' className='h-[20px] w-[20px]' />
				</div>
				{(variant === 'insurance' || variant === 'claim' || variant === 'todo') && (
					<div className='flex flex-col space-y-4'>
						<Skeleton variant='text' size='body-xs' className='w-[200px]' />
						<Skeleton variant='text' size='body-xs' className='w-[125px]' />
					</div>
				)}
			</div>
			<div className='items-row flex space-x-16'>
				{variant === 'claim' && <Skeleton variant='rounded' className='h-[24px] w-[75px]' />}

				{variant === 'insurance' || variant === 'claim' || variant === 'todo' ? (
					<Skeleton variant='circular' className='h-[24px] w-[24px]' />
				) : (
					<Skeleton variant='text' size='body-l' className='w-[100px]' />
				)}
			</div>
		</div>
	);
};

import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { isProduction } from '@monorepo/shared/lib/utils';
import { PageProps } from '@monorepo/types';

import { withCMS, withCMSV2 } from '@cms/hoc';
import {
	HeadANKO,
	HeadCybermeister,
	HeadMeestersgilde,
	MaiaHead,
	NVMHead,
	OnderhoudNlHead,
	SchoutenHead,
	VastgoedproHead,
} from '@common/components';
import { selectTheme } from '@common/store';
import {
	canonicalUrlBuilder,
	ogImageBuilder,
	seoDefaultConfig,
	seoDescriptionMap,
	seoTitleTemplateMap,
} from '@common/utils';
import { HeadMarengo } from '../HeadMarengo/HeadMarengo';
import { HeadNKC } from '../HeadNKC/HeadNKC';
import { HeadOnlineBeautyCursus } from '../HeadOnlineBeautyCursus/HeadOnlineBeautyCursus';
import { HeadTrustoo } from '../HeadTrustoo/HeadTrustoo';
import { HeadZorgZekerPakket } from '../HeadZorgZekerPakket/HeadZorgZekerPakket';

export const Page: PageProps = ({ title, children, ...props }) => {
	const theme = useSelector(selectTheme);
	const { asPath } = useRouter();

	return (
		<>
			{/* TODO: Make dynamic heads */}
			{theme === 'maia' && <MaiaHead />}
			{theme === 'nvm' && <NVMHead />}
			{theme === 'vbo' && <SchoutenHead />}
			{theme === 'vastgoedpro' && <VastgoedproHead />}
			{theme === 'onderhoudnl' && <OnderhoudNlHead />}
			{theme === 'anko' && <HeadANKO />}
			{theme === 'cybermeister' && <HeadCybermeister />}
			{theme === 'meestersgilde' && <HeadMeestersgilde />}
			{theme === 'trustoo' && <HeadTrustoo />}
			{theme === 'deonlinebeautycursussen' && <HeadOnlineBeautyCursus />}
			{theme === 'nkc' && <HeadNKC />}
			{theme === 'marengo' && <HeadMarengo />}
			{theme === 'zorgzekerpakket' && <HeadZorgZekerPakket />}

			<NextSeo
				titleTemplate={seoTitleTemplateMap[theme] || seoDefaultConfig.titleTemplate}
				title={title || seoDefaultConfig.title}
				description={seoDescriptionMap[theme]}
				canonical={canonicalUrlBuilder(asPath)}
				openGraph={ogImageBuilder(theme)}
				nofollow={!isProduction}
				noindex={!isProduction}
				{...props}
			/>
			{children}
		</>
	);
};

Page.CMS = withCMS(Page);
Page.CMSV2 = withCMSV2(Page);

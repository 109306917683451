import { motion } from 'framer-motion';
import { Trans, useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';

import { Bundle, CalculationProduct } from '@monorepo/types';

import { useProductFormControls } from '../../../../lib/hooks';
import { transformToCurrency } from '../../../../lib/utils';
import { TextButton } from '../../../DataEntry';
import { Icon, ProductIcon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { Tag } from '../../TagsAndStatus';
import { BaseCard } from '../BaseCard/BaseCard';

type Props = {
	id: string;
	product: CalculationProduct;
	bundles: Bundle[];
	form: ReactNode;
	nextButton: ReactNode;
	handleMoreInfoClick: (guid: string) => void;
	handleEditButtonClick: (guid: string) => void;
	isEditVisible: (guid: string) => boolean;
	isDetailsVisible: (guid: string) => boolean;
};

export const PickOptionsCard: FC<Props> = ({
	id,
	product,
	bundles,
	form,
	nextButton,
	handleMoreInfoClick,
	handleEditButtonClick,
	isEditVisible,
	isDetailsVisible,
}) => {
	const { t } = useTranslation(['common']);

	const { hasControls } = useProductFormControls();

	const activeOption = product.options.find(({ is_active }) => is_active);
	const activeInsuredAmount = activeOption?.specs.find(({ is_insured_amount }) => is_insured_amount)?.value;
	const activeDeductible = activeOption?.specs.find(
		({ is_deductible, deductible_type }) => is_deductible && deductible_type === 'default'
	)?.value;

	return (
		<BaseCard
			enableEffects={false}
			id={id}
			className='flex flex-col space-y-24 overflow-hidden p-16 md:p-24'
			data-testid={`pick-options-card-${product.name}`}>
			<div className='flex flex-row'>
				<div className='flex grow flex-row space-x-16'>
					<ProductIcon name={product.icon_name} />
					<div className='flex flex-col space-y-4'>
						<Text variant='display-6' weight='semibold' color='grayscale600'>
							{product.name}
						</Text>
						<Text
							onClick={() => handleMoreInfoClick(product.guid)}
							variant='body-xs'
							weight='medium'
							color='info300'
							className='cursor-pointer'>
							{t('common.moreInformation')}
						</Text>

						{activeInsuredAmount && (
							<motion.div
								key={`motion-${id}`}
								animate={{
									height: !isDetailsVisible(product.guid) ? 'auto' : '0px',
									opacity: !isDetailsVisible(product.guid) ? 1 : 0,
								}}>
								<Text variant='body-xs' color='grayscale300'>
									{t('components.pickOptionsCard.insuredAmountTo', {
										amount: transformToCurrency(activeInsuredAmount),
									})}
								</Text>
							</motion.div>
						)}
					</div>
				</div>
				<div className='ml-auto'>
					{!hasControls(product, bundles) && <Tag text={t('common.standard')} />}

					{hasControls(product, bundles) && (
						<motion.div
							className='flex items-center overflow-hidden'
							key={`motion-${id}`}
							animate={{
								height: isEditVisible(product.guid) ? '100%' : '0px',
								opacity: isEditVisible(product.guid) ? 1 : 0,
							}}>
							<TextButton
								size='xs'
								title={`${t('common.edit')} ${product.name}`}
								iconRight='pen'
								onClick={() => handleEditButtonClick(product.guid)}
								label={t('common.edit').toLowerCase()}
							/>
						</motion.div>
					)}
				</div>
			</div>

			<motion.div
				key={`motion-${id}`}
				className='flex flex-col space-y-24'
				animate={{
					height: isDetailsVisible(product.guid) ? 'auto' : '0px',
					opacity: isDetailsVisible(product.guid) ? 1 : 0,
					...(isDetailsVisible(product.guid)
						? {
								marginTop: '24px',
							}
						: {
								marginTop: '0px',
							}),
				}}>
				{form}
				<div className='flex flex-row items-center space-x-8'>
					{activeDeductible && (
						<div className='flex grow flex-row items-center space-x-4'>
							<Icon name='info-circle' color='grayscale400' />
							<Text variant='body-s' color='grayscale400'>
								<Trans
									i18nKey='components.pickOptionsCard.deductible'
									components={{
										Tag: <Tag className='!inline' text={transformToCurrency(activeDeductible)} />,
									}}
								/>
							</Text>
						</div>
					)}

					{hasControls(product, bundles) && <div className='!ml-auto'>{nextButton}</div>}
				</div>
			</motion.div>
		</BaseCard>
	);
};

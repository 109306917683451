import cn from 'classnames';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { CmsContainers, CmsContentTypes, INicheRecommendedInsuranceSection } from '@monorepo/types';

import { showDynamicModal, showModal } from '@common/store';
import { paths } from '@common/utils';
import { LargeSelectableProductCard } from '@funnel/components';

type NicheRecommendedInsuranceSectionProps = INicheRecommendedInsuranceSection & {
	className?: string;
};

export const NicheRecommendedInsuranceSection: FC<NicheRecommendedInsuranceSectionProps> = ({
	title,
	description,
	items,
	className,
}) => {
	const dispatch = useDispatch();

	const { push } = useRouter();

	const handleMoreInfoClick = (guid: string, container: CmsContainers) => {
		const type: CmsContentTypes = container === 'bundle_details' ? 'bundle' : 'product';

		dispatch(
			showDynamicModal({
				id: 'dynamic-content-modal',
				payload: {
					guid,
					type,
					container,
				},
			})
		);
	};

	const handleSelectProduct = async () => {
		await push(paths.funnel.company.search);
	};

	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-48'>
					<div className='col-start-2 flex flex-col space-y-12'>
						<Text as='h3' variant='display-4' weight='semibold' color='primaryMain'>
							{title}
						</Text>
						<Text as='p' variant='display-6' weight='regular' color='primaryMain'>
							{description}
						</Text>
					</div>
					<div className='grid grid-cols-2'>
						{items.map((item) => (
							<div className='col-span-1' key={`niche-recommended-insurance-selection-product-${item.guid}`}>
								<LargeSelectableProductCard
									price={item.minimum_price || 0}
									deductible={undefined}
									runningPromotion={null}
									onCtaClick={handleSelectProduct}
									onMoreInfoClick={handleMoreInfoClick}
									isActive={item.is_active && item.preconditions.every(({ answer }) => answer)}
									onEditPrecondition={() => dispatch(showModal(`precondition-modal-${item.guid}`))}
									name={item.name}
									icon={item.icon_name}
									isProductActive={item.is_active}
									guid={item.guid}
									render_type={item.render_type}
									preconditions={item.preconditions}
								/>
							</div>
						))}
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

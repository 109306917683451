import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, hydrate } from '@common/store';

export type ProgressState = {
	activeStep: number;
};

const initialState: ProgressState = {
	activeStep: 1,
};

export const progressSlice = createSlice({
	name: 'progress',
	initialState,
	reducers: {
		setProgressState(state, action: PayloadAction<number>) {
			state.activeStep = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(hydrate, (state, action: PayloadAction<{ progress: ProgressState }>) => {
			return {
				...state,
				...action.payload.progress,
			};
		});
	},
});

export const { setProgressState } = progressSlice.actions;

export const selectProgressState = (state: AppState): number => state.progress.activeStep;

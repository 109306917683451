import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, IBlurb } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { BlurbSection } from '@marketing/components';

export const handleMaiaInformation = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubTextElement = elements.find((element) => element.identifier === 'title-subtext') as CmsElement;
	const title = getProperty('title', titleSubTextElement?.custom_fields);
	const subtext = getProperty('subtext', titleSubTextElement?.custom_fields);
	const websiteRedirects = elements.filter((element) => element.identifier === 'website-redirect') as CmsElement[];

	const blurbs: IBlurb[] = websiteRedirects.map(({ custom_fields, links }) => {
		const title = getProperty('title', custom_fields);
		const subtext = getProperty('subtext', custom_fields);
		const icon = getProperty('icon_name', custom_fields);

		return { icon: `${icon}`, title: `${title}`, description: `${subtext}`, link: links[0] };
	});

	return () => (
		<BlurbSection className='pb-48 pt-24 md:pb-96 md:pt-0' title={`${title}`} subtitle={`${subtext}`} blurbs={blurbs} />
	);
};

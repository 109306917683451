import { faker } from '@faker-js/faker/locale/nl';

import type { ConvertedInsuranceDetails } from '@monorepo/types';

export function mockGetConvertedInsurance(): ConvertedInsuranceDetails {
	return {
		effective_date: faker.date.soon().toLocaleDateString(),
		premium_date: faker.date.soon().toLocaleDateString(),
		payment_method: 'Incasso',
		iban: faker.finance.iban(),
	};
}

import { IPhoneFeatureCarouselSection } from '@monorepo/types';

export const mockPhoneFeatureCarouselSection: IPhoneFeatureCarouselSection = {
	title: 'Schade?',
	subtitle: 'Samen lossen we het op.',
	features: [
		{
			selector: {
				icon: 'shield-question',
				title: 'Type claim vaststellen',
			},
			details: {
				title: 'Type claim vaststellen',
				description: 'Geen kleine lettertjes. Snel en simpel je bedrijf verzekered',
			},
			image: {
				path: '/images/marketing/why-choose-maia-1.svg',
				alt: 'alt-1',
			},
		},
		{
			selector: {
				icon: 'list-ui-alt',
				title: 'Claim details noteren',
			},
			details: {
				title: 'Claim details noteren',
				description: 'Geen kleine lettertjes. Snel en simpel je bedrijf verzekered',
			},
			image: {
				path: '/images/marketing/why-choose-maia-2.svg',
				alt: 'alt-2',
			},
		},
		{
			selector: {
				icon: 'eye',
				title: 'Laatste check',
			},
			details: {
				title: 'Laatste check',
				description: 'Geen kleine lettertjes. Snel en simpel je bedrijf verzekered',
			},
			image: {
				path: '/images/marketing/why-choose-maia-3.svg',
				alt: 'alt-3',
			},
		},
		{
			selector: {
				icon: 'shield-check',
				title: 'Claim is succesvol ingediend',
			},
			details: {
				title: 'Claim is succesvol ingediend',
				description: 'Geen kleine lettertjes. Snel en simpel je bedrijf verzekered',
			},
			image: {
				path: '/images/marketing/why-choose-maia-1.svg',
				alt: 'alt-4',
			},
		},
	],
};

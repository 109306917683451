import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Trans, useTranslation } from 'next-i18next';
import { FC } from 'react';

import { AcceptanceStateStatus } from '@monorepo/types';

import { ANALYTICS_ID, transformToCurrency } from '../../../../lib/utils';
import { StandardButton, TextButton } from '../../../DataEntry';
import { Text } from '../../../TextComponents';

type Props = {
	price: number;
	tax: number;
	acceptanceState: AcceptanceStateStatus;
	showPrice: boolean;
	showCta: boolean;
	showProceedLater: boolean;
	ctaValid: boolean;
	ctaLoading: boolean;
	handleCta: () => void;
	customFooterLabel?: string;
	customFooterIcon?: string;
	className?: string;
	handleProceedLater?: () => void;
};

export const ShoppingCartFooter: FC<Props> = ({
	price,
	tax,
	acceptanceState,
	showPrice,
	showCta,
	showProceedLater,
	handleCta,
	ctaValid,
	ctaLoading,
	customFooterLabel,
	customFooterIcon,
	handleProceedLater,
	className = '',
}) => {
	const { t } = useTranslation(['common']);

	return (
		<div className={cn(className, 'bg-grayscale0 z-20 flex flex-col space-y-16 px-32 md:space-y-24')}>
			<motion.div
				animate={{
					height: showPrice ? 'auto' : '0px',
					opacity: showPrice ? 1 : 0,
					...(!showPrice && {
						marginTop: 0,
					}),
				}}>
				<AnimatePresence>
					<div className='flex flex-row'>
						<div className='space-y-4'>
							<div className='flex flex-row'>
								<Text as='span' variant='body-s' weight='semibold'>
									{t('components.shoppingCartFooter.totalPerMonth')}
								</Text>
							</div>
							<Text as='span' variant='body-xs' color='grayscale400' className='block'>
								<Trans
									i18nKey='components.shoppingCartFooter.inclTax'
									tOptions={{
										tax: transformToCurrency(tax),
									}}
								/>
							</Text>
						</div>
						<div className='ml-auto flex items-center pl-8'>
							<Text as='span' className='ml-auto' color='primaryMain' variant='body-s' weight='semibold'>
								{transformToCurrency(price)}
							</Text>
						</div>
					</div>
				</AnimatePresence>
			</motion.div>

			<motion.div
				className='z-30 !mt-0'
				animate={{
					height: showCta ? 'auto' : '0px',
					opacity: showCta ? 1 : 0,
				}}>
				<AnimatePresence>
					<StandardButton
						name={ANALYTICS_ID.funnel_finish_compose}
						variant='cta'
						className='w-full'
						isLoading={ctaLoading}
						disabled={!ctaValid || ctaLoading}
						onClick={handleCta}
						iconRight={customFooterIcon ? customFooterIcon : undefined}
						label={t(
							customFooterLabel
								? customFooterLabel
								: acceptanceState === AcceptanceStateStatus.Accepted
									? 'common.ctaCloseOff'
									: 'common.ctaRequest'
						)}
						data-testid='shopping-cart-footer-main-cta'
					/>
				</AnimatePresence>
			</motion.div>

			<motion.div
				animate={{
					height: showProceedLater ? 'auto' : '0px',
					opacity: showProceedLater ? 1 : 0,
					...(!showProceedLater && {
						marginTop: 0,
					}),
				}}>
				<AnimatePresence>
					<TextButton
						className='w-full'
						iconLeft='envelope'
						onClick={handleProceedLater}
						label={t('components.shoppingCartFooter.buttonProceedLater')}
						name={t('components.shoppingCartFooter.buttonProceedLater')}
					/>
				</AnimatePresence>
			</motion.div>
		</div>
	);
};

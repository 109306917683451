import cn from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';

import { IconButton } from '../Forms';

type Angle = 'down' | 'up' | 'left' | 'right';

type ToggleExpandProps = {
	className?: string;
	initialAngle?: Angle;
	openAngle?: Angle;
	isOpen?: boolean;
	onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
};

export const ToggleExpand: FC<ToggleExpandProps> = ({
	className = '',
	initialAngle = 'down',
	openAngle = 'up',
	onClick,
	isOpen = false,
}: ToggleExpandProps) => {
	const buttonClassName = cn(className, 'bg-primary100 hover:enabled:bg-primary200 rounded-6');

	const angleName = `angle-${isOpen ? openAngle : initialAngle}`;

	return <IconButton onClick={onClick} className={buttonClassName} iconName={angleName} />;
};

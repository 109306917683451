import cn from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { IWeAreMaiaSection } from '@monorepo/types';

type WeAreMaiaSectionProps = IWeAreMaiaSection & {
	className?: string;
};

export const WeAreMaiaSection: FC<WeAreMaiaSectionProps> = ({ title, description, image, className }) => {
	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing' className='relative overflow-hidden'>
				<div className='col-start-2 flex flex-row items-center gap-48 sm:gap-64'>
					<div className='relative z-10 w-full py-48 md:py-96'>
						<div className='flex w-full flex-col space-y-24'>
							<Text variant='display-3' weight='semibold' color='primaryMain'>
								{title}
							</Text>

							<Text variant='body-l' weight='regular' color='primaryMain'>
								{description}
							</Text>
						</div>
					</div>
					<div className='relative z-0 flex w-full max-md:hidden'>
						<div className='h-[367px]'>
							<Image sizes={generateSizesString()} src={image.path} alt={image.alt} className='object-contain' fill />
						</div>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

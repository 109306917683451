import { ICtaSection } from '@monorepo/types';

export const mockCtaSectionSearchForm: ICtaSection = {
	title: 'Regel je verzekering in 5 minuten',
	subtext: 'Ondertitel hier schrijven lorem ipsum dolor sit.',
	cta: 'searchForm',
};

export const mockCtaSectionLink: ICtaSection = {
	title: 'Regel je verzekering in 5 minuten',
	subtext: 'Ondertitel hier schrijven lorem ipsum dolor sit.',
	cta: 'link',
	link: {
		content: 'Some content',
		href: '/',
		title: 'Some title',
		target: '_self',
	},
};

export const mockCtaSectionDoubleLink: ICtaSection = {
	title: 'Regel je verzekering in 5 minuten',
	subtext: 'Ondertitel hier schrijven lorem ipsum dolor sit.',
	cta: 'double-link',
	secondaryLink: {
		content: 'Some content',
		href: '/',
		title: 'Some title',
		target: '_self',
	},
	primaryLink: {
		content: 'Some content',
		href: '/',
		title: 'Some title',
		target: '_self',
	},
};

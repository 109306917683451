export const yesNo = ['J', 'N'] as const;
export type YesNo = (typeof yesNo)[number];

export const propertyOwnershipOptions = ['O', 'R', 'N'] as const;
export type PropertyOwnershipOptions = (typeof propertyOwnershipOptions)[number];

// TODO: Make dynamic
export type AffinityGroupOptions = (typeof affinityGroupsOptions)[number];
export const affinityGroupsOptions = ['nvm', 'vbo', 'vastgoedpro', 'onderhoudnl', 'anko', 'N'] as const;

export type InputSelectOption = {
	guid?: string;
	code: string | number;
	name: string | number;
};

export type ReactSelectOption = {
	value: string | number;
	label: string | number;
	selected?: boolean;
	icon?: string;
	image?: string;
};

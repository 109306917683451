import cn from 'classnames';
import { FC } from 'react';

import { Text } from '@monorepo/components/TextComponents';
import { IDetailHero } from '@monorepo/types';

export const DetailHeroTitle: FC<Pick<IDetailHero, 'title' | 'pretext' | 'subtext'> & { className?: string }> = ({
	title,
	pretext,
	subtext,
	className,
}) => {
	return (
		<div className={cn(className, 'flex flex-col')}>
			{pretext && (
				<Text as='p' variant='display-5' color='grayscale0'>
					{pretext}
				</Text>
			)}
			<Text as='h1' variant='display-3' weight='semibold' color='grayscale0' className='hyphens-auto'>
				{title}
			</Text>
			{subtext && (
				<Text as='p' variant='display-5' color='grayscale0' className='mt-12 md:mt-16'>
					{subtext}
				</Text>
			)}
		</div>
	);
};

import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { KvKCompany } from '@monorepo/types';

import { TextButton } from '../../../../DataEntry';
import { Text } from '../../../../TextComponents';
import { CompanySearchResultCard } from '../CompanySearchResultCard';

type ListProps = {
	companies: KvKCompany[];
	numResults: number;
	onResultClick: (dossierNumber: string) => void;
	isLoading?: boolean;
	hasMore: boolean;
	onClickMore: () => void;
	className?: string;
};

export const CompanySearchResultsList: FC<ListProps> = ({
	companies,
	numResults,
	onResultClick,
	isLoading = false,
	hasMore,
	onClickMore,
	className = '',
}) => {
	const { t } = useTranslation(['common']);

	return (
		<div className={cn(className)}>
			<Text className='mb-12' as='p' variant='body-s' color='grayscale400'>
				{t(numResults === 1 ? 'common.numResult' : 'common.numResults', { num: numResults })}
			</Text>
			<ul className='space-y-16'>
				{companies.map((company, index) => (
					<CompanySearchResultCard
						onClick={onResultClick}
						key={`company-search-result-${company.kvkNummer}-${index}`}
						company={company}
					/>
				))}
			</ul>
			{hasMore && (
				<div className='mt-24 flex items-center justify-center'>
					<TextButton
						title={t('components.companySearchResults.moreResults')}
						onClick={onClickMore}
						isLoading={isLoading}
						iconLeft='angle-down'
						label={t('components.companySearchResults.moreResults')}
					/>
				</div>
			)}
		</div>
	);
};

import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import { StandardButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';

export type FeatureSectionProps = {
	title: string;
	description: string;
	image: { source: string; alt: string };

	linkText: string;
	linkHref: string;
	isFlipped?: boolean;
	className?: string;
};

export const FeatureSection: FC<FeatureSectionProps> = ({
	title,
	description,
	image,
	className,
	linkText,
	linkHref,
	isFlipped = false,
}) => {
	return (
		<section className={classNames(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 grid grid-cols-2 gap-32 md:gap-80'>
					<div
						className={classNames(
							'rounded-16 relative order-2 col-span-full h-[320px] w-full overflow-hidden md:col-span-1 md:h-[640px]',
							isFlipped && 'md:order-1'
						)}>
						<Image
							sizes={generateSizesString()}
							src={image.source}
							alt={image.alt}
							className='object-cover object-center'
							fill
						/>
					</div>

					<div
						className={classNames(
							'order-1 col-span-full flex flex-col justify-center space-y-16 md:col-span-1',
							isFlipped && 'md:order-2'
						)}>
						<Text as='h2' variant='display-2' color='primaryMain' weight='semibold'>
							{title}
						</Text>
						<Text as='p' variant='display-6' color='grayscale400' className='max-md:hidden'>
							{description}
						</Text>
					</div>

					<div className='order-3 col-span-full md:hidden'>
						<Text as='p' variant='display-6' color='grayscale400'>
							{description}
						</Text>
					</div>

					<div className='order-4 col-span-full md:hidden'>
						<Link href={linkHref}>
							<StandardButton variant='cta' label={linkText} iconRight='angle-right' className='w-full' />
						</Link>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

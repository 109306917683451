import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';

export const dataRootApi = createApi({
	reducerPath: 'dataRootApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/data`,
	}),
	tagTypes: ['Insurer', 'Countries'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: () => ({}),
});

import { isDevelopment } from '@monorepo/shared/lib/utils';

type LogLevel = 'log' | 'info' | 'warn' | 'error' | 'debug' | 'trace';
type LogParams = unknown; // Or a more specific type, e.g., Record<string, unknown>

const SENSITIVE_KEYS = new Set(
	['hash', 'password', 'token', 'email', 'bearer', 'firstname', 'lastname', 'name', 'apikey'].map((key) =>
		key.toLowerCase()
	)
);

function redactSensitiveDataFromStrings(value: string): string {
	const urlPattern = /([\?&])([^=]+)=([^&]+)/g; // Pattern to match query parameters
	return value.replace(urlPattern, (match, prefix, key) => {
		if (SENSITIVE_KEYS.has(key.toLowerCase())) {
			return `${prefix}${key}=***REDACTED***`; // Redact sensitive query parameters
		}
		return match; // Return the original match if not sensitive
	});
}

function redactSensitiveData(obj: unknown): unknown {
	if (typeof obj === 'string') {
		return redactSensitiveDataFromStrings(obj); // Handle strings directly for redaction
	} else if (typeof obj !== 'object' || obj === null) {
		return obj; // Non-objects and null values are returned as-is
	} else if (Array.isArray(obj)) {
		return obj.map(redactSensitiveData); // Recursively process array items
	} else {
		// Recursively process object keys for case-insensitive comparison
		return Object.keys(obj).reduce(
			(acc, key) => {
				const value = (obj as Record<string, unknown>)[key];
				if (SENSITIVE_KEYS.has(key.toLowerCase())) {
					acc[key] = '***REDACTED***';
				} else {
					acc[key] = redactSensitiveData(value);
				}
				return acc;
			},
			{} as Record<string, unknown>
		);
	}
}

function createLogFunction(appName: string, level: LogLevel) {
	return (message: string, params?: LogParams) => {
		const redactedMessage = redactSensitiveDataFromStrings(message);
		const redactedParams = params ? redactSensitiveData(params) : params;
		const prefix = `[${appName}]${isDevelopment ? ' [' + level.toUpperCase() + ']' : ''} -`;
		console[level](prefix, redactedMessage, redactedParams ?? '');
	};
}

export function createLogger(appName: string) {
	const levels: LogLevel[] = ['log', 'info', 'warn', 'error', 'debug', 'trace'];
	return levels.reduce(
		(acc, level) => {
			acc[level] = createLogFunction(appName, level);
			return acc;
		},
		{} as { [K in LogLevel]: (message: string, params?: LogParams) => void }
	);
}

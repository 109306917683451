import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CancelCurrentInsuranceSchema } from '@monorepo/shared/lib/schemas';

import { hideModal, showModal } from '@common/store';
import {
	useCreateCurrentInsuranceMutation,
	useDeleteCurrentInsuranceMutation,
	useGetCurrentInsurancesQuery,
	useGetTransferableProductsQuery,
	useUpdateCurrentInsuranceMutation,
} from '@funnel/store';

export const useCancelInsurance = (cart_guid: string) => {
	const [selectedInsurance, setSelectedInsurance] = useState<CancelCurrentInsuranceSchema>();

	const dispatch = useDispatch();

	const [saveCurrentInsurances, saveCurrentInsurancesResponse] = useCreateCurrentInsuranceMutation();
	const [deleteInsurance, saveDeleteInsuranceResponse] = useDeleteCurrentInsuranceMutation();
	const [updateInsurance, saveUpdateInsuranceResponse] = useUpdateCurrentInsuranceMutation();

	const { data: insurances = [] } = useGetCurrentInsurancesQuery(cart_guid);
	const { data: transferableProducts = [] } = useGetTransferableProductsQuery(cart_guid);

	const isLoading =
		saveCurrentInsurancesResponse.isLoading ||
		saveDeleteInsuranceResponse.isLoading ||
		saveUpdateInsuranceResponse.isLoading;

	/**
	 * Open the modal to add a new insurance
	 */
	const handleAdd = () => {
		setSelectedInsurance(undefined);
		dispatch(showModal('add-or-edit-insurance-modal'));
	};

	/**
	 * Open the edit modal that is prefilled with the clicked insurance fields
	 */
	const handleEdit = (insurance: CancelCurrentInsuranceSchema) => {
		setSelectedInsurance(insurance);
		dispatch(showModal('add-or-edit-insurance-modal'));
	};

	/**
	 * Cancels the changes made to an insurance
	 */
	const handleCancelEdit = () => {
		setSelectedInsurance(undefined);
		dispatch(hideModal('add-or-edit-insurance-modal'));
	};

	/**
	 * Open the prompt to delete the insurance
	 */
	const handleDeleteRequest = (insurance: CancelCurrentInsuranceSchema) => {
		setSelectedInsurance(insurance);
		dispatch(showModal('delete-insurance-prompt'));
	};

	/**
	 * Close the prompt to delete the insurance
	 */
	const handleCancelDelete = () => {
		setSelectedInsurance(undefined);
		dispatch(hideModal('delete-insurance-prompt'));
	};

	/**
	 * Deletes an insurance
	 */
	const handleDelete = async () => {
		await deleteInsurance({ cart_guid, guid: `${selectedInsurance?.guid}` }).unwrap();
		dispatch(hideModal('delete-insurance-prompt'));
	};

	/**
	 * Saves a new insurance or updates an existing insurance
	 */
	const handleSubmit = async (insurance: CancelCurrentInsuranceSchema) => {
		if (insurance.guid) {
			await updateInsurance({
				cart_guid,
				guid: insurance.guid,
				product_guid: `${
					transferableProducts.find((transferableProduct) => transferableProduct.guid === insurance.product_guid)?.guid
				}`,
				insurer_code: insurance.insurer_code,
				policy_identifier: insurance.policy_identifier,
			}).unwrap();
		} else {
			await saveCurrentInsurances({
				cart_guid,
				insurances: [
					{
						product_guid: insurance.product_guid,
						insurer_code: insurance.insurer_code,
						policy_identifier: insurance.policy_identifier,
					},
				],
			}).unwrap();
		}
		dispatch(hideModal('add-or-edit-insurance-modal'));
	};

	return {
		insurances,
		isLoading,
		selectedInsurance,
		handleAdd,
		handleEdit,
		handleCancelEdit,
		handleDelete,
		handleCancelDelete,
		handleDeleteRequest,
		handleSubmit,
	};
};

import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import { TextButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { CmsLink, CmsMedia } from '@monorepo/types';

export type BlurbItem = {
	image: CmsMedia;
	title: string;
	description: string;
	link: CmsLink;
};

type CenteredBlurbSectionProps = {
	title: string;
	description: string;
	blurbs: BlurbItem[];
	className?: string;
};

export const CenteredBlurbSection: FC<CenteredBlurbSectionProps> = ({ title, description, blurbs, className }) => {
	return (
		<section className={cn(className, 'md:py-104 bg-primary50 py-48')}>
			<LayoutGrid as='div' layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-48'>
					<div className='flex flex-col items-center space-y-16'>
						<Text variant='display-4' weight='semibold' color='primaryMain'>
							{title}
						</Text>
						<Text variant='body-l' weight='regular' color='grayscale300'>
							{description}
						</Text>
					</div>
					<div className='grid grid-cols-3 gap-48'>
						{blurbs.map(({ image, title, description, link }, key) => (
							<Link
								href={link.href}
								title={link.content || link.title}
								key={`centered-blurb-${key}`}
								className='bg-grayscale0 rounded-16 col-span-full flex flex-col p-24 md:col-span-1'>
								<div className='flex flex-col space-y-24 md:h-full'>
									<div className='flex flex-col space-y-12 md:flex-1'>
										<div className='relative h-80 w-full'>
											<Image
												sizes={generateSizesString()}
												src={`${image.path}`}
												alt={image.alt}
												fill
												className='object-contain object-left'
											/>
										</div>
										<Text variant='display-6' weight='semibold' color='primaryMain'>
											{title}
										</Text>
										<Text variant='body-s' weight='regular' color='grayscale400'>
											{description}
										</Text>
									</div>

									<TextButton className='w-fit' label={link.content || link.title} iconRight='arrow-right' />
								</div>
							</Link>
						))}
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

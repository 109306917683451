import cn from 'classnames';
import { type FC, type ReactNode } from 'react';

type ButtonGroupProps = {
	className?: string;
	sticky?: boolean;
	alwaysSticky?: boolean;
	children: ReactNode;
};

export const ButtonGroup: FC<ButtonGroupProps> = ({ className, sticky = false, alwaysSticky = false, children }) => {
	return (
		<div
			role='group'
			className={cn(
				className,
				!className?.includes('justify-') && 'justify-evenly sm:justify-end',
				(sticky || alwaysSticky) && 'bg-gradient-to-b from-transparent to-white sm:to-white/90',
				'flex gap-x-12 pt-10 sm:space-x-6 max-sm:[&>*]:w-full',
				sticky && !alwaysSticky && 'relative sm:sticky sm:bottom-0 sm:pb-8',
				alwaysSticky && 'sticky bottom-0 pb-8'
			)}>
			{children}
		</div>
	);
};

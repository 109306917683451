import Link from 'next/link';
import { FC } from 'react';

import { BaseCard } from '@monorepo/components/DataDisplay';
import { TextButton } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { ISupportFaqCard } from '@monorepo/types';

type SupportFaqCardProps = ISupportFaqCard;

export const SupportFaqCard: FC<SupportFaqCardProps> = ({ title, description, link }) => {
	return (
		<BaseCard className='h-full'>
			<Link
				href={link.href}
				target={link.target}
				title={link.title}
				className='group flex flex-col space-y-12 px-32 py-24 md:px-56'>
				<div className='flex flex-1 flex-col space-y-12'>
					<Text variant='display-5' weight='semibold' color='grayscale600'>
						{title}
					</Text>

					<Text variant='body-l' weight='regular' color='grayscale400' className='line-clamp-3'>
						{description}
					</Text>
				</div>

				<Link href={link.href} title={link.title} className='-mb-8 -ml-8'>
					<TextButton label={link.title} className='underline' />
				</Link>
			</Link>
		</BaseCard>
	);
};

import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';

import { animationKeyMap, pageTransitions } from '@monorepo/shared/lib/utils';

type LayoutProps = {
	className?: string;
	children: ReactNode;
};

export const Transition: FC<LayoutProps> = ({ className, children }) => {
	const { asPath } = useRouter();

	return (
		<motion.main
			key={asPath}
			className={className}
			{...animationKeyMap}
			variants={pageTransitions}
			transition={{ type: 'spring' }}>
			{children}
		</motion.main>
	);
};

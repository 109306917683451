import { FC } from 'react';

import { mockSchoutenSection } from '@monorepo/mocks/marketing';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { SchoutenSection } from '@marketing/components';

export const handleSectionImagesNavigationText = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	// All elements
	const imagesEle = elements.find(({ identifier }) => identifier === 'images') as CmsElement,
		imagesTitleSubtextEle = imagesEle.child_elements.find(
			({ identifier }) => identifier === 'title-subtext'
		) as CmsElement,
		titleSubtextEle: CmsElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement,
		listsChecklistEle: CmsElement = elements.find(({ identifier }) => identifier === 'lists_checklist') as CmsElement;
	// const imageEle: CmsElement = elements.find(({ identifier }) => identifier === 'image') as CmsElement;

	// Component title
	const title = getProperty('title', imagesTitleSubtextEle.custom_fields),
		// Card title and description
		subTitle = getProperty('title', titleSubtextEle.custom_fields),
		description = getProperty('subtext', titleSubtextEle.custom_fields);

	// Bulletpoints
	const bulletList: string[] = listsChecklistEle.child_elements.map(({ markdown }) => `${markdown}`);

	// Schouten Logo
	const logo = mockSchoutenSection.logo;

	// Images
	const images = imagesEle.media;

	return (props) => (
		<SchoutenSection
			{...props}
			title={`${title}`}
			images={images}
			subtitle={`${subTitle}`}
			description={`${description}`}
			bulletList={bulletList}
			logo={logo}
		/>
	);
};

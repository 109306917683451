import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useClickAway } from 'react-use';

import { Form } from '@monorepo/components/DataEntry';
import { CheckList, Text } from '@monorepo/components/TextComponents';
import { ClassificationsSchema } from '@monorepo/shared/lib/schemas';
import { GroupedClassifications, OrganizationClassification } from '@monorepo/types';

import { Modal } from '@common/components';
import { showModal } from '@common/store';

type ActivitiesFormProps = {
	groupedClassifications: GroupedClassifications;
	onSubmit: ({ classifications }: ClassificationsSchema) => void;
	searchQuery?: string;
	isStoppedTyping?: boolean;
	handleToggleActivity: (guid: string) => void;
	handleRemoveActivity: (guid: string) => void;
	chosenActivityGuids?: { guid: string }[];
	autocompleteItems?: OrganizationClassification[];
};

export const ActivitiesForm: FC<ActivitiesFormProps> = ({
	groupedClassifications,
	onSubmit,
	searchQuery,
	isStoppedTyping,
	handleToggleActivity,
	handleRemoveActivity,
	chosenActivityGuids,
	autocompleteItems,
}) => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();
	const searchContainerRef = useRef(null);
	const [isFocusSearch, setIsFocusSearch] = useState<boolean>(false);

	const MINIMUM_BEFORE_SEARCH = 10;

	const showSearch = useMemo(
		() =>
			Object.entries(groupedClassifications)
				.filter(([name]) => name !== 'suggested')
				?.flatMap(([_, { classifications }]) => classifications).length > MINIMUM_BEFORE_SEARCH,
		[groupedClassifications]
	);

	const pickedActivitiesFromSearch: OrganizationClassification[] = useMemo(
		() =>
			chosenActivityGuids
				? chosenActivityGuids
						.map((autocompleteGuid) => {
							const matchingActivity = Object.entries(groupedClassifications)
								.filter(([name]) => name !== 'suggested')
								?.flatMap(([_, { classifications }]) => classifications)
								.find(({ guid }) => guid === autocompleteGuid.guid);
							return matchingActivity!;
						})
						.filter((guid): guid is OrganizationClassification => !!guid)
				: [],
		[chosenActivityGuids, groupedClassifications]
	);

	const openTooltip = (id: string) => {
		dispatch(showModal(id));
	};

	useClickAway(searchContainerRef, () => {
		if (isFocusSearch) {
			setIsFocusSearch(false);
		}
	});

	return (
		<Form.Context<ClassificationsSchema>>
			{({ register, handleSubmit }) => (
				<Form id='activities-form' onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-y-32'>
					<Form.Group
						className='flex flex-col gap-y-32'
						isRendered={
							Object.entries(groupedClassifications).filter(([name]) => (showSearch ? name === 'suggested' : true))
								.length > 0
						}>
						{Object.entries(groupedClassifications)
							.filter(([name]) => (showSearch ? name === 'suggested' : true))
							.map(([key, { name, classifications }]) => (
								<div key={`activity-${key}`} className='flex flex-col gap-y-16'>
									<Text as='h2' variant='body-l' weight='medium'>
										{t(name)}
									</Text>

									<Form.CheckboxList elevation={key === 'suggested' ? 'flat' : 'elevated'}>
										{classifications?.map(({ guid, name, content }) => (
											<div key={guid} className='flex flex-row py-4' role='list-item'>
												<Form.Checkbox
													id={guid}
													name='classifications'
													label={name}
													checked={chosenActivityGuids?.some(({ guid: _guid }) => _guid === guid)}
													onChange={() => handleToggleActivity(guid)}
													endIcon={content && content.length > 0 ? 'info-circle' : ''}
													onEndIconClick={() => openTooltip(guid)}
												/>

												{content && content.length > 0 && (
													<Modal id={guid} title={name}>
														<CheckList items={content} />
													</Modal>
												)}
											</div>
										))}
									</Form.CheckboxList>
								</div>
							))}
					</Form.Group>
					<Form.Group isRendered={showSearch} className='flex w-full flex-col gap-y-16'>
						<Text as='h2' variant='body-l' weight='medium'>
							Voeg activiteiten toe
						</Text>
						{pickedActivitiesFromSearch && pickedActivitiesFromSearch.length > 0 && (
							<Form.CheckboxList elevation='flat'>
								{pickedActivitiesFromSearch?.map(({ guid, content, name }) => (
									<div className='flex flex-row py-4' key={`chosen-activity-${guid}`} role='list-item'>
										<Form.Checkbox
											id={`classification-picked-${guid}`}
											name='classifications'
											label={name}
											onChange={() => handleRemoveActivity(guid)}
											defaultChecked={true}
											endIcon={content && content.length > 0 ? 'info-circle' : ''}
											onEndIconClick={() => openTooltip(guid)}
										/>

										{content && content.length > 0 && (
											<Modal id={guid} title={name}>
												<CheckList items={content} />
											</Modal>
										)}
									</div>
								))}
							</Form.CheckboxList>
						)}
						<div className='relative w-full md:basis-2/3' ref={searchContainerRef}>
							<Form.SearchInput
								onFocus={() => setIsFocusSearch(true)}
								className={classNames(isFocusSearch && isStoppedTyping && 'rounded-b-none border-b-0', 'h-48')}
								name='search'
								startIcon='search'
								autoComplete='off'
								placeholder={t('page.funnel.profile.activities.placeholder')}
								register={register}
							/>

							{autocompleteItems && searchQuery && isFocusSearch && (
								<>
									{autocompleteItems.length > 0 ? (
										<div className='border-primaryMain bg-grayscale0 rounded-b-6 absolute z-50 max-h-[250px] w-full overflow-hidden overflow-y-auto border'>
											{autocompleteItems.map(({ guid, name }) => {
												return (
													<div
														key={`search-activites-autocomplete-${guid}`}
														className='hover:bg-primary50 flex flex-row px-12 py-12'
														role='list-item'>
														<Form.Checkbox
															id={`classification-${guid}`}
															name='classifications'
															label={name}
															onChange={() => handleToggleActivity(guid)}
															checked={chosenActivityGuids?.some(({ guid: _guid }) => _guid === guid)}
															value={guid}
														/>
													</div>
												);
											})}
										</div>
									) : searchQuery && isStoppedTyping ? (
										<div className='border-primaryMain rounded-b-6 max-h-[250px] w-full overflow-hidden overflow-y-auto border'>
											<div className='px-12 py-12'>
												<Text variant='body-m' weight='regular' color='grayscale300'>
													{t('page.funnel.profile.activities.empty')}
												</Text>
											</div>
										</div>
									) : null}
								</>
							)}
						</div>
					</Form.Group>
				</Form>
			)}
		</Form.Context>
	);
};

import classNames from 'classnames';
import cn from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { generateSizesString, getFileIconSourceFromMimeType } from '../../../../lib/utils';
import { ActionButton } from '../../../DataEntry';
import { Skeleton } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { BaseCard } from '../BaseCard/BaseCard';

type FileUploadedCardProps = (
	| {
			variant: 'tile';
			onDelete: () => void;
	  }
	| {
			variant: 'row';
			name: string;
			onDelete?: () => void;
	  }
) & {
	enableEffects?: boolean;
	mimeType: string;
	isLoading?: boolean;
	className?: string;
	onClick?: () => void;
};

export const FileUploadedCard: FC<FileUploadedCardProps> = ({
	mimeType,
	onClick,
	className,
	isLoading = false,
	enableEffects = true,
	...props
}) => {
	return (
		<BaseCard
			isElevated={props.variant === 'tile'}
			as='div'
			title={`File download`}
			enableEffects={enableEffects}
			onClick={typeof onClick === 'function' ? onClick : () => {}}
			className={cn(
				'bg-grayscale0 rounded-6 flex items-center gap-x-12 p-12',
				props.variant === 'tile' && 'hover:shadow-3 w-fit',
				typeof onClick === 'function' && 'cursor-pointer',
				className
			)}>
			<>
				<div className='flex flex-1 items-center space-x-12 break-all'>
					<Skeleton
						isLoading={isLoading}
						variant='rounded'
						className={classNames(props.variant === 'row' && 'h-20 w-20', props.variant === 'tile' && 'h-40 w-40')}
						containerClassName={classNames(
							'relative',
							props.variant === 'row' && 'h-20 w-20',
							props.variant === 'tile' && 'h-40 w-40 transition-transform group-hover:scale-105 group-focus:scale-105'
						)}>
						<Image
							src={`/images/file-types/${getFileIconSourceFromMimeType(mimeType)}`}
							alt='Uploaded document'
							className='object-contain'
							sizes={generateSizesString()}
							fill
						/>
					</Skeleton>
					{props.variant === 'row' && (
						<Skeleton isLoading={isLoading} variant='text' size='body-xs' className='w-[200px]'>
							<Text variant='body-xs' weight='regular' color='grayscale300' className='text-ellipsis'>
								{props.name}
							</Text>
						</Skeleton>
					)}
				</div>
				{((props.variant === 'row' && props.onDelete) || props.variant === 'tile') && !isLoading && (
					<ActionButton
						type='button'
						icon='times'
						onClick={props.onDelete}
						size={props.variant === 'tile' ? 'small' : 'large'}
						className={classNames(props.variant === 'tile' && 'absolute right-4 top-4')}
					/>
				)}
			</>
		</BaseCard>
	);
};

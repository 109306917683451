import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'react-use';

import { StaticNotification } from '@monorepo/components/DataDisplay';
import { StandardButton } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import {
	depositPerMonthRange,
	oneTimeDepositRange,
	pensionPlanSchema,
	PensionPlanSchema,
} from '@monorepo/shared/lib/schemas';
import { CalculationPrecondition } from '@monorepo/types';

import { closeQuote } from '@funnel/store';
import { useCalculatePensionForecastMutation, useCalculatePensionMutation } from '@funnel/store/slices/vive';
import { mapResponseToChartData } from '@funnel/utils';
import { PensionExpectedResultsBox } from '../PensionExpectedResultsBox/PensionExpectedResultsBox';
import { PensionGraph, PensionGraphData } from '../PensionGraph/PensionGraph';
import { PensionSpecificationsBox } from '../PensionSpecificationsBox/PensionSpecificationsBox';

type PensionFormProps = {
	preconditions: Array<CalculationPrecondition>;
	productGuid: string;
	isLoading?: boolean;
	onSubmit: (fields: PensionPlanSchema) => void;
};

export const PensionForm: FC<PensionFormProps> = ({ preconditions, productGuid, isLoading = false, onSubmit }) => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();
	const [cachedPensionDetails, setCachedPensionDetails] = useState<PensionPlanSchema>();
	const [cachedGraphData, setCachedGraphData] = useState<Array<PensionGraphData>>([]);
	const form = useForm<PensionPlanSchema>({
		mode: 'onBlur',
		resolver: zodResolver(pensionPlanSchema(t)),
		defaultValues: {
			age: 18,
			retirementAge: 65,
			oneTimeDeposit: 0,
			depositPerMonth: 50,
		},
	});

	const [calculatePension, calculatePensionResponse] = useCalculatePensionMutation();
	const [calculatePensionForecast, calculatePensionForecastResponse] = useCalculatePensionForecastMutation();

	const fields = form.watch();

	const handlePensionChange = useCallback(
		async ({ age, depositPerMonth, oneTimeDeposit, retirementAge }: PensionPlanSchema) => {
			const payload = {
				annuityDuration: 20,
				currentAge: age,
				monthlyDeposit: depositPerMonth,
				oneoffDeposit: oneTimeDeposit,
				pensionAge: retirementAge,
				maxYearlyvar: 0.2,
			};

			await calculatePension(payload).unwrap();

			const graphData = await calculatePensionForecast(payload).unwrap();
			setCachedGraphData(mapResponseToChartData(graphData ?? []));
		},
		[calculatePension, calculatePensionForecast]
	);

	const [_] = useDebounce(
		() => {
			const isAtleastOneValueChanged = Object.entries(fields).some(([key, value]) => {
				if (typeof cachedPensionDetails === 'undefined') return true;

				return (
					Object.entries(cachedPensionDetails).find((cachedPensionDetail) => cachedPensionDetail[0] === key)?.[1] !==
					value
				);
			});

			if (pensionPlanSchema(t).safeParse(fields).success && isAtleastOneValueChanged) {
				setCachedPensionDetails(fields);
				handlePensionChange(fields);
			}
		},
		450,
		[fields]
	);

	useEffect(() => {
		if (preconditions.length > 0) {
			for (const { reference, answer } of preconditions) {
				if (typeof answer !== 'undefined' && answer !== null) {
					if (reference === 'age') form.setValue('age', parseInt(answer), { shouldValidate: true });
					if (reference === 'retirement-age')
						form.setValue('retirementAge', parseInt(answer), { shouldValidate: true });
					if (reference === 'one-time-deposit')
						form.setValue('oneTimeDeposit', parseInt(answer), {
							shouldValidate: true,
						});
					if (reference === 'monthly-deposit')
						form.setValue('depositPerMonth', parseInt(answer), {
							shouldValidate: true,
						});
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preconditions]);

	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<FormProvider {...form}>
				<div className='grid grid-cols-2 gap-24'>
					<div className='col-span-full sm:col-span-1'>
						<PensionSpecificationsBox
							oneTimeDepositRange={oneTimeDepositRange}
							depositPerMonthRange={depositPerMonthRange}
						/>
					</div>
					<div className='col-span-full sm:col-span-1'>
						<PensionExpectedResultsBox
							expectedResult={calculatePensionResponse.data?.expectedValueOnTargetDate ?? 0}
							totalWorth={calculatePensionResponse.data?.monthlyAnnuity ?? 0}
							retirementDate={calculatePensionResponse.data?.targetDate ?? ''}
							totalInlay={calculatePensionResponse.data?.cumulativeDeposits ?? 0}
						/>
					</div>
					<div className='col-span-full'>
						<PensionGraph
							data={cachedGraphData}
							isLoading={calculatePensionResponse.isLoading || calculatePensionForecastResponse.isLoading}
						/>
					</div>
					<div className='col-span-full'>
						<StaticNotification status='info'>
							<Text variant='body-s' color='grayscale500' weight='regular'>
								<Trans
									i18nKey={'page.funnel.compose.products.pensioenplan.informationField'}
									components={{
										bold: <span className='inline-block font-semibold' />,
									}}
								/>
							</Text>
						</StaticNotification>
					</div>
				</div>

				<div className='mt-24 flex w-full flex-col items-center justify-between gap-12 sm:flex-row'>
					<StandardButton
						type='button'
						variant='secondary'
						label={t('common.cancel')}
						className='order-2 w-full sm:order-1 sm:w-fit'
						disabled={isLoading || calculatePensionResponse.isLoading || calculatePensionForecastResponse.isLoading}
						onClick={() => dispatch(closeQuote(productGuid))}
					/>

					<StandardButton
						type='submit'
						variant='cta'
						iconRight='angle-right'
						label={t('common.requestWithoutObligation')}
						className='order-1 w-full sm:order-2 sm:w-fit'
						isLoading={isLoading}
						disabled={
							isLoading ||
							!form.formState.isValid ||
							calculatePensionResponse.isLoading ||
							calculatePensionForecastResponse.isLoading
						}
						data-testid='confirm-quote-Pensioenplan via Vive'
					/>
				</div>
			</FormProvider>
		</form>
	);
};

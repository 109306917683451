import cn from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { ThemeColors, ThemeTypographyWeight } from '@monorepo/types';

import { Icon } from '../../../Misc';
import { Text } from '../../TextComponents';
import { List } from '../List/List';

type IconItem = {
	title?: ReactNode | string;
	icon: string;
	subtext?: ReactNode | string;
	titleColor?: ThemeColors;
	titleWeight?: ThemeTypographyWeight;
};

type IconItemProps = {
	item: IconItem;
	isListItem?: boolean;
	className?: string;
};

export const IconItem: FC<IconItemProps> = ({ item, isListItem = true, className = '', ...rest }) => {
	return (
		<Text
			as={isListItem ? 'li' : 'div'}
			variant='body-m'
			className={cn(className, 'flex flex-row items-start leading-none')}
			color='grayscale500'
			{...rest}>
			<Icon className={cn('mr-12 self-start')} size='lg' name={item.icon} color='primaryMain' />
			<div className='flex flex-col space-y-4'>
				{item.title && (
					<Text
						as='span'
						variant='body-m'
						color={item.titleColor || 'grayscale500'}
						weight={item.titleWeight || 'medium'}>
						{item.title}
					</Text>
				)}
				{item.subtext && (
					<Text as='span' variant='body-s' color='grayscale400'>
						{item.subtext}
					</Text>
				)}
			</div>
		</Text>
	);
};

type IconListProps = {
	items?: IconItem[];
	className?: string;
};

export const IconList: FC<PropsWithChildren<IconListProps>> = ({ items, className = '', children }) => {
	return (
		<List className={className}>
			{items ? items.map((item, index) => <IconItem key={`icon-item-${index}`} item={item} />) : children}
		</List>
	);
};

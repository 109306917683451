import Image from 'next/image';
import { FC } from 'react';

import { Icon } from '@monorepo/components/Misc';
import { EmphasizedPriceFormat } from '@monorepo/components/TextComponents/TextComponents/EmphasizedPriceFormat/EmphasizedPriceFormat';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { ComparisonTable } from '../../../../marketing/components/ComparisonTable/ComparisonTable';

export const handleSectionComparisonTable = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubTextElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const linkElement: CmsElement = elements.find(({ identifier }) => identifier === 'links') as CmsElement;
	const moreInfoElement = elements.find(({ identifier }) => identifier === 'more-info-modal') as CmsElement;

	const title = getProperty('title', titleSubTextElement.custom_fields);
	const subtext = getProperty('subtext', titleSubTextElement.custom_fields);
	const [link] = linkElement.links;
	const moreInfoLabel = getProperty('label', moreInfoElement.custom_fields);

	const data = [
		[
			<></>,
			<Image
				alt='Maia'
				src='/images/logo/maia/logo.svg'
				width={112}
				height={48}
				style={{ height: 48 }}
				key='logo-maia'
			/>,
			<Image
				alt='KNMV'
				src='/images/marketing/logos/knmv.svg'
				width={112}
				height={48}
				style={{ height: 48 }}
				key='logo-knmv'
			/>,
			<Image
				alt='ANWB'
				src='/images/marketing/logos/anwb.svg'
				width={112}
				height={48}
				style={{ height: 48 }}
				key='logo-anwb'
			/>,
			<Image
				alt='Combimotors'
				src='/images/marketing/logos/combimotors.svg'
				width={112}
				height={48}
				style={{ height: 48 }}
				key='logo-combimotors'
			/>,
		],
		[
			'Prijs',
			<EmphasizedPriceFormat price={7.86} afterText='p.m' key='price-maia' />,
			<EmphasizedPriceFormat price={11.93} afterText='p.m' key='price-nmv' />,
			<EmphasizedPriceFormat price={25.47} afterText='p.m' key='price-anwb' />,
			<EmphasizedPriceFormat price={23.69} afterText='p.m' key='price-combimotors' />,
		],
		['Eigen risico', '€ 0,-', '€ 150,-', '€ 150,-', '€ 150,-'],
		['Helm/kleding/schoenen', 'Tot € 1500,-', 'Tot € 750,-', 'Tot € 1000,-', 'Maximaal € 750,-'],
		[
			'Accesoiers',
			'Tot € 5000,-',
			'Tot € 5000,-',
			<Icon name='times' color='error300' size='xl' key='times-icon' />,
			'Tot € 500,-',
		],
		[
			'Internationale dekking',
			<Icon name='check' color='success300' size='xl' key='check-maia' />,
			<Icon name='check' color='success300' size='xl' key='check-nmv' />,
			<Icon name='check' color='success300' size='xl' key='check-anwb' />,
			<Icon name='check' color='success300' size='xl' key='check-combimotors' />,
		],
		['Dekking periode', '365 dagen', 'Winterstop melden', 'Winterstop melden', 'Winterstop melden'],
	];

	return () => (
		<ComparisonTable
			data={data}
			title={title ?? ''}
			subTitle={subtext ?? ''}
			link={link}
			moreInfo={{ label: moreInfoLabel ?? '' }}
		/>
	);
};

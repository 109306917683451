import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, hydrate } from '@common/store';

export type NavigationState = {
	isNavigating: boolean;
	navDirection: 'back' | 'forward' | null; // New field
};

const initialState: NavigationState = {
	isNavigating: false,
	navDirection: null,
};

export const navigationSlice = createSlice({
	name: 'navigation',
	initialState,
	reducers: {
		setNavForward(state) {
			state.isNavigating = true;
			state.navDirection = 'forward';
		},
		setNavBack(state) {
			state.isNavigating = true;
			state.navDirection = 'back';
		},
		setNavigating(state, action: PayloadAction<boolean>) {
			state.isNavigating = action.payload;
		},
		setNavigationDirection(state, action: PayloadAction<'back' | 'forward' | null>) {
			state.navDirection = action.payload;
		},
		resetNavigationState() {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(hydrate, (state, action: PayloadAction<{ navigation: NavigationState }>) => {
			return {
				...state,
				...action.payload.navigation,
			};
		});
	},
});

export const { setNavForward, setNavBack, setNavigating, setNavigationDirection, resetNavigationState } =
	navigationSlice.actions; // Include new action

export const selectNavigationState = (state: AppState) => state.navigation;

import { rootApi } from '../../../../common/store/root/root.api';

const newsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getDetailedCauses: builder.query<{ guid: string; description: string }[], { insuranceGuid: string }>({
			query({ insuranceGuid }) {
				return {
					url: `/detailed-causes?insurance_guid=${insuranceGuid}`,
					method: 'GET',
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetDetailedCausesQuery, useLazyGetDetailedCausesQuery } = newsApi;

export const { getDetailedCauses } = newsApi.endpoints;

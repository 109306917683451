import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { NavItem } from '@monorepo/types';

import { MarketingCtaButton } from '../../../../DataEntry';
import { LayoutGrid } from '../../../../Misc';
import { NavbarMenuItem } from '../NavBarMenuItem/NavbarMenuItem';
import { NavbarMenuLink } from '../NavbarMenuLink/NavbarMenuLink';

type NavBarSideMenuProps = {
	items: NavItem[];
	isMobileMenuOpen?: boolean;
	isB2C?: boolean;
};

export const NavBarMobileMenu: FC<NavBarSideMenuProps> = ({ items, isB2C, isMobileMenuOpen = false }) => {
	const { t } = useTranslation(['common']);
	return (
		<AnimatePresence>
			{isMobileMenuOpen && (
				<motion.div
					data-testid='nav_bar_side_menu'
					className='bg-grayscale0 relative z-50 overflow-hidden'
					initial={{ opacity: 0, height: 0 }}
					animate={{ opacity: 100, height: '100vh' }}
					exit={{ height: 0 }}
					transition={{ duration: 0.3 }}>
					<LayoutGrid>
						<div className='col-start-2 flex w-full flex-col gap-24 py-24'>
							{items.map((item, index) => {
								return (
									<div key={`mobile-sidenav-item-${index}`}>
										{item.type === 'menuItem' && <NavbarMenuItem item={item} inMobileNav={true} />}
										{item.type === 'link' && <NavbarMenuLink item={item} inMobileNav={true} />}
									</div>
								);
							})}
							<div className='!mt-64'>
								<MarketingCtaButton
									className='w-full'
									link={{
										href: isB2C ? '/particulier/motorverzekering/aanvragen' : '/aanvragen/bedrijf/zoeken',
										title: t('common.ctaCalculatePremium'),
										content: t('common.ctaCalculatePremium'),
										target: '_self',
									}}
									size='sm'
								/>
							</div>
						</div>
					</LayoutGrid>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import { Icon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { NicheCtaItem } from '@monorepo/types';

type SingleNicheCtaProps = NicheCtaItem & {
	className?: string;
};

export const SingleNicheCta: FC<SingleNicheCtaProps> = ({ link, image, className }) => {
	return (
		<Link
			href={link.href}
			title={link.title}
			className={cn(className, 'bg-grayscale0 rounded-24 group overflow-hidden')}>
			<div className='rounded-lt-24 rounded-tr-24 rounded-bl-24 bg-grayscale0 relative z-10 flex h-80 flex-row items-center space-x-8 px-24'>
				<Text as='span' variant='body-l' color='primaryMain' weight='medium' className='grow leading-none'>
					{link.value}
				</Text>

				<Icon
					name='angle-right'
					color='info300'
					className='relative ml-auto block transition-transform group-hover:translate-x-8 group-active:translate-x-8'
				/>
			</div>

			<div className='relative z-0 -mt-24 h-[260px] w-full md:h-[370px]'>
				<div className='after:rounded-tr-24 absolute right-0 top-24 z-10 h-24 w-24 overflow-hidden after:absolute after:right-0 after:top-0 after:h-24 after:w-24 after:shadow-[0_0_0_100px_white]' />
				<Image
					sizes={generateSizesString()}
					src={image.src}
					alt={image.alt}
					className='rounded-b-12 object-cover object-top transition-transform duration-500 ease-in-out group-hover:scale-110'
					fill
				/>
			</div>
		</Link>
	);
};

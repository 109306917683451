import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC } from 'react';

import { anchorClassName, underlineClassName } from '../../../../../lib/utils';
import { Text } from '../../../../TextComponents';

type BlogSectionFooterProps = {
	privacyStatementHref: string;
	termsHref: string;
	className?: string;
};

export const BlogSectionFooter: FC<BlogSectionFooterProps> = ({ privacyStatementHref, termsHref, className }) => {
	const { t } = useTranslation(['common']);

	return (
		<div className={cn(className, 'mx-auto flex w-full items-center justify-center md:justify-end')}>
			<ul className='flex gap-x-24'>
				<li className='inline-block'>
					<Link href={termsHref} className={anchorClassName}>
						<Text as='span' variant='body-s' weight='regular' color='grayscale300'>
							{t('common.terms')}
						</Text>
						<span className={underlineClassName}></span>
					</Link>
				</li>
				<li className='inline-block'>
					<Link href={privacyStatementHref} className={anchorClassName}>
						<Text as='span' variant='body-s' weight='regular' color='grayscale300'>
							{t('common.privacyPolicy')}
						</Text>
						<span className={underlineClassName}></span>
					</Link>
				</li>
			</ul>
		</div>
	);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types'; // Adjust the import path as needed

export const jrlndmwSchema = (t: TranslationType) =>
	z.object({
		revenue: z.coerce
			.number()
			.min(1, t('validation.min', { min: '€ 1' }))
			.max(599_999, t('validation.max', { max: '€ 599.999' }))
			.transform((value) => (typeof value === 'number' && !isNaN(value) ? value : NaN)),
	});

export type JrlndmwSchema = z.infer<ReturnType<typeof jrlndmwSchema>>;

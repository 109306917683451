import { getYear, parse } from 'date-fns';

import { IPostPensionForecastResponse } from '@monorepo/types';

import { PensionGraphData } from '@funnel/components/PensionGraph/PensionGraph';

export const mapResponseToChartData = (response?: IPostPensionForecastResponse): Array<PensionGraphData> => {
	if (typeof response === 'undefined') {
		return [];
	}

	return [...response].map((record) => ({
		year: getYear(parse(record.dateReporting, 'yyyy-MM-dd', new Date())),
		optimistic: record.cumulativeCapital[2] ?? 0,
		realistic: record.cumulativeCapital[1] ?? 0,
		pessimistic: record.cumulativeCapital[0] ?? 0,
		inlay: record.cumulativeDeposits,
	}));
};

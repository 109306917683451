import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import { ThemeNames } from '@monorepo/types';

import { useIsMobile } from '../../../../lib/hooks';

type Props = {
	direction?: 'ltr' | 'rtl' | 'center';
	align?: 'left' | 'center' | 'right' | 'center-contain';
	landingHref: string;
	theme: ThemeNames;
	className?: string;
};

// Themes with mobile logo
const themesWithMobileLogo: ThemeNames[] = ['cybermeister', 'meestersgilde'];

export const Logo: FC<Props> = ({ direction = 'ltr', align, landingHref, theme, className = '' }) => {
	const { isMobile } = useIsMobile();

	return (
		<Link className={cn(className, 'transition-all')} href={landingHref} title='Home'>
			<Image
				className={cn(
					direction === 'ltr' ? 'object-left' : direction === 'center' ? 'object-center' : 'object-right',
					align === 'left' && 'object-contain',
					align === 'center' && 'object-fill',
					align === 'center-contain' && 'object-contain',
					align === 'right' && 'object-contain'
				)}
				src={`/affinitygroups/${theme}/logo/logo-${direction === 'center' || direction === 'ltr' ? 'ltr' : 'rtl'}${
					isMobile && themesWithMobileLogo.includes(theme) ? '-mobile' : ''
				}.svg`}
				alt={`Logo ${theme}`}
				sizes='100%'
				fill
			/>
		</Link>
	);
};

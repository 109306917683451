import { cva, VariantProps } from 'class-variance-authority';

export const root = cva(
	[
		'rounded-16',
		'border-primaryMain',
		'bg-primary50',
		'data-checked:bg-primaryMain',
		'disabled:border-grayscale300',
		'flex',
		'h-24',
		'w-40',
		'border',
		'p-[3px]',
		'transition-all',
		'duration-100',
	],
	{
		variants: {
			color: {
				default: ['border-primaryMain', 'bg-primary50', 'data-checked:bg-primaryMain'],
				success: ['bg-grayscale100', 'data-checked:bg-success300', 'border-transparent'],
			},
		},
		defaultVariants: {
			color: 'default',
		},
	}
);

export const indicator = cva(
	[
		'data-[disabled]:bg-grayscale300',
		'block',
		'h-16',
		'w-16',
		'translate-x-0.5',
		'rounded-full',
		'transition-all',
		'duration-100',
		'data-[state=checked]:translate-x-[16px]',
	],
	{
		variants: {
			color: {
				default: ['bg-primaryMain', 'data-checked:bg-grayscale0'],
				success: ['bg-grayscale0'],
			},
		},
		defaultVariants: {
			color: 'default',
		},
	}
);

export type ToggleVariants = VariantProps<typeof root>['color'];

import * as RadixSelect from '@radix-ui/react-select';
import { ComponentProps, useCallback, useState } from 'react';

import { Icon } from '@monorepo/shared/icon';
import { Text } from '@monorepo/shared/typography';

type SelectItem = {
	label: string;
} & ComponentProps<typeof RadixSelect.Item>;

type SelectProps = {
	placeholder: string;
	items: Array<SelectItem>;
} & ComponentProps<typeof RadixSelect.Root>;

export function Select(props: SelectProps) {
	const { items, placeholder, defaultValue, onValueChange, ...rest } = props;
	const [value, setValue] = useState<ComponentProps<typeof RadixSelect.Item>['value'] | undefined>(undefined);

	const handleOnValueChange = useCallback(
		(value: ComponentProps<typeof RadixSelect.Item>['value']) => {
			setValue(value);
			onValueChange?.(value);
		},
		[defaultValue]
	);

	const hasActiveOption = !!value;

	return (
		<RadixSelect.Root onValueChange={handleOnValueChange} {...rest}>
			<RadixSelect.Trigger className='bg-grayscale0 rounded-6 border-grayscale300 flex w-full items-center justify-between gap-x-12 border p-8'>
				<Text
					variant='body-m'
					weight={!hasActiveOption ? 'regular' : 'medium'}
					color={!hasActiveOption ? 'grayscale300' : 'grayscale500'}>
					<RadixSelect.Value placeholder={placeholder} />
				</Text>
				<RadixSelect.Icon>
					<Icon name='angle-down' aria-hidden />
				</RadixSelect.Icon>
			</RadixSelect.Trigger>
			<RadixSelect.Portal>
				<RadixSelect.Content className='bg-grayscale0 shadow-1 border-grayscale100 rounded-6 border'>
					<RadixSelect.ScrollUpButton />
					<RadixSelect.Viewport asChild>
						<ul>
							{items.map(({ label, ...itemProps }) => {
								return (
									<RadixSelect.Item {...itemProps} asChild>
										<li
											key={itemProps.value}
											className='hover:bg-primary50 flex items-center justify-between px-16 py-8'>
											<Text variant='body-m' weight={!hasActiveOption ? 'regular' : 'medium'} color='grayscale500'>
												<RadixSelect.ItemText>{label}</RadixSelect.ItemText>
											</Text>
											<RadixSelect.ItemIndicator asChild>
												<Icon name='check' className='text-success300' />
											</RadixSelect.ItemIndicator>
										</li>
									</RadixSelect.Item>
								);
							})}
						</ul>
					</RadixSelect.Viewport>
					<RadixSelect.ScrollDownButton />
				</RadixSelect.Content>
			</RadixSelect.Portal>
		</RadixSelect.Root>
	);
}

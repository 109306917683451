import cn from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';

import { NavItem } from '@monorepo/types';

import { anchorClassName, underlineClassName } from '../../../../../lib/utils';
import { Text } from '../../../../TextComponents';

type MenuLinkProps = {
	item: NavItem;
	inMobileNav?: boolean;
	className?: string;
};

export const NavbarMenuLink: FC<MenuLinkProps> = ({ item, inMobileNav = false, className }) => {
	return (
		<Link href={item.href} title={item.value} className={cn(anchorClassName, className, 'flex w-fit flex-col')}>
			<Text variant={inMobileNav ? 'body-l' : 'body-m'} weight='medium' color='primaryMain'>
				{item.value}
			</Text>
			<span className={underlineClassName}></span>
		</Link>
	);
};

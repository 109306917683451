import cn from 'classnames';
import Link from 'next/link';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { StandardButton } from '@monorepo/components/DataEntry';
import { useAnalytics } from '@monorepo/shared/lib/hooks';
import { slugify } from '@monorepo/shared/lib/utils';
import { CmsLink } from '@monorepo/types';

import { selectTheme } from '@common/store';

type DetailInfoLinksProps = { links: CmsLink[]; className?: string };

export const DetailInfoLinks: FC<DetailInfoLinksProps> = ({ links, className }) => {
	const { trackEvent } = useAnalytics();
	const theme = useSelector(selectTheme);

	const onClick = () => {
		trackEvent('start_funnel', { sales_channel: theme });
	};

	return (
		<div className={cn(className, 'flex flex-row flex-wrap gap-12')}>
			{links.map(({ href, content, title }) => (
				<Link
					onClick={onClick}
					role='link'
					key={`detail-info-link-${slugify(content || title)}`}
					href={href}
					title={title}>
					<StandardButton role='button' size='sm' variant='secondary' label={content || title} />
				</Link>
			))}
		</div>
	);
};

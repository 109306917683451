import { IDetailHero } from '@monorepo/types';

export const mockDetailHero: IDetailHero = {
	pretext: 'Regel direct je',
	title: 'Aansprakelijkheidsverzekering',
	subtext: 'Bescherming voor jouw passie en vak',
	price: {
		pretext: 'Vanaf',
		subtext: 'incl. assurantiebelasting',
		amount: '€94*',
	},
	link: {
		href: '/aanvragen/bedrijf/zoeken',
		content: 'Stel nu samen',
		title: 'Stel nu samen',
	},
	items: [
		{ content: 'Wereldwijd verzekerd tot wel € 2.500.000' },
		{ content: 'Ook brand schade' },
		{ content: 'Met of zonder eigen risico' },
	],

	tagline: 'Binnen 5 minuten geregeld',
};

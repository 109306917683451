import Link from 'next/link';
import { FC } from 'react';

import { Icon } from '@monorepo/components/Misc';
import { SvgCircle } from '@monorepo/components/Misc/Doodles/Circle/Circle';
import { Text } from '@monorepo/components/TextComponents';
import { INicheOverviewCard } from '@monorepo/types';

type NicheOverviewCardProps = INicheOverviewCard;

export const NicheOverviewCard: FC<NicheOverviewCardProps> = ({ icon, title, description, link }) => {
	return (
		<Link href={link.href} target={link.target} title={link.title} className='group'>
			<div className='bg-grayscale0 shadow-1 rounded-16 flex h-full flex-col p-20 md:px-56 md:py-32'>
				<div className='flex flex-row max-md:items-center max-md:space-x-16 md:flex-1 md:flex-col md:space-y-12'>
					<div className='relative w-fit'>
						<SvgCircle
							theme='maia'
							className='text-secondaryMain w-h-8 absolute bottom-8 left-0 h-8 md:bottom-4 md:h-12 md:w-12'
						/>
						<Icon name={icon} size='custom' color='info300' className='relative z-10 text-[32px] md:text-[40px]' />
						<SvgCircle
							theme='maia'
							className='text-secondaryMain absolute -right-4 top-8 h-16 w-16 md:right-0 md:top-4 md:h-20 md:w-20'
						/>
					</div>

					<Text variant='display-5' weight='semibold' color='grayscale600'>
						{title}
					</Text>

					<Text variant='body-l' weight='regular' color='grayscale400' className='max-md:hidden'>
						{description}
					</Text>
				</div>

				<button className='-mb-8 -ml-4 mt-24 flex items-center space-x-4 px-4 py-8 max-md:hidden'>
					<span>{link.content || link.title}</span>
					<Icon name='arrow-right' color='info300' size='lg' />
				</button>
			</div>
		</Link>
	);
};

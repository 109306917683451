import 'react-datepicker/dist/react-datepicker.css';

import cn from 'classnames';
import { nl } from 'date-fns/locale/nl';
import { FC, forwardRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

import { D_DATE_FORMAT } from '../../../../lib/utils';
import { CalendarHeader } from '../../Calendar/CalendarHeader/CalendarHeader';
import {
	baseClasses,
	disabledClasses,
	fieldSizeLgClasses,
	fieldSizeMdClasses,
	fieldSizeSmClasses,
	focusClasses,
	FormBaseInputDefaultProps,
	FormBaseInputProps,
	hoverClasses,
} from '../FormBaseInput/FormBaseInput';
import { FormBaseInputWrapper } from '../FormBaseInputWrapper/FormBaseInputWrapper';
import { FormHelper } from '../FormHelper/FormHelper';

registerLocale('nl', nl);

type ForwardedPatternFormatProps = {
	isError?: boolean;
	startIcon?: string;
	endIcon?: string;
	fieldSize: string;
	className?: string;
	defaultValue: string;
};

export const ForwardedPatternFormat: FC<ForwardedPatternFormatProps> = forwardRef<
	HTMLInputElement,
	ForwardedPatternFormatProps
>(({ className, endIcon, fieldSize, isError, startIcon, defaultValue, ...props }, ref) => {
	return (
		<PatternFormat
			getInputRef={ref}
			format='##-##-####'
			className={cn(
				baseClasses.replace('typography-body-m', 'typography-body-l'),
				disabledClasses,
				hoverClasses,
				focusClasses,
				isError && 'border-error300',
				!isError && 'border-grayscale200',
				startIcon && 'pl-40',
				endIcon && 'pr-10',
				fieldSize === 'sm' && fieldSizeSmClasses,
				fieldSize === 'md' && fieldSizeMdClasses,
				fieldSize === 'lg' && fieldSizeLgClasses,
				'text-primaryMain text-center font-bold',
				className
			)}
			autoComplete='off'
			defaultValue={defaultValue}
			{...props}
		/>
	);
});

export type FormDateInputProps<TFormValues extends FieldValues> = {
	hint?: string;
	hideError?: boolean;
	control: Control<TFormValues>;
	onEndIconClick?: () => void;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<FormBaseInputProps, 'name' | 'errors' | 'type'>;

export const FormDateInput = <TFormValues extends Record<string, unknown>>({
	name,
	fieldSize = 'md',
	min,
	max,
	errors,
	hint,
	hideError,
	placeholder,
	control,
	onEndIconClick,
	defaultValue,
	startIcon,
	endIcon,
	className,
	...props
}: FormDateInputProps<TFormValues>): JSX.Element => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	let minDate: Date | undefined, maxDate: Date | undefined;

	if (min) {
		minDate = new Date(`${min}`);
	}

	if (max) {
		maxDate = new Date(`${max}`);
	}

	return (
		<FormHelper errors={errors} hint={hint} hideError={hideError}>
			{({ isError }) => (
				<Controller
					control={control}
					defaultValue={defaultValue as PathValue<TFormValues, Path<TFormValues>>}
					name={name}
					render={({ field: { name, onChange, onBlur, value, ref }, fieldState: { isDirty } }) => (
						<FormBaseInputWrapper
							isDisabled={props.disabled}
							isDirty={isDirty}
							isError={isError}
							isEmpty={!value}
							startIcon={startIcon}
							endIcon={endIcon}
							onEndIconClick={() => {
								setIsOpen(!isOpen);
								onEndIconClick && onEndIconClick();
							}}
							{...props}>
							<DatePicker
								minDate={minDate}
								maxDate={maxDate}
								disabled={props.disabled}
								onClickOutside={() => setIsOpen(false)}
								locale={nl}
								id={name}
								name={name}
								ref={ref}
								autoComplete='off'
								open={isOpen}
								onInputClick={() => setIsOpen(!isOpen)}
								dateFormat={D_DATE_FORMAT}
								selected={value as Date}
								onChange={(date) => {
									setIsOpen(false);
									onChange && date && onChange(date);
								}}
								onBlur={() => {
									setIsOpen(false);
									onBlur && onBlur();
								}}
								customInput={
									<ForwardedPatternFormat
										fieldSize={fieldSize}
										defaultValue={`${value}`}
										endIcon={endIcon}
										isError={isError}
										startIcon={startIcon}
										className={className}
										{...props}
									/>
								}
								placeholderText={placeholder}
								popperPlacement='bottom-end'
								renderCustomHeader={({
									date,
									decreaseMonth,
									increaseMonth,
									prevMonthButtonDisabled,
									nextMonthButtonDisabled,
								}) => (
									<CalendarHeader
										date={date}
										prevMonthButtonDisabled={prevMonthButtonDisabled}
										nextMonthButtonDisabled={nextMonthButtonDisabled}
										decreaseMonth={decreaseMonth}
										increaseMonth={increaseMonth}
									/>
								)}
							/>
						</FormBaseInputWrapper>
					)}
				/>
			)}
		</FormHelper>
	);
};

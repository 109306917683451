import { CreateRiskAddressRequest } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const riskAddressApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		createRiskAddress: builder.mutation<void, CreateRiskAddressRequest>({
			query({ cart_guid, product_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/products/${product_guid}/risk-address`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['Calculations'],
		}),
		deleteRiskAddress: builder.mutation<void, { product_guid: string; cart_guid: string }>({
			query({ cart_guid, product_guid }) {
				return {
					url: `/carts/${cart_guid}/products/${product_guid}/risk-address`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['Calculations'],
		}),
	}),
	overrideExisting: true,
});

export const { useCreateRiskAddressMutation, useDeleteRiskAddressMutation } = riskAddressApi;

export const { createRiskAddress } = riskAddressApi.endpoints;

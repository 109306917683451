import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const fteSchema = (t: TranslationType) =>
	z.object({
		fteType: z.enum(['single', 'multiple'], { required_error: t('validation.required') }),
		fte: z.coerce.number().min(0.1, t('validation.min', { min: 0.1 })),
	});

export type FteSchema = z.infer<ReturnType<typeof fteSchema>>;

import cn from 'classnames';
import { FC } from 'react';

import { ThemeColors } from '@monorepo/types';

import { Icon } from '../../../Misc';

type Props = {
	id: string;
	iconName?: string;
	iconColor?: ThemeColors | 'inherit';
	className?: string;
};

export const TooltipIcon: FC<Props> = ({
	id,
	iconName = 'question-circle',
	iconColor = 'grayscale400',
	className = '',
}) => {
	return (
		<button data-tooltip-id={id} type='button' tabIndex={-1} className={cn(className, 'cursor-pointer leading-none')}>
			<Icon color={iconColor} name={iconName} />
		</button>
	);
};

import { FC, ReactNode } from 'react';

import { BaseAccordion } from '../BaseAccordion/BaseAccordion';

type Props = {
	icon: string;
	title: string;
	children: ReactNode;
	isDefaultOpen?: boolean;
	className?: string;
};

export const CheckboxAccordion: FC<Props> = ({ title, icon, isDefaultOpen = false, children, className }) => {
	return (
		<BaseAccordion title={title} icon={icon} isDefaultOpen={isDefaultOpen} className={className}>
			<div className='mt-12 space-y-12'>{children}</div>
		</BaseAccordion>
	);
};

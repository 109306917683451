import { FC } from 'react';

import { BulletList } from '../../../TextComponents';
import { BaseAccordion } from '../BaseAccordion/BaseAccordion';

export type InfoAccordionProps = {
	title: string;
	items: string[];
};

export const InfoAccordion: FC<InfoAccordionProps> = ({ title, items }) => {
	return (
		<BaseAccordion title={title}>
			<BulletList className='mt-16' items={items} />
		</BaseAccordion>
	);
};

import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { PhoneFeatureCarouselSection } from '@marketing/components';

export const handleSectionClaimReporting = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextElement = elements.find((element) => element.identifier === 'title-subtext') as CmsElement;
	const title = getProperty('title', titleSubtextElement?.custom_fields);
	const subtext = getProperty('subtext', titleSubtextElement?.custom_fields);
	const claimInfoElements = elements.filter((element) => element.identifier === 'claim-info') as CmsElement[];

	const features = claimInfoElements.map(({ custom_fields, media, child_elements }) => {
		const checklistElements = child_elements.find((element) => element.identifier === 'title-subtext') as CmsElement;
		const title = getProperty('title', checklistElements.custom_fields);
		const subtext = getProperty('subtext', checklistElements.custom_fields);
		const icon = getProperty('icon_name', custom_fields);

		return {
			selector: {
				icon: `${icon}`,
				title: `${title}`,
			},
			details: {
				title: `${title}`,
				description: `${subtext}`,
			},
			image: media[0],
		};
	});
	return () => (
		<PhoneFeatureCarouselSection
			className='pb-104 md:pt-104 pt-48'
			title={`${title}`}
			subtitle={`${subtext}`}
			features={features}
		/>
	);
};

import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { SplashHeader } from '@common/components';

export const handleSectionSplashHeader = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const promotionTagsEle = elements.find((ele) => ele.identifier === 'tags_promotion-tag') as CmsElement;
	const titleSubtextEle = elements.find((ele) => ele.identifier === 'title-subtext') as CmsElement;

	const promotionTag = promotionTagsEle.markdown;
	const title = getProperty('title', titleSubtextEle.custom_fields);
	const subtext = getProperty('subtext', titleSubtextEle.custom_fields);

	return (props) => <SplashHeader {...props} tag={promotionTag} title={`${title}`} subtext={`${subtext}`} />;
};

import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { generateSizesString } from '../../../../../lib/utils';
import { StandardButton } from '../../../../DataEntry';
import { IconList, Text } from '../../../../TextComponents';

type BlogSectionUspHighlightProps = {
	title: string;
	img: string;
	alt: string;
	usps: { icon: string; title: string }[];
	ctaLabel: string;
	ctaHref: string;
	className?: string;
};

export const BlogSectionUspHighlight: FC<BlogSectionUspHighlightProps> = ({
	title,
	img,
	alt,
	usps,
	ctaLabel,
	ctaHref,
	className = '',
}) => {
	const { locale } = useRouter();

	return (
		<section
			className={cn(
				className,
				'bg-primary50 max-sm:before:bg-primary50 md:px-104 rounded-40 relative col-span-full mx-auto mt-32 flex w-full max-w-[1200px] flex-col items-center space-y-16 py-24 max-sm:before:absolute max-sm:before:inset-0 max-sm:before:-left-full max-sm:before:-right-full max-sm:before:-z-10 sm:my-48 sm:space-y-48 sm:px-48 sm:py-48'
			)}>
			<Text as='h2' variant='display-3' weight='semibold' color='grayscale600' className='max-w-[750px] text-center'>
				{title}
			</Text>
			<div className='flex w-full flex-col max-sm:space-y-16 sm:flex-row sm:space-x-24 md:space-x-48'>
				<div className='flex w-full items-center justify-center sm:w-1/2'>
					<div className='relative h-[330px] min-h-[330px] w-full sm:h-[350px] sm:min-h-[350px] sm:w-[350px]'>
						<Image
							sizes={generateSizesString()}
							src={img}
							priority={true}
							quality={100}
							alt={alt}
							className='rounded-12 object-cover object-center'
							fill
						/>
					</div>
				</div>
				<div className='flex w-full flex-col items-start justify-center space-y-24 sm:w-1/2 sm:space-y-48'>
					<IconList
						items={usps.map(({ icon, title }) => ({
							icon,
							title,
							titleColor: 'grayscale500',
						}))}
					/>
					<Link className='w-fit max-sm:w-full' locale={locale} href={ctaHref} title={ctaLabel}>
						<StandardButton label={ctaLabel} variant='warning' size='lg' className='w-full' iconRight='angle-right' />
					</Link>
				</div>
			</div>
		</section>
	);
};

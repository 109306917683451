import type { QuestionPageData } from '@monorepo/types';

export const premiumPageMap = new Map<string, QuestionPageData>();
premiumPageMap
	.set('vakgebieden', {
		slug: 'vakgebieden',
		i18nKey: 'workfields',
	})
	.set('activiteiten', {
		slug: 'activiteiten',
		i18nKey: 'activities',
	})
	.set('affinity-group', {
		slug: 'affinity-group',
		i18nKey: 'affinityGroup',
	})
	.set('fte', {
		slug: 'fte',
		i18nKey: 'fte',
	})
	.set('revenue', {
		slug: 'revenue',
		i18nKey: 'revenue',
	})
	.set('employees', {
		slug: 'employees',
		i18nKey: 'employees',
	})
	.set('property-ownership', {
		slug: 'property-ownership',
		i18nKey: 'propertyOwnership',
	})
	.set('ag-membership', {
		slug: 'ag-membership',
		i18nKey: 'agMembership',
	});

import { FC } from 'react';

import { CheckListProps, NumberedList, Text, TitleAndText } from '@monorepo/components/TextComponents';
import { getProperty } from '@monorepo/shared/lib/cms';
import { slugify } from '@monorepo/shared/lib/utils';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleNumberedList = ({ component }: HandlerProps): FC<CheckListProps> => {
	const title = getProperty('title', component.custom_fields);

	const numberedListItems = component.child_elements.map((child_element) => {
		const itemTitle = getProperty('title', child_element.custom_fields);

		return (
			<div key={`handle-numbered-list-${slugify(`${itemTitle}`)}`} className='flex flex-col'>
				<Text variant='body-m' weight='medium' color='grayscale500'>
					{itemTitle}
				</Text>
				<Text variant='body-m' color='grayscale500'>
					{child_element.markdown}
				</Text>
			</div>
		);
	});

	return (props) => (
		<div className='space-y-16'>
			<TitleAndText title={`${title}`} text={`${component.markdown}`} />
			<NumberedList {...props} items={numberedListItems} />
		</div>
	);
};

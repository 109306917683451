import Head from 'next/head';
import { type FC } from 'react';

export const OnderhoudNlHead: FC = () => (
	<Head>
		<link rel='apple-touch-icon' sizes='180x180' href='/label/onderhoudnl/apple-touch-icon.png' />
		<link rel='icon' href='/label/onderhoudnl/favicon.ico' sizes='any' />
		<link rel='shortcut icon' href='/label/onderhoudnl/favicon.ico' sizes='any' />
		<link rel='icon' type='image/png' sizes='32x32' href='/label/onderhoudnl/favicon-32x32.png' />
		<link rel='icon' type='image/png' sizes='16x16' href='/label/onderhoudnl/favicon-16x16.png' />
		<link rel='mask-icon' href='/label/onderhoudnl/safari-pinned-tab.svg' color='#5bbad5"' />
		<link rel='manifest' href='/label/onderhoudnl/manifest.json' />

		<meta name='msapplication-TileColor' content='#da532c' />
		<meta name='theme-color' content='#ffffff' />
		<meta name='msapplication-config' content='/label/onderhoudnl/browserconfig.xml' />
	</Head>
);

import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { SwagItem } from '@monorepo/types';

import { SingleSwag } from './SingleSwag/SingleSwag';

export type SwagSectionProps = {
	items: SwagItem[];
	className?: string;
};

export const singleSwagBgClassName: Record<number, string> = {
	0: 'bg-swag-yellow',
	1: 'bg-swag-red',
	2: 'bg-swag-green',
	3: 'bg-swag-blue',
};

export const SwagSection: FC<SwagSectionProps> = ({ items, className }) => {
	return (
		<section className={cn(className, 'relative z-10 py-48')}>
			<LayoutGrid layout='marketing'>
				<ul role='list' className='col-start-2 flex flex-row flex-wrap justify-between gap-32'>
					{items
						.sort((a, b) => a.sequence - b.sequence)
						.map((item, index) => (
							<SingleSwag
								className={cn(singleSwagBgClassName[index], 'basis-1/2 md:basis-1/4')}
								key={`single-swag-${item.sequence}`}
								{...item}
							/>
						))}
				</ul>
			</LayoutGrid>
		</section>
	);
};

import { useEffect, useState } from 'react';

import { claimByGuid, IntakeRequest, Products } from '@monorepo/types/src/portal/Intake.type';

import { formatDate, M_DATE_FORMAT } from '../date/date';
import { INPUT_TYPES, Question } from './intakeReducer';

export type AiResponse = {
	data: {
		data: Array<{
			content: Array<{
				text: {
					value: string;
				};
			}>;
		}>;
	};
};

export const serializeAiData: (response: AiResponse) => { name: string | null; guid: string | null } | null = (
	response
) => {
	try {
		const viableProducts = response.data.data[0].content[0].text.value;
		return JSON.parse(viableProducts);
	} catch (err) {
		return null;
	}
};

export type PostIntakeQuestionToOpenAiParams = {
	claimDescription: string;
	insurances: Array<{
		guid: string;
		name: string;
	}>;
};

export const postIntakeQuestionToOpenAi = async (body: PostIntakeQuestionToOpenAiParams) => {
	const response = await fetch('/api/chat/claim-intake', {
		method: 'POST',
		body: JSON.stringify(body),
	});
	const data = await response.json();
	return serializeAiData(data);
};

export type SanitizeIntakeQuestionsParams = {
	subject: string | null;
	product_guid: string | null;
	messages: Array<Question>;
};

export const sanitizeIntakeQuestions = (data: SanitizeIntakeQuestionsParams): IntakeRequest => {
	return {
		claim_date: formatDate({
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			date: new Date(getMessageByQuestionGuid(data.messages, 'claim_date')?.answer ?? (new Date() as any)),
			dateFormat: M_DATE_FORMAT,
		}).toString(),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		claim_description: (getMessageByQuestionGuid(data.messages, 'claim_description')?.answer ?? '') as any,
		claimed_amount: Number.parseInt(
			(getMessageByQuestionGuid(data.messages, 'claimed_amount')?.answer as string) ?? '0'
		),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		subject: data.subject as any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		product_guid: data.product_guid as any,
	};
};

export type useGetInformationFormAiAssistantProps = {
	skip: boolean;
	questions: Array<Question>;
	products: Products;
};

export const useGetInformationFormAiAssistant = ({
	skip,
	questions,
	products,
}: useGetInformationFormAiAssistantProps) => {
	const [data, setData] = useState<IntakeRequest>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (skip || data || isLoading || questions.some((q) => !q.answer)) {
			return;
		}

		setIsLoading(true);
		async function collectData() {
			const intakeOpenAiResponse = await postIntakeQuestionToOpenAi({
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				claimDescription: getMessageByQuestionGuid(questions, 'claim_description')?.answer as any,
				insurances: products.flatMap((product) => product),
			});

			return sanitizeIntakeQuestions({
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				product_guid: intakeOpenAiResponse?.guid as any,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				subject: intakeOpenAiResponse?.name as any,
				messages: questions,
			});
		}

		collectData()
			.then(setData)
			.finally(() => setIsLoading(false));
	}, [skip, questions, products]);

	return { data, isLoading };
};

// TODO: Find lib for FP utils (Effect-ts, fp-ts, Rambda)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Fn = (...args: any[]) => any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LastReturnType<L extends Fn[]> = L extends [...any, infer Last extends Fn] ? ReturnType<Last> : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const pipe = <Funcs extends Fn[]>(value: any, ...fns: Funcs) =>
	fns.reduce((acc, fn) => fn(acc), value) as LastReturnType<Funcs>;

export const orderNovuloIntakeQuestionsBySequence = (novuloIntakeQuestions: claimByGuid): claimByGuid =>
	[...novuloIntakeQuestions].sort((a, b) => a.sequence - b.sequence);

export const sanitizeNovuloQuestions = (novuloIntakeQuestions: claimByGuid): Array<Question> => {
	return novuloIntakeQuestions.map((question) => {
		const inputType = INPUT_TYPES[question.input_type as keyof typeof INPUT_TYPES];

		if (inputType === 'multiple_choice') {
			return {
				guid: question.guid,
				question: question.description,
				inputType: INPUT_TYPES[question.input_type as keyof typeof INPUT_TYPES],
				inputOptions: question.response_options,
				answer: Object.entries(question).find(([key]) => key.startsWith('answer_'))?.[1],
				sequence: question.sequence,
			};
		}

		return {
			guid: question.guid,
			question: question.description,
			inputType: INPUT_TYPES[question.input_type as keyof typeof INPUT_TYPES],
			answer: Object.entries(question).find(([key]) => key.startsWith('answer_'))?.[1],
			sequence: question.sequence,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	}) as any;
};

export const novuloQuestions = (novuloIntakeQuestions: claimByGuid) =>
	pipe(novuloIntakeQuestions, orderNovuloIntakeQuestionsBySequence, sanitizeNovuloQuestions);

export const getMessageByQuestionGuid = (questions: Array<Question>, guid: Question['guid']) =>
	questions.find((message) => message.guid === guid);

export const userHasAccesToInsurance = (productGuid?: string | null, userProductsGuids?: Array<string | null>) => {
	if (!productGuid || !userProductsGuids) {
		return false;
	}
	return userProductsGuids.includes(productGuid);
};

export const updateQuestionValuesByGuid = (prevQuestions: Array<Question>, newQuestions: Array<Question>) => {
	const mergeReducer = (accumulator: Array<Question>, current: Question) => {
		const index = accumulator.findIndex((item) => item.guid === current.guid);
		if (index !== -1) {
			accumulator[index] = current; // Replace the existing object
		} else {
			accumulator.push(current); // Add the new object
		}
		return accumulator;
	};

	// Start with an empty array and process both arrays
	const mergedArray = [...prevQuestions, ...newQuestions].reduce(mergeReducer, []);

	return mergedArray;
};

import { ComponentPropsWithoutRef, createContext, FC, forwardRef, ReactNode, Ref, useContext, useState } from 'react';

const StepperContext = createContext<number | undefined>(undefined);

export const useStepper = ({ steps, defaultActiveStep }: { steps: number; defaultActiveStep?: number }) => {
	const [activeStep, setActiveStep] = useState<number>(defaultActiveStep || 0);

	const progress = Math.floor((activeStep / (steps - 1)) * 10000) / 100;

	const previousStep = () => {
		if (activeStep > 0) {
			setActiveStep((step) => (step -= 1));
		}
	};

	const nextStep = () => {
		if (activeStep < steps) {
			setActiveStep((step) => (step += 1));
		}
	};

	const goToStep = (step: number) => {
		if (step >= 0 && step < steps) {
			setActiveStep(step);
		}
	};

	return {
		previousStep,
		nextStep,
		activeStep,
		goToStep,
		progress,
	};
};

type StepperProps = {
	activeStep: number;
} & ComponentPropsWithoutRef<'div'>;

const Stepper = forwardRef((props: StepperProps, ref: Ref<HTMLDivElement>) => {
	const { activeStep, children, ...rest } = props;

	return (
		<StepperContext.Provider value={activeStep}>
			<div role='progressbar' {...rest} ref={ref}>
				{children}
			</div>
		</StepperContext.Provider>
	);
});

type StepProps = {
	step: number;
	children: ReactNode | (({ isActive }: { isActive: boolean }) => ReactNode);
};

const Step: FC<StepProps> = ({ step, children }) => {
	const stepperContext = useContext(StepperContext);
	const isActive = typeof stepperContext !== 'undefined' && stepperContext >= step;

	return <>{typeof children === 'function' ? children({ isActive }) : children}</>;
};

const CompoundStepper = Object.assign(Stepper, { Step: Step });

export { CompoundStepper as HeadlessStepper };

import Image from 'next/image';
import { FC } from 'react';

import { generateSizesString } from '../../../../../lib/utils';
import { Text } from '../../../../TextComponents';
import { TestimonialRating } from '../TestimonialRating/TestimonialRating';

export type TestimonialProps = {
	testimonial: string;
	rating: string;
	name: string;
	company?: string;
	image: string;
	alt: string;
};

export const Testimonial: FC<TestimonialProps> = ({ name, rating, testimonial, company, image, alt }) => {
	return (
		<div className='rounded-18 shadow-1 bg-grayscale0 space-y-12 p-20'>
			<Text as='p' variant='body-l' weight='regular' color='grayscale400'>
				{testimonial}
			</Text>
			<div className='flex items-center space-x-8'>
				<div className='relative h-[36px] w-[36px]'>
					<Image
						sizes={generateSizesString()}
						src={image}
						fill
						className='rounded-full object-contain object-center'
						alt={alt}
					/>
				</div>
				<div className='space-y-4'>
					<TestimonialRating rating={rating} />
					<Text variant='body-s' weight='medium' color='grayscale600' className='flex items-baseline space-x-8'>
						<span>{name}</span>
						{company && (
							<Text as='div' variant='body-xs' weight='regular' color='grayscale300'>
								{company}
							</Text>
						)}
					</Text>
				</div>
			</div>
		</div>
	);
};

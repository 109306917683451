import cn from 'classnames';
import { FC, PropsWithChildren, ReactElement, ReactNode, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '@monorepo/components/TextComponents';
import { useAnalytics } from '@monorepo/shared/lib/hooks';
import { toSnakeCase } from '@monorepo/shared/lib/utils';

import { ModalActions, ModalHeader } from '@common/components';
import { hideModal, selectModalIdState } from '@common/store';

ReactModal.defaultStyles.content = {};

type Props = PropsWithChildren<{
	id: string;
	title: string | ReactElement;
	icon?: string;
	subTitle?: string | ReactElement;
	text?: string | ReactElement;
	closable?: boolean;
	submitButton?: ReactNode;
	cancelButton?: ReactNode;
	buttonsFullWidth: boolean;
	className?: string;
	onClose?: () => void;
}>;

// Use to compose Modal variants
export const BaseModal: FC<Props> = ({
	id,
	icon,
	title,
	subTitle,
	text,
	closable = true,
	submitButton,
	cancelButton,
	children,
	buttonsFullWidth,
	className = '',
	onClose,
	...rest
}) => {
	const [modalContainer, setModalContainer] = useState<HTMLElement | null>(null);
	const modalState = useSelector(selectModalIdState(id));
	const dispatch = useDispatch();
	const hasButtons = !!(cancelButton || submitButton);
	const { trackEvent } = useAnalytics();

	const closeModal = async () => {
		dispatch(hideModal(id));

		if (onClose) {
			onClose();
		}
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const modalContainerRef = document.getElementById('modals') as HTMLElement;

			if (modalContainerRef) {
				setModalContainer(modalContainerRef);
			}
		}
	}, []);

	/* istanbul ignore next */
	if (!modalContainer) {
		return null;
	}

	return (
		<ReactModal
			onAfterOpen={() => trackEvent('select_content', { content_type: 'modal', item_id: toSnakeCase(`${id}`) })}
			closeTimeoutMS={200}
			appElement={modalContainer}
			parentSelector={() => modalContainer}
			id={id}
			bodyOpenClassName='overflow-hidden'
			overlayClassName={cn('flex items-end md:items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-50')}
			className={cn(
				className,
				'shadow-1 bg-grayscale0 rounded-t-18 md:rounded-18 relative z-20 h-fit min-h-64 overflow-y-auto pb-32 outline-none max-md:max-h-[80vh] md:max-h-[70vh] md:pb-56'
			)}
			isOpen={modalState ? modalState.state : false}
			onRequestClose={closable ? closeModal : undefined}
			contentLabel={id}
			{...rest}>
			<ModalHeader icon={icon} title={title} subTitle={subTitle} closable={closable} closeModal={closeModal} />
			<div className='space-y-32 px-24 md:px-56'>
				{text && (
					<Text color='grayscale500' variant='body-m'>
						{text}
					</Text>
				)}
				{/* Modal body */}
				{children}
			</div>

			{/* Footer buttons */}
			{hasButtons && (
				<ModalActions
					buttonsFullWidth={buttonsFullWidth}
					cancelButton={cancelButton}
					submitButton={submitButton}
					className='px-24 md:px-56'
				/>
			)}
		</ReactModal>
	);
};

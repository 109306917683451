import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Icon } from '../../../../Misc';
import { Text, UspRow } from '../../../../TextComponents';

export type InsuredBoxProps = {
	usps?: string[];
	title?: string;
	onMoreInfoClick?: () => void;
	className?: string;
};

export const InsuredBox: FC<InsuredBoxProps> = ({ title, usps, onMoreInfoClick, className = '' }) => {
	const { t } = useTranslation(['common']);

	if (!usps || usps.length === 0) {
		return null;
	}

	return (
		<div className={cn(className, 'flex flex-col space-y-12')}>
			<div className='flex flex-row'>
				<Text variant='body-s' as='p' color='grayscale500' weight='semibold'>
					{title}
				</Text>
				{onMoreInfoClick && (
					<Text
						as='button'
						className='pointer-events-auto ml-auto flex cursor-pointer items-center space-x-4'
						onClick={onMoreInfoClick}
						variant='body-xs'
						color='info300'
						weight='medium'>
						<span>{t('common.moreInformation')}</span>

						<Icon name='info-circle' />
					</Text>
				)}
			</div>

			<div className='bg-grayscale50 rounded-6 p-16 md:p-20'>
				<UspRow uspWeight='regular' iconColor='success300' size='sm' iconSize='md' texts={usps} />
			</div>
		</div>
	);
};

import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Form } from '@monorepo/components/DataEntry';
import { ServiceSearchSchema, serviceSearchSchema } from '@monorepo/shared/lib/schemas';

export const ServiceSearchForm: FC = () => {
	const { t } = useTranslation(['common']);

	const serviceSearchForm = useForm<ServiceSearchSchema>({
		mode: 'onBlur',
		resolver: zodResolver(serviceSearchSchema(t)),
	});

	// TODO: implement
	const onSubmit = () => {};

	return (
		<FormProvider {...serviceSearchForm}>
			<Form.Context<ServiceSearchSchema>>
				{({ handleSubmit, register }) => {
					return (
						<Form id='service-search-form' className='flex w-full md:w-2/3' onSubmit={handleSubmit(onSubmit)}>
							<Form.Group>
								<Form.SearchInput
									name='query'
									startIcon='search'
									autoComplete='off'
									placeholder={t('components.serviceSearchForm.placeholder')}
									register={register}
								/>
							</Form.Group>
						</Form>
					);
				}}
			</Form.Context>
		</FormProvider>
	);
};

import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { HeroWithScollingImageAndSearch } from '@marketing/components';

export const handleSectionImageScrollTitle = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextEle = elements.find((ele) => ele.identifier === 'title-subtext') as CmsElement,
		imagesEle = elements.find((ele) => ele.identifier === 'images') as CmsElement;

	const title = getProperty('title', titleSubtextEle.custom_fields);

	return (props) => (
		<HeroWithScollingImageAndSearch {...props} title={`${title}`} images={imagesEle.media} className='bg-primary50' />
	);
};

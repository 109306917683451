export const PHONE_REGEX =
	/(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;

export const IBAN_REGEX =
	/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;

export const IBAN_STRIPPED_REGEX = /^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/;

export const isIban = (possibleIban: string) => {
	if (!IBAN_REGEX.test(possibleIban)) {
		return false;
	}

	const ibanStripped = possibleIban
		.replace(/[^A-Z0-9]+/gi, '') //keep numbers and letters only
		.toUpperCase(); //calculation expects upper-case

	const m = ibanStripped.match(IBAN_STRIPPED_REGEX) as RegExpMatchArray;

	const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, (ch) => {
		//replace upper-case characters by numbers 10 to 35
		return `${ch.charCodeAt(0) - 55}`;
	});

	//The resulting number would be to long for javascript to handle without loosing precision.
	//So the trick is to chop the string up in smaller parts.
	const mod97 = numbericed.match(/\d{1,7}/g)?.reduce((total, curr) => {
		return `${Number(total + curr) % 97}`;
	}, '');

	return mod97 === '1';
};

export const GUID_REGEX = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const isGuid = (guid: string): boolean => {
	return GUID_REGEX.test(guid);
};

// https://blog.kenteken.tv/2011/05/06/code-snippets-formatteren-rdw-kenteken/#:~:text=Code%20snippet%20kenteken%20formatteren&text=RegEx%20%3A%3D%20arrSC%5BiT1%5D,%3D%20%2D1%3B%20finally%20PerlRegEx.
export function getSidecodeLicenseplate(Licenseplate: string): number {
	const arrSC = [
		/^[a-zA-Z]{2}\d{2}\d{2}$/, // 1 XX-99-99
		/^\d{2}\d{2}[a-zA-Z]{2}$/, // 2 99-99-XX
		/^\d{2}[a-zA-Z]{2}\d{2}$/, // 3 99-XX-99
		/^[a-zA-Z]{2}\d{2}[a-zA-Z]{2}$/, // 4 XX-99-XX
		/^[a-zA-Z]{2}[a-zA-Z]{2}\d{2}$/, // 5 XX-XX-99
		/^\d{2}[a-zA-Z]{2}[a-zA-Z]{2}$/, // 6 99-XX-XX
		/^\d{2}[a-zA-Z]{3}\d{1}$/, // 7 99-XXX-9
		/^\d{1}[a-zA-Z]{3}\d{2}$/, // 8 9-XXX-99
		/^[a-zA-Z]{2}\d{3}[a-zA-Z]{1}$/, // 9 XX-999-X
		/^[a-zA-Z]{1}\d{3}[a-zA-Z]{2}$/, // 10 X-999-XX
		/^[a-zA-Z]{3}\d{2}[a-zA-Z]{1}$/, // 11 XXX-99-X
		/^[a-zA-Z]{1}\d{2}[a-zA-Z]{3}$/, // 12 X-99-XXX
		/^\d{1}[a-zA-Z]{2}\d{3}$/, // 13 9-XX-999
		/^\d{3}[a-zA-Z]{2}\d{1}$/, // 14 999-XX-9
	];
	let scUitz = '';

	Licenseplate = Licenseplate.replace('-', '').toUpperCase();

	for (let i = 0; i < arrSC.length; i++) {
		if (arrSC[i].test(Licenseplate)) {
			return i + 1;
		}
	}

	//except licenseplates for diplomats
	scUitz = `^CD[ABFJNST][0-9]{1,3}$`; //for example: CDB1 of CDJ45
	if (Licenseplate.match(scUitz)) {
		return 15;
	}

	return -1;
}

export function formatLicenseplate(Licenseplate: string, Sidecode: number) {
	Licenseplate = Licenseplate.replace(/-/g, '').toUpperCase();

	if (Sidecode <= 6) {
		return Licenseplate.substr(0, 2) + '-' + Licenseplate.substr(2, 2) + '-' + Licenseplate.substr(4, 2);
	}
	if (Sidecode == 7 || Sidecode == 9) {
		return Licenseplate.substr(0, 2) + '-' + Licenseplate.substr(2, 3) + '-' + Licenseplate.substr(5, 1);
	}
	if (Sidecode == 8 || Sidecode == 10) {
		return Licenseplate.substr(0, 1) + '-' + Licenseplate.substr(1, 3) + '-' + Licenseplate.substr(4, 2);
	}
	if (Sidecode == 11 || Sidecode == 14) {
		return Licenseplate.substr(0, 3) + '-' + Licenseplate.substr(3, 2) + '-' + Licenseplate.substr(5, 1);
	}
	if (Sidecode == 12 || Sidecode == 13) {
		return Licenseplate.substr(0, 1) + '-' + Licenseplate.substr(1, 2) + '-' + Licenseplate.substr(3, 3);
	}

	return Licenseplate;
}

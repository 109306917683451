import { createContext } from 'react';

type NavigationContext = {
	goBack: () => void;
	isChildRoute: boolean;
	isB2CFunnel: boolean;
	isB2BFunnel: boolean;
};

export const NavigationContext = createContext<NavigationContext>({
	goBack: () => {},
	isChildRoute: false,
	isB2CFunnel: false,
	isB2BFunnel: true,
});

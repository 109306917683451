import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { DeductibleTag, PriceSticker, TagProps } from '@monorepo/components/DataDisplay';
import { StandardButton } from '@monorepo/components/DataEntry';
import { ProductIcon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { findElementByIdentifier } from '@monorepo/shared/lib/cms';
import { BillingUnits, CalculationProduct, CmsContainers, CmsElement, UpsellProduct } from '@monorepo/types';

import { mapContentToComponent } from '@cms/componentMapper/componentMapper';

type Props = {
	product: CalculationProduct | UpsellProduct;
	content?: CmsElement[];
	price: number;
	deductible: number | undefined;
	runningPromotion: string | boolean | null | undefined;
	ctaText: string;
	onCtaClick?: (guid: string) => void;
	ctaLoading?: boolean;
	billingUnit?: BillingUnits;
	onMoreInfoClick: (guid: string, container: CmsContainers) => void;
};

/**
 * This is the small version of the SelectableProductCard.
 * Note: don't use it as an isolated component
 */
export const SmallProductCard: FC<Props> = ({
	product,
	content,
	price,
	deductible,
	runningPromotion,
	ctaText,
	onCtaClick,
	ctaLoading = false,
	billingUnit = 'monthly',
	onMoreInfoClick,
}) => {
	const { t } = useTranslation(['common']);

	const isProductActive = (product as CalculationProduct).is_active ? (product as CalculationProduct).is_active : false;
	const taglineContent = findElementByIdentifier(content || [], 'product_tagline') || {};
	const Tagline = mapContentToComponent(taglineContent as CmsElement);
	const tagContent = findElementByIdentifier(content || [], 'tags_tag') || {};
	const Tag = mapContentToComponent<TagProps>(tagContent as CmsElement);

	if (content === null) return <></>;

	return (
		<div className='flex w-full flex-col space-y-16 md:space-y-20'>
			{/* Icon and product name  */}
			<div className='flex flex-row items-center space-x-16'>
				<ProductIcon name={product.icon_name} />
				<Text variant='body-l' weight='semibold' color='grayscale600' className='hyphens-auto'>
					{product.name}
				</Text>
			</div>
			{/* Tags  */}
			<div className='flex flex-row flex-wrap items-start gap-8'>
				{tagContent && <Tag />}
				{deductible && <DeductibleTag deductible={deductible} />}
				{runningPromotion === 'first_year' ? (
					<PriceSticker billingUnit={billingUnit} variant='free' size='xs' price={price} alignment='left' />
				) : (
					<PriceSticker
						billingUnit={billingUnit}
						variant='default'
						size='xs'
						excludeFrom={isProductActive}
						price={price}
					/>
				)}
			</div>

			{/* Description and more info click  */}
			<div className='flex grow flex-col space-y-4'>
				{taglineContent && <Tagline />}
				<Text
					as='button'
					className='pointer-events-auto w-fit cursor-pointer'
					onClick={() => onMoreInfoClick(product.guid, 'product_details')}
					variant='body-s'
					color='info300'
					weight='medium'>
					{t('common.moreInformation')}
				</Text>
			</div>

			{/* CTA */}
			{onCtaClick && (
				<StandardButton
					isLoading={ctaLoading}
					disabled={ctaLoading}
					variant='cta'
					className='pointer-events-auto w-full'
					onClick={() => onCtaClick(product.guid)}
					iconRight='angle-right-b'
					label={ctaText}
				/>
			)}
		</div>
	);
};

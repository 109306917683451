import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { List } from '@monorepo/components/TextComponents/Lists/List/List';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { ContentPageTitleSection } from '@marketing/components';

const H1 = (props: ReactMarkdownProps) => (
	<Text as='h1' weight='semibold' variant='display-3' color='primaryMain' {...props} />
);

const H2 = (props: ReactMarkdownProps) => (
	<Text as='h2' weight='semibold' variant='display-4' color='primaryMain' {...props} />
);

const H3 = (props: ReactMarkdownProps) => (
	<Text as='h3' weight='semibold' variant='display-5' color='primaryMain' {...props} />
);

const H4 = (props: ReactMarkdownProps) => (
	<Text as='h4' weight='semibold' variant='display-6' color='primaryMain' {...props} />
);

const Paragraph = (props: ReactMarkdownProps) => <Text as='p' variant='body-m' color='grayscale600' {...props} />;

const UL = (props: ReactMarkdownProps) => <List {...props} />;
const LI = (props: ReactMarkdownProps) => <Text as='li' color='grayscale600' variant='body-m' {...props} />;

const Anchor = (props: ReactMarkdownProps) => (
	<Text as='a' color='info300' variant='body-m' className='underline' {...props} />
);

const Strong = (props: ReactMarkdownProps) => <span className='font-semibold text-[inherit]' {...props} />;

const components: Components = {
	h1: H1,
	h2: H2,
	h3: H3,
	h4: H4,
	p: Paragraph,
	ul: UL,
	li: LI,
	a: Anchor,
	strong: Strong,
	b: Strong,
};

export const handleSectionContentPage = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextEle = elements.find((element) => element.identifier === 'title-subtext') as CmsElement,
		contentEle = elements.find((element) => element.identifier === 'content') as CmsElement,
		title = getProperty('title', titleSubtextEle?.custom_fields);

	return (props) => (
		<>
			<ContentPageTitleSection title={`${title}`} />
			<LayoutGrid layout='marketing'>
				<div className='prose col-start-2 max-w-[initial] py-64 md:py-96'>
					<ReactMarkdown {...props} components={components}>{`${contentEle.markdown}`}</ReactMarkdown>
				</div>
			</LayoutGrid>
		</>
	);
};

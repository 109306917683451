import type { QuestionPageData } from '@monorepo/types';

export const consumerPreconditionsPageMap = new Map<string, QuestionPageData>();
consumerPreconditionsPageMap
	.set('transfer-service', {
		slug: 'transfer-service',
		i18nKey: 'transferService',
	})
	.set('cancel-current-insurance', {
		slug: 'cancel-current-insurance',
		i18nKey: 'cancelCurrentInsurance',
	})
	.set('closing-statement', {
		slug: 'closing-statement',
		i18nKey: 'closingStatement',
	});

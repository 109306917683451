import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ToasterNotification } from '@monorepo/components/DataDisplay';
import { getStaticMapUrl } from '@monorepo/shared/lib/utils';
import { Address, Company } from '@monorepo/types';

import { useFlags } from '@common/hooks';
import { hideModal, selectTheme, showModal } from '@common/store';
import { paths } from '@common/utils';
import { updateCompany } from '@funnel/store';

export const useCompanyCheck = (company: Company) => {
	const [locationImageSrc, setLocationImageSrc] = useState<string>('');
	const { t } = useTranslation(['common']);
	const { push } = useRouter();
	const theme = useSelector(selectTheme);
	const dispatch = useDispatch();
	const { getBoolean } = useFlags();
	const showPortalUpsell = getBoolean('showPortalUpsell');

	const handleExistingCustomer = async (cartGuid?: string | null) => {
		if (!showPortalUpsell) {
			toast.custom(
				({ visible }) => (
					<ToasterNotification
						title={t('page.funnel.finalize.summary.toastErrorTitle')}
						visible={visible}
						message={t('page.funnel.finalize.summary.toastErrorMessage', {
							organizationName: t('common.yourCompany'),
						})}
						status='error'
					/>
				),
				{
					ariaProps: {
						role: 'alert',
						'aria-live': 'assertive',
					},
				}
			);
		}

		const query = {
			dossierNumber: company.dossierNumber,
			...(cartGuid && { cartGuid }),
		};

		const pathname = showPortalUpsell ? paths.funnel.company.login : paths.funnel.finalize.request_contact;

		await push({
			pathname,
			query,
		});
	};

	const handleEditClick = () => {
		dispatch(showModal('change_company'));
	};

	const handleCloseModal = () => {
		dispatch(hideModal('change_company'));
	};

	const handleAddressSubmit = async (data: Address) => {
		dispatch(updateCompany({ address: data }));
		handleCloseModal();
	};

	useEffect(() => {
		setLocationImageSrc(getStaticMapUrl(company.address as Address, theme));
	}, [company.address, theme]);

	return {
		locationImageSrc,
		handleExistingCustomer,
		handleAddressSubmit,
		handleEditClick,
		handleCloseModal,
	};
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const sendMailSchema = (t: TranslationType) =>
	z.object({
		first_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		last_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		email: z.string({ required_error: t('validation.required') }).email(t('validation.email')),
		terms_agreement: z.boolean({ required_error: t('validation.required') }).refine((value) => value === true, {
			message: t('validation.agreement'),
			path: ['terms_agreement'],
		}),
	});

export type SendMailSchema = z.infer<ReturnType<typeof sendMailSchema>>;

import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Trans, useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';

import { ProductDocument, SupportedInsurance, TagStatus } from '@monorepo/types';

import { transformToCurrency } from '../../../../lib/utils';
import { Toggle } from '../../../DataEntry';
import { Divider, Icon, InsuranceDocuments } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { Tag } from '../../TagsAndStatus';
import { BaseCard } from '../BaseCard/BaseCard';
import { InsuredBox, InsuredBoxProps } from '../SelectableProductCard';

type ToggleHeaderProps = {
	icon: string;
	isToggled: boolean;
	hasActiveOption: boolean;
	onToggleInsurance: () => void;
	isDisabled?: boolean;
	isCalculated?: boolean;
	tag?: string;
	tagStatus?: TagStatus;
	lowestPrice?: number;
	price: number;
	isFree?: boolean;
	priceBeforeDiscount?: number;
	title: string;
	name: SupportedInsurance;
	subTitle?: string[];
	subtexts?: ReactNode | string;
};

const ToggleHeader: FC<ToggleHeaderProps> = ({
	icon,
	isToggled,
	hasActiveOption,
	onToggleInsurance,
	isDisabled,
	isCalculated,
	tag,
	tagStatus,
	price,
	lowestPrice,
	isFree,
	priceBeforeDiscount,
	title,
	subTitle,
	subtexts,
}) => {
	const displayPrice = isToggled && hasActiveOption && isCalculated ? price : lowestPrice;

	return (
		<div className='flex flex-col space-y-16 md:space-y-24'>
			<div className='relative flex flex-row items-start'>
				<Toggle isDisabled={isDisabled} onChange={onToggleInsurance} isToggled={isToggled} />
				{tag && <Tag className='mx-16 max-sm:hidden' text={tag} status={tagStatus} />}

				<div className='ml-auto sm:absolute sm:right-0'>
					{isFree ? (
						<div className='flex flex-col items-end space-y-4'>
							<Text variant='display-6' weight='medium' color={isDisabled ? 'grayscale300' : 'success400'}>
								<Trans
									i18nKey={'common.freeYear'}
									tOptions={{ price: transformToCurrency(price) }}
									components={{
										price: (
											<span
												className={`${
													isDisabled ? 'text-grayscale300' : 'text-grayscale600'
												} typography-body-s font-semibold`}
											/>
										),
									}}
								/>
							</Text>
							<Text variant='body-xs' color={isDisabled ? 'grayscale300' : 'grayscale400'}>
								<Trans
									i18nKey={'components.productToggleCard.afterDiscount'}
									tOptions={{ price: transformToCurrency(priceBeforeDiscount) }}
									components={{
										price: (
											<span
												className={`${
													isDisabled ? 'text-grayscale300' : 'text-grayscale600'
												} typography-body-s font-semibold`}
											/>
										),
									}}
								/>
							</Text>
						</div>
					) : (
						<Text variant='body-xs' className='[word-spacing:4px]' color={isDisabled ? 'grayscale300' : 'grayscale400'}>
							<Trans
								i18nKey={
									isToggled && hasActiveOption
										? 'components.productToggleCard.price'
										: 'components.productToggleCard.priceFrom'
								}
								tOptions={{ price: transformToCurrency(displayPrice) }}
								components={{
									price: (
										<span
											className={cn(
												isDisabled ? 'text-grayscale300' : 'text-grayscale600',
												'typography-display-6 font-semibold [word-spacing:0]'
											)}
										/>
									),
								}}
							/>
						</Text>
					)}
				</div>
			</div>
			<div className='flex flex-col space-y-4'>
				<div className='flex flex-row items-start'>
					<Icon
						name={icon}
						className='mr-12 hidden leading-none sm:block'
						color={isDisabled ? 'grayscale300' : 'grayscale600'}
					/>

					<div className='flex flex-col space-y-4'>
						<Text variant='display-6' weight='semibold' color={isDisabled ? 'grayscale300' : 'grayscale600'}>
							{title}
						</Text>
						{subTitle && (
							<Text variant='body-m' color={isDisabled ? 'grayscale200' : 'grayscale400'}>
								{subTitle.join(' / ')}
							</Text>
						)}
					</div>
				</div>

				{tag && <Tag className='!mt-16 sm:hidden' text={tag} status={tagStatus} />}

				{subtexts && (
					<Text variant='body-m' color={isDisabled ? 'grayscale300' : 'grayscale600'}>
						{subtexts}
					</Text>
				)}
			</div>
		</div>
	);
};
type ToggleFooterProps = {
	productDocument?: ProductDocument;
};

const ToggleFooter: FC<ToggleFooterProps> = ({ productDocument }) => {
	const { t } = useTranslation(['common']);

	if (!productDocument) {
		return null;
	}

	return (
		<div className='border-grayscale100 flex flex-col items-start space-y-12 border-t px-16 py-12 md:p-24'>
			<div className='w-full'>
				<InsuranceDocuments title={t('documents.title')} productDocument={productDocument} />
			</div>
			<Text variant='body-xxs' color='grayscale400' className='w-full'>
				{t('documents.disclaimer')}
			</Text>
		</div>
	);
};

type Props = ToggleHeaderProps &
	InsuredBoxProps &
	ToggleFooterProps & {
		preConditionForm?: ReactNode;
		form?: ReactNode;
		description?: string;
		className?: string;
	};

export const ProductToggleCard: FC<Props> = ({
	icon,
	isToggled,
	hasActiveOption,
	onToggleInsurance,
	isDisabled,
	isCalculated,
	tag,
	tagStatus,
	lowestPrice,
	price,
	isFree = false,
	priceBeforeDiscount,
	title,
	name,
	subTitle,
	subtexts,
	description,
	onMoreInfoClick,
	usps,
	preConditionForm,
	form,
	productDocument,
	className = '',
}) => {
	const { t } = useTranslation(['common']);
	return (
		<BaseCard isElevated={isToggled} enableEffects={false} className={cn(className, 'flex-col')}>
			<div className='overflow-hidden p-16 md:p-24'>
				<ToggleHeader
					icon={icon}
					isToggled={isToggled}
					hasActiveOption={hasActiveOption}
					onToggleInsurance={onToggleInsurance}
					isDisabled={isDisabled}
					isCalculated={isCalculated}
					tag={tag}
					tagStatus={tagStatus}
					lowestPrice={lowestPrice}
					price={price}
					isFree={isFree}
					priceBeforeDiscount={priceBeforeDiscount}
					title={title}
					name={name}
					subTitle={subTitle}
					subtexts={subtexts}
				/>

				{description && (
					<Text variant='body-m' color='grayscale500' className='my-24'>
						{description}
					</Text>
				)}

				<InsuredBox title={t('components.productToggleCard.insured')} usps={usps} onMoreInfoClick={onMoreInfoClick} />

				<AnimatePresence initial={false}>
					{isToggled && (
						<motion.div
							key='content'
							initial='collapsed'
							animate='open'
							exit='collapsed'
							variants={{
								open: { height: 'auto' },
								collapsed: { height: 0 },
							}}
							transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}>
							<div className='relative'>
								<div className='h-4 w-full' />
								<Divider className='mt-24 hidden' />
								{preConditionForm && preConditionForm}
								<AnimatePresence initial={isCalculated ? true : false}>
									{isCalculated && form && (
										<motion.div
											style={{ overflow: 'hidden' }}
											initial={{ height: 0 }}
											animate={{ height: 'auto' }}
											transition={{ duration: 0.5 }}
											key={`form-of-${name}`}>
											{form}
										</motion.div>
									)}
								</AnimatePresence>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</div>

			<AnimatePresence initial={false}>
				{isToggled && <ToggleFooter productDocument={productDocument} />}
			</AnimatePresence>
		</BaseCard>
	);
};

import { FC } from 'react';

import { mockHeroWithVideo } from '@monorepo/mocks/marketing';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, CmsMedia } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { HeroWithVideo } from '@marketing/components';

export const handleSectionVideo = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextElement = elements.find((element) => element.identifier === 'title-subtext') as CmsElement;
	const title = getProperty('title', titleSubtextElement?.custom_fields);
	const subtext = getProperty('subtext', titleSubtextElement?.custom_fields);
	const videoElement = elements.find((element) => element.identifier === 'video') as CmsElement;
	const video = videoElement?.media.find(({ media_type }) => media_type === 'video') as CmsMedia;
	const thumbnail = videoElement?.media.find(({ media_type }) => media_type === 'image') as CmsMedia;

	return () => (
		<HeroWithVideo
			className='pt-48 md:pb-24'
			title={`${title}`}
			subtext={`${subtext}`}
			// TODO: Remove mock once test is updated with correct content
			video={video || mockHeroWithVideo.video}
			// TODO: Remove mock once test is updated with correct content
			thumbnail={thumbnail || mockHeroWithVideo.thumbnail}
		/>
	);
};

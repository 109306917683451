import { FirebaseApp } from '@firebase/app';
import { getPerformance } from 'firebase/performance';
import { FC, ReactNode, useEffect } from 'react';

import { useFirebase } from '../FirebaseProvider/FirebaseProvider';

type Props = {
	children: ReactNode;
};

export const FirebasePerformanceProvider: FC<Props> = ({ children }) => {
	const firebaseApp = useFirebase();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			// Check that this is running on the client
			(async () => {
				if (firebaseApp) {
					const performance = getPerformance(firebaseApp as FirebaseApp);
					performance.dataCollectionEnabled = true;
					performance.instrumentationEnabled = true;
				}
			})();
		}
	}, [firebaseApp]);

	return <>{children}</>;
};

import classNames from 'classnames';
import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { ColumnSection } from '@marketing/components';

/**
 * TODO: The idea is to make this dynamic, meaning the columns are
 * based on the amount of children received and what should be
 * rendered is based on the received children
 *
 * ! Important: Right now this component is only used on the maia.insure/landing/marketing/makelaars page
 * ! When this component will be used more often, we should make it more dynamic, right now it is only made for that page
 */
export const handleSectionColumn = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const titleSubtextEl = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const title = getProperty('title', titleSubtextEl.custom_fields);
	const subtext = getProperty('subtext', titleSubtextEl.custom_fields);

	const productCardGeneralSpecific = elements.find(
		({ identifier }) => identifier === 'product-card-general-specific'
	) as CmsElement;
	const ctaText = getProperty('cta_text', productCardGeneralSpecific.custom_fields);
	const pricePretext = getProperty('price_pretext', productCardGeneralSpecific.custom_fields);
	const priceSubtext = getProperty('price_subtext', productCardGeneralSpecific.custom_fields);
	const productGuid = getProperty('product_guid', productCardGeneralSpecific.custom_fields);

	return () => (
		<ColumnSection
			title={`${title}`}
			subTitle={`${subtext}`}
			productGuid={productGuid}
			ctaText={`${ctaText}`}
			pricePretext={`${pricePretext}`}
			priceSubtext={`${priceSubtext}`}
			className={classNames(rootContext?.path === 'landing/marketing/makelaars' && 'mt-48')}
		/>
	);
};

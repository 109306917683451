import type { QuestionPageData } from '@monorepo/types';

export const consumerPremiumPageMap = new Map<string, QuestionPageData>();
consumerPremiumPageMap
	.set('object-details', {
		slug: 'object-details',
		i18nKey: 'objectDetails',
	})
	.set('main-driver', {
		slug: 'main-driver',
		i18nKey: 'mainDriver',
	})
	.set('bought-in-netherlands', {
		slug: 'bought-in-netherlands',
		i18nKey: 'boughtInNetherlands',
	})
	.set('object-ownership', {
		slug: 'object-ownership',
		i18nKey: 'objectOwnership',
	})
	.set('driver-experience', {
		slug: 'driver-experience',
		i18nKey: 'driverExperience',
	})
	.set('consumer-affinitygroup', {
		slug: 'consumer-affinitygroup',
		i18nKey: 'consumerAffinityGroup',
	})
	.set('pet-characteristics', {
		slug: 'pet-characteristics',
		i18nKey: 'petCharacteristics',
	})
	.set('pet-name', {
		slug: 'pet-name',
		i18nKey: 'petName',
	})
	.set('pet-indoor-outdoor', {
		slug: 'pet-indoor-outdoor',
		i18nKey: 'petIndoorOutdoor',
	})
	.set('pet-location', {
		slug: 'pet-location',
		i18nKey: 'petLocation',
	})
	.set('pet-breed', {
		slug: 'pet-breed',
		i18nKey: 'petBreed',
	})
	.set('pet-size', {
		slug: 'pet-size',
		i18nKey: 'petSize',
	})
	.set('pet-neutered', {
		slug: 'pet-neutered',
		i18nKey: 'petNeutered',
	})
	.set('pet-sterilized', {
		slug: 'pet-sterilized',
		i18nKey: 'petSterilized',
	})
	.set('pet-chipped', {
		slug: 'pet-chipped',
		i18nKey: 'petChipped',
	});

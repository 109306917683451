import cn from 'classnames';
import { motion } from 'framer-motion';
import { FC } from 'react';

import { Tab } from '@monorepo/components/Headless/Tab/Tab';
import { Icon, Skeleton } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { themes } from '@monorepo/shared/lib/utils';

type TabbedOverviewHead = {
	icon: string;
	description: string;
};

type TabbedOverviewHeadProps = {
	items: TabbedOverviewHead[];
	isLoading?: boolean;
};

export const TabbedOverviewHead: FC<TabbedOverviewHeadProps> = ({ items, isLoading }) => {
	return (
		<div className='max-md:before:from-grayscale0 max-md:after:from-grayscale0 max-md:before:w-50 max-md:after:w-50 relative before:h-full after:h-full max-md:before:pointer-events-none max-md:before:absolute max-md:before:bottom-0 max-md:before:left-0 max-md:before:z-10 max-md:before:bg-gradient-to-r max-md:after:pointer-events-none max-md:after:absolute max-md:after:bottom-0 max-md:after:right-0 max-md:after:z-10 max-md:after:bg-gradient-to-l'>
			<Tab.Menu className='max-md:[&>*:first-child>div]:!ml-50 max-md:[&>*:last-child>div]:!mr-50 no-scrollbar flex snap-x snap-mandatory snap-always items-center justify-between space-x-40 overflow-x-auto'>
				{isLoading
					? [1, 2, 3, 4, 5].map((key) => (
							<div
								className='flex snap-center flex-col items-center space-y-16 md:space-y-24'
								key={`tabbed-overview-head-skeleton-${key}`}>
								<Skeleton variant='rounded' className='h-[44px] w-[44px]' />
								<Skeleton variant='text' size='body-l' className='w-[132px]' />
								<Skeleton variant='rounded' className='h-[6px] w-[170px] md:w-[170px]' />
							</div>
						))
					: items.map(({ icon, description }, key) => (
							<Tab.MenuItem
								key={`support-faq-section-tab-menu-item-${key}`}
								className='group flex snap-center flex-col items-center space-y-16 md:space-y-24'>
								{({ selected }) => (
									<>
										<div className='flex flex-col items-center space-y-12'>
											<Icon
												name={icon}
												size='xxxl'
												className={cn(
													selected &&
														'group-hover:-translate-y-8 group-hover:-rotate-6 group-active:-translate-y-8 group-active:-rotate-6',
													'transition'
												)}
											/>
											<Text variant='body-l' weight='medium' className='whitespace-nowrap'>
												{description}
											</Text>
										</div>
										<motion.div
											key={`activator-background-${key}`}
											animate={{
												backgroundColor: selected
													? themes['maia'].colors.secondaryMain
													: themes['maia'].colors.grayscale100,
											}}
											className='h-[6px] w-[170px] md:w-[170px]'
										/>
									</>
								)}
							</Tab.MenuItem>
						))}
			</Tab.Menu>
		</div>
	);
};

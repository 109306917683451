import cn from 'classnames';
import classNames from 'classnames';
import { FC } from 'react';

import { ThemeColors } from '@monorepo/types';

import { Text } from '../../../TextComponents';

export type StatusLabelStatus = 'success' | 'warning' | 'error';
export type StatusLabelSize = 'small' | 'large';
export type StatusLabelBg = `bg-${ThemeColors}`;

type StatusLabelProps = {
	text: string;
	status?: StatusLabelStatus;
	size?: StatusLabelSize;
	className?: string;
};

const StatusLabelClasses: {
	statusColor: Record<StatusLabelStatus, StatusLabelBg>;
	bgColor: Record<StatusLabelStatus, StatusLabelBg>;
	textColor: Record<StatusLabelStatus, ThemeColors>;
} = {
	statusColor: {
		success: 'bg-success300',
		warning: 'bg-warning300',
		error: 'bg-error300',
	},
	bgColor: {
		success: 'bg-success50',
		warning: 'bg-warning50',
		error: 'bg-error50',
	},
	textColor: {
		success: 'success400',
		warning: 'warning300',
		error: 'error300',
	},
};

export const StatusLabel: FC<StatusLabelProps> = ({ text, status = 'success', size = 'large', className = '' }) => {
	return (
		<div
			className={cn(
				className,
				StatusLabelClasses.bgColor[status],
				'rounded-12 flex w-fit items-center justify-center space-x-8 px-12 py-4'
			)}>
			<div className={classNames('h-[8px] w-[8px] rounded-full', StatusLabelClasses.statusColor[status])}></div>
			<Text
				as='span'
				variant={size === 'small' ? 'body-xxs' : 'body-xs'}
				weight='medium'
				color={StatusLabelClasses.textColor[status]}>
				{text}
			</Text>
		</div>
	);
};

import cn from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { FC, Fragment, useEffect, useState } from 'react';

import { BillingUnits, Bundle, CartItem, CartVehicle, ToggleButtonOption } from '@monorepo/types';

import { transformToCurrency } from '../../../../lib/utils';
import { ToggleButtonRow } from '../../../DataEntry';
import { Divider } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { Tag } from '../../TagsAndStatus';
import { BaseCard } from '../BaseCard/BaseCard';
import { SummaryCardFooter } from '../SummaryCardFooter/SummaryCardFooter';
import { SummaryCardItem } from '../SummaryCardItem/SummaryCardItem';
import { SummaryCardVehicle } from '../SummaryCardVehicle/SummaryCardVehicle';

type SummaryCardProps = (
	| { variant: 'compose' | 'summary' }
	| {
			variant: 'configure';
			onChangeDeductible: (deductible: string) => void;
			deductibles: ToggleButtonOption[];
			waterDamage?: string;
			isLoading?: boolean;
	  }
) & {
	items: CartItem[];
	vehicles?: ({ guid?: string } & CartVehicle)[];
	billingUnit?: BillingUnits;
	className?: string;
	activeBundle?: Bundle;
	chosenDeductible?: string;
	showDocumentName?: boolean;
	isB2B?: boolean;
};

export const SummaryCard: FC<SummaryCardProps> = ({ showDocumentName = true, isB2B = true, ...props }) => {
	const { t } = useTranslation(['common']);
	const hasItems = (props.items && props.items.length > 0) || props.variant === 'configure';
	const isElevated = hasItems && props.variant === 'compose';
	const [totalPrice, setTotalPrice] = useState<number>(0);
	const [totalTax, setTotalTax] = useState<number>(0);
	const [totalProvision, setTotalProvision] = useState<number>(0);

	useEffect(() => {
		if (
			props.variant === 'compose' ||
			props.variant === 'summary' ||
			(props.variant === 'configure' && !props.isLoading)
		) {
			setTotalPrice(props.items?.reduce((sum, { price }) => sum + price, 0));
			setTotalTax(props.items?.reduce((sum, { tax }) => sum + tax, 0));

			if (!isB2B) {
				setTotalProvision(props.items?.reduce((sum, { provision }) => sum + (provision || 0), 0));
			}
		}
	}, [props.items, props.variant]);

	return (
		<BaseCard
			as='div'
			isElevated={isElevated}
			enableEffects={false}
			className={cn(props?.className, 'flex-col overflow-hidden')}>
			{props.vehicles && props.vehicles?.length > 0 ? (
				<ul className='mb-16 flex flex-col gap-y-16 px-24 pt-16'>
					{props.vehicles.map((vehicle, vehicleIndex, allVehicles) => (
						<Fragment key={`vehicle-${vehicleIndex}`}>
							<SummaryCardVehicle vehicle={vehicle} />
							<Divider className='my-8' />
							{hasItems &&
								props.items
									.filter((item) => item.reference === vehicle.license && item.objectGuid === vehicle.guid)
									.map((item, index, all) => {
										return (
											<Fragment key={`vehicle-${vehicleIndex}-item-${item.guid}`}>
												<SummaryCardItem
													variant={props.variant === 'configure' ? 'configure' : 'compose'}
													billingUnit={props.billingUnit}
													hasBundle={typeof props.activeBundle !== 'undefined'}
													showDocumentName={showDocumentName}
													isB2B={isB2B}
													{...item}
												/>
												{!props.vehicles && index < all.length - 1 && props.variant === 'summary' && <Divider />}
											</Fragment>
										);
									})}
							{vehicleIndex !== allVehicles.length - 1 && props.variant === 'summary' && (
								// Somewhat dirty hack to make sure the divider is full width of the container
								<Divider className='my-16 -ml-32 w-screen' />
							)}
						</Fragment>
					))}
				</ul>
			) : (
				<ul className={cn(hasItems ? 'flex-col space-y-16 p-24' : 'items-center justify-center p-32 md:p-40', 'flex')}>
					{hasItems ? (
						props.items.map((item, index, all) => (
							<Fragment key={`summary-item-${index}`}>
								<SummaryCardItem
									variant={props.variant === 'configure' ? 'configure' : 'compose'}
									billingUnit={props.billingUnit}
									hasBundle={typeof props.activeBundle !== 'undefined'}
									showDocumentName={showDocumentName}
									isB2B={isB2B}
									{...item}
								/>
								{!props.vehicles && index < all.length - 1 && props.variant === 'summary' && <Divider />}
							</Fragment>
						))
					) : (
						<Text as='p' variant='body-m' color='grayscale400' className='text-center'>
							{t('components.summaryCard.noItems')}
						</Text>
					)}
				</ul>
			)}

			{props.variant === 'configure' && (
				<>
					<Divider className='px-24' />
					<div className='flex flex-col items-start justify-between p-24 max-md:space-y-12 md:flex-row md:items-center md:space-x-24'>
						<div className='space-y-4'>
							<Text variant='body-l' weight='semibold' color='grayscale600'>
								{t('components.summaryCard.chooseRisk')}
							</Text>
							<Text variant='body-s' weight='regular' color='grayscale300'>
								{t('components.summaryCard.riskDisclaimer')}
							</Text>
						</div>
						<ToggleButtonRow
							options={props.deductibles}
							name='summary-card-deductible'
							onChange={(deductible: string) => props.onChangeDeductible(deductible)}
							isDisabled={props.isLoading}
						/>
					</div>
				</>
			)}

			{props.variant === 'configure' && typeof props?.waterDamage !== 'undefined' && (
				<div className='px-24 pb-24'>
					<Text variant='body-xs' color='grayscale300' className='italic'>
						<Trans
							i18nKey='common.waterDamage'
							components={{
								Tag: <Tag className='!inline not-italic' text={transformToCurrency(props.waterDamage)} />,
							}}
						/>
					</Text>
				</div>
			)}

			{props.activeBundle && props.variant === 'summary' && (
				<>
					<Divider className='px-24' />

					<div className='flex items-center space-x-12 p-24'>
						<Text>{t('components.summaryCard.chosenRisk')}</Text>
						<Tag status='info' text={props.chosenDeductible} />
					</div>
				</>
			)}

			<SummaryCardFooter
				billingUnit={props.billingUnit}
				price={totalPrice}
				tax={totalTax}
				provision={totalProvision}
				isB2B={isB2B}
			/>
		</BaseCard>
	);
};

import { FieldValues } from 'react-hook-form';

import { FormBaseInput, FormBaseInputDefaultProps, FormBaseInputProps } from '../FormBaseInput/FormBaseInput';

export type FormHiddenInputProps<TFormValues extends FieldValues> = FormBaseInputDefaultProps<TFormValues> &
	Omit<FormBaseInputProps, 'name' | 'errors' | 'type'>;

export const FormHiddenInput = <TFormValues extends Record<string, unknown>>({
	name,
	register,
	rules,
	...props
}: FormHiddenInputProps<TFormValues>): JSX.Element => {
	return <FormBaseInput type='hidden' name={name} {...props} {...(register && register(name, rules))} />;
};

import { format, parseISO } from 'date-fns';
import { FC } from 'react';

import { Text } from '@monorepo/components/TextComponents';
import { transformToCurrency } from '@monorepo/shared/lib/utils';

type PensionExpectedResultsBoxProps = {
	expectedResult: number;
	totalWorth: number;
	retirementDate: string;
	totalInlay: number;
};

export const PensionExpectedResultsBox: FC<PensionExpectedResultsBoxProps> = ({
	expectedResult,
	retirementDate,
	totalInlay,
	totalWorth,
}) => {
	return (
		<div className='bg-grayscale50 rounded-18 h-full p-24'>
			<div className=''>
				<Text variant='body-s' weight='medium' color='grayscale600'>
					Verwacht resultaat
				</Text>

				<div className='mt-16 flex flex-col'>
					<div className='border-b-grayscale100 border-t-grayscale100 flex flex-row border-b border-t'>
						<Text variant='body-xs' weight='medium' color='grayscale600' className='flex-1 py-16'>
							Totaal vermogen
						</Text>
						<Text variant='body-xs' weight='medium' color='grayscale600' className='py-16'>
							{transformToCurrency(expectedResult)}
						</Text>
					</div>

					<div className='border-b-grayscale100 flex flex-row border-b'>
						<Text variant='body-xs' weight='medium' color='grayscale600' className='flex-1 py-16'>
							Uitkering per maand
						</Text>
						<Text variant='body-xs' weight='medium' color='grayscale600' className='py-16'>
							{transformToCurrency(totalWorth)}
						</Text>
					</div>

					<div className='border-b-grayscale100 flex flex-row border-b'>
						<Text variant='body-xs' color='grayscale600' className='flex-1 py-16'>
							Pensioendatum
						</Text>
						<Text variant='body-xs' color='grayscale600' className='py-16'>
							{retirementDate ? format(parseISO(retirementDate), 'dd-MM-yyyy') : ''}
						</Text>
					</div>

					<div className='flex flex-row'>
						<Text variant='body-xs' color='grayscale600' className='flex-1 py-16'>
							Totaal ingelegd
						</Text>
						<Text variant='body-xs' color='grayscale600' className='py-16'>
							{transformToCurrency(totalInlay)}
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
};

import classNames from 'classnames';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { FormBaseInputDefaultProps, FormBaseInputProps } from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormBigNumberInputProps<TFormValues extends FieldValues> = {
	hint?: string;
	hideError?: boolean;
	decimalScale?: number;
	control: Control<TFormValues>;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<FormBaseInputProps, 'name' | 'errors' | 'type'>;

export const FormBigNumberInput = <TFormValues extends Record<string, unknown>>({
	name,
	errors,
	hint,
	hideError,
	decimalScale = 2,
	placeholder,
	control,
	defaultValue,
	className,
	autoFocus,
	...props
}: FormBigNumberInputProps<TFormValues>): JSX.Element => {
	return (
		<FormHelper errors={errors} hint={hint} hideError={hideError}>
			{({}) => (
				<Controller
					control={control}
					defaultValue={defaultValue as PathValue<TFormValues, Path<TFormValues>>}
					name={name}
					{...props}
					render={({ field: { name, onChange, onBlur, value } }) => {
						return (
							<NumericFormat
								{...props}
								className={classNames(
									className,
									value ? 'caret-info300' : 'caret-grayscale100',
									// default
									'placeholder:text-grayscale100 text-grayscale600 border-none text-[60px] font-semibold placeholder:text-[60px] placeholder:font-semibold focus:ring-0 md:text-[100px] md:placeholder:text-[100px]',
									// hover
									'hover:enabled:caret-info300',
									// active
									'active:enabled:caret-info300',
									// focus
									'focus:enabled:caret-info300',
									// disabled
									'disabled:caret-grayscale100 disabled:text-grayscale100'
								)}
								decimalScale={decimalScale}
								decimalSeparator={','}
								thousandSeparator='.'
								id={name}
								autoComplete='off'
								placeholder={placeholder}
								name={name}
								autoFocus={autoFocus}
								prefix='€ '
								onValueChange={({ value }) => onChange(value)}
								onBlur={onBlur}
								allowNegative={false}
								defaultValue={`${value}`}
								value={`${value}`}
							/>
						);
					}}
				/>
			)}
		</FormHelper>
	);
};

import { IDetailInfoSection } from '@monorepo/types';

import { slugify } from '../../../../shared/src/lib/utils';

export const mockInsuranceDetailInfoSection: IDetailInfoSection = {
	title: 'Bevat de volgende dekkingen',
	subtext: 'Een aansprakelijkheidsverzekering, ook bekend als AOV beschermt jouw onderneming tegen claims zoals:',
	items: [
		{
			title: 'Publieke Aansprakelijkheid',
			description:
				'Beschermt je tegen claims voor persoonlijk letsel en schade aan andermans eigendom tijdens je zakelijke activiteiten.',
			image: {
				path: '/images/marketing/insurance-coverages.svg',
				alt: 'Alt text',
			},
			link: {
				href: '/aanvragen/bedrijf/zoeken',
				content: 'Bereken premie',
				title: 'Bereken premie',
			},
		},
		{
			title: 'Wettelijke aansprakelijkheid',
			description:
				'Onder deze polis valt de juridische verdediging en bepaalde boetes als je onverhoopt de wet overtreedt in je professionele hoedanigheid.',
			image: {
				path: '/images/marketing/insurance-coverages.svg',
				alt: 'Alt text',
			},
			link: {
				href: '/aanvragen/bedrijf/zoeken',
				content: 'Bereken premie',
				title: 'Bereken premie',
			},
		},
		{
			title: 'Werkgevers aansprakelijkheid',
			description:
				'Deze dekt je voor claims als een werknemer letsel oploopt dat niet door de ACC wordt afgehandeld, waardoor je bedrijf juridisch beschermd is.',
			image: {
				path: '/images/marketing/insurance-coverages.svg',
				alt: 'Alt text',
			},
			link: {
				href: '/aanvragen/bedrijf/zoeken',
				content: 'Bereken premie',
				title: 'Bereken premie',
			},
		},
	],
};

export const mockNicheDetailInfoSection: IDetailInfoSection = {
	title: 'Speciaal ontwikkeld voor',
	links: ['Haar & beauty', 'Vastgoed & taxatie', 'Bouw & aannemers', 'Afwerking & onderhoud'].map((value) => ({
		content: value,
		title: value,
		href: `/marketing/niches/${slugify(value).toLowerCase()}`,
	})),
	subtext:
		'Verzekeringen op maat gemaakt, in nauwe samenwerking met vakmensen en brancheverenigingen uit de haar & beauty sector. Omdat we geloven in het samen creëren van de beste bescherming voor jouw passie en vakmanschap.',
	items: [
		{
			title: 'Behandelingsschade',
			description:
				'Stel je voor, een klant heeft een allergische reactie op een haarverf of gezichtsbehandeling die jij hebt toegepast. Deze dekking zorgt ervoor dat de medische kosten en eventuele juridische kosten gedekt zijn.',
			image: {
				path: '/images/marketing/insurance-coverages.svg',
				alt: 'Alt text',
			},
			link: {
				href: '/aanvragen/bedrijf/zoeken',
				content: 'Bereken premie',
				title: 'Bereken premie',
			},
		},
		{
			title: 'Apparatuur en inventaris',
			description:
				'Je hoogwaardige föhn of gespecialiseerde behandelstoel gaat plotseling kapot of wordt gestolen. Met deze dekking ben je beschermd tegen de vervangingskosten van essentiële apparatuur en inventaris.',
			image: {
				path: '/images/marketing/insurance-coverages.svg',
				alt: 'Alt text',
			},
			link: {
				href: '/aanvragen/bedrijf/zoeken',
				content: 'Bereken premie',
				title: 'Bereken premie',
			},
		},
		{
			title: 'Bedrijfsonderbreking',
			description:
				'Door een onverwachte gebeurtenis, zoals waterschade in jouw salon, kun je tijdelijk niet werken. Deze dekking biedt financiële ondersteuning tijdens de periode dat je niet kan werken, zodat je snel weer op de been bent.',
			image: {
				path: '/images/marketing/insurance-coverages.svg',
				alt: 'Alt text',
			},
			link: {
				href: '/aanvragen/bedrijf/zoeken',
				content: 'Bereken premie',
				title: 'Bereken premie',
			},
		},
	],
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { PHONE_REGEX } from '../utils';

export const involvedPersonSchema = (t: TranslationType) =>
	z.object({
		id: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		first_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		last_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		phone: z
			.string({ required_error: t('validation.required') })
			.regex(PHONE_REGEX, t('validation.phone'))
			.optional()
			.or(z.literal('')),
	});

export type InvolvedPersonSchema = z.infer<ReturnType<typeof involvedPersonSchema>>;

import cn from 'classnames';
import { motion } from 'framer-motion';
import { FC, KeyboardEvent, ReactNode, useState } from 'react';

import { useAnalytics } from '../../../../../lib/hooks';
import { toSnakeCase } from '../../../../../lib/utils';
import { Text } from '../../../../TextComponents';
import { Icon } from '../../../Icon/Icon';

type FaqProps = {
	icon: string;
	question: string;
	answer: string | ReactNode;
};

const Faq: FC<FaqProps> = ({ icon, question, answer }) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const { trackEvent } = useAnalytics();

	const variants = {
		open: { opacity: 1, height: 'auto', marginTop: 16 },
		collapsed: { opacity: 0, height: 0 },
	};

	const handleToggle = () => {
		setIsExpanded((_) => !_);

		if (!isExpanded) {
			trackEvent('select_content', { content_type: 'landing_faq', item_id: toSnakeCase(question) });
		}
	};

	const keyDown = (e: KeyboardEvent) => {
		e.stopPropagation();
		if (e.key === 'Enter' || e.key === ' ') {
			handleToggle();
		}
	};

	return (
		<div
			role='button'
			tabIndex={0}
			className='border-primary100 flex select-none flex-col border-b py-16'
			onClick={handleToggle}
			onKeyDown={keyDown}>
			<div className={cn('flex w-full cursor-pointer flex-row items-start space-x-12')}>
				<Icon name={icon} size='lg' color='primaryMain' className='leading-none' />
				<div className='flex flex-col '>
					<Text as='p' variant='body-l'>
						{question}
					</Text>
				</div>
				<div className={cn(isExpanded && 'rotate-90', '!ml-auto transform transition-all duration-200')}>
					<Icon name='angle-right' size='lg' color='primaryMain' className='leading-none' />
				</div>
			</div>
			<motion.div
				initial='collapsed'
				animate={isExpanded ? 'open' : 'collapsed'}
				variants={variants}
				className='space-y-8 overflow-hidden'
				transition={{ duration: 0.2 }}>
				<Text as='p' variant='body-m'>
					{answer}
				</Text>
			</motion.div>
		</div>
	);
};

type LandingSectionFAQProps = {
	title: string;
	faqs: FaqProps[];
	className?: string;
};

export const LandingSectionFAQ: FC<LandingSectionFAQProps> = ({ title, faqs, className = '' }) => {
	return (
		<section
			className={cn(
				className,
				'col-span-full mx-auto my-24 flex w-full max-w-[1440px] flex-col space-y-12 sm:my-48 md:col-span-8 md:col-start-3 md:space-y-32 lg:col-span-12 lg:col-start-7'
			)}>
			<Text as='h2' variant='display-5' weight='semibold' className='text-center'>
				{title}
			</Text>
			<div className='flex flex-col'>
				{faqs.map((props, index) => (
					<Faq key={`landing-faq-${index}`} {...props} />
				))}
			</div>
		</section>
	);
};

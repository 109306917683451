import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { ActionButton } from '../../../../DataEntry';
import { Icon, ReadMore, Skeleton } from '../../../../Misc';
import { Text } from '../../../../TextComponents';
import { TagRow } from '../../../TagsAndStatus';

type DetailsLineProps = {
	title: ReactNode;
	icon?: string;
	content?: string | number | string[] | ReactNode;
	isTruncated?: boolean;
	isLoading?: boolean;
	maxLines?: number;
	onEdit?: () => void;
	onRemove?: () => void;
};

export const DetailsLine: FC<DetailsLineProps> = ({
	icon,
	title,
	content,
	maxLines = 2,
	isLoading = false,
	isTruncated = false,
	onEdit,
	onRemove,
}) => {
	return (
		<div className={classNames('flex items-baseline')}>
			{icon && (
				<Skeleton isLoading={isLoading} variant='circular' className='h-[16px] w-[16px]' containerClassName='pr-8'>
					<Icon size='sm' name={icon} color='primaryMain' className='mr-8' />
				</Skeleton>
			)}

			<div className='flex flex-1 flex-col lg:flex-row'>
				<Skeleton
					isLoading={isLoading}
					variant='text'
					size='body-xs'
					containerClassName={classNames(
						!content ? 'w-full' : onEdit || onRemove || isLoading ? 'lg:w-1/2' : 'lg:w-[calc(50%-16px)]'
					)}
					className='w-[150px]'>
					<Text variant='body-xs' weight='regular' color='grayscale500' className='whitespace-pre-line'>
						{title}
					</Text>
				</Skeleton>

				{content && (
					<div className='max-lg:pt-4 lg:w-1/2'>
						<Skeleton isLoading={isLoading} variant='rounded' className='h-[16px] w-[175px]'>
							{(typeof content === 'string' || typeof content === 'number') && !isTruncated && (
								<Text variant='body-xs' weight='medium' color='grayscale600'>
									{content}
								</Text>
							)}

							{(typeof content === 'string' || typeof content === 'number') && isTruncated && (
								<div className='flex flex-col items-start gap-y-8'>
									<ReadMore children={content.toString()} maxLines={maxLines} />
								</div>
							)}

							{typeof content === 'object' &&
								(Array.isArray(content) ? (
									<TagRow
										tagClassName='!whitespace-normal'
										tags={content.map((tag) => ({
											text: tag,
											status: 'default',
										}))}
									/>
								) : (
									content
								))}
						</Skeleton>
					</div>
				)}
			</div>

			{onEdit && !isLoading && <ActionButton icon='edit-alt' onClick={onEdit} />}
			{onRemove && !isLoading && <ActionButton icon='trash' onClick={onRemove} />}
		</div>
	);
};

import React from 'react';

export const capitalizeFirstLetter = (string: string): string => {
	return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const proseClassNames =
	'prose prose-p:m-0 prose-li:m-0 prose-p:typography-body-s prose-p:font-light prose-ul:m-0 prose-ul:px-2 prose-ul:list-disc prose prose-li:m-0 prose-li:p-0 prose-ul:mx-0 prose-ul:mb-0 prose-ul:mt-4 prose-ul:px-20 prose-ul:list-disc prose-p:text-grayscale600 prose-li:marker:text-inherit prose-p:text-body-s-desktop prose-ul:font-light prose-ul:text-grayscale600 prose-ul:text-body-s-desktop';

export const newsProseClassNames =
	'prose prose-li:m-0 prose-p:typography-body-m prose-p:font-light prose-ul:m-0 prose-ul:px-2 prose-ul:list-disc prose prose-li:m-0 prose-li:p-0 prose-ul:mx-0 prose-ul:mb-0 prose-ul:mt-4 prose-ul:px-20 prose-ul:list-disc prose-p:text-grayscale600 prose-li:marker:text-inherit prose-p:text-body-m-desktop prose-ul:font-light prose-ul:text-grayscale600 prose-ul:text-body-m-desktop';

export const anchorUnderlineClassName =
	'transition-all duration-300 text-grayscale300 hover:text-grayscale400 active:text-grayscale500';

export const anchorClassName = `${anchorUnderlineClassName} group flex flex-col`;
export const underlineClassName =
	'block h-[0.5px] max-w-0 bg-grayscale300 group-hover:bg-grayscale400 group-active:bg-grayscale500 transition-all duration-300 group-hover:max-w-full group-active:max-w-full';

export const stripHtml = (html: string): string => {
	return html.replaceAll(/<\/?[^>]+(>|$)/gi, '');
};

export const removeTrailingSlash = (str: string) => {
	return str.endsWith('/') ? str.slice(0, -1) : str;
};

interface Replacement {
	replace_this: string;
	replace_with: string | null;
}

interface TextReplacementProps {
	title: string;
	replacements: Replacement[];
}

export const replaceTextWithHighlight = ({ title, replacements }: TextReplacementProps) => {
	const regex = /\{\{(.*?)\}\}/g; // Regex to find {{key}} in the string
	const parts = title.split(regex); // Split the string into parts

	return (
		<>
			{parts.map((part, index) => {
				// Check if the part is a key that needs to be replaced
				const replacement = replacements.find((r) => r.replace_this === part);

				// If it's a key that needs to be replaced, render it as a span element
				if (replacement && replacement.replace_with) {
					return (
						<span key={index} className='text-success300'>
							{replacement.replace_with}
						</span>
					);
				}

				// Otherwise, render the text as is
				return <React.Fragment key={index}>{part}</React.Fragment>;
			})}
		</>
	);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const driverExperienceSchema = (t: TranslationType) =>
	z.object({
		'damage-free-years': z
			.string()
			.transform((value) => parseInt(value, 10))
			.refine((value) => !isNaN(value), {
				message: t('validation.number'),
			})
			.refine((value) => value >= -5, {
				message: t('validation.min', { min: -5 }),
			}),
		'km-year': z.string({
			required_error: t('validation.required'),
		}),
		'driving-certificate': z.enum(['J', 'N'], {
			required_error: t('validation.required'),
		}),
	});

export type DriverExperienceSchema = z.infer<ReturnType<typeof driverExperienceSchema>>;

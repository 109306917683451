export const mockProductsSectionProducts = {
	title: 'Jouw beroep verdient de beste bescherming.',
	description: 'Geen kleine lettertjes. Snel en helder je bedrijf verzekerd',
	variant: 'products',
	products: [],
};

export const mockProductsSectionBundles = {
	variant: 'bundle',
	title: 'Jouw beroep verdient de beste bescherming.',
	description: 'Geen kleine lettertjes. Snel en helder je bedrijf verzekerd',
	bundles: [],
};

import { isRejectedWithValue, type Middleware } from '@reduxjs/toolkit';

import { logger } from '@common/utils';

/**
 * Send warnings and errors for all api requests of the redux store
 */
export const logMiddleware: Middleware = () => (next) => async (action) => {
	if (isRejectedWithValue(action)) {
		const message = `${action.type.split('/')[0]}/${action.meta.arg.endpointName} - ${
			action.payload?.data?.error?.message || action.error.message
		}`;

		const url = action.meta.baseQueryMeta.request.url;

		const logEntry = {
			...(action.payload && { payload: JSON.stringify(action.payload) }),
			...(action.meta?.arg?.originalArgs && { arguments: JSON.stringify(action.meta.arg.originalArgs) }),
			url,
		};
		if (action.payload?.status >= 400 && action.payload?.status < 500) {
			logger.warn(message, logEntry);
		} else {
			logger.error(message, logEntry);
		}
	}

	return next(action);
};

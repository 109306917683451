import { SuperAdminPortalAccount } from '@monorepo/types';

import { rootSuperAdminApi } from '../../root/root.api';

export const cmsUsersApi = rootSuperAdminApi.injectEndpoints({
	endpoints: ({ query, mutation }) => ({
		listCmsUsers: query<SuperAdminPortalAccount[], void>({
			query() {
				return {
					url: `/cmsusers`,
					method: 'GET',
				};
			},
			providesTags: (result) => (result ? result.map(({ guid }) => ({ type: 'CmsUsers', id: guid })) : []),
		}),
		getCmsUser: query<SuperAdminPortalAccount, string>({
			query(guid) {
				return {
					url: `/cmsusers/${guid}`,
					method: 'GET',
				};
			},
			providesTags: (_, error, guid) => [{ type: 'CmsUsers', id: guid }],
		}),
		updateCmsUser: mutation<{ email: string }, { guid: string; phone?: string; email?: string }>({
			query({ guid, ...body }) {
				return {
					url: `/cmsusers/${guid}`,
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: (_, _err, { guid }) => [{ type: 'CmsUsers', id: guid }],
		}),
	}),
	overrideExisting: true,
});

export const { useListCmsUsersQuery, useGetCmsUserQuery, useUpdateCmsUserMutation } = cmsUsersApi;

export const { listCmsUsers, getCmsUser, updateCmsUser } = cmsUsersApi.endpoints;

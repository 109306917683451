import { Trans } from 'next-i18next';
import { FC } from 'react';

import { BillingUnits } from '@monorepo/types';

import { transformToCurrency } from '../../../../lib/utils';
import { Text } from '../../../TextComponents';

export type SummaryCardPriceProps = {
	price: number;
	priceBeforeDiscount?: number;
	priceAfterDiscount?: number;
	isFree?: boolean;
	billingUnit?: BillingUnits;
};

export const SummaryCardPrice: FC<SummaryCardPriceProps> = ({
	price,
	priceBeforeDiscount,
	priceAfterDiscount,
	billingUnit,
	isFree = priceAfterDiscount === 0,
}) => {
	return (
		<div className='flex flex-col space-y-4 md:text-right'>
			<Text
				variant='body-s'
				weight={isFree ? 'medium' : 'regular'}
				color={isFree ? 'success400' : 'grayscale400'}
				className='whitespace-nowrap'>
				<Trans
					i18nKey={
						isFree
							? 'common.freeYear'
							: billingUnit === 'monthly'
								? 'components.summaryCard.pricePerMonth'
								: 'components.summaryCard.pricePerYear'
					}
					tOptions={{ price: transformToCurrency(price) }}
					components={{
						amount: <span className='typography-body-s text-grayscale600 font-semibold' />,
					}}
				/>
			</Text>
			{isFree && (
				<Text variant='body-xs' color='grayscale400'>
					<Trans
						i18nKey={'components.productToggleCard.afterDiscount'}
						tOptions={{ price: transformToCurrency(priceBeforeDiscount) }}
						components={{
							price: <span className='text-grayscale600 typography-body-s font-semibold' />,
						}}
					/>
				</Text>
			)}
		</div>
	);
};

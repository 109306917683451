/**
 * Returns true if process is running in the browser
 */
export const isBrowser: boolean = typeof window !== 'undefined';

/**
 * Smoothscroll to a given element or to top if no id is given
 * @param id
 */
export const scrollToPosition = (id?: string) => {
	if (id) {
		document?.getElementById(id)?.scrollIntoView({
			behavior: 'smooth',
		});
	} else {
		window?.scrollTo({ top: 0, behavior: 'smooth' });
	}
};

import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { NotificationStatus, StaticNotification } from '@monorepo/components/DataDisplay';

import { HandlerProps } from '@cms/componentMapper/componentMapper';
import { mdComponents } from '../handleMarkDown/handleMarkDown';

export const handleStaticNotification = <P extends object>({ component }: HandlerProps): FC<P> => {
	const variant: NotificationStatus = component.custom_fields.find(({ key }) => key === 'type')
		?.value as NotificationStatus;

	return (props) => (
		<StaticNotification status={variant} {...props}>
			<ReactMarkdown components={mdComponents}>{`${component.markdown}`}</ReactMarkdown>
		</StaticNotification>
	);
};

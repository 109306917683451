import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

import { BaseCard } from '../../../../DataDisplay/Cards/BaseCard/BaseCard';

type Props = PropsWithChildren<{ className?: string }>;

// Render this list and put <Toggle /> components inside it as children
export const ToggleList: FC<Props> = ({ children, className = '' }) => {
	return (
		<BaseCard
			isElevated={false}
			enableEffects={false}
			className={cn(className, 'flex flex-col space-y-24 px-16 py-20 md:px-20')}>
			{children}
		</BaseCard>
	);
};

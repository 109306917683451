import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, DetailCtaVariants, ICtaSection } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { CtaSection } from '@marketing/components';

export const handleCtaTextLink = <P extends object>({ elements, identifier }: HandlerPropsV2): FC<P> => {
	const titleSubTextElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const UrlElement = elements.find(({ identifier }) => identifier === 'url') as CmsElement;

	const title = getProperty('title', titleSubTextElement.custom_fields);
	const subtext = getProperty('subtext', titleSubTextElement.custom_fields);

	const cta: DetailCtaVariants =
		UrlElement?.links?.length > 1 ? 'double-link' : identifier === 'cta-text-only' ? 'searchForm' : 'link';

	let ctaProps: ICtaSection = {} as ICtaSection;

	if (cta === 'double-link') {
		ctaProps = {
			cta,
			title: `${title}`,
			subtext: `${subtext}`,
			secondaryLink: UrlElement?.links[0],
			primaryLink: UrlElement?.links[1],
		};
	}

	if (cta === 'link') {
		ctaProps = {
			cta,
			title: `${title}`,
			subtext: `${subtext}`,
			link: UrlElement?.links[0],
		};
	}

	if (cta === 'searchForm') {
		ctaProps = {
			cta,
			title: `${title}`,
			subtext: `${subtext}`,
		};
	}

	return (props) => <CtaSection {...props} {...ctaProps} />;
};

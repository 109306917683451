export * from './Auth/SvgFunnelLogin';
export * from './Auth/SvgLogin';

export * from './Claims/SvgAddClaim';
export * from './Claims/SvgNewClaimSuccess';
export * from './Claims/SvgOpenClaimChat';

export * from './Funnel/SvgConclusion';
export * from './Funnel/SvgMotorCyclist';
export * from './Funnel/SvgPrecondition';
export * from './Funnel/SvgSplash';

export * from './Onboarding/SvgClaims';
export * from './Onboarding/SvgInsurances';
export * from './Onboarding/SvgInvoices';

export * from './Rocket/SvgRocket';

export * from './TrustpilotStar/TrustpilotStar';

export * from './WomenGivingThumbsUp/WomenGivingThumbsUp';

export * from './WomenLyingOnCouchWithPhone/WomenLyingOnCouchWithPhone';

export * from './WomenTinkeringWithRocket/WomenTinkeringWithRocket';

import { FC } from 'react';

import { ErrorPage } from '@common/components';
import { ErrorLayout } from '@common/layouts';

export const ErrorBoundaryFallback: FC = () => {
	return (
		<ErrorLayout>
			<ErrorPage error={500} />
		</ErrorLayout>
	);
};

import { AnimatePresence, motion, useInView } from 'framer-motion';
import React, { FC, useEffect, useRef, useState } from 'react';

type ReplacingWordsProps = {
	words: string[];
	transitionTime?: number;
};

const fadeInOut = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
	transition: { duration: 2 },
};

const DEFAULT_TRANSITION_TIME = 3000;

export const ReplacingWords: FC<ReplacingWordsProps> = ({ words, transitionTime = DEFAULT_TRANSITION_TIME }) => {
	const ref = useRef<HTMLDivElement>(null);
	const isVisible = useInView(ref);
	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (isVisible) {
			const interval = setInterval(() => {
				setIndex((prevIndex) => (prevIndex + 1) % words.length);
			}, transitionTime);

			return () => clearInterval(interval);
		}
	}, [isVisible]);

	return (
		<AnimatePresence mode='wait'>
			<div ref={ref}>
				<motion.span className='font-[inherit] text-[inherit]' key={words[index]} {...fadeInOut}>
					{words[index]}
				</motion.span>
			</div>
		</AnimatePresence>
	);
};

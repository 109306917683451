import classNames from 'classnames';
import { FC } from 'react';

import { mockWhyChooseMaia } from '@monorepo/mocks/marketing';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, IWhyChooseMaia } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { WhyChooseMaia } from '@marketing/components';

export const handleSectionChooseMaia = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const titleSubText = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const steps = elements.find(({ identifier }) => identifier === 'steps') as CmsElement;
	const title = getProperty('title', titleSubText.custom_fields);
	const description = getProperty('subtext', titleSubText.custom_fields);

	const items: IWhyChooseMaia['items'] = steps.child_elements.map((step) => ({
		text: `${getProperty('text', step.custom_fields)}`,
		// TODO: Remove mock once test is updated with correct content
		image: step.media?.[0] ? `${step.media?.[0].path}` : mockWhyChooseMaia.items[0].image,
		// TODO: Remove mock once test is updated with correct content
		alt: step.media?.[0] ? `${step.media?.[0].alt}` : mockWhyChooseMaia.items[0].alt,
	}));

	return (props) => (
		<WhyChooseMaia
			{...props}
			title={`${title}`}
			description={`${description}`}
			items={items}
			// TODO: Remove mock once test is updated with correct content
			mobileImage={items[0]?.image ? { image: items[0]?.image, alt: items[0]?.alt } : mockWhyChooseMaia.mobileImage}
			className={classNames(rootContext?.path === 'claims' && 'bg-primary50')}
		/>
	);
};

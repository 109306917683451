import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { transformToCurrency } from '../utils';

type MinMax = { min: number; max: number };

type PreconditionMinMax = Record<'gross-wage' | 'rebuild-value' | 'employees' | 'AANTTMW', MinMax>;

type Reference = keyof PreconditionMinMax;

const validateMinMax = (minMax: MinMax, value: number, path: Reference, ctx: z.RefinementCtx, t: TranslationType) => {
	if (value > minMax.max) {
		const max = path === 'gross-wage' || path === 'rebuild-value' ? transformToCurrency(minMax.max) : minMax.max;

		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: t('validation.max', { max }),
			path: ['answer'],
		});
	}
	if (value < minMax.min) {
		const min = path === 'gross-wage' || path === 'rebuild-value' ? transformToCurrency(minMax.min) : minMax.min;

		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: t('validation.min', { min }),
			path: ['answer'],
		});
	}
};

const preconditionAnswerSchema = z.object({
	guid: z.string(),
	reference: z.enum([
		'default',
		'gross-wage',
		'rebuild-value',
		'employees',
		'owners',
		'theft',
		'AANTTMW',
		'age',
		'retirement-age',
		'one-time-deposit',
		'monthly-deposit',
		'fte',
		'profession',
		'insured_amount',
		'waiting-term',
		'premium-indication',
		'date-of-birth',
	]),
	answer: z.string(),
});

export const preconditionSchema = (t: TranslationType, preconditionsMinMax: PreconditionMinMax) =>
	z.object({
		preconditions: z.array(
			preconditionAnswerSchema.superRefine((obj, ctx) => {
				const { reference, answer } = obj;
				const answerFloat = parseFloat(answer);

				if (reference in preconditionsMinMax) {
					validateMinMax(preconditionsMinMax[reference as Reference], answerFloat, reference as Reference, ctx, t);
				} else if ((reference === 'owners' || reference === 'theft') && (answerFloat < 1 || answer === '')) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t('validation.min', { min: 1 }),
						path: ['answer'],
					});
				}
			})
		),
	});

export type PreconditionSchema = z.infer<ReturnType<typeof preconditionSchema>>;

import classNames from 'classnames';
import { type InputHTMLAttributes } from 'react';
import { FieldValues } from 'react-hook-form';

import { Text } from '../../../TextComponents';
import { FormBaseInputDefaultProps } from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormChoiceProps<TFormValues extends FieldValues> = {
	label?: string;
	helperText?: string;
	hideError?: boolean;
	isChoice?: boolean;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<InputHTMLAttributes<HTMLInputElement>, 'aria-invalid' | 'className'>;

export const FormChoice = <TFormValues extends Record<string, unknown>>({
	name,
	errors,
	helperText,
	hideError,
	register,
	rules,
	...props
}: FormChoiceProps<TFormValues>): JSX.Element => (
	<FormHelper errors={errors} hint={helperText} hideError={hideError}>
		{({ isError }) => (
			<label
				className={classNames(
					'rounded-6 sm:rounded-18 border-grayscale200 flex flex-grow space-x-12 border p-12 sm:items-center sm:p-20',
					props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
				)}>
				<input
					id={name}
					name={name}
					aria-invalid={isError}
					type='radio'
					className={classNames(
						// Base
						'text-primary border-grayscale300 peer h-24 w-24 cursor-pointer appearance-none rounded-full transition-colors',
						// Disabled
						'disabled:border-grayscale200 disabled:bg-grayscale50 disabled:checked:bg-grayscale200 disabled:checked:border-grayscale200 disabled:cursor-not-allowed',
						// Hover
						'hover:border-primaryMain hover:bg-primary50 hover:checked:border-primary100 hover:checked:bg-primary600 hover:checked:border-4',
						// Focus
						'focus:border-primaryMain focus:bg-primary50 focus:checked:bg-primary600 focus:checked:border-primary100 focus:ring-0',
						// Active
						'active:border-primary200 active:text-primaryMain active:border-4',
						// Checked
						'checked:border-primary200 checked:bg-primaryMain checked:border-4'
					)}
					{...props}
					{...(register && register(name, rules))}
				/>
				<div className='text-grayscale500 peer-disabled:text-grayscale400 sm:flex-1'>
					<Text variant='body-m' as='div' color='inherit' className='flex cursor-pointer'>
						{props.label}
					</Text>
				</div>
			</label>
		)}
	</FormHelper>
);

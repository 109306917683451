import cn from 'classnames';
import { useInView } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC, useRef } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { MarketingCtaButton, StandardButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { ICtaSection } from '@monorepo/types';

import { paths } from '@common/utils';
import { CtaLicenseplate, CtaSearchFormSection } from '@marketing/components';
import { AnimatedSvgLine } from '@marketing/components/AnimatedSvgLine/AnimatedSvgLine';

type DetailCtaSectionProps = ICtaSection & {
	className?: string;
};

const Bold = (props: ReactMarkdownProps) => <strong {...props} className='text-secondaryMain font-medium' />;

const mdComponents: Components = {
	b: Bold,
	strong: Bold,
};

export const CtaSection: FC<DetailCtaSectionProps> = ({ title, subtext, className, ...props }) => {
	const { t } = useTranslation(['common']);
	const sectionRef = useRef<HTMLDivElement>(null);
	const isInView = useInView(sectionRef, { amount: 0.45, once: true });

	return (
		<section className={cn(className, 'bg-primaryMain py-104 relative overflow-hidden')} ref={sectionRef}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col items-center justify-center'>
					<AnimatedSvgLine
						inView={isInView}
						className='absolute -top-12 h-[100px] w-[200px] max-md:-left-64 md:-top-56 md:right-0 md:h-[200px] md:w-[400px]'
					/>
					<div className='z-10 flex flex-col space-y-24 text-center'>
						<Text variant='display-2' as='h3' weight='semibold' color='grayscale0'>
							<ReactMarkdown components={mdComponents}>{`${title}`}</ReactMarkdown>
						</Text>
						<Text variant='display-6' as='div' weight='medium' color='grayscale0'>
							<ReactMarkdown components={mdComponents}>{`${subtext}`}</ReactMarkdown>
						</Text>
					</div>
					<div
						className={cn(props.cta === 'searchForm' ? 'mt-48' : 'mt-24', 'flex w-full items-center justify-center')}>
						{props.cta === 'searchForm' ? (
							<CtaSearchFormSection className='bg-grayscale0' id={`detail-cta-section-form`} />
						) : props.cta === 'cta-licenseplate' ? (
							<CtaLicenseplate />
						) : props.cta === 'link' ? (
							<MarketingCtaButton link={props.link} className='w-fit' />
						) : props.cta === 'double-link' ? (
							<div className='flex flex-col gap-16 max-sm:w-full sm:flex-row md:gap-24'>
								<Link
									className='w-full md:w-fit'
									href={props.secondaryLink?.href || paths.funnel.company.search}
									title={props.secondaryLink?.title || t('common.calculatePremiumCta')}>
									<StandardButton
										className='w-full'
										variant='secondary'
										label={
											props.secondaryLink?.content || props.secondaryLink?.title || t('common.calculatePremiumCta')
										}
									/>
								</Link>
								<MarketingCtaButton link={props.primaryLink} className='w-full md:w-fit' />
							</div>
						) : null}
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

import { GroupedClassifications, OrganizationClassification } from '@monorepo/types';

import { sortAlphabetically } from '../sort/sort';

function addToGroup(grouped: GroupedClassifications, activity: OrganizationClassification) {
	const { code, description } = activity.group;
	if (!grouped[code]) {
		grouped[code] = {
			name: description,
			classifications: [],
		};
	}
	grouped[code].classifications.push(activity);
}

function addSuggestedGroup(grouped: GroupedClassifications, suggestedGroup: OrganizationClassification[]) {
	if (suggestedGroup.length > 0) {
		suggestedGroup.sort((a, b) => sortAlphabetically(a.name, b.name));
		grouped['suggested'] = {
			name: 'page.funnel.profile.activities.suggestion',
			classifications: suggestedGroup,
		};
	}
}

function sortGroupClassifications(grouped: GroupedClassifications) {
	Object.values(grouped).forEach((group) => {
		group.classifications.sort((a, b) => sortAlphabetically(a.name, b.name));
	});
}

function sortGroups(grouped: GroupedClassifications): GroupedClassifications {
	const sortedGrouped: GroupedClassifications = {};
	const groupKeys = Object.keys(grouped).sort();
	if (grouped['suggested']) {
		sortedGrouped['suggested'] = grouped['suggested'];
	}
	groupKeys.forEach((key) => {
		if (key !== 'suggested') {
			sortedGrouped[key] = grouped[key];
		}
	});
	return sortedGrouped;
}

function checkAndSetNameForSingleGroup(sortedGrouped: GroupedClassifications) {
	if (Object.keys(sortedGrouped).length === 1 && !sortedGrouped['suggested']) {
		const onlyKey = Object.keys(sortedGrouped)[0];
		sortedGrouped[onlyKey].name = 'page.funnel.profile.activities.all';
	}
}

export function groupClassifications(
	classifications: OrganizationClassification[],
	overrideSingleGroupName = false
): GroupedClassifications {
	const grouped: GroupedClassifications = {};
	const suggestedGroup: OrganizationClassification[] = [];

	classifications.forEach((activity) => {
		if (activity.suggested) {
			suggestedGroup.push(activity);
		} else {
			addToGroup(grouped, activity);
		}
	});

	sortGroupClassifications(grouped);
	addSuggestedGroup(grouped, suggestedGroup);
	const sortedGrouped = sortGroups(grouped);

	if (overrideSingleGroupName) {
		checkAndSetNameForSingleGroup(sortedGrouped);
	}

	return sortedGrouped;
}

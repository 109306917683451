import { sortAlphabetically } from '@monorepo/shared/lib/utils';
import { Product } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const transferableProductsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getTransferableProducts: builder.query<Product[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/transferable-products`,
					method: 'GET',
				};
			},
			transformResponse: (response: Product[]) => response.sort((a, b) => sortAlphabetically(`${a.name}`, `${b.name}`)),
			providesTags: (result) =>
				result ? result.map(({ guid }) => ({ type: 'TransferableProduct', id: guid })) : ['TransferableProduct'],
		}),
	}),
	overrideExisting: true,
});

export const { useGetTransferableProductsQuery } = transferableProductsApi;

export const { getTransferableProducts } = transferableProductsApi.endpoints;

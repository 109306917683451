import { Contact } from '@monorepo/types';

import { cartOrganizationApi } from '@funnel/store';
import { rootApi } from '../../../../common/store/root/root.api';

export const contactApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		setContact: builder.mutation<void, { cart_guid: string } & Contact>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/contact`,
					method: 'PUT',
					body: rest,
				};
			},
			// Update the cached cart in another api. If the query fails, undo the update.
			async onQueryStarted({ cart_guid, ...patch }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					cartOrganizationApi.util.updateQueryData('getCartOrganization', cart_guid, ({ contact }) => {
						Object.assign(contact, patch);
					})
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
	}),
	overrideExisting: true,
});

export const { useSetContactMutation } = contactApi;

export const { setContact } = contactApi.endpoints;

import { FC } from 'react';

import { UspRow } from '@monorepo/components/TextComponents';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';

export const handleSectionChecklist = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const checklistEle = elements.find((ele) => ele.identifier === 'lists_checklist') as CmsElement;
	const uspItems = checklistEle?.child_elements.filter(({ markdown }) => !!markdown).map((ele) => `${ele.markdown}`);

	return (props) => <UspRow {...props} className='mt-40' iconColor='success300' texts={uspItems} />;
};

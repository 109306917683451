import { AcceptanceQuestion, ClassificationContent } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export type IRecalculationAddress = {
	city: string;
	country: string;
	guid: string;
	house_number_appendix: string;
	number: number;
	postal_code: string;
	street: string;
};

type IGetRecalculationInsuranceAddressesResponse = {
	addresses: IRecalculationAddress[];
	product: string;
	guid: string;
}[];

type IGetRecalculationInsuranceAddressesRequest = {
	guid: string;
};

type IPutRecalculationInsuranceAddressResponse = unknown;

type IPutRecalculationInsuranceAddressRequest = {
	guid: string;
	insurance_guid: string;
	street: string;
	house_number: string | number;
	house_number_appendix?: string;
	postal_code: string;
	city: string;
};

export type IRecalculationPremiumQuestionAnswer = {
	input_type: number;
	number?: number;
	text?: string;
	multiple_choice?: string;
};

export type IRecalculationPremiumQuestion = {
	guid: string;
	reference: string;
	sequence: number;
	input_type: number;
	icon: string;
	response_options: {
		key: string;
		value: string;
	}[];
	copy: {
		phrasing: string;
		explanation?: string;
	};
	markdown: string;
	current_answer?: IRecalculationPremiumQuestionAnswer;
	new_answer?: IRecalculationPremiumQuestionAnswer;
};
type IGetRecalculationPremiumQuestionsResponse = IRecalculationPremiumQuestion[];

type IGetRecalculationPremiumQuestionsRequest = {
	guid: string;
};

type IPutRecalculationPremiumQuestionsResponse = unknown;

type IPutRecalculationPremiumQuestionsRequest = {
	guid: string;
	questions: {
		guid: string;
		answer: string;
	}[];
};

export type IRecalculationClassification = {
	guid: string;
	description: string;
};

type IGetRecalculationClassificationsResponse = IRecalculationClassification[];

type IGetRecalculationClassificationsRequest = {
	guid: string;
};

export type IRecalculationClassificationsNested = {
	guid: string;
	name: string;
	suggested: boolean;
	content: ClassificationContent[];
};

export type IRecalculationClassificationsNestedGroup = {
	guid: string;
	icon: string;
	name: string;
	children: Array<IRecalculationClassificationsNested>;
};

export type IRecalculationClassificationsNestedWorkfield = {
	guid: string;
	icon: string;
	name: string;
	children: Array<IRecalculationClassificationsNestedGroup>;
};
type IGetRecalculationClassificationsNestedResponse = Array<IRecalculationClassificationsNestedWorkfield>;

type IGetRecalculationClassificationsNestedRequest = {
	guid: string;
};

type IPutRecalculationClassificationsResponse = unknown;

type IPutRecalculationClassificationsRequest = {
	guid: string;
	classifications: Array<{ guid: string }>;
};

type IGetRecalculationAcceptanceQuestionsResponse = AcceptanceQuestion[];

type IGetRecalculationAcceptanceQuestionsRequest = {
	guid: string;
};

type IPutRecalculationAcceptanceQuestionsResponse = unknown;

type IPutRecalculationAcceptanceQuestionsRequest = {
	guid: string;
	questions: {
		guid: string;
		answer: string;
	}[];
};

type IRecalculationInsurancesProduct = {
	name: string;
	reference: string;
	icon: string;
};

type IRecalculationInsurances = {
	guid: string;
	status: string;
	name: string;
	icon_name: string;
	reference: string;
	product_price: {
		promotion: boolean;
		premium_before_discount: number;
		discount_total: number;
		premium_after_discount: number;
		tax: number;
		premium_after_tax: number;
	};
};
type IGetRecalculationInsurancesResponse = {
	product: IRecalculationInsurancesProduct;
	current_insurance: IRecalculationInsurances;
	recalculated_insurance: IRecalculationInsurances;
}[];

type IGetRecalculationInsurancesRequest = {
	guid: string;
};

type IGetRecalculationResponse = {
	guid: string;
	started_at: string;
	finished_at: null;
	status: 'accepted' | 'in_review';
	open_task: boolean;
};

type IGetRecalculationRequest = {
	guid: string;
};

type IPutRecalculationQuestionsResponse = unknown;

type IPutRecalculationQuestionsRequest = {
	guid: string;
};

type IPutRecalculationFinalizeResponse = unknown;

type IPutRecalculationFinalizeRequest = {
	guid: string;
	terms_agreement: boolean;
};

export const checkupApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getRecalculationInsuranceAddresses: builder.query<
			IGetRecalculationInsuranceAddressesResponse,
			IGetRecalculationInsuranceAddressesRequest
		>({
			query({ guid }) {
				return {
					url: `/recalculations/${guid}/insurance-addresses`,
					method: 'GET',
				};
			},
			providesTags: ['CheckupAddress'],
		}),
		putRecalculationInsuranceAddress: builder.mutation<
			IPutRecalculationInsuranceAddressResponse,
			IPutRecalculationInsuranceAddressRequest
		>({
			query({ guid, insurance_guid, ...address }) {
				return {
					url: `/recalculations/${guid}/insurance-addresses/${insurance_guid}`,
					method: 'PUT',
					body: { ...address },
				};
			},
			invalidatesTags: ['CheckupAddress'],
		}),
		getRecalculationPremiumQuestions: builder.query<
			IGetRecalculationPremiumQuestionsResponse,
			IGetRecalculationPremiumQuestionsRequest
		>({
			query({ guid }) {
				return {
					url: `/recalculations/${guid}/premium-questions`,
					method: 'GET',
				};
			},
			providesTags: ['PremiumQuestion'],
		}),
		putRecalculationPremiumQuestions: builder.mutation<
			IPutRecalculationPremiumQuestionsResponse,
			IPutRecalculationPremiumQuestionsRequest
		>({
			query({ guid, questions }) {
				return {
					url: `/recalculations/${guid}/premium-questions`,
					method: 'PUT',
					body: { questions },
				};
			},
			invalidatesTags: ['PremiumQuestion', 'Recalculation'],
		}),
		getRecalculationClassifications: builder.query<
			IGetRecalculationClassificationsResponse,
			IGetRecalculationClassificationsRequest
		>({
			query({ guid }) {
				return {
					url: `/recalculations/${guid}/classifications`,
					method: 'GET',
				};
			},
			providesTags: ['Classifications'],
		}),
		getRecalculationClassificationsNested: builder.query<
			IGetRecalculationClassificationsNestedResponse,
			IGetRecalculationClassificationsNestedRequest
		>({
			query({ guid }) {
				return {
					url: `/recalculations/${guid}/classifications/nested`,
					method: 'GET',
				};
			},
			providesTags: ['Classifications'],
		}),
		putRecalculationClassifications: builder.mutation<
			IPutRecalculationClassificationsResponse,
			IPutRecalculationClassificationsRequest
		>({
			query({ guid, classifications }) {
				return {
					url: `/recalculations/${guid}/classifications`,
					method: 'PUT',
					body: { classifications },
				};
			},
			invalidatesTags: ['Classifications'],
		}),
		getRecalculationAcceptanceQuestions: builder.query<
			IGetRecalculationAcceptanceQuestionsResponse,
			IGetRecalculationAcceptanceQuestionsRequest
		>({
			query({ guid }) {
				return {
					url: `/recalculations/${guid}/acceptance-questions`,
					method: 'GET',
				};
			},
			providesTags: ['AcceptanceQuestion'],
		}),
		putRecalculationAcceptanceQuestions: builder.mutation<
			IPutRecalculationAcceptanceQuestionsResponse,
			IPutRecalculationAcceptanceQuestionsRequest
		>({
			query({ guid, questions }) {
				return {
					url: `/recalculations/${guid}/acceptance-questions`,
					method: 'PUT',
					body: { questions },
				};
			},
			invalidatesTags: ['AcceptanceState', 'Recalculation'],
			async onQueryStarted({ guid, questions: patchedQuestions }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					checkupApi.util.updateQueryData('getRecalculationAcceptanceQuestions', { guid }, (existingQuestions) => {
						if (!existingQuestions) return [];

						return existingQuestions.map((question) => {
							const patchQuestion = patchedQuestions.find((pq) => pq.guid === question.guid);
							if (patchQuestion) {
								return {
									...question,
									is_answered: true,
									answer_readable: patchQuestion.answer === 'J' ? 'Ja' : 'Nee',
									answer_multiple_choice: {
										key: patchQuestion.answer === 'J' ? 'J' : 'N',
										value: patchQuestion.answer === 'J' ? 'Ja' : 'Nee',
									},
								};
							}
							return question;
						});
					})
				);

				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
		getRecalculationInsurances: builder.query<IGetRecalculationInsurancesResponse, IGetRecalculationInsurancesRequest>({
			query({ guid }) {
				return {
					url: `/recalculations/${guid}/insurances`,
					method: 'GET',
				};
			},
		}),
		getRecalculation: builder.query<IGetRecalculationResponse, IGetRecalculationRequest>({
			query({ guid }) {
				return {
					url: `/recalculations/${guid}`,
					method: 'GET',
				};
			},
			providesTags: ['Recalculation'],
		}),
		putRecalculationQuestions: builder.mutation<IPutRecalculationQuestionsResponse, IPutRecalculationQuestionsRequest>({
			query({ guid }) {
				return {
					url: `/recalculations/${guid}/questions`,
					method: 'PUT',
				};
			},
		}),
		putRecalculationFinalize: builder.mutation<IPutRecalculationFinalizeResponse, IPutRecalculationFinalizeRequest>({
			query({ guid, terms_agreement }) {
				return {
					url: `/recalculations/${guid}/finalize`,
					method: 'PUT',
					body: { terms_agreement },
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetRecalculationAcceptanceQuestionsQuery,
	useGetRecalculationClassificationsQuery,
	useGetRecalculationInsuranceAddressesQuery,
	useGetRecalculationInsurancesQuery,
	useGetRecalculationPremiumQuestionsQuery,
	useGetRecalculationQuery,
	usePutRecalculationAcceptanceQuestionsMutation,
	usePutRecalculationClassificationsMutation,
	usePutRecalculationInsuranceAddressMutation,
	usePutRecalculationPremiumQuestionsMutation,
	usePutRecalculationQuestionsMutation,
	useGetRecalculationClassificationsNestedQuery,
	usePutRecalculationFinalizeMutation,
} = checkupApi;

export const { getRecalculation } = checkupApi.endpoints;

import classNames from 'classnames';
import cn from 'classnames';
import { FC, HTMLAttributes, MouseEvent, useState } from 'react';

import { ButtonIcon, Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';

type Sizes = 'small' | 'large';

type Props = {
	company: string;
	location: string;
	cursorActive?: boolean;
	size?: Sizes;
} & Omit<HTMLAttributes<HTMLDivElement>, 'role'>;

export const SearchSuggestionCompany: FC<Props> = ({
	company,
	cursorActive,
	location,
	size = 'large',
	className = '',
	onClick,
	...props
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleClick = (e: MouseEvent<HTMLDivElement>) => {
		setIsLoading(true);
		onClick && onClick(e);
	};

	return (
		<div
			role='button'
			tabIndex={0}
			onClick={handleClick}
			className={classNames(
				className,
				cursorActive && 'bg-primary50',
				'hover:active:bg-primary50 bg-grayscale0 group group transition-colors',
				size === 'large' && 'p-16 sm:px-20 sm:py-16',
				size === 'small' && 'px-12 py-8'
			)}
			{...props}>
			<div className='flex items-baseline'>
				<div
					className={classNames(
						cursorActive && 'text-primaryMain',
						'text-grayscale300 group-hover:active:text-primaryMain transition-colors',
						size === 'large' && 'mr-12',
						size === 'small' && 'mr-8'
					)}>
					<Icon name='building' size={size === 'large' ? 'md' : 'sm'} color='inherit' />
				</div>
				<div className='flex w-full items-center space-x-8'>
					<div className='flex-grow'>
						<Text variant='body-l' weight='semibold' color='grayscale600'>
							{company}
						</Text>
						<Text
							variant='body-m'
							weight='regular'
							color='grayscale400'
							className={classNames(size === 'large' && 'mt-4')}>
							{location}
						</Text>
					</div>
					{size === 'large' && (
						<ButtonIcon
							className={cn(
								cursorActive && 'translate-x-8',
								'transition-transform delay-75 duration-300 group-hover:active:translate-x-8 group-active:translate-x-8'
							)}
							size='xl'
							mode='dark'
							icon='angle-right'
							isLoading={isLoading}
							iconColor='info300'
						/>
					)}
				</div>
			</div>
		</div>
	);
};

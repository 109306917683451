import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';

import { BaseModal } from '../ModalWrapper/ModalWrapper';

type Props = PropsWithChildren<{
	id: string;
	icon?: string;
	title: string | ReactElement;
	subtitle?: string | ReactElement;
	text?: string | ReactElement;
	closable?: boolean;
	cancelButton?: ReactNode;
	submitButton?: ReactNode;
	onClose?: () => void;
}>;

export const Modal: FC<Props> = ({ id, icon, title, subtitle, text, children, closable = true, ...rest }) => {
	return (
		<BaseModal
			className='w-[100vw] space-y-24 max-md:h-fit sm:max-w-[90vw] md:max-h-[70vh] md:max-w-[920px]'
			icon={icon}
			title={title}
			subTitle={subtitle}
			text={text}
			id={id}
			closable={closable}
			buttonsFullWidth={false}
			{...rest}>
			{children}
		</BaseModal>
	);
};

import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Tag } from '@monorepo/components/DataDisplay';
import { Form } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { useRenderFormControl } from '@monorepo/shared/lib/hooks';
import { CalculationProduct, CalculationProductOptionSpecWithGuid } from '@monorepo/types';

import { selectThemeContext } from '@common/store';
import { selectBillingUnit } from '@funnel/store';

type Props = {
	product: CalculationProduct;
	specs: CalculationProductOptionSpecWithGuid[][];
	onChangeOption: (guid: string) => void;
	showSubtext?: boolean;
};

export const ProductFormRadio: FC<Props> = ({ product, specs, showSubtext = true, onChangeOption }) => {
	const { t } = useTranslation(['common']);
	const { displaySalesChannel } = useSelector(selectThemeContext);
	const { getRadioTagPrice, getRadioLabel, getRadioSubText, activeOption } = useRenderFormControl(
		product,
		displaySalesChannel
	);

	const billingUnit = useSelector(selectBillingUnit);

	return (
		<div className='space-y-12'>
			<Text as='p' variant='body-s' weight='medium' color='grayscale500'>
				{t('components.productForm.insuredAmounts.formTitle', {
					insuredObject:
						product.name === 'Spullen-in-je-busverzekering' || product.name === 'Spullen-in-je-opslagverzekering'
							? 'spullen'
							: 'bedrijf',
				})}
			</Text>

			<Form.RadioList key={`formcontrol-formradio-${product.guid}`}>
				{specs.map((specArray, index2) => {
					const defaultSpec = specArray.find(
						(spec) => spec.insured_amount_type === 'case' || spec.insured_amount_type === 'default'
					);

					return (
						<Form.Radio
							key={`productform-radio-${defaultSpec?.option_guid}-${defaultSpec?.value}`}
							id={`productform-radio-${defaultSpec?.option_guid}-${defaultSpec?.value}`}
							label={getRadioLabel(specArray)}
							subtext={showSubtext ? getRadioSubText(specArray) : undefined}
							name={`${product.name}-${defaultSpec?.reference}`}
							value={defaultSpec?.value}
							defaultChecked={activeOption?.guid === defaultSpec?.option_guid}
							description={
								<Tag
									status={index2 === 0 ? 'success' : 'info'}
									text={index2 === 0 ? t('common.basic') : getRadioTagPrice(specArray, billingUnit)}
								/>
							}
							onChange={() => onChangeOption(`${defaultSpec?.option_guid}`)}
						/>
					);
				})}
			</Form.RadioList>
		</div>
	);
};

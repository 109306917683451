import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const petSizeSchema = (t: TranslationType) =>
	z.object({
		size: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
	});

export type PetSizeSchema = z.infer<ReturnType<typeof petSizeSchema>>;

import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from './../../TextComponents';

type ReadMoreProps = {
	children: string;
	maxLines?: number;
};

export const ReadMore: FC<ReadMoreProps> = ({ children, maxLines = 2 }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const textRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation(['common']);

	const toggleReadMore = () => {
		setIsExpanded((prevState) => !prevState);
	};

	useEffect(() => {
		const textElement = textRef.current;

		if (textElement) {
			const lineHeight = parseInt(getComputedStyle(textElement).lineHeight, 10);
			const maxHeight = lineHeight * maxLines;

			const resizeObserver = new ResizeObserver(() => setIsOverflowing(textElement.scrollHeight > maxHeight));

			resizeObserver.observe(textElement);

			setIsOverflowing(textElement.scrollHeight > maxHeight);

			return () => {
				resizeObserver.unobserve(textElement);
			};
		}
	}, [children, maxLines]);

	return (
		<div className='relative'>
			<div
				ref={textRef}
				className={`${!isExpanded ? (maxLines < 2 ? 'line-clamp-1' : 'line-clamp-2') : ''} whitespace-pre-line`}>
				{children}
			</div>
			{isOverflowing && (
				<Text as='button' variant='body-xs' weight='medium' color='info300' onClick={toggleReadMore}>
					{isExpanded ? t('common.showLess') : t('common.showMore')}
				</Text>
			)}
		</div>
	);
};

import classNames from 'classnames';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useEffect, useRef, useState } from 'react';

import { useIsMobile } from '../../../../../lib/hooks';
import { ActionButton } from '../../../../DataEntry';
import { Text } from '../../../../TextComponents';
import { TrustpilotStar } from '../../../Illustrations/TrustpilotStar/TrustpilotStar';

const overlayTransition: Variants = {
	collapsed: {
		opacity: 0,
	},
	open: {
		opacity: 0.3,
	},
};

const popoverTransition: Variants = {
	collapsed: {
		height: 0,
	},
	open: {
		height: 'auto',
		transition: {
			type: 'spring',
			stiffness: 150,
		},
	},
};

const contentTransition: Variants = {
	collapsed: {
		paddingBottom: '20px',
	},
	open: {
		paddingBottom: '40px',
		transition: {
			type: 'spring',
			stiffness: 150,
			repeat: Infinity,
			repeatType: 'mirror',
			repeatDelay: 0.3,
			delay: 5,
		},
	},
};

type TrustpilotPopupProps = {
	review_link: string;
};

export const TrustpilotPopup: FC<TrustpilotPopupProps> = ({ review_link }) => {
	const [isAnimatedOnce, setIsAnimatedOnce] = useState<boolean>(false);
	const [isFocusedOnce, setIsFocusedOnce] = useState<boolean>(false);
	const [lastHoveredRating, setLastHoveredRating] = useState<number>();
	const [selectedRating, setSelectedRating] = useState<number>(0);
	const [isToggled, setIsToggled] = useState<boolean>(false);
	const [isMounted, setIsMounted] = useState<boolean>(false);
	const PLAY_ATTENTION_ANIMATION_AFTER = 750;
	const { isMobile } = useIsMobile();
	const automaticOpenTime = useRef<NodeJS.Timeout>();

	const ratingBackgroundColorMap: Record<number, string> = {
		0: 'bg-grayscale200',
		1: 'bg-trustpilot-rating-1',
		2: 'bg-trustpilot-rating-2',
		3: 'bg-trustpilot-rating-3',
		4: 'bg-trustpilot-rating-4',
		5: 'bg-trustpilot-rating-5',
	};

	const getRatingColor = (rating: number, currentRating: number, hoverRating?: number): string => {
		if (typeof hoverRating === 'number') {
			return rating <= hoverRating ? ratingBackgroundColorMap[hoverRating] : ratingBackgroundColorMap[0];
		}

		return rating <= currentRating ? ratingBackgroundColorMap[currentRating] : ratingBackgroundColorMap[0];
	};

	useEffect(() => {
		setIsMounted(true);

		if (isMounted && !isMobile) {
			automaticOpenTime.current = setTimeout(() => {
				if (!isAnimatedOnce && !isMobile) {
					setIsToggled(() => true);
				}
			}, PLAY_ATTENTION_ANIMATION_AFTER);
		}
		return () => clearTimeout(automaticOpenTime.current);
	}, [isMounted, isMobile]);

	return (
		<>
			<AnimatePresence>
				{isToggled && isMobile && (
					<motion.div
						variants={overlayTransition}
						initial='collapsed'
						animate={isToggled ? 'open' : 'collapsed'}
						exit='collapsed'
						className='bg-grayscale600 fixed inset-0 z-10'
					/>
				)}
			</AnimatePresence>

			<div
				className='bg-grayscale0 shadow-2 rounded-t-16 relative z-20 w-full sm:min-w-[304px]'
				onMouseEnter={() => setIsFocusedOnce(true)}>
				<div className='flex items-center justify-between space-x-20 px-20 py-20'>
					<Text variant='body-m' weight='semibold' color='grayscale600'>
						Hoe vond je het gaan?
					</Text>
					<ActionButton
						icon={isToggled ? 'angle-down' : 'angle-up'}
						size='small'
						onClick={() => setIsToggled((_) => !_)}
					/>
				</div>

				<AnimatePresence>
					{isToggled && (
						<motion.div
							variants={popoverTransition}
							initial='collapsed'
							animate={isToggled ? 'open' : 'collapsed'}
							onAnimationComplete={() => setIsAnimatedOnce(true)}
							exit='collapsed'
							className='flex flex-col gap-y-20'>
							<div className='flex flex-col space-y-20 px-20'>
								<div className='flex flex-row justify-between' onMouseLeave={() => setLastHoveredRating(undefined)}>
									{[1, 2, 3, 4, 5].map((rating) => (
										<Link
											target='__blank'
											href={review_link}
											key={`trustpilot-star-${rating}`}
											onClick={() => setSelectedRating(rating)}
											className='custom-radio-label'
											onMouseEnter={() => {
												setLastHoveredRating(rating);
											}}>
											<div
												className={classNames(
													'flex h-40 w-40 min-w-40 items-center justify-center transition-all duration-500 ease-out',
													getRatingColor(rating, selectedRating, lastHoveredRating)
												)}>
												<TrustpilotStar theme='maia' />
											</div>
										</Link>
									))}
								</div>

								<div className='flex items-center justify-between'>
									<Text variant='body-s' weight='regular' color='grayscale600'>
										Slecht
									</Text>
									<Text variant='body-s' weight='regular' color='grayscale600'>
										Erg goed
									</Text>
								</div>
							</div>
							<motion.div
								variants={contentTransition}
								initial='collapsed'
								animate={isAnimatedOnce && !isFocusedOnce && !isMobile ? 'open' : 'collapsed'}
								exit='collapsed'
								className='flex items-center justify-center px-20 pb-20'>
								<div className='relative h-24 w-1/2'>
									<Image src='/images/trustpilot/logo.png' alt='Trustpilot logo' className='object-contain' fill />
								</div>
							</motion.div>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</>
	);
};

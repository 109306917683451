import classNames from 'classnames';
import { ComponentProps, forwardRef } from 'react';

import { Icon } from '@monorepo/shared/icon';

import { Tooltip } from '../tooltip/tooltip';
import { input, InputSizeVariants } from './variants';

type InputProps = {
	icon?: string;
	name: string;
	toolTip?: Pick<ComponentProps<typeof Tooltip>, 'title' | 'body' | 'side'>;
	size?: InputSizeVariants;
} & Omit<ComponentProps<'input'>, 'name' | 'size'>;

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
	const { icon, toolTip, disabled, className, size = 'base', ...rest } = props;

	return (
		<div className='relative'>
			{!!icon && (
				<Icon
					name={icon}
					aria-hidden
					className='text-grayscale300 pointer-events-none absolute flex h-48 w-48 items-center justify-center'
					data-disabled={disabled}
				/>
			)}
			<input
				{...rest}
				ref={ref}
				disabled={disabled}
				className={classNames(
					input({
						size,
						layout: !!icon
							? 'paddingStart'
							: !!toolTip
								? 'paddingEnd'
								: !!icon && !!toolTip
									? 'paddingInline'
									: undefined,
					}),
					className
				)}
			/>
			{!!toolTip && (
				<div className='text-grayscale300 pointer-events-none absolute right-0 top-0 flex h-48 w-48 items-center justify-center'>
					<Tooltip title={toolTip?.title} body={toolTip?.body} />
				</div>
			)}
		</div>
	);
});

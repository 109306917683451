// Rewrites the origin and pathname for a given url so it matches the origin and pathname of the user
export function rewriteUrlOriginAndPathname(urlToRewrite: string, lookupUrl: string) {
	const { origin: oldOrigin } = new URL(urlToRewrite);
	const { origin: newOrigin } = new URL(lookupUrl);

	if (oldOrigin !== newOrigin) {
		urlToRewrite = urlToRewrite.replace(oldOrigin, newOrigin);
	}

	return urlToRewrite;
}

export function getLastPartOfUrl(url: string, offset?: number): string | null {
	const urlObject = new URL(url, 'http://localhost'); // Adding a base URL to handle relative paths
	const pathSegments = urlObject.pathname.split('/').filter((segment) => segment !== '');
	if (pathSegments.length > 0) {
		if (typeof offset === 'number') {
			const partIndex = pathSegments.length - 1 - offset;

			if (partIndex >= 0) {
				return pathSegments[partIndex];
			}
		} else {
			return pathSegments[pathSegments.length - 1];
		}
	}

	return null;
}

export function addQueryParamsToString(url: string, queryParams: Record<string, string>): string {
	// Convert queryParams array into a query string
	const queryString = Object.entries(queryParams)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&');

	// Return the URL with the appended query string
	return url + '?' + queryString;
}

import { FC } from 'react';

import { Skeleton } from '../../../Misc';

export const MultipleProductCardSkeleton: FC = () => {
	return (
		<div className='rounded-18 shadow-2 space-y-20 p-24 md:space-y-24'>
			<div className='space-y-16'>
				<div className='space-y-8'>
					<Skeleton variant='text' size='display-5' className='w-[175px]' />
				</div>
				<Skeleton variant='text' size='display-3' className='w-[75px]' />
			</div>

			<Skeleton variant='text' size='body-m' className='w-full' />

			<Skeleton variant='rounded' className='h-[44px] w-full' />

			<div className='space-y-8'>
				<Skeleton variant='text' size='body-s' className='w-[75px]' />

				<div className='space-y-8'>
					<Skeleton variant='text' size='body-s' className='w-10/12' />
					<Skeleton variant='text' size='body-s' className='w-8/12' />
					<Skeleton variant='text' size='body-s' className='w-7/12' />
				</div>
			</div>
		</div>
	);
};

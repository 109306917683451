import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const acceptanceStatementSchema = (t: TranslationType) =>
	z.object({
		questions: z
			.array(
				z.object({
					guid: z.string().min(1, t('validation.required')),
					checked: z.enum(['J', 'N']),
				})
			)
			.min(1, t('validation.required')),
	});

export type AcceptanceStatementSchema = z.infer<ReturnType<typeof acceptanceStatementSchema>>;

export enum KeyboardKeys {
	Space = ' ',
	Enter = 'Enter',
	ArrowLeft = 'ArrowLeft',
	ArrowUp = 'ArrowUp',
	ArrowRight = 'ArrowRight',
	ArrowDown = 'ArrowDown',
	PageUp = 'PageUp',
	PageDown = 'PageDown',
}

import { FirebaseApp } from '@firebase/app';
import { FirebaseOptions, getApps, initializeApp } from 'firebase/app';
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';

export const firebaseConfig: FirebaseOptions = {
	apiKey: process.env.NEXT_PUBLIC_FB_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
	projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
	messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
	appId: process.env.NEXT_PUBLIC_FB_APP_ID,
	measurementId: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
};

export const FirebaseContext = createContext<FirebaseApp | null>(null);

export const useFirebase = () => {
	return useContext(FirebaseContext);
};

type Props = {
	children: ReactNode;
};

export const FirebaseProvider: FC<Props> = ({ children }) => {
	const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);

	useEffect(() => {
		if (!getApps().length) {
			const app: FirebaseApp = initializeApp(firebaseConfig);
			setFirebaseApp(app);
		}
	}, []);

	return <FirebaseContext.Provider value={firebaseApp}>{children}</FirebaseContext.Provider>;
};

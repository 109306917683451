import { z } from 'zod';

import { TranslationType } from '@monorepo/types'; // Adjust the import path as needed

import { isIban } from '../utils';

export const updateCompanyIbanSchema = (t: TranslationType) =>
	z.object({
		iban: z
			.string()
			.min(1, t('validation.required'))
			.refine((value) => isIban(value), { message: t('validation.format') })
			.transform((value) => value.toUpperCase()),
		terms_iban: z.boolean(),
	});

export type UpdateCompanyIbanSchema = z.infer<ReturnType<typeof updateCompanyIbanSchema>>;

import cn from 'classnames';
import { FC } from 'react';

import { ToggleButtonsRowProps } from '@monorepo/types';

import { ToggleButton } from '../ToggleButton';

export const ToggleButtonRow: FC<ToggleButtonsRowProps> = ({
	options,
	name,
	onChange,
	isDisabled = false,
	className = '',
	childClassName = '',
}) => {
	const handleChange = (value: string) => {
		onChange(value);
	};

	return (
		<div role='radiogroup' className={cn(className, !className.includes('flex') && 'flex')}>
			{options?.map((option, index) => {
				let position: 'first' | 'middle' | 'last' = 'middle';
				if (index === 0) position = 'first';
				else if (index === options.length - 1) position = 'last';

				return (
					<ToggleButton
						checked={option?.checked || false}
						key={`single-togglebutton-${option.value}`}
						name={name}
						id={`${name}-${option.value}`}
						label={option.label}
						value={option.value}
						position={position}
						onChange={() => handleChange(option.value)}
						icon={option?.icon}
						iconColor={option?.iconColor}
						isDisabled={isDisabled}
						className={childClassName}
					/>
				);
			})}
		</div>
	);
};

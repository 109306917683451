import classNames from 'classnames';
import { animate, AnimatePresence, motion, useMotionValue } from 'framer-motion';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { LayoutGrid, TrustpilotRating } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { getReviewsSortedByLength } from '@monorepo/shared/lib/utils';

import { useGetTrustpilotReviewsQuery } from '@common/store';
import { FindCompanyActionButton } from '../FindCompanyActionButton/FindCompanyActionButton';

export type TestimonialSingleSliderSectionProps = {
	className?: string;
};

export const TestimonialSingleSliderSection: FC<TestimonialSingleSliderSectionProps> = ({ className }) => {
	const { data } = useGetTrustpilotReviewsQuery({ stars: [4, 5] });

	const reviews = useMemo(() => {
		return data?.reviews && data.reviews.length > 0 ? getReviewsSortedByLength(data.reviews) : [];
	}, [data]);

	const x = useMotionValue(0);
	const containerRef = useRef<HTMLDivElement>(null);
	const [index, setIndex] = useState<number>(0);
	const isPreviousDisabled = index === 0;
	const isNextDisabled = index === reviews.length - 1;

	const calculateNewX = () => -index * (containerRef.current?.clientWidth || 0);

	const handleNext = () => {
		setIndex(index + 1 === reviews.length ? index : index + 1);
	};

	const handlePrevious = () => {
		setIndex(index - 1 < 0 ? 0 : index - 1);
	};

	const updateContainerWidth = () => {
		x.set(calculateNewX());
	};

	useEffect(() => {
		const controls = animate(x, calculateNewX());

		window.addEventListener('resize', updateContainerWidth);

		return () => {
			controls.stop();
			window.removeEventListener('resize', updateContainerWidth);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index]);

	return (
		<section className={classNames('bg-primary50 py-48 md:py-64', className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-row items-center justify-between'>
					<div>
						<FindCompanyActionButton icon='arrow-left' onClick={handlePrevious} disabled={isPreviousDisabled} />
					</div>
					<div
						className='before:from-primary50 after:from-primary50 relative flex w-full max-w-[750px] flex-row overflow-x-hidden before:absolute before:left-0 before:z-10 before:h-full before:w-12 before:bg-gradient-to-r after:absolute after:right-0 after:z-10 after:h-full after:w-12 after:bg-gradient-to-l md:before:w-20 md:after:w-20'
						ref={containerRef}>
						<AnimatePresence>
							{reviews.map((review, i) => (
								<motion.div
									className='flex-0 inline-flex w-full flex-col items-center justify-center gap-y-24 px-12 text-center md:px-20'
									style={{ x, left: `${i * 100}%`, right: `${i * 100}%` }}
									key={i}>
									<TrustpilotRating rating={review.stars} />
									<Text variant='display-6' weight='medium' color='primaryMain'>
										{`"${review.text}"`}
									</Text>
								</motion.div>
							))}
						</AnimatePresence>
					</div>
					<div>
						<FindCompanyActionButton icon='arrow-right' onClick={handleNext} disabled={isNextDisabled} />
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

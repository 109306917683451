import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IContentPageTitleSection } from '@monorepo/types';

type ContentPageTitleSectionProps = IContentPageTitleSection & {
	className?: string;
};

export const ContentPageTitleSection: FC<ContentPageTitleSectionProps> = ({ title, className }) => {
	return (
		<section className={cn(className, 'bg-primary50 py-32 md:py-64')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col'>
					<Text as='h1' color='primaryMain' weight='semibold' variant='display-3'>
						{title}
					</Text>
				</div>
			</LayoutGrid>
		</section>
	);
};

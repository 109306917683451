import { NavItem } from '@monorepo/types';

export const mockNavBarItems: NavItem[] = [
	{
		type: 'menuItem',
		value: 'Zakelijke verzekeringen',
		href: '/verzekeringen',
		sequence: 0,
		subItems: [
			{
				type: 'link',
				icon: 'sun',
				value: 'Aansprakelijkheidsverzekering',
				href: '/verzekeringen/aansprakelijkheid',
				subtext: 'Onze aansprakelijkheidsverzekering',
				sequence: 0,
			},
			{
				type: 'link',
				icon: 'list-ui-alt',
				value: 'Alle verzekeringen',
				href: '/verzekeringen',
				subtext: 'Bekijk alle verzekeringen',
				sequence: 1,
			},
		],
	},
	{
		type: 'menuItem',
		value: 'Werkvelden',
		href: '/werkvelden',
		sequence: 1,
		subItems: [
			{
				type: 'link',
				icon: 'sun',
				value: 'Haar & beauty',
				subtext: 'Alle informatie rond haar & beauty',
				href: '/werkvelden/haar-en-beauty',
				sequence: 0,
			},
			{
				type: 'link',
				icon: 'home',
				value: 'Vastgoed & taxatie',
				subtext: 'Alle informatie rond vastgoed & taxatie',
				href: '/werkvelden/vastgoed-en-taxatie',
				sequence: 1,
			},
			{
				type: 'link',
				icon: 'constructor',
				value: 'Bouw & aannemers',
				subtext: 'Alle informatie rond bouw & aannemers',
				href: '/werkvelden/bouw-en-aannemers',
				sequence: 2,
			},
			{
				type: 'link',
				icon: 'paint-tool',
				value: 'Afwerking & onderhoud',
				subtext: 'Alle informatie rond afwerking & onderhoud',
				href: '/werkvelden/afwerking-en-onderhoud',
				sequence: 4,
			},
			{
				type: 'link',
				icon: 'list-ui-alt',
				value: 'Alle werkvelden',
				href: '/werkvelden',
				subtext: 'Bekijk alle werkvelden',
				sequence: 5,
			},
		],
	},
	{
		type: 'link',
		value: 'Claims',
		href: '/claims',
		sequence: 2,
	},
	{
		type: 'link',
		value: 'Klantenservice',
		href: '/klantenservice',
		sequence: 3,
	},
	{
		type: 'link',
		value: 'Over ons',
		href: '/over-ons',
		sequence: 4,
	},
];

import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { ComponentProps } from 'react';

import { Radio } from '../../atoms';

type RadioGroupProps = {
	items: Array<ComponentProps<typeof Radio>>;
	size: ComponentProps<typeof Radio>['size'];
} & ComponentProps<typeof RadixRadioGroup.RadioGroup>;

export function RadioGroup(props: RadioGroupProps) {
	const { items, size, ...rest } = props;
	return (
		<RadixRadioGroup.RadioGroup className='flex flex-col gap-y-24' {...rest}>
			{items.map((item) => (
				<Radio {...item} size={size} />
			))}
		</RadixRadioGroup.RadioGroup>
	);
}

import { createContext, MutableRefObject, useContext } from 'react';

import { sortByDomNode } from '../../../lib/utils';

export type TabState = {
	selectedIndex: number;
	previousIndex: number;
	tabs: MutableRefObject<HTMLElement | null>[];
	panels: MutableRefObject<HTMLElement | null>[];
};

export enum TabActionTypes {
	SetSelectedIndex,
	RegisterTab,
	UnregisterTab,
	RegisterPanel,
	UnregisterPanel,
}

export type TabActions =
	| { type: TabActionTypes.SetSelectedIndex; index: number }
	| { type: TabActionTypes.RegisterTab; tab: MutableRefObject<HTMLElement | null> }
	| { type: TabActionTypes.UnregisterTab; tab: MutableRefObject<HTMLElement | null> }
	| { type: TabActionTypes.RegisterPanel; panel: MutableRefObject<HTMLElement | null> }
	| { type: TabActionTypes.UnregisterPanel; panel: MutableRefObject<HTMLElement | null> };

export const TabsContext = createContext<
	| ({
			orientation: 'horizontal' | 'vertical';
	  } & TabState)
	| null
>(null);

export const useData = (component: string) => {
	const context = useContext(TabsContext);

	if (context === null) {
		const err = new Error(`<${component} /> is missing a parent <Tab /> component.`);

		if (Error.captureStackTrace) Error.captureStackTrace(err, useData);

		throw err;
	}
	return context;
};

export type TabsContextData = ReturnType<typeof useData>;

export const TabsActionsContext = createContext<{
	registerTab: (tab: MutableRefObject<HTMLElement | null>) => void;
	registerPanel: (panel: MutableRefObject<HTMLElement | null>) => void;
	unregisterTab: (tab: MutableRefObject<HTMLElement | null>) => void;
	unregisterPanel: (panel: MutableRefObject<HTMLElement | null>) => void;
	change(index: number): void;
} | null>(null);

export const useActions = (component: string) => {
	const context = useContext(TabsActionsContext);

	if (context === null) {
		const err = new Error(`<${component} /> is missing a parent <Tab /> component.`);

		if (Error.captureStackTrace) Error.captureStackTrace(err, useActions);
		throw err;
	}

	return context;
};

export type TabsActionData = ReturnType<typeof useActions>;

export function tabReducer(state: TabState, action: TabActions): TabState {
	switch (action.type) {
		case TabActionTypes.RegisterTab:
			if (state.tabs.includes(action.tab) || action.tab.current === null) return state;
			const activeTab = state.tabs[state.selectedIndex];
			const adjustedTabs = sortByDomNode([...state.tabs, action.tab], (tab) => tab.current);

			let selectedIndex = adjustedTabs.indexOf(activeTab) ?? state.selectedIndex;

			if (selectedIndex === -1) selectedIndex = state.selectedIndex;

			return { ...state, tabs: adjustedTabs, selectedIndex };
		case TabActionTypes.RegisterPanel:
			if (state.panels.includes(action.panel) || action.panel.current === null) return state;

			return {
				...state,
				panels: sortByDomNode([...state.panels, action.panel], (panel) => panel.current),
			};
		case TabActionTypes.SetSelectedIndex: {
			const previousIndex = state.selectedIndex;
			const tabs = sortByDomNode(state.tabs, (tab) => tab.current);
			const panels = sortByDomNode(state.panels, (panel) => panel.current);

			const focusableTabs = tabs.filter((tab) => !tab.current?.hasAttribute('disabled'));

			const nextState: TabState = { ...state, tabs, panels, previousIndex };

			const before = tabs.slice(0, action.index);
			const after = tabs.slice(action.index);

			const next = [...after, ...before].find((tab) => focusableTabs.includes(tab));
			if (!next) return nextState;

			let selectedIndex = tabs.indexOf(next) ?? state.selectedIndex;

			if (selectedIndex === -1) selectedIndex = state.selectedIndex;

			return { ...nextState, selectedIndex };
		}
		case TabActionTypes.UnregisterTab: {
			return { ...state, tabs: state.tabs.filter((tab) => tab !== action.tab) };
		}
		case TabActionTypes.UnregisterPanel: {
			return { ...state, panels: state.panels.filter((panel) => panel !== action.panel) };
		}
		default:
			return state;
	}
}

import classNames from 'classnames';
import { animate, AnimatePresence, motion, useMotionValue } from 'framer-motion';
import Image from 'next/image';
import { FC, useEffect, useRef, useState } from 'react';

import { Icon, LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { ISchoutenSection } from '@monorepo/types';

import { FindCompanyActionButton } from '../FindCompanyActionButton/FindCompanyActionButton';

type SchoutenSectionProps = ISchoutenSection & {
	className?: string;
};

export const SchoutenSection: FC<SchoutenSectionProps> = ({
	title,
	images,
	subtitle,
	description,
	bulletList,
	logo,
	className,
}) => {
	const x = useMotionValue(0);
	const containerRef = useRef<HTMLDivElement>(null);
	const [index, setIndex] = useState<number>(0);
	const isPreviousDisabled = index === 0;
	const isNextDisabled = index === images.length - 1;

	const calculateNewX = () => -index * (containerRef.current?.clientWidth || 0);

	const handleNext = () => {
		setIndex(index + 1 === images.length ? index : index + 1);
	};

	const handlePrevious = () => {
		setIndex(index - 1 < 0 ? 0 : index - 1);
	};

	const updateContainerWidth = () => {
		x.set(calculateNewX());
	};

	useEffect(() => {
		const controls = animate(x, calculateNewX());

		window.addEventListener('resize', updateContainerWidth);

		return () => {
			controls.stop();
			window.removeEventListener('resize', updateContainerWidth);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index]);

	return (
		<section className={classNames(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 mb-48 mt-64'>
					<Text variant='display-2' weight='regular' color='primaryMain' className='text-center'>
						{title}
					</Text>
				</div>

				<div className='relative col-span-full h-[250px] w-full sm:col-start-2 sm:col-end-3 md:h-[450px] lg:h-[800px]'>
					<div
						className='max-md:rounded-t-16 relative flex h-full w-full flex-row overflow-x-hidden'
						ref={containerRef}>
						<AnimatePresence>
							{images.map(({ alt, path }, i) => (
								<motion.div
									className='flex-0 relative inline-flex w-full px-12 text-center md:px-20'
									style={{ x }}
									key={i}>
									<Image sizes={generateSizesString()} src={path} alt={alt} fill className='object-cover' />
								</motion.div>
							))}
						</AnimatePresence>
					</div>
					<FindCompanyActionButton
						className='absolute left-12 top-1/2 -translate-y-1/2 xl:left-0 xl:-translate-x-1/2'
						icon='arrow-left'
						onClick={handlePrevious}
						disabled={isPreviousDisabled}
					/>
					<FindCompanyActionButton
						className='absolute right-12 top-1/2 -translate-y-1/2 xl:right-0 xl:translate-x-1/2'
						icon='arrow-right'
						onClick={handleNext}
						disabled={isNextDisabled}
					/>
				</div>

				<div className='col-span-full grid grid-cols-12 pb-48 sm:col-start-2 sm:col-end-3'>
					<div className='bg-primary50 col-span-full flex flex-col space-y-24 p-48 md:col-span-8'>
						<Text variant='display-3' weight='semibold' color='primaryMain'>
							{subtitle}
						</Text>
						<Text variant='body-l' weight='regular' color='primaryMain'>
							{description}
						</Text>

						<ul className='flex flex-col space-y-12'>
							{bulletList.map((bulletPoint, key) => (
								<li className='flex flex-row items-center space-x-12' key={`bullet-point-${key}`}>
									<Icon name='check' color='success300' />
									<Text variant='body-l' weight='medium' color='primaryMain'>
										{bulletPoint}
									</Text>
								</li>
							))}
						</ul>
					</div>
					<div className='col-span-full flex items-center justify-center p-48 md:col-span-4'>
						<Image sizes={generateSizesString()} src={logo.path} alt={logo.alt} width={170} height={102} />
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

import { IDetailHeroLogoSection } from '@monorepo/types';

export const mockDetailHeroLogoSection: IDetailHeroLogoSection = {
	trustpilotImage: {
		path: '/images/marketing/trustpilot.png',
		alt: 'Trustpilot',
	},
	subtext: 'In samenwerking met',
	logos: [
		{
			path: '/images/marketing/nn.png',
			alt: 'Nationale Nederlanden',
		},
		{
			path: '/images/marketing/aig.png',
			alt: 'AIG',
		},
	],
};

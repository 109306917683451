import classNames from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { IFeatureImageHighlightSection } from '@monorepo/types';

export type FeatureImageHighlightSectionProps = IFeatureImageHighlightSection & {
	className?: string;
};

export const FeatureImageHighlightSection: FC<FeatureImageHighlightSectionProps> = ({
	title,
	description,
	image,
	className,
}) => {
	return (
		<section className={classNames(className)}>
			<LayoutGrid layout='marketing'>
				<div className='bg-primaryMain rounded-16 col-start-2 grid grid-cols-2 overflow-hidden'>
					<div className='rounded-16 relative col-span-full h-[300px] w-full overflow-hidden sm:col-span-1 sm:h-[450px] md:h-[600px]'>
						<Image
							sizes={generateSizesString()}
							src={image.path}
							alt={image.alt}
							className='object-cover object-center'
							fill
						/>
					</div>
					<div className='col-span-full flex flex-col items-center justify-center space-y-24 p-24 sm:col-span-1 sm:px-48'>
						<Text as='h2' variant='display-4' color='grayscale0' weight='semibold' className='max-sm:text-center'>
							{title}
						</Text>
						<Text as='h2' variant='body-l' color='grayscale0' weight='regular' className='max-sm:text-center'>
							{description}
						</Text>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

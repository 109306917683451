import { zodResolver } from '@hookform/resolvers/zod';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { type FC } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { StatementCard } from '@monorepo/components/DataDisplay';
import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { ButtonGroup } from '@monorepo/components/Misc';
import { AcceptanceStatementSchema, acceptanceStatementSchema } from '@monorepo/shared/lib/schemas';
import { isQuestionAnswered } from '@monorepo/shared/lib/utils';
import { Question } from '@monorepo/types';

import { selectNavigationState } from '@common/store';

type AcceptanceStatementFormProps = {
	id: string;
	questions: Question[];
	isLoading?: boolean;
	onSubmit: ({ questions }: AcceptanceStatementSchema) => void;
};

const list = {
	visible: {
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.15,
		},
	},
	hidden: {},
};

const item = {
	visible: { opacity: 1, x: 0 },
	hidden: { opacity: 0, x: -25 },
};

export const AcceptanceStatementForm: FC<AcceptanceStatementFormProps> = ({ id, questions, isLoading, onSubmit }) => {
	const { t } = useTranslation(['common']);
	const { isNavigating, navDirection } = useSelector(selectNavigationState);
	const { handleSubmit, control } = useForm<AcceptanceStatementSchema>({
		mode: 'onBlur',
		resolver: zodResolver(acceptanceStatementSchema(t)),
		defaultValues: {
			questions: [...questions].map((question) => ({
				guid: question.guid,
				checked:
					question?.is_answered || isQuestionAnswered(question)
						? question.answer_readable === 'Ja' ||
							(typeof question.answer_multiple_choice === 'string' && question.answer_multiple_choice === 'J')
							? 'J'
							: 'N'
						: 'N', // If the question is answered, set the value to the answer, otherwise set it to 'N' (opt-in)
			})),
		},
	});

	const { fields, update } = useFieldArray<AcceptanceStatementSchema>({
		name: 'questions',
		control,
	});

	return (
		<Form id={`${id}-form`} onSubmit={handleSubmit(onSubmit)}>
			<motion.div className='space-y-24' initial='hidden' animate='visible' variants={list}>
				{fields.map((field, key) => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const question = questions.find((question) => question.guid === field.guid);

					if (!question) return null;

					return (
						<motion.div key={`statement-card-${key}`} variants={item}>
							<StatementCard
								label={question.question}
								tooltips={question.tooltips}
								isToggled={field.checked === 'J'}
								onToggleChange={() => update(key, { guid: field.guid, checked: field.checked === 'J' ? 'N' : 'J' })}
								icon={question.icon_name}
							/>
						</motion.div>
					);
				})}
				<motion.div variants={item}>
					<ButtonGroup>
						<StandardButton
							type='submit'
							variant='cta'
							size='lg'
							label={t('common.proceed')}
							iconRight='angle-right'
							disabled={isLoading || isNavigating}
							isLoading={isLoading || (isNavigating && navDirection === 'forward')}
							data-testid='confirm'
						/>
					</ButtonGroup>
				</motion.div>
			</motion.div>
		</Form>
	);
};

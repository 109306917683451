import { BaseCard } from '@monorepo/components/DataDisplay/Cards/BaseCard/BaseCard';
import { Skeleton } from '@monorepo/components/Misc';

export const SmallProductCardSkeleton = () => {
	return (
		<BaseCard isElevated={false} className='flex flex-col space-y-16 p-24 md:space-y-20'>
			<div className='flex flex-row space-x-16'>
				<Skeleton containerClassName='w-1/4' variant='circular' className='h-[40px] w-full' />
				<Skeleton containerClassName='w-3/4' variant='rectangular' className='h-[40px] w-full' />
			</div>
			<div className='flex flex-row space-x-8'>
				<Skeleton containerClassName='w-1/3' variant='rectangular' className='h-[40px] w-full' />
				<Skeleton containerClassName='w-1/3' variant='rectangular' className='h-[40px] w-full' />
			</div>
			<div className='flex flex-col'>
				<Skeleton variant='rectangular' className='h-40 w-full' />
				<Skeleton variant='rectangular' className='h-16 w-full' />
			</div>
			<div>
				<Skeleton variant='rectangular' className='h-48 w-full' />
			</div>
		</BaseCard>
	);
};

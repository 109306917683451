import { Trans, useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';

import { BillingUnits } from '@monorepo/types';

import { transformToCurrency } from '../../../../lib/utils';
import { Divider, Icon } from '../../../Misc';
import { EmphasizedPriceFormat, Text } from '../../../TextComponents';
import { TooltipPopup } from '../../Tooltips';

type SummaryCardFooterProps = {
	price: number;
	tax: number;
	provision?: number;
	billingUnit?: BillingUnits;
	isB2B?: boolean;
	b2bLabel?: ReactNode;
};

export const SummaryCardFooter: FC<SummaryCardFooterProps> = ({
	price,
	tax,
	provision,
	billingUnit = 'monthly',
	isB2B = true,
	b2bLabel,
}) => {
	const { t } = useTranslation(['common']);

	return (
		<>
			<div className='bg-primary50 zigzag rounded-b-12 flex flex-col gap-y-4 px-20 py-24 pt-32'>
				{!isB2B && (
					<div className='flex flex-col gap-y-4'>
						<div className='flex flex-row items-center justify-between'>
							<Text variant='body-xs' weight='regular' color='grayscale400'>
								{t('common.insuranceTax')}
							</Text>
							<Text variant='body-xs' color='grayscale400'>
								<Trans
									i18nKey={'components.summaryCard.footer.pricePerMonth'}
									components={{ highlight: <Text as='span' variant='body-xs' color='grayscale400' weight='medium' /> }}
									tOptions={{ price: transformToCurrency(tax) }}
								/>
							</Text>
						</div>
						<div className='flex flex-row items-center justify-between'>
							<div data-tooltip-id='tooltip-provision' data-tooltip-place='bottom' className='cursor-pointer'>
								<TooltipPopup
									id='tooltip-provision'
									title={t('components.summaryCard.footer.provisionExplanation.title')}
									content={t('components.summaryCard.footer.provisionExplanation.description')}
								/>

								<div className='decoration-info300 flex flex-row items-center gap-x-4 underline'>
									<Text variant='body-xs' color='info300'>
										{t('components.summaryCard.footer.provision')}
									</Text>
									<Icon name='question-circle' size='sm' color='info300' />
								</div>
							</div>
							<Text variant='body-xs' color='grayscale400'>
								<Trans
									i18nKey={'components.summaryCard.footer.pricePerMonth'}
									components={{ highlight: <Text as='span' variant='body-xs' color='grayscale400' weight='medium' /> }}
									tOptions={{ price: transformToCurrency(provision) }}
								/>
							</Text>
						</div>
					</div>
				)}

				{!isB2B && <Divider className='mt-16 pb-12' />}

				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-col gap-y-4'>
						<Text variant='body-l' color='grayscale600' weight='medium'>
							{b2bLabel ? b2bLabel : t('components.summaryCard.footer.premiumPerMonth')}
						</Text>
						{isB2B && (
							<Text variant='body-xs' color='grayscale400'>
								{t('components.summaryCard.footer.inclTax', { tax: transformToCurrency(tax) })}
							</Text>
						)}
					</div>

					<EmphasizedPriceFormat
						textColor='grayscale600'
						price={price}
						afterText={t(billingUnit === 'monthly' ? 'common.perMonthShort' : 'common.perYearShort')}
					/>
				</div>
			</div>
		</>
	);
};

import { faker } from '@faker-js/faker/locale/nl';

import { Bundle, BundleMappedToProducts } from '@monorepo/types';

import { mockGetCalculationProduct } from './calculation-product.mock';

export function mockGetBundle(guid?: string): Bundle {
	return {
		code: guid ? guid : faker.string.alphanumeric(16),
		guid: faker.string.alphanumeric(16),
		name: faker.lorem.word(),
		sequence: faker.number.int(),
		minimum_price: faker.number.float(),
		is_active: faker.datatype.boolean(1),
		products: [],
	};
}

export function mockGetBundles(num = 3, guid?: string): Bundle[] {
	const bundles: Bundle[] = [];

	for (let i = 0; i < num; i++) {
		bundles.push(mockGetBundle(guid));
	}

	return bundles;
}

export function mockBundleMappedToProducts(): BundleMappedToProducts {
	return {
		...mockGetBundle(),
		mappedProducts: [
			mockGetCalculationProduct(),
			mockGetCalculationProduct(),
			mockGetCalculationProduct(),
			mockGetCalculationProduct(),
			mockGetCalculationProduct(),
		],
	};
}

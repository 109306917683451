import { cva, VariantProps } from 'class-variance-authority';

export const input = cva(
	[
		'border-grayscale200',
		'border',
		'hover:border-primaryMain',
		'focus:bg-primary50',
		'focus:border-primaryMain',
		'focus:ring-primaryMain',
		'focus:outline-1',
		'placeholder:font-light',
		'placeholder:text-grayscale200',
		'disabled:bg-grayscale50',
		'disabled:border-grayscale200',
	],
	{
		variants: {
			layout: {
				default: [],
				paddingStart: ['pl-48'],
				paddingEnd: ['pr-48'],
				paddingInline: ['pl-48', 'pr-48'],
			},
			size: {
				small: ['h-32', 'rounded-6', 'typography-bodyxss'],
				base: ['h-48', 'p-16', 'rounded-6'],
			},
		},
		defaultVariants: {
			layout: 'default',
			size: 'base',
		},
	}
);

export type InputSizeVariants = Exclude<VariantProps<typeof input>['size'], null | undefined>;

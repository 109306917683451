import cn from 'classnames';
import { FC } from 'react';

import { TextButton } from '../../../DataEntry';
import { IconButton } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { Tag } from '../../TagsAndStatus';
import { BaseCard } from '../BaseCard/BaseCard';

type Props = {
	title?: string;
	content: { title: string; text: string }[];
	onEditClick?: (param?: string) => void;
	footerButtonText?: string;
	footerButtonIcon?: string;
	onFooterClick?: () => void;
	className?: string;
};

export const ContentCard: FC<Props> = ({
	title,
	content,
	onEditClick,
	footerButtonText,
	footerButtonIcon,
	onFooterClick,
	className = '',
}) => {
	const showFooterButton = !!(footerButtonText && onFooterClick);

	return (
		<BaseCard
			className={cn(className, 'pointer-events-none flex flex-col space-y-20 p-24 pb-12 md:space-y-24 md:px-32')}>
			<div className='flex flex-row'>
				<Tag text={title} />
				{onEditClick && (
					<div className='ml-auto'>
						<IconButton
							title='edit'
							iconName='pen'
							onClick={() => onEditClick()}
							className='text-info300 hover:text-info400 active:text-info500 pointer-events-auto'
						/>
					</div>
				)}
			</div>
			<ul className='space-y-16'>
				{content.map(({ title, text }, index) => {
					return (
						<li
							key={`content-${index}`}
							className='flex flex-col items-start justify-center space-y-4 md:flex-row md:items-center'>
							<Text as='p' className='flex-1' variant='body-m' weight='semibold' color='grayscale600'>
								{title}
							</Text>
							<Text as='p' className='flex-1' variant='body-m' color='grayscale400'>
								{text}
							</Text>
						</li>
					);
				})}
			</ul>

			{showFooterButton && (
				<>
					<div className='before:border-grayscale100 group-active:before:border-grayscale200 mx-auto before:absolute before:left-0 before:w-full before:border-t before:transition-all before:duration-300' />
					<div style={{ marginTop: '12px' }} className='mx-auto'>
						<TextButton
							className='pointer-events-auto'
							variant='critical'
							onClick={onFooterClick}
							title={footerButtonText}
							label={footerButtonText}
							iconLeft={footerButtonIcon}
						/>
					</div>
				</>
			)}
		</BaseCard>
	);
};

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { formatError, logger, paths } from '@common/utils';
import { selectClassifications, useUpdateOrganizationClassificationsMutation } from '@funnel/store';
import { useQuestionPageManager } from '../useQuestionPageManager/useQuestionPageManager';

export const useSubmitActivities = () => {
	const [updateClassifications, updateClassificationsResponse] = useUpdateOrganizationClassificationsMutation();
	const { nextPage } = useQuestionPageManager({ isPremiumQuestions: true });
	const {
		push,
		query: { cartGuid },
	} = useRouter();

	const activities = useSelector(selectClassifications);

	const submitActivities = async () => {
		try {
			const response = await updateClassifications({
				cart_guid: `${cartGuid}`,
				classifications: activities.map((a) => ({ guid: a.guid })),
			}).unwrap();

			if (response.valid) {
				await push({
					pathname: `${nextPage}`,
					query: { cartGuid },
				});
			} else {
				await push({
					pathname: paths.funnel.finalize.request_contact,
					query: { cartGuid },
				});
			}
		} catch (error) {
			logger.error('Could not submit classifications', {
				error: formatError(error),
			});

			await push({
				pathname: paths.funnel.finalize.request_contact,
				query: { cartGuid },
			});
		}
	};

	return {
		submitActivities,
		isLoading: updateClassificationsResponse.isLoading,
	};
};

import cn from 'classnames';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

import { Tab } from '@monorepo/components/Headless/Tab/Tab';
import { LayoutGrid } from '@monorepo/components/Misc';
import { ISupportFaq } from '@monorepo/types';

import { SupportFaqCard } from '../SupportFaqCard/SupportFaqCard';
import { panelContentTransition, panelTransition, TabbedOverview } from '../TabbedOverview';

type SupportFaqProps = ISupportFaq & { className?: string };

export const SupportFaq: FC<SupportFaqProps> = ({ items, className }) => {
	return (
		<section
			className={cn(
				className,
				'before:bg-primary50 md:mb-104 relative mb-48 before:absolute before:left-0 before:right-0 before:top-0 before:-z-10 before:h-[250px]'
			)}>
			<LayoutGrid as='div' layout='marketing' gutter='48'>
				<TabbedOverview
					isLoading={false}
					menuLinks={items.map(({ category: { icon, text } }) => ({ icon, description: text }))}
					skeletonProps={{
						// TODO: Once this page is added, add a skeleton here
						skeleton: <></>,
					}}>
					{({ isAnimatedOnce }) => (
						<>
							{items.map(({ cards }, key) => (
								<Tab.Panel
									as={motion.div}
									variants={panelTransition}
									initial={isAnimatedOnce ? 'hidden' : 'show'}
									animate='show'
									className='grid grid-cols-2 gap-24 md:gap-48'
									key={`support-faq-section-tab-panel-${key}`}>
									{({ selected }) => (
										<AnimatePresence>
											{selected &&
												cards.map((card, key2) => (
													<motion.div
														key={`support-faq-section-tab-panel-${key}-content-${key2}`}
														variants={panelContentTransition}
														className={classNames(
															cards.length === key2 + 1 && (key2 + 1) % 2 !== 0
																? 'col-span-full'
																: 'col-span-full md:col-span-1'
														)}>
														<SupportFaqCard {...card} />
													</motion.div>
												))}
										</AnimatePresence>
									)}
								</Tab.Panel>
							))}
						</>
					)}
				</TabbedOverview>
			</LayoutGrid>
		</section>
	);
};

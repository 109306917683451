import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC } from 'react';

export type AnimatedSvgLineProps = {
	inView: boolean;
	strokeWidth?: number;
	className?: string;
};

export const AnimatedSvgLine: FC<AnimatedSvgLineProps> = ({ inView, strokeWidth = 18, className }) => {
	const transition = { duration: 1.5, ease: [0.17, 0.35, 0.83, 0.91] };

	return (
		<motion.svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 305 151' className={classNames(className)}>
			<motion.path
				d='M9.5 31C9.5 31 32.5044 66.984 38 68.5C52.5 72.5 73.2394 27.3697 84.5 33C87.5 34.5 73.7286 134.738 79.5 140C90.5 150.029 175.217 17.9234 185.5 28.5C203 46.5 193.549 86.1926 209 101C221 112.5 257.5 44 285 17C288.402 13.6603 295 10 295 10'
				fill='transparent'
				strokeWidth={inView ? strokeWidth : 0}
				stroke='#00E57E'
				strokeLinecap='round'
				initial={{ pathLength: 0 }}
				animate={{ pathLength: inView ? 1 : 0 }}
				transition={transition}
			/>
		</motion.svg>
	);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types'; // Adjust the import path as needed

export const revenueSchema = (t: TranslationType) =>
	z.object({
		revenue: z.coerce
			.number()
			.min(1, t('validation.min', { min: '€ 1' }))
			.max(999_999_999, t('validation.max', { max: '€ 999.999.999' }))
			.transform((value) => (typeof value === 'number' && !isNaN(value) ? value : NaN)),
	});

export type RevenueSchema = z.infer<ReturnType<typeof revenueSchema>>;

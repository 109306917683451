import { ITextImageSection } from '@monorepo/types';

export const mockTextImageSection1: ITextImageSection = {
	title: 'Straal vertrouwen en professionaliteit uit',
	content:
		'Klanten vertrouwen jou met hun uiterlijk en welzijn. Door verzekerd te zijn, laat je zien dat je hun vertrouwen serieus neemt en voorbereid bent op alle eventualiteiten. Dit kan klanten geruststellen en jouw reputatie versterken.',
	image: {
		path: '/images/marketing/professional.png',
		alt: 'Image',
	},
	imagePosition: 'right',
};

export const mockTextImageSection2: ITextImageSection = {
	title: "Bescherming tegen specialistische risico's",
	content:
		"In de haar & beauty wereld zijn er unieke risico's, zoals allergieën en apparatuurschade. Met een op maat gemaakte verzekering van Maia Insure ben je gedekt, zodat jij je kunt focussen op wat je het beste doet: stralende diensten leveren.",
	image: {
		path: '/images/marketing/quality.png',
		alt: 'Image',
	},
	imagePosition: 'left',
	link: {
		content: 'Bereken premie',
		title: 'Bereken premie',
		href: '/aanvragen/bedrijf/zoeken',
	},
};

import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

import { Text } from '@monorepo/shared/typography';

import { radio, radioIndicator, RadioVariantProps } from './variants';

type RadioProps = {
	label: string;
	size?: RadioVariantProps['size'];
} & ComponentProps<typeof RadixRadioGroup.Item>;

export function Radio(props: RadioProps) {
	const { label, id, size, hidden, ...rest } = props;

	return (
		<Text
			className={twMerge('flex cursor-pointer items-center gap-x-12', hidden && 'sr-only')}
			as='label'
			htmlFor={id}
			variant='body-m'
			color='grayscale500'
			weight='regular'
			hidden={hidden}>
			<RadixRadioGroup.Item id={id} {...rest} className={radio({ size })} title={label} hidden={hidden}>
				<RadixRadioGroup.RadioGroupIndicator className={radioIndicator({ size })} />
			</RadixRadioGroup.Item>
			<span>{label}</span>
		</Text>
	);
}

import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { StandardButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';

import { Page } from '@common/components';

type ErrorPageProps = {
	error: 404 | 500;
	className?: string;
};

export const ErrorPage: FC<ErrorPageProps> = ({ error, className }) => {
	const { locale } = useRouter();
	const { t } = useTranslation(['common']);

	return (
		<Page title={t(`page.${error}.seo.title`)}>
			<div className={cn(className, 'h-full')}>
				<LayoutGrid layout='marketing'>
					<div className='col-start-2 flex flex-col items-center justify-center max-md:grow max-md:pb-96 md:my-auto md:flex-row md:justify-start md:space-x-16'>
						<div>
							<div className='relative h-[250px] w-[280px] md:h-[430px] md:w-[493px]'>
								<Image src={`/images/${error}.svg`} fill alt={t(`page.${error}.seo.title`)} />
							</div>
							<div className='flex flex-col space-y-12 md:space-y-24 md:px-96'>
								<div className='flex w-fit flex-col items-start space-y-8'>
									<Text as='h1' color='primaryMain' variant='display-5' weight='semibold'>
										{t(`page.${error}.title`)}
									</Text>
									<Text as='p' color='grayscale400' variant='body-m'>
										{t(`page.${error}.subTitle`)}
									</Text>
								</div>
								<div className='flex w-full justify-center text-center md:justify-start'>
									<Link className='w-fit' locale={locale} href='/' title={t(`page.${error}.buttonText`)}>
										<StandardButton size='sm' variant='cta' label={t(`page.${error}.buttonText`)} />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</LayoutGrid>
			</div>
		</Page>
	);
};

import cn from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StatusCircle } from '@monorepo/components/DataDisplay';
import { ActionButton, StandardButton } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';

import { selectThemeContext } from '@common/store';
import { closeSupportOptions, openSupportChat, selectChatIsOnline } from '@support/store';

const containerVariants = {
	initial: {
		opacity: 0,
		y: -50,
	},
	animate: {
		opacity: 1,
		y: 0,
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.1,
			staggerDirection: -1,
		},
	},
	exit: {
		opacity: 0,
		y: -50,
		transition: {
			when: 'afterChildren',
			staggerChildren: 0.1,
			staggerDirection: 1,
		},
	},
};

const itemVariants = {
	initial: { opacity: 0, y: -50 },
	animate: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: 50 },
};

type Props = {
	alignment?: 'start' | 'end';
};

export const ChatBubble: FC<Props> = ({ alignment = 'start' }) => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();
	const { phoneNumber, phoneNumberLink } = useSelector(selectThemeContext);
	const isOnline = useSelector(selectChatIsOnline);

	const handleCloseSupportOptions = () => {
		dispatch(closeSupportOptions());
	};

	const handleOpenChat = () => {
		dispatch(openSupportChat());
	};

	return (
		<motion.div
			className='relative z-50 flex flex-col gap-12'
			variants={containerVariants}
			initial='initial'
			animate='animate'
			exit='exit'>
			<motion.div variants={itemVariants}>
				<ActionButton
					className={cn(alignment == 'end' && 'ml-auto')}
					name='close_chat'
					title={t('components.chat.closeChatTitle')}
					icon='times'
					onClick={handleCloseSupportOptions}
				/>
			</motion.div>
			<motion.div
				variants={itemVariants}
				className='shadow-1 bg-grayscale0 rounded-6 rounded-tl-16 flex flex-row gap-12 overflow-hidden p-12'>
				<div>
					<div className='relative'>
						<div className='bg-primary50 flex h-40 w-40 items-end overflow-hidden rounded-full'>
							<Image
								sizes={generateSizesString()}
								src='/images/support/operator.png'
								alt={t('components.chat.operatorImageAlt')}
								width={40}
								height={40}
								quality={100}
							/>
						</div>
						<div className='bg-grayscale0 shadow-1 absolute bottom-0 right-0 rounded-full p-[2px]'>
							<StatusCircle status={isOnline ? 'success' : 'error'} />
						</div>
					</div>
				</div>
				<div className='flex flex-col gap-4'>
					<Text as='p' variant='body-m' weight='semibold' color='grayscale600'>
						{t('components.chat.title')}
					</Text>
					<Text as='span' variant='body-s' color='grayscale300'>
						{t('components.chat.subtext')}
					</Text>
				</div>
			</motion.div>
			<motion.div variants={itemVariants} className='flex flex-col gap-12 overflow-hidden sm:flex-row'>
				<div className='w-full md:w-1/2'>
					<Link
						className='w-full'
						href={phoneNumberLink}
						title={t('components.chat.supportByPhoneTitle', { phone: phoneNumber })}>
						<StandardButton
							className='w-full'
							name='open_phone_support'
							size='sm'
							label={t('components.chat.supportByPhone')}
						/>
					</Link>
				</div>
				<div className='w-full md:w-1/2'>
					<StandardButton
						onClick={handleOpenChat}
						className='w-full'
						name='open_chat_support'
						size='sm'
						title={t('components.chat.supportByChatTitle')}
						label={t('components.chat.supportByChat')}
					/>
				</div>
			</motion.div>
		</motion.div>
	);
};

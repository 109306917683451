import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { CheckItem, Text } from '@monorepo/components/TextComponents';
import { List } from '@monorepo/components/TextComponents/Lists/List/List';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { HeroWithScrollingImage } from '@marketing/components';

const ListItem = (props: ReactMarkdownProps) => (
	<CheckItem item={{ content: props.children, included: true }} color='grayscale0' {...props} />
);

const highlight = (props: ReactMarkdownProps) => (
	<Text as='span' variant='body-m' color='secondaryMain' weight='medium' {...props} />
);

const mdComponents: Components = {
	p: ListItem,
	strong: highlight,
	em: highlight,
};

export const handleSectionHeroImageScroll = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextEle = elements.find((ele) => ele.identifier === 'title-subtext') as CmsElement,
		checkListEle = elements.find((ele) => ele.identifier === 'lists_checklist') as CmsElement,
		linkOptionalSubtextEle = elements.find((ele) => ele.identifier === 'link-optional-subtext') as CmsElement,
		imagesEle = elements.find((ele) => ele.identifier === 'images') as CmsElement,
		checklist = checkListEle?.child_elements?.map(({ markdown }) => {
			return {
				markdown,
			};
		});

	const title = getProperty('title', titleSubtextEle.custom_fields);
	const subtext = getProperty('subtext', titleSubtextEle.custom_fields);

	const [link] = linkOptionalSubtextEle.links;

	const images =
		imagesEle.media
			?.filter((media) => !!media)
			.map((media) => ({
				src: `${media.path}`,
				alt: media.alt,
			})) || [];

	const Content = () => {
		return (
			<List>
				{checklist.map(({ markdown }, index) => (
					<ReactMarkdown
						key={`section-hero-markdown-${index}`}
						components={mdComponents}>{`${markdown}`}</ReactMarkdown>
				))}
			</List>
		);
	};

	return (props) => (
		<HeroWithScrollingImage
			{...props}
			content={<Content />}
			className='bg-primaryMain'
			link={link}
			images={images}
			title={`${title}`}
			subtext={subtext}
			tagline={`${linkOptionalSubtextEle.markdown}`}
		/>
	);
};

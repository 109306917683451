import { zodResolver } from '@hookform/resolvers/zod';
import { format, parse } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { StaticNotification } from '@monorepo/components/DataDisplay';
import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { M_DATE_FORMAT, today, transformToCurrency } from '@monorepo/shared/lib/utils';
import { CalculationPrecondition } from '@monorepo/types';

import { closeQuote } from '@funnel/store';
import {
	aovProductSchema,
	AovProductSchema,
} from '../../../../../../../packages/shared/src/lib/schemas/aov-product.schema';

type AOVFormProps = {
	preconditions: Array<CalculationPrecondition>;
	productGuid: string;
	isLoading?: boolean;
	onSubmit: (fields: AovProductSchema) => void;
};

export const aovInsuredAmounts = [
	{ userValue: 1_500, apiValue: '25500' },
	{ userValue: 2_000, apiValue: '38000' },
	{ userValue: 2_500, apiValue: '50000' },
	{ userValue: 3_000, apiValue: '62000' },
] as const;

export const AOVForm: FC<AOVFormProps> = ({ preconditions, productGuid, isLoading, onSubmit }) => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();
	const form = useForm<AovProductSchema>({
		mode: 'onBlur',
		resolver: zodResolver(aovProductSchema(t)),
		defaultValues: {
			duration: '60',
		},
	});

	useEffect(() => {
		if (preconditions.length > 0) {
			for (const { reference, answer } of preconditions) {
				if (typeof answer !== 'undefined' && answer !== null) {
					if (reference === 'date-of-birth') {
						// Parse the date string into a Date object
						const parsedDate = parse(answer, 'd-M-yyyy', new Date());

						// Format the date to 'dd-MM-yyyy'
						const formattedDate = format(parsedDate, 'dd-MM-yyyy');

						form.setValue('dateOfBirth', formattedDate, { shouldValidate: true });
					}
					if (reference === 'insured_amount') {
						form.setValue('insuredAmount', answer, { shouldValidate: true });
					}
					if (reference === 'waiting-term') form.setValue('remainingDays', answer, { shouldValidate: true });
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preconditions]);

	return (
		<Form className='grid grid-cols-1 gap-24' onSubmit={form.handleSubmit(onSubmit)}>
			<Form.Group>
				<Form.Label weight='medium'>{t('page.funnel.compose.products.aov.fields.birthdate.label')}</Form.Label>
				<Form.SimpleDateInput
					fieldSize='lg'
					startIcon='calendar-alt'
					autoComplete='bday'
					control={form.control}
					register={form.register}
					name='dateOfBirth'
					placeholder={t('page.funnel.compose.products.aov.fields.birthdate.placeholder')}
					errors={form.formState.errors?.dateOfBirth}
					max={`${today({ dateFormat: M_DATE_FORMAT, asDate: false })}`}
					disabled={isLoading}
					data-testid='aov-form-date-input'
				/>
			</Form.Group>

			<Form.Group>
				<Form.Label weight='medium'>{t('page.funnel.compose.products.aov.fields.insured_amount.label')}</Form.Label>

				<Form.RadioList>
					{[...aovInsuredAmounts].map(({ apiValue, userValue }) => (
						<Form.Radio
							key={`aov-form-insured-amount-${apiValue}`}
							id={`aov-form-insured-amount-${apiValue}`}
							onClick={() => form.setValue('insuredAmount', apiValue)}
							label={t('common.perMonthAmount', { amount: transformToCurrency(userValue) })}
							title={t('common.perMonthAmount', { amount: transformToCurrency(userValue) })}
							register={form.register}
							name='insuredAmount'
							data-testid={`aov-form-insured-amount-${userValue}`}
							value={apiValue}
							disabled={isLoading}
						/>
					))}
				</Form.RadioList>
			</Form.Group>

			<Form.Group>
				<Form.Label weight='medium'>
					{t('page.funnel.compose.products.aov.fields.days_without_benefits.label')}
				</Form.Label>
				<Form.Dropdown
					control={form.control}
					name='remainingDays'
					startIcon='euro'
					options={[
						{ label: '3 maanden', value: '90' },
						{ label: '6 maanden', value: '180' },
						{ label: '1 jaar', value: '365' },
					]}
					errors={form.formState.errors.remainingDays}
					className='z-30'
					placeholder={t('page.funnel.compose.products.aov.fields.days_without_benefits.placeholder')}
					disabled={isLoading}
					data-testid='aov-form-remaining-days-input'
				/>
			</Form.Group>

			<div className='col-span-full'>
				<StaticNotification status='info'>
					<Text variant='body-s' color='grayscale500' weight='regular'>
						<Trans
							i18nKey={'page.funnel.compose.products.aov.informationField'}
							components={{
								bold: <span className='inline-block font-semibold' />,
							}}
						/>
					</Text>
				</StaticNotification>
			</div>

			<div className='mt-24 flex w-full flex-col items-center justify-between gap-12 sm:flex-row'>
				<StandardButton
					type='button'
					variant='secondary'
					label={t('common.cancel')}
					className='order-2 w-full sm:order-1 sm:w-fit'
					disabled={isLoading}
					onClick={() => dispatch(closeQuote(productGuid))}
				/>

				<StandardButton
					type='submit'
					variant='cta'
					iconRight='angle-right'
					className='order-1 w-full sm:order-2 sm:w-fit'
					label={t('page.funnel.compose.products.aov.submitCta')}
					isLoading={isLoading}
					disabled={isLoading || !form.formState.isValid}
					data-testid='confirm-quote-Arbeidsongeschiktheidsverzekering'
				/>
			</div>
		</Form>
	);
};

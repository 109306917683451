import { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';

import { Input } from '../../atoms/input/input';
import { CheckboxList } from '../../molecules/checkboxList/checkboxList';
import { Field } from '../../molecules/form/form';
import { RadioGroup } from '../../molecules/radioGroup/radioGroup';
import { Select } from '../../molecules/select/select';
import { ToggleButtons } from '../../molecules/toggleButtons/toggleButton';
import { useFormUtils } from './hooks';

export type Input<InputName, InputProps> = { input: InputName } & InputProps;

export type InputMap =
	| Input<'input', ComponentProps<typeof Input>>
	| Input<'checkboxList', ComponentProps<typeof CheckboxList>>
	| Input<'yesno', Omit<ComponentProps<typeof ToggleButtons>, 'type' | 'default'>>
	| Input<'toggleButtons', Omit<ComponentProps<typeof ToggleButtons>, 'type' | 'default'>>
	| Input<'select', ComponentProps<typeof Select>>
	| Omit<Input<'radioGroup', ComponentProps<typeof RadioGroup>>, 'size'>;

type InputMapperProps = InputMap & Omit<ComponentProps<typeof Field>, 'input'>;

export function FormField(props: InputMapperProps) {
	const {
		formState: { errors },
	} = useFormContext();

	const message: ComponentProps<typeof Field>['message'] = !!errors[props.name]?.message
		? {
				type: 'error',
				content: errors[props.name]?.message as string,
			}
		: props.message
			? props.message
			: {
					type: 'hint',
					content: undefined,
				};

	return <Field name={props.name} label={props.label} input={<InputMapper {...props} />} message={message} />;
}

export function InputMapper(props: InputMapperProps) {
	const { input } = props;
	const { register } = useFormContext();
	const { onChangeSetArrayValue, onChangeSetSingleValue } = useFormUtils();

	switch (input) {
		case 'input':
			return <Input {...props} {...register(props.name)} />;
		case 'checkboxList':
			return (
				<CheckboxList
					items={props.items.map((item) => ({
						...item,
						...register(props.name),
						onCheckedChange: () => onChangeSetArrayValue(props.name, item.value as string),
					}))}
				/>
			);

		case 'yesno':
			return (
				<ToggleButtons
					type='single'
					{...register(props.name)}
					onValueChange={(value: string) => onChangeSetSingleValue(props.name, value)}
					{...props}
					items={[props.items[0], props.items[1]]}
				/>
			);
		case 'toggleButtons':
			return (
				<ToggleButtons
					type='single'
					{...register(props.name)}
					onValueChange={(value: string) => onChangeSetSingleValue(props.name, value)}
					{...props}
					intent='default'
				/>
			);
		case 'select':
			return (
				<Select
					{...register(props.name)}
					onValueChange={(value: string) => onChangeSetSingleValue(props.name, value)}
					{...props}
				/>
			);
		case 'radioGroup':
			return (
				<RadioGroup
					{...register(props.name)}
					size='default'
					onValueChange={(value: string) => onChangeSetSingleValue(props.name, value)}
					{...props}
				/>
			);
	}
}

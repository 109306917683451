import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';
import { cmsContainers } from '@monorepo/types';

export const rootCmsApi = createApi({
	reducerPath: 'cmsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			isTest || !isBrowser ? `${process.env.NEXT_PUBLIC_NOVULO_URL}${process.env.NEXT_PUBLIC_NOVULO_API_PATH}` : ''
		}/cms`,
	}),
	tagTypes: cmsContainers,
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: () => ({}),
});

import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';
import { Company, KvKProfileResponse, KvKSearchRequest, KvKSearchResponse } from '@monorepo/types';

export const kvkApi = createApi({
	reducerPath: 'kvkApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/kvk`,
	}),
	tagTypes: ['Companies'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		search: builder.query<KvKSearchResponse, KvKSearchRequest>({
			query({ q, page = 1, num = 10, type = '&type=hoofdvestiging&type=nevenvestiging' }) {
				return {
					url: '/search',
					method: 'GET',
					params: {
						q,
						page,
						num,
						type,
					},
				};
			},
			transformErrorResponse: (error: FetchBaseQueryError) => {
				/* istanbul ignore next */
				return {
					status: error.status,
					data: error.data,
				};
			},
			keepUnusedDataFor: 5,
		}),
		profile: builder.query<Company, string>({
			query(dossierNumber) {
				return {
					url: '/profile',
					method: 'GET',
					params: {
						q: dossierNumber,
					},
				};
			},
			transformResponse: ({ eersteHandelsnaam, kvkNummer, adressen }: KvKProfileResponse) => {
				const company: Company = {
					name: eersteHandelsnaam,
					dossierNumber: kvkNummer,
					address: {
						street: adressen[0]?.straatnaam ?? 'Postbus',
						house_number: adressen[0]?.huisnummer ?? adressen[0]?.postbusnummer,
						...(adressen[0]?.huisnummerToevoeging && {
							house_number_appendix: adressen[0]?.huisnummerToevoeging,
						}),
						postal_code: adressen[0]?.postcode,
						city: adressen[0]?.plaats,
						country: adressen[0]?.land,
					},
				};
				return company;
			},
			transformErrorResponse: (error: FetchBaseQueryError) => {
				/* istanbul ignore next */
				return {
					status: error.status,
					data: error.data,
				};
			},
		}),
	}),
});

export const { useSearchQuery, useLazyProfileQuery, useLazySearchQuery, useProfileQuery } = kvkApi;

export const { search, profile } = kvkApi.endpoints;

import { getCookie, setCookie } from 'cookies-next';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	applyTheme,
	getAffinityGroupfromSearchParams,
	getAffinityGroupFromUrl,
	getCreatedTheme,
	isAcceptance,
	isMocking,
	isProduction,
	SALES_CHANNEL_COOKIENAME,
} from '@monorepo/shared/lib/utils';
import { affinityGroups, NextPageWithLayout, ThemeNames } from '@monorepo/types';

import { selectTheme, setTheme } from '@common/store';

type Props = Record<string, unknown>;

export const withTheme = <P extends Props>(Component: NextPageWithLayout<P>): NextPageWithLayout<P> => {
	return (props: P): ReactElement | null => {
		const [isMounted, setIsMounted] = useState<boolean>(false);
		const theme = useSelector(selectTheme);
		const dispatch = useDispatch();

		const updateTheme = useCallback(
			async (theme: ThemeNames) => {
				const createdTheme = getCreatedTheme(theme);
				dispatch(setTheme(theme));
				await applyTheme(createdTheme);

				// When mocking is enabled, set the saleschannel in a cookie so MSW "knows" about the salesChannel
				if (isMocking || isAcceptance || !isProduction) {
					setCookie(SALES_CHANNEL_COOKIENAME, theme);
				}
			},
			[dispatch]
		);

		useEffect(() => {
			const changeTheme = async () => {
				if (!isMounted) {
					setIsMounted(true);

					if (isProduction) {
						await updateTheme(getAffinityGroupFromUrl(window.location.href));
					} else {
						const themeFromSearchParms = getAffinityGroupfromSearchParams(window.location.href);
						const themeFromCookies = getCookie(SALES_CHANNEL_COOKIENAME);

						if (themeFromSearchParms) {
							await updateTheme(themeFromSearchParms);
						} else if (themeFromCookies && affinityGroups.includes(getCookie(SALES_CHANNEL_COOKIENAME) as ThemeNames)) {
							await updateTheme(themeFromCookies as ThemeNames);
						} else {
							await updateTheme(getAffinityGroupFromUrl(window.location.href));
						}
					}
				} else {
					await updateTheme(theme);
				}
			};

			changeTheme();

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [theme, isMounted, updateTheme]);

		return <Component {...props} />;
	};
};

import { FC } from 'react';

import { BaseCard } from '@monorepo/components/DataDisplay';
import { Skeleton } from '@monorepo/components/Misc';

export const CustomerServiceSkeletonCard: FC = () => (
	<BaseCard as='div' enableEffects={false} className='bg-grayscale0 flex h-full flex-col space-y-12 p-24'>
		<Skeleton variant='text' size='display-6' containerClassName='w-1/3' />
		<Skeleton variant='text' size='body-s' containerClassName='w-1/2' />
	</BaseCard>
);

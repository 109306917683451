import { z } from 'zod';

export const questionSchema = z.object({
	guid: z.string(),
	reference: z.string(),
	answer: z.string(),
});

export type QuestionSchema = z.infer<typeof questionSchema>;

export const formSchema = z.object({
	questions: z.array(questionSchema),
});

export type FormSchema = z.infer<typeof formSchema>;

import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { StandardButton } from '../../../../DataEntry';
import { Text } from '../../../../TextComponents';

type BlogSectionSimpleCTAProps = {
	className?: string;
	label: string;
	href: string;
	tagLine?: string;
};

export const BlogSectionSimpleCTA: FC<BlogSectionSimpleCTAProps> = ({ tagLine, label, href, className }) => {
	const { locale } = useRouter();

	return (
		<section className={cn(className, 'sm:mb-104 col-span-full mx-auto my-24 w-full max-w-[1110px] sm:mt-48')}>
			<div className='flex w-full max-w-[1440px] flex-col items-center space-y-12'>
				<Link className='w-fit' locale={locale} href={href} title={label}>
					<StandardButton variant='warning' size='lg' label={label} iconRight='angle-right' className='w-fit' />
				</Link>
				{tagLine && (
					<Text variant='body-s' weight='regular' color='grayscale300'>
						{tagLine}
					</Text>
				)}
			</div>
		</section>
	);
};

import * as RadixTooltip from '@radix-ui/react-tooltip';
import { Lexend } from 'next/font/google';
import { ComponentProps, ReactNode } from 'react';

import { Icon, IconProps } from '@monorepo/shared/icon';
import { Text } from '@monorepo/shared/typography';

import { card } from '../../patterns/card';

const lexend = Lexend({
	weight: ['300', '500', '600', '700'],
	preload: true,
	display: 'swap',
	variable: '--font-lexend',
	subsets: ['latin'],
});

type TooltipProps = {
	title: string;
	body?: string;
	side?: ComponentProps<typeof RadixTooltip.Content>['side'];
	icon?: IconProps['name'];
	label?: string;
} & ComponentProps<typeof RadixTooltip.Root>;

export function Tooltip(props: TooltipProps) {
	const { title, body, side, icon = 'info', label, ...rest } = props;
	return (
		<RadixTooltip.TooltipProvider>
			<RadixTooltip.Root {...rest}>
				<RadixTooltip.Trigger asChild>
					<button className='flex justify-center gap-x-4'>
						{label && (
							<Text variant='body-xs' weight='semibold' color='info300'>
								Meer informatie
							</Text>
						)}
						<div className='bg-grayscale0 border-info300 text-info300 hover:border-info300 hover:text-info300 s-20 flex h-16 w-16 items-center justify-center rounded-full border-2'>
							<Icon name={icon} className='text-currentColor' size='sm' />
						</div>
					</button>
				</RadixTooltip.Trigger>
				<RadixTooltip.Portal>
					<div className={lexend.className}>
						<RadixTooltip.Content
							side={side}
							className='bg-grayscale0 border-primary100 rounded-6 shadow-4 flex max-w-[212px] flex-col gap-y-8 border p-16'>
							<Text variant='body-s' weight='semibold' color='grayscale600'>
								{title}
							</Text>
							{body && (
								<Text variant='body-xs' weight='regular' color='grayscale600'>
									{body}
								</Text>
							)}
						</RadixTooltip.Content>
					</div>
				</RadixTooltip.Portal>
			</RadixTooltip.Root>
		</RadixTooltip.TooltipProvider>
	);
}

import cn from 'classnames';
import { ComponentPropsWithoutRef, createElement, ElementType, PropsWithChildren } from 'react';

type MarketingLayoutOptions = {
	layout?: 'marketing';
	gutter?: '48';
};

type CommonLayoutOptions = {
	layout?: 'common';
};

type TextProps<C extends ElementType> = {
	as?: C;
} & (MarketingLayoutOptions | CommonLayoutOptions);

type Props<C extends ElementType> = PropsWithChildren<TextProps<C>> & ComponentPropsWithoutRef<C>;

export const LayoutGrid = <C extends ElementType>({ as, className, layout = 'common', ...props }: Props<C>) => {
	return createElement(as || 'div', {
		className: cn(
			className,
			layout === 'marketing' &&
				!props.gutter &&
				'grid-cols-[24px_minmax(0,1fr)_24px] xl:grid-cols-[1fr_minmax(0px,1200px)_1fr]',
			layout === 'marketing' &&
				props.gutter === '48' &&
				'grid-cols-[24px_minmax(0,1fr)_24px] xl:grid-cols-[1fr_minmax(0px,1296px)_1fr]',
			layout === 'common' && 'grid-cols-[32px_1fr_32px] 2xl:grid-cols-[1fr_minmax(0px,1800px)_1fr]',
			'grid grid-rows-1 max-md:h-full'
		),
		...props,
	});
};

import { ISaveUboRequest, ISaveUboResponse, IUboResponse } from '@monorepo/types';

import { rootCompliancyApi } from './../../root/root.api';

export const uboApi = rootCompliancyApi.injectEndpoints({
	endpoints: ({ query, mutation }) => ({
		getUbo: query<IUboResponse, string>({
			query(guid) {
				return {
					url: `/${guid}`,
					method: 'GET',
				};
			},
			providesTags: (_, _err, guid) => {
				return [{ type: 'Ubo', id: guid }];
			},
		}),
		saveUbo: mutation<ISaveUboResponse, ISaveUboRequest>({
			query({ guid, ubo_contacts }) {
				return {
					url: `/ubo/${guid}`,
					method: 'POST',
					body: {
						ubo_contacts,
					},
				};
			},
			invalidatesTags: ['Ubo'],
		}),
	}),
	overrideExisting: true,
});

export const { useGetUboQuery, useSaveUboMutation } = uboApi;

export const { getUbo, saveUbo } = uboApi.endpoints;

import cn from 'classnames';
import { FC, KeyboardEvent } from 'react';

import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';

type Props = {
	icon: string;
	name: string;
	onClick: () => void;
	active: boolean;
	className?: string;
};

export const ProductCard: FC<Props> = ({ icon, name, onClick, active = false, className = '' }) => {
	const keyDown = (e: KeyboardEvent) => {
		if (e.key === 'Enter' || e.key === ' ') {
			e.preventDefault();
			onClick();
		}
	};

	return (
		<div
			role='button'
			tabIndex={0}
			onKeyDown={keyDown}
			onClick={onClick}
			className={cn(
				className,
				active && 'border-primary200 bg-primary50 hover:bg-primary50 hover:border-primary200 shadow-none',
				'shadow-1 rounded-18 hover:border-grayscale100 focus:border-grayscale100 flex cursor-pointer flex-col items-center justify-center space-y-16 border border-transparent px-32 py-24 transition-all hover:shadow-none focus:shadow-none'
			)}>
			<Icon name={icon} size='xl' />
			<Text variant='body-s' className='hyphens-auto'>
				{name}
			</Text>
		</div>
	);
};

import cn from 'classnames';
import { FC, KeyboardEvent, useRef, useState } from 'react';

import { NavItem } from '@monorepo/types';

import { Icon } from '../../../../Misc';
import { Text } from '../../../../TextComponents';
import { NavbarMenu } from '../NavBarMenu/NavbarMenu';

type MenuItemProps = {
	item: NavItem;
	inMobileNav?: boolean;
};

export const NavbarMenuItem: FC<MenuItemProps> = ({ item, inMobileNav = false }) => {
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const closeTimer = useRef<NodeJS.Timeout | null>(null); // to keep track of the setTimeout

	const handleMouseEnter = () => {
		if (inMobileNav) {
			return;
		}

		if (closeTimer.current) {
			clearTimeout(closeTimer.current); // clear any pending close action
		}
		setMenuOpen(true);
	};

	const handleMouseLeave = () => {
		if (inMobileNav) {
			return;
		}

		closeTimer.current = setTimeout(() => {
			setMenuOpen(false);
		}, 150);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		e.stopPropagation();
		if (e.key === 'Enter' || e.key === ' ') {
			toggleMenu();
		}
	};

	const toggleMenu = () => {
		setMenuOpen((prev) => !prev);
	};

	return (
		<div
			tabIndex={0}
			ref={containerRef}
			onKeyDown={handleKeyDown}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={toggleMenu}
			className='z-50'>
			<div className={cn(inMobileNav && 'justify-between', 'flex cursor-pointer flex-row items-center space-x-12')}>
				<Text variant={inMobileNav ? 'body-l' : 'body-m'} weight='medium' color={menuOpen ? 'info300' : 'primaryMain'}>
					{item.value}
				</Text>
				<Icon
					name='angle-down'
					size='lg'
					color='info300'
					className={cn(menuOpen && 'rotate-90', 'leading-none transition-transform')}
				/>
			</div>

			<NavbarMenu isOpen={menuOpen} item={item} />
		</div>
	);
};

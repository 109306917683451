import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IPartnersSection } from '@monorepo/types';

import { SingleLogo } from '../LogoListSection/SingleLogo/SingleLogo';

type PartnersSectionProps = IPartnersSection & {
	className?: string;
};

export const PartnersSection: FC<PartnersSectionProps> = ({ title, logos, className }) => {
	return (
		<section className={cn(className, 'py-48')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col items-center space-y-48'>
					<div className='max-md:w-9/12'>
						<Text as='h2' variant='display-3' color='primaryMain' weight='semibold' className='text-center'>
							{title}
						</Text>
					</div>
					<div className='flex flex-col gap-64 md:gap-24'>
						<ul role='list' className='flex flex-row flex-wrap justify-center gap-64'>
							{logos.map((props, key) => (
								<SingleLogo key={`logo-list-${props?.link?.href}-${key}`} {...props} />
							))}
						</ul>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

import { SwagItem } from '@monorepo/types';

export const mockSwagSection: SwagItem[] = [
	{
		value: 70,
		content: `<span>jaren ervaring</span> in verzekeringen`,
		sequence: 1,
	},
	{
		value: 10,
		suffix: 'K',
		content: `<span>ondernemers</span> kozen voor ons`,
		sequence: 2,
	},
	{
		value: 20,
		suffix: '%',
		content: `<span>goedkoper</span> dan de rest`,
		sequence: 3,
	},
	{
		value: 25,
		content: `<span>branches</span> die ons vertrouwen`,
		sequence: 4,
	},
];

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import {
	IPostPensionForecastRequest,
	IPostPensionForecastResponse,
	IPostPensionRequest,
	IPostPensionResponse,
} from '@monorepo/types';

export const viveApi = createApi({
	reducerPath: 'viveApi',
	baseQuery: fetchBaseQuery({ baseUrl: 'https://plancalculator.dev.viveapp.io/api' }),
	tagTypes: ['pension', 'forecast'],

	endpoints: (builder) => ({
		calculatePension: builder.mutation<IPostPensionResponse, IPostPensionRequest>({
			query(body) {
				return {
					url: `/pension`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['pension'],
		}),
		calculatePensionForecast: builder.mutation<IPostPensionForecastResponse, IPostPensionForecastRequest>({
			query(body) {
				return {
					url: `/pension/forecast`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['forecast'],
		}),
	}),
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
});
export const { useCalculatePensionForecastMutation, useCalculatePensionMutation } = viveApi;

import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Form, StandardButton, TextButton } from '@monorepo/components/DataEntry';
import { SelectBox, SelectBoxInput } from '@monorepo/components/Misc';
import { ActivityGroupSchema, activitySchema, ActivitySchema } from '@monorepo/shared/lib/schemas';
import { Classification, ClassificationChild } from '@monorepo/types';

import { Modal, ModalActions } from '@common/components';
import { hideModal } from '@common/store';
import { ActivityForm } from '@compliancy/forms/ActivityForm/ActivityForm';
import { openSupportChat } from '@support/store';

type ActivityModalProps = {
	classifications: Classification[];
	activeAdditions: ClassificationChild[];
	isLoading?: boolean;
	onSubmit: (fields: ActivityGroupSchema) => void;
};

export const ActivityModal: FC<ActivityModalProps> = ({ classifications, activeAdditions, isLoading, onSubmit }) => {
	const { t } = useTranslation(['common']);
	const [step, setStep] = useState<number>(0);
	const [chosenClassifications, setChosenClassifications] = useState<Classification[]>([]);
	const dispatch = useDispatch();

	const classificationsForm = useForm<ActivitySchema>({
		resolver: zodResolver(activitySchema(t)),
		defaultValues: { classifications: classifications.map(({ guid }) => ({ guid, checked: false })) },
	});

	const handleSubmitStepOne = (fields: ActivitySchema) => {
		setChosenClassifications(
			classifications.filter(({ guid }) => fields.classifications.some((field) => field.guid === guid && field.checked))
		);
		setStep(1);
	};

	const handleClose = () => {
		setStep(0);
		setChosenClassifications([]);
		classificationsForm.reset();
		dispatch(hideModal('activity-modal'));
	};

	return (
		<Modal
			id='activity-modal'
			title={
				step === 0
					? t('page.funnel.profile.activitiesNew.modal.step1')
					: t('page.funnel.profile.activitiesNew.modal.step2')
			}
			onClose={handleClose}>
			{step === 0 && (
				<FormProvider {...classificationsForm}>
					<Form onSubmit={classificationsForm.handleSubmit(handleSubmitStepOne)}>
						<div className='grid grid-cols-2 gap-16 md:grid-cols-3 md:gap-32'>
							{classifications?.map(({ guid, icon, name }, index) => (
								<SelectBoxInput
									key={`activity-modal-classification-${guid}`}
									icon={icon}
									title={name}
									subtitle={t('page.funnel.profile.activitiesNew.iWorkIn')}
									name={`classifications.${index}.checked`}
								/>
							))}
							<SelectBox
								icon='question-circle'
								title={t('page.funnel.profile.activitiesNew.missingWorkfield')}
								className='col-span-1'
								onClick={() => {
									dispatch(openSupportChat());
								}}
							/>
						</div>

						<ModalActions
							cancelButton={
								<TextButton type='button' onClick={handleClose} label={t('common.cancel')} iconLeft='angle-left' />
							}
							submitButton={
								<StandardButton
									type='submit'
									variant='primary'
									label={t('common.next')}
									iconRight='angle-right'
									isLoading={isLoading}
									disabled={isLoading || !classificationsForm.formState.isValid}
								/>
							}
						/>
					</Form>
				</FormProvider>
			)}

			{step === 1 && (
				<ActivityForm
					activeActivities={activeAdditions}
					classifications={chosenClassifications.flatMap((classification) => classification.children)}
					onCancel={handleClose}
					onSubmit={(fields) => {
						onSubmit(fields);
						handleClose();
					}}
				/>
			)}
		</Modal>
	);
};

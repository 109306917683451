import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

import { Notification, NotificationStatus } from '../Notification/Notification';

export type StaticNotificationProps = PropsWithChildren & {
	status?: NotificationStatus;
	className?: string;
};

export const StaticNotification: FC<StaticNotificationProps> = ({ status = 'default', className, children }) => {
	return (
		<Notification className={cn(className, 'items-start p-16')} status={status}>
			{children}
		</Notification>
	);
};

import classNames from 'classnames';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { FC, Fragment, useState } from 'react';

import { TextButton } from '@monorepo/components/DataEntry';
import { Icon, LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { slugify, themes } from '@monorepo/shared/lib/utils';
import { INicheOverviewSection } from '@monorepo/types';

import { NicheOverviewCard } from '../NicheOverviewCard/NicheOverviewCard';
import { NicheOverviewCardFeatured } from '../NicheOverviewCardFeatured/NicheOverviewCardFeatured';

type NicheOverviewProps = INicheOverviewSection & { className?: string };

export const NicheOverview: FC<NicheOverviewProps> = ({ items, moreLink, className }) => {
	const [activeCategory, setActiveCategory] = useState<number>(0);

	return (
		<section
			className={cn(
				className,
				'before:bg-primary50 relative before:absolute before:left-0 before:right-0 before:top-0 before:-z-10 before:h-[250px]'
			)}>
			<LayoutGrid as='div' layout='marketing' gutter='48'>
				<div className='rounded-24 bg-grayscale0 relative col-start-1 flex w-full flex-col space-y-32 max-md:col-span-full md:col-start-2 md:space-y-48 md:p-48'>
					<div className='max-md:before:from-grayscale0 max-md:after:from-grayscale0 max-md:before:w-50 max-md:after:w-50 relative overflow-hidden max-md:pt-24 max-md:before:pointer-events-none max-md:before:absolute max-md:before:bottom-0 max-md:before:left-0 max-md:before:z-10 max-md:before:h-[calc(100%-24px)] max-md:before:bg-gradient-to-r max-md:after:pointer-events-none max-md:after:absolute max-md:after:bottom-0 max-md:after:right-0 max-md:after:z-10 max-md:after:h-[calc(100%-24px)] max-md:after:bg-gradient-to-l '>
						<div className='no-scrollbar flex snap-x snap-mandatory snap-always items-center justify-between space-x-40 overflow-x-auto'>
							{items.map(({ category }, key) => (
								<button
									onClick={() => setActiveCategory(key)}
									key={`nav-${key}`}
									className='max-md:first:!ml-50 max-md:last:!mr-50 group flex snap-center flex-col items-center space-y-16 md:space-y-24'>
									<div className='flex flex-col items-center space-y-12'>
										<Icon
											name={category.icon}
											size='xxxl'
											className={cn(
												activeCategory !== key &&
													'group-hover:-translate-y-8 group-hover:-rotate-6 group-active:-translate-y-8 group-active:-rotate-6',
												'transition'
											)}
										/>
										<Text variant='body-l' weight='medium' className='whitespace-nowrap'>
											{category.text}
										</Text>
									</div>
									<motion.div
										key={`background-${slugify(category.text)}`}
										animate={{
											backgroundColor:
												activeCategory === key
													? themes['maia'].colors.secondaryMain
													: themes['maia'].colors.grayscale100,
										}}
										className='h-[6px] w-[170px] md:w-[170px]'></motion.div>
								</button>
							))}
						</div>
					</div>

					<div className='grid grid-cols-2 gap-24 max-md:px-24 md:grid-cols-12 md:gap-48'>
						<AnimatePresence>
							{items[activeCategory].cards.map((card, key) => (
								<Fragment key={`niche-card-${key}`}>
									{card.featured ? (
										<motion.div
											key={`card-${slugify(card.title)}-${slugify(items[activeCategory].category.text)}`}
											initial={{ opacity: 0, y: 100 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{ duration: 0.3 }}
											className='col-span-full'>
											<NicheOverviewCardFeatured {...card} />
										</motion.div>
									) : (
										<motion.div
											key={`card-${slugify(card.title)}-${slugify(items[activeCategory].category.text)}`}
											initial={{ opacity: 0, y: 100 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{ duration: 0.3, delay: 0.3 + key * 0.1 }}
											className={classNames(
												key < 3 && 'col-span-full sm:col-span-1 md:col-span-6',
												key >= 3 && 'relative col-span-full sm:col-span-1 md:col-span-4'
											)}>
											<NicheOverviewCard {...card} />
										</motion.div>
									)}
								</Fragment>
							))}
						</AnimatePresence>
					</div>

					<div className='flex items-center justify-center max-md:px-24'>
						<Link href={moreLink.href} title={moreLink.title}>
							<TextButton label={moreLink.content || moreLink.title} iconRight='arrow-right' />
						</Link>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

import classNames from 'classnames';
import { FC } from 'react';

export type CircularSkeletonProps = {
	className?: string;
};

export const CircularSkeleton: FC<CircularSkeletonProps> = ({ className }) => (
	<div role='status' className={classNames('animate-pulse rounded-full', className)}>
		<span className='sr-only'>Loading...</span>
	</div>
);

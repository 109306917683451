import { IWhatWeDoForYouSection } from '@monorepo/types';

export const mockWhatWeDoForYouSection: IWhatWeDoForYouSection = {
	title: 'Dit doen wij voor jou',
	subtitle: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.',
	items: [
		{ icon: 'file-copy-alt', title: 'Unieke verzekeringen', description: 'Lorem ipsum Dolor' },
		{ icon: 'file-copy-alt', title: 'Unieke verzekeringen', description: 'Lorem ipsum Dolor' },
		{ icon: 'file-copy-alt', title: 'Unieke verzekeringen', description: 'Lorem ipsum Dolor' },
		{ icon: 'file-copy-alt', title: 'Unieke verzekeringen', description: 'Lorem ipsum Dolor' },
		{ icon: 'file-copy-alt', title: 'Unieke verzekeringen', description: 'Lorem ipsum Dolor' },
		{ icon: 'file-copy-alt', title: 'Unieke verzekeringen', description: 'Lorem ipsum Dolor' },
	],
};

import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import { BaseCard } from '@monorepo/components/DataDisplay';
import { StandardButton } from '@monorepo/components/DataEntry';
import { Icon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { INicheCard } from '@monorepo/types';

type NicheCardProps = INicheCard & {
	className?: string;
};

export const NicheCard: FC<NicheCardProps> = ({ image, title, icon, bulletpoints, link, className }) => {
	return (
		<BaseCard className={cn(className, 'bg-grayscale0 pointer-events-none flex flex-col overflow-hidden')}>
			<div className='rounded-t-16 after:from-grayscale600 relative h-[200px] overflow-hidden after:absolute after:inset-0 after:bg-gradient-to-t after:opacity-80'>
				<Text
					variant='display-6'
					weight='semibold'
					color='grayscale0'
					className='absolute bottom-0 left-0 z-10 px-24 pb-24'>
					{title}
				</Text>
				<Image
					sizes={generateSizesString()}
					src={image.path}
					alt={image.alt}
					fill
					className='object-cover object-top'
				/>
			</div>
			<div className='relative flex flex-grow flex-col gap-y-24 p-24'>
				<div className='bg-grayscale0 rounded-6 absolute right-32 top-0 flex h-48 w-48 min-w-48 -translate-y-1/2 items-center justify-center'>
					<Icon name={icon} size='lg' color='info300' />
				</div>
				<ul className='flex flex-grow list-inside list-disc flex-col'>
					{bulletpoints.map((bulletpoint, key) => (
						<Text as='li' variant='body-s' weight='regular' color='primaryMain' key={`${title}-bulletpoint-${key}`}>
							{bulletpoint}
						</Text>
					))}
				</ul>
				<Link className='pointer-events-auto w-fit' href={link.href} title={link.title}>
					<StandardButton variant='primary' size='sm' label={`${link.content || link.title}`} iconRight='angle-right' />
				</Link>
			</div>
		</BaseCard>
	);
};

import cn from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { FC, KeyboardEvent, PropsWithChildren, ReactNode, SyntheticEvent, useEffect, useState } from 'react';

import { useAnalytics } from '../../../../lib/hooks';
import { toSnakeCase } from '../../../../lib/utils';
import { ActionButton } from '../../../DataEntry';
import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';

type Props = PropsWithChildren<{
	title: string | ReactNode;
	icon?: string;
	isDefaultOpen?: boolean;
	className?: string;
	onToggle?: (event: SyntheticEvent) => void;
}>;

export const BaseAccordion: FC<Props> = ({ title, icon, isDefaultOpen = false, onToggle, children, className }) => {
	const { t } = useTranslation(['common']);
	const [isOpen, setIsOpen] = useState<boolean>(isDefaultOpen);
	const { trackEvent } = useAnalytics();

	const handleToggle = (event: SyntheticEvent) => {
		if (onToggle) {
			onToggle(event);
		} else {
			setIsOpen((_) => !_);
		}

		if (!isOpen && typeof title === 'string') {
			trackEvent('select_content', { content_type: 'information_accordion', item_id: toSnakeCase(title) });
		}
	};

	const keyDown = (e: KeyboardEvent) => {
		e.stopPropagation();

		if (e.key === 'Enter' || e.key === ' ') {
			handleToggle(e);
		}
	};

	useEffect(() => {
		setIsOpen(isDefaultOpen);
	}, [isDefaultOpen]);

	return (
		<div
			title={t(isOpen ? 'common.closeAccordion' : 'common.openAccordion', { name: title })}
			className={cn(
				isOpen ? 'border-primary200' : 'border-primary100 hover:bg-primary50 hover:border-primaryMain',
				'rounded-12 bg-grayscale0 group cursor-pointer border',
				className
			)}>
			<div
				tabIndex={0}
				role='button'
				onKeyDown={keyDown}
				onClick={handleToggle}
				className='flex select-none items-center gap-8 px-20 py-16 md:gap-16 md:p-20'
				data-testid={`base-accordion-activator-${title}`}>
				{icon && <Icon name={icon} color='info300' className='leading-none' />}

				<Text as='p' variant='body-m' color='grayscale600' weight='semibold'>
					{typeof title === 'string' ? t(title) : title}
				</Text>

				<ActionButton
					type='button'
					title={t(isOpen ? 'common.closeAccordion' : 'common.openAccordion', { name: title })}
					isActive={true}
					icon='angle-down'
					className={cn(
						isOpen && 'rotate-180',
						'text-info300 group-hover:text-info400 !ml-auto text-[150%] leading-none transition-transform'
					)}
				/>
			</div>

			<motion.div
				initial={{ height: 0 }}
				animate={{ height: isOpen ? 'auto' : 0 }}
				exit={{ height: 0 }}
				transition={{ duration: 0.15, ease: 'easeInOut' }}
				className='gap-4 overflow-hidden'>
				<div className=' px-20 pb-20 md:gap-8 md:px-20'>{children}</div>
			</motion.div>
		</div>
	);
};

import cn from 'classnames';
import type { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';

type PageBodyProps = ComponentPropsWithoutRef<'div'>;

export const PageBody: FC<PropsWithChildren<PageBodyProps>> = ({ children, className, ...rest }) => (
	<div className={cn(className, !className?.includes('my-') && 'my-24 md:my-32')} {...rest}>
		{children}
	</div>
);

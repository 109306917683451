import { faker } from '@faker-js/faker/locale/nl';

import { CalculationProduct } from '@monorepo/types';

import { mockGetCalculationProductOptions } from './calculation-product-option.mock';

export function mockGetCalculationProduct(guid?: string): CalculationProduct {
	return {
		code: guid ? guid : faker.string.alphanumeric(16),
		guid: faker.string.alphanumeric(16),
		is_active: true,
		name: 'Aansprakelijkheidsverzekering',
		premium_is_calculated: true,
		risk_addresses: [],
		show_on_website: true,
		insurer: faker.company.name(),
		options: mockGetCalculationProductOptions(),
		sold_separately: true,
		minimum_price: 100,
		preconditions: [],
		specs: [],
		icon_name: 'edit-alt',
		is_rider: false,
		dependant_on: [{ guid: faker.string.alphanumeric(16), name: 'Bedrijfsaansprakelijkheid' }],
		render_type: 'default',
	};
}

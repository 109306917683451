import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { CheckItem, CheckList, CheckListProps } from '@monorepo/components/TextComponents';
import { getProperty } from '@monorepo/shared/lib/cms';

import { mdComponents } from '@cms/componentHandlers/handleMarkDown/handleMarkDown';
import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleCheckList = ({ component }: HandlerProps): FC<CheckListProps> => {
	const title = getProperty('title', component.custom_fields);
	const included = getProperty('included', component.custom_fields) === 'true';

	const checklistItems: CheckItem[] = component.child_elements.map((child_element) => {
		const Content = () => <ReactMarkdown components={mdComponents}>{`${child_element.markdown}`}</ReactMarkdown>;

		return {
			included,
			content: <Content />,
		};
	});

	return (props) => <CheckList {...props} title={title} items={checklistItems} />;
};

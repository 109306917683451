import cn from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { generateSizesString } from '../../../../../lib/utils';
import { Text } from '../../../../TextComponents';

export type Blog = {
	title: string;
	content: string;
	image: string;
	alt: string;
};
export type ArticleProps = Blog & {
	className?: string;
};

export const Article: FC<ArticleProps> = ({ className, content, image, title, alt }) => {
	return (
		<section className={cn(className, 'col-span-full mx-auto my-16 w-full max-w-[1110px] sm:my-32')}>
			<div className='flex flex-col items-center gap-x-48 max-md:space-y-16 sm:items-start md:flex-row md:items-center'>
				<Text as='h2' variant='display-4' weight='semibold' color='grayscale600' className='sm:hidden'>
					{title}
				</Text>
				<div className='rounded-12 relative h-[350px] w-full overflow-hidden sm:h-[250px] sm:w-[250px] sm:min-w-[250px] md:h-[350px] md:w-[350px] md:min-w-[350px]'>
					<Image sizes={generateSizesString()} src={image} className='object-cover object-center' alt={alt} fill />
				</div>
				<div className='flex flex-col gap-y-12'>
					<Text as='h2' variant='display-4' weight='semibold' color='grayscale600' className='max-sm:hidden'>
						{title}
					</Text>
					<Text as='p' variant='body-l' weight='regular' color='grayscale600'>
						{content}
					</Text>
				</div>
			</div>
		</section>
	);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types'; // Adjust the import path as needed

export const employeesSchema = (t: TranslationType, minEmployees = 0.1) =>
	z.object({
		employeeType: z.enum(['single', 'multiple'], { required_error: t('validation.required') }),
		employees: z.coerce.number().min(minEmployees, t('validation.min', { min: minEmployees })),
	});

export type EmployeesSchema = z.infer<ReturnType<typeof employeesSchema>>;

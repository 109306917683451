import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ToasterNotification } from '@monorepo/components/DataDisplay';
import { Form } from '@monorepo/components/DataEntry';
import { Icon, LicensePlateInput } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { LicensePlateSchema, licensePlateSchema } from '@monorepo/shared/lib/schemas';
import { trackEvent } from '@monorepo/shared/lib/utils';

import { selectTheme, setNavForward, setNavigating } from '@common/store';
import { logger, paths } from '@common/utils';
import { useCreateConsumerCartMutation } from '@consumer/store';
import { FindCompanyActionButton } from '../FindCompanyActionButton/FindCompanyActionButton';

export const CtaLicenseplate: FC = () => {
	const { t } = useTranslation(['common']);
	const theme = useSelector(selectTheme);
	const [createCart, createCartResponse] = useCreateConsumerCartMutation();
	const { push } = useRouter();
	const dispatch = useDispatch();

	const form = useForm<LicensePlateSchema>({
		mode: 'onBlur',
		resolver: zodResolver(licensePlateSchema(t)),
	});

	const submitCart = async ({ license_plate }: LicensePlateSchema) => {
		dispatch(setNavForward());

		try {
			await createCart({
				code: theme,
				insurable_objects: [
					{
						object_code: 'OB',
						reference: license_plate,
					},
				],
			})
				.unwrap()
				.then(async ({ cart_guid }) => {
					trackEvent('start_funnel', { sales_channel: theme });

					await push({
						pathname: paths.consumerFunnel.premiumQuestions.objectDetails,
						query: { cartGuid: cart_guid },
					});
				})
				.catch((error) => {
					if (error?.status === 400) {
						toast.custom(
							(_toast) => (
								<ToasterNotification
									title={t('toasts.licenseNotFound.title')}
									visible={_toast.visible}
									message={t('toasts.licenseNotFound.message')}
									status='error'
									onClose={() => toast.remove(_toast.id)}
								/>
							),
							{
								ariaProps: {
									role: 'alert',
									'aria-live': 'assertive',
								},
								id: 'license-does-not-exist-toast',
							}
						);
					}

					logger.error('Error creating cart', error);
					dispatch(setNavigating(false));
				});
		} catch (err) {
			logger.error('Error creating cart', err);
			dispatch(setNavigating(false));
		}
	};

	const handleSubmit = () => {
		if (form.formState.isValid) {
			submitCart({ license_plate: form.getValues('license_plate') });
		}
	};

	return (
		<div className='bg-grayscale0 rounded-18 flex flex-col p-32'>
			<FormProvider {...form}>
				<Form
					id='funnel-start-form'
					onSubmit={form.handleSubmit(submitCart)}
					className='flex flex-row items-center gap-48'>
					<div className='flex flex-row items-center gap-24'>
						<Icon name='search' size='xxl' color='info300' />
						<Text variant='display-4' weight='semibold' color='primaryMain'>
							Vind je motor
						</Text>
					</div>

					<div className='flex flex-row items-center gap-24'>
						<LicensePlateInput
							name='license_plate'
							size='medium'
							className='w-[260px]'
							placeholder={t('common.bikeLicenseplate')}
							isDisabled={createCartResponse.isLoading}
						/>
						<FindCompanyActionButton
							icon='arrow-right'
							type='submit'
							onClick={handleSubmit}
							disabled={createCartResponse.isLoading}
						/>
					</div>
				</Form>
			</FormProvider>
		</div>
	);
};

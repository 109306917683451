import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ToggleButtonRow } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { useRenderFormControl } from '@monorepo/shared/lib/hooks';
import { slugify, sortAscending, transformToCurrency } from '@monorepo/shared/lib/utils';
import { CalculationProduct, CalculationProductOptionSpecWithGuid, ToggleButtonOption } from '@monorepo/types';

import { selectThemeContext } from '@common/store';

type Props = {
	product: CalculationProduct;
	specs: CalculationProductOptionSpecWithGuid[][];
	onChangeOption: (guid: string) => void;
};

export const ProductFormToggleButton: FC<Props> = ({ product, specs, onChangeOption }) => {
	const { t } = useTranslation(['common']);
	const { displaySalesChannel } = useSelector(selectThemeContext);
	const { activeOption } = useRenderFormControl(product, displaySalesChannel);
	const mappedSpecs: ToggleButtonOption[] = specs
		.flat()
		.sort((a, b) => sortAscending(parseFloat(a.value), parseFloat(b.value)))
		.map((spec) => {
			return {
				label: transformToCurrency(spec.value),
				value: spec.option_guid,
				checked: activeOption?.specs.find((s) => s.is_deductible)?.value === spec.value,
			};
		});

	return (
		<div className='space-y-12'>
			<div className='flex flex-col space-y-4'>
				<Text as='p' variant='body-s' weight='medium' color='grayscale500'>
					{t('components.productForm.deductibles.title')}
				</Text>
				<Text as='span' variant='body-s' color='grayscale400'>
					{t('components.productForm.deductibles.description')}
				</Text>
			</div>

			<ToggleButtonRow
				options={mappedSpecs}
				name={`togglebutton-${slugify(product.name)}`}
				onChange={(guid: string) => onChangeOption(guid)}
			/>
		</div>
	);
};

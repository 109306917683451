import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC } from 'react';

import { ThemeNames } from '@monorepo/types';

import { anchorClassName, underlineClassName } from '../../../../../lib/utils';
import { Text } from '../../../../TextComponents';
import { Logo } from '../../../UI';

type LandingSectionFooterProps = {
	landingHref: string;
	termsHref: string;
	privacyStatementHref: string;
	className?: string;
	theme: ThemeNames;
};

export const LandingSectionFooter: FC<LandingSectionFooterProps> = ({
	landingHref,
	privacyStatementHref,
	termsHref,
	className,
	theme,
}) => {
	const { t } = useTranslation(['common']);
	return (
		<footer className={cn(className, 'relative flex flex-col py-40 sm:flex-row sm:items-center')}>
			<div className='relative h-64 w-full max-w-[200px]'>
				<Logo direction='ltr' align='left' landingHref={landingHref} theme={theme} />
			</div>
			<div className='flex flex-col max-sm:mt-12 max-sm:space-y-12 sm:ml-auto sm:flex-row sm:space-x-24'>
				<div className='w-fit'>
					<Link href={termsHref} className={anchorClassName}>
						<Text as='span' variant='body-m' color='inherit'>
							{t('common.terms')}
						</Text>
						<span className={underlineClassName}></span>
					</Link>
				</div>
				<div className='w-fit'>
					<Link href={privacyStatementHref} className={anchorClassName}>
						<Text as='span' variant='body-m' color='inherit'>
							{t('common.privacyPolicy')}
						</Text>
						<span className={underlineClassName}></span>
					</Link>
				</div>
			</div>
		</footer>
	);
};

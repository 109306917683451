import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { ContactSection } from '@marketing/components';

export const handleMaiaSupport = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextElement = elements.find((element) => element.identifier === 'title-subtext') as CmsElement;
	const title = getProperty('title', titleSubtextElement?.custom_fields);
	const subtext = getProperty('subtext', titleSubtextElement?.custom_fields);

	const chatWithUsElement = elements.find((element) => element.identifier === 'cta-text-button-icon') as CmsElement;
	const chatWithUsIcon = getProperty('icon_name', chatWithUsElement?.custom_fields);
	const chatWithUsTitleSubtextElement = chatWithUsElement.child_elements.find(
		(element) => element.identifier === 'title-subtext'
	) as CmsElement;
	const chatWithUsTitle = getProperty('title', chatWithUsTitleSubtextElement?.custom_fields);
	const chatWithUsDescription = getProperty('subtext', chatWithUsTitleSubtextElement?.custom_fields);
	const chatWithUsLink = chatWithUsElement.links[0];

	const websiteRedirectElements = elements.filter(
		(element) => element.identifier === 'website-redirect'
	) as CmsElement[];

	const emailElement = websiteRedirectElements[0];
	const emailIcon = getProperty('icon_name', emailElement?.custom_fields);
	const emailTitle = getProperty('title', emailElement?.custom_fields);
	const emailDescription = getProperty('subtext', emailElement?.custom_fields);
	const emailLink = emailElement.links[0];

	const phoneElement = websiteRedirectElements[1];
	const phoneIcon = getProperty('icon_name', phoneElement?.custom_fields);
	const phoneTitle = getProperty('title', phoneElement?.custom_fields);
	const phoneDescription = getProperty('subtext', phoneElement?.custom_fields);
	const phoneLink = phoneElement.links[0];

	return () => (
		<ContactSection
			className='py-48 md:py-96'
			title={`${title}`}
			subtitle={`${subtext}`}
			chat={{
				icon: `${chatWithUsIcon}`,
				title: `${chatWithUsTitle}`,
				description: `${chatWithUsDescription}`,
				link: chatWithUsLink,
			}}
			email={{
				icon: `${emailIcon}`,
				title: `${emailTitle}`,
				description: `${emailDescription}`,
				email: emailLink.title,
			}}
			phone={{
				icon: `${phoneIcon}`,
				title: `${phoneTitle}`,
				description: `${phoneDescription}`,
				phone: phoneLink.title,
			}}
		/>
	);
};

import classNames from 'classnames';
import parse from 'html-react-parser';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { StandardButton } from '@monorepo/components/DataEntry';
import { Icon } from '@monorepo/components/Misc';
import { SvgCircle } from '@monorepo/components/Misc/Doodles/Circle/Circle';
import { Text } from '@monorepo/components/TextComponents';
import { IContactHighlightCard } from '@monorepo/types';

import { openSupportChat } from '@support/store';

type ContactHighlightCardProps = IContactHighlightCard & {
	className?: string;
};

export const ContactHighlightCard: FC<ContactHighlightCardProps> = ({ icon, title, description, link, className }) => {
	const dispatch = useDispatch();
	const handleAskQuestion = () => {
		dispatch(openSupportChat());
	};

	return (
		<div className={classNames('bg-primaryMain md:p-50 rounded-16 relative overflow-hidden px-24 py-32', className)}>
			<div className='z-10 space-y-32 md:space-y-40'>
				<div className='space-y-12 md:space-y-16'>
					<div className='relative w-fit md:hidden'>
						<SvgCircle theme='maia' className='text-info300 absolute bottom-8 left-0 h-12 w-12' />
						<Icon name={icon} size='xxl' color='secondaryMain' className='relative z-10' />
						<SvgCircle theme='maia' className='text-info300 absolute right-0 top-12 h-12 w-12' />
					</div>
					<Text as='h3' variant='display-4' weight='semibold' color='grayscale0'>
						{parse(title)}
					</Text>
					<Text variant='body-l' weight='regular' color='grayscale0'>
						{description}
					</Text>
				</div>
				<StandardButton
					variant='ctaInfo'
					label={link.content || link.title}
					title={link.title}
					onClick={handleAskQuestion}
					className='w-full md:w-fit'
					iconLeft={icon}
				/>
			</div>
			<div className='pointer-events-none absolute inset-0 z-0 max-md:hidden'>
				<div className='rotate-15 absolute -right-64 top-1/2 flex -translate-y-1/2 items-center justify-center'>
					<Icon name={icon} size='custom' color='secondaryMain' className='text-[520px]' />
				</div>
			</div>
		</div>
	);
};

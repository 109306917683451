import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, INicheUsp } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { NicheUspSection } from '@marketing/components/NicheUspSection/NicheUspSection';

export const handleSectionMaiaUsp = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	// const titleSubTextElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement,
	// 	title = getProperty('title', titleSubTextElement?.custom_fields);
	const titleSubtextEle: CmsElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const title = getProperty('title', titleSubtextEle.custom_fields);

	// const title = mockNicheUspSection.title;
	const usps: INicheUsp[] = elements
		.filter(({ identifier }) => identifier === 'maia-usp')
		.map((usp) => {
			const titleSubTextElement = usp.child_elements.find(
				({ identifier }) => identifier === 'title-subtext'
			) as CmsElement;
			const title = getProperty('title', titleSubTextElement.custom_fields);
			const subtext = getProperty('subtext', titleSubTextElement.custom_fields);
			const [image] = usp.media;

			return {
				title: `${title}`,
				description: `${subtext}`,
				image: image,
			};
		});

	return (props) => <NicheUspSection {...props} className='md:py-104' title={`${title}`} usps={usps} />;
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { isGuid } from '../utils';

export const newUserSchema = (t: TranslationType) =>
	z.object({
		firstName: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		lastName: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		email: z.string({ required_error: t('validation.required') }).email(t('validation.email')),
		cartGuid: z.string().refine(isGuid, t('validation.format')),
		salesChannel: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		existingCustomer: z.string({ required_error: t('validation.required') }).transform((value) => value === 'true'),
	});

export type NewUserSchema = z.infer<ReturnType<typeof newUserSchema>>;

import classNames from 'classnames';
import { ReactNode, type InputHTMLAttributes } from 'react';
import { FieldValues } from 'react-hook-form';

import { TooltipIcon } from '../../../DataDisplay';
import { Text } from '../../../TextComponents';
import { FormBaseInputDefaultProps } from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormRadioProps<TFormValues extends FieldValues> = {
	label?: string;
	helperText?: string;
	hideError?: boolean;
	description?: string | ReactNode;
	tooltipId?: string;
	subtext?: ReactNode | string;
	useSuccessColors?: boolean;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<InputHTMLAttributes<HTMLInputElement>, 'aria-invalid'>;

export const FormRadio = <TFormValues extends Record<string, unknown>>({
	id,
	name,
	errors,
	helperText,
	hideError,
	register,
	rules,
	tooltipId,
	description,
	useSuccessColors,
	className,
	...props
}: FormRadioProps<TFormValues>): JSX.Element => (
	<FormHelper errors={errors} hint={helperText} hideError={hideError}>
		{({ isError }) => (
			<label
				htmlFor={id || name}
				className={classNames('flex cursor-pointer flex-row items-center space-x-3', className)}>
				<div className='flex flex-grow space-x-3 sm:items-center'>
					<input
						id={id || name}
						name={name}
						aria-invalid={isError}
						type='radio'
						className={classNames(
							// Base
							'text-primary border-grayscale300 peer h-24 w-24 cursor-pointer appearance-none rounded-full transition-colors',
							// Disabled
							'disabled:border-grayscale200 disabled:bg-grayscale50 disabled:checked:bg-grayscale200 disabled:checked:border-grayscale200 disabled:cursor-not-allowed',
							// Hover
							useSuccessColors
								? 'hover:border-success300 hover:bg-success50 hover:checked:border-success400 hover:checked:bg-success500 hover:checked:border-4'
								: 'hover:border-primaryMain hover:bg-primary50 hover:checked:border-primary100 hover:checked:bg-primary600 hover:checked:border-4',
							// Focus
							useSuccessColors
								? 'focus:border-success300 focus:bg-success50 focus:checked:bg-success500 focus:checked:border-success100'
								: 'focus:border-primaryMain focus:bg-primary50 focus:checked:bg-primary600 focus:checked:border-primary100',
							'focus:ring-0',
							// Active
							useSuccessColors
								? 'active:border-success200 active:text-success400 active:border-4'
								: 'active:border-primary200 active:text-primaryMain active:border-4',
							// Checked
							useSuccessColors
								? 'checked:border-success200 checked:bg-success400 checked:border-4'
								: 'checked:border-primary200 checked:bg-primaryMain checked:border-4',

							props?.subtext && 'self-start'
						)}
						{...(register && register(name, rules))}
						{...props}
					/>
					<div className='flex flex-1 flex-col pl-12 sm:flex-row sm:items-center'>
						<div className='text-grayscale500 peer-disabled:text-grayscale400 sm:flex-1'>
							<Text
								variant='body-s'
								as='label'
								htmlFor={id || name}
								color='inherit'
								className='flex cursor-pointer flex-col'>
								{props.label}

								{props?.subtext && (
									<Text variant='body-xs' as='span' color='grayscale300' className='mt-4'>
										{props.subtext}
									</Text>
								)}
							</Text>
						</div>
						{description && typeof description === 'string' && (
							<Text
								variant='body-s'
								color='grayscale400'
								className={classNames(props?.subtext && 'self-start', 'cursor-pointer sm:pr-12')}>
								{description}
							</Text>
						)}
						{description && typeof description === 'object' && <div className='max-sm:mt-4'>{description}</div>}
					</div>
				</div>

				{tooltipId && (
					<div className='relative'>
						<TooltipIcon id={tooltipId} />
					</div>
				)}
			</label>
		)}
	</FormHelper>
);

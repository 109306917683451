import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

type FormCheckboxListElevation = 'flat' | 'elevated';
type FormCheckboxListRadius = 'main' | 'embedded' | 'custom';

type FormCheckboxListProps = {
	elevation?: FormCheckboxListElevation;
	radius?: FormCheckboxListRadius;
	className?: string;
};

export const FormCheckboxList: FC<PropsWithChildren<FormCheckboxListProps>> = ({
	elevation = 'flat',
	radius = 'main',
	className,
	children,
}) => {
	return (
		<section
			className={classNames(
				'space-y-24 px-16 py-20 sm:p-20',
				elevation === 'flat' && 'border-grayscale200 border',
				elevation === 'elevated' && 'shadow-1',
				radius === 'main' && 'rounded-18',
				radius === 'embedded' && 'rounded-12',
				className
			)}>
			{children}
		</section>
	);
};

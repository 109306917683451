import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC, useMemo } from 'react';

import { Media, ProductDocument } from '@monorepo/types';

import { getDocName } from '../../../lib/utils';
import { DownloadButton } from '../../DataEntry';
import { Text } from '../../TextComponents';

type DocumentProps = {
	title?: string;
	productDocument: ProductDocument;
	className?: string;
	documentContainerClassName?: string;
	disclaimer?: string;
	showDocumentName?: boolean;
};

export const InsuranceDocuments: FC<DocumentProps> = ({
	title,
	productDocument,
	className,
	disclaimer,
	documentContainerClassName,
	showDocumentName = true,
}) => {
	const { t } = useTranslation(['common']);

	const mappedProducts: { name: string; documents: Media[] }[] = useMemo(
		() =>
			productDocument
				? [
						...(productDocument.documents.length > 0
							? [{ name: productDocument.name, documents: productDocument.documents }]
							: []),
						...productDocument.options?.map((option) => ({ name: option.name, documents: option.documents })),
					]
				: [],
		[productDocument]
	);

	return (
		<div className={cn(className, 'flex flex-col space-y-16')}>
			{title && (
				<Text variant='body-m' color='grayscale600' weight='semibold'>
					{title}
				</Text>
			)}

			{mappedProducts.map(({ name, documents }, key) => {
				return (
					<div className='flex flex-col gap-y-8' key={`${name}-${key}`}>
						{showDocumentName && mappedProducts.length > 1 && (
							<Text variant='body-s' weight='semibold'>
								{name}
							</Text>
						)}

						<div className={cn('flex flex-row flex-wrap gap-12', documentContainerClassName)}>
							{documents?.map(({ filename, name, path, type }, index) => {
								const displayName = name ? name : getDocName(type, t, filename);

								return (
									<Link
										target='_blank'
										className='pointer-events-auto max-sm:col-span-2 sm:w-fit'
										key={`${filename}-${index}`}
										title={displayName}
										href={`/${path}`}>
										<DownloadButton label={displayName} iconLeft='file-info-alt' className='w-full' />
									</Link>
								);
							})}
						</div>
					</div>
				);
			})}
			{disclaimer && (
				<Text as='p' variant='body-s' color='grayscale400'>
					{disclaimer}
				</Text>
			)}
		</div>
	);
};

import cn from 'classnames';
import { FC, KeyboardEvent } from 'react';

import { ThemeColors, ToggleButtonProps, ToggleButtonVariants, YesNo } from '@monorepo/types';

import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';

const getValueVariant = (value: string | YesNo): ToggleButtonVariants => {
	switch (value) {
		case 'J':
			return 'yes';
		case 'N':
			return 'no';
		default:
			return 'default';
	}
};

const getIconForValue = (value: string | YesNo): string | undefined => {
	return value === 'J' ? 'check' : value === 'N' ? 'times' : undefined;
};

const getIconColorForValue = (value: string | YesNo): ThemeColors | 'inherit' => {
	return value === 'J' ? 'success300' : value === 'N' ? 'error300' : 'inherit';
};

const baseClasses =
	'min-w-24 bg-shades0 border-grayscale200 text-grayscale500 relative flex cursor-pointer select-none flex-row items-center space-x-4 border px-16 py-8 transition-colors ease-in-out hover:z-20';

const getPositionClasses = (position: string) => {
	switch (position) {
		case 'first':
			return 'rounded-tl-6 rounded-bl-6';
		case 'middle':
			return '-ml-[1px]';
		case 'last':
			return 'rounded-tr-6 rounded-br-6 -ml-[1px]';
		default:
			return '';
	}
};

const getVariantClasses = (variant: ToggleButtonVariants, checked: boolean, isDisabled?: boolean) => {
	const base = {
		default: 'bg-primary100 border-primaryMain text-primaryMain hover:bg-primary50',
		yes: 'border-success300 bg-success50 hover:bg-grayscale0',
		no: 'border-error300 bg-error50 hover:bg-grayscale0',
	};

	const hover = {
		default: 'hover:bg-primary50 hover:border-primaryMain hover:text-primaryMain',
		yes: 'hover:border-success300',
		no: 'hover:border-error300',
	};

	const active = {
		default: 'active:bg-primary100 active:border-primary600 active:text-primary600',
		yes: 'active:border-success300 active:bg-success50',
		no: 'active:border-error300 active:bg-error50',
	};

	const focus = {
		default: 'focus:border-primaryMain focus:ring-0 outline-0',
		yes: 'focus:border-success500 focus:ring-0 outline-0',
		no: 'focus:border-error500 focus:ring-0 outline-0',
	};

	const disabled = {
		default: 'bg-grayscale100 border-grayscale200 text-grayscale200 cursor-progress',
		yes: 'border-grayscale200 bg-grayscale100',
		no: 'border-grayscale200 bg-grayscale100',
	};

	return !isDisabled
		? cn(checked && base[variant], !checked && hover[variant], !checked && active[variant], focus[variant])
		: disabled[variant];
};

export const ToggleButton: FC<ToggleButtonProps> = ({
	name,
	id,
	label,
	value,
	checked = false,
	onChange,
	icon = getIconForValue(value),
	iconColor = getIconColorForValue(value),
	position = 'first',
	variant = getValueVariant(value),
	error = false,
	isDisabled = false,
	className = '',
}) => {
	const handleKeyDown = (e: KeyboardEvent) => {
		if (['Enter', ' '].includes(e.key)) {
			e.preventDefault();
			onChange();
		}
	};

	return (
		<label
			tabIndex={0}
			role='radio'
			aria-disabled={isDisabled}
			onKeyDown={handleKeyDown}
			className={cn(
				className,
				baseClasses,
				getPositionClasses(position),
				getVariantClasses(variant, checked, isDisabled),
				checked && 'z-10',
				error && 'border-error300'
			)}>
			{icon && <Icon name={icon} color={iconColor} size='md' />}
			<Text as='span' variant='body-m' color='inherit' weight='semibold'>
				{label}
			</Text>
			<input
				type='radio'
				id={id}
				name={name}
				value={value}
				checked={checked}
				onChange={onChange}
				disabled={isDisabled}
				className='hidden'
			/>
		</label>
	);
};

import cn from 'classnames';
import classNames from 'classnames';
import type { FC, ReactNode } from 'react';

import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { BaseCard } from '../BaseCard/BaseCard';

type InfoCardProps = {
	icon: string;
	title: string;
	description?: ReactNode;
	className?: string;
	size?: 'small' | 'large';
	orientation?: 'vertical' | 'horizontal';
};

export const InfoCard: FC<InfoCardProps> = ({
	icon,
	title,
	description,
	size = 'large',
	orientation = 'vertical',
	className,
}) => {
	return (
		<BaseCard isElevated={false} enableEffects={false} className={cn(className, 'p-16 sm:p-20')}>
			<div className={classNames(orientation === 'vertical' ? 'flex flex-col gap-y-16' : 'flex flex-row gap-x-16')}>
				<Icon name={icon} size='lg' />
				<div className='space-y-4'>
					<Text variant={size === 'large' ? 'body-m' : 'body-s'} color='grayscale500' weight='medium'>
						{title}
					</Text>
					<Text variant={size === 'large' ? 'body-s' : 'body-xs'} color='grayscale400' weight='regular'>
						{description}
					</Text>
				</div>
			</div>
		</BaseCard>
	);
};

import { getCookie, hasCookie } from 'cookies-next';

export const COOKIE_KEYS = {
	PERSIST_UBO: 'persist_ubo',
};

export const parseCookies = <T>(key: string, req: Request): undefined | T => {
	if (!hasCookie(key, { req })) {
		return undefined;
	}

	try {
		const cookieString = getCookie(COOKIE_KEYS.PERSIST_UBO, { req });

		return JSON.parse(`${cookieString}`) as T;
	} catch (error) {
		return undefined;
	}
};

import { AcceptanceQuestion, AnswerQuestionsRequest, PremiumQuestion } from '@monorepo/types';

export const isGivenAnswerDifferentThanExistingAnswer = (
	answer: AnswerQuestionsRequest,
	questions: PremiumQuestion[] | AcceptanceQuestion[]
): boolean => {
	return answer.questions.some(({ guid, answer }) => {
		const correspondingQuestion = questions.find((q) => q.guid === guid);

		if (!correspondingQuestion) {
			return false;
		}

		switch (correspondingQuestion.type) {
			case 'Numeriek':
				return Number(answer) !== correspondingQuestion?.answer_amount;
			case 'Meerkeuze':
				const foundKey = correspondingQuestion?.response_options?.find(({ key }) => answer === key)?.value;
				return foundKey && correspondingQuestion?.answer_readable !== foundKey;
			default:
				return false;
		}
	});
};

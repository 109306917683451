import { FormEvent, InputHTMLAttributes, PropsWithChildren } from 'react';

import { FormAcceptanceStatementCheckbox } from '../FormAcceptanceStatementCheckbox/FormAcceptanceStatementCheckbox';
import { FormBigNumberInput } from '../FormBigNumberInput/FormBigNumberInput';
import { FormBigTextInput } from '../FormBigTextInput/FormBigTextInput';
import { FormCheckbox } from '../FormCheckbox/FormCheckbox';
import { FormCheckboxList } from '../FormCheckbox/List/FormCheckboxList';
import { FormChoice } from '../FormChoice/FormChoice';
import { FormChoiceList } from '../FormChoice/List/FormChoiceList';
import { FormContext } from '../FormContext/FormContext';
import { FormDateInput } from '../FormDateInput/FormDateInput';
import { FormDropdown } from '../FormDropdown/FormDropdown';
import { FormEmailInput } from '../FormEmailInput/FormEmailInput';
import { FormGroup } from '../FormGroup/FormGroup';
import { FormHiddenInput } from '../FormHiddenInput/FormHiddenInput';
import { FormIbanInput } from '../FormIbanInput/FormIbanInput';
import { FormLabel } from '../FormLabel/FormLabel';
import { FormNumberInput } from '../FormNumberInput/FormNumberInput';
import { FormNumberWithControlsInput } from '../FormNumberWithControlsInput/FormNumberWithControlsInput';
import { FormPhoneInput } from '../FormPhoneInput/FormPhoneInput';
import { FormRadio } from '../FormRadio/FormRadio';
import { FormRadioList } from '../FormRadio/List/FormRadioList';
import { FormSearchInput } from '../FormSearchInput/FormSearchInput';
import { FormSimpleDateInput } from '../FormSimpleDateInput/FormSimpleDateInput';
import { FormTextarea } from '../FormTextarea/FormTextarea';
import { FormTextInput } from '../FormTextInput/FormTextInput';
import { FormValutaInput } from '../FormValutaInput/FormValutaInput';

type FormProps = {
	onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
} & Omit<InputHTMLAttributes<HTMLFormElement>, 'onSubmit'>;

export const Form = ({ onSubmit, children, ...props }: PropsWithChildren<FormProps>) => {
	return (
		<form onSubmit={(event) => (onSubmit ? onSubmit(event) : null)} {...props}>
			{children}
		</form>
	);
};

// Inputs
Form.AcceptanceStatementCheckbox = FormAcceptanceStatementCheckbox;
Form.Checkbox = FormCheckbox;
Form.Radio = FormRadio;
Form.Textarea = FormTextarea;
Form.NumberInput = FormNumberInput;
Form.NumberWithControlsInput = FormNumberWithControlsInput;
Form.BigNumberInput = FormBigNumberInput;
Form.BigTextInput = FormBigTextInput;
Form.ValutaInput = FormValutaInput;
Form.PhoneInput = FormPhoneInput;
Form.SimpleDateInput = FormSimpleDateInput;

Form.DateInput = FormDateInput;
Form.TextInput = FormTextInput;
Form.SearchInput = FormSearchInput;
Form.IbanInput = FormIbanInput;
Form.Choice = FormChoice;
Form.EmailInput = FormEmailInput;
Form.Dropdown = FormDropdown;
Form.HiddenInput = FormHiddenInput;

// Helpers
Form.Group = FormGroup;
Form.Label = FormLabel;
Form.Context = FormContext;
Form.ChoiceList = FormChoiceList;
Form.RadioList = FormRadioList;
Form.CheckboxList = FormCheckboxList;

export default Form;

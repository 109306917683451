import { useEffect, useMemo, useState } from 'react';

import { Calculation } from '@monorepo/types';

export const useProductNavigation = (calculations: Calculation[]) => {
	const initialActiveProductGuid = useMemo(
		() =>
			calculations.find(({ formControls }) => formControls && formControls?.length > 0)?.product.guid ||
			calculations[0]?.product.guid,
		[calculations]
	);

	const hastAtleastOneConfigureableProduct = useMemo(
		() => calculations.some(({ formControls }) => formControls && formControls?.length > 0),
		[calculations]
	);

	const [activeProductGuid, setActiveProductGuid] = useState<string>(initialActiveProductGuid);
	const [isLastProductAnswered, setIsLastProductAnswered] = useState<boolean>(hastAtleastOneConfigureableProduct);
	const [hasNextProduct, setHasNextProduct] = useState<boolean>(false);

	useEffect(() => {
		if (initialActiveProductGuid) {
			setActiveProductGuid(initialActiveProductGuid);
		}
	}, [initialActiveProductGuid]);

	useEffect(() => {
		const nextProductGuid = nextEditableProductInList(activeProductGuid);
		setHasNextProduct(!!nextProductGuid);
	}, [activeProductGuid]);

	const nextEditableProductInList = (guid: string): string | undefined => {
		const currentIndex = calculations.findIndex(({ product }) => product.guid === guid);

		const nextEditableProduct = calculations
			.slice(currentIndex + 1)
			.find(({ formControls }) => formControls && formControls?.length > 0);

		return nextEditableProduct?.product.guid;
	};

	const handleNextButtonClick = (guid: string) => {
		const nextGuid = nextEditableProductInList(guid);

		if (nextGuid) {
			setActiveProductGuid(nextGuid);
		} else {
			setActiveProductGuid('');
			setIsLastProductAnswered(true);
		}
	};

	const handleEditButtonClick = (guid: string) => {
		const productExists = calculations.some(({ product }) => product.guid === guid);
		if (productExists) {
			setActiveProductGuid(guid);
		}
	};

	const isDetailsVisible = (guid: string) => activeProductGuid === guid;

	const isEditVisible = useMemo(() => {
		const activeIndex = calculations.findIndex(({ product }) => product.guid === activeProductGuid);
		return (guid: string) =>
			calculations.findIndex(({ product }) => product.guid === guid) < activeIndex || isLastProductAnswered;
	}, [calculations, activeProductGuid]);

	return {
		activeProductGuid,
		hasNextProduct,
		handleNextButtonClick,
		handleEditButtonClick,
		isLastProductAnswered,
		isDetailsVisible,
		isEditVisible,
	};
};

import {
	AcceptanceQuestion,
	AnswerQuestionsRequest,
	InputSelectOption,
	PremiumQuestion,
	QuestionAnswer,
	ReactSelectOption,
} from '@monorepo/types';

export function toAnswerObject(guid: QuestionAnswer['guid'], answer: QuestionAnswer['answer']): QuestionAnswer {
	return {
		guid,
		answer,
	};
}

export function toReactSelectObject(arr: InputSelectOption[]): ReactSelectOption[] {
	return arr.map(({ guid, code, name }) => {
		return {
			value: guid ?? code,
			label: name,
		};
	});
}

export function toAnswerRequestObject(
	cartGuid: string,
	questions: PremiumQuestion[] | AcceptanceQuestion[],
	answers: string[]
): AnswerQuestionsRequest {
	return {
		cart_guid: cartGuid,
		questions: Object.assign([], questions).map(({ guid }, index) => toAnswerObject(guid as string, answers[index])),
	};
}

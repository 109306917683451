import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SvgSplash } from '@monorepo/components/Misc/Illustrations/Funnel/SvgSplash';
import { D_DATE_FORMAT, formatDate } from '@monorepo/shared/lib/utils';
import { CMSPageProps, CmsWebpage } from '@monorepo/types';

import { mapContentToComponent, mapContentToComponentV2 } from '@cms/componentMapper/componentMapper';
import { selectTheme } from '@common/store';
import { paths } from '@common/utils';
import { DynamicContentModal } from '@funnel/modals';

/**
 * @deprecated
 */
export const withCMS = (WrappedComponent: CMSPageProps) => {
	return function PageWithContentComponent(props: { children?: ReactNode; page: CmsWebpage }) {
		const theme = useSelector(selectTheme);

		return (
			<WrappedComponent {...props}>
				{props?.children && props.children}
				{props?.page?.containers
					?.map((container) => container.elements)
					.flat()
					.map((content) =>
						mapContentToComponent(content, {
							author: props.page.author,
							theme: theme,
							publishDate: `${formatDate({ date: new Date(props.page.publish_date), dateFormat: D_DATE_FORMAT })}`,
						})
					)
					.map((Component, key) => <Component key={`page-item-${key}`} />)}
			</WrappedComponent>
		);
	};
};

export const withCMSV2 = (WrappedComponent: CMSPageProps) => {
	// Ensure these pages always load for all users, also from AG
	const nonRedirectPaths = ['algemene-voorwaarden', 'cookies', 'privacy', 'disclaimer'];

	return function PageWithContentComponent(props: { children?: ReactNode; page: CmsWebpage; path?: string }) {
		const theme = useSelector(selectTheme);
		const { push } = useRouter();
		const isAanvragenPage = props?.path?.includes('landing/aanvragen');

		const pageContents = useMemo(
			() =>
				props.page.containers
					?.map((content) =>
						mapContentToComponentV2(content, {
							author: props.page.author,
							theme,
							path: props.path,
							publishDate: `${formatDate({ date: new Date(props.page.publish_date), dateFormat: D_DATE_FORMAT })}`,
						})
					)
					.map((Component, key) => <Component key={`page-item-${key}`} />),
			[props.page, theme, props.path]
		);

		// Redirect to appropriate page when user is not on our main theme
		useEffect(() => {
			if (theme !== 'maia' && props?.path && !nonRedirectPaths.includes(props?.path)) {
				push(paths.funnel.root);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [theme, props?.path]);

		return (
			<WrappedComponent {...props}>
				{/* TODO: Fix this temporary solution for a one off /aanvragen page */}
				{isAanvragenPage ? (
					<div className='grid h-full grid-cols-[24px_minmax(0,1fr)_24px] grid-rows-1 max-md:h-full xl:grid-cols-[1fr_minmax(0px,1200px)_1fr]'>
						<div className='lg:grid-cols-24 col-start-2 grid grid-cols-4 gap-x-24 sm:grid-cols-8 md:grid-cols-12 lg:gap-x-20 2xl:gap-24'>
							<div className='relative col-span-full flex flex-col pb-32 pt-40 md:col-span-6 md:pt-[100px] lg:col-span-12'>
								{props.children}
								{pageContents}
							</div>
							<div className='bg-primary50 relative flex h-full w-[50vw] items-end max-md:hidden md:col-span-6 lg:col-span-12'>
								<SvgSplash className='max-h-[90vh] w-full object-cover object-bottom' theme={theme} />
							</div>
						</div>
					</div>
				) : (
					<>
						{props.children}
						{pageContents}
					</>
				)}

				<DynamicContentModal id={`dynamic-content-modal`} />
			</WrappedComponent>
		);
	};
};

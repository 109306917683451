import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { ContactSchema } from '@monorepo/shared/lib/schemas';
import { isBrowser, isTest } from '@monorepo/shared/lib/utils';

export const generalApi = createApi({
	reducerPath: 'generalApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/prospect`,
	}),
	tagTypes: ['Contact'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		requestContact: builder.mutation<void, ContactSchema & { dossier_number: string; code: string }>({
			query(payload) {
				return {
					url: `/request-contact`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: ['Contact'],
		}),
	}),
});

export const { useRequestContactMutation } = generalApi;

export const { requestContact } = generalApi.endpoints;

import cn from 'classnames';
import { FC } from 'react';

export type PageSkeletonProps = {
	className?: string;
};

export const PageSkeleton: FC<PageSkeletonProps> = ({ className = '' }) => (
	<div className={cn(className, 'flex min-h-[50vh] w-full animate-pulse flex-col')}>
		<div className='bg-grayscale200 rounded-12 my-16 h-64 w-2/3'></div>
		<div className='bg-grayscale200 h-20 w-full'></div>
		<div className='bg-grayscale200 rounded-12 my-16 h-64 w-3/4'></div>
		<div className='bg-grayscale200 my-8 h-px w-11/12'></div>
		<div className='bg-grayscale200 rounded-6 my-16 h-32 w-4/5'></div>
		<div className='my-18 bg-grayscale200 h-px w-10/12'></div>
		<div className='bg-grayscale200 rounded-12 my-16 h-48 w-1/2'></div>
	</div>
);

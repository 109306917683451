import { z } from 'zod';

import { affinityGroupsOptions, TranslationType } from '@monorepo/types';

export const affinityGroupSchema = (t: TranslationType) =>
	z.object({
		affinityGroup: z.enum(affinityGroupsOptions, { required_error: t('validation.required') }),
	});

export type AffinityGroupSchema = z.infer<ReturnType<typeof affinityGroupSchema>>;

import { AnswerQuestionsRequest, AnswerQuestionsResponse, PremiumQuestion } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const premiumQuestionsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getPremiumQuestions: builder.query<PremiumQuestion[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/premium-questions`,
					method: 'GET',
				};
			},
			providesTags: (result) => {
				if (result) {
					return result.map(({ guid }) => ({ type: 'PremiumQuestion', id: guid }));
				}

				/* istanbul ignore next */
				return ['PremiumQuestion'];
			},
		}),
		answerPremiumQuestions: builder.mutation<AnswerQuestionsResponse, AnswerQuestionsRequest>({
			query({ cart_guid, questions }) {
				return {
					url: `/carts/${cart_guid}/premium-questions`,
					method: 'PUT',
					body: { questions },
				};
			},
			invalidatesTags: ['PremiumQuestion', 'Cart', 'CalculationState', 'Organization', 'CalcuationsForm'],
		}),
	}),
});

export const { useAnswerPremiumQuestionsMutation, useGetPremiumQuestionsQuery } = premiumQuestionsApi;

export const { answerPremiumQuestions, getPremiumQuestions } = premiumQuestionsApi.endpoints;

import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC } from 'react';

import { ButtonSize, ButtonVariant, CmsLink, ThemeNames } from '@monorepo/types';

import { useAnalytics } from '../../../../lib/hooks';
import { StandardButton } from '../StandardButton/StandardButton';

type Props = {
	link: CmsLink;
	variant?: ButtonVariant;
	size?: ButtonSize;
	icon?: string;
	theme?: ThemeNames;
	className?: string;
};

// Use the MarketingCtaButton only marketing pages where we link to the funnel
export const MarketingCtaButton: FC<Props> = ({
	link,
	variant = 'cta',
	size = 'md',
	theme = 'maia',
	icon,
	className,
}) => {
	const { trackEvent } = useAnalytics();
	const { t } = useTranslation(['common']);

	const onClick = () => {
		trackEvent('start_funnel', { sales_channel: theme });
	};

	return (
		<Link
			className={className}
			onClick={onClick}
			href={link?.href || '/aanvragen/bedrijf/zoeken'}
			title={link?.title || ''}
			target={link?.target || '_self'}>
			<StandardButton
				iconRight={icon ? icon : undefined}
				className='w-full'
				size={size}
				variant={variant}
				label={link?.content || link?.title || t('common.calculatePremiumCta')}
			/>
		</Link>
	);
};

import cn from 'classnames';
import { FC, ReactNode } from 'react';

type Props = {
	mobileHidden?: boolean;
	className?: string;
	centerText?: ReactNode;
};

export const Divider: FC<Props> = ({ className = '', centerText }) => {
	return (
		<div className={cn(className, 'relative', centerText && '')}>
			<hr className='border-grayscale200 h-fit w-auto border-dashed md:block' />
			{centerText && (
				<div className='bg-grayscale0 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 px-4'>
					{centerText}
				</div>
			)}
		</div>
	);
};

import { FC } from 'react';

import { Article } from '@monorepo/components/Misc';
import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleArticleSiteSectionArticle = <P extends object>({ component }: HandlerProps): FC<P> => {
	const title = getProperty('title', component.custom_fields) as string;

	return () => (
		<Article
			title={title}
			content={`${component.markdown}`}
			image={`${component.media?.[0]?.path}`}
			alt={`${component.media?.[0]?.alt}`}
		/>
	);
};

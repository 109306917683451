import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { FirebaseUser } from '@monorepo/types';

import { rootAuthApi } from '@auth/store';

const usersApi = rootAuthApi.injectEndpoints({
	endpoints: ({ query, mutation }) => ({
		getUser: query<FirebaseUser, string>({
			query(uid) {
				return {
					url: `/get-user?uid=${uid}`,
					method: 'GET',
				};
			},
			providesTags: (_, _err, uid) => {
				return [{ type: 'User', id: uid }];
			},
			transformErrorResponse: (error: FetchBaseQueryError) => {
				return {
					status: error.status,
					data: error.data,
				};
			},
		}),
		verifyUsers: mutation<object, void>({
			query(payload) {
				return {
					url: `/superadmin/verify-users`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: ['User'],
			transformErrorResponse: (error: FetchBaseQueryError) => {
				/* istanbul ignore next */
				return {
					status: error.status,
					data: error.data,
				};
			},
		}),
		updateFirebaseEmail: mutation<{ email: string }, { firebaseUid: string; email: string }>({
			query(payload) {
				return {
					url: `/superadmin/update-email`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: (_, _err, { firebaseUid }) => [{ type: 'User', id: firebaseUid }],
			transformErrorResponse: (error: FetchBaseQueryError) => {
				/* istanbul ignore next */
				return {
					status: error.status,
					data: error.data,
				};
			},
		}),
		verifyUser: mutation<{ email: string }, { firebaseUid: string }>({
			query(payload) {
				return {
					url: `/superadmin/verify-user`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: (_, _err, { firebaseUid }) => [{ type: 'User', id: firebaseUid }],
			transformErrorResponse: (error: FetchBaseQueryError) => {
				/* istanbul ignore next */
				return {
					status: error.status,
					data: error.data,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetUserQuery, useUpdateFirebaseEmailMutation, useVerifyUsersMutation, useVerifyUserMutation } =
	usersApi;

export const { getUser } = usersApi.endpoints;

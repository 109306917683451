import { Address, PortalOrganization } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

const organizationApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getOrganization: builder.query<PortalOrganization, void>({
			query() {
				return {
					url: `/organization`,
					method: 'GET',
				};
			},
			providesTags: ['Organization'],
		}),
		updateAddress: builder.mutation<void, { organizationGuid: string; address: Address }>({
			query({ organizationGuid, ...payload }) {
				return {
					url: `/organizations/${organizationGuid}/address`,
					method: 'PUT',
					body: payload,
				};
			},
			invalidatesTags: ['Organization'],
		}),
	}),
	overrideExisting: true,
});

export const { useGetOrganizationQuery, useUpdateAddressMutation } = organizationApi;

export const { getOrganization, updateAddress } = organizationApi.endpoints;

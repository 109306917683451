import {
	CreateHistoricalInsuranceRequest,
	CreateHistoricalInsuranceResponse,
	DeleteHistoricalInsuranceRequest,
	HistoricalInsuranceState,
	UpdateHistoricalInsuranceRequest,
} from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

const currentInsurancesApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getCurrentInsurances: builder.query<HistoricalInsuranceState[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/current-insurances`,
					method: 'GET',
				};
			},
			providesTags: ['CurrentInsurance'],
			// providesTags: (result) =>
			// 	result ? result.map(({ guid }) => ({ type: 'CurrentInsurance', id: guid })) : ['CurrentInsurance'],
		}),
		updateCurrentInsurance: builder.mutation<void, UpdateHistoricalInsuranceRequest>({
			query({ cart_guid, guid, product_guid, policy_identifier, insurer_code }) {
				return {
					url: `/carts/${cart_guid}/current-insurances/${guid}`,
					method: 'PUT',
					body: {
						product_guid,
						policy_identifier,
						insurer_code,
					},
				};
			},
			invalidatesTags: ['CurrentInsurance'],
		}),
		createCurrentInsurance: builder.mutation<CreateHistoricalInsuranceResponse, CreateHistoricalInsuranceRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/current-insurances`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['CurrentInsurance'],
		}),
		deleteCurrentInsurance: builder.mutation<void, DeleteHistoricalInsuranceRequest>({
			query({ cart_guid, guid }) {
				return {
					url: `/carts/${cart_guid}/current-insurances/${guid}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['CurrentInsurance'],
		}),
		deleteAllCurrentInsurances: builder.mutation<void, { cart_guid: string }>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/current-insurances`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['CurrentInsurance'],
		}),
	}),
	overrideExisting: true,
});

export const {
	useCreateCurrentInsuranceMutation,
	useDeleteAllCurrentInsurancesMutation,
	useGetCurrentInsurancesQuery,
	useDeleteCurrentInsuranceMutation,
	useUpdateCurrentInsuranceMutation,
} = currentInsurancesApi;

// These are used serverside in the sales funnel
export const {
	createCurrentInsurance,
	deleteAllCurrentInsurances,
	deleteCurrentInsurance,
	getCurrentInsurances,
	updateCurrentInsurance,
} = currentInsurancesApi.endpoints;

import { rootApi } from '../../../../common/store/root/root.api';

export const classificationsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getClassificationsOfOrganization: builder.query<{ guid: string; description: string }[], void>({
			query() {
				return {
					url: `/classifications`,
					method: 'GET',
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetClassificationsOfOrganizationQuery, useLazyGetClassificationsOfOrganizationQuery } =
	classificationsApi;

export const { getClassificationsOfOrganization } = classificationsApi.endpoints;

import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, ILogo } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { PartnersSection } from '@marketing/components';

export const handleSectionLogoListLarge = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextEle: CmsElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement,
		imageElements: CmsElement[] = elements.filter(({ identifier }) => identifier !== 'title-subtext') as CmsElement[],
		title = getProperty('title', titleSubtextEle.custom_fields);

	const logos: ILogo[] = imageElements
		.filter((item) => item?.media && item?.media?.length > 0)
		.map((item) => {
			const [link] = item.links;

			return {
				src: `${item.media?.[0].path}`,
				alt: `${item.media?.[0].alt}`,
				link,
			};
		});

	return (props) => <PartnersSection {...props} className='pb-96 pt-64' title={`${title}`} logos={logos} />;
};

import cn from 'classnames';
import { FC } from 'react';

import { Icon } from '../../../../../Misc';
import { Text } from '../../../../../TextComponents';
import { ProgressState } from '../ProgressBar';

type Props = {
	label: string;
	state?: ProgressState;
	className?: string;
};

const stepClassNames: Record<ProgressState, string> = {
	inactive: `bg-grayscale0 border-grayscale200 h-8 w-8 lg:h-12 lg:w-12`,
	active: `bg-secondary50 border-secondaryMain h-8 w-8 lg:h-12 lg:w-12`,
	completed: `bg-secondaryMain lg:bg-grayscale0 border-secondaryMain h-8 w-8 lg:h-24 lg:w-24`,
};

export const ProgressStep: FC<Props> = ({ label, state = 'active', className = '' }) => {
	return (
		<li className={cn(className, 'z-10 flex flex-row items-center')}>
			<div className='order-2 ml-12 md:order-1 md:ml-0 md:mr-12'>
				<Text variant='body-xs' weight='medium' color={state === 'inactive' ? 'grayscale300' : 'grayscale600'}>
					{label}
				</Text>
			</div>
			<div className='z-20 order-1 flex h-8 w-8 items-center justify-center md:order-2 lg:h-24 lg:w-24'>
				<div
					className={cn(
						stepClassNames[state],
						'flex items-center justify-center rounded-full border-2 transition-all ease-in-out'
					)}>
					{state === 'completed' && <Icon className='hidden lg:block' name='check' color='grayscale600' size='sm' />}
				</div>
			</div>
		</li>
	);
};

import { PortalDocument, PortalInsurance } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

const insuranceApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getInsurances: builder.query<PortalInsurance[], void>({
			query() {
				return {
					url: `/insurances`,
					method: 'GET',
				};
			},
		}),
		getInsurance: builder.query<PortalInsurance, { insuranceGuid: string }>({
			query({ insuranceGuid }) {
				return {
					url: `/insurances/${insuranceGuid}`,
					method: 'GET',
				};
			},
		}),
		insuranceOverview: builder.mutation<PortalDocument, void>({
			query() {
				return {
					url: `/insurances/insurance-overview`,
					method: 'POST',
				};
			},
			invalidatesTags: ['Overview'],
		}),
		insuranceCertificate: builder.mutation<PortalDocument, { insuranceGuid: string }>({
			query({ insuranceGuid }) {
				return {
					url: `/insurances/${insuranceGuid}/insurance-certificate`,
					method: 'POST',
				};
			},
			invalidatesTags: ['Certificate'],
		}),
		insuranceDocument: builder.mutation<PortalDocument, { insuranceGuid: string; documentGuid: string }>({
			query({ insuranceGuid, documentGuid }) {
				return {
					url: `/insurances/${insuranceGuid}/documents/${documentGuid}`,
					method: 'POST',
				};
			},
			invalidatesTags: ['Documents'],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetInsuranceQuery,
	useGetInsurancesQuery,
	useInsuranceCertificateMutation,
	useInsuranceDocumentMutation,
	useInsuranceOverviewMutation,
} = insuranceApi;

export const { getInsurance, getInsurances, insuranceCertificate, insuranceDocument, insuranceOverview } =
	insuranceApi.endpoints;

import { AcceptanceQuestion, ThemeNames } from '@monorepo/types';

const pathMap: Record<string, string> = {
	onderhoudnl: './acceptanceQuestions_bouw.json',
	anko: './acceptanceQuestions_beauty.json',
	default: './acceptanceQuestions_advies-en-consultancy.json',
};

export const getMockAcceptanceQuestions = async (salesChannel: ThemeNames): Promise<AcceptanceQuestion[]> => {
	const filePath = pathMap[salesChannel] || pathMap['default'];
	const { default: data } = await import(`${filePath}`);
	return data as unknown as AcceptanceQuestion[];
};

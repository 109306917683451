import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@common/store';

export type ChatState = {
	optionsOpen: boolean; // the support options
	chatOpen: boolean; // the chat window
	chatOnline: boolean; // the status indicator
	unreadMessages: number; // the number of unread messages
	chatCookies: boolean; // enable/disable chat cookies
};

const initialState: ChatState = {
	optionsOpen: false,
	chatOpen: false,
	chatOnline: true,
	unreadMessages: 0,
	chatCookies: false,
};

export const chatSlice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		openSupportOptions(state) {
			state.optionsOpen = true;
		},
		closeSupportOptions(state) {
			state.optionsOpen = false;
		},
		toggleSupportOptions(state) {
			state.optionsOpen = !state.optionsOpen;
		},
		openSupportChat(state) {
			state.chatOpen = true;
		},
		closeSupportChat(state) {
			state.chatOpen = false;
		},
		toggleSupportChat(state) {
			state.chatOpen = !state.chatOpen;
		},
		setChatOnline(state, action: PayloadAction<boolean>) {
			state.chatOnline = action.payload;
		},
		setUnreadMessages(state, action: PayloadAction<number>) {
			state.unreadMessages = action.payload;
		},
		setChatCookies(state, action: PayloadAction<boolean>) {
			state.chatCookies = action.payload;
		},
		resetSupport() {
			return initialState;
		},
	},
});

export const {
	openSupportOptions,
	closeSupportOptions,
	toggleSupportOptions,
	openSupportChat,
	closeSupportChat,
	toggleSupportChat,
	setChatOnline,
	setUnreadMessages,
	setChatCookies,
	resetSupport,
} = chatSlice.actions;

export const selectOptionsIsOpen = (state: AppState) => state.chat.optionsOpen;
export const selectChatIsOpen = (state: AppState) => state.chat.chatOpen;
export const selectChatIsOnline = (state: AppState) => state.chat.chatOnline;
export const selectUnreadMessages = (state: AppState) => state.chat.unreadMessages;
export const selectStoreChatCookies = (state: AppState) => state.chat.chatCookies;

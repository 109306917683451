import { FC } from 'react';

import { LandingSectionFAQ } from '@monorepo/components/Misc';
import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleArticleSiteSectionFaq = <P extends object>({ component }: HandlerProps): FC<P> => {
	const title = getProperty('title', component.custom_fields) as string;

	return () => (
		<LandingSectionFAQ
			title={title}
			faqs={component.child_elements.map((child_element) => {
				const icon = getProperty('icon', child_element.custom_fields) as string;
				const question = getProperty('title', child_element.custom_fields) as string;

				return {
					answer: child_element.markdown,
					icon,
					question,
				};
			})}
		/>
	);
};

import { IBlurbSection } from '@monorepo/types';

export const mockBlurbSection: IBlurbSection = {
	title: 'Liever zelf regelen?',
	subtitle:
		'Regelt u liever uw zaken zelf? Dat begrijpen we. Neem de leiding over uw verzekeringsbehoeften en maak gebruik van onze gebruiksvriendelijke tools om uw zaken te beheren zoals u dat wilt.',
	blurbs: [
		{
			icon: 'arrow-circle-right',
			title: 'Zelf regelen in portal',
			description: 'Schadeclaims, andere zaken regelen',
			link: {
				content: 'Naar portal',
				title: 'Naar portal',
				href: '/portal',
			},
		},
		{
			icon: 'newspaper',
			title: 'Perssite',
			description: 'Voor redactionele, media- en persvragen.',
			link: {
				content: 'Naar pers',
				title: 'Naar pers',
				href: '/pers',
			},
		},
		{
			icon: 'file-copy-alt',
			title: 'Kennisbank',
			description: "Vind artikelen, video's en meer hulpbronnen.",
			link: {
				content: 'Naar insights',
				title: 'Naar insights',
				href: '/insights',
			},
		},
	],
};

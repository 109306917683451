import { FC } from 'react';

import { ALL_CALCULATIONS } from '@monorepo/mocks/funnel';
import { CmsContainer } from '@monorepo/types';

import { HandlerProps } from '@cms/componentMapper/componentMapper';
import { SelectableProductCard } from '@funnel/components';

export const handleProductIntro = <P extends object>(container: HandlerProps): FC<P> => {
	return () => (
		<>
			<SelectableProductCard
				variant='lg'
				billingUnit='monthly'
				onCtaClick={() => {}}
				isLoading={false}
				onMoreInfoClick={() => {}}
				product={ALL_CALCULATIONS[0].product}
				content={container as unknown as CmsContainer}
			/>
		</>
	);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const petBreedSchema = (t: TranslationType, isAgreementRequired = false) =>
	z.object({
		search: z.string().optional(),
		terms_agreement: z
			.boolean({ required_error: t('validation.required') })
			.optional()
			.superRefine((val, ctx) => {
				if (isAgreementRequired) {
					if (!val) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							message: t('validation.required'),
						});
					}
				}
			}),
		breeds: z
			.object({
				label: z.string(),
				value: z.string(),
			})
			.array()
			.min(1, t('validation.required'))
			.max(2, { message: t('validation.max', { max: 2 }) }),
	});

export type PetBreedSchema = z.infer<ReturnType<typeof petBreedSchema>>;

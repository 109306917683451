import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { PHONE_REGEX } from '../utils';

export const contactSchema = (t: TranslationType) =>
	z.object({
		first_name: z.string({ required_error: t('validation.required') }),
		last_name: z.string({ required_error: t('validation.required') }),
		email: z.string({ required_error: t('validation.required') }).email(t('validation.email')),
		phone: z
			.string({ required_error: t('validation.required') })
			.regex(PHONE_REGEX, t('validation.phone'))
			.optional(),
		terms_agreement: z.literal<boolean>(true, { required_error: t('validation.required') }),
	});

export type ContactSchema = z.infer<ReturnType<typeof contactSchema>>;

import { skipToken } from '@reduxjs/toolkit/query';
import cn from 'classnames';
import Link from 'next/link';
import { FC } from 'react';

import { Tag } from '@monorepo/components/DataDisplay';
import { TextButton } from '@monorepo/components/DataEntry';
import { Icon, LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { ANALYTICS_ID, transformToCurrency } from '@monorepo/shared/lib/utils';
import { IColumnSection } from '@monorepo/types';

import { useGetProductQuery } from '@cms/store';

export type ColumnSectionProps = IColumnSection & {
	className?: string;
};

export const ColumnSection: FC<ColumnSectionProps> = ({
	title,
	subTitle,
	ctaText,
	pricePretext,
	priceSubtext,
	productGuid,
	className,
}) => {
	const { data } = useGetProductQuery(productGuid ? { productGuid } : skipToken);

	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex w-full flex-col max-md:space-y-48 md:flex-row md:space-x-48'>
					<div className='flex flex-col space-y-12 md:basis-1/3 md:justify-center'>
						<Text as='h2' variant='display-4' weight='semibold'>
							{title}
						</Text>
						<Text as='p' variant='body-l' color='grayscale500'>
							{subTitle}
						</Text>
					</div>
					<div className='flex md:basis-2/3'>
						<div className='shadow-1 rounded-18 flex flex-col space-y-24 p-24'>
							<div className='flex flex-row items-center space-x-16'>
								<div className='bg-primary50 rounded-12 flex h-56 w-56 items-center justify-center'>
									<Icon size='lg' name={`${data?.icon_name}`} />
								</div>
								<Text variant='body-l' weight='semibold' className='hyphens-auto'>
									{data?.name}
								</Text>
							</div>
							<div className='flex flex-row space-x-12'>
								<Tag text={data?.tag} status='info' />
								<Text
									as='p'
									variant='body-xs'
									color='info300'
									className='bg-info50 rounded-12 inline shrink px-12 py-4'>
									{pricePretext} <span className='font-semibold'>{transformToCurrency(data?.minimum_price)}</span>{' '}
									{priceSubtext}
								</Text>
							</div>

							<Text variant='body-m' color='grayscale400'>
								{data?.detailed_description}
							</Text>

							<div className='ml-auto w-fit'>
								<Link
									href={{
										pathname: data?.link.href,
									}}
									title={data?.link.title}
									target={data?.link.target}>
									<TextButton
										name={ANALYTICS_ID.landing_calculate_premium_cta}
										size='sm'
										label={`${ctaText}`}
										iconRight='angle-right'
									/>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

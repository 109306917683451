import { FC } from 'react';

import { LandingSectionHero } from '@monorepo/components/Misc';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';

export const handleSectionHeroImage = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const titleSubtextElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const title = getProperty('title', titleSubtextElement.custom_fields);
	const subtitle = getProperty('subtext', titleSubtextElement.custom_fields);

	const buttonElement = elements.find(({ identifier }) => identifier === 'button') as CmsElement;
	const [link] = buttonElement.links;

	const imageElement = elements.find(({ identifier }) => identifier === 'image') as CmsElement;
	const [image] = imageElement.media;

	return () => (
		<LandingSectionHero
			title={`${title}`}
			link={link}
			subTitle={`${subtitle}`}
			buttonLabel={`${buttonElement.markdown}`}
			image={image}
			theme={rootContext?.theme}
		/>
	);
};

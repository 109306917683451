import classNames from 'classnames';
import { FieldValues } from 'react-hook-form';

import { FormBaseInputDefaultProps, FormBaseInputProps } from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormBigTextInputProps<TFormValues extends FieldValues> = {
	hint?: string;
	hideError?: boolean;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<FormBaseInputProps, 'name' | 'errors' | 'type'>;

export const FormBigTextInput = <TFormValues extends Record<string, unknown>>({
	name,
	register,
	rules,
	errors,
	hint,
	hideError,
	value,
	...props
}: FormBigTextInputProps<TFormValues>): JSX.Element => (
	<FormHelper errors={errors} hint={hint} hideError={hideError}>
		<input
			type='text'
			name={name}
			{...props}
			className={classNames(
				value ? 'caret-info300' : 'caret-grayscale100',
				// default
				'placeholder:text-grayscale100 text-grayscale600 border-none text-[60px] font-semibold placeholder:text-[60px] placeholder:font-semibold focus:ring-0 md:text-[100px] md:placeholder:text-[100px]',
				// hover
				'hover:enabled:caret-info300',
				// active
				'active:enabled:caret-info300',
				// focus
				'focus:enabled:caret-info300',
				// disabled
				'disabled:caret-grayscale100 disabled:text-grayscale100'
			)}
			{...(register && register(name, rules))}
		/>
	</FormHelper>
);

import { Trans, useTranslation } from 'next-i18next';
import Link from 'next/link';
import { type FC } from 'react';

import { Form } from '@monorepo/components/DataEntry';
import { ContactSchema } from '@monorepo/shared/lib/schemas';

import { paths } from '@common/utils';

type SendMailFormProps = {
	onSubmit: (fields: ContactSchema) => void;
};

export const RequestContactForm: FC<SendMailFormProps> = ({ onSubmit }) => {
	const { t } = useTranslation(['common']);

	return (
		<Form.Context<ContactSchema>>
			{({ handleSubmit, formState: { errors }, register }) => {
				return (
					<Form
						id='request-contact-form'
						onSubmit={handleSubmit(onSubmit)}
						className='grid grid-cols-2 gap-x-20 gap-y-20 sm:gap-y-24'>
						<Form.Group className='col-span-2 sm:col-span-1'>
							<Form.Label>{t('common.firstName')}</Form.Label>
							<Form.TextInput
								fieldSize='lg'
								name='first_name'
								autoComplete='first-name'
								autoCapitalize='words'
								errors={errors?.first_name}
								placeholder={t('common.firstName')}
								register={register}
								className='w-full'
							/>
						</Form.Group>

						<Form.Group className='col-span-2 sm:col-span-1'>
							<Form.Label>{t('common.lastName')}</Form.Label>
							<Form.TextInput
								fieldSize='lg'
								name='last_name'
								autoComplete='family-name'
								autoCapitalize='words'
								errors={errors?.last_name}
								placeholder={t('common.lastName')}
								register={register}
								className='w-full'
							/>
						</Form.Group>

						<Form.Group className='col-span-2'>
							<Form.Label>{t('common.email')}</Form.Label>
							<Form.EmailInput
								fieldSize='lg'
								name='email'
								autoCapitalize='off'
								errors={errors?.email}
								startIcon='envelope'
								placeholder={t('common.yourEmail')}
								register={register}
								className='w-full'
							/>
						</Form.Group>

						<Form.Group className='col-span-2'>
							<Form.Label>{t('common.mobilePhone')}</Form.Label>
							<Form.PhoneInput
								fieldSize='lg'
								name='phone'
								autoCapitalize='off'
								placeholder={t('common.mobilePhone')}
								startIcon='mobile-android'
								errors={errors?.phone}
								register={register}
								className='w-full'
							/>
						</Form.Group>

						<Form.Group className='col-span-2'>
							<Form.Checkbox
								name='terms_agreement'
								label={
									<Trans
										i18nKey='forms.sendMailForm.acceptTermsLabel'
										components={{
											terms: (
												<Link
													href={paths.content.terms}
													rel='noreferrer'
													target='_blank'
													className='text-info300 underline'
												/>
											),
											privacy: (
												<Link
													href={paths.content.privacy_statement}
													rel='noreferrer'
													target='_blank'
													className='text-info300 underline'
												/>
											),
										}}
									/>
								}
								register={register}
							/>
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};

import { PremiumQuestion, ThemeNames } from '@monorepo/types';

const pathMap: Record<string, string> = {
	onderhoudnl: './premiumQuestions_bouw.json',
	anko: './premiumQuestions_beauty.json',
	default: './premiumQuestions_advies-en-consultancy.json',
};

export const getMockPremiumQuestions = async (salesChannel: ThemeNames): Promise<PremiumQuestion[]> => {
	const filePath = pathMap[salesChannel] || pathMap['default'];
	const { default: data } = await import(`${filePath}`);
	return data as unknown as PremiumQuestion[];
};

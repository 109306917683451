import { Insurer } from '@monorepo/types';

import { dataRootApi } from '../../root/root';

export const insurersApi = dataRootApi.injectEndpoints({
	endpoints: (builder) => ({
		getInsurers: builder.query<Insurer[], void>({
			query() {
				return {
					url: '/insurers',
					method: 'GET',
				};
			},
			providesTags: (result) => {
				if (result) {
					return result.map(({ code }) => ({ type: 'Insurer', id: code }));
				}

				/* istanbul ignore next */
				return ['Insurer'];
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetInsurersQuery, useLazyGetInsurersQuery } = insurersApi;

export const { getInsurers } = insurersApi.endpoints;

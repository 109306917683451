import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';

import { AcceptanceStateStatus } from '@monorepo/types';

import { ActionButton } from '../../DataEntry';
import { Divider, IconButton } from '../../Misc';
import { Text } from '../../TextComponents';
import { ShoppingCartFooter } from '../ShoppingCart/Footer/ShoppingCartFooter';

export type ActivitiesCartState = 'collapsed' | 'expanded';

type ActivitiesCartProps = {
	activities: { guid: string; name: string }[];
	ctaValid: boolean;
	ctaLoading: boolean;
	handleDeleteActivity: (guid: string) => void;
	handleCta: () => void;
	defaultInternalState?: ActivitiesCartState;
};

const cartContentTransition = {
	expanded: {
		height: 'auto',
		opacity: 1,
	},
	collapsed: {
		height: 0,
		opacity: 0,
	},
};

export const ActivitiesCart: FC<ActivitiesCartProps> = ({
	activities,
	ctaValid = false,
	ctaLoading = false,
	handleDeleteActivity,
	handleCta,
	defaultInternalState = 'collapsed',
}) => {
	const showFull = true;
	const { t } = useTranslation(['common']);
	const [internalState, setInternalState] = useState<ActivitiesCartState>(defaultInternalState);

	useEffect(() => {
		if (!activities || activities.length === 0) {
			setInternalState('collapsed');
		} else {
			setInternalState('expanded');
		}
	}, [activities]);

	return (
		<AnimatePresence>
			<motion.div
				className={cn(
					'shadow-5 rounded-t-18 md:rounded-b-18 bg-grayscale0 relative z-50 flex max-h-[85vh] flex-col overflow-hidden py-20 lg:py-24'
				)}
				animate={{ height: 'auto' }}
				transition={{ duration: 0.3 }}>
				<div className='flex flex-row items-center px-32'>
					<Text variant='body-m' color='grayscale600' weight='medium'>
						Bedrijfsactiviteiten{' '}
						{activities.length > 0 && <span className='text-info300'>{`(${activities.length})`}</span>}
					</Text>
					<div className='ml-auto'>
						<ActionButton
							onClick={() => setInternalState((s) => (s === 'expanded' ? 'collapsed' : 'expanded'))}
							icon='arrow-up'
							rotate={internalState === 'expanded'}
							disabled={!activities || activities.length <= 0}
						/>
					</div>
				</div>

				<motion.ul
					className={cn(
						'flex flex-col gap-y-20 overflow-hidden overflow-y-scroll px-32 lg:gap-y-24',
						internalState === 'expanded' && 'mt-20 lg:mt-24'
					)}
					variants={cartContentTransition}
					initial={internalState === 'expanded' ? 'expanded' : 'collapsed'}
					animate={internalState === 'expanded' ? 'expanded' : 'collapsed'}>
					<AnimatePresence>
						{activities?.map(({ guid, name }) => (
							<motion.li
								className='bg-grayscale0 relative z-20'
								key={`shopping-cart-animate-item-${guid}`}
								initial={{ opacity: 0, height: 0, zIndex: 0 }}
								animate={{ opacity: 1, height: 'auto' }}
								exit={{ opacity: 0, height: 0, zIndex: 0 }}
								transition={{ duration: 0.3 }}>
								<div className='flex flex-row justify-between'>
									<Text variant='body-s' color='grayscale600' weight='regular'>
										{name}
									</Text>
									<IconButton
										size='md'
										iconName='times'
										className='hover:text-error300 transition-colors duration-300 ease-out'
										onClick={() => handleDeleteActivity(guid)}
									/>
								</div>
							</motion.li>
						))}

						{!showFull && <Divider />}
					</AnimatePresence>
				</motion.ul>

				<ShoppingCartFooter
					price={0}
					tax={0}
					acceptanceState={AcceptanceStateStatus.Accepted}
					showPrice={false}
					className='mt-20 lg:mt-24'
					customFooterLabel={t('common.next')}
					customFooterIcon='angle-right'
					showProceedLater={false}
					showCta={true}
					ctaValid={ctaValid}
					ctaLoading={ctaLoading}
					handleCta={handleCta}
				/>
			</motion.div>
		</AnimatePresence>
	);
};

import { sortAscending } from '@monorepo/shared/lib/utils';
import { AnswerOption, IPremiumQuestionGroup, Question, QuestionAnswerMultipleChoice } from '@monorepo/types';

interface SequencedObject {
	sequence: number;
	reference: string;
	[key: string]: unknown;
}

interface IGetNextSequence<T extends SequencedObject> {
	objects: T[];
	currentReference?: string;
}

export function getNextSequenceBasedOnReference<T extends SequencedObject>({
	objects,
	currentReference,
}: IGetNextSequence<T>): string | undefined {
	// Sort the objects by sequence
	const sortedBySequenceNumber = [...objects].sort((a, b) => sortAscending(a.sequence, b.sequence));

	if (!sortedBySequenceNumber[0]) {
		return undefined;
	}

	// If no current sequence is specified, the first sequenced object is returned from the sorted objects
	if (!currentReference) {
		return sortedBySequenceNumber[0].reference;
	}

	// Find the index of the currentReference object
	const indexOfCurrentSequenceObject = sortedBySequenceNumber.findIndex((s) => s.reference === currentReference);

	// If the found currentSequence object is not the last in the array, return the following
	if (indexOfCurrentSequenceObject < sortedBySequenceNumber.length - 1) {
		return sortedBySequenceNumber[indexOfCurrentSequenceObject + 1].reference;
	}

	return undefined;
}

interface IGetGroupBasedOnQuestionGuids {
	questions: Array<string>;
	groups: Array<IPremiumQuestionGroup>;
}

export function getGroupBasedOnQuestionGuid({
	questions,
	groups,
}: IGetGroupBasedOnQuestionGuids): IPremiumQuestionGroup | undefined {
	const groupWithSameAMountOfQuestions = [...groups].filter((group) => group.questions.length === questions.length);

	if (groupWithSameAMountOfQuestions.length === 0) {
		return undefined;
	}

	const groupWithSameGuid = groupWithSameAMountOfQuestions.find((group) =>
		group.questions.every((groupQuestion) => questions.some((guid) => guid === groupQuestion.guid))
	);

	return groupWithSameGuid;
}

interface QuestionObject {
	reference: string;
	[key: string]: unknown;
}

type ExtractedQuestions<T extends QuestionObject, K extends string> = Record<K, T>;

export function extractQuestionsFromGroup<T extends QuestionObject, K extends string>(
	questions: T[],
	extract: K[]
): ExtractedQuestions<T, K> {
	return [...extract].reduce(
		(acc, key) => {
			const foundQuestion = questions.find((question) => question.reference === key);

			if (foundQuestion && acc && key) {
				acc[key] = foundQuestion; // If not found, `undefined` will be the value.
			}

			return acc;
		},
		{} as ExtractedQuestions<T, K>
	);
}

export function extractSelectOptionsFromQuestion(options: AnswerOption[]) {
	return [...options].map((option) => ({
		label: option.value,
		value: option.key,
	}));
}

export function extractMultipleChoiceAnswersFromQuestions(questions: Question[]): Array<QuestionAnswerMultipleChoice> {
	const answers: Array<QuestionAnswerMultipleChoice> = [];

	for (const question of questions) {
		if (question.answer?.multiple_choice) {
			const match = question.response_options?.find(({ value }) => value === question.answer?.multiple_choice);

			if (match) {
				answers.push({ key: match.key, value: match.value });
			}
		}
	}
	return answers;
}

export function extractFirstResponseOptionsFromQuestions(questions: Question[]): Array<AnswerOption> {
	const questionWithResponseOptions = questions.find(
		(question) => question.response_options && question.response_options?.length > 0
	);

	if (questionWithResponseOptions && questionWithResponseOptions.response_options) {
		return questionWithResponseOptions.response_options;
	}

	return [];
}

import { FC, ReactNode } from 'react';

import { BaseModal } from '../ModalWrapper/ModalWrapper';

type Props = {
	id: string;
	title: string;
	text: string;
	cancelButton: ReactNode;
	submitButton: ReactNode;
};

export const Prompt: FC<Props> = ({ id, title, text, ...rest }) => {
	return (
		<BaseModal
			className='w-fit md:max-w-[480px]'
			closable={true}
			id={id}
			title={title}
			text={text}
			buttonsFullWidth={true}
			{...rest}
		/>
	);
};

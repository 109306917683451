import type { QuestionPageData } from '@monorepo/types';

export const preconditionsPageMap = new Map<string, QuestionPageData>();
preconditionsPageMap
	.set('current-insurance', {
		slug: 'current-insurance',
		i18nKey: 'currentInsurance',
	})
	.set('cancel-current-insurance', {
		slug: 'cancel-current-insurance',
		i18nKey: 'cancelCurrentInsurance',
	})
	.set('acceptance-statement', {
		slug: 'acceptance-statement',
		i18nKey: 'acceptanceStatement',
	})
	.set('cyber-acceptance-statement', {
		slug: 'cyber-acceptance-statement',
		i18nKey: 'cyberAcceptanceStatement',
	})
	.set('historical-claims', {
		slug: 'historical-claims',
		i18nKey: 'historicalClaims',
	})
	.set('historical-illnesses', {
		slug: 'historical-illnesses',
		i18nKey: 'historicalClaims',
	})
	.set('claims-overview', {
		slug: 'claims-overview',
		i18nKey: 'claimsOverview',
	})
	.set('illnesses-overview', {
		slug: 'illnesses-overview',
		i18nKey: 'claimsOverview',
	})
	.set('closing-statement', {
		slug: 'closing-statement',
		i18nKey: 'closingStatement',
	})
	.set('ubos', {
		slug: 'ubos',
		i18nKey: 'ubos',
	});

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const transferServiceSchema = (t: TranslationType) =>
	z.object({
		items: z.array(
			z.object({
				insurer: z.string().min(1, { message: t('validation.insurerRequired') }),
				policyNumber: z.string().min(1, { message: t('validation.policyNumberRequired') }),
			})
		),
	});

export type TransferServiceSchema = z.infer<ReturnType<typeof transferServiceSchema>>;

import classNames from 'classnames';
import Image from 'next/image';
import { FC, ReactNode } from 'react';

import { BaseCard } from '@monorepo/components/DataDisplay';
import { Icon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { CmsMedia } from '@monorepo/types';

type BlurbCardProps = {
	className?: string;
	icon?: string;
	image?: CmsMedia;
	title: string;
	description: string;
	isElevated?: boolean;
	enableEffects?: boolean;
	action?: ReactNode;
};

export const BlurbCard: FC<BlurbCardProps> = ({
	icon,
	image,
	title,
	description,
	isElevated = true,
	enableEffects = true,
	action,
	className,
}) => (
	<BaseCard
		as='div'
		isElevated={isElevated}
		enableEffects={enableEffects}
		className={classNames('bg-grayscale0 flex h-full flex-col space-y-12 p-24', className)}>
		{image && (
			<div className='relative h-[80px] w-[90px] '>
				<Image sizes={generateSizesString()} src={image.path} alt={image.alt} fill className='object-contain' />
			</div>
		)}
		{icon && <Icon name={icon} size='lg' color='info300' />}
		<Text variant='display-6' weight='semibold' color='primaryMain'>
			{title}
		</Text>
		<Text variant='body-s' weight='regular' color='grayscale400' className='flex-1'>
			{description}
		</Text>
		{action && action}
	</BaseCard>
);

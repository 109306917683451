import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { CheckboxAccordion } from '@monorepo/components/DataDisplay/Accordions/CheckboxAccordion/CheckboxAccordion';
import { Form, StandardButton, TextButton } from '@monorepo/components/DataEntry';
import { ButtonGroup } from '@monorepo/components/Misc';
import { CheckList } from '@monorepo/components/TextComponents';
import { activityGroupSchema, ActivityGroupSchema } from '@monorepo/shared/lib/schemas';
import { ClassificationChild } from '@monorepo/types';

import { Modal } from '@common/components';
import { showModal } from '@common/store';

type ActivityFormProps = {
	activeActivities: ClassificationChild[];
	classifications: ClassificationChild[];
	isLoading?: boolean;
	onSubmit: (fields: ActivityGroupSchema) => void;
	onCancel: () => void;
};

export const ActivityForm: FC<ActivityFormProps> = ({ activeActivities, classifications, onSubmit, onCancel }) => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();

	const [classificationTotals, setClassificationTotals] = useState<{ guid: string; total: number }[]>(
		classifications.map((classification) => ({
			guid: classification.guid,
			total: 0,
		}))
	);

	const activitiesForm = useForm<ActivityGroupSchema>({
		resolver: zodResolver(activityGroupSchema(t)),
		mode: 'all',
	});

	const handleChange = useCallback(() => {
		const totalsPerClassification = activitiesForm.getValues('classifications').map((classification) => ({
			guid: classification.guid,
			total: classification.activity.filter(({ checked }) => checked).length,
		}));

		setClassificationTotals(totalsPerClassification);
	}, [activitiesForm]);

	useEffect(() => {
		const values = classifications
			// .flatMap((classification) => classification.children)
			.map((classification) => ({
				additional: true,
				guid: classification.guid,
				activity: classification.children?.map((activity) => {
					const isChecked = activeActivities
						.flatMap(({ children }) => children.flatMap(({ guid }) => guid))
						.includes(activity.guid);
					return { guid: activity.guid, checked: isChecked };
				}),
			}));

		activitiesForm.reset({
			classifications: values,
		});
		handleChange();
	}, [activeActivities, activitiesForm, classifications, handleChange]);

	return (
		<Form onSubmit={activitiesForm.handleSubmit(onSubmit)} className='flex flex-col gap-y-24' onChange={handleChange}>
			{classifications.map((classification, index) => {
				const total = classificationTotals.find(({ guid }) => guid === classification.guid)?.total ?? 0;

				return (
					<CheckboxAccordion
						key={classification.guid}
						title={`${classification.name} ${total ? `(${total})` : ''}`}
						icon={classification.icon}
						isDefaultOpen={total > 0}>
						{classification.children?.map((activity, index2) => (
							<div key={activity.guid} className='flex flex-row py-4' role='list-item'>
								<Form.Checkbox
									id={activity.guid}
									label={activity.name}
									name={`classifications.${index}.activity.${index2}.checked`}
									register={activitiesForm.register}
									endIcon={activity.content && activity.content.length > 0 ? 'info-circle' : ''}
									onEndIconClick={() => dispatch(showModal(activity.guid))}
								/>

								{activity.content && activity.content.length > 0 && (
									<Modal id={activity.guid} title={activity.name}>
										<CheckList items={activity.content} />
									</Modal>
								)}
							</div>
						))}
					</CheckboxAccordion>
				);
			})}

			<ButtonGroup className='justify-between'>
				<TextButton onClick={onCancel} variant='standard' iconLeft='angle-left-b' label={t('common.cancel')} />
				<StandardButton
					type='submit'
					variant='cta'
					disabled={!activitiesForm.formState.isValid}
					iconRight='angle-right-b'
					label={t('common.next')}
				/>
			</ButtonGroup>
		</Form>
	);
};

import cn from 'classnames';
import { createElement, FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
	as?: 'ul' | 'ol';
	className?: string;
};

export const List: FC<Props> = ({ as, className = '', ...props }) => {
	return createElement(as || 'ul', {
		className: cn(className, 'flex flex-col space-y-12 md:space-y-16'),
		...props,
	});
};

import { createContext } from 'react';

type FlagsContext = {
	getString: (key: string) => string;
	getNumber: (key: string) => number;
	getBoolean: (key: string) => boolean;
};

export const FlagsContext = createContext<FlagsContext>({
	getString: () => '',
	getNumber: () => 0,
	getBoolean: () => false,
});

import { useTranslation } from 'next-i18next';
import { FC, ReactElement } from 'react';

import { Icon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';

type ModalHeaderProps = {
	icon?: string;
	title: string | ReactElement;
	subTitle?: string | ReactElement;
	closable?: boolean;
	closeModal?: () => void;
};

export const ModalHeader: FC<ModalHeaderProps> = ({ icon, title, subTitle, closable = true, closeModal }) => {
	const { t } = useTranslation(['common']);

	return (
		<div className='bg-grayscale0 sticky top-0 z-50 flex w-full flex-row px-24 pb-8 pt-64 md:px-56 md:pt-80'>
			<div className='flex flex-row items-start space-x-12'>
				{icon && <Icon name='comment-info-alt' size='lg' color='primaryMain' />}

				<div className='space-y-4'>
					<Text variant='display-6' weight='semibold'>
						{title}
					</Text>
					{subTitle && (
						<Text variant='body-m' color='grayscale300'>
							{subTitle}
						</Text>
					)}
				</div>
			</div>

			{closable && (
				<div className='ml-auto'>
					<button
						name='CloseModal'
						title={t('common.close')}
						type='button'
						className='group absolute right-0 top-0 flex items-center p-24 transition-all'
						onClick={closeModal}>
						<Icon
							name='times'
							color='inherit'
							className='text-info300 group-hover:text-info400 group-active:text-info500 text-[24px] transition-colors'
						/>
					</button>
				</div>
			)}
		</div>
	);
};

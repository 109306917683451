import { MarketingFooterItems } from '@monorepo/types';

export const mockFooterItems: MarketingFooterItems = {
	columns: [
		{
			title: 'Wat we verzekeren',
			items: ['Aansprakelijkheid', 'Werknemersschade', 'Ongevallen', 'Alle verzekeringen'].map((content) => ({
				content,
				title: content,
				href: `/verzekeringen/${content.toLowerCase().replaceAll(' ', '-')}`,
			})),
		},
		{
			title: 'Wie we verzekeren',
			items: ['Werkvelden'].map((content) => ({
				content,
				title: content,
				href: `/${content.toLowerCase()}`,
			})),
		},
		{
			title: 'Māia',
			items: [
				{
					content: 'Veelgestelde vragen',
					title: 'Veelgestelde vragen',
					href: '/veelgestelde-vragen',
				},
				{
					content: 'Claims',
					title: 'Claims',
					href: '/claims',
				},
				{
					content: 'Klantenservice',
					title: 'Klantenservice',
					href: '/klantenservice',
				},
				{
					content: 'Over Māia',
					title: 'Over Māia',
					href: '/about-us',
				},
			],
		},
		{
			title: 'Partners',
			items: [
				{
					content: 'Affiliates',
					title: 'Affiliates',
					href: '/affiliates',
				},
				{
					content: 'Embedded Insurance',
					title: 'Embedded Insurance',
					href: '/embedded-insurance',
				},
				{
					title: 'Media assets',
					content: 'Media assets',
					href: '/media-assets',
				},
			],
		},
	],
	links: [
		{
			content: 'Privacybeleid',
			title: 'Privacybeleid',
			href: '/content/privacybeleid',
		},
		{
			content: 'Algemene voorwaarden',
			title: 'Algemene voorwaarden',
			href: '/content/algemene-voorwaarden',
		},
		{
			content: 'Cookies',
			title: 'Cookies',
			href: '/content/cookiebeleid',
		},
	],
	socials: [
		{
			image: {
				path: '/images/socials/facebook.svg',
				alt: 'Facebook',
			},
			link: {
				href: 'https://www.facebook.com/maia',
				title: 'Facebook',
				content: 'Facebook',
			},
		},
		{
			image: {
				path: '/images/socials/instagram.svg',
				alt: 'Instagram',
			},
			link: {
				href: 'https://www.instagram.com/maia',
				title: 'Instagram',
				content: 'Instagram',
			},
		},
		{
			image: {
				path: '/images/socials/x.svg',
				alt: 'X',
			},
			link: {
				href: 'https://www.x.com/maia',
				title: 'X',
				content: 'X',
			},
		},
		{
			image: {
				path: '/images/socials/youtube.svg',
				alt: 'Youtube',
			},
			link: {
				href: 'https://www.youtube.com/maia',
				content: 'youtube',
				title: 'Youtube',
			},
		},
	],
	proofs: [
		{
			image: {
				path: `/images/marketing/footer/gdpr-compliant.svg`,
				alt: 'GDPR compliant',
			},
		},
	],
};

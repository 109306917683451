import { cva, VariantProps } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';

type TableProps = {
	data: Array<TableHeadProps['row']>;
	highlightedColumnIndex?: number;
	withRowHeader?: boolean;
	withStickyRowHeader?: boolean;
	className?: string;
};

export function Table(props: TableProps) {
	const { data, withRowHeader = false, withStickyRowHeader = false, highlightedColumnIndex, className } = props;

	return (
		<div
			role='table'
			className={twMerge(
				'grid max-w-[1500px] grid-cols-[repeat(5,_minmax(max-content,_1fr))] overflow-x-auto',
				className
			)}>
			{data.map((row, index) =>
				match({ index })
					.with({ index: 0 }, () => (
						<TableHead row={row} withRowHeader={withRowHeader} withStickyRowHeader={withStickyRowHeader} />
					))
					.with({ index: data.length - 1 }, () => (
						<TableRow
							withStickyRowHeader={withStickyRowHeader}
							row={row}
							withRowHeader={withRowHeader}
							isLastRow
							highlightedColumnIndex={highlightedColumnIndex}
						/>
					))
					.otherwise(() => (
						<TableRow
							row={row}
							withRowHeader={withRowHeader}
							highlightedColumnIndex={highlightedColumnIndex}
							withStickyRowHeader={withStickyRowHeader}
						/>
					))
			)}
		</div>
	);
}

type TableHeadProps = {
	row: Array<TableCellProps['children'] | 'blank'>;
	withRowHeader: boolean;
	withStickyRowHeader: boolean;
};

function TableHead(props: TableHeadProps) {
	const { row, withRowHeader, withStickyRowHeader } = props;

	return (
		<ul role='rowgroup' className='col-span-full grid grid-cols-subgrid'>
			{row.map((cell, index) => (
				<>
					{match({ index, withRowHeader })
						.with({ index: 0, withRowHeader: false }, () => (
							<TableCell className='border-t' position='tl'>
								{cell}
							</TableCell>
						))
						.with({ index: 0, withRowHeader: true }, () => (
							<TableCell className='border-t' isRowHeader isSticky={withStickyRowHeader}>
								{cell}
							</TableCell>
						))
						.with({ index: 1, withRowHeader: true }, () => (
							<TableCell className='border-t' position='tl'>
								{cell}
							</TableCell>
						))
						.with({ index: row.length - 1 }, () => (
							<TableCell className='border-t' position='tr'>
								{cell}
							</TableCell>
						))
						.otherwise(() => (
							<TableCell className='border-t'>{cell}</TableCell>
						))}
				</>
			))}
		</ul>
	);
}

type TableRowProps = {
	row: Array<TableCellProps['children'] | 'blank'>;
	withRowHeader: boolean;
	isLastRow?: boolean;
	highlightedColumnIndex?: number;
	withStickyRowHeader: boolean;
};

function TableRow(props: TableRowProps) {
	const { row, isLastRow = false, withRowHeader, withStickyRowHeader, highlightedColumnIndex } = props;

	return (
		<ul role='row' className='col-span-full grid grid-cols-subgrid'>
			{row.map((cell, index) => {
				const isBlankCell = cell === 'blank';
				return (
					<>
						{match({ index, isLastRow, withRowHeader })
							.with({ index: 0, withRowHeader: true }, () => (
								<TableCell isRowHeader isSticky={withStickyRowHeader}>
									{cell}
								</TableCell>
							))
							.with({ index: row.length - 1, isLastRow: true }, () => <TableCell position='br'>{cell}</TableCell>)
							.with({ index: 1, isLastRow: true, withRowHeader: true }, () => (
								<TableCell position='bl' isHighlighted={highlightedColumnIndex === index}>
									{cell}
								</TableCell>
							))
							.with({ index: 0, isLastRow: true }, () => (
								<TableCell position='bl' isHighlighted={highlightedColumnIndex === index}>
									{cell}
								</TableCell>
							))
							.otherwise(() => (
								<TableCell isRowHeader={isBlankCell} isHighlighted={highlightedColumnIndex === index}>
									{cell}
								</TableCell>
							))}
					</>
				);
			})}
		</ul>
	);
}

type TableCellProps = {
	children: ReactNode;
	className?: string;
} & TableCellVariantProps;

function TableCell(props: TableCellProps) {
	const { children, className, isRowHeader = false, isSticky = false, isHighlighted = false, position } = props;

	const role = isRowHeader ? 'rowheader' : 'cell';

	return (
		<li role={role} className={twMerge(tableCell({ isSticky, isHighlighted, position, isRowHeader }), className)}>
			{children}
		</li>
	);
}

const tableCell = cva(
	[
		'px-12',
		'py-24',
		'border-grayscale200',
		'border-b',
		'border-l',
		'last:border-r',
		'flex',
		'items-center',
		'justify-center',
	],
	{
		variants: {
			position: {
				tl: ['rounded-tl-12'],
				tr: ['rounded-tr-12'],
				bl: ['rounded-bl-12'],
				br: ['rounded-br-12'],
			},
			isSticky: {
				true: ['sticky', 'left-0', 'z-10'],
				false: [],
			},
			isHighlighted: {
				true: ['bg-primary50'],
				false: ['bg-grayscale0'],
			},
			isRowHeader: {
				true: ['border-transparent', 'justify-end'],
			},
		},
	}
);

type TableCellVariantProps = VariantProps<typeof tableCell>;

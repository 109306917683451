import { cva, VariantProps } from 'class-variance-authority';

export const item = cva(
	[
		'flex',
		'items-center',
		'gap-x-4',
		'relative',
		'border',
		'border-grayscale200',
		'bg-grayscale0',
		'h-40',
		'px-16',
		'md:px-20',
		'hover:z-10',
		'data-on:z-10',
		'text-primaryMain',
		'data-[disabled]:text-grayscale300',
	],
	{
		variants: {
			position: {
				start: ['rounded-l-6'],
				middle: ['-ml-[1px]'],
				end: ['rounded-r-6', '-ml-[1px]'],
			},
			intent: {
				default: [
					'hover:bg-primary50',
					'active:bg-primary100',
					'data-on:bg-primary100',
					'data-on:border-primaryMain',
					'hover:data-on:bg-primary50',
					'hover:data-on:border-primaryMain',
					'disabled:bg-grayscale50',
					'disabled:border-grayscale200',
					'disabled:state-checked:bg-greyscale200',
				],
				yesno: [''],
			},
		},
		defaultVariants: {
			intent: 'default',
		},
		compoundVariants: [
			{
				intent: 'yesno',
				position: 'start',
				class: [
					'hover:border-success300',
					'hover:bg-grayscale0',
					'active:bg-success50',
					'data-on:bg-success50',
					'data-on:border-success300',
				],
			},
			{
				intent: 'yesno',
				position: 'end',
				class: [
					'hover:border-error300',
					'hover:bg-grayscale0',
					'active:bg-error50',
					'data-on:bg-error50',
					'data-on:border-error300',
				],
			},
		],
	}
);

export const iconVariant = cva([], {
	variants: {
		position: {
			start: [],
			middle: [],
			end: [],
		},
		intent: {
			default: 'text-grayscale500',
			yesno: '',
		},
	},
	compoundVariants: [
		{
			intent: 'yesno',
			position: 'start',
			class: ['text-success300'],
		},
		{
			intent: 'yesno',
			position: 'end',
			class: ['text-error300'],
		},
	],
});

export type ItemPositionVariantProps = VariantProps<typeof item>['position'];
export type ItemIntentVariantProps = VariantProps<typeof item>['intent'];

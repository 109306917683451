import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { cva, VariantProps } from 'class-variance-authority';
import { ComponentProps, forwardRef } from 'react';

import { Icon } from '@monorepo/shared/icon';
import { Text } from '@monorepo/shared/typography';

import { Label } from '../label/label';

const checkbox = cva(
	[
		'rounded-4',
		'border-grayscale300',
		'bg-grayscale0',
		'hover:border-primaryMain',
		'hover:bg-primary50',
		'active:bg-primary100',
		'flex',
		'border',
		'disabled:bg-grayscale50',
		'disabled:border-grayscale200',
	],
	{
		variants: {
			size: {
				default: ['h-24', 'w-24'],
				small: ['w-16', 'h-16'],
			},
		},
		defaultVariants: {
			size: 'default',
		},
	}
);

type CheckboxSizeVariantProps = VariantProps<typeof checkbox>;

type CheckboxProps = {
	label?: string;
	size?: CheckboxSizeVariantProps['size'];
} & ComponentProps<typeof RadixCheckbox.Root>;

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>((props, ref) => {
	const { label, size, ...rest } = props;

	return (
		<Label className='flex gap-x-12'>
			<RadixCheckbox.Root {...rest} className={checkbox({ size })}>
				<RadixCheckbox.Indicator className='bg-primaryMain data-[disabled]:bg-grayscale300 h-full w-full rounded-[2px]'>
					<Icon name='check' color='grayscale0' />
				</RadixCheckbox.Indicator>
			</RadixCheckbox.Root>
			{!!label && (
				<Text variant='body-m' weight='regular' color='grayscale500'>
					{label}
				</Text>
			)}
		</Label>
	);
});

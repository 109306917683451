import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';

import { Icon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { useIsMobile } from '@monorepo/shared/lib/hooks';
import { anchorClassName, slugify, underlineClassName } from '@monorepo/shared/lib/utils';
import { MarketingFooterColumn } from '@monorepo/types';

export type FooterColumnProps = MarketingFooterColumn & {
	className?: string;
};

const variants = {
	open: { opacity: 1, height: 'auto' },
	closed: { opacity: 0, height: 0 },
};
export const FooterColumn: FC<FooterColumnProps> = ({ title, items, className }) => {
	const { isSmallTablet } = useIsMobile();
	const [columnOpen, setColumnOpen] = useState<boolean>(!isSmallTablet);

	useEffect(() => {
		setColumnOpen(!isSmallTablet);
	}, [isSmallTablet]);

	const handleKeyDown = (e: KeyboardEvent) => {
		e.stopPropagation();
		if (e.key === 'Enter' || e.key === ' ') {
			toggleColumn();
		}
	};

	const toggleColumn = () => {
		if (isSmallTablet) {
			setColumnOpen(!columnOpen);
		}
	};

	return (
		<nav className={cn(className, 'flex flex-col space-y-4')}>
			<header
				role={isSmallTablet ? 'button' : 'presentation'}
				onKeyDown={isSmallTablet ? handleKeyDown : undefined}
				tabIndex={isSmallTablet ? 0 : undefined}
				onClick={isSmallTablet ? toggleColumn : undefined}
				className='flex flex-row items-center justify-between py-8 max-md:cursor-pointer'>
				<Text as='span' variant='body-m' color='grayscale0' weight='medium'>
					{title}
				</Text>
				<Icon
					name='angle-down'
					size='lg'
					color='inherit'
					className={cn(columnOpen && 'rotate-90', 'leading-none transition-transform md:hidden')}
				/>
			</header>

			<AnimatePresence>
				{columnOpen && (
					<motion.div
						initial='closed'
						animate='open'
						exit='closed'
						variants={variants}
						className='flex flex-col space-y-8'>
						{items.map(({ href, content, title, target }) => (
							<Link
								key={`footer-column-link-${slugify(title)}`}
								href={href}
								title={title}
								target={target}
								className={cn(anchorClassName, 'flex w-fit flex-col py-8')}>
								<Text as='span' variant='body-s' color='grayscale300'>
									{content}
								</Text>
								<span className={underlineClassName}></span>
							</Link>
						))}
					</motion.div>
				)}
			</AnimatePresence>
		</nav>
	);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types'; // Adjust the import path as needed

export const serviceSearchSchema = (t: TranslationType) =>
	z.object({
		query: z.string({ required_error: t('validation.required') }).min(2, t('validation.min')),
	});

export type ServiceSearchSchema = z.infer<ReturnType<typeof serviceSearchSchema>>;

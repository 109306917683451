import { Trans } from 'next-i18next';
import { FC } from 'react';

import { Icon } from '../../../Misc';
import { Text } from '../../TextComponents';
import { List } from '../List/List';

type BulletItemProps = {
	item: string;
};

const BulletItem: FC<BulletItemProps> = ({ item }) => {
	return (
		<Text as='li' variant='body-m' className='flex flex-row items-start' color='grayscale500'>
			<Icon name='minus' className='mr-12' color='grayscale500' />

			<Trans
				/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
				i18nKey={item as any}
				components={{
					title: <span className='block font-semibold' />,
					regular: <span className='font-regular' />,
					regularBlock: <span className='font-regular block' />,
				}}
			/>
		</Text>
	);
};

type BulletListProps = {
	items: string[];
	className?: string;
};

export const BulletList: FC<BulletListProps> = ({ items, className = '' }) => {
	return (
		<List className={className}>
			{items.map((item, index) => (
				<BulletItem key={`bullet-item-${index}`} item={item} />
			))}
		</List>
	);
};

import Head from 'next/head';
import { type FC } from 'react';

export const HeadCybermeister: FC = () => (
	<Head>
		<link rel='apple-touch-icon' sizes='180x180' href='/label/cybermeister/apple-touch-icon.png' />
		<link rel='icon' href='/label/cybermeister/favicon.ico' sizes='any' />
		<link rel='shortcut icon' href='/label/cybermeister/favicon.ico' sizes='any' />
		<link rel='icon' type='image/png' sizes='32x32' href='/label/cybermeister/favicon-32x32.png' />
		<link rel='icon' type='image/png' sizes='16x16' href='/label/cybermeister/favicon-16x16.png' />
		<link rel='mask-icon' href='/label/cybermeister/safari-pinned-tab.svg' color='#60269E' />
		<link rel='manifest' href='/label/cybermeister/manifest.json' />

		<meta name='msapplication-TileColor' content='#60269E' />
		<meta name='theme-color' content='#60269E' />
		<meta name='msapplication-config' content='/label/cybermeister/browserconfig.xml' />
	</Head>
);

import cn from 'classnames';
import { FC } from 'react';

import { MarketingCtaButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { CheckList, Text } from '@monorepo/components/TextComponents';
import { IDetailHero } from '@monorepo/types';

import { DetailHeroPrice } from './DetailHeroPrice/DetailHeroPrice';
import { DetailHeroTitle } from './DetailHeroTitle/DetailHeroTitle';

export type DetailHeroProps = IDetailHero & {
	className?: string;
};

export const DetailHero: FC<DetailHeroProps> = ({
	pretext,
	title,
	subtext,
	price,
	items,
	link,
	tagline,
	className,
}) => {
	return (
		<header className={cn(className, 'bg-primaryMain py-48 md:py-64')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-32 overflow-hidden'>
					<div className='flex flex-col md:flex-row md:space-x-48'>
						<DetailHeroTitle className='md:w-2/3' title={title} pretext={pretext} subtext={subtext} />
						{price && <DetailHeroPrice className='max-md:mt-12 md:w-1/3' price={price} />}
					</div>
					<CheckList
						color='grayscale0'
						className='text-grayscale0 md:!-mt-48'
						items={items.map((item) => ({ ...item, included: true }))}
					/>
					<div className='flex flex-col space-y-12'>
						<MarketingCtaButton link={link} className='w-fit' icon='angle-right' />
						<Text variant='body-s' color='grayscale0'>
							{tagline}
						</Text>
					</div>
				</div>
			</LayoutGrid>
		</header>
	);
};

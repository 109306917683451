import { CartAcceptanceState } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const acceptanceStateApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAcceptanceState: builder.query<CartAcceptanceState, string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/acceptance-state`,
					method: 'GET',
				};
			},
			providesTags: ['AcceptanceState'],
		}),
	}),
	overrideExisting: true,
});

export const { useGetAcceptanceStateQuery } = acceptanceStateApi;

// These are used serverside in the sales funnel
export const { getAcceptanceState } = acceptanceStateApi.endpoints;

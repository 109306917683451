import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { type FC } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Form, StandardButton, TextButton } from '@monorepo/components/DataEntry';
import { IconButton, TotalFte } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { fteCalculatorSchema, FteCalculatorSchema } from '@monorepo/shared/lib/schemas';

import { ModalActions } from '@common/components';
import { hideModal } from '@common/store';

type FteCalculatorFormProps = {
	id: string;
	onCalculation: (fte: number) => void;
};

export const FteCalculatorForm: FC<FteCalculatorFormProps> = ({ id, onCalculation }) => {
	const { t } = useTranslation(['common']);

	const {
		register,
		control,
		handleSubmit,
		formState: { isValid, errors },
		watch,
	} = useForm<FteCalculatorSchema>({
		defaultValues: {
			employees: [{ num: null, workingHours: null }],
		},
		mode: 'onBlur',
		resolver: zodResolver(fteCalculatorSchema(t)),
	});

	const dispatch = useDispatch();

	const { fields, append, remove } = useFieldArray({
		name: 'employees',
		control,
	});

	const hoursToFte = (): number => {
		const employees = watch('employees');

		if (employees) {
			const total = employees.reduce((acc, current) => {
				const totalHours = (current.workingHours || 0) * (current.num || 0);
				return acc + totalHours / 40;
			}, 0);

			return Math.ceil(total * 10) / 10;
		}

		return 0;
	};

	return (
		<Form onSubmit={handleSubmit(() => onCalculation(hoursToFte()))}>
			<Text variant='body-m' weight='semibold'>
				{t('page.funnel.profile.fte.modal.calculatorTitle')}
			</Text>

			{fields.map((_, index) => {
				return (
					<div
						key={index}
						className={cn(
							// Add hover effect to show which card is active
							fields.length > 1 && 'hover:border-primary200',
							'border-grayscale100 rounded-12 mt-20 flex flex-col border px-16 py-20 max-md:space-y-24 md:flex-row md:space-x-24 md:p-20'
						)}>
						<Form.Group>
							<Form.Label>{t('page.funnel.profile.fte.modal.calculatorCoworkerLabel')}</Form.Label>
							<Form.NumberInput
								name={`employees.${index}.num`}
								errors={errors?.employees?.[index]?.num}
								placeholder={t('page.funnel.profile.fte.modal.calculatorCoworkerPlaceholder')}
								autoComplete='off'
								register={register}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t('page.funnel.profile.fte.modal.calculatorHoursLabel')}</Form.Label>

							<Form.NumberInput
								name={`employees.${index}.workingHours`}
								errors={errors?.employees?.[index]?.workingHours}
								placeholder={t('page.funnel.profile.fte.modal.calculatorHoursLabel')}
								step={1}
								autoComplete='off'
								register={register}
							/>
						</Form.Group>
						{fields.length > 1 && (
							<div className='relative col-span-1 self-end md:bottom-8 md:align-bottom'>
								<IconButton iconName='trash-alt' color='info300' onClick={() => remove(index)} />
							</div>
						)}
					</div>
				);
			})}

			<div className='mt-24 flex flex-col items-center md:flex-row md:justify-between'>
				<div>
					<TextButton
						type='button'
						iconLeft='plus-circle'
						onClick={() => append({ num: null, workingHours: null })}
						label={t('page.funnel.profile.fte.modal.calculatorAddRowLabel')}
					/>
				</div>

				<TotalFte
					className={cn(
						hoursToFte() > 0 ? 'visible' : 'invisible max-md:hidden',
						'mt-20 w-full max-md:text-center md:mt-0 md:w-fit'
					)}
					label={t('page.funnel.profile.fte.modal.calculatorTotalFte')}
					fte={hoursToFte().toLocaleString('nl-NL')}
				/>
			</div>

			<ModalActions
				cancelButton={
					<StandardButton variant='secondary' onClick={() => dispatch(hideModal(id))} label={t('common.cancel')} />
				}
				submitButton={<StandardButton iconRight='check' disabled={!isValid} type='submit' label={t('common.add')} />}
			/>
		</Form>
	);
};

import cn from 'classnames';
import { useInView } from 'framer-motion';
import parse from 'html-react-parser';
import Image from 'next/image';
import { FC, ReactNode, useRef } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';

import { CtaSearchForm } from '@marketing/components/CtaSearchFormSection/CtaSearchForm/CtaSearchForm';
import { AnimatedSvgLine } from '../AnimatedSvgLine/AnimatedSvgLine';

export type CtaSearchSectionProps = {
	title: string | ReactNode;
	subtext: string;
	image: {
		src: string;
		alt: string;
	};
	className?: string;
};

export const CtaSearchSection: FC<CtaSearchSectionProps> = ({ title, subtext, image, className }) => {
	const sectionRef = useRef<HTMLDivElement>(null);
	const isInView = useInView(sectionRef, { amount: 0.45, once: true });

	return (
		<section
			className={cn(
				className,
				'md:py-104 bg-primaryMain relative py-96 max-md:overflow-hidden max-md:bg-[right_-7rem_top_-1rem] max-md:bg-no-repeat'
			)}
			ref={sectionRef}>
			<AnimatedSvgLine inView={isInView} className='absolute -top-32 right-0 h-[100px] w-[200px] md:hidden' />

			<Image
				sizes={generateSizesString()}
				src={image.src}
				alt={image.alt}
				fill
				className='object-cover max-md:hidden'
			/>

			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex justify-center'>
					<div className='md:shadow-2 md:bg-primaryMain rounded-12 relative z-10 col-start-2 flex w-full flex-col gap-y-32 overflow-hidden bg-right-top bg-no-repeat md:max-w-[750px] md:p-48 md:pb-48 md:pt-32'>
						<AnimatedSvgLine
							inView={isInView}
							className='-right-50 absolute -top-56 -z-10 h-[200px] w-[300px] max-md:hidden'
						/>

						<div className='flex flex-col space-y-12 md:space-y-16'>
							<Text
								as='h3'
								variant='display-2'
								color='grayscale0'
								weight='semibold'
								className='[&>span]:bg-cta-highlight md:w-8/12 [&>span]:inline-block [&>span]:bg-center [&>span]:bg-no-repeat [&>span]:px-8'>
								{typeof title === 'string' ? parse(title) : title}
							</Text>
							<Text variant='body-l' color='grayscale0'>
								{subtext}
							</Text>
						</div>

						<div className='bg-grayscale0 rounded-12 p-16 max-md:space-y-16 md:p-32'>
							<CtaSearchForm id='cta-search-form' />
						</div>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

import {
	CreateHistoricalClaimRequest,
	CreateHistoricalClaimResponse,
	DeleteHistoricalClaimRequest,
	HistoricalClaimState,
	UpdateHistoricalClaimRequest,
} from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

const historicalClaimsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getHistoricalClaims: builder.query<HistoricalClaimState[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/historical-claims`,
					method: 'GET',
				};
			},
			providesTags: ['HistoricalClaim'],
			// providesTags: (result) =>
			// 	result ? result.map(({ guid }) => ({ type: 'HistoricalClaim', id: guid })) : ['HistoricalClaim'],
		}),
		updateHistoricalClaim: builder.mutation<void, UpdateHistoricalClaimRequest>({
			query({ cart_guid, guid, amount, explanation, year, product_guid }) {
				return {
					url: `/carts/${cart_guid}/historical-claims/${guid}`,
					method: 'PUT',
					body: {
						amount,
						explanation,
						year,
						product_guid,
					},
				};
			},
			invalidatesTags: ['HistoricalClaim'],
		}),
		createHistoricalClaim: builder.mutation<CreateHistoricalClaimResponse, CreateHistoricalClaimRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/historical-claims`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['HistoricalClaim'],
		}),

		deleteHistoricalClaim: builder.mutation<void, DeleteHistoricalClaimRequest>({
			query({ cart_guid, guid }) {
				return {
					url: `/carts/${cart_guid}/historical-claims/${guid}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['HistoricalClaim'],
		}),
		deleteAllHistoricalClaims: builder.mutation<
			void,
			{
				cart_guid: string;
			}
		>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/historical-claims`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['HistoricalClaim'],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetHistoricalClaimsQuery,
	useCreateHistoricalClaimMutation,
	useUpdateHistoricalClaimMutation,
	useDeleteHistoricalClaimMutation,
	useDeleteAllHistoricalClaimsMutation,
} = historicalClaimsApi;

export const {
	createHistoricalClaim: createHistoricalClaimRequest,
	deleteAllHistoricalClaims: deleteAllHistoricalClaimsRequest,
	deleteHistoricalClaim: deleteHistoricalClaimRequest,
	getHistoricalClaims: getHistoricalClaimsRequest,
	updateHistoricalClaim: updateHistoricalClaimRequest,
} = historicalClaimsApi.endpoints;

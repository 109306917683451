import { IWhyInsureThroughMaiaSection } from '@monorepo/types';

export const mockWhyInsureThroughMaiaSection: IWhyInsureThroughMaiaSection = {
	title: 'Waarom via Maia verzekeren?',
	bulletpoints: [
		{
			title: 'Direct in 2-min online verzekerd',
			description: 'Alles in één klap online geregeld',
		},
		{
			title: 'Bespaar op “online” verzekeringen',
			description: 'Door slimme inkoop geniet je van de scherpste premies zonder concessie op dekking',
		},
		{
			title: 'Zonder zorgen blijven ondernemen',
			description: 'Mocht er toch iets fout gaan staan we 24 uur p/d, 7 dagen p/w voor je klaar',
		},
	],
	image: {
		alt: 'alt',
		path: '/images/marketing/cta-search-background.png',
	},
};

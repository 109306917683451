import cn from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';

import { ButtonIcon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { BaseButton } from '../BaseButton/BaseButton';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
	label: string;
	selected?: boolean;
	isLoading?: boolean;
	iconLeft?: string;
	iconRight?: string;
};
export const SelectToggleButton: FC<Props> = ({
	label,
	selected = false,
	isLoading,
	disabled,
	iconLeft,
	iconRight,
	className = '',
	...props
}) => {
	return (
		<BaseButton
			className={cn(
				className,
				// Default
				'border-primaryMain rounded-6 disabled:bg-grayscale100 disabled:border-grayscale300 disabled:text-grayscale300 h-40 space-x-4 border-2 p-8',
				// Hover
				'hover:enabled:bg-primary50 hover:enabled:border-primary400',
				// Active
				'active:enabled:bg-primary100 active:enabled:border-primary600',
				// Selected
				selected &&
					'bg-success50 text-success300 hover:text-success400 hover:enabled:bg-success50 border-success300 hover:enabled:border-success400 active:enabled:text-success500 active:enabled:bg-success50 active:enabled:border-success500'
			)}
			disabled={disabled || isLoading}
			{...props}>
			{iconLeft && <ButtonIcon icon={iconLeft} size='md' iconPosition='left' isLoading={isLoading} mode='dark' />}
			<Text variant='body-s' weight='semibold' color='inherit'>
				{label}
			</Text>
			{(iconRight || (!iconLeft && isLoading)) && (
				<ButtonIcon icon={iconRight} size='md' iconPosition='right' isLoading={isLoading} mode='dark' />
			)}
		</BaseButton>
	);
};

import { zodResolver } from '@hookform/resolvers/zod';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'next-i18next';
import { useEffect, useState, type FC } from 'react';
import { useForm } from 'react-hook-form';

import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { LookupSuggestion } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { AddressSchema, addressSchema } from '@monorepo/shared/lib/schemas';
import type { PostalCodeLookupRequest } from '@monorepo/types';

import { ModalActions } from '@common/components';
import { useLookupQuery } from '@common/store';

type AddressFormProps = {
	onSubmit: (form: AddressSchema) => void | Promise<void>;
	onCancel: () => void;
};

export const AddressForm: FC<AddressFormProps> = ({ onSubmit, onCancel }) => {
	const { t } = useTranslation(['common']);

	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors, isValid },
		trigger,
	} = useForm<AddressSchema>({
		mode: 'onBlur',
		resolver: zodResolver(addressSchema(t)),
	});

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [request, setRequest] = useState<PostalCodeLookupRequest>();
	const { data, isFetching, isError, isSuccess, isUninitialized } = useLookupQuery(request ?? skipToken);

	const handleOnBlur = () => {
		const { postal_code, house_number } = getValues();

		if (postal_code && house_number) {
			setRequest({ postal_code: postal_code.replaceAll(' ', ''), house_number });
		}
	};

	useEffect(() => {
		setValue('street', isError ? '' : `${data?.street}`);
		setValue('city', isError ? '' : `${data?.city}`);
		setIsEdit(false);

		if (!isError && !isUninitialized) {
			trigger();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, setValue, isError]);

	return (
		<Form id='company-search-form' onSubmit={handleSubmit(onSubmit)}>
			<Form.Group className='space-y-32'>
				<div className='grid grid-cols-4 gap-x-8 gap-y-12'>
					<Form.Group className='col-span-full md:col-span-2'>
						<Form.Label>{t('common.postalCode')}</Form.Label>
						<Form.TextInput
							fieldSize='lg'
							autoComplete='postal-code'
							name='postal_code'
							placeholder={t('common.postalCode')}
							errors={errors.postal_code}
							register={register}
							rules={{ onBlur: handleOnBlur }}
						/>
					</Form.Group>

					<Form.Group className='col-span-2 md:col-span-1'>
						<Form.Label>{t('common.houseNumber')}</Form.Label>
						<Form.NumberInput
							fieldSize='lg'
							name='house_number'
							autoComplete='off'
							placeholder={t('common.houseNumber')}
							errors={errors.house_number}
							register={register}
							rules={{ onBlur: handleOnBlur }}
						/>
					</Form.Group>

					<Form.Group className='col-span-2 md:col-span-1'>
						<Form.Label>{t('common.houseNumberAddition')}</Form.Label>
						<Form.TextInput
							fieldSize='lg'
							name='house_number_appendix'
							autoComplete='off'
							placeholder={t('common.houseNumberAddition')}
							errors={errors.house_number_appendix}
							register={register}
							rules={{ onBlur: handleOnBlur }}
						/>
					</Form.Group>

					{(isError || isEdit) && (
						<>
							{isError && (
								<div className='col-span-full'>
									<Text>{t('page.funnel.company.change.suggestionNotFound')}</Text>
								</div>
							)}
							<Form.Group className='col-span-full'>
								<Form.Label>{t('common.street')}</Form.Label>
								<Form.TextInput
									fieldSize='lg'
									autoComplete='street-address'
									name='street'
									placeholder={t('common.street')}
									errors={errors.street}
									register={register}
									autoFocus
								/>
							</Form.Group>

							<Form.Group className='col-span-full'>
								<Form.Label>{t('common.city')}</Form.Label>
								<Form.TextInput
									fieldSize='lg'
									autoComplete='address-level2'
									name='city'
									placeholder={t('common.city')}
									errors={errors.city}
									register={register}
								/>
							</Form.Group>
						</>
					)}

					{isSuccess && data && !isEdit && (
						<div className='col-span-full'>
							<LookupSuggestion
								city={data.city}
								number={data.number}
								postcode={data.postcode}
								street={data.street}
								onEdit={() => setIsEdit(true)}
								addition={getValues().house_number_appendix}
								isLoading={isFetching}
							/>
						</div>
					)}
				</div>

				<ModalActions
					cancelButton={
						<StandardButton type='button' onClick={onCancel} variant='secondary' label={t('common.cancel')} />
					}
					submitButton={
						<StandardButton
							type='submit'
							iconRight='check'
							variant='primary'
							label={t('common.save')}
							isLoading={isFetching}
							disabled={isFetching || !isValid || isUninitialized}
						/>
					}></ModalActions>
			</Form.Group>
		</Form>
	);
};

import cn from 'classnames';
import { forwardRef, HTMLAttributes, useState, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Text } from '../../TextComponents';
import { SvgCircle } from '../Doodles/Circle/Circle';
import { Icon } from '../Icon/Icon';

type SelectBoxInputProps = {
	icon: string;
	title: string;
	name: string;
	subtitle?: string;
	size?: 'small' | 'default';
} & HTMLAttributes<HTMLLabelElement>;

export const SelectBoxInput: FC<SelectBoxInputProps> = forwardRef<HTMLLabelElement, SelectBoxInputProps>(
	({ icon, title, subtitle, size = 'default', name, className, ...props }, ref) => {
		const { control } = useFormContext();
		const [isFocused, setIsFocused] = useState<boolean>(false);

		return (
			<Controller
				name={name}
				control={control}
				defaultValue={false}
				render={({ field }) => (
					<label
						ref={ref}
						htmlFor={name}
						className={cn(
							'border-grayscale100 shadow-1 rounded-16 hover:border-info300 relative cursor-pointer gap-y-16 border p-24 transition-colors',
							field.value === true && 'bg-info50 border-info300',
							isFocused && 'border-info300',
							size === 'default' && 'md:gap-y-20',
							className
						)}
						{...props}>
						<div className='relative w-fit'>
							<SvgCircle
								theme='maia'
								className={cn(
									'text-secondaryMain absolute bottom-8 left-0 h-8 w-8',
									size === 'default' && 'md:bottom-4 md:h-[10px] md:w-[10px]'
								)}
							/>
							<Icon
								name={icon}
								size='custom'
								color='info300'
								className={cn('relative text-[24px]', size === 'default' && 'md:text-[32px]')}
							/>
							<SvgCircle
								theme='maia'
								className={cn(
									'text-secondaryMain absolute -right-[6px] top-[2px] h-12 w-12',
									size === 'default' && 'md:-right-[7px] md:top-4 md:h-[15px] md:w-[15px]'
								)}
							/>
						</div>
						<div className='flex flex-col gap-y-4'>
							<Text variant='body-xs' weight='regular' color='grayscale400' className='min-h-[14px]'>
								{subtitle ?? ''}
							</Text>

							<Text variant='body-m' weight='medium' color='grayscale600'>
								{title}
							</Text>
						</div>
						<input
							type='checkbox'
							onChange={(e) => field.onChange(e.target.checked)}
							onFocus={() => setIsFocused(true)}
							onBlur={() => {
								field.onBlur();
								setIsFocused(false);
							}}
							className='absolute inset-0 h-full w-full cursor-pointer opacity-0'
						/>
					</label>
				)}
			/>
		);
	}
);

type SelectBoxProps = {
	icon: string;
	title: string;
	onClick: () => void;
	subtitle?: string;
	size?: 'small' | 'default';
	className?: string;
};

// export const SelectBox
export const SelectBox: FC<SelectBoxProps> = ({ icon, onClick, title, size, subtitle, className }) => {
	return (
		<div
			role='button'
			className={cn(
				'border-grayscale100 shadow-1 rounded-16 hover:border-info300 relative cursor-pointer gap-y-16 border p-24 transition-colors',
				size === 'default' && 'md:gap-y-20',
				className
			)}
			onClick={onClick}>
			<div className='relative w-fit'>
				<SvgCircle
					theme='maia'
					className={cn(
						'text-secondaryMain absolute bottom-8 left-0 h-8 w-8',
						size === 'default' && 'md:bottom-4 md:h-[10px] md:w-[10px]'
					)}
				/>
				<Icon
					name={icon}
					size='custom'
					color='info300'
					className={cn('relative text-[24px]', size === 'default' && 'md:text-[32px]')}
				/>
				<SvgCircle
					theme='maia'
					className={cn(
						'text-secondaryMain absolute -right-[6px] top-[2px] h-12 w-12',
						size === 'default' && 'md:-right-[7px] md:top-4 md:h-[15px] md:w-[15px]'
					)}
				/>
			</div>
			<div className='flex flex-col gap-y-4'>
				<Text variant='body-xs' weight='regular' color='grayscale400' className='min-h-[14px]'>
					{subtitle ?? ''}
				</Text>

				<Text variant='body-m' weight='medium' color='grayscale600'>
					{title}
				</Text>
			</div>
		</div>
	);
};

export const mockHeroWithScrollingImageAndSearch = {
	title: 'Speciaal ontwikkeld voor jouw werkveld',
	images: [
		{
			path: '/images/marketing/logo-card-vastgoedpro.png',
			alt: 'alt-1',
		},
		{
			path: '/images/marketing/logo-card-onderhoudnl.png',
			alt: 'alt-2',
		},
		{
			path: '/images/marketing/logo-card-cybermeister.png',
			alt: 'alt-3',
		},
		{
			path: '/images/marketing/logo-card-anko.png',
			alt: 'alt-4',
		},
		{
			path: '/images/marketing/logo-card-nvm.png',
			alt: 'alt-5',
		},
		{
			path: '/images/marketing/logo-card-vbo.png',
			alt: 'alt-6',
		},
	],
};

import { sortAlphabetically } from '@monorepo/shared/lib/utils';
import { Bundle, BundleMappedToProducts, Calculation } from '@monorepo/types';

export const mapBundlesToContent = (bundles: Bundle[], calculations: Calculation[]): BundleMappedToProducts[] => {
	return bundles
		?.map((bundle) => ({
			...bundle,
			mappedProducts: calculations
				? calculations
						.filter(({ product }) => bundle.products.some(({ code }) => code === product.code))
						.map((calculation) => calculation?.product)
						.sort((a, b) => sortAlphabetically(a.name, b.name))
				: [],
		}))
		.filter((bundle) => bundle.products.length > 0);
};

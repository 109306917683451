import { faker } from '@faker-js/faker/locale/nl';

import {
	CalculationProductOption,
	CalculationProductOptionSpec,
	DeductibleTypes,
	InsuredAmountTypes,
	SUPPORTED_INSURANCES,
} from '@monorepo/types';

export function mockGetCalculationProductOption(isActive = false): CalculationProductOption {
	const price = +faker.commerce.price();
	const tax = 21;

	return {
		guid: faker.string.alphanumeric(16),
		name: SUPPORTED_INSURANCES[0],
		code: faker.string.alphanumeric(6),
		is_active: isActive,
		is_calculated: faker.datatype.boolean(),
		price: {
			discount: 0,
			discount_percentage: 0,
			promotion: 'first_year',
			tax,
			premium_after_discount: price,
			premium_before_discount: price,
			premium_after_tax: price + (price * tax) / price,
		},
		show_on_website: true,
		specs: [
			mockGetCalculationProductOptionSpec({ variant: 'deductible', type: 'default' }),
			mockGetCalculationProductOptionSpec({ variant: 'deductible', type: 'theft' }),
			mockGetCalculationProductOptionSpec({ variant: 'insured_amount', type: 'default' }),
			mockGetCalculationProductOptionSpec({ variant: 'insured_amount', type: 'deceased' }),
		],
	};
}

export function mockGetCalculationProductOptionSpec({
	variant,
	type,
}:
	| { variant: 'deductible'; type: DeductibleTypes }
	| { variant: 'insured_amount'; type: InsuredAmountTypes }): CalculationProductOptionSpec {
	if (variant === 'deductible') {
		return {
			is_deductible: true,
			deductible_type: type,
			value: `${faker.number.int({ min: 0, max: 10000 })}`,
			reference: '12345',
		};
	}

	return {
		is_insured_amount: true,
		insured_amount_type: type,
		value: `${faker.number.int({ min: 0, max: 100000 })}`,
		reference: '12345',
	};
}

export function mockGetCalculationProductOptions(num = 3): CalculationProductOption[] {
	const options: CalculationProductOption[] = [];
	for (let i = 0; i < num; i++) {
		options.push(mockGetCalculationProductOption(i === 0));
	}
	return options;
}

import classNames from 'classnames';
import { motion, motionValue, useTransform } from 'framer-motion';
import { FC } from 'react';

import { Text } from '../../../../TextComponents';

type ProgressIndicatorProps = {
	currentStep: number;
	steps: number;
	className?: string;
};

export const ProgressIndicator: FC<ProgressIndicatorProps> = ({ currentStep, steps, className }) => {
	// Create a motion value for the current step
	const stepProgress = motionValue(currentStep);

	// Calculate the progress percentage
	const progress = useTransform(stepProgress, [0, steps], [0, 1], { clamp: true });

	// Calculate the current step and limit it to the maximum step
	const _currentStep = Math.min(Math.max(currentStep, 0), steps);

	return (
		<div className={classNames('flex flex-col items-center space-y-12', className)}>
			<div className='bg-primary100 rounded-6 relative h-[6px] w-full'>
				<motion.div
					className='bg-primaryMain rounded-6 absolute left-0 h-[6px]'
					initial={{ width: '0%' }}
					animate={{ width: `${progress.get() * 100}%` }}
				/>
			</div>

			<Text variant='body-xs' weight='regular' color='grayscale300'>
				{_currentStep} / {steps}
			</Text>
		</div>
	);
};

import Link from 'next/link';
import { FC } from 'react';

import { BaseCard, PriceSticker } from '@monorepo/components/DataDisplay';
import { Icon } from '@monorepo/components/Misc';
import { SvgCircle } from '@monorepo/components/Misc/Doodles/Circle/Circle';
import { Text } from '@monorepo/components/TextComponents';
import { IInsuranceOverviewCard } from '@monorepo/types';

type InsuranceOverviewCardProps = IInsuranceOverviewCard;

export const InsuranceOverviewCard: FC<InsuranceOverviewCardProps> = ({
	icon,
	title,
	description,
	link,
	minimumPrice,
}) => {
	return (
		<BaseCard className='h-full'>
			<Link
				href={link.href || '/'}
				target={link.target}
				title={link.title || 'Lees meer'}
				className='group flex h-full flex-col overflow-hidden p-20 max-md:space-y-16 md:p-32'>
				<div className='flex flex-col md:flex-1 md:space-y-12'>
					<div className='flex w-full flex-row items-center max-md:gap-16 md:justify-between'>
						<div className='relative w-fit'>
							<SvgCircle
								theme='maia'
								className='text-secondaryMain w-h-8 absolute bottom-8 left-0 h-8 md:bottom-4 md:h-12 md:w-12'
							/>
							<Icon name={icon} size='custom' color='info300' className='relative z-10 text-[32px] md:text-[40px]' />
							<SvgCircle
								theme='maia'
								className='text-secondaryMain absolute -right-4 top-8 h-16 w-16 md:right-0 md:top-4 md:h-20 md:w-20'
							/>
						</div>

						{minimumPrice && (
							<div className='max-md:hidden'>
								<PriceSticker size='small' variant='default' price={minimumPrice} />
							</div>
						)}

						<Text
							as='h4'
							variant='display-5'
							weight='semibold'
							color='grayscale600'
							className='break-words-force hyphens-auto md:hidden'>
							{title}
						</Text>
					</div>

					<Text
						as='h4'
						variant='display-5'
						weight='semibold'
						color='grayscale600'
						className='hyphens-auto max-md:hidden'>
						{title}
					</Text>

					<Text variant='body-l' weight='regular' color='grayscale400' className='max-md:hidden'>
						{description}
					</Text>
				</div>

				{minimumPrice && (
					<div className='md:hidden'>
						<PriceSticker size='small' variant='default' price={minimumPrice} />
					</div>
				)}

				<button className='-mb-8 -ml-4 mt-24 flex items-center space-x-4 px-4 py-8 max-md:hidden'>
					<span>{link.content || link.title}</span>
					<Icon name='arrow-right' color='info300' size='lg' />
				</button>
			</Link>
		</BaseCard>
	);
};

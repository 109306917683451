import { IPartnersSection } from '@monorepo/types';

const logos = [
	'vbo',
	'nvm',
	'anko',
	'cybermeister',
	'onderhoudnl',
	'schouten-zekerheid',
	'anko',
	'cybermeister',
	'onderhoudnl',
	'schouten-zekerheid',
];

export const mockPartnersSection: IPartnersSection = {
	title: 'Māia werkt samen met veel partners aan een nieuwe wereld van verzekeringen',
	logos: logos.map((logo) => ({
		alt: logo,
		src: `/images/marketing/logos/${logo}.svg`,
		link: {
			href: `https://www.${logo}.nl/`,
			content: logo,
			target: '_blank',
			title: logo,
		},
	})),
};

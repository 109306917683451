import cn from 'classnames';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale/nl';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { IconButton } from '../../../Misc';
import { Text } from '../../../TextComponents';

type Props = {
	date: Date;
	prevMonthButtonDisabled: boolean;
	nextMonthButtonDisabled: boolean;
	decreaseMonth: () => void;
	increaseMonth: () => void;
};

export const CalendarHeader: FC<Props> = ({
	date,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled,
	decreaseMonth,
	increaseMonth,
}) => {
	const { t } = useTranslation(['common']);
	return (
		<div className='flex items-center space-x-8'>
			<IconButton
				title={t('components.calendar.decreaseMonth')}
				color='info300'
				onClick={decreaseMonth}
				className={cn(
					prevMonthButtonDisabled && 'cursor-not-allowed',
					'rounded-6 hover:enabled:bg-primary100 active:enabled:bg-primary200'
				)}
				iconName='arrow-left'
			/>
			<div className='grow'>
				<Text as='span' variant='body-s' weight='medium' className='capitalize'>
					{format(date, 'MMMM yyyy', { locale: nl })}
				</Text>
			</div>
			<IconButton
				title={t('components.calendar.increaseMonth')}
				color='info300'
				onClick={increaseMonth}
				className={cn(
					nextMonthButtonDisabled && 'cursor-not-allowed',
					'rounded-6 hover:enabled:bg-primary100 active:enabled:bg-primary200'
				)}
				iconName='arrow-right'
			/>
		</div>
	);
};

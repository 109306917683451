import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import parse from 'html-react-parser';
import { useTranslation } from 'next-i18next';
import { FC, KeyboardEvent, MouseEvent, useState } from 'react';

import { PortalInsuranceClause } from '@monorepo/types';

import { useAnalytics } from '../../../../lib/hooks';
import { proseClassNames, toSnakeCase } from '../../../../lib/utils';
import { ActionButton } from '../../../DataEntry';
import { Text } from '../../../TextComponents';
import { BaseAccordion } from '../BaseAccordion/BaseAccordion';

type Props = {
	title: string;
	clauses: PortalInsuranceClause[];
};

const ClauseAccordionItem: FC<PortalInsuranceClause> = ({ name, content }) => {
	const { t } = useTranslation(['common']);
	const [isOpen, setIsOpen] = useState(false);
	const { trackEvent } = useAnalytics();

	const handleToggle = (e?: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
		e?.stopPropagation();
		setIsOpen(!isOpen);

		if (!isOpen) {
			trackEvent('select_content', { content_type: 'information_accordion_item', item_id: toSnakeCase(name) });
		}
	};

	const keyDown = (e: KeyboardEvent) => {
		e.stopPropagation();
		if (e.key === 'Enter' || e.key === ' ') {
			handleToggle();
		}
	};

	return (
		<div
			role='button'
			title={t(isOpen ? 'common.closeAccordion' : 'common.openAccordion', { name })}
			tabIndex={0}
			onKeyDown={keyDown}
			onClick={handleToggle}
			className={cn(
				isOpen && 'bg-primary50',
				'hover:bg-primary50 rounded-6 group flex flex-row items-baseline space-x-24 p-12'
			)}>
			<div>
				<ActionButton
					title={t(isOpen ? 'common.closeAccordion' : 'common.openAccordion', { name })}
					className='group-hover:bg-grayscale0'
					isActive={isOpen}
					icon={isOpen ? 'minus' : 'plus'}
				/>
			</div>

			<div className={cn(proseClassNames, 'flex flex-col space-y-12')}>
				<Text as='h4' variant='body-s' weight='medium'>
					{parse(name)}
				</Text>
				<AnimatePresence mode='wait'>
					{isOpen && (
						<motion.div
							initial={{ opacity: 0, height: 0 }}
							animate={{ opacity: 1, height: 'auto' }}
							exit={{ opacity: 0, height: 0 }}>
							{parse(content)}
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

export const ClauseAccordion: FC<Props> = ({ title, clauses }) => {
	return (
		<BaseAccordion title={title}>
			<div className='mt-12 space-y-12'>
				{clauses.map((clause, index) => (
					<ClauseAccordionItem {...clause} key={`clauseaccordion-item-${index}`} />
				))}
			</div>
		</BaseAccordion>
	);
};

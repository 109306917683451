import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, ILogoList } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { LogoListSection } from '@marketing/components';

export const handleSectionLogoList = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextEl = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;

	const selectedLogosEl = elements.find(
		({ identifier }) => identifier === 'selected-logos' || identifier === 'logo-list-master'
	) as CmsElement;

	const logos: ILogoList['logos'] = selectedLogosEl.child_elements.map((item) => {
		const [media] = item.media;
		const [link] = item.links;

		return {
			src: `${media?.path}`,
			alt: `${media?.alt}`,
			link,
		};
	});

	const title = titleSubtextEl
		? getProperty('title', titleSubtextEl.custom_fields)
		: getProperty('title', selectedLogosEl.custom_fields);

	if (!logos || logos.length === 0) {
		return () => <></>;
	}

	return (props) => <LogoListSection {...props} title={`${title}`} logos={logos} />;
};

import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { CheckItem, Text } from '@monorepo/components/TextComponents';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { WhyInsureThroughMaiaSection } from '@marketing/components';

const ListItem = (props: ReactMarkdownProps) => (
	<CheckItem item={{ content: props.children, included: true }} size='lg' color='inherit' {...props} />
);

const Strong = (props: ReactMarkdownProps) => (
	<Text as='strong' variant='body-l' weight='medium' className='block' color='inherit' {...props} />
);
const Span = (props: ReactMarkdownProps) => (
	<Text as='span' variant='body-m' className='block' color='inherit' {...props} />
);

const mdComponents: Components = {
	p: ListItem,
	strong: Strong,
	span: Span,
};

export const handleSectionWhyMaia = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const checklistEle = elements.find(({ identifier }) => identifier === 'lists_checklist') as CmsElement,
		imageEle = elements.find(({ identifier }) => identifier === 'image') as CmsElement,
		title = getProperty('title', checklistEle.custom_fields),
		[image] = imageEle.media;

	const Content = () => (
		<ul className='flex flex-col space-y-24'>
			{checklistEle.child_elements.map((ele, index) => (
				<ReactMarkdown key={index} components={mdComponents}>{`${ele.markdown}`}</ReactMarkdown>
			))}
		</ul>
	);

	return (props) => (
		<WhyInsureThroughMaiaSection {...props} className='md:py-104 pb-48 pt-24' title={`${title}`} image={image}>
			<Content />
		</WhyInsureThroughMaiaSection>
	);
};

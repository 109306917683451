import { isBrowser } from '@monorepo/shared/lib/utils';

export async function initMocks(): Promise<void> {
	if (isBrowser) {
		const { worker } = await import('./browser');

		return worker.start({ waitUntilReady: true }).then(() => console.log('🚀 MSW Browser is running'));
	}
	const { server } = await import('./server');
	return Promise.resolve(() => server.listen()).then(() => console.log('🚀 MSW Server is running'));
}

import { FC } from 'react';

import { Tag, TagProps } from '@monorepo/components/DataDisplay';
import { getProperty } from '@monorepo/shared/lib/cms';
import { TagStatus } from '@monorepo/types';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleTag = ({ component }: HandlerProps): FC<TagProps> => {
	const content = getProperty('content', component.custom_fields);
	const type: TagStatus = (getProperty('type', component.custom_fields) as TagStatus) || 'default';

	return (props) => <Tag {...props} status={type} text={content} />;
};

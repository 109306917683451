import { IContactSection } from '@monorepo/types';

export const mockContactSection: IContactSection = {
	title: 'Wij staan altijd voor u klaar',
	subtitle:
		'Bij Māia zijn we er voor u. Ons team van experts staat 24/7 voor u klaar om al uw vragen te beantwoorden. Ongeacht of het gaat om het afsluiten van verzekeringen, op maat gemaakte oplossingen of ondersteuning bij claims. ',
	chat: {
		icon: 'comments',
		title: 'Chat met ons',
		description: '24 uur per dag, 7 dagen per week',
		link: {
			content: 'Stel direct uw vraag',
			title: 'Stel direct uw vraag',
			href: '',
		},
	},
	email: {
		icon: 'envelope',
		title: 'E-mail met support',
		description: 'Ons support team staat voor je klaar',
		email: 'support@maia.insure',
	},
	phone: {
		icon: 'mobile-android',
		title: 'Bet met support',
		description: 'Ons support team staat voor je klaar',
		phone: '+31620442192',
	},
};

export const mockTestimonialMultiSliderSection = {
	title: 'Dit zeggen jouw collega’s',
	testimonials: [
		{
			testimonial:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum.',
			name: 'Pedro Santos',
			company: 'Rockn Rose Hair Studio 1',
			image: {
				source: '/images/marketing/headshot.png',
				alt: 'some-alt-1',
			},
		},
		{
			testimonial:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum.',
			name: 'Pedro Santos',
			company: 'Rockn Rose Hair Studio 2',
			image: {
				source: '/images/marketing/headshot.png',
				alt: 'some-alt-1',
			},
		},
		{
			testimonial:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum.',
			name: 'Pedro Santos',
			company: 'Rockn Rose Hair Studio 3',
			image: {
				source: '/images/marketing/headshot.png',
				alt: 'some-alt-1',
			},
		},
		{
			testimonial:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum.',
			name: 'Pedro Santos',
			company: 'Rockn Rose Hair Studio 4',
			image: {
				source: '/images/marketing/headshot.png',
				alt: 'some-alt-1',
			},
		},
		{
			testimonial:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum.',
			name: 'Pedro Santos',
			company: 'Rockn Rose Hair Studio 5',
			image: {
				source: '/images/marketing/headshot.png',
				alt: 'some-alt-1',
			},
		},
		{
			testimonial:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum.',
			name: 'Pedro Santos',
			company: 'Rockn Rose Hair Studio 6',
			image: {
				source: '/images/marketing/headshot.png',
				alt: 'some-alt-1',
			},
		},
		{
			testimonial:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum.',
			name: 'Pedro Santos',
			company: 'Rockn Rose Hair Studio 7',
			image: {
				source: '/images/marketing/headshot.png',
				alt: 'some-alt-1',
			},
		},
		{
			testimonial:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum.',
			name: 'Pedro Santos',
			company: 'Rockn Rose Hair Studio 8',
			image: {
				source: '/images/marketing/headshot.png',
				alt: 'some-alt-1',
			},
		},
	],
};

import cn from 'classnames';
import { FC } from 'react';

import { IllustrationProps } from '@monorepo/types';

export const SvgCircle: FC<IllustrationProps> = ({ className = '' }) => (
	<svg className={cn(className)} viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M19 10.6427C18.9032 11.8962 18.5088 13.1092 17.849 14.1824C17.1892 15.2557 16.2827 16.1588 15.2037 16.8178C13.2879 18.1338 11.0382 18.8898 8.71069 18.9997C7.49833 19.0103 6.29763 18.7643 5.18869 18.278C4.07975 17.7918 3.08809 17.0764 2.27987 16.1797C1.4364 15.2618 0.801669 14.1747 0.418697 12.9921C0.035725 11.8094 -0.0865346 10.5589 0.0602031 9.32533C0.32307 6.93036 1.32259 4.67375 2.92296 2.86205C3.79094 1.88751 4.85898 1.10848 6.05538 0.577257C7.35826 0.00431839 8.8099 -0.146924 10.2043 0.144997C12.6148 0.714486 14.767 2.06092 16.3239 3.97357C17.9937 5.80589 18.9435 8.17302 19 10.6427ZM15.6601 10.6427C15.2856 9.09849 14.4983 7.6831 13.3812 6.54562C12.264 5.40815 10.8584 4.5907 9.31228 4.1794C7.00963 3.43839 4.02242 6.62886 3.44157 9.79875C3.28374 10.567 3.2947 11.3601 3.47369 12.1238C3.65268 12.8875 3.99551 13.6039 4.4788 14.2242C4.98386 14.8395 5.61494 15.3412 6.33022 15.696C7.04551 16.0509 7.82865 16.2508 8.62771 16.2826C10.3968 16.2946 12.1168 15.7063 13.5027 14.6153C14.1557 14.172 14.6922 13.5801 15.0676 12.889C15.4429 12.1978 15.6461 11.4276 15.6601 10.6427Z'
			fill='currentColor'
		/>
	</svg>
);

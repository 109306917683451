import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Bundle, Calculation, CalculationForm, CalculationRiskAddress } from '@monorepo/types';

import { AppState } from '@common/store';
import { bundlesApi, calculationsApi } from '@funnel/store';

export type ProductState = {
	bundles: Bundle[];
	calculations: Calculation[];
	forms: CalculationForm[];
};

// Initial state
const initialState: ProductState = {
	bundles: [],
	calculations: [],
	forms: [],
};

export const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		// Action to set the bundles
		setBundles(state, action: PayloadAction<Bundle[]>) {
			state.bundles = [...state.bundles, ...action.payload];
		},
		setCalculations(state, action: PayloadAction<Calculation[]>) {
			state.calculations = [...state.calculations, ...action.payload];
		},
		resetProducts() {
			return initialState;
		},
		toggleActiveCalculation(state, action) {
			state.calculations = [
				...state.calculations?.map((oldCalculation) =>
					oldCalculation.product.guid === action.payload.product.guid
						? {
								...action.payload,
							}
						: oldCalculation
				),
			];
		},
		toggleActiveCalculationSpec(state, action: PayloadAction<{ productGuid: string; optionGuid: string }>) {
			state.calculations = [...state.calculations].map((calculation) =>
				calculation.product.guid === action.payload.productGuid
					? {
							...calculation,
							product: {
								...calculation.product,
								options: calculation.product.options.map((option) => ({
									...option,
									is_active: option.guid === action.payload.optionGuid,
								})),
							},
						}
					: calculation
			);
		},
		updateCalculationOptionRiskAddress(
			state,
			action: PayloadAction<{ productGuid: string; newRiskAddress: CalculationRiskAddress }>
		) {
			state.calculations = [...state.calculations].map((calculation) =>
				calculation.product.guid === action.payload.productGuid
					? {
							...calculation,
							product: {
								...calculation.product,
								risk_addresses:
									calculation.product.risk_addresses.length === 0
										? [action.payload.newRiskAddress]
										: calculation.product.risk_addresses.map((risk_address) =>
												risk_address.city === action.payload.newRiskAddress.city &&
												risk_address.postal_code === action.payload.newRiskAddress.postal_code &&
												risk_address.house_number === action.payload.newRiskAddress.house_number &&
												risk_address.house_number_appendix === action.payload.newRiskAddress.house_number_appendix
													? risk_address
													: action.payload.newRiskAddress
											),
							},
						}
					: calculation
			);
		},
		updateActiveProductsBasedOnDefaultDeductible(state, action: PayloadAction<{ deductible: string }>) {
			state.calculations = state.calculations.map((calculation) => {
				if (!calculation.product.is_active) {
					return calculation;
				}

				const activeProduct = calculation.product.options.find((option) => option.is_active);
				const specsWithoutDefaultDeductible = activeProduct?.specs
					.filter((spec) => spec.deductible_type !== 'default')
					.map((spec) => {
						if (spec?.deductible_type === 'water-damage') {
							if (action.payload.deductible === '250') {
								return { ...spec, value: '500' };
							}
							if (action.payload.deductible === '500') {
								return { ...spec, value: '1000' };
							}
							if (action.payload.deductible === '1000') {
								return { ...spec, value: '1500' };
							}
						}
						return spec;
					});

				if (!specsWithoutDefaultDeductible) {
					return calculation;
				}

				const updatedOptions = calculation.product.options.map((option) => {
					const isSpecInNewActiveOption = option.specs.every((spec) => {
						if (spec.is_deductible && spec.deductible_type === 'default') {
							return (
								spec.is_deductible && spec.deductible_type === 'default' && spec.value === action.payload.deductible
							);
						} else {
							const specKey = `${spec.is_deductible}${spec.deductible_type}${spec.is_insured_amount}${spec.insured_amount_type}${spec.value}`;
							return specsWithoutDefaultDeductible?.some((_spec) => {
								const _specKey = `${_spec.is_deductible}${_spec.deductible_type}${_spec.is_insured_amount}${_spec.insured_amount_type}${_spec.value}`;
								return specKey === _specKey;
							});
						}
					});
					return { ...option, is_active: isSpecInNewActiveOption };
				});

				return {
					...calculation,
					product: {
						...calculation.product,
						options: updatedOptions,
					},
				};
			});
		},
		toggleActiveProduct(state, action: PayloadAction<{ productGuid: string }>) {
			state.calculations = [...state.calculations].map((calculation) =>
				calculation.product.guid === action.payload.productGuid
					? {
							...calculation,
							product: { ...calculation.product, is_active: !calculation.product.is_active },
						}
					: calculation
			);
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(calculationsApi.endpoints.getCartCalculations.matchFulfilled, (state, action) => {
			state.calculations = action.payload;
		});
		builder.addMatcher(bundlesApi.endpoints.getBundles.matchFulfilled, (state, action) => {
			state.bundles = action.payload;
		});
		builder.addMatcher(calculationsApi.endpoints.getCartCalculationsForms.matchFulfilled, (state, action) => {
			state.forms = action.payload;
		});
	},
});

export const {
	setCalculations,
	setBundles,
	resetProducts,
	toggleActiveProduct,
	toggleActiveCalculationSpec,
	updateCalculationOptionRiskAddress,
	toggleActiveCalculation,
	updateActiveProductsBasedOnDefaultDeductible,
} = productsSlice.actions;

export const selectCalculationState = (state: AppState) => state.products.calculations;

export const selectRiderProducts = createSelector(
	(state: AppState) => state.products,
	({ calculations, forms }) => {
		// Filter calculations where is_rider is true
		const riderCalculations = calculations?.filter((calc) => calc.product.is_rider);

		// Find calculations where is_active is true and store their guids
		const activeGuids = calculations?.filter((calc) => calc.product.is_active).map((calc) => calc.product.guid);

		// Filter riderCalculations where the guid of CalculationDependant matches
		// the guid of another CalculationProduct with is_active set to true
		const matchingCalculations = riderCalculations?.filter((riderCalc) =>
			riderCalc.product.dependant_on.some((dependant) => activeGuids.includes(dependant.guid))
		);

		return { calculations: matchingCalculations, forms };
	}
);

export const selectShownCalculationStateWithoutRiderProducts = createSelector(
	(state: AppState) => state.products,
	({ bundles, calculations, forms }) => {
		return {
			calculations: calculations
				?.filter(({ product }) => product.show_on_website)
				?.filter(({ product }) => !product.is_rider)
				.map((calculation) => {
					const copyOfCalculation = JSON.parse(JSON.stringify(calculation)) as Calculation;

					const calculationWithOptionsThatAreShown: Calculation = {
						...copyOfCalculation,
						product: {
							...copyOfCalculation.product,
							options: copyOfCalculation.product.options.filter(
								(option) => option.show_on_website || option.name === 'Bedrijfsaansprakelijkheid' // Hardcode Bedrijfsaansprakelijkheid because its part of a package
							),
						},
					};

					return calculationWithOptionsThatAreShown;
				})
				.filter(({ product }) => {
					const activeBundle = bundles?.find((bundle) => bundle.is_active);
					// Assume we are in the nvm, etc flow when no bundles are active
					if (!activeBundle) return true;

					// Only return the product when it is active and answered
					const productInActiveBundle = activeBundle.products.find(({ code }) => code === product.code);

					return (
						typeof productInActiveBundle !== 'undefined' &&
						(!productInActiveBundle.is_optional ||
							(productInActiveBundle.is_optional &&
								product.preconditions.every((precondition) => precondition.answer !== null)))
					);
				}),
			forms,
		};
	}
);

/**
 * Only return the active calculations and options that are allowed to be shown on the website
 * */
export const selectShownCalculationState = createSelector(
	(state: AppState) => state.products,
	({ bundles, calculations, forms }) => {
		return {
			calculations: calculations
				?.filter(({ product }) => product.show_on_website)
				.map((calculation) => {
					const calculationWithOptionsThatAreShown: Calculation = {
						...calculation,
						product: {
							...calculation.product,
							options: calculation.product.options.filter(
								(option) => option.show_on_website || option.name === 'Bedrijfsaansprakelijkheid' // Hardcode Bedrijfsaansprakelijkheid because its part of a package
							),
						},
					};

					return calculationWithOptionsThatAreShown;
				})
				.filter(({ product }) => {
					const activeBundle = bundles?.find((bundle) => bundle.is_active);
					// Assume we are in the nvm, etc flow when no bundles are active
					if (!activeBundle) return true;

					// Only return the product when it is active and answered
					const productInActiveBundle = activeBundle.products.find(({ code }) => code === product.code);

					return (
						typeof productInActiveBundle !== 'undefined' &&
						(!productInActiveBundle.is_optional ||
							(productInActiveBundle.is_optional &&
								product.preconditions.every((precondition) => precondition.answer !== null)))
					);
				}),
			forms,
		};
	}
);

/**
 * Only return the active calculations and options that are allowed to be shown on the website
 * */
export const selectShownCalculationStateWithoutOptionalProducts = createSelector(
	(state: AppState) => state.products,
	({ bundles, calculations, forms }) => {
		return {
			calculations: calculations
				?.filter((calculation) => calculation.product.show_on_website)
				.filter(({ product }) => {
					const activeBundle = bundles?.find((bundle) => bundle.is_active);
					if (!activeBundle) return false;

					return activeBundle.products.some(({ is_optional, code }) => product.code === code && !is_optional);
				})
				.map((calculation) => {
					const copyOfCalculation = JSON.parse(JSON.stringify(calculation)) as Calculation;

					const calculationWithOptionsThatAreShown: Calculation = {
						...copyOfCalculation,
						product: {
							...copyOfCalculation.product,
							options: copyOfCalculation.product.options.filter(
								(option) => option.show_on_website || option.name === 'Bedrijfsaansprakelijkheid' // Hardcode Bedrijfsaansprakelijkheid because its part of a package
							),
						},
					};

					return calculationWithOptionsThatAreShown;
				}),
			forms,
		};
	}
);

/**
 * Return all the active calculations options
 * */
export const selectActiveCalculationProducts = createSelector(
	(state: AppState) => state.products,
	({ calculations }) =>
		calculations
			?.filter((calculation) => calculation.product.show_on_website && calculation.product.is_active)
			.map((calculation) => calculation.product)
);

/**
 * Only return the active calculations product option
 * */
export const selectActiveCalculationBundle = createSelector(
	(state: AppState) => state.products,
	({ bundles }) => bundles?.find((bundle) => bundle.is_active)
);

/**
 * Only return the products that are optional
 * */
export const selectOptionalProducts = createSelector(
	(state: AppState) => state.products,
	({ bundles, calculations, forms }) => {
		return {
			calculations: calculations?.filter(({ product }) => {
				const activeBundle = bundles?.find((bundle) => bundle.is_active);

				if (!activeBundle) return false;

				return activeBundle.products.some(({ is_optional, code }) => product.code === code && is_optional);
			}),
			forms,
		};
	}
);

/**
 * Return the active water damage based on the active bundle
 * */
export const selectActiveWaterDamage = createSelector(
	(state: AppState) => state.products,
	({ bundles, calculations }) => {
		const activeBundle = bundles?.find((bundle) => bundle.is_active);

		if (!activeBundle || !calculations) {
			return undefined;
		}

		const calculationsWithActiveBundle = calculations.filter(
			({ product }) => product.is_active && activeBundle.products.some(({ code }) => code === product.code)
		);

		if (!calculationsWithActiveBundle) {
			return undefined;
		}

		const calculationWithWaterDamage = calculationsWithActiveBundle.find(({ product }) =>
			product.options.some(({ specs }) =>
				specs.some((spec) => spec.is_deductible && spec.deductible_type === 'water-damage')
			)
		);

		if (!calculationWithWaterDamage) {
			return undefined;
		}

		const waterDamageValue = calculationWithWaterDamage?.product.options
			.find((option) => option.is_active)
			?.specs.find((spec) => spec.is_deductible && spec.deductible_type === 'water-damage')?.value;

		return waterDamageValue;
	}
);

/**
 * Return the deductible based on the active bundle
 * */
export const selectSelectedBundleDeductible = createSelector(
	(state: AppState) => state.products,
	({ bundles, calculations }) => {
		const activeBundle = bundles?.find((bundle) => bundle.is_active);

		if (!activeBundle || !calculations) {
			return undefined;
		}

		const calculationsWithActiveBundle = calculations.filter(
			({ product }) => product.is_active && activeBundle.products.some(({ code }) => code === product.code)
		);

		if (!calculationsWithActiveBundle) {
			return undefined;
		}

		let deductible = undefined;

		calculationsWithActiveBundle.forEach(({ product }) => {
			const _deductible = product.options
				.find((option) => option.is_active)
				?.specs.find((spec) => spec.is_deductible)?.value;

			if (_deductible) {
				deductible = _deductible;
			}
		});

		return deductible;
	}
);

export const selectBundles = (state: AppState) => state.products.bundles;

import { rootApi } from '../../../../common/store/root/root.api';

export const resendConfirmationApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		// Resend confirmation mail
		resendConfirmationMail: builder.mutation<void, { cart_guid: string; email: string }>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/resend-confirmation`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['Email'],
		}),
	}),
	overrideExisting: true,
});

export const { resendConfirmationMail } = resendConfirmationApi.endpoints;

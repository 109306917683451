import z from 'zod';

import { FlowState, TranslationType } from '@monorepo/types';

import { D_DATE_FORMAT, formatDate, isIban, today } from '../utils';

export const summarySchema = ({
	t,
	state,
	maxDate,
	minDate,
}: {
	t: TranslationType;
	state: FlowState;
	minDate?: Date;
	maxDate?: Date;
}) => {
	const min: Date = minDate ? minDate : (today({ asDate: true, dayModifier: -1 }) as Date);
	const max: Date = maxDate ? maxDate : (today({ asDate: true, dayModifier: 90 }) as Date);

	min.setHours(0, 0, 0, 0);
	max.setHours(0, 0, 0, 0);

	return z.object({
		iban: z
			.string()
			.toUpperCase()
			.optional()
			.superRefine((val, ctx) => {
				if (
					state === 'wel-itc-happy' ||
					state === 'geen-itc-happy' ||
					state === 'inreview-wel-itc-sad' ||
					state === 'inreview-geen-itc-sad'
				) {
					if (!val) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							message: t('validation.required'),
						});
					} else if (!isIban(val as string)) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							message: t('validation.format'),
						});
					}
				}
			}),

		effective_date: z
			.date()
			.min(min, t('validation.dateMin', { date: formatDate({ date: min, dateFormat: D_DATE_FORMAT }) }))
			.max(max, t('validation.dateMax'))
			.optional()
			.superRefine((val, ctx) => {
				if (state === 'geen-itc-happy' || state === 'inreview-geen-itc-sad') {
					if (!val) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							message: t('validation.required'),
						});
					}
				}
			}),
		terms_agreement: z.literal<boolean>(true, { required_error: t('validation.required') }),
	});
};

export type SummarySchema = z.infer<ReturnType<typeof summarySchema>>;

export enum MediaTypes {
	Condition = 'condition',
	Ipid = 'ipid',
	GeneralTerms = 'terms_and_conditions',
	ServiceGuide = 'service_guide',
	GeneralInsuranceTerms = 'generalInsuranceTerms',
}
export type Media = {
	type: MediaTypes;
	name?: string | null;
	filename: string;
	mimetype: string;
	path: string;
};
export type OptionDocument = {
	name: string;
	code: string;
	documents: Media[];
};

export type ProductDocument = {
	name: string;
	code: string;
	object?: {
		guid: string;
		reference: string;
	};
	composition?: {
		guid: string;
		name: string;
	};
	documents: Media[];
	options: OptionDocument[];
	addons: OptionDocument[];
};

export type InsuranceDocs = {
	products: ProductDocument[];
	documents: Media[];
};

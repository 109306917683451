import { FC } from 'react';

import { CmsContainer } from '@monorepo/types';

import { componentMapper, HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleProductDetails = <P extends object>(container: HandlerProps): FC<P> => {
	return () => (
		<div className='flex flex-col space-y-32'>
			{componentMapper(container as unknown as CmsContainer).map((Component, index) => (
				<Component key={`${Component.name}-${index}`} />
			))}
		</div>
	);
};

import { FC } from 'react';

import { InfoAccordion, InfoAccordionProps } from '@monorepo/components/DataDisplay';
import { TitleAndText } from '@monorepo/components/TextComponents';
import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleInfoAccordion = ({ component }: HandlerProps): FC<InfoAccordionProps> => {
	const title = getProperty('title', component.custom_fields);
	const subTitle = getProperty('subTitle', component.custom_fields);

	return (props) => (
		<div className='space-y-16'>
			<TitleAndText title={`${title}`} text={`${subTitle}`} />
			{component.child_elements.map(({ custom_fields, markdown }, index) => {
				const title = getProperty('title', custom_fields);
				return (
					<InfoAccordion key={`dynamic-accordion-${index}`} {...props} title={`${title}`} items={[`${markdown}`]} />
				);
			})}
		</div>
	);
};

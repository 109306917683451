import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { BlurbItem, CenteredBlurbSection } from '@marketing/components/CenteredBlurbSection/CenteredBlurbSection';

export const handlePortalTopicsSection = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextElement = elements.find((element) => element.identifier === 'title-subtext') as CmsElement;
	const title = getProperty('title', titleSubtextElement?.custom_fields);
	const description = getProperty('subtext', titleSubtextElement?.custom_fields);

	const portalTopicElements = elements.filter((element) => element.identifier === 'portal-topic') as CmsElement[];

	const blurbs: BlurbItem[] = portalTopicElements.map(({ child_elements, media, links }) => {
		const portalTopicTitleSubtextElement = child_elements.find(
			(element) => element.identifier === 'title-subtext'
		) as CmsElement;

		const [image] = media;
		const [link] = links;

		const title = getProperty('title', portalTopicTitleSubtextElement?.custom_fields);
		const subtext = getProperty('subtext', portalTopicTitleSubtextElement?.custom_fields);

		return {
			image: image,
			title: `${title}`,
			description: `${subtext}`,
			link,
		};
	});
	return () => <CenteredBlurbSection title={`${title}`} description={`${description}`} blurbs={blurbs} />;
};

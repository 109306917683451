export const mockFeatureSection = {
	title: 'Bescherming tegen specialistische risicos',
	description:
		'In de haar & beauty wereld zijn er unieke risicos, zoals allergieën en apparatuurschade. Met een op maat gemaakte verzekering van Maia Insure ben je gedekt, zodat jij je kunt focussen op wat je het beste doet: stralende diensten leveren.',
	image: {
		source: '/images/marketing/cta-search-background.png',
		alt: 'Alt image',
	},
	linkText: 'Button tekst',
	linkHref: 'https://www.google.com',
};

import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { IDynamicColumnSection } from '@monorepo/types';

import { BlurbCard } from '../BlurbCard/BlurbCard';

export type DynamicColumnSectionProps = IDynamicColumnSection & {
	className?: string;
};

export const DynamicColumnSection: FC<DynamicColumnSectionProps> = ({ className, blurbs }) => {
	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 grid grid-cols-3 gap-32'>
					{blurbs.map((blurb, key) => (
						<BlurbCard key={`section-column-dynamic-card-${key}`} enableEffects={false} isElevated={false} {...blurb} />
					))}
				</div>
			</LayoutGrid>
		</section>
	);
};

import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sortAscending, transformToCurrency } from '@monorepo/shared/lib/utils';
import { CalculationProductOptionSpec } from '@monorepo/types';

import { formatError, logger } from '@common/utils';
import {
	selectActiveCalculationProducts,
	selectActiveWaterDamage,
	setCalculatingCart,
	updateActiveProductsBasedOnDefaultDeductible,
	useToggleDeductibleMutation,
} from '@funnel/store';

export const useOverview = (cartGuid: string) => {
	const activeProducts = useSelector(selectActiveCalculationProducts);
	const dispatch = useDispatch();
	const [toggleDeductible] = useToggleDeductibleMutation();
	const activeWaterDamage = useSelector(selectActiveWaterDamage);

	const deductibles = useMemo(() => {
		const specs = activeProducts
			?.map((activeProduct) => activeProduct.options)
			.flat()
			.map((option) => option.specs)
			.flat()
			.filter((spec) => spec.is_deductible && spec.deductible_type === 'default');

		const uniqueSpecs = specs?.reduce((accumulator: CalculationProductOptionSpec[], currentObject) => {
			if (!accumulator.some((obj) => obj.value === currentObject.value)) {
				return [...accumulator, currentObject];
			}
			return accumulator;
		}, []);

		return uniqueSpecs
			?.sort((a, b) => sortAscending(parseFloat(a.value), parseFloat(b.value)))
			.flat()
			.map((spec) => ({
				label: transformToCurrency(spec.value),
				value: spec.value,
				checked: activeProducts.some((activeProduct) =>
					activeProduct.options
						.filter((option) => option.is_active)
						.some((option) =>
							option.specs.some(
								(_spec) => _spec.is_deductible && _spec.deductible_type === 'default' && _spec.value === spec.value
							)
						)
				),
			}));
	}, [activeProducts]);

	const handleChangeDeductible = async (deductible: string) => {
		try {
			dispatch(setCalculatingCart(true));

			await toggleDeductible({
				cart_guid: cartGuid,
				deductible: deductible,
			});

			dispatch(updateActiveProductsBasedOnDefaultDeductible({ deductible }));
		} catch (error) {
			logger.error('Failed to change deductible', {
				error: formatError(error),
				cartGuid,
				deductible,
			});
		} finally {
			dispatch(setCalculatingCart(false));
		}
	};

	return {
		deductibles,
		activeWaterDamage,
		handleChangeDeductible,
	};
};

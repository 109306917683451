import { Middleware } from '@reduxjs/toolkit';

import { modalSlice } from '@common/store/slices/slices';

export const modalMiddleware: Middleware = () => (next) => (action) => {
	if (action.type === modalSlice.actions.hideModal.type) {
		document?.querySelector('body.overflow-hidden')?.classList.remove('overflow-hidden');
	}
	return next(action);
};

import cn from 'classnames';
import { FC } from 'react';

import { Skeleton } from '../../../Misc';

export const AccordionSkeleton: FC = () => {
	return (
		<div className={cn('border-grayscale100 rounded-12 border px-20 py-16 md:p-20')}>
			<div className='flex select-none items-center justify-between'>
				<Skeleton variant='text' size='body-m' className='w-[150px]' />
				<Skeleton variant='circular' className='h-[21px] w-[21px]' />
			</div>
		</div>
	);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const emailSchema = (t: TranslationType) =>
	z.object({
		email: z.string({ required_error: t('validation.required') }).email(t('validation.email')),
	});

export type EmailSchema = z.infer<ReturnType<typeof emailSchema>>;

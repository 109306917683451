import { Calculation } from '@monorepo/types';

export const ALL_CALCULATIONS: Calculation[] = [
	{
		is_active: true,
		product: {
			guid: '9b150a4d-e6d4-4a27-9456-5020a26992a9',
			is_active: true,
			name: 'Werknemersschadeverzekering',
			code: 'M-ALG-AB-0002',
			insurer: 'HDI Global Specialty SE',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 16.42,
			risk_addresses: [],
			render_type: 'default',
			options: [
				{
					is_active: true,
					guid: 'ec1c6203-a4b6-4e09-b79e-7f22cac11c8a',
					name: 'Werknemersschadeverzekering',
					code: 'M-ALG-AB-0002-0001',
					is_calculated: false,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 0,
						discount: 0,
						premium_after_discount: 0,
						tax: 0,
						premium_after_tax: 0,
					},
					specs: [
						{
							reference: 'insured_amount_M-ALG-AB-0002-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '1000000',
						},
						{
							reference: 'insured_amount_yearly_M-ALG-AB-0002-0001',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '2000000',
						},
						{
							reference: 'deductible_M-ALG-AB-0002-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
					],
				},
			],
			preconditions: [
				{
					guid: '3e4769be-a664-4a1e-a1a0-174033338175',
					reference: 'gross-wage',
					answer: '12345',
				},
			],
			icon_name: 'hard-hat',
			specs: [
				{
					reference: 'insured_amount_M-ALG-AB-0002-0001',
					order: 10,
				},
				{
					reference: 'insured_amount_yearly_M-ALG-AB-0002-0001',
					order: 20,
				},
				{
					reference: 'deductible_M-ALG-AB-0002-0001',
					order: 30,
				},
				{
					reference: 'gross-wage',
					order: 40,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: 'bb1bd38e-f445-4c02-beaa-f3c9212d0318',
			is_active: true,
			name: 'Cyberverzekering',
			code: 'M-ALG-AB-0001',
			insurer: 'AIG Europe S.A.',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: null,
			risk_addresses: [],
			render_type: 'default',
			options: [
				{
					is_active: false,
					guid: '19ff87f6-7586-4390-b295-0f7a5a731e04',
					name: 'Cyberverzekering',
					code: 'M-ALG-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 42.35,
						discount: 0,
						premium_after_discount: 42.35,
						tax: 8.89,
						premium_after_tax: 51.24,
					},
					specs: [
						{
							reference: 'additional_cyber_system-failures',
							is_insured_amount: false,
							is_deductible: false,
							value: 'N',
						},
						{
							reference: 'insured_amount_M-ALG-AB-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '100000',
						},
						{
							reference: 'deductible_M-ALG-AB-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
					],
				},
				{
					is_active: true,
					guid: '993e5aa1-02ec-4299-bb5b-18f2445aa7d9',
					name: 'Cyberverzekering',
					code: 'M-ALG-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 48.71,
						discount: 0,
						premium_after_discount: 48.71,
						tax: 10.23,
						premium_after_tax: 58.94,
					},
					specs: [
						{
							reference: 'additional_cyber_system-failures',
							is_insured_amount: false,
							is_deductible: false,
							value: 'J',
						},
						{
							reference: 'insured_amount_M-ALG-AB-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '100000',
						},
						{
							reference: 'deductible_M-ALG-AB-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: 'd52c8adc-e028-4600-9476-5e28d0a3e57a',
					name: 'Cyberverzekering',
					code: 'M-ALG-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 54.9,
						discount: 0,
						premium_after_discount: 54.9,
						tax: 11.53,
						premium_after_tax: 66.43,
					},
					specs: [
						{
							reference: 'additional_cyber_system-failures',
							is_insured_amount: false,
							is_deductible: false,
							value: 'N',
						},
						{
							reference: 'insured_amount_M-ALG-AB-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '250000',
						},
						{
							reference: 'deductible_M-ALG-AB-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: 'f146490d-1047-4779-bf72-ef504440b5ca',
					name: 'Cyberverzekering',
					code: 'M-ALG-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 63.14,
						discount: 0,
						premium_after_discount: 63.14,
						tax: 13.26,
						premium_after_tax: 76.4,
					},
					specs: [
						{
							reference: 'additional_cyber_system-failures',
							is_insured_amount: false,
							is_deductible: false,
							value: 'J',
						},
						{
							reference: 'insured_amount_M-ALG-AB-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '250000',
						},
						{
							reference: 'deductible_M-ALG-AB-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: 'df7b1758-b7d1-417c-a04a-35c97892524e',
					name: 'Cyberverzekering',
					code: 'M-ALG-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 80,
						discount: 0,
						premium_after_discount: 80,
						tax: 16.8,
						premium_after_tax: 96.8,
					},
					specs: [
						{
							reference: 'additional_cyber_system-failures',
							is_insured_amount: false,
							is_deductible: false,
							value: 'N',
						},
						{
							reference: 'insured_amount_M-ALG-AB-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '500000',
						},
						{
							reference: 'deductible_M-ALG-AB-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: '06ac332d-5d6e-4ab2-8aa4-b2b8b086a5ff',
					name: 'Cyberverzekering',
					code: 'M-ALG-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 92,
						discount: 0,
						premium_after_discount: 92,
						tax: 19.32,
						premium_after_tax: 111.32,
					},
					specs: [
						{
							reference: 'additional_cyber_system-failures',
							is_insured_amount: false,
							is_deductible: false,
							value: 'J',
						},
						{
							reference: 'insured_amount_M-ALG-AB-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '500000',
						},
						{
							reference: 'deductible_M-ALG-AB-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: '251f70db-d047-47a8-9cfb-bd0a02635278',
					name: 'Cyberverzekering',
					code: 'M-ALG-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 116.08,
						discount: 0,
						premium_after_discount: 116.08,
						tax: 24.38,
						premium_after_tax: 140.46,
					},
					specs: [
						{
							reference: 'additional_cyber_system-failures',
							is_insured_amount: false,
							is_deductible: false,
							value: 'N',
						},
						{
							reference: 'insured_amount_M-ALG-AB-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '1000000',
						},
						{
							reference: 'deductible_M-ALG-AB-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: 'cd1615d4-3fd4-4013-bdef-46ae9ac42f02',
					name: 'Cyberverzekering',
					code: 'M-ALG-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 133.49,
						discount: 0,
						premium_after_discount: 133.49,
						tax: 28.03,
						premium_after_tax: 161.52,
					},
					specs: [
						{
							reference: 'additional_cyber_system-failures',
							is_insured_amount: false,
							is_deductible: false,
							value: 'J',
						},
						{
							reference: 'insured_amount_M-ALG-AB-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '1000000',
						},
						{
							reference: 'deductible_M-ALG-AB-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'globe',
			specs: [
				{
					reference: 'additional_cyber_system-failures',
					order: 10,
				},
				{
					reference: 'insured_amount_M-ALG-AB-0001-0001',
					order: 20,
				},
				{
					reference: 'deductible_M-ALG-AB-0001-0001',
					order: 30,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: '61a1db64-4eec-4dff-bb78-e8d690683902',
			is_active: true,
			name: 'Alles-in-één aansprakelijkheidsverzekering',
			code: 'M-MAK-AB-0001',
			insurer: 'AIG Europe S.A.',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 64.27,
			risk_addresses: [],
			render_type: 'default',
			options: [
				{
					is_active: false,
					guid: '155e0156-502a-4349-a25a-d2e39c3f4bb0',
					name: 'Beroepsaansprakelijkheid',
					code: 'M-MAK-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 53.11,
						discount: 0,
						premium_after_discount: 53.11,
						tax: 11.16,
						premium_after_tax: 64.27,
					},
					specs: [
						{
							reference: 'insured_amount_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '500000',
						},
						{
							reference: 'insured_amount_yearly_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '1000000',
						},
						{
							reference: 'deductible_M-ABM-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '5000',
						},
					],
				},
				{
					is_active: true,
					guid: '8e320a0c-7a49-4f0a-9917-4dca5fdb7386',
					name: 'Beroepsaansprakelijkheid',
					code: 'M-MAK-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 55.15,
						discount: 0,
						premium_after_discount: 55.15,
						tax: 11.59,
						premium_after_tax: 66.74,
					},
					specs: [
						{
							reference: 'insured_amount_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '500000',
						},
						{
							reference: 'insured_amount_yearly_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '1000000',
						},
						{
							reference: 'deductible_M-ABM-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '2000',
						},
					],
				},
				{
					is_active: false,
					guid: '6d9e32fb-e078-40cf-bfbd-6a7f6199468a',
					name: 'Beroepsaansprakelijkheid',
					code: 'M-MAK-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 74.95,
						discount: 0,
						premium_after_discount: 74.95,
						tax: 15.74,
						premium_after_tax: 90.69,
					},
					specs: [
						{
							reference: 'insured_amount_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '1250000',
						},
						{
							reference: 'insured_amount_yearly_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '1250000',
						},
						{
							reference: 'deductible_M-ABM-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '5000',
						},
					],
				},
				{
					is_active: false,
					guid: '8e2e09fe-cead-488a-9b38-bd4a14ed4585',
					name: 'Beroepsaansprakelijkheid',
					code: 'M-MAK-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 77.9,
						discount: 0,
						premium_after_discount: 77.9,
						tax: 16.36,
						premium_after_tax: 94.26,
					},
					specs: [
						{
							reference: 'insured_amount_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '1250000',
						},
						{
							reference: 'insured_amount_yearly_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '1250000',
						},
						{
							reference: 'deductible_M-ABM-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '2000',
						},
					],
				},
				{
					is_active: false,
					guid: 'fec68ac4-8bba-4140-847d-84616be7e497',
					name: 'Beroepsaansprakelijkheid',
					code: 'M-MAK-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 116.73,
						discount: 0,
						premium_after_discount: 116.73,
						tax: 24.52,
						premium_after_tax: 141.25,
					},
					specs: [
						{
							reference: 'insured_amount_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '2500000',
						},
						{
							reference: 'insured_amount_yearly_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '2500000',
						},
						{
							reference: 'deductible_M-ABM-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '5000',
						},
					],
				},
				{
					is_active: false,
					guid: 'b62a4e11-8609-4249-889c-558c2c4f145c',
					name: 'Beroepsaansprakelijkheid',
					code: 'M-MAK-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 121.42,
						discount: 0,
						premium_after_discount: 121.42,
						tax: 25.5,
						premium_after_tax: 146.92,
					},
					specs: [
						{
							reference: 'insured_amount_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '2500000',
						},
						{
							reference: 'insured_amount_yearly_M-ABM-0001',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '2500000',
						},
						{
							reference: 'deductible_M-ABM-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '2000',
						},
					],
				},
				{
					is_active: true,
					guid: '9e9db453-40ff-43ea-8977-556ae12217b3',
					name: 'Bedrijfsaansprakelijkheid',
					code: 'M-MAK-AB-0001-0002',
					is_calculated: true,
					show_on_website: false,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 0,
						discount: 0,
						premium_after_discount: 0,
						tax: 0,
						premium_after_tax: 0,
					},
					specs: [
						{
							reference: 'insured_amount_M-ABM-0002',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '2500000',
						},
						{
							reference: 'insured_amount_yearly_M-ABM-0002',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '5000000',
						},
						{
							reference: 'deductible_M-ABM-0002',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '250',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'balance-scale',
			specs: [
				{
					reference: 'insured_amount_M-ABM-0001',
					order: 10,
				},
				{
					reference: 'insured_amount_yearly_M-ABM-0001',
					order: 20,
				},
				{
					reference: 'deductible_M-ABM-0001',
					order: 30,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: '28de65e6-5074-4839-b736-1fd1f67fb1e6',
			is_active: true,
			name: 'Ongevallenverzekering',
			code: 'M-ZZP-CO-0001',
			insurer: 'Quantum Leben AG',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: false,
			minimum_price: null,
			risk_addresses: [],
			render_type: 'default',
			options: [
				{
					is_active: true,
					guid: '01183109-db4d-4b12-b086-7560308753a9',
					name: 'Ongevallenverzekering',
					code: 'M-ZZP-CO-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: 'first_year',
						discount_percentage: 100.0,
						premium_before_discount: 1.58,
						discount: 1.58,
						premium_after_discount: 0.0,
						tax: 0.0,
						premium_after_tax: 0.0,
					},
					specs: [
						{
							reference: 'insured_amount_deceased_M-ZZP-CO-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'deceased',
							is_deductible: false,
							value: '33000',
						},
						{
							reference: 'insured_amount_disability_M-ZZP-CO-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'disability',
							is_deductible: false,
							value: '66000',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'band-aid',
			specs: [
				{
					reference: 'insured_amount_deceased_M-ZZP-CO-0001-0001',
					order: 10,
				},
				{
					reference: 'insured_amount_disability_M-ZZP-CO-0001-0001',
					order: 20,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: '82f4c600-db11-4272-a942-c7e6cf60af5d',
			is_active: true,
			name: 'Aansprakelijkheidsverzekering',
			code: 'M-OND-AB-0001',
			insurer: 'Nationale-Nederlanden Schadeverzekering Maatschappij N.V.',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: null,
			risk_addresses: [],
			render_type: 'default',
			options: [
				{
					is_active: true,
					guid: '20934d94-1238-4d12-a3bc-5aed267a22a0',
					name: 'Aansprakelijkheidsverzekering',
					code: 'M-OND-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 41.85,
						discount: 0.0,
						premium_after_discount: 41.85,
						tax: 8.79,
						premium_after_tax: 50.64,
					},
					specs: [
						{
							reference: 'insured_amount_case_M-OND-AB-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '2500000',
						},
						{
							reference: 'insured_amount_yearly_M-OND-AB-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '5000000',
						},
						{
							reference: 'deductible_M-OND-AB-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '500',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'balance-scale',
			specs: [
				{
					reference: 'insured_amount_case_M-OND-AB-0001-0001',
					order: 10,
				},
				{
					reference: 'insured_amount_yearly_M-OND-AB-0001-0001',
					order: 20,
				},
				{
					reference: 'deductible_M-OND-AB-0001-0001',
					order: 30,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: 'd0dbb5d1-a5c8-4027-871b-24272a6786b2',
			is_active: true,
			name: 'Spullen-in-je-busverzekering',
			code: 'M-OND-TR-0001',
			insurer: 'Nationale-Nederlanden Schadeverzekering Maatschappij N.V.',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: null,
			risk_addresses: [],
			render_type: 'default',
			options: [
				{
					is_active: true,
					guid: '411127ef-ccf4-48a0-8158-8c9822c9d4ad',
					name: 'Spullen-in-je-busverzekering',
					code: 'M-OND-TR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 11.37,
						discount: 0.0,
						premium_after_discount: 11.37,
						tax: 0.6,
						premium_after_tax: 11.97,
					},
					specs: [
						{
							reference: 'insured_amount_case_M-OND-TR-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '5000',
						},
						{
							reference: 'deductible_theft_M-OND-TR-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'theft',
							value: '1000',
						},
						{
							reference: 'deductible_other_M-OND-TR-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '500',
						},
					],
				},
				{
					is_active: false,
					guid: 'bf6a6798-b091-4305-9c1f-04c6d9c926cc',
					name: 'Spullen-in-je-busverzekering',
					code: 'M-OND-TR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 18.8,
						discount: 0.0,
						premium_after_discount: 18.8,
						tax: 0.99,
						premium_after_tax: 19.79,
					},
					specs: [
						{
							reference: 'insured_amount_case_M-OND-TR-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '20000',
						},
						{
							reference: 'deductible_theft_M-OND-TR-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'theft',
							value: '1000',
						},
						{
							reference: 'deductible_other_M-OND-TR-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '500',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'truck',
			specs: [
				{
					reference: 'insured_amount_case_M-OND-TR-0001-0001',
					order: 10,
				},
				{
					reference: 'deductible_theft_M-OND-TR-0001-0001',
					order: 10,
				},
				{
					reference: 'deductible_other_M-OND-TR-0001-0001',
					order: 30,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: 'ba23d35e-851a-460d-a6b5-dad89972510b',
			is_active: true,
			name: 'Spullen-in-je-opslagverzekering',
			code: 'M-OND-BR-0001',
			insurer: 'Nationale-Nederlanden Schadeverzekering Maatschappij N.V.',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: null,
			render_type: 'default',
			risk_addresses: [
				{
					street: 'Boswinkelsweg',
					house_number: 2,
					house_number_appendix: '',
					postal_code: '7475MB',
					city: 'Markelo',
					country: 'Nederland',
				},
			],
			options: [
				{
					is_active: true,
					guid: '1ca316e8-2522-4a09-8ce2-a9fc4736df1d',
					name: 'Spullen-in-je-opslagverzekering',
					code: 'M-OND-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 7.41,
						discount: 0.0,
						premium_after_discount: 7.41,
						tax: 1.56,
						premium_after_tax: 8.97,
					},
					specs: [
						{
							reference: 'deductible_M-OND-BR-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '500',
						},
						{
							reference: 'insured_amount_case_M-OND-BR-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '15000',
						},
					],
				},
				{
					is_active: false,
					guid: 'b7295a64-9662-4e36-ae08-aded8dcc3551',
					name: 'Spullen-in-je-opslagverzekering',
					code: 'M-OND-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 11.11,
						discount: 0.0,
						premium_after_discount: 11.11,
						tax: 2.33,
						premium_after_tax: 13.44,
					},
					specs: [
						{
							reference: 'deductible_M-OND-BR-0001-0001',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '500',
						},
						{
							reference: 'insured_amount_case_M-OND-BR-0001-0001',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '30000',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'estate',
			specs: [
				{
					reference: 'deductible_M-OND-BR-0001-0001',
					order: 10,
				},
				{
					reference: 'insured_amount_case_M-OND-BR-0001-0001',
					order: 10,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: '06206c03-5e7b-4493-92c6-9bda3cc8e975',
			is_active: true,
			name: 'Vangnet voor verweer',
			code: 'M-ALG-AB-0003',
			insurer: 'DAS Nederlandse Rechtsbijstand Verzekeringmaatschappij N.V.',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: false,
			minimum_price: 9.08,
			risk_addresses: [],
			render_type: 'default',
			options: [
				{
					is_active: true,
					guid: '1b9057c9-6102-4177-ba9c-4e8f5900f666',
					name: 'Vangnet voor verweer',
					code: 'M-ALG-AB-0003-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 7.5,
						discount: 0,
						premium_after_discount: 7.5,
						tax: 1.58,
						premium_after_tax: 9.08,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'dispute',
							is_deductible: false,
							value: '50000',
						},
						{
							reference: 'insured_amount_year',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '1000000',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'users-alt',
			specs: [
				{
					reference: 'insured_amount',
					order: 10,
				},
				{
					reference: 'insured_amount_year',
					order: 20,
				},
			],
			is_rider: true,
			dependant_on: [
				{
					guid: '51b2177b-9a62-44d2-a0d6-48ae0ce7c88f',
					name: 'Alles-in-één aansprakelijkheidsverzekering',
				},
			],
		},
	},
];

export const BUNDLE_CALCULATIONS: Calculation[] = [
	{
		is_active: true,
		product: {
			guid: '5067ac3b-26b7-4982-b841-784f9ea5f107',
			is_active: true,
			name: 'Bedrijfsaansprakelijkheid',
			code: 'M-BEA-AB-0001',
			insurer: 'Avéro Achmea',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 0.65,
			risk_addresses: [],
			render_type: 'default',
			options: [
				{
					is_active: false,
					guid: '5105a1d7-1c66-4766-9d59-57ef7218d92c',
					name: 'Bedrijfsaansprakelijkheid',
					code: 'M-BEA-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 5.39,
						discount: 0,
						premium_after_discount: 5.39,
						tax: 1.13,
						premium_after_tax: 6.52,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '2500000',
						},
						{
							reference: 'insured_amount_year',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '5000000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: '58a215bd-f373-432b-bd7d-83643395997b',
					name: 'Bedrijfsaansprakelijkheid',
					code: 'M-BEA-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 5.69,
						discount: 0,
						premium_after_discount: 5.69,
						tax: 1.19,
						premium_after_tax: 6.88,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '2500000',
						},
						{
							reference: 'insured_amount_year',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '5000000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '500',
						},
					],
				},
				{
					is_active: true,
					guid: '000a44d2-5d97-4788-aff0-669b213792b5',
					name: 'Bedrijfsaansprakelijkheid',
					code: 'M-BEA-AB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 5.99,
						discount: 0,
						premium_after_discount: 5.99,
						tax: 1.26,
						premium_after_tax: 7.25,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '2500000',
						},
						{
							reference: 'insured_amount_year',
							is_insured_amount: true,
							insured_amount_type: 'year',
							is_deductible: false,
							value: '5000000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '250',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'balance-scale',
			specs: [
				{
					reference: 'insured_amount',
					order: 10,
				},
				{
					reference: 'insured_amount_year',
					order: 20,
				},
				{
					reference: 'deductible',
					order: 30,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: 'cc2967d6-9039-41fa-9c25-2f0e40f9c175',
			is_active: true,
			name: 'Inventaris en goederen',
			code: 'M-BEA-BR-0001',
			insurer: 'Avéro Achmea',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 7.48,
			render_type: 'default',
			risk_addresses: [
				{
					street: 'Banne Buikslootlaan',
					house_number: 178,
					house_number_appendix: '30D',
					postal_code: '1034BE',
					city: 'Amsterdam',
					country: 'Nederland',
				},
			],
			options: [
				{
					is_active: false,
					guid: '66eb4ca4-0e12-4412-bea3-4328951c16c9',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 6.18,
						discount: 0,
						premium_after_discount: 6.18,
						tax: 1.3,
						premium_after_tax: 7.48,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '25000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1500',
						},
					],
				},
				{
					is_active: false,
					guid: '9bddea02-7fc0-49ca-addd-4ab4d413090c',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 6.52,
						discount: 0,
						premium_after_discount: 6.52,
						tax: 1.37,
						premium_after_tax: 7.89,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '25000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1000',
						},
					],
				},
				{
					is_active: true,
					guid: '27e5ca86-ac9f-4514-aa5c-676a336c0067',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 6.86,
						discount: 0,
						premium_after_discount: 6.86,
						tax: 1.44,
						premium_after_tax: 8.3,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '25000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '500',
						},
					],
				},
				{
					is_active: false,
					guid: 'c076adc4-f790-45ae-bd4f-d60324d9c4bf',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 12.35,
						discount: 0,
						premium_after_discount: 12.35,
						tax: 2.59,
						premium_after_tax: 14.94,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '50000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1500',
						},
					],
				},
				{
					is_active: false,
					guid: '2f0854b2-58c9-4cb1-b308-6965d36d1f37',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 13.04,
						discount: 0,
						premium_after_discount: 13.04,
						tax: 2.74,
						premium_after_tax: 15.78,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '50000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: '1211c8cd-220a-439a-82bb-4eb278f4cd46',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 13.73,
						discount: 0,
						premium_after_discount: 13.73,
						tax: 2.88,
						premium_after_tax: 16.61,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '50000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '500',
						},
					],
				},
				{
					is_active: false,
					guid: '96e1660d-597d-4922-963c-dea4a6dccef2',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 18.53,
						discount: 0,
						premium_after_discount: 18.53,
						tax: 3.89,
						premium_after_tax: 22.42,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '75000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1500',
						},
					],
				},
				{
					is_active: false,
					guid: 'd07a74da-8812-405e-a6c6-18412b3c0ce1',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 19.56,
						discount: 0,
						premium_after_discount: 19.56,
						tax: 4.11,
						premium_after_tax: 23.67,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '75000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: '07db2755-fbe9-444b-8784-27d9e915e326',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 20.59,
						discount: 0,
						premium_after_discount: 20.59,
						tax: 4.32,
						premium_after_tax: 24.91,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '75000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '500',
						},
					],
				},
				{
					is_active: false,
					guid: 'f2ab0c24-75bb-49b5-8600-2e6548b2a160',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 24.71,
						discount: 0,
						premium_after_discount: 24.71,
						tax: 5.19,
						premium_after_tax: 29.9,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '100000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1500',
						},
					],
				},
				{
					is_active: false,
					guid: '0699454b-93d4-478e-ac8a-e2ba41447c93',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 26.08,
						discount: 0,
						premium_after_discount: 26.08,
						tax: 5.48,
						premium_after_tax: 31.56,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '100000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: '5ceb765d-ab3c-4efc-9fbc-f44be87578f7',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 27.45,
						discount: 0,
						premium_after_discount: 27.45,
						tax: 5.76,
						premium_after_tax: 33.21,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '100000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '500',
						},
					],
				},
				{
					is_active: false,
					guid: '13ff999a-dfd1-4861-86af-3271e032abb9',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 49.41,
						discount: 0,
						premium_after_discount: 49.41,
						tax: 10.38,
						premium_after_tax: 59.79,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '200000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1500',
						},
					],
				},
				{
					is_active: false,
					guid: '57fd4e00-b266-4cc3-bcd8-de247a0d5cda',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 52.16,
						discount: 0,
						premium_after_discount: 52.16,
						tax: 10.95,
						premium_after_tax: 63.11,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '200000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: '15368bcf-adfb-4a70-aec9-8fd74c8abc41',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 54.9,
						discount: 0,
						premium_after_discount: 54.9,
						tax: 11.53,
						premium_after_tax: 66.43,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '200000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '500',
						},
					],
				},
				{
					is_active: false,
					guid: '52169690-cbe9-4867-be9f-89dcbd97b3b7',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 74.12,
						discount: 0,
						premium_after_discount: 74.12,
						tax: 15.57,
						premium_after_tax: 89.69,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '300000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1500',
						},
					],
				},
				{
					is_active: false,
					guid: 'a8b26913-c17d-4ca6-bcfc-c40b69de833e',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 78.24,
						discount: 0,
						premium_after_discount: 78.24,
						tax: 16.43,
						premium_after_tax: 94.67,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '300000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: 'd593a863-c257-49a2-a2db-58df6be90c0f',
					name: 'Inventaris en goederen',
					code: 'M-BEA-BR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 82.35,
						discount: 0,
						premium_after_discount: 82.35,
						tax: 17.29,
						premium_after_tax: 99.64,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '300000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '500',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'building',
			specs: [
				{
					reference: 'deductible',
					order: 10,
				},
				{
					reference: 'insured_amount',
					order: 20,
				},
				{
					reference: 'deductible_water_damage',
					order: 25,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: '49aaf14b-149d-4c32-b6bf-a506600632a9',
			is_active: true,
			name: 'Werknemersschade',
			code: 'M-BEA-AB-0002',
			insurer: 'Avéro Achmea',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 2.67,
			risk_addresses: [],
			render_type: 'default',
			options: [
				{
					is_active: false,
					guid: 'f89ecf47-4395-4c23-9591-6b535eda202c',
					name: 'Werknemersschade',
					code: 'M-BEA-AB-0002-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 2.21,
						discount: 0,
						premium_after_discount: 2.21,
						tax: 0.46,
						premium_after_tax: 2.67,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '1000',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '1000000',
						},
					],
				},
				{
					is_active: false,
					guid: '18436783-202d-46f7-ba56-a3878ba323c2',
					name: 'Werknemersschade',
					code: 'M-BEA-AB-0002-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 2.33,
						discount: 0,
						premium_after_discount: 2.33,
						tax: 0.49,
						premium_after_tax: 2.82,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '500',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '1000000',
						},
					],
				},
				{
					is_active: true,
					guid: 'cebcb7f6-30af-4768-9b6a-4bb3df56e4c2',
					name: 'Werknemersschade',
					code: 'M-BEA-AB-0002-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 2.45,
						discount: 0,
						premium_after_discount: 2.45,
						tax: 0.51,
						premium_after_tax: 2.96,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'case',
							value: '250',
						},
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'case',
							is_deductible: false,
							value: '1000000',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'car',
			specs: [
				{
					reference: 'deductible',
					order: 10,
				},
				{
					reference: 'insured_amount',
					order: 20,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: '98cd3004-cc8f-4135-b4ac-0850f342cb44',
			is_active: true,
			name: 'Omzetverlies',
			code: 'M-BEA-BR-0002',
			insurer: 'Avéro Achmea',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 1.22,
			render_type: 'default',
			risk_addresses: [
				{
					street: 'Banne Buikslootlaan',
					house_number: 178,
					house_number_appendix: '30D',
					postal_code: '1034BE',
					city: 'Amsterdam',
					country: 'Nederland',
				},
			],
			options: [
				{
					is_active: true,
					guid: 'e0e294b1-a56b-4657-8fb1-79c46b661409',
					name: 'Omzetverlies',
					code: 'M-BEA-BR-0002-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 10.09,
						discount: 0,
						premium_after_discount: 10.09,
						tax: 2.12,
						premium_after_tax: 12.21,
					},
					specs: [
						{
							reference: 'insured_amount_numeric',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '85000',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'image-broken',
			specs: [
				{
					reference: 'insured_amount_numeric',
					order: 10,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: 'ddd94ea3-e5b3-4d23-956f-ea9283fef110',
			is_active: true,
			name: 'Elektronica',
			code: 'M-BEA-BR-0003',
			insurer: 'Avéro Achmea',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 3.8,
			render_type: 'default',
			risk_addresses: [
				{
					street: 'Banne Buikslootlaan',
					house_number: 178,
					house_number_appendix: '30D',
					postal_code: '1034BE',
					city: 'Amsterdam',
					country: 'Nederland',
				},
			],
			options: [
				{
					is_active: false,
					guid: '8e9c9378-56a9-44be-83f6-57e69e2928ec',
					name: 'Elektronica',
					code: 'M-BEA-BR-0003-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 3.14,
						discount: 0,
						premium_after_discount: 3.14,
						tax: 0.66,
						premium_after_tax: 3.8,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '5000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: '8c273a43-8484-47fd-900d-a157a23e7afa',
					name: 'Elektronica',
					code: 'M-BEA-BR-0003-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 3.32,
						discount: 0,
						premium_after_discount: 3.32,
						tax: 0.7,
						premium_after_tax: 4.02,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '5000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
					],
				},
				{
					is_active: true,
					guid: 'f72345c9-0f1c-4448-b846-cdeb9aff45f8',
					name: 'Elektronica',
					code: 'M-BEA-BR-0003-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 3.49,
						discount: 0,
						premium_after_discount: 3.49,
						tax: 0.73,
						premium_after_tax: 4.22,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '5000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
					],
				},
				{
					is_active: false,
					guid: '0a7a24ec-9e0c-4de4-af30-3966fbff73c1',
					name: 'Elektronica',
					code: 'M-BEA-BR-0003-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 4.71,
						discount: 0,
						premium_after_discount: 4.71,
						tax: 0.99,
						premium_after_tax: 5.7,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '7500',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: 'f28d68f7-b50b-4f93-aaec-a433a971da35',
					name: 'Elektronica',
					code: 'M-BEA-BR-0003-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 4.98,
						discount: 0,
						premium_after_discount: 4.98,
						tax: 1.05,
						premium_after_tax: 6.03,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '7500',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
					],
				},
				{
					is_active: false,
					guid: '9e66c09e-e5ac-4972-9602-53e06bbb6514',
					name: 'Elektronica',
					code: 'M-BEA-BR-0003-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 5.24,
						discount: 0,
						premium_after_discount: 5.24,
						tax: 1.1,
						premium_after_tax: 6.34,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '7500',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
					],
				},
				{
					is_active: false,
					guid: '5f13e459-b080-434b-a863-7c56235e0299',
					name: 'Elektronica',
					code: 'M-BEA-BR-0003-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 6.28,
						discount: 0,
						premium_after_discount: 6.28,
						tax: 1.32,
						premium_after_tax: 7.6,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '10000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: 'cfba6ffb-64c8-49a5-94a3-b65780ad5b9f',
					name: 'Elektronica',
					code: 'M-BEA-BR-0003-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 6.63,
						discount: 0,
						premium_after_discount: 6.63,
						tax: 1.39,
						premium_after_tax: 8.02,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '10000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
					],
				},
				{
					is_active: false,
					guid: '8c68d92f-afa2-4c3b-9946-2b89f3330ff0',
					name: 'Elektronica',
					code: 'M-BEA-BR-0003-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 6.98,
						discount: 0,
						premium_after_discount: 6.98,
						tax: 1.47,
						premium_after_tax: 8.45,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '10000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'laptop',
			specs: [
				{
					reference: 'insured_amount',
					order: 10,
				},
				{
					reference: 'deductible',
					order: 20,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: '2e61b615-b0fb-44b8-a4ac-17a1813efd7f',
			is_active: true,
			name: 'Glas en lichtreclame',
			code: 'M-BEA-BR-0004',
			insurer: 'Avéro Achmea',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 2.19,
			render_type: 'default',
			risk_addresses: [
				{
					street: 'Banne Buikslootlaan',
					house_number: 178,
					house_number_appendix: '30D',
					postal_code: '1034BE',
					city: 'Amsterdam',
					country: 'Nederland',
				},
			],
			options: [
				{
					is_active: false,
					guid: 'd6b7d7f9-b39b-4619-b61c-e2111434b918',
					name: 'Glas en lichtreclame',
					code: 'M-BEA-BR-0004-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 1.81,
						discount: 0,
						premium_after_discount: 1.81,
						tax: 0.38,
						premium_after_tax: 2.19,
					},
					specs: [
						{
							reference: 'insured_amount_M-OND-BR-0001',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '15000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: 'f40deb31-98a3-4250-8ae9-14694f084827',
					name: 'Glas en lichtreclame',
					code: 'M-BEA-BR-0004-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 1.91,
						discount: 0,
						premium_after_discount: 1.91,
						tax: 0.4,
						premium_after_tax: 2.31,
					},
					specs: [
						{
							reference: 'insured_amount_M-OND-BR-0001',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '15000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
					],
				},
				{
					is_active: true,
					guid: '4012f139-8b9c-4e25-824b-1a1dee45f2af',
					name: 'Glas en lichtreclame',
					code: 'M-BEA-BR-0004-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 2.02,
						discount: 0,
						premium_after_discount: 2.02,
						tax: 0.42,
						premium_after_tax: 2.44,
					},
					specs: [
						{
							reference: 'insured_amount_M-OND-BR-0001',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '15000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'apps',
			specs: [
				{
					reference: 'insured_amount_M-OND-BR-0001',
					order: 5,
				},
				{
					reference: 'deductible',
					order: 10,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: 'f2b46b58-bf4b-42cb-9959-3f68f1437dcf',
			is_active: true,
			name: 'Spullen onderweg',
			code: 'M-BEA-TR-0001',
			insurer: 'Avéro Achmea',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 1.78,
			risk_addresses: [],
			render_type: 'default',
			options: [
				{
					is_active: false,
					guid: '164f0eb7-601d-452e-9f40-ddf513d3f6eb',
					name: 'Spullen onderweg',
					code: 'M-BEA-TR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 1.47,
						discount: 0,
						premium_after_discount: 1.47,
						tax: 0.31,
						premium_after_tax: 1.78,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '1500',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: 'acff6808-8c35-43b8-a074-9529f94243fd',
					name: 'Spullen onderweg',
					code: 'M-BEA-TR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 1.55,
						discount: 0,
						premium_after_discount: 1.55,
						tax: 0.33,
						premium_after_tax: 1.88,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '1500',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
					],
				},
				{
					is_active: true,
					guid: 'd98b56f2-edc1-4010-a559-38a64591a885',
					name: 'Spullen onderweg',
					code: 'M-BEA-TR-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 1.63,
						discount: 0,
						premium_after_discount: 1.63,
						tax: 0.34,
						premium_after_tax: 1.97,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '1500',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'truck',
			specs: [
				{
					reference: 'insured_amount',
					order: 10,
				},
				{
					reference: 'deductible',
					order: 20,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: 'bafcdaf7-50c7-4250-a021-73a3d9f62c9a',
			is_active: true,
			name: 'Geld',
			code: 'M-BEA-VB-0001',
			insurer: 'Avéro Achmea',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 2.72,
			render_type: 'default',
			risk_addresses: [
				{
					street: 'Banne Buikslootlaan',
					house_number: 178,
					house_number_appendix: '30D',
					postal_code: '1034BE',
					city: 'Amsterdam',
					country: 'Nederland',
				},
			],
			options: [
				{
					is_active: false,
					guid: 'a41ef272-7c11-4c09-b7e4-2ce10072d0ad',
					name: 'Geld',
					code: 'M-BEA-VB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 2.25,
						discount: 0,
						premium_after_discount: 2.25,
						tax: 0.47,
						premium_after_tax: 2.72,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '5000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
					],
				},
				{
					is_active: false,
					guid: '977674b8-ce6e-48a8-9b17-a285909e8548',
					name: 'Geld',
					code: 'M-BEA-VB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 2.37,
						discount: 0,
						premium_after_discount: 2.37,
						tax: 0.5,
						premium_after_tax: 2.87,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '5000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
					],
				},
				{
					is_active: true,
					guid: 'fa65b00e-065b-44b1-a915-51ab4fb09f00',
					name: 'Geld',
					code: 'M-BEA-VB-0001-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 2.49,
						discount: 0,
						premium_after_discount: 2.49,
						tax: 0.52,
						premium_after_tax: 3.01,
					},
					specs: [
						{
							reference: 'insured_amount',
							is_insured_amount: true,
							insured_amount_type: 'default',
							is_deductible: false,
							value: '5000',
						},
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
					],
				},
			],
			preconditions: [],
			icon_name: 'euro-circle',
			specs: [
				{
					reference: 'insured_amount',
					order: 10,
				},
				{
					reference: 'deductible',
					order: 20,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
	{
		is_active: true,
		product: {
			guid: 'e671b0cb-8d17-4aec-92bf-a9162ff7bf0c',
			is_active: true,
			name: 'Gebouw',
			code: 'M-BEA-BR-0005',
			insurer: 'Avéro Achmea',
			premium_is_calculated: true,
			show_on_website: true,
			sold_separately: true,
			minimum_price: 10.38,
			render_type: 'default',
			risk_addresses: [
				{
					street: 'Gladiolenstraat',
					house_number: 7,
					house_number_appendix: '',
					postal_code: '4695HT',
					city: 'Sint-Maartensdijk',
					country: 'Nederland',
				},
			],
			options: [
				{
					is_active: false,
					guid: '1eafc6fa-c708-4fb8-b9f2-213402052d21',
					name: 'Gebouw',
					code: 'M-BEA-BR-0005-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 0.91,
						discount: 0,
						premium_after_discount: 0.91,
						tax: 0.19,
						premium_after_tax: 1.1,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '1000',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1500',
						},
					],
				},
				{
					is_active: false,
					guid: '649af376-18f6-4969-9e39-50fd85f55aa5',
					name: 'Gebouw',
					code: 'M-BEA-BR-0005-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 0.96,
						discount: 0,
						premium_after_discount: 0.96,
						tax: 0.2,
						premium_after_tax: 1.16,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '500',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '1000',
						},
					],
				},
				{
					is_active: true,
					guid: 'acf5ca38-df70-4ed4-bfa8-20b65dc02362',
					name: 'Gebouw',
					code: 'M-BEA-BR-0005-0001',
					is_calculated: true,
					show_on_website: true,
					price: {
						promotion: null,
						discount_percentage: null,
						premium_before_discount: 1.01,
						discount: 0,
						premium_after_discount: 1.01,
						tax: 0.21,
						premium_after_tax: 1.22,
					},
					specs: [
						{
							reference: 'deductible',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'default',
							value: '250',
						},
						{
							reference: 'deductible_water_damage',
							is_insured_amount: false,
							is_deductible: true,
							deductible_type: 'water-damage',
							value: '500',
						},
					],
				},
			],
			preconditions: [
				{
					guid: '27988681-4cc8-4c27-af92-6f787b225fd3',
					reference: 'rebuild-value',
					answer: '10000',
				},
			],
			icon_name: 'building',
			specs: [
				{
					reference: 'deductible',
					order: 20,
				},
				{
					reference: 'deductible_water_damage',
					order: 25,
				},
			],
			is_rider: false,
			dependant_on: [],
		},
	},
];

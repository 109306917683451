import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Tag } from '@monorepo/components/DataDisplay';
import { Text } from '@monorepo/components/TextComponents';
import { transformToCurrency } from '@monorepo/shared/lib/utils';
import type { CalculationProduct, CalculationProductOptionSpecWithGuid, InsuredAmountTypes } from '@monorepo/types';

import { selectRevenueAnswer } from '@funnel/store';

type Props = {
	product: CalculationProduct;
	specs: CalculationProductOptionSpecWithGuid[][];
};
export const ProductFormText: FC<Props> = ({ product, specs }) => {
	const { t } = useTranslation(['common']);
	const flattenedSpecs = specs?.flat();
	const insuredAmounts = flattenedSpecs?.filter((spec) => spec.is_insured_amount);
	const deductibles = flattenedSpecs?.filter((spec) => spec.is_deductible);
	const revenue = useSelector(selectRevenueAnswer);

	return (
		<>
			{deductibles && deductibles.length > 0 && product.name === 'Werknemersschadeverzekering' && (
				<div className='space-y-4'>
					<Text as='p' variant='body-s' weight='medium' color='grayscale600'>
						{t('components.productForm.franchise.title')}
					</Text>
					<div className='flex flex-row items-center space-x-4'>
						<Text as='span' variant='body-s' color='grayscale500'>
							{t('components.productForm.franchise.description')}
						</Text>
						<Tag text={transformToCurrency(deductibles[0].value)} />
					</div>
				</div>
			)}

			{insuredAmounts && insuredAmounts.length > 0 && (
				<div className='space-y-4'>
					{product.name !== 'Omzetverlies' && (
						<Text variant='body-s' weight='medium' color='grayscale600'>
							{t('components.productForm.insuredAmounts.textTitle')}
							{product.name === 'Ongevallenverzekering' && <> {t('components.productForm.insuredAmounts.cao')}</>}
						</Text>
					)}
					<div>
						{insuredAmounts.map((s) => {
							const insuredAmountLabels: Record<string, string> = {
								disability: 'components.productForm.insuredAmounts.byDisabilityLabel',
								deceased: 'components.productForm.insuredAmounts.byDeceasedLabel',
								year: 'components.productForm.insuredAmounts.perYearLabel',
								case: 'components.productForm.insuredAmounts.perCaseLabel',
								default: 'components.productForm.insuredAmounts.defaultLabel',
								dispute: 'components.productForm.insuredAmounts.perDispute',
							};

							return (
								<Text variant='body-s' color='grayscale500' key={`productform-text-${s.option_guid}-${s.value}`}>
									{product.name === 'Omzetverlies' && revenue
										? t('components.productForm.insuredAmounts.oneOffs.omzetVerlies', {
												amount: transformToCurrency(Math.abs(0.85 * revenue)),
											})
										: t(insuredAmountLabels[s.insured_amount_type as InsuredAmountTypes], {
												amount: transformToCurrency(s.value),
											})}
								</Text>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};

import { Trans, useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ChoiceCard } from '@monorepo/components/DataDisplay';
import { Form } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { FteSchema } from '@monorepo/shared/lib/schemas';
import { Question } from '@monorepo/types';

import { showModal } from '@common/store';

type FteFormProps = {
	question: Question;
	onSubmit: ({ fte }: FteSchema) => void;
};

export const FteForm: FC<FteFormProps> = ({ onSubmit }) => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();

	return (
		<Form.Context<FteSchema>>
			{({ handleSubmit, getValues, reset, register }) => {
				return (
					<Form id='fte-form' onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className='space-y-24'>
							<ChoiceCard
								inputPosition='left'
								onClick={() => reset({ fteType: 'single', fte: 1 })}
								inputElement={<Form.Radio id='fte-single' register={register} name='fteType' value='single' />}
								checked={getValues('fteType') === 'single'}
								text={t('page.funnel.profile.fte.inputLabelZzp')}
								data-testid='fte-form-single-option'
							/>

							<ChoiceCard
								inputPosition='left'
								onClick={() => reset({ fteType: 'multiple', fte: undefined })}
								inputElement={<Form.Radio id='fte-multiple' register={register} name='fteType' value='multiple' />}
								checked={getValues('fteType') === 'multiple'}
								text={t('page.funnel.profile.fte.inputLabelMultiple')}
								data-testid='fte-form-multiple-option'>
								<div className='space-y-8'>
									<Form.NumberInput
										fieldSize='lg'
										startIcon='users-alt'
										register={register}
										name='fte'
										step={0.1}
										autoComplete='off'
										autoFocus={true}
										placeholder={t('page.funnel.profile.fte.inputPlaceholder')}
										data-testid='fte-form-multiple-input'
									/>
									<Text variant='body-xs' color='grayscale400'>
										<Trans
											i18nKey={'page.funnel.profile.fte.fteCalculatorHelpText'}
											components={{
												openCalc: (
													<span className='cursor-pointer underline' onClick={() => dispatch(showModal('fte-modal'))} />
												),
											}}
										/>
									</Text>
								</div>
							</ChoiceCard>
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};

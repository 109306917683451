import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company } from '@monorepo/types';

import { AppState, hydrate, kvkApi } from '@common/store';

export type CompanyState = Company;

const initialState: CompanyState = {} as Company;

export const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		setCompany(_, action: PayloadAction<Company>) {
			return action.payload;
		},
		updateCompany(state, action: PayloadAction<Partial<Company>>) {
			return {
				...state,
				...action.payload,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(hydrate, (state, action: PayloadAction<{ company: CompanyState }>) => {
			return {
				...state,
				...action.payload.company,
			};
		});
		builder.addMatcher(kvkApi.endpoints.profile.matchFulfilled, (state, action) => {
			return {
				...state,
				...action.payload,
			};
		});
	},
});

export const { setCompany, updateCompany } = companySlice.actions;

export const selectCompanyState = (state: AppState) => state.company;

import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { MenuItem } from '@monorepo/types';

import { TextButton } from '../../../DataEntry';
import { Text } from '../../../TextComponents';
import { MyMaiaSecondMenu } from '../MyMaiaSecondMenu/MyMaiaSecondMenu';

export type MyMaiaHeaderVariantBack = {
	variant: 'back';
};

export type MyMaiaHeaderVariantTitle = {
	variant: 'title';
	title: string;
};

export type MyMaiaHeaderVariantName = {
	variant: 'name';
	name: string;
};

export type MyMaiaHeaderVariantEmpty = {
	variant: 'empty';
};

type Props = {
	name: string;
	type: 'consumer' | 'company';
	goBackFn: () => void;
	isNavigating: boolean;
	navDirection: 'back' | 'forward' | null;
	secondMenuLinks: MenuItem[];
	isLoading?: boolean;
};

export type MyMaiaHeaderProps = Props &
	(MyMaiaHeaderVariantBack | MyMaiaHeaderVariantTitle | MyMaiaHeaderVariantName | MyMaiaHeaderVariantEmpty);

export const MyMaiaHeader: FC<MyMaiaHeaderProps> = ({ isLoading = true, ...props }) => {
	const { t } = useTranslation(['common']);

	return (
		<header className='w-full py-20 md:py-32'>
			<div className='flex items-center justify-between max-md:hidden'>
				<div className='flex-1'>
					{props.variant === 'title' && (
						<Text as='h1' variant='display-6' weight='semibold' color='primary400'>
							{props.title}
						</Text>
					)}

					{props.variant === 'name' && (
						<Text variant='body-m' weight='medium' color='primary400'>
							{props.name}
						</Text>
					)}

					{props.variant === 'back' && (
						<TextButton
							disabled={props.isNavigating}
							isLoading={props.isNavigating && props.navDirection === 'back'}
							iconLeft='arrow-left'
							onClick={props.goBackFn}
							label={t('common.back')}
						/>
					)}
				</div>

				{!isLoading && <MyMaiaSecondMenu type={props.type} menuLinks={props.secondMenuLinks} name={props.name} />}
			</div>
		</header>
	);
};

import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { Text } from '@monorepo/components/TextComponents';
import { getProperty } from '@monorepo/shared/lib/cms';
import { INicheOverviewCard, INicheOverviewSection } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { NicheOverview } from '@marketing/components';

const Paragraph = (props: ReactMarkdownProps) => (
	<Text as='p' variant='body-l' color='grayscale0'>
		{props.children}
	</Text>
);
const highlight = (props: ReactMarkdownProps) => (
	<Text as='span' variant='body-l' color='secondaryMain' weight='medium' {...props} />
);

const mdComponents: Components = {
	p: Paragraph,
	span: Paragraph,
	strong: highlight,
};

export const handleSectionCategorizedProducts = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const cmsProps: INicheOverviewSection = {
		moreLink: elements[0].links[0],
		items: elements.map((element) => {
			const categoryTitle = getProperty('title', element.custom_fields),
				categoryIcon = getProperty('icon', element.custom_fields),
				headerTitle = getProperty('header_title', element.custom_fields),
				headerContent = getProperty('header_content', element.custom_fields);

			const HeaderContent = () => {
				// Replace single '\n' with two '\n' to create a new paragraph
				const formattedHeaderContent = `${headerContent}`.replace(/\n/g, '\n\n');
				return <ReactMarkdown components={mdComponents}>{formattedHeaderContent}</ReactMarkdown>;
			};

			const headerCard: INicheOverviewCard = {
				icon: `${categoryIcon}`,
				title: `${headerTitle}`,
				description: <HeaderContent />,
				link: element['links'][0],
				featured: true,
			};

			const cards = element.child_elements.map((card) => {
				const title = getProperty('title', card.custom_fields),
					icon = getProperty('icon', card.custom_fields),
					subtext = getProperty('subtext', card.custom_fields);

				return {
					icon: `${icon}`,
					title: `${title}`,
					description: `${subtext}`,
					link: card['links'][0],
					featured: false,
				};
			});

			return {
				category: {
					text: `${categoryTitle}`,
					icon: `${categoryIcon}`,
				},
				cards: [headerCard, ...cards],
			};
		}),
	};

	return (props) => <NicheOverview {...props} {...cmsProps} />;
};

import * as RadixToggleGroup from '@radix-ui/react-toggle-group';
import { ComponentProps, useState } from 'react';

import { Icon } from '@monorepo/shared/icon';
import { Text } from '@monorepo/shared/typography';

import { iconVariant, item, ItemPositionVariantProps } from './variants';

type SingleToggleGroupRoot = Extract<ComponentProps<typeof RadixToggleGroup.Root>, { type: 'single' }>;

type ToggleButton = {
	label: string;
	icon?: string;
} & ComponentProps<typeof RadixToggleGroup.Item>;

type ToggleButtonsProps =
	| ({
			intent: 'yesno';
			name: string;
			items: [positive: ToggleButton, negative: ToggleButton];
	  } & SingleToggleGroupRoot)
	| ({
			intent: 'default';
			name: string;
			items: Array<ToggleButton>;
	  } & SingleToggleGroupRoot);

export function ToggleButtons(props: ToggleButtonsProps) {
	const { items, intent, name, defaultValue, onValueChange, ...rest } = props;
	const [value, setValue] = useState<string | undefined>(defaultValue);

	return (
		<RadixToggleGroup.Root
			asChild
			{...rest}
			type='single'
			value={value}
			onValueChange={(value) => {
				if (value) {
					setValue(value);
					onValueChange?.(value);
				}
			}}>
			<ul className='flex overflow-x-auto'>
				{items.map(({ label, icon, ...itemProps }, index) => {
					const position = mapPosition(index, items.length);

					return (
						<li className='shrink-0' key={itemProps.value}>
							<RadixToggleGroup.Item {...itemProps} className={item({ position, intent })} name={name}>
								{icon && <Icon name={icon} className={iconVariant({ position, intent })} aria-hidden />}
								<Text variant='body-m' color='inherit' weight='semibold'>
									{label}
								</Text>
							</RadixToggleGroup.Item>
						</li>
					);
				})}
			</ul>
		</RadixToggleGroup.Root>
	);
}

function mapPosition(index: number, total: number): NonNullable<ItemPositionVariantProps> {
	switch (index) {
		case 0:
			return 'start';
		case total - 1:
			return 'end';
		default:
			return 'middle';
	}
}

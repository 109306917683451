import cn from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';

export type HamburgerButtonProps = {
	isActive: boolean;
	toggleActive: () => void;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const HamburgerButton: FC<HamburgerButtonProps> = ({ isActive = false, toggleActive, className, ...props }) => {
	return (
		<button
			role='button'
			title='Toggle menu'
			name='toggle_menu'
			className={cn(className, 'hover:bg-grayscale100 active:bg-grayscale200 rounded-12 p-8 transition-colors')}
			onClick={toggleActive}
			{...props}>
			<div
				className={`bg-grayscale600 h-[2px] w-24 transform transition-all duration-300 ease-in-out ${
					isActive ? 'translate-y-[6px] rotate-45' : ''
				}`}></div>
			<div
				className={`bg-grayscale600 my-4 h-[2px] w-24 transition-all duration-300 ease-in-out ${
					isActive ? 'opacity-0' : ''
				}`}></div>
			<div
				className={`bg-grayscale600 h-[2px] w-24 transform transition-all duration-300 ease-in-out ${
					isActive ? 'translate-y-[-6px] -rotate-45' : ''
				}`}></div>
		</button>
	);
};

import cn from 'classnames';
import Link from 'next/link';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { IFaqCategorySection } from '@monorepo/types';

import { BlurbCard } from '../BlurbCard/BlurbCard';

export type FaqCategorySectionProps = IFaqCategorySection & {
	className?: string;
};

export const FaqCategorySection: FC<FaqCategorySectionProps> = ({ categories, className }) => {
	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 grid grid-cols-6 gap-24 md:gap-x-64'>
					{categories.map((category, key) => (
						<Link
							href={category.link.href}
							title={category.link.title}
							className='col-span-full cursor-pointer sm:col-span-3 md:col-span-2'
							key={`faq-category-${key}`}>
							<BlurbCard {...category} />
						</Link>
					))}
				</div>
			</LayoutGrid>
		</section>
	);
};

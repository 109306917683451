import { IWeAreMaiaSection } from '@monorepo/types';

export const mockWeAreMaiaSection: IWeAreMaiaSection = {
	title: 'Wij zijn Māia',
	description:
		"Jouw digitale partner in zakelijke verzekeringen. Simpel, direct en transparant, dat is waar wij voor staan. Met onze innovatieve aanpak maken we verzekeren voor ZZP'ers en MKB'ers niet alleen makkelijker, maar ook betrouwbaarder. Geen kleine lettertjes, maar duidelijkheid en snelheid in alles wat we doen. Jouw onderneming verdient immers de beste bescherming, zonder gedoe. Wat ons betreft sta jij voorop, jouw bedrijf, jouw groei, jouw zekerheid.",
	image: {
		alt: 'alt',
		path: '/images/marketing/we-are-maia.png',
	},
};

import { FieldValues } from 'react-hook-form';

import { FormBaseInput, FormBaseInputDefaultProps, FormBaseInputProps } from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormNumberInputProps<TFormValues extends FieldValues> = {
	hint?: string;
	hideError?: boolean;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<FormBaseInputProps, 'name' | 'errors' | 'type'>;

export const FormNumberInput = <TFormValues extends Record<string, unknown>>({
	name,
	register,
	rules,
	errors,
	hint,
	hideError,
	min = 0,
	...props
}: FormNumberInputProps<TFormValues>): JSX.Element => (
	<FormHelper errors={errors} hint={hint} hideError={hideError}>
		{({ isError }) => (
			<FormBaseInput
				min={min}
				type='number'
				isError={isError}
				name={name}
				{...props}
				{...(register && register(name, rules))}
			/>
		)}
	</FormHelper>
);

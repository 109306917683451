import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, type FC } from 'react';
import { useForm } from 'react-hook-form';

import { Form } from '@monorepo/components/DataEntry';
import { claimSchema, ClaimSchema } from '@monorepo/shared/lib/schemas';
import { getPrevYears, toReactSelectObject } from '@monorepo/shared/lib/utils';

import { useGetClaimableProductsQuery } from '@funnel/store';

type ClaimFormProps = {
	id: string;
	cartGuid: string;
	defaultValues?: ClaimSchema;
	onSubmit: (fields: ClaimSchema) => void;
	onChangeIsValid: (isValid: boolean) => void;
};

export const ClaimForm: FC<ClaimFormProps> = ({ id, cartGuid, defaultValues, onSubmit, onChangeIsValid }) => {
	const { t } = useTranslation(['common']);
	const YEARS_BACK = 10;

	const { data: claimableProducts = [], isLoading } = useGetClaimableProductsQuery(cartGuid);

	const selectYears = toReactSelectObject(getPrevYears(YEARS_BACK));
	const selectInsurances = useMemo(() => toReactSelectObject(claimableProducts), [claimableProducts]);

	const hasOneProduct = claimableProducts?.length === 1;

	const claimForm = useForm<ClaimSchema>({
		mode: 'onChange',
		resolver: zodResolver(claimSchema(t, YEARS_BACK)),
		defaultValues,
	});

	useEffect(() => {
		if (onChangeIsValid) {
			onChangeIsValid(claimForm.formState.isValid);
		}
	}, [claimForm.formState.isValid, onChangeIsValid]);

	useEffect(() => {
		if (hasOneProduct) {
			claimForm.setValue('product_guid', `${claimableProducts[0].guid}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasOneProduct]);

	return (
		<Form id={id} className='grid grid-cols-2 gap-x-6 gap-y-32' onSubmit={claimForm.handleSubmit(onSubmit)}>
			{defaultValues?.guid && (
				<Form.Group className='hidden'>
					<Form.HiddenInput name='guid' register={claimForm.register} />
				</Form.Group>
			)}

			<Form.Group className='col-span-2'>
				<Form.Label htmlFor='product_guid'>
					{t('page.funnel.preconditions.claimsOverview.form.insuranceLabel')}
				</Form.Label>
				<Form.Dropdown
					fieldSize='lg'
					control={claimForm.control}
					isLoading={isLoading}
					name='product_guid'
					disabled={hasOneProduct || isLoading}
					options={selectInsurances}
					errors={claimForm.formState.errors.product_guid}
					className='z-40'
					placeholder={t('page.funnel.preconditions.claimsOverview.form.insurancePlaceholder')}
				/>
			</Form.Group>
			<Form.Group className='col-span-2'>
				<Form.Label htmlFor='year'>
					{t('page.funnel.preconditions.claimsOverview.form.yearOfOccurrenceLabel')}
				</Form.Label>
				<Form.Dropdown
					fieldSize='lg'
					control={claimForm.control}
					name='year'
					options={selectYears}
					errors={claimForm.formState.errors.year}
					placeholder={t('page.funnel.preconditions.claimsOverview.form.yearOfOccurrencePlaceholder')}
				/>
			</Form.Group>

			<Form.Group className='col-span-2'>
				<Form.Label htmlFor='amount'>{t('page.funnel.preconditions.claimsOverview.form.paidOutLabel')}</Form.Label>
				<Form.ValutaInput
					fieldSize='lg'
					decimalScale={0}
					control={claimForm.control}
					name='amount'
					className='w-full'
					errors={claimForm.formState.errors.amount}
					placeholder={t('page.funnel.preconditions.claimsOverview.form.paidOutPlaceholder')}
				/>
			</Form.Group>

			<Form.Group className='col-span-2'>
				<Form.Label htmlFor='explanation'>
					{t('page.funnel.preconditions.claimsOverview.form.claimDescriptionLabel')}
				</Form.Label>
				<Form.Textarea
					fieldSize='lg'
					name='explanation'
					placeholder={t('page.funnel.preconditions.claimsOverview.form.claimDescriptionPlaceholder')}
					errors={claimForm.formState.errors.explanation}
					register={claimForm.register}
					maxLength={200}
				/>
			</Form.Group>
		</Form>
	);
};

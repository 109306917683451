import { PortalDocumentWithGuid, PortalInvoice } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

const invoiceApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getInvoices: builder.query<PortalInvoice[], void>({
			query() {
				return {
					url: `/invoices`,
					method: 'GET',
				};
			},
		}),
		downloadInvoices: builder.mutation<{ invoices_documents: PortalDocumentWithGuid[] }, { invoice_guids: string[] }>({
			query({ invoice_guids }) {
				return {
					url: `/invoices`,
					method: 'POST',
					body: { invoice_guids },
				};
			},
			invalidatesTags: ['Invoice'],
		}),
	}),
	overrideExisting: true,
});

export const { useDownloadInvoicesMutation, useGetInvoicesQuery, useLazyGetInvoicesQuery } = invoiceApi;

export const { downloadInvoices, getInvoices } = invoiceApi.endpoints;

import type { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { Logo } from '@monorepo/components/Misc';
import { useIsMobile } from '@monorepo/shared/lib/hooks';

import { Transition } from '@common/layouts';
import { selectTheme } from '@common/store';
import { paths } from '@common/utils';

export const ErrorLayout: FC<PropsWithChildren> = ({ children }) => {
	const { isSmallTablet } = useIsMobile();
	const theme = useSelector(selectTheme);

	return (
		<div className='min-h-screen-ios flex min-h-screen flex-row'>
			<div className='p-0-safe m-0 flex grow flex-col'>
				<nav className='mx-4 mb-8 mt-8 flex items-center justify-center md:justify-end'>
					<div className='relative h-[48px] w-full max-w-[286px] md:right-32 md:ml-auto md:h-[64px]'>
						<Logo
							landingHref={paths.funnel.root}
							align={isSmallTablet ? 'center' : 'right'}
							direction={isSmallTablet ? 'ltr' : 'rtl'}
							theme={theme}
						/>
					</div>
				</nav>
				<Transition className='mx-auto grid w-full grow grid-cols-4 gap-x-4 p-6 sm:grid-cols-8 sm:gap-x-8 sm:px-10 sm:py-10 md:max-w-[840px]'>
					<div className='relative col-span-4 flex flex-col sm:col-span-8 lg:col-span-11'>{children}</div>
				</Transition>
			</div>
		</div>
	);
};

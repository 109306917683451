import cn from 'classnames';
import { FC, ReactElement } from 'react';

import { Text } from '../Text/Text';

type Props = {
	title: string | ReactElement;
	text?: string | ReactElement;
	className?: string;
};
export const TitleAndText: FC<Props> = ({ title, text, className = '' }) => {
	return (
		<div className={cn(className, 'flex flex-col space-y-4')}>
			<Text as='h2' variant='display-6' className='hyphens-auto' color='grayscale600' weight='medium'>
				{title}
			</Text>
			{text && (
				<Text as='p' variant='body-m' color='grayscale500'>
					{text}
				</Text>
			)}
		</div>
	);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { getSidecodeLicenseplate } from './../../utils';

export const licensePlateSchema = (t: TranslationType) =>
	z.object({
		license_plate: z
			.string({
				required_error: t('validation.required'),
			})
			.refine((value) => getSidecodeLicenseplate(value) !== -1, {
				message: t('validation.licensePlate'),
			}),
	});

export type LicensePlateSchema = z.infer<ReturnType<typeof licensePlateSchema>>;

import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, useTranslation } from 'next-i18next';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { StaticNotification } from '@monorepo/components/DataDisplay';
import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { AddressSchema, emailSchema, EmailSchema } from '@monorepo/shared/lib/schemas';

import { ModalActions } from '@common/components';
import { useUpdateCmsUserMutation, useUpdateFirebaseEmailMutation } from '@superadmin/store';

type Props = {
	cmsUserGuid: string;
	firebaseUid: string;
	currentEmail: string;
	onCancel: () => void;
};

export const UpdateEmailForm: FC<Props> = ({ cmsUserGuid, firebaseUid, currentEmail, onCancel }) => {
	const { t } = useTranslation(['common']);
	const [updateFirebaseEmail, response] = useUpdateFirebaseEmailMutation();
	const [updateCmsUser, cmsUserResponse] = useUpdateCmsUserMutation();

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isValid },
	} = useForm<EmailSchema>({
		mode: 'onChange',
		resolver: zodResolver(emailSchema(t)),
	});

	const newEmail = watch('email');
	const emailMatches = useMemo(() => newEmail === currentEmail, [newEmail, currentEmail]);
	const responseLoading = response.isLoading || cmsUserResponse.isLoading;
	const responseSuccess = response.isSuccess && cmsUserResponse.isSuccess;
	const responseError = response.isError || cmsUserResponse.isError;

	const notificationStatus = responseLoading
		? 'info'
		: responseError
			? 'error'
			: responseSuccess
				? 'success'
				: emailMatches
					? 'error'
					: isValid
						? 'success'
						: 'warning';

	const onSubmit = async ({ email }: EmailSchema) => {
		await Promise.all([updateFirebaseEmail({ firebaseUid, email }), updateCmsUser({ guid: cmsUserGuid, email })]).then(
			onCancel
		);
	};

	return (
		<Form.Context<AddressSchema>>
			{({}) => {
				return (
					<Form id='update-email-form' onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className='space-y-32'>
							<div className='grid grid-cols-4 gap-x-8 gap-y-12'>
								<Form.Group className='col-span-full'>
									<Form.Label>{t('common.email')}</Form.Label>
									<Form.EmailInput
										autoComplete='off'
										name='email'
										placeholder={t('common.email')}
										errors={errors.email}
										register={register}
									/>
								</Form.Group>

								<StaticNotification status={notificationStatus} className='col-span-full'>
									<Text as='p' variant='body-m'>
										{responseLoading ? (
											t('forms.updateEmail.isLoading')
										) : responseError ? (
											t('forms.updateEmail.isError')
										) : responseSuccess ? (
											t('forms.updateEmail.isSuccess')
										) : notificationStatus !== 'error' ? (
											<Trans
												i18nKey='forms.updateEmail.notification'
												tOptions={{ currentEmail, newEmail: newEmail || '...' }}
												components={{
													bold: <Text as='span' variant='body-m' color='info300' weight='semibold' />,
												}}
											/>
										) : (
											<>{t('forms.updateEmail.errorNotification')}</>
										)}
									</Text>
								</StaticNotification>
							</div>

							<ModalActions
								cancelButton={
									<StandardButton type='button' onClick={onCancel} variant='secondary' label={t('common.cancel')} />
								}
								submitButton={
									<StandardButton
										type='submit'
										iconRight='check'
										variant='primary'
										label={t('common.save')}
										isLoading={responseLoading}
										disabled={!isValid || responseLoading || emailMatches}
									/>
								}></ModalActions>
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};

import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { slugify } from '@monorepo/shared/lib/utils';
import { ILogoList } from '@monorepo/types';

import { SingleLogo } from '@marketing/components/LogoListSection/SingleLogo/SingleLogo';

type LogoListSectionProps = ILogoList & {
	className?: string;
};

export const LogoListSection: FC<LogoListSectionProps> = ({ title, logos, className }) => {
	return (
		<section className={cn(className, 'py-48')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-32'>
					<Text as='h2' variant='body-l' color='grayscale200' className='text-center'>
						{title}
					</Text>
					<ul role='list' className='gap flex flex-row flex-wrap justify-between gap-y-24'>
						{logos.map((props, key) => (
							<SingleLogo className='basis-1/3 md:basis-auto' key={`logo-list-${slugify(`${key}`)}`} {...props} />
						))}
					</ul>
				</div>
			</LayoutGrid>
		</section>
	);
};

import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '@monorepo/components/TextComponents';

import { openSupportOptions, selectOptionsIsOpen, selectUnreadMessages } from '@support/store';

export const ChatIcon: FC = () => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();
	const isOpen = useSelector(selectOptionsIsOpen);
	const unreadMessages = useSelector(selectUnreadMessages);
	const hasUnreadMessages = unreadMessages > 0;

	const handleClick = () => {
		dispatch(openSupportOptions());
	};

	return (
		<button
			onClick={handleClick}
			title={t('components.chat.openChatTitle')}
			name='open_chat'
			className={cn(
				isOpen
					? 'bg-success300 hover:enabled:bg-success400 active:enabled:bg-success500'
					: 'bg-primaryMain hover:enabled:bg-primary600 active:enabled:bg-primary600',
				'group relative flex h-[60px] w-[60px] items-center justify-center rounded-full transition-colors duration-300'
			)}>
			<Image
				src='/images/support/chat.svg'
				alt={t('components.chat.iconAlt')}
				height={30}
				width={30}
				className={cn(isOpen && 'scale-110', 'delay-150 duration-300 group-hover:scale-110 group-active:scale-110')}
			/>

			{hasUnreadMessages && (
				<div
					data-testid='chat-icon-unread'
					className='bg-error300 absolute right-0 top-0 flex h-20 w-20 items-center justify-center rounded-full'>
					<Text as='span' variant='body-s' color='grayscale0'>
						{unreadMessages}
					</Text>
					<span className='bg-error300 absolute inline-flex h-full w-full animate-ping rounded-full opacity-75'></span>
				</div>
			)}
		</button>
	);
};

import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IHeroAlignedLeft } from '@monorepo/types';

export type HeroAlignedLeftProps = IHeroAlignedLeft & {
	className?: string;
};

export const HeroAlignedLeft: FC<HeroAlignedLeftProps> = ({ title, subtitle, className }) => {
	return (
		<section className={cn('bg-primary50', className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-8 py-32 md:py-64'>
					<Text as='h1' variant='display-3' weight='semibold' color='primaryMain'>
						{title}
					</Text>
					<Text as='p' variant='body-l' weight='regular' color='grayscale400'>
						{subtitle}
					</Text>
				</div>
			</LayoutGrid>
		</section>
	);
};

import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import { Text } from '../../../TextComponents';

type FormHelperProps = {
	errors?: FieldError;
	hint?: string;
	hideError?: boolean;
	hideHint?: boolean;
	characterCount?: number;
	maxCharacterCountLength?: number;
	children: (({ isError }: { isError: boolean }) => ReactNode) | ReactNode;
};

export const FormHelper: FC<FormHelperProps> = ({
	errors,
	hint,
	hideError,
	children,
	hideHint,
	characterCount,
	maxCharacterCountLength,
	...rest
}) => {
	const errorMessage = errors && !hideError ? (errors.message as string) : false;
	const includesMaxCharacterCount = typeof maxCharacterCountLength === 'number';

	return (
		<div className='flex w-full flex-col' {...rest}>
			{typeof children === 'function' ? children({ isError: errorMessage !== false }) : children}
			{(errorMessage || (hint && !hideHint) || includesMaxCharacterCount) && (
				<div className={classNames('pt-8', includesMaxCharacterCount && 'flex items-center justify-between space-x-4')}>
					{(hint || errorMessage) && (
						<Text
							variant='body-xs'
							weight='regular'
							color={errorMessage ? 'error500' : 'grayscale400'}
							className='transition-colors duration-200'>
							{errorMessage ? errorMessage : hint}
						</Text>
					)}

					{includesMaxCharacterCount && (
						<Text variant='body-xs' color={errorMessage ? 'error500' : 'grayscale400'} className='ml-auto text-right'>
							{`${characterCount}/${maxCharacterCountLength}`}
						</Text>
					)}
				</div>
			)}
		</div>
	);
};

import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, IUsp } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { UspSection } from '@marketing/components';

export const handleSectionUspCards = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextEle: CmsElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement,
		title = getProperty('title', titleSubtextEle.custom_fields),
		subtext = getProperty('subtext', titleSubtextEle.custom_fields),
		uspCardsEle: CmsElement[] = elements.filter(({ identifier }) => identifier === 'usp-card') as CmsElement[];

	const usps: IUsp[] = uspCardsEle.map((usp) => ({
		title: `${getProperty('title', usp.custom_fields)}`,
		description: `${getProperty('subtext', usp.custom_fields)}`,
		icon: `${getProperty('icon_name', usp.custom_fields)}`,
	}));

	return (props) => (
		<UspSection {...props} className='pb-64 pt-48' title={`${title}`} subtitle={`${subtext}`} usps={usps} />
	);
};

import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { addOrRemoveFromArray } from './utils';

export function useFormUtils() {
	const { setValue, getValues } = useFormContext();
	const onChangeSetArrayValue = useCallback(
		(name: string, value: string) => {
			setValue(name, addOrRemoveFromArray(getValues(name), value));
		},
		[getValues, setValue]
	);

	const onChangeSetSingleValue = useCallback(
		(name: string, value: string) => {
			setValue(name, value);
		},
		[setValue]
	);

	return {
		onChangeSetArrayValue,
		onChangeSetSingleValue,
	};
}

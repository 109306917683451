import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';
import { CreateSubjectResponse } from '@monorepo/types';

export const openAiApi = createApi({
	reducerPath: 'openAiApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/openai`,
	}),
	tagTypes: ['OpenAi'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		subject: builder.query<CreateSubjectResponse, { claimDescription: string }>({
			query(payload) {
				return {
					url: `/create-subject`,
					method: 'POST',
					body: payload,
				};
			},
			transformResponse: ({ answer }) => {
				return {
					answer: answer.replaceAll('\n', '').replaceAll('.\n', ''),
				};
			},
			transformErrorResponse: (error: FetchBaseQueryError) => {
				/* istanbul ignore next */
				return {
					status: error.status,
					data: error.data,
				};
			},
		}),
	}),
});

export const { useSubjectQuery } = openAiApi;

export const { subject } = openAiApi.endpoints;

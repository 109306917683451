import classNames from 'classnames';
import { FC } from 'react';

import { ThemeTypography, typography } from '@monorepo/types';

export type TextSkeletonProps = {
	size: ThemeTypography;
	className?: string;
};

const typographyMap = {} as Record<ThemeTypography, string>;

typography.forEach((typography) => {
	typographyMap[typography] = `typography-${typography}`;
});

export const TextSkeleton: FC<TextSkeletonProps> = ({ size, className }) => (
	<div role='status' className={classNames('rounded-6 animate-pulse select-none', typographyMap[size], className)}>
		<span className='invisible select-none'>&nbsp;</span>
		<span className='sr-only'>Loading...</span>
	</div>
);

import { z } from 'zod';

import { TranslationType, yesNo } from '@monorepo/types';

export const agMembershipSchema = (t: TranslationType) =>
	z.object({
		membership: z.enum(yesNo, { required_error: t('validation.required') }),
	});

export type AgMembershipSchema = z.infer<ReturnType<typeof agMembershipSchema>>;

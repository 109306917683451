import {
	createElement,
	ElementType,
	Fragment,
	JSXElementConstructor,
	MutableRefObject,
	ReactElement,
	ReactNode,
	useEffect,
	useMemo,
	useReducer,
} from 'react';

import { TabMenu } from './Menu/Tab.Menu';
import { TabMenuItem } from './MenuItem/Tab.MenuItem';
import { TabPanel } from './Panel/Tab.Panel';
import { TabPanels } from './Panels/Tab.Panels';
import {
	TabActionTypes,
	tabReducer,
	TabsActionData,
	TabsActionsContext,
	TabsContext,
	TabsContextData,
} from './Tab.store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReactTag = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

export type TabProps<Tag extends ReactTag, Slot> = {
	as?: Tag;
	children?: ReactNode | ((slot: Slot) => ReactElement);
} & (Tag extends React.ElementType ? Omit<React.ComponentProps<Tag>, 'ref' | 'children' | 'onChange'> : never);

const DEFAULT_TAB_TAG = Fragment;

type TabRootProps<Tag extends ReactTag, Slot> = {
	defaultIndex?: number;
	selectedIndex?: number;
	vertical?: boolean;
	onChange?: (index: number) => void;
} & TabProps<Tag, Slot>;

const TabRoot = <Tag extends ElementType = typeof DEFAULT_TAB_TAG>(
	props: TabRootProps<Tag, { selectedIndex: number }>
) => {
	const { defaultIndex = 0, as: ComponentFromProps, selectedIndex = null, vertical, onChange, ...rest } = props;

	const orientation = vertical ? 'vertical' : 'horizontal';
	const isControlled = selectedIndex !== null;

	const [state, dispatch] = useReducer(tabReducer, {
		previousIndex: 0,
		selectedIndex: selectedIndex ?? defaultIndex,
		tabs: [],
		panels: [],
	});

	const slot = useMemo(() => ({ selectedIndex: state.selectedIndex }), [state.selectedIndex]);
	const tabsData = useMemo<TabsContextData>(() => ({ orientation, ...state }), [orientation, state]);

	const registerTab = (tab: MutableRefObject<HTMLElement | null>) => {
		dispatch({ type: TabActionTypes.RegisterTab, tab });
	};

	const registerPanel = (panel: MutableRefObject<HTMLElement | null>) => {
		dispatch({ type: TabActionTypes.RegisterPanel, panel });
	};

	const unregisterTab = (tab: MutableRefObject<HTMLElement | null>) => {
		dispatch({ type: TabActionTypes.UnregisterTab, tab });
	};

	const unregisterPanel = (panel: MutableRefObject<HTMLElement | null>) => {
		dispatch({ type: TabActionTypes.UnregisterPanel, panel });
	};

	const change = (index: number) => {
		if (typeof onChange !== 'undefined') {
			onChange(index);
		}

		if (!isControlled) {
			dispatch({ type: TabActionTypes.SetSelectedIndex, index });
		}
	};

	const tabsActions = useMemo<TabsActionData>(
		() => ({ registerTab, registerPanel, change, unregisterTab, unregisterPanel }),
		[]
	);

	const Component = ComponentFromProps || DEFAULT_TAB_TAG;
	const resolvedChildren = (typeof props.children === 'function' ? props.children(slot) : props.children) as
		| ReactElement
		| ReactElement[];

	useEffect(() => {
		if (typeof selectedIndex === 'number') {
			dispatch({ type: TabActionTypes.SetSelectedIndex, index: selectedIndex });
		}
	}, [selectedIndex]);

	return (
		<TabsActionsContext.Provider value={tabsActions}>
			<TabsContext.Provider value={tabsData}>
				{createElement(Component, Object.assign({ ...rest }), resolvedChildren)}
			</TabsContext.Provider>
		</TabsActionsContext.Provider>
	);
};

export const Tab = Object.assign(TabRoot, { Menu: TabMenu, MenuItem: TabMenuItem, Panel: TabPanel, Panels: TabPanels });

import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { INicheCollaborateSection } from '@monorepo/types';

import { NicheCard } from '../NicheCard/NicheCard';

type NicheCollaborateSectionProps = INicheCollaborateSection & {
	className?: string;
};

export const NicheCollaborateSection: FC<NicheCollaborateSectionProps> = ({ title, subtitle, niches, className }) => {
	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-48'>
					<div className='flex flex-col space-y-12'>
						<Text variant='display-4' weight='semibold' color='primaryMain'>
							{title}
						</Text>
						<Text variant='display-6' weight='regular' color='primaryMain'>
							{subtitle}
						</Text>
					</div>
					<div className='grid grid-cols-1 gap-48 align-top md:grid-cols-2'>
						{niches.map((niche, key) => (
							<NicheCard {...niche} className='col-span-full md:col-span-1' key={`niche-card-${key}`} />
						))}
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { SearchBox } from '@common/components';

export const handleSectionSearchbox = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const [element] = elements;
	const title = getProperty('title', element.custom_fields);
	const link = element?.links?.[0] || undefined;

	return (props) => (
		<SearchBox
			className='z-20 mt-40 w-full md:w-screen md:max-w-[60vw] lg:max-w-[70vw] xl:max-w-[55vw] 2xl:max-w-[45vw]'
			{...props}
			label={title}
			link={link}
		/>
	);
};

import { faker } from '@faker-js/faker/locale/nl';

import { Organization } from '@monorepo/types';

import { mockGetAddress } from './address.mock';
import { mockGetContact } from './contact.mock';
import { mockGetOrganizationClassifications } from './organization-classification.mock';

export function mockGetOrganization(): Organization {
	return {
		guid: faker.string.alphanumeric(16),
		dossier_number: faker.string.alphanumeric(8),
		name: faker.company.name(),
		legal_form: faker.company.name(),
		primary_address: mockGetAddress(),
		contact: mockGetContact(),
		iban: null,
		classifications: mockGetOrganizationClassifications(3),
		fte: 1.5,
		employees: 2,
		revenue: 0,
	};
}

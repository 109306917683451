import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import { generateSizesString } from '@monorepo/shared/lib/utils';
import { ILogo } from '@monorepo/types';

type SingleLogoProps = ILogo & {
	className?: string;
};

export const SingleLogo: FC<SingleLogoProps> = ({ link, alt, src, className }) => {
	return (
		<li role='listitem' className={cn(className, link && 'group', 'relative h-48 w-[112px]')}>
			{link ? (
				<Link target={link.target} href={link.href} title={link.title} className='group'>
					<Image
						sizes={generateSizesString()}
						className='grayscale transition-all group-hover:grayscale-0 group-active:grayscale-0'
						fill
						src={src}
						alt={alt}
					/>
				</Link>
			) : (
				<Image
					sizes={generateSizesString()}
					className='grayscale transition-all group-hover:grayscale-0 group-active:grayscale-0'
					fill
					src={src}
					alt={alt}
				/>
			)}
		</li>
	);
};

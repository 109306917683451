import classNames from 'classnames';
import { FC } from 'react';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { TestimonialSingleSliderSection } from '@marketing/components';

export const handleSectionTrustPilotSmall = <P extends object>({ rootContext }: HandlerPropsV2): FC<P> => {
	return () => (
		<TestimonialSingleSliderSection
			className={classNames(
				'md:py-104 py-48',
				(rootContext?.path?.includes('werkvelden') || rootContext?.path?.includes('niches')) && 'bg-primary50'
			)}
		/>
	);
};

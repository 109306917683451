import cn from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { Text } from '../Text/Text';

export type CaseBlockProps = PropsWithChildren<{
	title: string | ReactNode;
	size?: 'sm' | 'md'; // small is used in portal, medium is uded in funnel
	className?: string;
}>;

export const CaseBlock: FC<CaseBlockProps> = ({ title, size = 'md', children, className = '' }) => {
	return (
		<div
			className={cn(
				className,
				size === 'sm' && 'p-16 md:p-20',
				size === 'md' && 'p-20 md:p-24',
				'bg-grayscale50 rounded-6 md:rounded-12 space-y-8'
			)}>
			<Text
				as={typeof children === 'string' ? 'p' : 'div'}
				variant={size === 'sm' ? 'body-s' : 'body-m'}
				weight='medium'
				color='primaryMain'>
				{title}
			</Text>

			<Text as={typeof children === 'string' ? 'p' : 'div'} variant='body-s' color='grayscale500'>
				{children}
			</Text>
		</div>
	);
};

import cn from 'classnames';
import { FC } from 'react';

import { ProgressStep } from './Step/ProgressStep';

export type ProgressState = 'inactive' | 'active' | 'completed';

type Props = {
	steps: string[];
	activeStep: number;
};

export const ProgressBar: FC<Props> = ({ steps, activeStep }) => {
	return (
		<ul className='flex w-full flex-col items-start md:items-end'>
			{steps.map((step, index, all) => {
				const currentStep = index + 1;
				const isLastStep = index + 1 === all.length;

				let state: ProgressState = 'inactive';

				if (activeStep === currentStep) {
					state = 'active';
				} else if (activeStep >= currentStep) {
					state = 'completed';
				}

				return (
					<ProgressStep
						className={cn(
							'pb-12 last:pb-0 sm:pb-24 lg:pb-40',
							state === 'completed' ? 'after:border-secondaryMain' : 'after:border-grayscale200',
							!isLastStep &&
								'after:absolute after:left-[3px] after:top-[10px] after:z-0 after:h-full after:border-r-2 md:after:left-0 md:after:right-[3px] lg:after:right-[11px]',
							'relative transition-all after:transition-all after:ease-in-out'
						)}
						key={`progressbar-step-${index}`}
						label={step}
						state={state}
					/>
				);
			})}
		</ul>
	);
};

import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import Image from 'next/image';
import { FC, ReactNode } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';

import { CtaSearchForm } from '@marketing/components/CtaSearchFormSection/CtaSearchForm/CtaSearchForm';

type HeroProps = {
	title: string | ReactNode;
	image: {
		src: string;
		alt: string;
	};
};

const containerTransition = {
	hidden: {},
	visible: {
		transition: {
			delayChildren: 0.3,
			staggerChildren: 0.35,
		},
	},
};

const appearTransition = {
	hidden: {
		opacity: 0,
		y: 20,
	},
	visible: {
		opacity: 1,
		y: 0,
	},
};

export const Hero: FC<HeroProps> = ({ title, image }) => {
	return (
		<header className='bg-primary50 relative max-md:overflow-hidden'>
			<div className='md:py-104 relative z-10 overflow-hidden pb-24 pt-48'>
				<LayoutGrid as='div' layout='marketing'>
					<div className='col-start-2 flex w-full flex-col md:flex-row md:gap-x-56 lg:gap-x-80'>
						<motion.div
							variants={containerTransition}
							initial='hidden'
							animate='visible'
							className='flex w-full flex-col md:max-w-xl'>
							<Text
								as={motion.h1}
								variants={appearTransition}
								variant='custom'
								weight='bold'
								color='primaryMain'
								className='text-display-3-desktop leading-display-3-desktop md:text-display-2-desktop md:leading-display-2-desktop [&>span]:text-info300 max-md:text-center'>
								{typeof title === 'string' ? parse(title) : title}
							</Text>
							<motion.div
								variants={appearTransition}
								className='bg-grayscale0 rounded-18 shadow-1 mt-24 flex w-full px-12 py-12 max-md:mx-auto max-md:mb-16 sm:justify-center md:justify-start md:px-32 md:py-24'>
								<CtaSearchForm id='hero-search-form' />
							</motion.div>
						</motion.div>
						<div className='flex w-full md:justify-end'>
							<div className='relative h-full min-h-[300px] w-full md:scale-110 lg:scale-125'>
								<Image
									sizes={generateSizesString()}
									src={image.src}
									alt={image.alt}
									fill
									className='object-contain object-center'
								/>
							</div>
						</div>
					</div>
				</LayoutGrid>
			</div>
		</header>
	);
};

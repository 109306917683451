import cn from 'classnames';
import Link from 'next/link';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IContactSection } from '@monorepo/types';

import { ContactHighlightCard } from '@marketing/components';
import { BlurbCard } from '../BlurbCard/BlurbCard';

export type ContactSectionProps = IContactSection & {
	className?: string;
};

export const ContactSection: FC<ContactSectionProps> = ({ title, subtitle, chat, email, phone, className }) => {
	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-48'>
					<div className='flex flex-col space-y-12'>
						<Text variant='display-4' weight='semibold' color='primaryMain'>
							{title}
						</Text>
						<Text variant='display-6' weight='regular' color='primaryMain'>
							{subtitle}
						</Text>
					</div>
					<div className='grid grid-cols-2 gap-24 md:gap-48'>
						<div className='col-span-full'>
							<ContactHighlightCard
								icon={chat.icon}
								title={chat.title}
								description={chat.description}
								link={chat.link}
							/>
						</div>
						<Link href={`mailto:${email.email}`} title={`Mail ${email.email}`} className='col-span-full md:col-span-1'>
							<BlurbCard
								icon={email.icon}
								title={email.title}
								description={email.description}
								action={
									<Text as='span' variant='body-xs' weight='semibold' color='info300' className='w-fit underline'>
										{email.email}
									</Text>
								}
							/>
						</Link>
						<Link href={`tel:${phone.phone}`} title={`Bel ${phone.phone}`} className='col-span-full md:col-span-1'>
							<BlurbCard
								icon={phone.icon}
								title={phone.title}
								description={phone.description}
								action={
									<Text as='span' variant='body-xs' weight='semibold' color='info300' className='w-fit underline'>
										{phone.phone}
									</Text>
								}
							/>
						</Link>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

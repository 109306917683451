import cn from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { ThemeNames } from '@monorepo/types';

import { generateSizesString } from '../../../../../lib/utils';
import { Text } from '../../../../TextComponents';
import { Testimonial, TestimonialProps } from '../Testimonial/Testimonial';

type SectionTestimonialProps = (
	| { variant: 'image'; testimonialImageSrcs: string[] }
	| { variant: 'rating'; testimonials: TestimonialProps[]; title: string }
) & {
	className?: string;
	theme: ThemeNames;
};

export const SectionTestimonial: FC<SectionTestimonialProps> = (props) => {
	return (
		<section
			className={cn(
				props.className,
				'col-span-full mx-auto my-24 w-full max-w-[750px] sm:my-48',
				props.variant === 'rating' && 'space-y-16 sm:space-y-48',
				props.variant === 'image' && 'flex flex-col items-center justify-center sm:flex-row sm:space-x-32'
			)}>
			{props.variant === 'rating' && (
				<Text as='h2' variant='display-5' weight='medium' color='grayscale600' className='text-center'>
					{props.title}
				</Text>
			)}

			{props.variant === 'rating' && (
				<div className='space-y-16 sm:space-y-24'>
					{props.testimonials.map((testimonial, key) => (
						<Testimonial key={`testimonial-${key}`} {...testimonial} />
					))}
				</div>
			)}

			{props.variant === 'image' &&
				props.testimonialImageSrcs.map((testimonial, index) => (
					<div key={`landing-testimonial-${index}`} className='relative h-[250px] w-full sm:w-[350px]'>
						<Image
							sizes={generateSizesString()}
							src={`/images/landing/${props.theme !== 'onderhoudnl' ? 'makelaars' : props.theme}/${testimonial}.png`}
							fill
							className='object-contain object-center'
							alt={`Testimonial ${index}`}
						/>
					</div>
				))}
		</section>
	);
};

import { FC, Fragment, ReactNode } from 'react';

import { MenuItem, ThemeNames } from '@monorepo/types';

import { useIsMobile } from '../../../../lib/hooks';
import { slugify } from '../../../../lib/utils';
import { Divider, Icon, Logo } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { MyMaiaMenuItem } from '../MyMaiaMenuItem/MyMaiaMenuItem';

type MyMaiaMenuProps = {
	adminMenuItems?: MenuItem[];
	theme: ThemeNames;
	menuItemGroup: MenuItem[][];
	phoneLink: string;
	dashboardHref: string;
	notifications?: Record<string, number>;
	chat?: ReactNode;
};

export const MyMaiaMenu: FC<MyMaiaMenuProps> = ({
	dashboardHref,
	adminMenuItems = [],
	menuItemGroup = [],
	theme,
	chat,
	notifications,
}) => {
	const { isLargeDisplay } = useIsMobile();

	return (
		<nav className='border-r-grayscale100 flex w-full flex-col border-r max-sm:hidden sm:h-full 2xl:relative'>
			<div className='flex h-full flex-col md:sticky md:top-0 md:h-screen'>
				<div className='mt-24 flex-1 space-y-32 overflow-y-auto pb-32'>
					{/* Logo */}
					<div className='relative mb-20 h-full max-h-40 w-full max-w-[90%] flex-1 md:max-h-48'>
						<Logo
							landingHref={dashboardHref}
							align={isLargeDisplay ? 'right' : 'left'}
							direction={isLargeDisplay ? 'rtl' : 'ltr'}
							theme={theme}
						/>
					</div>

					{/* Navigation */}
					<div className='w-full flex-1 space-y-3'>
						{menuItemGroup.map((menuItems, key) => (
							<Fragment key={`menu-item-group-${key}`}>
								<ul className='space-y-12'>
									{menuItems.map((menuItem) => (
										<li key={`menu-item-group-${key}-menu-item-${menuItem.pathname}`}>
											<MyMaiaMenuItem
												to={menuItem.pathname}
												text={menuItem.title}
												icon={menuItem.icon}
												notificationCount={
													notifications && Object.keys(notifications).includes(menuItem.pathname)
														? notifications[menuItem.pathname]
														: undefined
												}
											/>
										</li>
									))}
								</ul>
								{key < menuItemGroup.length - 1 && <Divider className='my-20 mr-32' />}
							</Fragment>
						))}
					</div>

					{adminMenuItems && adminMenuItems.length > 0 && (
						<>
							<Divider className='my-20 mr-32' />
							<ul className='space-y-12'>
								<li className='flex w-full flex-row items-center py-4 2xl:relative 2xl:overflow-hidden 2xl:pr-32'>
									<div className='flex w-full items-center space-x-12 2xl:flex-row-reverse 2xl:space-x-reverse'>
										<Icon name='user-md' size='lg' color='grayscale600' />
										<Text weight='medium' variant='body-m'>
											Super Admin
										</Text>
									</div>
								</li>
								{adminMenuItems.map(({ title, pathname, icon }, index) => (
									<li key={`superadmin-menu-item-${slugify(pathname)}-${index}`}>
										<MyMaiaMenuItem to={pathname} text={title} icon={icon} />
									</li>
								))}
							</ul>
						</>
					)}
				</div>
				{/* Widgets */}
				{chat && <div className='pb-32-safe w-full space-y-12 pr-32'>{chat}</div>}
			</div>
		</nav>
	);
};

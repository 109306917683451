import { createElement, ElementType, ReactElement, useMemo, useRef } from 'react';

import { ReactTag, TabProps } from '../Tab';
import { useData } from '../Tab.store';

const DEFAULT_TAB_PANELS_TAG = 'div' as const;

type TabPanelsProps<Tag extends ReactTag, Slot> = TabProps<Tag, Slot>;

export const TabPanels = <PanelsElement extends ElementType = typeof DEFAULT_TAB_PANELS_TAG>(
	props: TabPanelsProps<PanelsElement, { selectedIndex: number }>
) => {
	const { as: ComponentFromProps, ...rest } = props;

	const { selectedIndex } = useData('Tab.Panels');
	const panelsRef = useRef<HTMLElement | null>(null);

	const slot = useMemo(() => ({ selectedIndex }), [selectedIndex]);

	const Component = ComponentFromProps || DEFAULT_TAB_PANELS_TAG;

	const resolvedChildren = (typeof props.children === 'function' ? props.children(slot) : props.children) as
		| ReactElement
		| ReactElement[];

	return createElement(Component, { ...rest, ref: panelsRef }, resolvedChildren);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types/src/common/translation.type';

export const addressSchema = (t: TranslationType) =>
	z.object({
		street: z.string().min(1, t('validation.required')),
		house_number: z.coerce.number({ required_error: t('validation.required') }).min(1, t('validation.required')),
		house_number_appendix: z.string().optional(),
		postal_code: z.string().min(1, t('validation.required')),
		city: z.string().min(1, t('validation.required')),
		country: z.string().optional(),
		guid: z.string().optional(),
	});

export type AddressSchema = z.infer<ReturnType<typeof addressSchema>>;

import { FC } from 'react';

import { BlogSectionUspHighlight } from '@monorepo/components/Misc';
import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleArticleSiteSectionUspHighlight = <P extends object>({ component }: HandlerProps): FC<P> => {
	const title = getProperty('title', component.custom_fields) as string;
	const ctaLabel = getProperty('cta-label', component.custom_fields) as string;
	const ctaHref = getProperty('cta-link', component.custom_fields) as string;

	return () => (
		<BlogSectionUspHighlight
			title={title}
			img={`${component?.media![0].path}`}
			alt={component?.media![0].alt}
			usps={component.child_elements.map((child_element) => {
				const icon = getProperty('icon', child_element.custom_fields) as string;

				return {
					icon,
					title: `${child_element.markdown}`,
				};
			})}
			ctaHref={ctaHref}
			ctaLabel={ctaLabel}
		/>
	);
};

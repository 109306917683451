import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Tag } from '@monorepo/components/DataDisplay';
import { Toggle, ToggleList } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { useRenderFormControl } from '@monorepo/shared/lib/hooks';
import { CalculationProduct, CalculationProductOptionSpecWithGuid } from '@monorepo/types';

import { selectThemeContext } from '@common/store';
import { selectBillingUnit } from '@funnel/store';

type Props = {
	product: CalculationProduct;
	specs: CalculationProductOptionSpecWithGuid[][];
	onChangeOption: (guid: string) => void;
};

export const ProductFormToggle: FC<Props> = ({ product, specs, onChangeOption }) => {
	const { t } = useTranslation(['common']);
	const { displaySalesChannel } = useSelector(selectThemeContext);
	const { getRadioTagPrice, activeOption } = useRenderFormControl(product, displaySalesChannel);
	const billingUnit = useSelector(selectBillingUnit);

	return (
		<div className='space-y-12'>
			<div className='space-y-4'>
				<Text as='p' variant='body-s' color='grayscale500' weight='medium'>
					{t('components.productForm.systemFailure.title')}
				</Text>
				<Text as='span' variant='body-s' color='grayscale400'>
					{t('components.productForm.systemFailure.description')}
				</Text>
			</div>

			<ToggleList>
				{Object.values(
					// First, apply the filter function
					(specs as CalculationProductOptionSpecWithGuid[][])
						// Then, group the filtered specs by reference
						.reduce<Record<string, CalculationProductOptionSpecWithGuid[]>>((acc, curr) => {
							const key = curr[0].reference;
							if (!acc[key]) {
								acc[key] = [];
							}
							acc[key].push(...curr);
							return acc;
						}, {})
				)
					// Ensure that each group has both "off" and "on" states
					.filter(
						(groupSpecs) =>
							groupSpecs.some((spec) => spec.value === 'N') && groupSpecs.some((spec) => spec.value === 'J')
					)
					.map((groupSpecs) => {
						const offState = groupSpecs.find((spec) => spec.value === 'N');
						const onState = groupSpecs.find((spec) => spec.value === 'J');

						const isCurrentlyOn =
							activeOption?.specs.find((spec) => !spec.is_deductible && !spec.is_insured_amount)?.value === 'J';

						return (
							<Toggle
								key={`productForm-toggle-${onState?.option_guid}-${offState?.option_guid}`}
								variant='onOff'
								isToggled={isCurrentlyOn}
								label={t('components.productForm.systemFailure.label')}
								onChange={() => onChangeOption(isCurrentlyOn ? offState!.option_guid : onState!.option_guid)}
								tag={<Tag status='info' text={getRadioTagPrice(groupSpecs, billingUnit)} />}
							/>
						);
					})}
			</ToggleList>
		</div>
	);
};

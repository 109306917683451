import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PremiumQuestion } from '@monorepo/types';

import { AppState } from '@common/store';
import { premiumQuestionsApi } from '@funnel/store';

export type PremiumQuestionsState = {
	hasMessage: boolean;
	premiumQuestions: PremiumQuestion[];
};

const initialState: PremiumQuestionsState = {
	hasMessage: false,
	premiumQuestions: [],
};

export const premiumQuestionsSlice = createSlice({
	name: 'premiumQuestions',
	initialState,
	reducers: {
		setHasMessage(state, action: PayloadAction<boolean>) {
			state.hasMessage = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(premiumQuestionsApi.endpoints.getPremiumQuestions.matchFulfilled, (state, action) => {
			state.premiumQuestions = action.payload;
		});
	},
});

export const { setHasMessage } = premiumQuestionsSlice.actions;

export const selectPremiumQuestionsHasMessage = (state: AppState) => state.premiumQuestions.hasMessage;

/**
 * Only return the active calculations and options that are allowed to be shown on the website
 * */
export const selectRevenueAnswer = createSelector(
	(state: AppState) => state.premiumQuestions,
	({ premiumQuestions }) => premiumQuestions?.find(({ reference }) => reference === 'revenue')?.answer_amount
);

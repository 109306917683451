import { useTranslation } from 'next-i18next';
import { FC, KeyboardEvent, useState } from 'react';

import { KvKCompany } from '@monorepo/types';

import { useAnalytics } from '../../../../lib/hooks';
import { ButtonIcon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { BaseCard } from '../BaseCard/BaseCard';

type CardProps = {
	company: KvKCompany;
	onClick: (dossierNumber: string) => void;
};

export const CompanySearchResultCard: FC<CardProps> = ({ company, onClick }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { t } = useTranslation(['common']);
	const { trackEvent } = useAnalytics();

	const handleClick = () => {
		setIsLoading(true);
		trackEvent('select_content', { content_type: 'company', item_id: company.kvkNummer });
		onClick(company.kvkNummer);
	};

	const keyDown = (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			handleClick();
		}
	};

	return (
		<BaseCard onClick={handleClick} onKeyDown={keyDown} role='listitem' tabIndex={0} className='flex p-16 md:p-24'>
			<div className='flex-flex-col grow space-y-4 md:space-y-8'>
				<Text variant='body-l' weight='semibold' color='grayscale600'>
					{company.naam}
				</Text>
				<div>
					<Text variant='body-m' color='grayscale400'>
						{company.adres.binnenlandsAdres?.straatnaam && company.adres.binnenlandsAdres.plaats
							? `${company.adres.binnenlandsAdres.straatnaam}, ${company.adres.binnenlandsAdres.plaats}`
							: `${t('components.companySearchResults.mailbox')} ${company.adres.binnenlandsAdres?.postbusnummer}`}
					</Text>
					<Text variant='body-m' color='grayscale400'>
						{t('components.companySearchResults.coc', { dossierNumber: company.kvkNummer })}
					</Text>
				</div>
			</div>
			<div className='ml-auto flex items-center justify-center'>
				<ButtonIcon
					className='relative transition-transform delay-75 duration-300 group-hover:translate-x-8 group-active:translate-x-8'
					size='xl'
					mode='dark'
					icon='angle-right'
					isLoading={isLoading}
					iconColor='info300'
				/>
			</div>
		</BaseCard>
	);
};

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';

import { SendMailSchema } from '@monorepo/shared/lib/schemas';
import { trackEcommerceEvent } from '@monorepo/shared/lib/utils';
import { Organization } from '@monorepo/types';

import { useNavigation } from '@common/hooks';
import { useShoppingCart } from '@funnel/hooks';
import { useGetCartOrganizationQuery, useSendCalculationToMailMutation } from '@funnel/store';
import { mapCartToAnalyticsProps } from '@funnel/utils';

export const useSendPremium = (cartGuid: string) => {
	const [email, setEmail] = useState<string>();
	const [isSend, setIsSend] = useState<boolean>(false);
	const { isB2BFunnel } = useNavigation();
	const { data: organization = {} as Organization } = useGetCartOrganizationQuery(
		isB2BFunnel && cartGuid && cartGuid !== 'undefined' ? cartGuid : skipToken
	);

	const [sendCalculation, response] = useSendCalculationToMailMutation();
	const shoppingCart = useShoppingCart();

	const handleSend = async (sendDetails: SendMailSchema) => {
		await sendCalculation({ ...sendDetails, cart_guid: cartGuid, visit_url: window.location.href })
			.unwrap()
			.then(() => {
				trackEcommerceEvent('generate_lead', {
					...mapCartToAnalyticsProps({ cartItems: shoppingCart.cartItems }),
					contact: {
						...(organization &&
							organization?.name && {
								organizationName: organization?.name,
							}),
						email: sendDetails?.email,
						firstName: sendDetails?.first_name,
						lastName: sendDetails?.last_name,
					},
				});
				setEmail(sendDetails.email);
				setIsSend(true);
			});
	};

	return {
		email,
		isSend,
		isLoading: response.isLoading,
		onSend: handleSend,
	};
};

import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC, useRef, useState } from 'react';

import { PortalInsurance } from '@monorepo/types';

import { transformToCurrency } from '../../../../lib/utils';
import { ActionButton } from '../../../DataEntry';
import { Skeleton } from '../../../Misc';
import { Text, TotalPriceFormat, TotalPriceFormatProps } from '../../../TextComponents';

type TotalsCardProps = {
	insurances?: PortalInsurance[];
	isLoading?: boolean;
} & Pick<TotalPriceFormatProps, 'taxPrice' | 'totalPrice'>;

export const TotalsCard: FC<TotalsCardProps> = ({ insurances, isLoading, taxPrice, totalPrice }) => {
	const [isExtended, setIsExtended] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);

	return (
		<div
			className={classNames(
				'rounded-12 border-grayscale100 overflow-hidden border transition-all',
				!isExtended && 'border-hidden'
			)}>
			<div className='bg-primary50'>
				{insurances && insurances.length > 1 && (
					<motion.div
						className={classNames('bg-grayscale0 flex flex-col overflow-hidden')}
						initial='closed'
						animate={isExtended ? 'extended' : 'closed'}
						variants={{
							extended: { height: ref?.current?.scrollHeight },
							closed: { height: 0 },
						}}
						ref={ref}>
						<div className='space-y-8 p-16'>
							{insurances.map((insurance, key) => (
								<div className='flex justify-between pr-32' key={`totals-card-${key}`}>
									<Text variant='body-xxs' weight='regular' color='grayscale400'>
										{insurance.name}
									</Text>
									<Text variant='body-xxs' weight='semibold' color='grayscale400'>
										{transformToCurrency(insurance.product_price.premium_after_tax)}
									</Text>
								</div>
							))}
						</div>
					</motion.div>
				)}

				<div className='space-x flex items-center space-x-8 p-16'>
					<TotalPriceFormat size='small' taxPrice={taxPrice} totalPrice={totalPrice} isLoading={isLoading} />
					<Skeleton variant='rounded' className='h-32 w-32' color='primary100' isLoading={isLoading}>
						{insurances && insurances.length > 1 && (
							<ActionButton
								icon='angle-up'
								className={classNames('rotate-0 transition-transform', isExtended && 'rotate-180')}
								onClick={() => setIsExtended((_) => !_)}
							/>
						)}
					</Skeleton>
				</div>
			</div>
		</div>
	);
};

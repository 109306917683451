import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const petCharacteristicsSchema = (t: TranslationType) =>
	z.object({
		species: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		sex: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		breed: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		year: z.number({ required_error: t('validation.required') }).min(1),
		month: z.number({ required_error: t('validation.required') }).min(1),
	});

export type PetCharacteristicsSchema = z.infer<ReturnType<typeof petCharacteristicsSchema>>;

import { ComponentProps } from 'react';
import { useDispatch } from 'react-redux';

import { MarketingCtaButton, TextButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { Table } from '@monorepo/ui/organism';

import { Modal } from '@common/components';
import { showModal } from '@common/store';

type ComparisonTableProps = {
	title: string;
	subTitle: string;
	data: ComponentProps<typeof Table>['data'];
	link: ComponentProps<typeof MarketingCtaButton>['link'];
	moreInfo: {
		label: string;
	};
};

export function ComparisonTable(props: ComparisonTableProps) {
	const { title, subTitle, data, link, moreInfo } = props;
	const dispatch = useDispatch();

	const handleOpenModal = () => {
		dispatch(showModal('comparison-info-modal'));
	};

	return (
		<section className='py-48 md:py-64'>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 grid grid-cols-3'>
					<Text variant='display-4' weight='semibold' color='primaryMain' className='col-span-3 mb-12 md:col-span-2'>
						{title}
					</Text>
					<TextButton
						iconLeft='info-circle'
						label={moreInfo.label}
						onClick={handleOpenModal}
						className='col-span-1 w-fit justify-self-end max-md:hidden'
					/>
					<Text variant='display-6' weight='regular' color='primaryMain' className='col-span-3 mb-24 md:mb-48'>
						{subTitle}
					</Text>
					<Table
						data={data}
						withRowHeader
						withStickyRowHeader
						highlightedColumnIndex={1}
						className='col-span-3 mb-48'
					/>
					<MarketingCtaButton
						link={link}
						icon='angle-right'
						className='col-span-3 w-full justify-self-center md:w-fit'
					/>
					<TextButton
						iconLeft='info-circle'
						label={moreInfo.label}
						onClick={handleOpenModal}
						className='col-span-3 md:hidden'
					/>
				</div>
				<Modal id='comparison-info-modal' title='Zo hebben we vergeleken'>
					<Text variant='body-m' weight='regular' color='grayscale400'>
						In Oktober 2024 hebben we alle prijzen vergeleken aan de hand van een willekeurig kenteken en een
						veelvoorkomende situaties.
					</Text>
					<Text variant='body-m' weight='regular' color='grayscale400' className='underline'>
						Dit zijn de gegevens die gebruikt zijn:
					</Text>
					<div className='grid grid-cols-2 gap-x-32'>
						<dl className='col-span-2 grid grid-cols-subgrid'>
							<dt>
								<Text variant='body-m' weight='medium' color='grayscale400'>
									Merk:
								</Text>
							</dt>
							<dd>
								<Text variant='body-m' weight='regular' color='grayscale400'>
									TRIUMPH
								</Text>
							</dd>
						</dl>
						<dl className='col-span-2 grid grid-cols-subgrid'>
							<dt>
								<Text variant='body-m' weight='medium' color='grayscale400'>
									Type:
								</Text>
							</dt>
							<dd>
								<Text variant='body-m' weight='regular' color='grayscale400'>
									STREET TRIPLE (2013-04-09)
								</Text>
							</dd>
						</dl>
						<dl className='col-span-2 grid grid-cols-subgrid'>
							<dt>
								<Text variant='body-m' weight='medium' color='grayscale400'>
									Aantal kilometers:
								</Text>
							</dt>
							<dd>
								<Text variant='body-m' weight='regular' color='grayscale400'>
									8000km
								</Text>
							</dd>
						</dl>
						<dl className='col-span-2 grid grid-cols-subgrid'>
							<dt>
								<Text variant='body-m' weight='medium' color='grayscale400'>
									Stad:
								</Text>
							</dt>
							<dd>
								<Text variant='body-m' weight='regular' color='grayscale400'>
									Leiden
								</Text>
							</dd>
						</dl>
						<dl className='col-span-2 grid grid-cols-subgrid'>
							<dt>
								<Text variant='body-m' weight='medium' color='grayscale400'>
									Leeftijd bestuurder:
								</Text>
							</dt>
							<dd>
								<Text variant='body-m' weight='regular' color='grayscale400'>
									36
								</Text>
							</dd>
						</dl>
						<dl className='col-span-2 grid grid-cols-subgrid'>
							<dt>
								<Text variant='body-m' weight='medium' color='grayscale400'>
									Certificaat:
								</Text>
							</dt>
							<dd>
								<Text variant='body-m' weight='regular' color='grayscale400'>
									Geen
								</Text>
							</dd>
						</dl>
						<dl className='col-span-2 grid grid-cols-subgrid'>
							<dt>
								<Text variant='body-m' weight='medium' color='grayscale400'>
									Eigen risico:
								</Text>
							</dt>
							<dd>
								<Text variant='body-m' weight='regular' color='grayscale400'>
									Het minimale dat mogelijk is
								</Text>
							</dd>
						</dl>
						<dl className='col-span-2 grid grid-cols-subgrid'>
							<dt>
								<Text variant='body-m' weight='medium' color='grayscale400'>
									Helm/kleding/schoenen:
								</Text>
							</dt>
							<dd>
								<Text variant='body-m' weight='regular' color='grayscale400'>
									€500,-
								</Text>
							</dd>
						</dl>
						<dl className='col-span-2 grid grid-cols-subgrid'>
							<dt>
								<Text variant='body-m' weight='medium' color='grayscale400'>
									Dagwaarde:
								</Text>
							</dt>
							<dd>
								<Text variant='body-m' weight='regular' color='grayscale400'>
									€9390,-
								</Text>
							</dd>
						</dl>
						<dl className='col-span-2 grid grid-cols-subgrid'>
							<dt>
								<Text variant='body-m' weight='medium' color='grayscale400'>
									Schadevrije jaren:
								</Text>
							</dt>
							<dd>
								<Text variant='body-m' weight='regular' color='grayscale400'>
									5 jaar
								</Text>
							</dd>
						</dl>
					</div>
				</Modal>
			</LayoutGrid>
		</section>
	);
}

import { Product } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const claimableProductsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getClaimableProducts: builder.query<Product[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/claimable-products`,
					method: 'GET',
				};
			},
			providesTags: (result) => {
				if (result) {
					return result.map(({ guid }) => ({ type: 'ClaimableProduct', id: guid }));
				}

				/* istanbul ignore next */
				return ['ClaimableProduct'];
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetClaimableProductsQuery } = claimableProductsApi;

export const { getClaimableProducts } = claimableProductsApi.endpoints;

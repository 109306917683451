import { SendMailSchema } from '@monorepo/shared/lib/schemas';

import { rootApi } from '../../../../common/store/root/root.api';

export const emailUnfinishedCartApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		sendCalculationToMail: builder.mutation<
			void,
			SendMailSchema & {
				cart_guid: string;
				visit_url: string;
			}
		>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/email-unfinished-cart`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['Email'],
		}),
	}),
	overrideExisting: true,
});

export const { useSendCalculationToMailMutation } = emailUnfinishedCartApi;

export const { sendCalculationToMail } = emailUnfinishedCartApi.endpoints;

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const addCompanyDataSchema = (t: TranslationType) =>
	z.object({
		dossierNumber: z
			.string()
			.min(8, t('validation.exact', { num: 8 }))
			.max(8, t('validation.exact', { num: 8 })),
		name: z.string().min(1, t('validation.required')),
		address: z.object({
			street: z.string().min(1, t('validation.required')),
			house_number: z.coerce.number().min(1, t('validation.required')),
			house_number_appendix: z.string().optional(),
			postal_code: z.string().min(1, t('validation.required')),
			city: z.string().min(1, t('validation.required')),
			country: z.string().optional(),
		}),
	});

export type AddCompanydataSchema = z.infer<ReturnType<typeof addCompanyDataSchema>>;

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';

import { AcceptanceStateStatus, FlowState } from '@monorepo/types';

import { useNavigation } from '@common/hooks';
import { useGetConsumerAcceptanceStateQuery } from '@consumer/store';
import { useGetAcceptanceStateQuery, useGetCurrentInsurancesQuery } from '@funnel/store';

type Props = {
	cartGuid: string;
	isInReview?: boolean;
};
export const useFlowState = ({ cartGuid, isInReview = false }: Props): FlowState => {
	// When customer is inReview (approval-after-review page) we don't want to fetch the acceptance state because it'll be Accepted
	const { isB2BFunnel } = useNavigation();
	const useAcceptanceStateQuery = isB2BFunnel ? useGetAcceptanceStateQuery : useGetConsumerAcceptanceStateQuery;
	const { data: acceptanceState } = useAcceptanceStateQuery(!isInReview ? cartGuid : skipToken);
	const acceptanceStatus = isInReview ? AcceptanceStateStatus.Accepted : acceptanceState?.status;
	const { data: currentInsurances = [] } = useGetCurrentInsurancesQuery(cartGuid);

	return useMemo(() => {
		if (isInReview) {
			if (currentInsurances?.length > 0) {
				return 'inreview-wel-itc-sad';
			} else {
				return 'inreview-geen-itc-sad';
			}
		}

		if (currentInsurances?.length > 0) {
			if (acceptanceStatus === AcceptanceStateStatus.Accepted) {
				return 'wel-itc-happy';
			} else {
				return 'wel-itc-sad';
			}
		} else {
			if (acceptanceStatus === AcceptanceStateStatus.Accepted) {
				return 'geen-itc-happy';
			} else {
				return 'geen-itc-sad';
			}
		}
	}, [acceptanceStatus, currentInsurances, isInReview]);
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PortalAccount } from '@monorepo/types';

import { AppState, getContact, hydrate } from '@common/store';

export type AccountState = {
	account: PortalAccount;
	idToken: string | null;
	hasFetchedIdToken: boolean;
};

const initialState: AccountState = {
	account: {} as PortalAccount,
	idToken: null,
	hasFetchedIdToken: false,
};

export const accountSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		setAccount(state, action: PayloadAction<PortalAccount>) {
			state.account = {
				...action.payload,
				sales_channel: action.payload.sales_channel ? action.payload.sales_channel : 'maia',
			};
		},
		setIdToken(state, action: PayloadAction<string | null>) {
			state.idToken = action.payload;
			state.hasFetchedIdToken = true;
		},
		resetAccount(state) {
			state.account = initialState.account;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(hydrate, (state, action: PayloadAction<{ account: AccountState }>) => {
			return {
				...state,
				idToken: action?.payload?.account?.idToken ?? state.idToken,
			};
		});
		builder.addMatcher(getContact.matchFulfilled, (state, action: PayloadAction<PortalAccount>) => {
			state.account = {
				...action.payload,
			};
		});
	},
});

export const { setIdToken, setAccount, resetAccount } = accountSlice.actions;

export const selectPortalAccount = (state: AppState) => state.account.account;
export const selectIdTokenState = (state: AppState) => state.account.idToken;
export const selectHasFetchedIdToken = (state: AppState) => state.account.hasFetchedIdToken;

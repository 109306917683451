import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

export const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [isSmallTablet, setIsSmallTablet] = useState<boolean>(false);
	const [isLargeTablet, setIsLargeTablet] = useState<boolean>(false);
	const [isLargeDisplay, setIsLargeDisplay] = useState<boolean>(false);
	const [windowWidth, setWindowWidth] = useState<number>();

	const [] = useDebounce(
		() => {
			setIsMobile(window.innerWidth < 600);
			setIsSmallTablet(window.innerWidth < 1024);
			setIsLargeTablet(window.innerWidth < 1280);
			setIsLargeDisplay(window.innerWidth > 1920);
		},
		150,
		[windowWidth]
	);

	useEffect(() => {
		window?.addEventListener('resize', () => setWindowWidth(window.innerWidth));

		return () => {
			window?.removeEventListener('resize', () => setWindowWidth(window.innerWidth));
		};
	}, []);

	return {
		isMobile,
		isSmallTablet,
		isLargeTablet,
		isLargeDisplay,
	};
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const boughtInNetherlandsSchema = (t: TranslationType) =>
	z.object({
		'object-acquired-in-holland': z.enum(['J', 'N'], {
			required_error: t('validation.required'),
		}),
	});

export type BoughtInNetherlandsSchema = z.infer<ReturnType<typeof boughtInNetherlandsSchema>>;

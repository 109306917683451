import { Trans, useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Text } from '@monorepo/components/TextComponents';
import { SendMailSchema } from '@monorepo/shared/lib/schemas';

import { Modal } from '@common/components';
import { SendMailForm } from '@funnel/forms';

type SendPremiumModalProps = {
	isSend: boolean;
	email?: string;
	isLoading?: boolean;
	onSubmit: (fields: SendMailSchema) => void;
	parent?: 'cart' | 'sidedrawer' | 'insurance' | 'header';
};

export const SendPremiumModal: FC<SendPremiumModalProps> = ({ email, isSend, onSubmit, isLoading, parent }) => {
	const { t } = useTranslation(['common']);

	return (
		<Modal
			id={`send-premium-to-mail-${parent}`}
			title={t(isSend ? 'modals.sendPremiumModal.confirmation.title' : 'modals.sendPremiumModal.title')}
			text={
				isSend ? (
					<Trans
						i18nKey='modals.sendPremiumModal.confirmation.description'
						tOptions={{ emailAddress: email }}
						components={{
							email: (
								<a target='_blank' rel='noreferrer' href={`mailto:${email}`} className='text-primaryMain underline' />
							),
						}}
					/>
				) : (
					t('modals.sendPremiumModal.description')
				)
			}>
			{isSend ? (
				<Text variant='body-m' color='grayscale500'>
					{t('modals.sendPremiumModal.confirmation.body')}
				</Text>
			) : (
				<div className='mt-8'>
					<SendMailForm onSubmit={onSubmit} isLoading={isLoading} />
				</div>
			)}
		</Modal>
	);
};

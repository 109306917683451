import { FC, ReactNode } from 'react';

import { StandardButton } from '../../../../DataEntry';
import { Icon, Skeleton } from '../../../../Misc';
import { Text } from '../../../../TextComponents';
import { StatusLabel, StatusLabelStatus } from '../../../TagsAndStatus';

type PortalDetailsBlockHeaderProps = {
	title: string | ReactNode;
	icon: string;
	description?: string;
	status?: StatusLabelStatus;
	statusText?: string;
	isLoading?: boolean;
	onEdit?: () => void;
};

export const PortalDetailsBlockHeader: FC<PortalDetailsBlockHeaderProps> = ({
	title,
	icon,
	description,
	status,
	statusText,
	isLoading,
	onEdit,
}) => (
	<div className='flex'>
		<div className='flex flex-1 flex-col max-lg:space-y-16 lg:flex-row lg:space-x-24'>
			<div className='bg-primary50 rounded-12 md:rounded-18 flex h-[60px] w-[60px] items-center justify-center md:h-[72px] md:w-[72px]'>
				<Skeleton isLoading={isLoading} variant='rounded' className='h-[32px] w-[32px]' color='primary100'>
					<Icon name={icon} size='xl' color='primaryMain' />
				</Skeleton>
			</div>
			<div className='space-y-12'>
				<div className='space-y-4'>
					<Skeleton isLoading={isLoading} variant='text' size='display-6' className='w-[300px]'>
						{typeof title === 'string' ? (
							<Text variant='display-6' weight='semibold' color='grayscale600'>
								{title}
							</Text>
						) : (
							title
						)}
					</Skeleton>
					<Skeleton isLoading={isLoading} variant='text' size='body-xs' className='w-[200px]'>
						<Text variant='body-xs' weight='regular' color='grayscale400'>
							{description}
						</Text>
					</Skeleton>
				</div>
				<Skeleton isLoading={isLoading} variant='circular' className='h-[24px] w-[125px]'>
					<StatusLabel text={`${statusText}`} status={status} />
				</Skeleton>
			</div>
		</div>

		{onEdit && (
			<Skeleton isLoading={isLoading} variant='rounded' className='h-[40px] w-[100px]'>
				<StandardButton variant='tertiary' label='Wijzig' iconRight='edit-alt' size='sm' onClick={onEdit} />
			</Skeleton>
		)}
	</div>
);

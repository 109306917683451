import { FC } from 'react';

import { IllustrationProps } from '@monorepo/types';

import { themes } from '../../../../lib/utils';

export const TrustpilotStar: FC<IllustrationProps> = ({ theme, className = '' }) => {
	return (
		<svg
			className={className}
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14 21.3522L20.0833 19.7233L22.625 28L14 21.3522ZM28 10.6541H17.2917L14 0L10.7083 10.6541H0L8.66667 17.2579L5.375 27.9119L14.0417 21.3082L19.375 17.2579L28 10.6541Z'
				fill={themes[theme].colors.grayscale0}
			/>
		</svg>
	);
};

/**
 * All supported affinity groups (labels such as NVM)
 * We cast the type as const, so we can use the values as type, works well for now
 */
export const affinityGroups = [
	'maia',
	'vbo',
	'vastgoedpro',
	'nvm',
	'onderhoudnl',
	'anko',
	'cybermeister',
	'meestersgilde',
	'trustoo',
	'deonlinebeautycursussen',
	'nkc',
	'marengo',
	'zorgzekerpakket',
] as const;
// app.schoutenverzekeringsdienstnvm.nl = nvm
// app.schoutenzekerheid.nl = vbo
// app.vastgoedproverzekeringen.nl = vastgoedpro
// app.onderhoudnlverzekeringen.nl = onderhoudnl
// app.ankoverzekeringsdienst.nl = anko
// cybermeister.maia.insure = cybermeister
// meestersgilde.maia.insure = meestersgilde
// trustoo.maia.insure = trustoo

export const uniqueAffinityGroupsPerFunnel = affinityGroups.filter(
	(ag) => ag === 'nvm' || ag === 'onderhoudnl' || ag === 'anko'
);

export const typography = [
	'display-1',
	'display-2',
	'display-3',
	'display-4',
	'display-5',
	'display-6',
	'body-l',
	'body-m',
	'body-s',
	'body-xs',
	'body-xxs',
] as const;

export const colors = [
	// Main
	'primaryMain',
	'secondaryMain',
	// Primary shades
	'primary600',
	'primary400',
	'primary300',
	'primary200',
	'primary100',
	'primary50',
	// Secondary shades
	'secondary600',
	'secondary400',
	'secondary300',
	'secondary200',
	'secondary100',
	'secondary50',
	// Shades of gray
	'grayscale600',
	'grayscale500',
	'grayscale400',
	'grayscale300',
	'grayscale200',
	'grayscale100',
	'grayscale50',
	'grayscale0',
	// Semantic colors
	'error500',
	'error400',
	'error300',
	'error200',
	'error50',
	'warning500',
	'warning400',
	'warning300',
	'warning200',
	'warning50',
	'success500',
	'success400',
	'success300',
	'success200',
	'success50',
	'info500',
	'info400',
	'info300',
	'info200',
	'info50',
] as const;

export default colors;

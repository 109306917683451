import cn from 'classnames';
import { useInView } from 'framer-motion';
import { FC, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Icon, LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { ICustomerSupportCta } from '@monorepo/types/src/components/CustomerSupportCta/CustomerSupportCta.type';

import { openSupportChat } from '@support/store';
import { AnimatedSvgLine } from '../AnimatedSvgLine/AnimatedSvgLine';

export type CtaSupportSectionProps = ICustomerSupportCta & {
	className?: string;
};

export const CtaSupportSection: FC<CtaSupportSectionProps> = ({ supportItems, className }) => {
	const sectionRef = useRef<HTMLDivElement>(null);
	const isInView = useInView(sectionRef, { amount: 0.45, once: true });
	const dispatch = useDispatch();

	const handleStartChat = () => {
		dispatch(openSupportChat());
	};

	return (
		<section
			className={cn(className, 'pt-104 md:py-104 bg-primaryMain relative overflow-hidden pb-48')}
			ref={sectionRef}>
			<LayoutGrid layout='marketing'>
				<AnimatedSvgLine
					inView={isInView}
					className='absolute -top-12 h-[100px] w-[200px] max-md:-left-64 md:-top-56 md:right-0 md:h-[200px] md:w-[400px]'
				/>
				<div className='col-start-2 grid grid-cols-3 gap-40'>
					{supportItems.map(({ icon, title, description, ...rest }, key) => (
						<div
							className='col-span-full flex flex-col space-y-24 p-24 md:col-span-1 md:space-y-56'
							key={`support-item-${key}`}>
							<Icon name={icon} size='xxxl' color='secondaryMain' />
							<div className='flex flex-col space-y-32'>
								<div className='flex flex-col space-y-8'>
									<Text variant='display-5' weight='semibold' color='grayscale0'>
										{title}
									</Text>
									<Text variant='body-l' weight='regular' color='grayscale0'>
										{description}
									</Text>
								</div>
								{rest.type === 'email' && (
									<Text
										as='a'
										href={`malto:${rest.email}`}
										variant='body-l'
										weight='semibold'
										color='grayscale0'
										className='w-fit underline'>
										{rest.email}
									</Text>
								)}
								{rest.type === 'chat' && (
									<Text
										as='button'
										type='button'
										variant='body-l'
										weight='semibold'
										color='grayscale0'
										className='w-fit underline'
										onClick={handleStartChat}>
										{rest.text}
									</Text>
								)}
								{rest.type === 'phone' && (
									<Text
										as='a'
										href={`tel:${rest.phoneNumber}`}
										variant='body-l'
										weight='semibold'
										color='grayscale0'
										className='w-fit underline'>
										{rest.phoneNumber}
									</Text>
								)}
							</div>
						</div>
					))}
				</div>
			</LayoutGrid>
		</section>
	);
};

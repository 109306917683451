import { createElement, ElementType, ReactElement } from 'react';

import { ReactTag, TabProps } from '../Tab';
import { useData } from '../Tab.store';

const DEFAULT_TAB_MENU_TAG = 'div' as const;

type TabMenuProps<Tag extends ReactTag, Slot> = TabProps<Tag, Slot>;

export const TabMenu = <Tag extends ElementType = typeof DEFAULT_TAB_MENU_TAG>(
	props: TabMenuProps<Tag, { selectedIndex: number }>
) => {
	const { selectedIndex, orientation } = useData('Tab.Menu');

	const { as: ComponentFromProps, ...rest } = props;
	const slot = { selectedIndex };

	const resolvedChildren = (typeof props.children === 'function' ? props.children(slot) : props.children) as
		| ReactElement
		| ReactElement[];

	const controlledProps = {
		role: 'tablist',
		'aria-orientation': orientation,
	};

	const Component = ComponentFromProps || DEFAULT_TAB_MENU_TAG;

	return createElement(Component, { ...rest, ...controlledProps }, resolvedChildren);
};

import { IHeaderSection } from '@monorepo/types';

export const mockInsurancesHeader: IHeaderSection = {
	title: 'Alle verzekeringen',
	variant: 'simple',
};

export const mockServiceHeader: IHeaderSection = {
	title: 'Waar kan Māia mee helpen?',
	variant: 'search',
};

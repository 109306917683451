import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'ts-pattern';

import { useAnalytics } from '@monorepo/shared/lib/hooks';
import { AovProductSchema, PensionPlanSchema, PreconditionSchema } from '@monorepo/shared/lib/schemas';
import { sortProducts, transformDateFormat } from '@monorepo/shared/lib/utils';
import {
	Address,
	Calculation,
	CalculationForm,
	CalculationPrecondition,
	CalculationProduct,
	CalculationRiskAddress,
	CmsContainer,
	ProductFormFields,
} from '@monorepo/types';

import { useLazyProductContainerQuery } from '@cms/store';
import { hideModal, showModal } from '@common/store';
import { formatError, logger } from '@common/utils';
import {
	closeQuote,
	selectOptionalProducts,
	selectRiderProducts,
	selectShownCalculationState,
	selectShownCalculationStateWithoutOptionalProducts,
	selectShownCalculationStateWithoutRiderProducts,
	setCalculatingCart,
	toggleActiveCalculation,
	toggleActiveCalculationSpec,
	toggleActiveProduct,
	updateCalculationOptionRiskAddress,
	updateIsProductLoading,
	useAnswerPreconditionsMutation,
	useCalculateAovMutation,
	useCreateRiskAddressMutation,
	useLazyGetCartCalculationsQuery,
	useUpdateActiveCalculationMutation,
} from '@funnel/store';

type Props = {
	useOptionalProducts?: boolean;
	excludeOptionalProducts?: boolean;
	useRiderProducts?: boolean;
	excludeRiderProducts?: boolean;
	fetchCmsContent?: boolean;
};

export const useCompose = ({
	useOptionalProducts,
	excludeOptionalProducts,
	useRiderProducts,
	excludeRiderProducts,
	fetchCmsContent,
}: Props = {}) => {
	const { calculations, forms } = useSelector(
		match({
			useOptionalProducts,
			excludeOptionalProducts,
			useRiderProducts,
			excludeRiderProducts,
		})
			.with({ useOptionalProducts: true }, () => selectOptionalProducts)
			.with({ excludeOptionalProducts: true }, () => selectShownCalculationStateWithoutOptionalProducts)
			.with({ useRiderProducts: true }, () => selectRiderProducts)
			.with({ excludeRiderProducts: true }, () => selectShownCalculationStateWithoutRiderProducts)
			.otherwise(() => selectShownCalculationState)
	);

	const { calculations: shownCalculations } = useSelector(selectShownCalculationState);
	const dispatch = useDispatch();
	const [calculateAov] = useCalculateAovMutation();

	const { trackEcommerceEvent, resetEcommerce } = useAnalytics();
	const [fetchCalculations] = useLazyGetCartCalculationsQuery();
	const [toggleInsurance] = useUpdateActiveCalculationMutation();
	const [answerPreconditions] = useAnswerPreconditionsMutation();
	const [createRiskAddressMutation] = useCreateRiskAddressMutation();
	const [fetchProductInfo] = useLazyProductContainerQuery();
	const [calculationsWithCmsContent, setCalculationsWithCmsContent] = useState<
		Array<{ forms: CalculationForm | undefined; renderType: Calculation['product']['render_type'] } & Calculation>
	>([]);

	// ? Hack to only send a full set of preconditions
	const [answeredProductPreconditions, setAnsweredProductPreconditions] = useState<
		Map<string, CalculationPrecondition[]>
	>(new Map());

	const handleEventTracking = (product: CalculationProduct, isToggled: boolean) => {
		const event = isToggled ? 'add_to_cart' : 'remove_from_cart';
		const activeOption = product.options.find(({ is_active }) => is_active);
		resetEcommerce();
		trackEcommerceEvent(event, {
			value: activeOption?.price.premium_after_tax,
			tax: activeOption?.price.tax,
			transaction_id: activeOption?.guid,
			items: [
				{
					item_id: activeOption?.code,
					item_name: activeOption?.name,
					item_brand: product?.insurer,
					price: activeOption?.price.premium_after_tax,
					discount: activeOption?.price?.discount,
					...(activeOption?.price?.promotion && {
						coupon: `${activeOption?.price?.promotion}`,
					}),
				},
			],
		});
	};

	const handleToggleInsurance = async (cartGuid: string, calculation: Calculation, isToggled: boolean) => {
		try {
			dispatch(setCalculatingCart(true));

			const response = await toggleInsurance({
				cart_guid: cartGuid,
				product_guid: calculation.product.guid,
				state: isToggled,
			});

			if (
				!(
					response as unknown as {
						error: FetchBaseQueryError | SerializedError;
					}
				)?.error
			) {
				const newCalculation: Calculation = {
					...calculation,
					product: { ...calculation.product, is_active: isToggled },
				};

				dispatch(toggleActiveCalculation(newCalculation));
				handleEventTracking(newCalculation.product, isToggled);
			}
		} catch (error) {
			logger.error('Failed to toggle insurance', {
				labels: {
					error: formatError(error),
					cartGuid,
					calculation,
					isToggled,
				},
			});
		} finally {
			dispatch(setCalculatingCart(false));
		}
	};

	const handleChangeAddress = async (cartGuid: string, calculation: Calculation, address: Address) => {
		try {
			dispatch(setCalculatingCart(true));

			const response = await createRiskAddressMutation({
				cart_guid: cartGuid,
				product_guid: calculation.product.guid,
				...address,
			});

			if (
				!(
					response as unknown as {
						error: FetchBaseQueryError | SerializedError;
					}
				)?.error
			) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { street_and_number: _, ...rest } = address;

				dispatch(
					updateCalculationOptionRiskAddress({
						productGuid: calculation.product.guid,
						newRiskAddress: rest as CalculationRiskAddress,
					})
				);
			}
		} catch (error) {
			logger.error('Failed to change address', {
				labels: {
					error: formatError(error),
					cartGuid,
					calculation,
					address,
				},
			});
		} finally {
			dispatch(setCalculatingCart(false));
		}
	};

	const handleChangeOption = async (cartGuid: string, calculation: Calculation, optionGuid: string) => {
		try {
			dispatch(setCalculatingCart(true));

			const response = await toggleInsurance({
				cart_guid: cartGuid,
				option_guid: optionGuid,
				state: true,
			});

			if (
				!(
					response as unknown as {
						error: FetchBaseQueryError | SerializedError;
					}
				)?.error
			) {
				dispatch(toggleActiveCalculationSpec({ productGuid: calculation.product.guid, optionGuid }));

				// Create the newCalulcation which is used for eventTracking
				const newCalculation = {
					...calculation,
					product: {
						...calculation.product,
						options: calculation.product.options.map((option) => {
							return {
								...option,
								is_active: option.guid === optionGuid,
							};
						}),
					},
				};
				handleEventTracking(newCalculation.product, true);
			}
		} catch (error) {
			logger.error('Failed to change option', {
				error: formatError(error),
				cartGuid,
				calculation,
				optionGuid,
			});
		} finally {
			dispatch(setCalculatingCart(false));
		}
	};

	const handleSubmitPensionQuote = async (cartGuid: string, product: CalculationProduct, fields: PensionPlanSchema) => {
		try {
			dispatch(setCalculatingCart(true));
			dispatch(updateIsProductLoading({ guid: product.guid, isLoading: true }));

			await toggleInsurance({
				cart_guid: cartGuid,
				product_guid: product.guid,
				state: true,
			});

			handleEventTracking(product, true);

			const questions = product?.preconditions
				?.map(({ answer, guid, reference }) => {
					if (reference === 'age') return { answer: fields.age.toString(), guid };
					if (reference === 'retirement-age') return { answer: fields.retirementAge.toString(), guid };
					if (reference === 'one-time-deposit') return { answer: fields.oneTimeDeposit.toString(), guid };
					if (reference === 'monthly-deposit') return { answer: fields.depositPerMonth.toString(), guid };

					return { answer: answer, guid };
				})
				.filter((precondition) => precondition.answer !== null);

			await answerPreconditions({
				cart_guid: cartGuid,
				questions: questions ?? [],
				product_guid: product.guid,
			});
			await fetchCalculations(cartGuid);
			dispatch(closeQuote(product.guid));
		} catch (error) {
			logger.error('Failed to change pension plan product', {
				error: formatError(error),
				cartGuid,
				productGuid: product.guid,
				fields,
			});
		} finally {
			dispatch(setCalculatingCart(false));
			dispatch(updateIsProductLoading({ guid: product.guid, isLoading: false }));
		}
	};

	const handleSubmitAovQuote = async (cartGuid: string, product: CalculationProduct, fields: AovProductSchema) => {
		try {
			dispatch(setCalculatingCart(true));
			dispatch(updateIsProductLoading({ guid: product.guid, isLoading: true }));

			const calculateAovResponse = await calculateAov({
				persoonsGegevens: {
					geboortedatum: fields.dateOfBirth,
				},
				werkGegevens: {
					beroep: 'Klinisch verpleegkundige',
					eindleeftijd: 68,
					verzekerdBedrag: parseInt(fields.insuredAmount),
					risicoTermijn: fields.remainingDays,
					uitkeringsduur: '68',
				},
				fromCalculationTool: false,
				product: 'aov',
				funnelNodeId: 34943,
				emediairId: 34942,
			}).unwrap();

			await toggleInsurance({
				cart_guid: cartGuid,
				product_guid: product.guid,
				state: true,
			});

			handleEventTracking(product, true);

			const questions = product?.preconditions
				?.map(({ answer, guid, reference }) => {
					if (reference === 'profession') return { answer: 'Klinisch verpleegkundige', guid };
					if (reference === 'date-of-birth')
						return { answer: transformDateFormat(fields.dateOfBirth.toString()), guid };
					if (reference === 'insured_amount') return { answer: fields.insuredAmount.toString(), guid };
					if (reference === 'waiting-term') return { answer: fields.remainingDays.toString(), guid };
					if (reference === 'premium-indication')
						return { answer: calculateAovResponse[0].TermijnbedragTotaal?.toString() ?? '0', guid };

					return { answer: answer, guid };
				})
				.filter((precondition) => precondition.answer !== null);

			await answerPreconditions({
				cart_guid: cartGuid,
				questions: questions ?? [],
				product_guid: product.guid,
			});
			await fetchCalculations(cartGuid);

			dispatch(closeQuote(product.guid));
		} catch (error) {
			logger.error('Failed to change pension plan product', {
				error: formatError(error),
				cartGuid,
				productGuid: product.guid,
				fields,
			});
		} finally {
			dispatch(setCalculatingCart(false));
			dispatch(updateIsProductLoading({ guid: product.guid, isLoading: false }));
		}
	};

	const handleChangePrecondition = async (
		cartGuid: string,
		productGuid: string,
		{ preconditions }: PreconditionSchema
	) => {
		try {
			dispatch(setCalculatingCart(true));
			dispatch(updateIsProductLoading({ guid: productGuid, isLoading: true }));

			if (!calculations.find(({ product }) => product.guid === productGuid)?.product.is_active) {
				await toggleInsurance({
					cart_guid: cartGuid,
					product_guid: productGuid,
					state: true,
				});
			}

			const questions = preconditions.map(({ answer, guid }) => ({ answer, guid }));

			await answerPreconditions({
				cart_guid: cartGuid,
				questions,
				product_guid: productGuid,
			});

			await fetchCalculations(cartGuid);
		} catch (error) {
			logger.error('Failed to change precondition', {
				error: formatError(error),
				cartGuid,
				productGuid,
				preconditions,
			});
		} finally {
			dispatch(setCalculatingCart(false));
			dispatch(updateIsProductLoading({ guid: productGuid, isLoading: false }));
			dispatch(hideModal(`precondition-modal-${productGuid}`));
		}
	};

	const handleToggleQuoteProduct = async (cartGuid: string, product: CalculationProduct, state?: boolean) => {
		try {
			dispatch(setCalculatingCart(true));
			dispatch(updateIsProductLoading({ guid: product.guid, isLoading: true }));

			const newToggleState = !product.is_active;

			const response = await toggleInsurance({
				cart_guid: cartGuid,
				product_guid: product.guid,
				state: state ? state : newToggleState,
			});

			if (
				!(
					response as unknown as {
						error: FetchBaseQueryError | SerializedError;
					}
				)?.error
			) {
				dispatch(toggleActiveProduct({ productGuid: product.guid }));
				handleEventTracking(product, newToggleState);

				// If the product is deselected, we need to check if any of the rider products are active and deselect them
				if (!newToggleState) {
					const riderProducts = shownCalculations.filter(
						({ product: { is_active, is_rider, dependant_on, guid } }) =>
							is_active && is_rider && guid !== product.guid && dependant_on.find(({ guid }) => guid === product.guid)
					);

					for (const riderProduct of riderProducts) {
						await handleToggleProduct(cartGuid, riderProduct.product, false);
					}
				}
			}
		} catch (error) {
			logger.error('Failed to toggle product', {
				error: formatError(error),
				cartGuid,
				product,
				state: `${state}`,
			});
		} finally {
			dispatch(setCalculatingCart(false));
			dispatch(updateIsProductLoading({ guid: product.guid, isLoading: false }));
		}
	};

	const handleToggleProduct = async (cartGuid: string, product: CalculationProduct, state?: boolean) => {
		if (product.preconditions.every(({ answer }) => answer)) {
			try {
				dispatch(setCalculatingCart(true));
				dispatch(updateIsProductLoading({ guid: product.guid, isLoading: true }));

				const newToggleState = !product.is_active;

				const response = await toggleInsurance({
					cart_guid: cartGuid,
					product_guid: product.guid,
					state: state ? state : newToggleState,
				});

				if (
					!(
						response as unknown as {
							error: FetchBaseQueryError | SerializedError;
						}
					)?.error
				) {
					dispatch(toggleActiveProduct({ productGuid: product.guid }));
					handleEventTracking(product, newToggleState);

					// If the product is deselected, we need to check if any of the rider products are active and deselect them
					if (!newToggleState) {
						const riderProducts = shownCalculations.filter(
							({ product: { is_active, is_rider, dependant_on, guid } }) =>
								is_active && is_rider && guid !== product.guid && dependant_on.find(({ guid }) => guid === product.guid)
						);

						for (const riderProduct of riderProducts) {
							await handleToggleProduct(cartGuid, riderProduct.product, false);
						}
					}
				}
			} catch (error) {
				logger.error('Failed to toggle product', {
					error: formatError(error),
					cartGuid,
					product,
					state: `${state}`,
				});
			} finally {
				dispatch(setCalculatingCart(false));
				dispatch(updateIsProductLoading({ guid: product.guid, isLoading: false }));
			}
		} else {
			dispatch(showModal(`precondition-modal-${product.guid}`));
		}
	};

	const handleChangeSpec = async (cartGuid: string, product: CalculationProduct, form: ProductFormFields) => {
		try {
			dispatch(setCalculatingCart(true));
			dispatch(updateIsProductLoading({ guid: product.guid, isLoading: true }));

			const activeSpecsFromForm = form.fields
				.map((field) => field.values.filter((value) => value.value === field.activeValue))
				.flat()
				.map((field) => field.specs)
				.flat();

			const findOptionWithMatchingSpecs = product.options.find((option) =>
				option.specs.every((spec) =>
					activeSpecsFromForm.some(
						(activeSpec) => activeSpec?.reference === spec.reference && activeSpec.value === spec.value
					)
				)
			);

			if (findOptionWithMatchingSpecs) {
				const response = await toggleInsurance({
					cart_guid: cartGuid,
					option_guid: findOptionWithMatchingSpecs.guid,
					state: true,
				});

				if (
					!(
						response as unknown as {
							error: FetchBaseQueryError | SerializedError;
						}
					)?.error
				) {
					dispatch(
						toggleActiveCalculationSpec({ productGuid: product.guid, optionGuid: findOptionWithMatchingSpecs.guid })
					);
					handleEventTracking(
						{
							...product,
							options: product.options.map((option) => {
								return {
									...option,
									is_active: option.guid === findOptionWithMatchingSpecs.guid,
								};
							}),
						},
						true
					);
				}
			}
		} catch (error) {
			logger.error('Failed to change spec', {
				error: formatError(error),
				cartGuid,
				product,
				form,
			});
		} finally {
			dispatch(setCalculatingCart(false));
			dispatch(updateIsProductLoading({ guid: product.guid, isLoading: false }));
		}
	};

	useEffect(() => {
		const fetchCalculationsWithContent = async () => {
			const newCalculations: Array<
				{ forms: CalculationForm | undefined; renderType: Calculation['product']['render_type'] } & Calculation
			> = [];

			for (const calculation of calculations || []) {
				let cmsData: CmsContainer = {} as CmsContainer;

				if (fetchCmsContent) {
					const { data } = await fetchProductInfo(
						{
							productGuid: calculation.product.guid,
							container: 'product_intro',
						},
						true // preferCacheValue
					);

					if (data) {
						cmsData = data;
					}
				}

				newCalculations.push({
					...calculation,
					...(cmsData && { content: cmsData }),
					forms: forms.find((form) => form.product === calculation.product.guid),
					renderType: calculation.product.render_type ?? 'default',
				});
			}

			setCalculationsWithCmsContent(
				newCalculations.sort(({ product: { name: nameA } }, { product: { name: nameB } }) => sortProducts(nameA, nameB))
			);
		};

		fetchCalculationsWithContent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calculations, fetchCmsContent]);

	useEffect(() => {
		if (answeredProductPreconditions.size === 0) {
			const _answeredProductPreconditions = new Map<string, CalculationPrecondition[]>(new Map());

			calculations.forEach(({ product }) => {
				if (product.preconditions.length > 0) {
					_answeredProductPreconditions.set(product.guid, product.preconditions);
				}
			});
			setAnsweredProductPreconditions(_answeredProductPreconditions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calculations]);

	return {
		handleSubmitAovQuote,
		handleSubmitPensionQuote,
		calculations: calculationsWithCmsContent,
		handleToggleInsurance,
		handleToggleProduct,
		handleToggleQuoteProduct,
		handleChangeAddress,
		handleChangeOption,
		handleChangePrecondition,
		handleChangeSpec,
	};
};

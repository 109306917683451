import cn from 'classnames';
import { InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { FieldValues } from 'react-hook-form';

import { BaseCard } from '../../../DataDisplay';
import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { FormBaseInputDefaultProps } from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormAcceptanceStatementCheckboxProps<TFormValues extends FieldValues> = {
	label?: ReactNode;
	helperText?: string;
	hideError?: boolean;
	grow?: boolean;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<InputHTMLAttributes<HTMLInputElement>, 'aria-invalid' | 'className'>;

export const FormAcceptanceStatementCheckbox = <TFormValues extends Record<string, unknown>>({
	id,
	name,
	label,
	errors,
	helperText,
	register,
	rules,
	grow,
	...props
}: FormAcceptanceStatementCheckboxProps<TFormValues>): ReactElement => {
	return (
		<FormHelper errors={errors} hint={helperText} hideError={true}>
			{({ isError }) => {
				return (
					<BaseCard
						isElevated={false}
						enableEffects={false}
						className={cn(
							isError && 'border-error300 outline-error300',
							props.checked &&
								!props.disabled &&
								'border-success300 outline-success300 hover:border-success400 hover:outline-success400 active:border-success500 active:outline-success500 focus:border-success500 focus:outline-success500',
							!props.checked &&
								!props.disabled &&
								'hover:border-grayscale200 active:border-grayscale300 focus:border-grayscale300 outline-transparent',
							!props.disabled && 'cursor-pointer',
							grow && 'flex-grow',
							'!rounded-6',
							'relative flex w-full flex-row items-center border outline outline-1'
						)}>
						<Text as='label' className='flex w-full cursor-pointer flex-row items-center p-24'>
							<div className='flex items-center justify-center'>
								<input
									id={id || name}
									name={name}
									type='checkbox'
									aria-invalid={isError}
									className={cn(
										// Base
										'rounded-4 text-success300 border-grayscale300 peer h-24 w-24 cursor-pointer appearance-none transition-colors duration-300',
										// Disabled
										'disabled:border-grayscale200 disabled:bg-grayscale50 disabled:checked:bg-grayscale200 disabled:checked:border-grayscale200 disabled:cursor-not-allowed',
										// Hover
										'group-hover:border-success300 group-hover:bg-success50 group-hover:checked:bg-success400 group-hover:checked:border-success400',
										// Focus
										'group-focus:border-success300 group-focus:bg-success50 group-focus:checked:bg-success300 group-focus:checked:border-success300 group-focus:ring-0',
										'focus:border-success300 focus:bg-success50 focus:checked:bg-success300 focus:checked:border-success300 focus:ring-0',
										// Active
										'group-active:border-success300 group-active:bg-success100',
										// Checked
										'checked:border-success300 checked:bg-success300 accent-error300'
									)}
									{...props}
									{...(register && register(name, rules))}
								/>

								<div
									className={cn(
										// Base
										'pointer-events-none absolute scale-0 cursor-pointer opacity-0 transition-all duration-300 ease-out',
										// Disabled
										'peer-disabled:text-grayscale400 peer-disabled:cursor-not-allowed',
										// Checked
										'peer-checked:text-grayscale0 peer-checked:scale-100 peer-checked:opacity-100'
									)}>
									<Icon name='check' color='inherit' className='relative block' />
								</div>
							</div>
							{label && (
								<Text
									as='span'
									variant='body-m'
									weight='regular'
									color='inherit'
									className={cn(
										// Base
										'ml-24 grow select-none transition-colors duration-300 ease-out',
										// Not disabled
										!props.disabled && 'text-grayscale300 cursor-pointer',
										// Disabled
										props.disabled && 'text-grayscale400 cursor-not-allowed',
										// Checked
										props.checked && !props.disabled && 'text-grayscale600'
									)}>
									{label}
								</Text>
							)}
						</Text>
					</BaseCard>
				);
			}}
		</FormHelper>
	);
};

import cn from 'classnames';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { FC, useRef } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { useIsMobile } from '@monorepo/shared/lib/hooks';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { CmsMedia } from '@monorepo/types';

import { CtaSearchForm } from '../CtaSearchFormSection/CtaSearchForm/CtaSearchForm';

export type HeroWithScollingImageAndSearchProps = {
	title: string;
	images: CmsMedia[];
	className?: string;
};

export const HeroWithScollingImageAndSearch: FC<HeroWithScollingImageAndSearchProps> = ({
	title,
	images,
	className,
}) => {
	const { isSmallTablet } = useIsMobile();

	const containerRef = useRef<HTMLDivElement>(null);
	const MOBILE_ANIMATION_TIME = images.length * 6;
	const DESKTOP_ANIMATION_TIME = images.length * 7;
	const TOTAL_WIDTH_OF_MOBILE_SLIDER = 135 * images.length + images.length * 16; // calculated by width of slide (135px) * total images + total images & space between slide

	const half = Math.ceil(images.length / 2);

	const desktopImageColumnGroups = [
		[[...images.slice(0, half)], [...images.slice(0, half)]],
		[[...images.slice(half)], [...images.slice(half)]],
	];

	return (
		<header className={cn(className, 'max-md:pt-48 md:overflow-y-hidden')}>
			<LayoutGrid layout='marketing'>
				<div className='gap-124 col-start-2 grid grid-cols-2'>
					<div className='col-span-full md:col-span-1 md:py-96'>
						<div className='col-start-2 flex flex-col space-y-24 overflow-hidden'>
							<div className='flex flex-col md:flex-row md:space-x-48'>
								<Text as='h1' variant='display-2' weight='semibold' color='primaryMain' className='hyphens-auto'>
									{title}
								</Text>
							</div>

							<div className='bg-grayscale0 rounded-12 p-16 md:p-32'>
								<CtaSearchForm id='hero-with-scrolling-image-and-search' />
							</div>
						</div>
					</div>

					{!isSmallTablet && (
						<div className='relative col-span-1 mx-32 max-md:hidden'>
							<div className='absolute inset-0 grid h-full w-full grid-cols-2 gap-x-16'>
								{desktopImageColumnGroups.map((columnGroup, columngGroupKey) => (
									<div
										key={`vertical-slide-column-group-${columngGroupKey}`}
										className={classNames(
											columngGroupKey === 0 && 'absolute bottom-0 left-0 flex w-[calc(50%-16px)] flex-col',
											columngGroupKey === 1 && 'absolute -bottom-[75px] right-0 flex w-[calc(50%-16px)] flex-col'
										)}>
										{columnGroup.map((column, columnKey) => (
											<motion.div
												key={`vertical-slide-column-${columnKey}-${columngGroupKey}`}
												transition={{ duration: DESKTOP_ANIMATION_TIME, ease: 'linear', repeat: Infinity }}
												initial={{ y: 0 }}
												animate={{ y: '100%' }}
												className='col-span-1 flex flex-col'>
												{column.map((image, imageKey) => (
													<div
														key={`vertical-slide-image-${imageKey}-${columnKey}-${columngGroupKey}`}
														className='rounded-18 bg-grayscale0 shadow-1 relative mb-32 flex h-[150px] w-full items-center justify-center overflow-hidden'>
														<div className='relative h-[74px] w-[172px]'>
															<Image
																sizes={generateSizesString()}
																priority
																src={image.path}
																alt={image.alt}
																fill
																className='object-contain'
															/>
														</div>
													</div>
												))}
											</motion.div>
										))}
									</div>
								))}
							</div>
						</div>
					)}
				</div>

				{isSmallTablet && (
					<div className='col-span-full flex flex-col space-y-16 overflow-x-hidden py-24 md:hidden' ref={containerRef}>
						{[0, 1].map((_, key0) => (
							<ul
								className={classNames('flex flex-row space-x-16', key0 === 1 && '-ml-[75.5px]')}
								key={`mobile-horizontal-slide-row-${key0}`}>
								{[0, 1].map((_, key1) => (
									<div className='flex flex-row space-x-16' key={`mobile-horizontal-slide-${key1}`}>
										{(key0 === 0 ? images : [...images].reverse()).map((image, key2) => (
											<motion.div
												transition={{ duration: MOBILE_ANIMATION_TIME, ease: 'linear', repeat: Infinity }}
												initial={{ x: 0 }}
												animate={{ x: -TOTAL_WIDTH_OF_MOBILE_SLIDER }}
												key={`mobile-horizontal-slide-${key0}-${key1}-${key2}`}>
												<div className='rounded-18 bg-grayscale0 shadow-1 relative flex h-[81px] w-[135px] items-center justify-center overflow-hidden'>
													<div className='relative h-[40px] w-[94px]'>
														<Image
															sizes={generateSizesString()}
															priority
															src={image.path}
															alt={image.alt}
															className='object-cover'
															fill
														/>
													</div>
												</div>
											</motion.div>
										))}
									</div>
								))}
							</ul>
						))}
					</div>
				)}
			</LayoutGrid>
		</header>
	);
};

import cn from 'classnames';
import { FC } from 'react';

import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { Notification, notificationClasses, NotificationStatus } from '../Notification/Notification';

type Props = {
	title: string;
	message: string;
	status?: NotificationStatus;
	visible?: boolean;
	onClose?: () => void;
	className?: string;
};

export const ToasterNotification: FC<Props> = ({ title, message, status = 'default', visible, onClose, className }) => {
	return (
		<Notification
			className={cn(
				className,
				visible ? 'motion-safe:animate-in slide-in-from-top' : 'motion-safe:animate-out fade-out',
				'fill-mode-both pointer-events-auto px-20 py-12'
			)}
			status={status}>
			<div className='flex flex-row items-center'>
				<div className='mr-16 flex flex-col'>
					<Text
						as='p'
						className='max-md:hidden'
						weight='semibold'
						color={status === 'info' ? 'info300' : 'grayscale600'}
						variant='body-xs'>
						{title}
					</Text>
					<Text variant='body-xs' color='grayscale500' as='span'>
						{message}
					</Text>
				</div>
				{onClose && (
					<div className='ml-auto'>
						<Icon
							name='times'
							className='cursor-pointer'
							color={notificationClasses.iconColor[status]}
							onClick={onClose}
						/>
					</div>
				)}
			</div>
		</Notification>
	);
};

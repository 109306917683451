import cn from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';

import { Icon } from '../../../Misc';
import { BaseButton } from '../BaseButton/BaseButton';

type ActionButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	icon?: string;
	size?: 'small' | 'large';
	isActive?: boolean;
	rotate?: boolean;
};

export const ActionButton: FC<ActionButtonProps> = ({
	icon = 'arrow-up',
	disabled = false,
	onClick,
	size = 'large',
	isActive = false,
	className = '',
	rotate = false,
	...props
}) => {
	return (
		<BaseButton
			disabled={disabled}
			onClick={onClick}
			className={cn(
				className,
				size === 'large' && 'rounded-12 h-32 min-w-32',
				size === 'small' && 'rounded-6 h-24 min-w-24',
				isActive ? 'bg-grayscale0' : 'bg-primary50',
				'disabled:bg-grayscale50 active:enabled:bg-primary200 hover:enabled:bg-primary100'
			)}
			{...props}>
			<div
				className={cn(rotate && 'rotate-180', 'flex h-full w-full items-center justify-center transition-transform')}>
				<Icon
					name={icon}
					size={size === 'large' ? 'md' : 'sm'}
					color='primaryMain'
					className='group-hover:text-primary600 group-active:text-primary600 group-disabled:text-grayscale300'
				/>
			</div>
		</BaseButton>
	);
};

import { FaqResponse } from '@monorepo/types';

import { rootApi } from '@common/store/root/root.api';

const faqApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getFaqItems: builder.query<FaqResponse, void>({
			query() {
				return {
					url: `/faqs`,
					method: 'GET',
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetFaqItemsQuery } = faqApi;

export const { getFaqItems } = faqApi.endpoints;

import {
	CmsContainer,
	CmsElement,
	CmsGlobals,
	CmsSitemapPath,
	CmsWebpageRequest,
	CmsWebpageResponse,
	CmsWebpagesResponse,
} from '@monorepo/types';

import {
	transformGlobalsToFooterColumns,
	transformGlobalsToNavItems,
	transformGlobalsToSocialItems,
	transformGlobalsToSocialProof,
} from '@cms/utils';
import { rootCmsApi } from '../../root/root.api';

const cmsWebpagesApi = rootCmsApi.injectEndpoints({
	endpoints: (builder) => ({
		getContainers: builder.query<CmsContainer, { guid: string }>({
			query({ guid }) {
				return {
					url: `/containers/${guid}`,
					method: 'GET',
				};
			},
			providesTags: (result, error, { guid }) => {
				if (result) {
					return [{ type: 'containers', id: guid }];
				}

				/* istanbul ignore next */
				return ['containers'];
			},
		}),
		getWebpages: builder.query<CmsWebpagesResponse, void>({
			query() {
				return {
					url: `/webpages`,
					method: 'GET',
				};
			},
		}),
		getWebpage: builder.query<CmsWebpageResponse, CmsWebpageRequest>({
			query({ website = 'maia.insure', path }) {
				return {
					url: ``,
					method: 'GET',
					params: {
						website,
						...(path && { path }),
					},
				};
			},
			providesTags: (result, error, { path }) => {
				if (result) {
					return [{ type: 'webpage', id: path }];
				}

				/* istanbul ignore next */
				return ['webpage'];
			},
		}),
		getSitemap: builder.query<CmsSitemapPath[], CmsWebpageRequest>({
			query({ website = 'maia.insure', path }) {
				return {
					url: `/sitemap`,
					method: 'GET',
					params: {
						website,
						...(path && { path }),
					},
				};
			},
			providesTags: (result, error, { path }) => {
				if (result) {
					return [{ type: 'sitemap', id: path }];
				}

				/* istanbul ignore next */
				return ['sitemap'];
			},
		}),
		getGlobals: builder.query<CmsGlobals, CmsWebpageRequest>({
			query({ website = 'maia.insure', path }) {
				return {
					url: `/globals`,
					method: 'GET',
					params: {
						website,
						...(path && { path }),
					},
				};
			},
			transformResponse: (response: CmsContainer[]): CmsGlobals => {
				const navBarItems = response.find((container) => container.identifier === 'global_navigation') as CmsContainer,
					footerItems = response.find((container) => container.identifier === 'global_footer') as CmsContainer,
					subFooterItems = response.find((container) => container.identifier === 'global-subfooter') as CmsContainer,
					subFooterLinks = subFooterItems?.elements?.find(
						(element) => element.identifier === 'global-subfooter-links'
					) as CmsElement,
					subFooterSocials = subFooterItems?.elements?.find(
						(element) => element.identifier === 'global-subfooter-socials'
					) as CmsElement,
					subFooterProofs = subFooterItems?.elements?.find(
						(element) => element.identifier === 'global-subfooter-proof'
					) as CmsElement;

				return {
					navItems: navBarItems.elements.map(transformGlobalsToNavItems) || [],
					footerColumns: footerItems.elements.map(transformGlobalsToFooterColumns) || [],
					footerLinks: subFooterLinks.links || [],
					footerSocials: subFooterSocials.child_elements.map(transformGlobalsToSocialItems) || [],
					footerProofs: subFooterProofs.child_elements.map(transformGlobalsToSocialProof) || [],
				};
			},
			providesTags: () => {
				return ['globals'];
			},
		}),
	}),
	overrideExisting: true,
});

export const { useLazyGetContainersQuery, useGetGlobalsQuery, useGetContainersQuery } = cmsWebpagesApi;

export const { getWebpage, getWebpages, getGlobals } = cmsWebpagesApi.endpoints;

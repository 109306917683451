import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, hydrate } from '@common/store';

export type PlatformState = {
	isPortal: boolean | null;
	isDeveloperMenuOpen: boolean;
	isPreviewPageMenuShown: boolean;
	isDeveloperMenuEnabled: boolean;
};

const initialState: PlatformState = {
	isPortal: null,
	isDeveloperMenuOpen: false,
	isPreviewPageMenuShown: true,
	isDeveloperMenuEnabled: false,
};

export const platformSlice = createSlice({
	name: 'platform',
	initialState,
	reducers: {
		setPlatformState(state, action: PayloadAction<boolean>) {
			state.isPortal = action.payload;
		},
		resetPlatformState() {
			return initialState;
		},
		toggleDeveloperMenu(state) {
			state.isDeveloperMenuOpen = !state.isDeveloperMenuOpen;
		},
		togglePreviewPageMenu(state) {
			state.isPreviewPageMenuShown = !state.isPreviewPageMenuShown;
		},
		setIsDeveloperMenuEnabled(state, action: PayloadAction<boolean>) {
			state.isDeveloperMenuEnabled = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(hydrate, (state, action: PayloadAction<{ platform: PlatformState }>) => ({
			...state,
			...action.payload.platform,
		}));
	},
});

export const {
	setPlatformState,
	resetPlatformState,
	toggleDeveloperMenu,
	togglePreviewPageMenu,
	setIsDeveloperMenuEnabled,
} = platformSlice.actions;

export const selectPlatformState = (state: AppState) => state.platform;
export const selectIsDeveloperMenuOpened = (state: AppState) => state.platform.isDeveloperMenuOpen;
export const selectIsPreviewPageMenuShown = (state: AppState) => state.platform.isPreviewPageMenuShown;
export const selectIsDeveloperMenuEnabled = (state: AppState) => state.platform.isDeveloperMenuEnabled;

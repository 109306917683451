import { z } from 'zod';

import { TranslationType } from '@monorepo/types'; // Adjust the import path as needed

export const fteCalculatorSchema = (t: TranslationType) =>
	z.object({
		employees: z
			.array(
				z.object({
					num: z.coerce
						.number()
						.min(1, t('validation.min', { min: 1 }))
						.optional()
						.transform((value) => (typeof value === 'number' && !isNaN(value) ? value : 1))
						.nullable(),
					workingHours: z.coerce
						.number()
						.min(1, t('validation.min', { min: 1 }))
						.max(40, t('validation.max', { max: 40 }))
						.optional()
						.transform((value) => (typeof value === 'number' && !isNaN(value) ? value : 1))
						.nullable(),
				})
			)
			.nonempty(),
	});

export type FteCalculatorSchema = z.infer<ReturnType<typeof fteCalculatorSchema>>;

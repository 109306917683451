import { FC } from 'react';

import { BlogSectionSimpleCTA } from '@monorepo/components/Misc';
import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleArticleSiteSectionCTABlock = <P extends object>({ component }: HandlerProps): FC<P> => {
	const label = getProperty('label', component.custom_fields) as string;
	const href = getProperty('link', component.custom_fields) as string;
	const tagline = getProperty('tagline', component.custom_fields) as string;

	return () => <BlogSectionSimpleCTA label={label} href={href} tagLine={tagline} />;
};

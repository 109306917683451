import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { ButtonHTMLAttributes, FC } from 'react';

import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { BaseButton } from '../BaseButton/BaseButton';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
	label: string;
	iconLeft?: string;
	iconRight?: string;
};

export const DownloadButton: FC<Props> = ({ label, className = '', iconLeft, iconRight, ...props }) => {
	const { t } = useTranslation(['common']);
	return (
		<BaseButton
			title={t('common.downloadFile', { name: label })}
			className={cn(
				className,
				'bg-grayscale0 border-primaryMain text-primaryMain disabled:bg-grayscale50 disabled:text-grayscale400 active:enabled:bg-primary100 hover:enabled:bg-primary50 rounded-6 min-h-24 space-x-4 border px-12 py-8 md:px-12'
			)}
			{...props}>
			{iconLeft && <Icon size='xs' name={iconLeft} color='inherit' />}

			<Text variant='body-xs' as='span' color='inherit' weight='semibold'>
				{label}
			</Text>

			{iconRight && <Icon size='xs' name={iconRight} color='inherit' />}
		</BaseButton>
	);
};

import cn from 'classnames';
import { ComponentPropsWithoutRef, createElement, ElementType, PropsWithChildren } from 'react';

import { colors, ThemeTypographyWeight, typography, type ThemeColors, type ThemeTypography } from '@monorepo/types';

const typographyMap = {} as Record<ThemeTypography, string>;

typography.forEach((typography) => {
	typographyMap[typography] = `typography-${typography}`;
});

const colorMap = {} as Record<ThemeColors, string>;

colors.forEach((color) => {
	colorMap[color] = `text-${color}`;
});

const weightMap: Record<ThemeTypographyWeight, string> = {
	regular: 'font-light',
	medium: 'font-medium',
	semibold: 'font-semibold',
	bold: 'font-bold',
};

type TextProps<C extends ElementType> = {
	as?: C;
	variant?: ThemeTypography | 'custom';
	color?: ThemeColors | 'inherit';
	weight?: ThemeTypographyWeight;
};

export type TextElementProps<C extends ElementType> = PropsWithChildren<TextProps<C>> & ComponentPropsWithoutRef<C>;

export const Text = <C extends ElementType>({
	as,
	variant = 'body-m',
	color = 'grayscale600',
	weight = 'regular',
	className,
	...props
}: TextElementProps<C>) => {
	const colorClasses = color === 'inherit' ? '' : colorMap[color];
	const variantClasses = variant !== 'custom' ? typographyMap[variant] : null;
	const weightClasses = weightMap[weight];

	return createElement(as || 'div', {
		className: cn(variantClasses, colorClasses, weightClasses, className, 'break-words'),
		...props,
	});
};

import cn from 'classnames';
import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { TextButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { IAddonSection } from '@monorepo/types';

import { Modal } from '@common/components';
import { showModal } from '@common/store';
import { BlurbCard } from '../BlurbCard/BlurbCard';

type AddonSectionProps = IAddonSection & {
	className?: string;
};

export const AddonSection: FC<AddonSectionProps> = ({ title, subtitle, addons, className }) => {
	const dispatch = useDispatch();

	const openModal = (id: string) => {
		dispatch(showModal(id));
	};

	return (
		<section className={cn(className)}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 flex flex-col space-y-48'>
					<div className='flex flex-col space-y-12'>
						<Text variant='display-4' weight='semibold' color='primaryMain'>
							{title}
						</Text>
						<Text variant='display-6' weight='regular' color='primaryMain'>
							{subtitle}
						</Text>
					</div>
					<div className='grid grid-cols-1 gap-48 align-top md:grid-cols-2'>
						{addons.map((addon, key) => (
							<Fragment key={`addon-card-${key}`}>
								<BlurbCard
									enableEffects={false}
									className='col-span-full md:col-span-1'
									icon={addon.icon}
									title={addon.title}
									description={addon.description}
									action={
										<TextButton
											size='sm'
											label='Lees meer'
											className='w-fit'
											onClick={() => openModal(`addon-modal-${addon.title}`)}
										/>
									}
								/>
								<Modal id={`addon-modal-${addon.title}`} title={addon.title}>
									{addon.content}
								</Modal>
							</Fragment>
						))}
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { CaseBlock } from '@monorepo/components/TextComponents';
import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerProps } from '@cms/componentMapper/componentMapper';
import { mdComponents } from '../handleMarkDown/handleMarkDown';

export const handleBundleProducts = <P extends object>({ component }: HandlerProps): FC<P> => {
	return (props) => (
		<>
			{component.child_elements.map(({ guid, markdown, custom_fields }, index) => {
				const title = getProperty('title', custom_fields) as string;

				return (
					<CaseBlock key={`case-block-${guid}`} {...props} title={`${index + 1}. ${title}`}>
						<ReactMarkdown components={mdComponents}>{`${markdown}`}</ReactMarkdown>
					</CaseBlock>
				);
			})}
		</>
	);
};

import { INicheCtaSection } from '@monorepo/types';

export const mockNicheCtaSection: INicheCtaSection = {
	title: 'Verzekering op maat voor',
	niches: ['Makelaars', 'Kappers', 'Bouwvakkers', 'Schilders'],
	items: [
		{
			link: {
				href: '/vastgoed-en-taxatie',
				value: 'Vastgoed en taxatie',
				title: 'Vastgoed en taxatie',
			},
			image: {
				src: '/images/marketing/cta-search-background.png',
				alt: 'Vastgoed & taxatie',
			},
		},
		{
			link: {
				href: '/haar-en-beauty',
				value: 'Haar & beauty',
				title: 'Haar & beauty',
			},
			image: {
				src: '/images/marketing/cta-search-background.png',
				alt: 'Haar & beauty',
			},
		},
		{
			link: {
				href: '/marketing/afwerking-en-onderhoud',
				value: 'Afwerking & onderhoud',
				title: 'Afwerking & onderhoud',
			},
			image: {
				src: '/images/marketing/cta-search-background.png',
				alt: 'Afwerking & onderhoud',
			},
		},
		{
			link: {
				href: '/marketing/bouw-en-aannemers',
				value: 'Bouw & aannemers',
				title: 'Bouw & aannemers',
			},
			image: {
				src: '/images/marketing/cta-search-background.png',
				alt: 'Bouw & aannemers',
			},
		},
	],
};

import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { StandardButton, TextButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { slugify } from '@monorepo/shared/lib/utils';
import { MarketingFooterColumn } from '@monorepo/types';

import { openSupportChat } from '@support/store';
import { FooterColumn } from '../FooterColumn/FooterColumn';

export type TopFooterProps = {
	columns: MarketingFooterColumn[];
	isLoggedIn: boolean;
	className?: string;
};

type AddressProps = {
	className?: string;
};

const Address: FC<AddressProps> = ({ className }) => {
	return (
		<Text as='p' color='grayscale300' variant='body-s' className={cn(className, 'py-8')}>
			Stationsplein 45, 3013 AK Rotterdam
		</Text>
	);
};

export const TopFooter: FC<TopFooterProps> = ({ columns, isLoggedIn, className }) => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();

	const handleChatClick = () => {
		dispatch(openSupportChat());
	};

	return (
		<div className={cn(className, 'bg-grayscale600 pb-24 pt-40 md:py-48')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 grid grid-cols-2 gap-40 md:grid-cols-4'>
					<div className='col-span-1 flex flex-row items-center gap-48 md:col-span-2'>
						<StandardButton
							variant='secondary'
							title={t('common.chat')}
							className='!text-grayscale300 !border-grayscale300 hover:enabled:!border-grayscale200 hover:enabled:!text-grayscale200 active:enabled:!border-grayscale100 active:enabled:!text-grayscale100 bg-transparent hover:active:!bg-transparent hover:enabled:!bg-transparent max-sm:!w-full'
							iconLeft='comment-lines'
							label={t('common.chat')}
							onClick={handleChatClick}
						/>

						<Address className='flex-1 max-md:hidden' />
					</div>
					<div className='col-span-1 flex items-center max-md:justify-end md:col-start-4'>
						<Link
							href={isLoggedIn ? '/portaal/overzicht' : '/inloggen'}
							title={t(isLoggedIn ? 'common.portal' : 'common.login')}>
							<TextButton
								className='!text-grayscale0 max-sm:!w-full'
								iconLeft='user-circle'
								label={t(isLoggedIn ? 'common.portal' : 'common.login')}
								size='sm'
							/>
						</Link>
					</div>
				</div>

				<div className='col-start-2 mt-48 flex flex-col space-y-40 md:mt-40'>
					<div className='grid grid-cols-2 gap-x-40 gap-y-12 md:grid-cols-4'>
						{columns.map((column) => (
							<FooterColumn
								className='col-span-full md:col-span-1'
								key={`footer-column-${slugify(column.title)}`}
								{...column}
							/>
						))}
					</div>
					<div className='md:hidden'>
						<Address />
					</div>
				</div>
			</LayoutGrid>
		</div>
	);
};

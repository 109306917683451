import cn from 'classnames';
import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { getProperty } from '@monorepo/shared/lib/cms';
import { ITextImageSectionVariant, TextImageChecklistImagePosition } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { TextImageSection } from '@marketing/components';

const Paragraph = (props: ReactMarkdownProps) => <p color='inherit' {...props} />;

export const mdComponents: Components = {
	p: Paragraph,
};

export const handleTextImage = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const [element] = elements;
	const title = getProperty('title', element.custom_fields),
		subtext = getProperty('subtext', element.custom_fields),
		imagePosition: TextImageChecklistImagePosition =
			(getProperty('image-position', element.custom_fields) as TextImageChecklistImagePosition) || 'left',
		[link] = element.links,
		[image] = element.media;

	const variant: ITextImageSectionVariant = rootContext?.path?.includes('landing/') ? 'inverted' : 'default';

	const Content = () => (
		<div className='space-y-16'>
			<ReactMarkdown components={mdComponents}>{`${subtext}`}</ReactMarkdown>
		</div>
	);

	return (props) => (
		<TextImageSection
			{...props}
			className={cn(rootContext?.path?.includes('over-maia') && 'bg-primary50')}
			title={`${title}`}
			content={<Content />}
			link={link}
			image={image}
			variant={variant}
			imagePosition={imagePosition}
		/>
	);
};

import cn from 'classnames';
import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import { getProperty } from '@monorepo/shared/lib/cms';
import { SwagItem } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { SwagSection } from '@marketing/components';

const Paragraph = (props: ReactMarkdownProps) => <>{props.children}</>;
const highlight = (props: ReactMarkdownProps) => <span className='font-bold' {...props} />;

const mdComponents: Components = {
	p: Paragraph,
	strong: highlight,
};

export const handleSectionSwag = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const items: SwagItem[] = elements.map((item, index) => {
		const value = getProperty('value', item.custom_fields),
			prefix = getProperty('prefix', item.custom_fields),
			suffix = getProperty('suffix', item.custom_fields),
			content = getProperty('content', item.custom_fields);

		const Content = () => <ReactMarkdown components={mdComponents}>{`${content}`}</ReactMarkdown>;

		return {
			value: +`${value}`,
			prefix,
			suffix,
			content: <Content />,
			sequence: index,
		};
	});

	return (props) => (
		<SwagSection className={cn(rootContext?.path === 'werkvelden' && 'shadow-1')} {...props} items={items} />
	);
};

import { motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC, useId } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { ActionButton, Toggle } from '@monorepo/components/DataEntry';
import { Icon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { getHomeReactSelectClassNames, salesChannels } from '@monorepo/shared/lib/utils';
import { ThemeNames } from '@monorepo/types';

import {
	selectIsPreviewPageMenuShown,
	selectTheme,
	setTheme,
	toggleDeveloperMenu,
	togglePreviewPageMenu,
} from '@common/store';

export const DeveloperMenu: FC = () => {
	const dispatch = useDispatch();
	const instanceId = useId();
	const { pathname } = useRouter();
	const activeTheme = useSelector(selectTheme);
	const isPreviewPageMenuShown = useSelector(selectIsPreviewPageMenuShown);
	const isPreviewPage = pathname.includes('preview');

	return (
		<motion.div
			className='bg-primaryMain text-grayscale0 rounded-12 shadow-primaryMain shadow-3 fixed left-1/2 top-1/2 z-[99999] min-w-[350px] cursor-move rounded'
			initial={{ x: '-50%', y: '-50%' }}
			drag
			dragMomentum={false}
			whileHover={{ scale: 1.05 }}>
			{/* Header */}
			<div className='flex cursor-grab items-center justify-between p-20'>
				<Text variant='body-l' color='grayscale0' weight='semibold' className='flex-1 cursor-grab'>
					Developer Menu
				</Text>
				<ActionButton icon='times' onClick={() => dispatch(toggleDeveloperMenu())} />
			</div>

			{/* Developer tools */}
			<div className='cursor-auto space-y-16 px-20 pb-20'>
				<div className='bg-grayscale0 rounded-12 flex flex-1 items-center justify-between py-8 pl-16'>
					<div className='flex items-center space-x-8'>
						<div className='bg-primary50 rounded-12 flex h-[32px] w-[32px] items-center justify-center'>
							<Icon name='palette' color='primaryMain' />
						</div>
						<Text variant='body-m' weight='semibold' color='grayscale600'>
							Theme
						</Text>
					</div>
					<div>
						<Select
							className='w-full'
							instanceId={instanceId}
							formatOptionLabel={(theme) => (
								<div className='maia-ag-option flex h-full flex-row items-center'>
									<Image width={24} height={24} src={theme.icon as string} sizes='100%' alt={theme.label as string} />
								</div>
							)}
							value={salesChannels.find((t) => t.value === activeTheme) ?? undefined}
							unstyled
							isSearchable={false}
							classNames={getHomeReactSelectClassNames()}
							onChange={(select) => dispatch(setTheme(select?.value as unknown as ThemeNames))}
							options={salesChannels}
						/>
					</div>
				</div>
				{isPreviewPage && (
					<div className='bg-grayscale0 rounded-12 flex flex-1 items-center justify-between py-8 pl-16'>
						<div className='flex items-center space-x-8'>
							<div className='bg-primary50 rounded-12 flex h-[32px] w-[32px] items-center justify-center'>
								<Icon name='eye' color='primaryMain' />
							</div>
							<Text variant='body-m' weight='semibold' color='grayscale600'>
								Show preview nav
							</Text>
						</div>
						<div className='pr-12'>
							<Toggle
								variant='onOff'
								isToggled={isPreviewPageMenuShown}
								onChange={() => dispatch(togglePreviewPageMenu())}
							/>
						</div>
					</div>
				)}
			</div>
		</motion.div>
	);
};

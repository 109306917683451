import { useTranslation } from 'next-i18next';

import { BillingUnits, CalculationProduct, CalculationProductOptionSpecWithGuid } from '@monorepo/types';

import { isSameSpec, isSameSpecWithoutValue, sortAscending, transformToCurrency } from '../../utils';

export const useRenderFormControl = (product: CalculationProduct, displaySalesChannel: string) => {
	const { t } = useTranslation(['common']);
	const activeOption = product.options.find((option) => option.is_active);

	const getPriceDifference = (activePrice: number, otherPrice: number, billingUnit: BillingUnits): string => {
		const priceDifference = otherPrice - activePrice;
		const sign = priceDifference > 0 ? '+' : priceDifference < 0 ? '-' : '';
		return priceDifference === 0
			? transformToCurrency(activePrice)
			: `${sign} ${transformToCurrency(Math.abs(priceDifference))} ${t(
					billingUnit === 'monthly' ? 'common.perMonthShort' : 'common.perYearShort'
				)}`;
	};

	const getRadioTagPrice = (
		specs: CalculationProductOptionSpecWithGuid[],
		billingUnit: BillingUnits = 'monthly'
	): string => {
		const isAllCyberSystemFailure = specs.every(({ reference }) => reference === 'additional_cyber_system-failures');

		if (isAllCyberSystemFailure) {
			const baseAnswerGuid = specs.find(({ value }) => value === 'N')?.option_guid;
			const baseOption = product.options.find(({ guid }) => guid === baseAnswerGuid);

			const otherAnswerGuid = specs.find(({ value }) => value === 'J')?.option_guid;
			const otherOption = product.options.find(({ guid }) => guid === otherAnswerGuid);

			if (!baseOption || !otherOption) return '';

			return getPriceDifference(baseOption.price.premium_after_tax, otherOption.price.premium_after_tax, billingUnit);
		}

		const optionBasedOnSpec = activeOption?.specs.map(
			(spec) => specs.find((_spec) => isSameSpecWithoutValue(spec, _spec)) || spec
		);

		const findOptionThatEqualsNewOptionSpecs = product.options.find((option) =>
			option.specs.every((spec) => optionBasedOnSpec?.some((_spec) => isSameSpec(spec, _spec)))
		);

		const activeDeductible = findOptionThatEqualsNewOptionSpecs?.specs.find((spec) => spec.is_deductible);

		const otherPrice = findOptionThatEqualsNewOptionSpecs?.price.premium_after_tax;

		const basePrice = product.options
			.filter((option) => option.specs.some((spec) => spec.is_deductible && spec.value === activeDeductible?.value))
			.sort((a, b) => sortAscending(a.price.premium_after_tax, b.price.premium_after_tax));

		if (!otherPrice || !basePrice[0]) return '';

		return getPriceDifference(basePrice[0].price.premium_after_tax, otherPrice, billingUnit);
	};

	const getRadioLabel = (specs: CalculationProductOptionSpecWithGuid[]): string => {
		const insuredAmountDefault = specs.find((spec) => spec.is_insured_amount && spec.insured_amount_type === 'default');
		const insuredAmountCase = specs.find((spec) => spec.is_insured_amount && spec.insured_amount_type === 'case');
		const insuredAmountYear = specs.find((spec) => spec.is_insured_amount && spec.insured_amount_type === 'year');

		if (insuredAmountCase && insuredAmountYear) {
			return t('common.perClaimPerYear', {
				amountPerClaim: transformToCurrency(insuredAmountCase.value),
				amountPerYear: transformToCurrency(insuredAmountYear.value),
			});
		}

		if (insuredAmountDefault) {
			return t('common.to', {
				amount: transformToCurrency(insuredAmountDefault?.value),
			});
		}

		return t('common.to', {
			amount: transformToCurrency(insuredAmountCase?.value),
		});
	};

	const getRadioSubText = (specs: CalculationProductOptionSpecWithGuid[]): string | undefined => {
		if (specs.length === 0) return undefined;

		const insuredAmountCase = specs.find((spec) => spec.is_insured_amount && spec.insured_amount_type === 'case');
		const insuredAmountYear = specs.find((spec) => spec.is_insured_amount && spec.insured_amount_type === 'year');

		// Currently we only show a subtext on these cases
		if (insuredAmountCase && insuredAmountYear) {
			const labels: Record<string, string> = {
				'500000': t('components.productForm.insuredAmounts.minimum', {
					label: displaySalesChannel,
				}),
				'1250000': t('components.productForm.insuredAmounts.extra'),
				'2500000': t('components.productForm.insuredAmounts.maximum'),
			};

			return labels[insuredAmountCase.value];
		}

		return undefined;
	};

	return {
		activeOption,
		isSameSpec,
		getRadioTagPrice,
		getRadioLabel,
		getRadioSubText,
	};
};

import { GetUbosResponse } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

const uboApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getUbos: builder.query<GetUbosResponse, void>({
			query() {
				return {
					url: `/ubo`,
					method: 'GET',
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetUbosQuery } = uboApi;
export const { getUbos } = uboApi.endpoints;

export const mockHeroWithScrollingImage = {
	pretext: 'Regel direct je',
	title: 'Aansprakelijkheidsverzekering',
	subtext: 'Bescherming voor jouw passie en vak',
	priceFrom: 94.99,
	content: 'content',
	link: {
		title: 'Stel nu samen',
		href: '/aanvragen/bedrijf/zoeken',
		value: 'Stel nu samen',
		content: 'Stel nu samen',
		target: undefined,
	},
	items: [
		{ content: 'Wereldwijd verzekerd tot wel € 2.500.000' },
		{ content: 'Ook brand schade' },
		{ content: 'Met of zonder eigen risico' },
	],
	images: [
		{
			src: '/images/marketing/cta-search-background.png',
			alt: 'alt-1',
		},
		{
			src: '/images/marketing/cta-search-background.png',
			alt: 'alt-2',
		},
		{
			src: '/images/marketing/cta-search-background.png',
			alt: 'alt-3',
		},
		{
			src: '/images/marketing/cta-search-background.png',
			alt: 'alt-4',
		},
		{
			src: '/images/marketing/cta-search-background.png',
			alt: 'alt-5',
		},
		{
			src: '/images/marketing/cta-search-background.png',
			alt: 'alt-6',
		},
		{
			src: '/images/marketing/cta-search-background.png',
			alt: 'alt-7',
		},
		{
			src: '/images/marketing/cta-search-background.png',
			alt: 'alt-8',
		},
		{
			src: '/images/marketing/cta-search-background.png',
			alt: 'alt-9',
		},
		{
			src: '/images/marketing/cta-search-background.png',
			alt: 'alt-10',
		},
	],
	tagline: 'Binnen 5 minuten geregeld',
};

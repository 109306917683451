import cn from 'classnames';
import parse from 'html-react-parser';
import { FC, ReactNode } from 'react';

import { ThemeColors } from '@monorepo/types';

import { Icon, IconSizes } from '../../../Misc';
import { SubtitleAndText, Text } from '../../TextComponents';
import { List } from '../List/List';

export type CheckItem = {
	content: string | ReactNode;
	included: boolean;
	sequence?: number;
};

type CheckItemProps = {
	item: CheckItem;
	color: ThemeColors | 'inherit';
	size?: IconSizes;
};

export const CheckItem: FC<CheckItemProps> = ({ item, color, size = 'md' }) => {
	return (
		<Text as='li' variant='body-m' className='flex flex-row items-center' color={color}>
			<Icon
				size={size}
				className={cn('mr-12 self-start')}
				name={item.included ? 'check' : 'times'}
				color={item.included ? 'success300' : 'error300'}
			/>
			<div>{typeof item.content === 'string' ? parse(item.content) : item.content}</div>
		</Text>
	);
};

export type CheckListProps = {
	title?: string;
	items?: CheckItem[];
	content?: ReactNode;
	color?: ThemeColors | 'inherit';
	className?: string;
};

export const CheckList: FC<CheckListProps> = ({ title, items, color = 'grayscale600', className = '' }) => {
	return (
		<div className='flex flex-col space-y-12'>
			{title && <SubtitleAndText title={title} />}

			<List className={className}>
				{items && items.map((item, index) => <CheckItem color={color} key={`checklist-item-${index}`} item={item} />)}
			</List>
		</div>
	);
};

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAnalytics } from '@monorepo/shared/lib/hooks';
import { Bundle, BundleMappedToProducts, CalculationProduct, CalculationProductOption } from '@monorepo/types';

import { paths } from '@common/utils';
import {
	selectCartCalculationTypes,
	useGetBundlesQuery,
	useGetCartCalculationsFormsQuery,
	useGetCartCalculationsQuery,
	useGetCartCalculationStateQuery,
	useGetInsuranceDocsQuery,
	useToggleBundleMutation,
} from '@funnel/store';
import { mapBundlesToContent } from '@funnel/utils';

const pagesWithProducts = [
		'/compose',
		'/samenstellen',
		'preconditions',
		'acceptatie',
		'finalize',
		'afronden',
		'conclusion',
		'slot',
	],
	toIgnoreProductPages = ['/profile', '/profiel'];

export const useProducts = (cartGuid: string) => {
	const { pathname, push } = useRouter();

	const [isCalculated, setIsCalculated] = useState<boolean>(false);
	const [toggleBundleMutation, toggleBundleMutationResponse] = useToggleBundleMutation();
	const [chosenBundleGuid, setChosenBundleGuid] = useState<string>();
	const { resetEcommerce, trackEcommerceEvent } = useAnalytics();

	const calculationTypes = useSelector(selectCartCalculationTypes),
		shouldFetchBundles = calculationTypes?.bundles;

	// Check if the current pathname includes any of the pagesWithProducts
	const isPathnameInPages = pagesWithProducts.some((page) => pathname.includes(page));
	const ignoreCalculations = toIgnoreProductPages.some((page) => pathname.includes(page));

	const isConsumer = pathname.includes('/particulier');

	const { data: calculationState } = useGetCartCalculationStateQuery(
		!isConsumer && cartGuid ? (cartGuid as string) : skipToken,
		{
			pollingInterval: 1000,
			skip: isCalculated || !isPathnameInPages,
		}
	);

	const {
		data: bundleResponse = [] as Bundle[],
		isLoading: bundlesIsLoading,
		isFetching: bundlesIsFetching,
		refetch: refetchBundles,
		isUninitialized: isUninitializedBundles,
	} = useGetBundlesQuery(cartGuid && !isConsumer ? (cartGuid as string) : skipToken, {
		skip: !calculationState?.is_calculated || !shouldFetchBundles || ignoreCalculations,
	});

	const {
		data: calculations = [],
		isLoading: calculationsIsLoading,
		isFetching: calculationsIsFetching,
		isUninitialized,
		refetch: refetchCalculations,
	} = useGetCartCalculationsQuery(cartGuid && !isConsumer ? (cartGuid as string) : skipToken, {
		skip: !calculationState?.is_calculated || ignoreCalculations,
	});

	const {
		data: calculationsForms = [],
		isLoading: calculationsFormsIsLoading,
		isFetching: calculationsFormsIsFetching,
		isUninitialized: calculationsFormsUninitialized,
		refetch: refetchCalculationsForms,
	} = useGetCartCalculationsFormsQuery(cartGuid, {
		skip: !calculationState?.is_calculated || ignoreCalculations,
	});

	const bundles: BundleMappedToProducts[] = useMemo(
		() => mapBundlesToContent(bundleResponse, calculations),
		[bundleResponse, calculations]
	);

	const { refetch: refetchDocs, isUninitialized: isUninitializedDocs } = useGetInsuranceDocsQuery(
		cartGuid && cartGuid !== 'undefined' ? (cartGuid as string) : skipToken,
		{
			skip: !calculationState?.is_calculated || ignoreCalculations,
		}
	);

	const handleEventTracking = (bundle: BundleMappedToProducts) => {
		const items = bundle.mappedProducts.map((product: CalculationProduct) => {
			const activeOption = product.options.find((option) => option.is_active) as CalculationProductOption;

			return {
				item_id: activeOption?.code,
				item_name: activeOption?.name,
				item_brand: product?.insurer,
				price: activeOption?.price.premium_after_tax,
				discount: activeOption?.price?.discount,
				...(activeOption?.price?.promotion && {
					coupon: `${activeOption?.price?.promotion}`,
				}),
			};
		});

		resetEcommerce();

		trackEcommerceEvent('add_to_cart', {
			value: bundle.minimum_price,
			transaction_id: bundle?.guid,
			items,
		});
	};

	const handleToggleBundle = async (bundle_guid: string) => {
		setChosenBundleGuid(bundle_guid);

		await toggleBundleMutation({
			bundle_guid,
			cart_guid: cartGuid,
			state: true,
		}).then(() => handleEventTracking(bundles.find((bundle) => bundle.guid === bundle_guid) as BundleMappedToProducts));

		setChosenBundleGuid(undefined);

		await push({
			pathname: paths.funnel.compose.configure_bundle,
			query: { cartGuid },
		});
	};

	useEffect(() => {
		setIsCalculated(calculationState?.is_calculated || false);

		// Handle products, refetching, etc
		if (calculationState?.is_calculated && !isUninitialized) {
			refetchCalculations();

			if (!isUninitializedDocs) {
				refetchDocs();
			}

			if (shouldFetchBundles && !isUninitializedBundles) {
				refetchBundles();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calculationState?.is_calculated]);

	return {
		bundles,
		calculations,
		isCalculated,
		isLoading: bundlesIsLoading || calculationsIsLoading,
		isFetching: bundlesIsFetching || calculationsIsFetching,
		isLoadingMutation: toggleBundleMutationResponse.isLoading || toggleBundleMutationResponse.isSuccess,
		handleToggleBundle,
		chosenBundleGuid: chosenBundleGuid,
		calculationsForms,
		calculationsFormsIsLoading,
		calculationsFormsIsFetching,
		calculationsFormsUninitialized,
		refetchCalculationsForms,
	};
};

import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { HeaderSection, HeroAlignedLeft } from '@marketing/components';

export const handleSectionTitleSubtext = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const titleSubTextElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const title = getProperty('title', titleSubTextElement.custom_fields);
	const subtext = getProperty('subtext', titleSubTextElement.custom_fields);

	return (props) =>
		rootContext?.path === 'contact' ? (
			<HeroAlignedLeft title={`${title}`} subtitle={`${subtext}`} />
		) : (
			<HeaderSection {...props} title={`${title}`} variant='simple' />
		);
};

import { GetBundleResponse, ToggleBundleRequest, ToggleBundleResponse } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const bundlesApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getBundles: builder.query<GetBundleResponse, string>({
			query(cart_guid) {
				return {
					url: `/carts/${cart_guid}/bundles`,
					method: 'GET',
				};
			},
			providesTags: ['Bundles'],
		}),
		toggleBundle: builder.mutation<ToggleBundleResponse, ToggleBundleRequest>({
			query({ cart_guid, bundle_guid, state }) {
				return {
					url: `/carts/${cart_guid}/bundles/${bundle_guid}/toggle-products`,
					method: 'PUT',
					body: { state },
				};
			},
			invalidatesTags: ['Bundles', 'Cart', 'Calculations'],
		}),
	}),
	overrideExisting: true,
});

export const { useGetBundlesQuery, useToggleBundleMutation } = bundlesApi;

export const { getBundles, toggleBundle } = bundlesApi.endpoints;

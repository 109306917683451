import { FC } from 'react';

import { Skeleton } from '@monorepo/components/Misc';

export const ModalBodySkeleton: FC = () => (
	<div className='space-y-8'>
		<Skeleton variant='text' size='body-m' className='w-9/12' />
		<Skeleton variant='text' size='body-m' className='w-7/12' />
		<Skeleton variant='text' size='body-m' className='w-3/12' />
		<Skeleton variant='text' size='body-m' className='w-1/12' />
		<Skeleton variant='text' size='body-m' className='w-6/12' />
		<Skeleton variant='text' size='body-m' className='w-5/12' />
		<Skeleton variant='text' size='body-m' className='w-3/12' />
	</div>
);

import Head from 'next/head';
import { type FC } from 'react';

export const HeadNKC: FC = () => (
	<Head>
		<link rel='apple-touch-icon' sizes='180x180' href='/label/nkc/apple-touch-icon.png' />
		<link rel='icon' href='/label/nkc/favicon.ico' sizes='any' />
		<link rel='shortcut icon' href='/label/nkc/favicon.ico' sizes='any' />
		<link rel='icon' type='image/png' sizes='32x32' href='/label/nkc/favicon-32x32.png' />
		<link rel='icon' type='image/png' sizes='16x16' href='/label/nkc/favicon-16x16.png' />
		<link rel='mask-icon' href='/label/nkc/safari-pinned-tab.svg' color='#0a5ad2' />
		<link rel='manifest' href='/label/nkc/manifest.json' />

		<meta name='msapplication-TileColor' content='#0a5ad2' />
		<meta name='theme-color' content='#0a5ad2' />
		<meta name='msapplication-config' content='/label/nkc/browserconfig.xml' />
	</Head>
);

import { InsuranceDocs } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const insuranceDocsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getInsuranceDocs: builder.query<InsuranceDocs, string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/documents`,
					method: 'GET',
				};
			},
			providesTags: (result, params, guid) => {
				if (result && guid) {
					return [{ type: 'Documents', id: guid }];
				}

				/* istanbul ignore next */
				return ['Documents'];
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetInsuranceDocsQuery } = insuranceDocsApi;

export const { getInsuranceDocs } = insuranceDocsApi.endpoints;

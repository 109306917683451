import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { D_DATE_FORMAT } from '../utils';

export const newClaimSchema = (t: TranslationType) => {
	return z
		.object({
			claim_date: z.coerce.string({ required_error: t('validation.required') }),
			insurance_guid: z.string({ required_error: t('validation.required') }).nullable(),
			industrial_classification_guid: z.string().optional().nullable(),
		})
		.superRefine((data, ctx) => {
			if (!data.claim_date) {
				const parsedDate = new Date(data.claim_date);
				const todayDate = new Date();
				todayDate.setHours(0, 0, 0, 0);

				if (!isNaN(parsedDate.getTime()) && parsedDate <= todayDate) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t('validation.formatSuggestion', { format: D_DATE_FORMAT.toLowerCase() }),
					});
				}
			}
		});
};

export const newClaimStep2 = (t: TranslationType) => {
	return z.object({
		claimed_amount: z.coerce
			.number()
			.transform((value) => (isNaN(value) ? 0 : value))
			.nullable(),
		cause_description: z.string({ required_error: t('validation.required') }).nullable(),
		detailed_cause_guid: z.string().optional().nullable(),
		detailed_cause_description: z.string().optional().nullable(),
		subject: z.string().optional().nullable(),
		attachments: z
			.array(
				z.object({
					filename: z.string().min(1, t('validation.required')),
					mimetype: z.string().min(1, t('validation.required')),
					encoded_document: z.string().min(1, t('validation.required')),
				})
			)
			.optional(),
	});
};

export type NewClaimStep1Schema = z.infer<ReturnType<typeof newClaimSchema>>;
export type NewClaimStep2Schema = z.infer<ReturnType<typeof newClaimStep2>>;
export type NewClaimSchema = NewClaimStep1Schema & NewClaimStep2Schema;
export type NewClaimKey = Partial<keyof NewClaimSchema>;

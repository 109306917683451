import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';

import { getOrganization } from '@portal/store';
import { getContact } from './getFirstContactApi';

export const rootApi = createApi({
	reducerPath: 'rootApi',
	baseQuery: async (args: FetchArgs, api, extraOptions) => {
		// Get the current state
		const state = api?.getState();

		// @ts-ignore Typescript issue
		const isPortalRequest = state?.platform?.isPortal;

		// @ts-ignore Typescript issue
		const accountGuid = state?.account?.guid;

		if (!accountGuid && isPortalRequest) {
			await api.dispatch(getContact.initiate()).unwrap();
		}

		if (isPortalRequest && args.url.includes('%%organizationGuid%%')) {
			const organization = await api.dispatch(getOrganization.initiate()).unwrap();

			args.url = args.url.replace('%%organizationGuid%%', organization.guid);
		}

		// Make the actual API call
		return fetchBaseQuery({
			baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/${
				isPortalRequest ? 'portal' : 'prospect'
			}`,
			prepareHeaders: (headers, api) => {
				// @ts-ignore Typescript issue
				const token: string = api.getState().account?.idToken;

				if (token && isPortalRequest) {
					headers.set(`Userauthorization`, `Bearer ${token}`);
				}

				return headers;
			},
		})(args, api, extraOptions);
	},
	tagTypes: [
		'AcceptanceState',
		'AcceptanceQuestion',
		'Bundles',
		'Cart',
		'Calculations',
		'CalculationState',
		'Checkout',
		'Claim',
		'ClaimableProduct',
		'Documents',
		'Invoice',
		'Certificate',
		'Claims',
		'Classifications',
		'Contact',
		'CurrentInsurance',
		'Email',
		'HistoricalClaim',
		'Organization',
		'Overview',
		'PremiumQuestion',
		'Profile',
		'RequestContact',
		'TransferableProduct',
		'Approval',
		'ObjectDetails',
		'Stakeholders',
		'Messages',
		'CheckupAddress',
		'CalcuationsForm',
		'Recalculation',
		'Ubos',
	],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: () => ({}),
});

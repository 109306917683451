import {
	Classification,
	ClassificationForCart,
	UpdateOrganizationClassificationsRequest,
	UpdateOrganizationClassificationsResponse,
} from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const classificationsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getOrganizationClassificationsNested: builder.query<Classification[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/classifications/nested`,
					method: 'GET',
				};
			},
			providesTags: ['Classifications'],
		}),
		getOrganizationClassifications: builder.query<ClassificationForCart[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/classifications`,
					method: 'GET',
				};
			},
			providesTags: ['Classifications'],
		}),
		updateOrganizationClassifications: builder.mutation<
			UpdateOrganizationClassificationsResponse,
			UpdateOrganizationClassificationsRequest
		>({
			query({ cart_guid, classifications }) {
				return {
					url: `/carts/${cart_guid}/classifications`,
					method: 'PUT',
					body: { classifications },
				};
			},
			invalidatesTags: ['PremiumQuestion', 'Classifications', 'Organization'],
		}),
	}),
	overrideExisting: true,
});

export const {
	useUpdateOrganizationClassificationsMutation,
	useGetOrganizationClassificationsNestedQuery,
	useGetOrganizationClassificationsQuery,
} = classificationsApi;

export const { getOrganizationClassifications, updateOrganizationClassifications } = classificationsApi.endpoints;

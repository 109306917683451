import { FC } from 'react';

import { Text } from '@monorepo/components/TextComponents';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleTagLine = <P extends object>({ component }: HandlerProps): FC<P> => {
	return (props) => (
		<Text {...props} variant='body-s' color='grayscale400'>
			{`${component.markdown}`}
		</Text>
	);
};

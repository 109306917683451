import { ITestimonialSingleSliderWithVideo } from '@monorepo/types';

export const mockTestimonialSingleSliderWithVideo: ITestimonialSingleSliderWithVideo = {
	title: 'Geliefd door duizenden',
	subtitle: 'Hoor wat enkele van onze tevreden klanten te zeggen hebben…',
	video: {
		path: '/images/marketing/video.mp4',
		alt: 'hero-desktop-image',
	},
	thumbnail: {
		path: '/images/marketing/video-thumbnail.png',
		alt: 'hero-desktop-image',
	},

	testimonials: [
		'1 Wat een geweldig team heeft Maia voor zijn klantenservice. Mijn claim was binnen 10minuten online gemeld en kreeg direct een bevestiging. Kortom, heel happy',
		'2 Wat een geweldig team heeft Maia voor zijn klantenservice. Mijn claim was binnen 10minuten online gemeld en kreeg direct een bevestiging. Kortom, heel happy',
		'3 Wat een geweldig team heeft Maia voor zijn klantenservice. Mijn claim was binnen 10minuten online gemeld en kreeg direct een bevestiging. Kortom, heel happy',
	],
};

import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';
import { PortalAccount } from '@monorepo/types';

// This API consists of an endpoint which should be called on the first portal page load to get the first contact
// Has to be an independant API
export const getFirstContactApi = createApi({
	reducerPath: 'getFirstContact',
	refetchOnMountOrArgChange: true,
	baseQuery: async (args: FetchArgs, api, extraOptions) => {
		const state = api.getState() as { account: { idToken: string | null } }; // Type this properly based on your state

		if (!state.account.idToken) {
			return Promise.reject('No ID token');
		}

		return fetchBaseQuery({
			baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/portal`,
			prepareHeaders: (headers) => {
				const token: string | null = state.account?.idToken;

				if (token) {
					headers.set('Userauthorization', `Bearer ${token}`);
				}

				return headers;
			},
		})(args, api, extraOptions);
	},
	tagTypes: ['Organization', 'Customer'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		getContact: builder.query<PortalAccount, void>({
			query() {
				return {
					url: `/account`,
					method: 'GET',
				};
			},
			providesTags: ['Customer', 'Organization'],
		}),
	}),
});

export const { useGetContactQuery } = getFirstContactApi;

export const { getContact } = getFirstContactApi.endpoints;

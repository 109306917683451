import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { FC, ReactNode } from 'react';

import { TextButton } from '@monorepo/components/DataEntry';
import { Tab } from '@monorepo/components/Headless/Tab/Tab';
import { LayoutGrid } from '@monorepo/components/Misc';

import { useListCmsProductsQuery } from '@cms/store';
import { InsuranceOverviewCard } from '@marketing/components/InsuranceOverviewCard/InsuranceOverviewCard';
import { InsuranceOverviewCardSkeleton } from '../InsuranceOverviewCardSkeleton/InsuranceOverviewCardSkeleton';
import { panelContentTransition, panelTransition, TabbedOverview } from '../TabbedOverview';

type Props = {
	ctaSlotsTop?: ReactNode[];
	ctaSlotsBottom?: ReactNode[];
	path?: string;
	showNicheLink?: boolean;
	className?: string;
};

export const DynamicTabbedOverview: FC<Props> = ({
	ctaSlotsTop,
	ctaSlotsBottom,
	path,
	showNicheLink = true,
	className,
}) => {
	const { data: productsData = [], isLoading } = useListCmsProductsQuery({
		path: path?.includes('werkvelden') ? path : undefined,
	});

	return (
		<section
			className={cn(
				className,
				'before:bg-primary50 relative pb-64 before:absolute before:left-0 before:right-0 before:top-0 before:-z-10 before:h-[170px] md:pb-48'
			)}>
			<LayoutGrid as='div' layout='marketing' gutter='48'>
				<TabbedOverview
					menuLinks={productsData.map(({ icon_name, description }) => ({ icon: icon_name, description }))}
					isLoading={isLoading}
					skeletonProps={{
						includeTopSlot: path?.includes('werkvelden'),
						skeleton: <InsuranceOverviewCardSkeleton />,
					}}>
					{({ isAnimatedOnce }) => (
						<>
							{productsData?.map(({ products, workfield_guid, link: nicheLink }, key) => (
								<Tab.Panel
									as={motion.div}
									variants={panelTransition}
									initial={isAnimatedOnce ? 'hidden' : 'show'}
									exit='hidden'
									animate='show'
									className='grid grid-cols-2 gap-24 md:gap-48'
									key={`support-faq-section-tab-panel-${key}`}>
									{({ selected, selectedIndex }) => {
										const ctaSlotTop = ctaSlotsTop?.find(
											// @ts-ignore - temporary evil
											(element) => element && element?.props.workfieldGuid === workfield_guid
										);

										const ctaSlotBottom = ctaSlotsBottom?.find(
											// @ts-ignore - temporary evil
											(element) => element && element?.props.workfieldGuid === workfield_guid
										);

										return (
											<AnimatePresence>
												{selected && (
													<>
														{ctaSlotTop && (
															<motion.div
																key={`dynamic-product-cta-tab-panel-${key}-content-${selectedIndex}`}
																variants={panelContentTransition}
																className='col-span-full'>
																{ctaSlotTop}
															</motion.div>
														)}
														{products.map(
															({ minimum_price, guid, icon_name, name, detailed_description, link }, key2) => {
																return (
																	<motion.div
																		key={`dynamic-product-section-tab-panel-${key}-content-${key2}`}
																		variants={panelContentTransition}
																		className='col-span-full md:col-span-1'>
																		<InsuranceOverviewCard
																			minimumPrice={minimum_price}
																			icon={icon_name}
																			title={name}
																			description={detailed_description}
																			link={link}
																			key={`dynamic-product-${guid}`}
																		/>
																	</motion.div>
																);
															}
														)}

														{ctaSlotBottom && <div className='col-span-full'>{ctaSlotBottom}</div>}

														{showNicheLink && nicheLink && nicheLink.href !== '/home' && (
															<div className='col-span-full flex justify-center'>
																<Link className='w-fit' href={nicheLink.href} title={nicheLink.title}>
																	<TextButton label={nicheLink.content || nicheLink.title} iconRight='angle-right' />
																</Link>
															</div>
														)}
													</>
												)}
											</AnimatePresence>
										);
									}}
								</Tab.Panel>
							))}
						</>
					)}
				</TabbedOverview>
			</LayoutGrid>
		</section>
	);
};

import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { ReplacingWords, Text } from '@monorepo/components/TextComponents';
import { slugify } from '@monorepo/shared/lib/utils';
import { INicheCtaSection } from '@monorepo/types';

import { CtaSearchFormSection } from '@marketing/components';
import { SingleNicheCta } from './SingleNicheCta/SingleNicheCta';

export type NicheCtaSectionProps = INicheCtaSection & {
	showSearchCta?: boolean;
	className?: string;
};

export const NicheCtaSection: FC<NicheCtaSectionProps> = ({
	title,
	niches,
	items,
	showSearchCta = false,
	className,
}) => {
	return (
		<section className={cn(className, 'md:pb-104 pb-48 max-md:relative')}>
			<LayoutGrid layout='marketing'>
				<div className='after:bg-niche-cta col-start-2 flex flex-col after:absolute after:left-0 after:z-10 after:h-[100px] after:w-[160px] after:-translate-x-1/2 after:-translate-y-1/2 after:bg-cover after:bg-right-bottom after:bg-no-repeat md:relative md:after:h-[171px] md:after:w-[306px] md:after:object-contain'>
					<div className='md:pt-104 before:bg-primaryMain md:before:rounded-12 pt-64 before:absolute before:left-0 before:top-0 before:-z-10 before:block before:h-[300px] before:w-full after:left-0 md:px-48 md:before:h-[500px]'>
						<div className='flex flex-col items-center text-center'>
							<Text variant='display-2' as='h2' color='grayscale0' weight='semibold'>
								{title}
							</Text>
							<Text variant='display-2' as='h2' color='secondaryMain' weight='semibold'>
								<ReplacingWords words={niches} />
							</Text>
						</div>
					</div>
				</div>
			</LayoutGrid>

			<LayoutGrid layout='marketing'>
				<div className='col-start-1 flex-col max-md:col-span-full max-md:overflow-hidden md:col-start-2 md:flex'>
					<div className='mt-32 flex w-screen snap-x snap-mandatory gap-x-24 overflow-x-auto px-48 pb-4 max-md:left-0 max-md:pl-24 md:mt-48 md:grid md:w-full md:grid-cols-4 md:gap-x-32 md:overflow-visible md:pb-0'>
						{items.map((item) => (
							<SingleNicheCta
								{...item}
								key={`niche-cta-section-item-${slugify(item.link.value)}`}
								className='shrink-0 snap-center max-md:w-[275px]'
							/>
						))}
					</div>

					{showSearchCta && (
						<div className='flex w-full justify-center max-md:px-24'>
							<CtaSearchFormSection className='mt-48' id={'find-company-section-search'} />
						</div>
					)}
				</div>
			</LayoutGrid>
		</section>
	);
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';
import { PostalCodeLookupRequest, PostalCodeLookupResponse } from '@monorepo/types';

export const postalCodeApi = createApi({
	reducerPath: 'postalCodeApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/lookup`,
		mode: 'cors',
		prepareHeaders: (headers) => {
			const apiKey = `${process.env.NEXT_PUBLIC_POSTCODE_API_KEY}`;
			headers.set('X-Api-Key', `${apiKey}`);
			return headers;
		},
	}),
	tagTypes: ['PostalCode'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		lookup: builder.query<PostalCodeLookupResponse, PostalCodeLookupRequest>({
			query({ postal_code, house_number }) {
				return {
					url: `/${postal_code}/${house_number}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useLookupQuery, useLazyLookupQuery } = postalCodeApi;

export const { lookup } = postalCodeApi.endpoints;

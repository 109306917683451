import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IObjectDetailsTransformed, ObjectKind } from '@monorepo/types';

import { AppState } from '@common/store';
import { consumerCartApi } from '@consumer/store';
import { determineObjectType } from '@consumer/utils/objects/objects';

export type ObjectsState = {
	type: ObjectKind;
	activeObject: IObjectDetailsTransformed;
	isLoading: boolean;
};

const initialState: ObjectsState = {
	type: null,
	activeObject: {} as IObjectDetailsTransformed,
	isLoading: false,
};

export const consumersObjectsSlice = createSlice({
	name: 'objects',
	initialState,
	reducers: {
		setActiveObject(state, action: PayloadAction<IObjectDetailsTransformed>) {
			state.activeObject = action.payload;
		},
		setIsLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
	},
	extraReducers: (builder) => {
		// Adds the first object as active object to the state when the getObjects request is fulfilled and only one object is present
		builder.addMatcher(consumerCartApi.endpoints.getObjects.matchPending, (state) => {
			state.isLoading = true;
		});
		builder.addMatcher(
			consumerCartApi.endpoints.getObjects.matchFulfilled,
			(state, action: PayloadAction<IObjectDetailsTransformed[]>) => {
				state.isLoading = false;
				state.type = determineObjectType(action.payload);

				// Add th last item in the array (the last added item) to the store
				if (action.payload && action.payload.length >= 1) {
					state.activeObject = action.payload[0];
				}
			}
		);
	},
});

export const { setActiveObject, setIsLoading } = consumersObjectsSlice.actions;

export const selectActiveObject = (state: AppState): IObjectDetailsTransformed => state.objects.activeObject;
export const selectObjectsLoading = (state: AppState): boolean => state.objects.isLoading;
export const selectObjectType = (state: AppState): ObjectKind => state.objects.type;

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const petIndoorOutdoorSchema = (t: TranslationType) =>
	z.object({
		environments: z.string().array().min(1, t('validation.required')),
	});

export type PetIndoorOutdoorSchema = z.infer<ReturnType<typeof petIndoorOutdoorSchema>>;

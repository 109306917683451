import cn from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { TrustpilotRating } from '@monorepo/components/Misc/Widgets';
import { Text } from '@monorepo/components/TextComponents';
import { generateSizesString, slugify } from '@monorepo/shared/lib/utils';
import { IDetailHeroLogoSection } from '@monorepo/types';

import { useGetTrustpilotScoreQuery } from '@common/store';

type DetailHeroLogoSectionProps = IDetailHeroLogoSection & {
	className?: string;
};

export const DetailHeroLogoSection: FC<DetailHeroLogoSectionProps> = ({ subtext, logos, className }) => {
	const { data } = useGetTrustpilotScoreQuery();

	return (
		<section className={cn(className, 'bg-grayscale0 shadow-1 relative z-10 py-24')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2'>
					<div className='flex flex-col items-start max-md:gap-24 md:flex-row md:items-center'>
						{data && (
							<TrustpilotRating
								rating={data.score.trustScore}
								totalReviews={data.numberOfReviews.total}
								includeReviewsOn
							/>
						)}

						{logos && (
							<div className='flex flex-row flex-wrap items-center gap-12 md:ml-auto md:gap-24'>
								<Text as='span' variant='body-m' color='grayscale200'>
									{subtext}
								</Text>
								{logos.map(({ path, alt }) => (
									<Image
										sizes={generateSizesString()}
										key={`detail-hero-logo-${slugify(path)}`}
										src={path}
										alt={alt}
										width={112}
										height={48}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};

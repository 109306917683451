import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, IDetailPrice } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { DetailHero } from '@marketing/components';

export const handleSectionHeroProductPrice = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextEle = elements.find((ele) => ele.identifier === 'title-subtext') as CmsElement,
		listsChecklistEle = elements.find((ele) => ele.identifier === 'lists_checklist') as CmsElement,
		linkOptionalSubtextEle = elements.find((ele) => ele.identifier === 'link-optional-subtext') as CmsElement,
		priceEle = elements.find((ele) => ele.identifier === 'price') as CmsElement;

	const title = getProperty('title', titleSubtextEle.custom_fields),
		pretext = getProperty('subtext', titleSubtextEle.custom_fields),
		items: { content: string }[] = listsChecklistEle?.child_elements?.map(({ markdown }) => {
			return {
				content: `${markdown}`,
			};
		});

	const price: IDetailPrice = {
		amount: `${getProperty('price', priceEle.custom_fields)}`,
		pretext: `${getProperty('pretext', priceEle.custom_fields)}`,
		subtext: `${getProperty('subtext', priceEle.custom_fields)}`,
	};

	const [link] = linkOptionalSubtextEle.links;

	return (props) => (
		<DetailHero
			{...props}
			pretext={`${pretext}`}
			title={`${title}`}
			items={items}
			link={link}
			tagline={`${linkOptionalSubtextEle.markdown}`}
			price={price}
		/>
	);
};

import cn from 'classnames';
import type { ButtonHTMLAttributes, FC, MouseEvent, PropsWithChildren } from 'react';

import { useAnalytics } from '../../../../lib/hooks';
import { toSnakeCase } from '../../../../lib/utils';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & PropsWithChildren;

/**
 * Use to compose new Button Components.
 */
export const BaseButton: FC<Props> = ({ className = '', name, onClick, children, ...props }) => {
	const { trackEvent } = useAnalytics();

	// Generic button clickHandler
	const handleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		trackEvent('select_content', { content_type: 'button', ...(name && { item_id: toSnakeCase(name as string) }) });
		onClick?.(e);
	};

	return (
		<button
			className={cn(
				className,
				!className.includes('justify-') && 'justify-center',
				'group flex h-24 cursor-pointer flex-row items-center transition-colors ease-in-out disabled:cursor-not-allowed'
			)}
			role='button'
			onClick={handleClick}
			{...props}>
			{children}
		</button>
	);
};

import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const insuranceFormSchema = (t: TranslationType) =>
	z.object({
		guid: z.string().optional(),
		object_guid: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		product_guid: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		insurer_code: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		policy_identifier: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
	});

export type InsuranceFormSchema = z.infer<ReturnType<typeof insuranceFormSchema>>;

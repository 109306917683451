import { AcceptanceQuestion, AnswerQuestionsRequest } from '@monorepo/types';

import { rootApi } from '../../../../common/store/root/root.api';

export const acceptanceQuestionsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAcceptanceQuestions: builder.query<AcceptanceQuestion[], string>({
			query(cart_guid: string) {
				return {
					url: `/carts/${cart_guid}/acceptance-questions`,
					method: 'GET',
				};
			},
			providesTags: (result) => {
				if (result) {
					return result.map(({ guid }) => ({ type: 'AcceptanceQuestion', id: guid }));
				}

				/* istanbul ignore next */
				return ['AcceptanceQuestion'];
			},
		}),
		generateAcceptanceQuestions: builder.mutation<void, { cart_guid: string }>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/acceptance-questions`,
					method: 'POST',
					body: {},
				};
			},
			invalidatesTags: ['AcceptanceQuestion', 'AcceptanceState'],
		}),
		answerAcceptanceQuestions: builder.mutation<void, AnswerQuestionsRequest>({
			query({ cart_guid, questions }) {
				return {
					url: `/carts/${cart_guid}/acceptance-questions`,
					method: 'PUT',
					body: { questions },
				};
			},
			invalidatesTags: (_result, _error, { cart_guid }) => ['AcceptanceState', { type: 'Cart', id: cart_guid }],
			async onQueryStarted({ cart_guid, questions: patchQuestions }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					acceptanceQuestionsApi.util.updateQueryData('getAcceptanceQuestions', cart_guid, (existingQuestions) => {
						if (!existingQuestions) return [];

						return existingQuestions.map((question) => {
							const patchQuestion = patchQuestions.find((pq) => pq.guid === question.guid);
							if (patchQuestion) {
								return {
									...question,
									is_answered: true,
									answer_readable: patchQuestion.answer === 'J' ? 'Ja' : 'Nee',
									answer_multiple_choice: {
										key: patchQuestion.answer === 'J' ? 'J' : 'N',
										value: patchQuestion.answer === 'J' ? 'Ja' : 'Nee',
									},
								};
							}
							return question;
						});
					})
				);

				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useAnswerAcceptanceQuestionsMutation,
	useGenerateAcceptanceQuestionsMutation,
	useGetAcceptanceQuestionsQuery,
	useLazyGetAcceptanceQuestionsQuery,
} = acceptanceQuestionsApi;

export const { answerAcceptanceQuestions, generateAcceptanceQuestions, getAcceptanceQuestions } =
	acceptanceQuestionsApi.endpoints;

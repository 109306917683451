import classNames from 'classnames';
import { FormEvent, TextareaHTMLAttributes, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { FormBaseInputDefaultProps } from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormTextareaProps<TFormValues extends FieldValues> = {
	hint?: string;
	hideError?: boolean;
	fieldSize?: 'sm' | 'md' | 'lg';
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'aria-invalid'>;

export const FormTextarea = <TFormValues extends Record<string, unknown>>({
	name,
	register,
	rules,
	errors,
	hint,
	hideError,
	rows = 3,
	fieldSize = 'md',
	className = '',
	...props
}: FormTextareaProps<TFormValues>): JSX.Element => {
	const [characterCount, setCharacterCount] = useState<number>(
		typeof props.defaultValue === 'string' ? props.defaultValue.length : 0
	);

	return (
		<FormHelper
			errors={errors}
			hint={hint}
			hideError={hideError}
			characterCount={characterCount}
			maxCharacterCountLength={props.maxLength}>
			{({ isError }) => (
				<textarea
					onChangeCapture={(e: FormEvent) => setCharacterCount((e.target as HTMLTextAreaElement).value.length)}
					className={classNames(
						className,
						// Base
						fieldSize === 'lg' && 'rounded-12 p-20',
						(fieldSize === 'md' || fieldSize === 'sm') && 'rounded-6 px-12 py-8',
						'typography-body-m text-grayscale500 peer transition-colors duration-300 ease-out placeholder:font-light',
						// Disabled
						'disabled:border-grayscale200 disabled:bg-grayscale50 disabled:cursor-not-allowed',
						// Hover
						'hover:border-primaryMain hover:bg-primary50',
						// Focus
						'focus:border-primaryMain focus:bg-primary50 focus:ring-0',
						// Error
						isError && 'border-error300',
						!isError && 'border-grayscale200'
					)}
					id={name}
					aria-invalid={isError ? 'true' : 'false'}
					name={name}
					rows={rows}
					{...props}
					{...(register && register(name, rules))}
				/>
			)}
		</FormHelper>
	);
};

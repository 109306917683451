import { ContactSchema } from '@monorepo/shared/lib/schemas';

import { rootApi } from '../../../../common/store/root/root.api';

export const requestContactApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		requestContactForShoppingCart: builder.mutation<void, ContactSchema & { cart_guid?: string }>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/request-contact`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['RequestContact'],
		}),
	}),
	overrideExisting: true,
});

export const { useRequestContactForShoppingCartMutation } = requestContactApi;

export const { requestContactForShoppingCart } = requestContactApi.endpoints;

import cn from 'classnames';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import { ThemeColors } from '@monorepo/types';

import { Text } from '../Text/Text';

type Props = {
	leadingText?: string;
	price: number;
	afterText: string;
	textColor?: ThemeColors | 'inherit';
	className?: string;
};

const AnimateNumber = dynamic(() => import('../../../Misc').then((mod) => mod.AnimateNumber), {
	ssr: false,
});

export const EmphasizedPriceFormat: FC<Props> = ({
	leadingText,
	price,
	afterText,
	textColor = 'info300',
	className = '',
}) => {
	const [euros = '0', cents = '00'] = price.toFixed(2).split('.');
	const eurosNumber = +euros,
		centsNumber = +cents;

	return (
		<div className={cn(className, 'relative flex w-fit flex-col')}>
			{leadingText && (
				<Text variant='body-xs' color={textColor}>
					{leadingText}
				</Text>
			)}

			<div className='relative flex flex-row flex-wrap'>
				<div className='flex flex-row whitespace-nowrap pr-4'>
					<Text variant='display-4' weight='semibold' color={textColor} className='flex items-center leading-none'>
						<span className='pr-4'>{'€'}</span>
						<AnimateNumber value={eurosNumber} />
						<span>{','}</span>
					</Text>
					<Text variant='body-s' weight='semibold' color={textColor} className='relative leading-none md:top-[2px]'>
						{centsNumber.toString().length === 1 && <AnimateNumber value={0} />}
						<AnimateNumber value={centsNumber} />
					</Text>
				</div>

				<Text as='span' variant='body-xs' color={textColor} className='absolute -right-8 bottom-0 leading-none'>
					{afterText}
				</Text>
			</div>
		</div>
	);
};

import { KvKProfileResponse, ThemeNames } from '@monorepo/types';

const pathMap: Record<string, string> = {
	onderhoudnl: './profile_bouw.json',
	anko: './profile_beauty.json',
	default: './profile_advies-en-consultancy.json',
};

export const getMockProfile = async (salesChannel: ThemeNames): Promise<KvKProfileResponse> => {
	const filePath = pathMap[salesChannel] || pathMap['default'];
	const { default: data } = await import(`${filePath}`);
	return data;
};

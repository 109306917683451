import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { D_DATE_FORMAT, PHONE_REGEX } from '../../utils';

const addressSchema = (t: TranslationType) => {
	return z.object({
		street: z.string().min(1, t('validation.required')).optional(),
		house_number: z.coerce.number().min(1, t('validation.required')),
		house_number_appendix: z.string().optional(),
		postal_code: z.string().min(1, t('validation.required')),
		city: z.string().min(1, t('validation.required')),
		country: z.string().optional(),
	});
};

export const contactConsumerSchema = (t: TranslationType, isPetsFunnel: boolean = false) => {
	const minDate = new Date(new Date().getFullYear() - 150, new Date().getMonth(), new Date().getDay());
	return z
		.object({
			initials: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
			first_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
			last_name: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
			email: z.string({ required_error: t('validation.required') }).email(t('validation.email')),
			phone: z.string({ required_error: t('validation.required') }).regex(PHONE_REGEX, t('validation.phone')),
			date_of_birth: z.string({ required_error: t('validation.required') }).superRefine((value, ctx) => {
				if (!value) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t('validation.required'),
					});
				}

				const parts = value.replaceAll(' ', '').split('-');

				if (parts.join('').length !== 8) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t('validation.formatSuggestion', { format: D_DATE_FORMAT.toLowerCase() }),
					});
				}

				const rearrangedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
				const parsedDate = new Date(rearrangedDate);

				const todayDate = new Date();
				todayDate.setHours(0, 0, 0, 0);

				if (isNaN(parsedDate.getTime())) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t('validation.formatSuggestion', { format: D_DATE_FORMAT.toLowerCase() }),
					});
				}

				if (parsedDate >= todayDate) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t('validation.dateValidBirthday'),
					});
				}

				if (parsedDate <= minDate) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t('validation.dateValidBirthday'),
					});
				}
			}),
			street: z.string().min(1, t('validation.required')).optional(),
			house_number: z.coerce.number().min(1, t('validation.required')).optional(),
			house_number_appendix: z.string().optional().optional(),
			postal_code: z.string().min(1, t('validation.required')).optional(),
			city: z.string().min(1, t('validation.required')).optional(),
			country: z.string().optional().optional(),
		})
		.superRefine((data) => {
			if (isPetsFunnel) {
				return addressSchema(t).parse(data);
			}
		});
};

export type ContactConsumerSchema = z.infer<ReturnType<typeof contactConsumerSchema>>;

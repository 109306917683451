import { z } from 'zod';

import { TranslationType } from '@monorepo/types'; // Adjust the import path as needed

export const searchCompanySchema = (t: TranslationType) =>
	z.object({
		query: z.string({ required_error: t('validation.min') }).min(2, t('validation.min')),
	});

export type SearchCompanySchema = z.infer<ReturnType<typeof searchCompanySchema>>;

export const searchCompanySchemaSplash = () =>
	z.object({
		query: z.string().optional(),
	});

export type SearchCompanySchemaSplash = z.infer<ReturnType<typeof searchCompanySchemaSplash>>;

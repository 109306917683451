import cn from 'classnames';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import 'odometer/themes/odometer-theme-minimal.css';

type Props = {
	value: number;
	duration?: number;
	className?: string;
};

const Odometer = dynamic(() => import('react-odometerjs'), {
	ssr: false,
	loading: () => <>0.00</>,
});

export const AnimateNumber: FC<Props> = ({ value, duration = 300, className = '' }) => {
	return (
		<Odometer
			value={value}
			format='(ddd),dd'
			theme='minimal'
			duration={duration}
			className={cn(className, 'leading-none')}
		/>
	);
};

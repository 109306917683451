import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { CtaSupportSection } from '@marketing/components';

export const handleSectionContactSupport = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const phoneElement = elements.find((element) => element.identifier === 'support-item-phone') as CmsElement;
	const phoneTitle = getProperty('title', phoneElement?.custom_fields);
	const phoneSubtext = getProperty('subtext', phoneElement?.custom_fields);
	const phoneIcon = getProperty('icon_name', phoneElement?.custom_fields);
	const phoneCta = getProperty('cta_text', phoneElement?.custom_fields);

	const emailElement = elements.find((element) => element.identifier === 'support-item-email') as CmsElement;
	const emailTitle = getProperty('title', emailElement?.custom_fields);
	const emailSubtext = getProperty('subtext', emailElement?.custom_fields);
	const emailIcon = getProperty('icon_name', emailElement?.custom_fields);
	const emailCta = getProperty('cta_text', emailElement?.custom_fields);

	const chatElement = elements.find((element) => element.identifier === 'support-item-chat') as CmsElement;
	const chatTitle = getProperty('title', chatElement?.custom_fields);
	const chatSubtext = getProperty('subtext', chatElement?.custom_fields);
	const chatIcon = getProperty('icon_name', chatElement?.custom_fields);
	const chatCta = getProperty('cta_text', chatElement?.custom_fields);

	return () => (
		<CtaSupportSection
			supportItems={[
				{
					type: 'phone',
					title: `${phoneTitle}`,
					description: `${phoneSubtext}`,
					icon: `${phoneIcon}`,
					phoneNumber: `${phoneCta}`,
				},
				{
					type: 'email',
					title: `${emailTitle}`,
					description: `${emailSubtext}`,
					icon: `${emailIcon}`,
					email: `${emailCta}`,
				},
				{
					type: 'chat',
					title: `${chatTitle}`,
					description: `${chatSubtext}`,
					icon: `${chatIcon}`,
					text: `${chatCta}`,
				},
			]}
		/>
	);
};

import { FC } from 'react';

import { Text } from '@monorepo/components/TextComponents';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';

export const handleSectionFooter = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const [element] = elements;
	return (props) => (
		<Text {...props} as='p' className='mt-auto pt-32' variant='body-xs' color='grayscale400'>
			{element.markdown}
		</Text>
	);
};

export const mockWhyChooseMaia = {
	title: 'Verzekeren is simpel met Māia',
	description: 'Geen kleine lettertjes. Snel en helder je bedrijf verzekerd',
	mobileImage: { image: '/images/marketing/why-choose-maia-3.svg', alt: 'alt tag 3' },
	items: [
		{ image: '/images/marketing/why-choose-maia-1.svg', alt: 'alt tag 1', text: 'Vertel wat je doet' },
		{ image: '/images/marketing/why-choose-maia-2.svg', alt: 'alt tag 2', text: 'Kies je voorkeuren' },
		{ image: '/images/marketing/why-choose-maia-3.svg', alt: 'alt tag 3', text: 'Je bent verzekerd' },
	],
};

import { FC, ReactElement, useState } from 'react';

import { Tab } from '@monorepo/components/Headless/Tab/Tab';

import { TabbedOverviewHead } from './TabbedOverviewHead/TabbedOverviewHead';
import {
	TabbedOverviewPanelSkeleton,
	TabbedOverviewPanelSkeletonProps,
} from './TabbedOverviewPanelSkeleton/TabbedOverviewPanelSkeleton';

export const panelTransition = {
	hidden: {},
	show: {
		transition: {
			staggerChildren: 0.15,
		},
	},
};

export const panelContentTransition = {
	hidden: {
		opacity: 0,
		y: 50,
	},
	show: {
		opacity: 1,
		y: 0,
		transition: { ease: [0.04, 0.62, 0.23, 0.98] },
	},
};

type TabbedOverviewProps = {
	menuLinks: { icon: string; description: string }[];
	isLoading?: boolean;
	children: ReactElement | ((slot: { isAnimatedOnce: boolean }) => ReactElement);
	skeletonProps: TabbedOverviewPanelSkeletonProps;
};

export const TabbedOverview: FC<TabbedOverviewProps> = ({ menuLinks, isLoading, skeletonProps, children }) => {
	const [isAnimatedOnce, setIsAnimatedOnce] = useState<boolean>(false);

	return (
		<div className='rounded-24 bg-grayscale0 relative col-start-1 flex w-full flex-col space-y-32 max-md:col-span-full md:col-start-2 md:space-y-48 md:p-48'>
			<Tab as='div' className='max-md:pt-24' onChange={() => setIsAnimatedOnce(true)}>
				<TabbedOverviewHead items={menuLinks} isLoading={isLoading} />

				<Tab.Panels className='mt-48 max-md:px-24'>
					{isLoading ? (
						<TabbedOverviewPanelSkeleton {...skeletonProps} />
					) : typeof children === 'function' ? (
						children({ isAnimatedOnce })
					) : (
						children
					)}
				</Tab.Panels>
			</Tab>
		</div>
	);
};

import * as RadixSwitch from '@radix-ui/react-switch';
import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';

import { Text } from '@monorepo/shared/typography';

import { indicator, root, ToggleVariants } from './variants';

interface ToggleGroupProps
	extends Omit<ComponentProps<typeof RadixSwitch.Root>, 'type' | 'defaultChecked' | 'defaultValue'> {
	label?: string;
	values: [value: string, value: string];
	onValueChange?: (value: this['values'][number]) => void | this['values'][number];
	defaultValue?: this['values'][number];
	colorVariant?: ToggleVariants;
}

export function Toggle(props: ToggleGroupProps) {
	const { label, values, onValueChange, defaultValue, className, colorVariant, ...rest } = props;

	const handleCheckChange = useCallback((value: boolean) => {
		onValueChange?.(values[+value]);
	}, []);

	return (
		<Text
			className={twMerge('flex cursor-pointer items-center gap-x-12', className)}
			as='label'
			variant='body-m'
			color='grayscale500'
			weight='regular'>
			<RadixSwitch.Root
				{...rest}
				defaultChecked={castDefaultValue(values, defaultValue)}
				onCheckedChange={handleCheckChange}
				className={root({ color: colorVariant })}>
				<RadixSwitch.Thumb className={indicator({ color: colorVariant })} />
			</RadixSwitch.Root>
			<span>{label}</span>
		</Text>
	);
}

type ToggleDecorativeProps = {
	checked: boolean;
	disabled?: boolean;
	colorVariant: ToggleVariants;
} & ComponentProps<'div'>;

export function ToggleDecorative(props: ToggleDecorativeProps) {
	const { checked, disabled, colorVariant, ...rest } = props;

	return (
		<div
			{...rest}
			data-disabled={disabled}
			data-state={checked ? 'checked' : 'unchecked'}
			className={root({ color: colorVariant })}>
			<div
				data-disabled={disabled}
				data-state={checked ? 'checked' : 'unchecked'}
				className={indicator({ color: colorVariant })}
			/>
		</div>
	);
}

function castDefaultValue(
	options: ToggleGroupProps['values'],
	defaultValue: ToggleGroupProps['defaultValue']
): boolean {
	return !!defaultValue
		? options.includes(defaultValue)
			? options.indexOf(defaultValue) === 1
				? true
				: false
			: false
		: false;
}

import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { CtaSection } from '@marketing/components';

export const handleSectionCtaImageButton = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const [element] = elements;
	const title = getProperty('title', element.custom_fields),
		subtext = getProperty('subtext', element.custom_fields),
		[link] = element.links;

	return (props) => <CtaSection {...props} title={`${title}`} subtext={`${subtext}`} cta={'link'} link={link} />;
};

import classNames from 'classnames';
import { InputHTMLAttributes, ReactNode } from 'react';
import { FieldValues } from 'react-hook-form';

import { TooltipIcon } from '../../../DataDisplay';
import { Icon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { FormBaseInputDefaultProps } from '../FormBaseInput/FormBaseInput';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormCheckboxProps<TFormValues extends FieldValues> = {
	label?: ReactNode;
	helperText?: string;
	hideError?: boolean;
	grow?: boolean;
	tooltipId?: string;
	endIcon?: string;
	onEndIconClick?: () => void;
	useSuccessColors?: boolean;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<InputHTMLAttributes<HTMLInputElement>, 'aria-invalid' | 'className'>;

export const FormCheckbox = <TFormValues extends Record<string, unknown>>({
	id,
	name,
	label,
	errors,
	helperText,
	hideError,
	register,
	rules,
	grow,
	tooltipId,
	endIcon,
	onEndIconClick,
	useSuccessColors,
	...props
}: FormCheckboxProps<TFormValues>): JSX.Element => {
	return (
		<FormHelper errors={errors} hint={helperText} hideError={hideError}>
			{({ isError }) => {
				return (
					<label htmlFor={id || name} className='flex cursor-pointer flex-row items-center'>
						<Text as='label' className={classNames('relative flex items-center', grow && 'flex-grow')}>
							<div className='flex items-center justify-center'>
								<input
									id={id || name}
									name={name}
									type='checkbox'
									aria-invalid={isError}
									className={classNames(
										// Base
										'rounded-4 text-primary border-grayscale300 peer h-24 w-24 cursor-pointer appearance-none transition-colors',
										// Disabled
										'disabled:border-grayscale200 disabled:bg-grayscale50 disabled:checked:bg-grayscale200 disabled:checked:border-grayscale200 disabled:cursor-not-allowed',
										// Hover
										useSuccessColors
											? 'hover:border-success300 hover:bg-success50 hover:checked:bg-success400 hover:checked:border-success400'
											: 'hover:border-primaryMain hover:bg-primary50 hover:checked:bg-primary600 hover:checked:border-primary600',
										// Focus
										useSuccessColors
											? 'focus:border-success300 focus:bg-success50 focus:checked:bg-success300 focus:checked:border-success300'
											: 'focus:border-primaryMain focus:bg-primary50 focus:checked:bg-primaryMain focus:checked:border-primaryMain',
										'focus:ring-0',
										// Active
										useSuccessColors
											? 'active:border-success400 active:bg-success100'
											: 'active:border-primaryMain active:bg-primary100',
										// Checked
										useSuccessColors
											? 'checked:border-success300 checked:bg-success300'
											: 'checked:border-primaryMain checked:bg-primaryMain',
										'accent-error300'
									)}
									{...props}
									{...(register && register(name, rules))}
								/>

								<div
									className={classNames(
										// Base
										'pointer-events-none absolute scale-0 cursor-pointer opacity-0 transition-all',
										// Disabled
										'peer-disabled:text-grayscale400 peer-disabled:cursor-not-allowed',
										// Checked
										'peer-checked:text-grayscale0 peer-checked:scale-100 peer-checked:opacity-100'
									)}>
									<Icon name='check' color='inherit' className='relative block' />
								</div>
							</div>
							{label && (
								<Text
									as='span'
									variant='body-m'
									weight='regular'
									color='inherit'
									className={classNames(
										// Base
										'ml-12 grow transition-colors',
										// Not disabled
										!props.disabled && 'text-grayscale500 cursor-pointer',
										// Disabled
										props.disabled && 'text-grayscale400 cursor-not-allowed'
									)}>
									{label}
								</Text>
							)}
						</Text>

						{endIcon && (
							<button
								type='button'
								className='text-primaryMain ml-auto cursor-pointer leading-none outline-0 focus:outline-1 disabled:cursor-not-allowed'
								disabled={props.disabled}
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
									onEndIconClick && onEndIconClick();
								}}>
								<Icon
									size='lg'
									name={endIcon}
									color={props.disabled ? 'grayscale300' : 'info300'}
									className='transition-colors'
								/>
							</button>
						)}

						{tooltipId && <TooltipIcon id={tooltipId} className='ml-12' />}
					</label>
				);
			}}
		</FormHelper>
	);
};

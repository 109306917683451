import * as RadixLabel from '@radix-ui/react-label';
import { ComponentProps, ReactNode } from 'react';

type LabelProps = {
	children: ReactNode;
} & ComponentProps<typeof RadixLabel.Root>;

export function Label(props: LabelProps) {
	const { children, ...rest } = props;

	return <RadixLabel.Root {...rest}>{children}</RadixLabel.Root>;
}

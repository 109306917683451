import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { ButtonHTMLAttributes, FC } from 'react';

import { SupportedSocialLogins } from '@monorepo/types';

import { BaseButton } from '../BaseButton/BaseButton';

type SocialMediaButtonProps = Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
	social: SupportedSocialLogins;
};

export const SocialMediaButton: FC<SocialMediaButtonProps> = ({ social, onClick }) => {
	const { t } = useTranslation(['common']);
	return (
		<BaseButton
			title={t('common.loginWith', { social })}
			type='button'
			onClick={onClick}
			className='border-grayscale100 rounded-12 bg-grayscale0 shadow-2 hover:shadow-4 focus:shadow-4 group h-[61px] w-[61px] border transition-shadow duration-300 ease-out'>
			<div className='relative h-[23px] w-[23px] transition-transform duration-300 ease-out group-hover:scale-110 group-focus:scale-110'>
				<Image src={`/images/social-logins/${social}.svg`} alt={`Social media login button of ${social}`} fill />
			</div>
		</BaseButton>
	);
};

import { FC, useState } from 'react';

import { ChoiceCard } from '@monorepo/components/DataDisplay';
import { Form } from '@monorepo/components/DataEntry';
import { AgMembershipSchema } from '@monorepo/shared/lib/schemas';
import { slugify } from '@monorepo/shared/lib/utils';
import { Question, YesNo } from '@monorepo/types';

type AgMembershipFormProps = {
	question: Question;
	onSubmit: ({ membership }: AgMembershipSchema) => void;
};

export const AgMembershipForm: FC<AgMembershipFormProps> = ({ question, onSubmit }) => {
	const [clickedButton, setClickedButton] = useState<string>('');

	const submit = (key: YesNo) => {
		setClickedButton(key);
		onSubmit({ membership: key });
	};

	return (
		<Form.Context<AgMembershipFormProps>>
			{({}) => {
				return (
					<Form id='ag-membership-form'>
						<Form.Group className='space-y-24'>
							{question?.response_options?.map(({ key, value }) => {
								return (
									<ChoiceCard
										title={value}
										iconName={key === 'J' ? 'check' : 'times'}
										iconColor={key === 'J' ? 'success300' : 'error300'}
										isLoading={clickedButton === key}
										disabled={clickedButton !== ''}
										onClick={() => submit(key as YesNo)}
										checked={question?.answer_readable === value}
										explicitSuccess={question?.answer_readable === value}
										key={`ag-membership-${slugify(value)}`}
										text={value}
									/>
								);
							})}
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};

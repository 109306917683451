import { Trans, useTranslation } from 'next-i18next';
import { FC, useState } from 'react';

import { ChoiceCard } from '@monorepo/components/DataDisplay';
import { Form } from '@monorepo/components/DataEntry';
import { PropertOwnershipSchema } from '@monorepo/shared/lib/schemas';
import { slugify, stripHtml } from '@monorepo/shared/lib/utils';
import { PropertyOwnershipOptions, Question } from '@monorepo/types';

type PropertyOwnershipFormProps = {
	question: Question;
	onSubmit: ({ ownership }: PropertOwnershipSchema) => void;
};

export const PropertyOwnershipForm: FC<PropertyOwnershipFormProps> = ({ question, onSubmit }) => {
	const { t } = useTranslation(['common']);
	const [clickedButton, setClickedButton] = useState<string>('');

	const submit = (key: PropertyOwnershipOptions) => {
		setClickedButton(key);
		onSubmit({ ownership: key });
	};

	return (
		<Form.Context<PropertOwnershipSchema>>
			{({}) => {
				return (
					<Form id='property-ownership-form'>
						<Form.Group className='space-y-24'>
							{question?.response_options?.map(({ key, value }) => (
								<ChoiceCard
									title={stripHtml(t(`page.funnel.profile.propertyOwnership.form.responseOptions.${key}`))}
									isLoading={clickedButton === key}
									disabled={clickedButton !== ''}
									onClick={() => submit(key as PropertyOwnershipOptions)}
									checked={question?.answer_readable === value}
									explicitSuccess={question?.answer_readable === value}
									key={`property-ownership-${slugify(value)}`}
									text={
										<Trans
											i18nKey={`page.funnel.profile.propertyOwnership.form.responseOptions.${key}`}
											components={{ underline: <span className='underline' /> }}
										/>
									}
									data-testid={`property-ownership-form-${key}`}
								/>
							))}
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};

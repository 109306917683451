import { FC } from 'react';

import { Text } from '@monorepo/components/TextComponents';
import { CmsContainer, CmsElement, ThemeNames } from '@monorepo/types';

import { handleArticleSiteSectionArticle } from '@cms/componentHandlers/handleArticleSiteSectionArticle/handleArticleSiteSectionArticle';
import { handleArticleSiteSectionCTABlock } from '@cms/componentHandlers/handleArticleSiteSectionCTABlock/handleArticleSiteSectionCTABlock';
import { handleArticleSiteSectionFaq } from '@cms/componentHandlers/handleArticleSiteSectionFaq/handleArticleSiteSectionFaq';
import { handleArticleSiteSectionHero } from '@cms/componentHandlers/handleArticleSiteSectionHero/handleArticleSiteSectionHero';
import { handleArticleSiteSectionTestimonials } from '@cms/componentHandlers/handleArticleSiteSectionTestimonials/handleArticleSiteSectionTestimonials';
import { handleArticleSiteSectionUspHighlight } from '@cms/componentHandlers/handleArticleSiteSectionUspHighlight/handleArticleSiteSectionUspHighlight';
import { handleBundleProducts } from '@cms/componentHandlers/handleBundleProducts/handleBundleProducts';
import { handleCaseBlock } from '@cms/componentHandlers/handleCaseBlock/handleCaseBlock';
import { handleCheckList } from '@cms/componentHandlers/handleCheckList/handleCheckList';
import { handleInfoAccordion } from '@cms/componentHandlers/handleInfoAccordion/handleInfoAccordion';
import { handleInsuredBox } from '@cms/componentHandlers/handleInsuredBox/handleInsuredBox';
import { handleMarkdown } from '@cms/componentHandlers/handleMarkDown/handleMarkDown';
import { handleNumberedList } from '@cms/componentHandlers/handleNumberedList/handleNumberedList';
import { handleStaticNotification } from '@cms/componentHandlers/handleStaticNotification/handleStaticNotification';
import { handleTag } from '@cms/componentHandlers/handleTag/handleTag';
import { handleTagLine } from '@cms/componentHandlers/handleTagline/handleTagLine';
import { handleCtaLicensePlate } from '@cms/componentHandlers/marketing/handleCtaLicensePlate/handleCtaLicensePlate';
import { handleCtaTextLink } from '@cms/componentHandlers/marketing/handleCtaTextLink/handleCtaTextLink';
import { handleDynamicOverview } from '@cms/componentHandlers/marketing/handleDynamicOverview/handleDynamicOverview';
import { handleDynamicTabbedOverview } from '@cms/componentHandlers/marketing/handleDynamicTabbedOverview/handleDynamicTabbedOverview';
import { handleKnowledgeBaseSection } from '@cms/componentHandlers/marketing/handleKnowledgeBaseSection/handleKnowledgeBaseSection';
import { handleMaiaInformation } from '@cms/componentHandlers/marketing/handleMaiaInformation/handleMaiaInformation';
import { handleMaiaSupport } from '@cms/componentHandlers/marketing/handleMaiaSupport/handleMaiaSupport';
import { handlePortalTopicsSection } from '@cms/componentHandlers/marketing/handlePortalTopicsSection/handlePortalTopicsSection';
import { handleProductDetails } from '@cms/componentHandlers/marketing/handleProductDetails/handleProductDetails';
import { handleProductIntro } from '@cms/componentHandlers/marketing/handleProductIntro/handleProductIntro';
import { handleSectionAddons } from '@cms/componentHandlers/marketing/handleSectionAddons/handleSectionAddons';
import { handleSectionCategorizedProducts } from '@cms/componentHandlers/marketing/handleSectionCategorizedProducts/handleSectionCategorizedProducts';
import { handleSectionChooseMaia } from '@cms/componentHandlers/marketing/handleSectionChooseMaia/handleSectionChooseMaia';
import { handleSectionClaimReporting } from '@cms/componentHandlers/marketing/handleSectionClaimReporting/handleSectionClaimReporting';
import { handleSectionColumn } from '@cms/componentHandlers/marketing/handleSectionColumn/handleSectionColumn';
import { handleSectionColumnDynamic } from '@cms/componentHandlers/marketing/handleSectionColumnDynamic/handleSectionColumnDynamic';
import { handleSectionComparisonTable as handleSectionComparisonTableAllRisk } from '@cms/componentHandlers/marketing/handleSectionComparisonTable/handleSectionComparisonTableAllrisk';
import { handleSectionComparisonTable as handleSectionComparisonTableWa } from '@cms/componentHandlers/marketing/handleSectionComparisonTable/handleSectionComparisonTableWa';
import { handleSectionContactSupport } from '@cms/componentHandlers/marketing/handleSectionContactSupport/handleSectionContactSupport';
import { handleSectionContentPage } from '@cms/componentHandlers/marketing/handleSectionContentPage/handleSectionContentPage';
import { handleSectionContentPageTitle } from '@cms/componentHandlers/marketing/handleSectionContentPageTitle/handleSectionContentPageTitle';
import { handleSectionCtaCards } from '@cms/componentHandlers/marketing/handleSectionCtaCards/handleSectionCtaCards';
import { handleSectionCtaImage } from '@cms/componentHandlers/marketing/handleSectionCtaImage/handleSectionCtaImage';
import { handleSectionCtaImageButton } from '@cms/componentHandlers/marketing/handleSectionCtaImageButton/handleSectionCtaImageButton';
import { handleSectionCtaImageCentered } from '@cms/componentHandlers/marketing/handleSectionCtaImageCentered/handleSectionCtaImageCentered';
import { handleSectionCtaImageListButton } from '@cms/componentHandlers/marketing/handleSectionCtaImageListButton/handleSectionCtaImageListButton';
import { handleSectionFaq } from '@cms/componentHandlers/marketing/handleSectionFaq/handleSectionFaq';
import { handleSectionHeroImage } from '@cms/componentHandlers/marketing/handleSectionHeroImage/handleSectionHeroImage';
import { handleSectionHeroImageScroll } from '@cms/componentHandlers/marketing/handleSectionHeroImageScroll/handleSectionHeroImageScroll';
import { handleSectionHeroProductPrice } from '@cms/componentHandlers/marketing/handleSectionHeroProductPrice/handleSectionHeroProductPrice';
import { handleSectionImageScrollTitle } from '@cms/componentHandlers/marketing/handleSectionImageScrollTitle/handleSectionImageScrollTitle';
import { handleSectionImagesNavigationText } from '@cms/componentHandlers/marketing/handleSectionImagesNavigationText/handleSectionImagesNavigationText';
import { handleSectionInsuranceCoverages } from '@cms/componentHandlers/marketing/handleSectionInsuranceCoverages/handleSectionInsuranceCoverages';
import { handleSectionInsured } from '@cms/componentHandlers/marketing/handleSectionInsured/handleSectionInsured';
import { handleSectionLogoList } from '@cms/componentHandlers/marketing/handleSectionLogoList/handleSectionLogoList';
import { handleSectionLogoListLarge } from '@cms/componentHandlers/marketing/handleSectionLogoListLarge/handleSectionLogoListLarge';
import { handleSectionMaiaUsp } from '@cms/componentHandlers/marketing/handleSectionMaiaUsp/handleSectionMaiaUsp';
import { handleSectionSwag } from '@cms/componentHandlers/marketing/handleSectionSwag/handleSectionSwag';
import { handleSectionTitleImage } from '@cms/componentHandlers/marketing/handleSectionTitleImage/handleSectionTitleImage';
import { handleSectionTitleSubtext } from '@cms/componentHandlers/marketing/handleSectionTitleSubtext/handleSectionTitleSubtext';
import { handleSectionTrustPilot } from '@cms/componentHandlers/marketing/handleSectionTrustPilot/handleSectionTrustPilot';
import { handleSectionTrustpilotLogoList } from '@cms/componentHandlers/marketing/handleSectionTrustpilotLogoList/handleSectionTrustpilotLogoList';
import { handleSectionTrustPilotSmall } from '@cms/componentHandlers/marketing/handleSectionTrustPilotSmall/handleSectionTrustPilotSmall';
import { handleSectionUspCards } from '@cms/componentHandlers/marketing/handleSectionUspCards/handleSectionUspCards';
import { handleSectionVideo } from '@cms/componentHandlers/marketing/handleSectionVideo/handleSectionVideo';
import { handleSectionWhyMaia } from '@cms/componentHandlers/marketing/handleSectionWhyMaia/handleSectionWhyMaia';
import { handleSectionWorkfieldActivities } from '@cms/componentHandlers/marketing/handleSectionWorkfieldActivities/handleSectionWorkfieldActivities';
import { handleSectionWorkfields } from '@cms/componentHandlers/marketing/handleSectionWorkfields/handleSectionWorkfields';
import { handleSectionWorkfieldSpecial } from '@cms/componentHandlers/marketing/handleSectionWorkfieldSpecial/handleSectionWorkfieldSpecial';
import { handleStartChatSection } from '@cms/componentHandlers/marketing/handleStartChatSection/handleStartChatSection';
import { handleTextImage } from '@cms/componentHandlers/marketing/handleTextImage/handleTextImage';
import { handleTextImageChecklist } from '@cms/componentHandlers/marketing/handleTextImageChecklist/handleTextImageChecklist';
import { handleSectionChecklist } from '@cms/componentHandlers/splash/handleSectionChecklist/handleSectionChecklist';
import { handleSectionFooter } from '@cms/componentHandlers/splash/handleSectionFooter/handleSectionFooter';
import { handleSectionSearchbox } from '@cms/componentHandlers/splash/handleSectionSearchbox/handleSectionSearchbox';
import { handleSectionSplashHeader } from '@cms/componentHandlers/splash/handleSectionSplashHeader/handleSectionSplashHeader';

export type RootContext = {
	author?: string;
	publishDate?: string;
	theme?: ThemeNames;
	path?: string;
};

export type HandlerProps = { component: CmsElement; rootContext?: RootContext };
export type HandlerPropsV2 = { elements: CmsElement[]; identifier: string; rootContext?: RootContext };

export type ContentHandler<P> = (_: HandlerProps) => FC<P>;
export type ContentHandlerV2<P> = (_: HandlerPropsV2) => FC<P>;

export const componentMapper = (response: CmsContainer) => {
	return response.elements.map(handleContentItem);
};

/**
 * @deprecated
 */
export const mapContentToComponent = <P extends object>(component: CmsElement, rootContext?: RootContext): FC<P> => {
	if (!component || !component?.identifier) {
		return () => <></>;
	}

	const handler: ContentHandler<P> = handlers[component.identifier] as ContentHandler<P>;

	try {
		return handler ? handler({ component, rootContext }) : handleDefault(component);
	} catch (error) {
		console.error(error);
		return () => null;
	}
};

export const mapContentToComponentV2 = <P extends object>(
	container: CmsContainer,
	rootContext?: RootContext
): FC<P> => {
	if (!container || !container?.identifier) {
		return () => <></>;
	}

	const handler: ContentHandlerV2<P> = handlers[container.identifier] as ContentHandlerV2<P>;

	try {
		return handler
			? handler({ elements: container.elements, rootContext, identifier: container.identifier })
			: handleDefaultV2();
	} catch (error) {
		console.error(error);
		return () => null;
	}
};

// TODO: Should be more reusable
export const mapContentToPreviewComponent = <P extends object>({
	element,
	container,
	rootContext,
}: {
	element?: CmsElement;
	container?: CmsContainer;
	rootContext?: RootContext;
}): FC<P> => {
	if (element) {
		const handler: ContentHandler<P> = handlers[element.identifier] as ContentHandler<P>;
		return handler ? handler({ component: element, rootContext }) : handleDefault(element);
	}

	if (container) {
		const handler: ContentHandlerV2<P> = handlers[container.identifier] as ContentHandlerV2<P>;
		return handler
			? handler({ elements: container.elements, rootContext, identifier: container.identifier })
			: handleDefaultV2();
	}

	return () => null;
};

const handleContentItem = <P extends object>(component: CmsElement): FC<P> => {
	if (!component.identifier) {
		return () => <></>;
	}

	const handler: ContentHandler<P> = handlers[component.identifier] as ContentHandler<P>;

	return handler ? handler({ component }) : handleDefault(component);
};

/**
 * Returns a default Text component
 * @deprecated
 */
const handleDefault = <P extends object>(component: CmsElement): FC<P> => {
	if (!component.markdown) {
		return () => <></>;
	}

	return () => <Text key={`dynamic-markdown-${component.identifier}`}>{component.markdown?.toString()}</Text>;
};

// TODO: default handler
const handleDefaultV2 = <P extends object>(): FC<P> => {
	return () => <></>;
};

export const isIdentifierMapped = (identifier: string) => identifier in handlers;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handlers: Record<string, ContentHandler<any> | ContentHandlerV2<any>> = {
	// Marketing landing
	'section-hero-image': handleSectionHeroImage,
	columns_dynamic: handleSectionColumnDynamic,
	'columns_1-3+2-3': handleSectionColumn,
	// Shared
	'section-trustpilot': handleSectionTrustPilot,
	'section-faq': handleSectionFaq,
	'section-maia-steps': handleSectionChooseMaia,
	'section-title-subtext': handleSectionTitleSubtext,
	'section-cta-text-link': handleCtaTextLink,
	// Landing page
	'section-cta-image': handleSectionCtaImage,
	'section-products-by-workfield': handleDynamicTabbedOverview,
	'section-workfields': handleSectionWorkfields,
	'section-logo-list': handleSectionLogoList,
	'section-swag': handleSectionSwag,
	'section-cta-image-centered': handleSectionCtaImageCentered,
	'section-comparison-table-allrisk': handleSectionComparisonTableAllRisk,
	'section-comparison-table-wa': handleSectionComparisonTableWa,
	// 🪦 Andre
	'section-products-landing': handleDynamicOverview,
	'section-why-maia': handleSectionWhyMaia,
	'section-cta-cards': handleSectionCtaCards,
	// Insurance Detail Page
	'section-cta-price-list-button': handleSectionHeroProductPrice,
	'section-trustpilot-logolist': handleSectionTrustpilotLogoList,
	'section-insurance-coverages': handleSectionInsuranceCoverages,
	'section-cta-image-list-button': handleSectionCtaImageListButton,
	'section-trustpilot-small': handleSectionTrustPilotSmall,
	'cta-5-min': handleCtaTextLink,
	// Insurance page
	'section-products-by-workfield-price': handleDynamicTabbedOverview,
	'section-categories': handleSectionWorkfields,
	// Niche detail page
	'section-image-scroll-title-list': handleSectionHeroImageScroll,
	'section-image-scroll-hero': handleSectionHeroImageScroll,
	'section-workfield-special': handleSectionWorkfieldSpecial,
	'section-bundles': handleDynamicOverview,
	'section-compositions': handleDynamicOverview,
	'section-static-products': handleDynamicOverview,
	'section-products': handleDynamicOverview,
	// Niche page
	'section-maia-usp': handleSectionMaiaUsp,
	'section-image-scroll-title': handleSectionImageScrollTitle,
	'section-categorized-products': handleSectionCategorizedProducts,
	'section-categorized-products-1': handleDynamicTabbedOverview,
	'section-categorized-products-2': handleDynamicTabbedOverview,
	'section-workfield-activities': handleSectionWorkfieldActivities,
	'section-addons': handleSectionAddons,
	'section-insured': handleSectionInsured,
	// About Maia page
	'section-title-image': handleSectionTitleImage,
	'section-images-navigation-text': handleSectionImagesNavigationText,
	'section-usp-cards': handleSectionUspCards,
	'section-logo-list-large': handleSectionLogoListLarge,
	'cta-text-only': handleCtaTextLink,
	'cta-licenseplate': handleCtaLicensePlate,
	'cta-image-button': handleSectionCtaImageButton,
	'section-hero_image-scroll': handleSectionHeroImageScroll,
	'section-text-image': handleTextImage,
	'section-text-image-checklist': handleTextImageChecklist,
	'section-content-page-title': handleSectionContentPageTitle,
	'section-markdown': handleSectionContentPage,
	// Claims page
	'section-contact-support': handleSectionContactSupport,
	'section-claim-reporting': handleSectionClaimReporting,
	'section-video-title': handleSectionVideo,
	// Contact page
	'section-maia-support': handleMaiaSupport,
	'section-maia-information': handleMaiaInformation,
	// Customer support page
	'section-start-chat': handleStartChatSection,
	'section-portal-topics': handlePortalTopicsSection,
	'section-knowledgebase': handleKnowledgeBaseSection,
	// FAQ page
	'section-choose-maia': handleSectionChooseMaia,
	// Article handlers
	'site-hero': handleArticleSiteSectionHero,
	tags_tag: handleTag,
	'site-faq': handleArticleSiteSectionFaq,
	'site-cta-block': handleArticleSiteSectionCTABlock,
	'site-testimonials': handleArticleSiteSectionTestimonials,
	'site-usp-highlight': handleArticleSiteSectionUspHighlight,
	'site-content-thumbnail': handleArticleSiteSectionArticle,
	article_header: handleArticleSiteSectionHero,
	'lists_insured-box': handleInsuredBox,
	content: handleMarkdown,
	'data_display-accordeon': handleInfoAccordion,
	notifications_static: handleStaticNotification,
	'lists_numbered-list': handleNumberedList,
	lists_checklist: handleCheckList,
	'blocks_case-block': handleCaseBlock,
	bundle_products: handleBundleProducts,
	product_tagline: handleTagLine,
	// TODO: These are added for backend to test product cards and product modals
	product_intro: handleProductIntro,
	product_details: handleProductDetails,
	// Components for /aanvragen page
	'section-splash_header': handleSectionSplashHeader,
	'section-searchbox': handleSectionSearchbox,
	'section-checklist': handleSectionChecklist,
	footer: handleSectionFooter,
};

import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { CtaSection } from '@marketing/components';

export const handleCtaLicensePlate = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubTextElement = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;

	const title = getProperty('title', titleSubTextElement.custom_fields);
	const subtext = getProperty('subtext', titleSubTextElement.custom_fields);

	return (props) => <CtaSection {...props} cta='cta-licenseplate' title={title} subtext={subtext} />;
};

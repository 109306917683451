import { FC } from 'react';

import { SectionTestimonial } from '@monorepo/components/Misc';
import { getProperty } from '@monorepo/shared/lib/cms';
import { ThemeNames } from '@monorepo/types';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

export const handleArticleSiteSectionTestimonials = <P extends object>({
	component,
	rootContext,
}: HandlerProps): FC<P> => {
	const title = getProperty('title', component.custom_fields) as string;

	return () => (
		<SectionTestimonial
			title={title}
			variant='rating'
			theme={rootContext?.theme as ThemeNames}
			testimonials={component.child_elements.map((child_element) => {
				const name = getProperty('author-fullname', child_element.custom_fields) as string;
				const rating = getProperty('rating', child_element.custom_fields) as string;
				const company = getProperty('company', child_element.custom_fields);

				return {
					image: `${child_element?.media![0].path}`,
					alt: child_element?.media![0].alt,
					name,
					rating,
					testimonial: `${child_element.markdown}`,
					company,
				};
			})}
		/>
	);
};

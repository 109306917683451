import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { ChoiceCard } from '@monorepo/components/DataDisplay';
import { Form } from '@monorepo/components/DataEntry';
import { EmployeesSchema } from '@monorepo/shared/lib/schemas';

type EmployeeFormProps = {
	onSubmit: ({ employees }: EmployeesSchema) => void;
};

export const EmployeesForm: FC<EmployeeFormProps> = ({ onSubmit }) => {
	const { t } = useTranslation(['common']);

	return (
		<Form.Context<EmployeesSchema>>
			{({ handleSubmit, getValues, register, reset }) => {
				return (
					<Form id='employees-form' onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className='space-y-24'>
							<ChoiceCard
								title={t('page.funnel.profile.employees.inputLabelZzp')}
								inputPosition='left'
								onClick={() => reset({ employeeType: 'single', employees: 1 })}
								inputElement={
									<Form.Radio id='employee-single' register={register} name='employeeType' value='single' />
								}
								checked={getValues('employeeType') === 'single'}
								text={t('page.funnel.profile.employees.inputLabelZzp')}
								data-testid='employees-form-single-option'
							/>

							<ChoiceCard
								title={t('page.funnel.profile.employees.inputLabelMultiple')}
								inputPosition='left'
								onClick={() => reset({ employeeType: 'multiple', employees: undefined })}
								inputElement={
									<Form.Radio id='employee-multiple' register={register} name='employeeType' value='multiple' />
								}
								checked={getValues('employeeType') === 'multiple'}
								text={t('page.funnel.profile.employees.inputLabelMultiple')}
								data-testid='employees-form-multiple-option'>
								<div className='space-y-8'>
									<Form.NumberInput
										fieldSize='lg'
										startIcon='users-alt'
										register={register}
										name='employees'
										step={1}
										autoComplete='off'
										autoFocus={true}
										placeholder={t('page.funnel.profile.employees.inputPlaceholder')}
										data-testid='employees-form-multiple-input'
									/>
								</div>
							</ChoiceCard>
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};

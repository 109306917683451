import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { HeroWithImage } from '@marketing/components';

export const handleSectionTitleImage = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const [element] = elements,
		title = getProperty('title', element.custom_fields),
		[image] = element.media;

	return (props) => <HeroWithImage {...props} title={`${title}`} image={image} />;
};

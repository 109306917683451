import classNames from 'classnames';
import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { TestimonialMultiSliderSection } from '@marketing/components';

export const handleSectionTrustPilot = <P extends object>({ elements, rootContext }: HandlerPropsV2): FC<P> => {
	const trustpilotElement = elements.find(({ identifier }) => identifier === 'trustpilot') as CmsElement;
	const title = getProperty('title', trustpilotElement.custom_fields);

	return () => (
		<TestimonialMultiSliderSection
			title={title}
			className={classNames(
				'md:py-104 py-48',
				(rootContext?.path?.includes('werkvelden') || rootContext?.path?.includes('niches')) && 'bg-primary50'
			)}
		/>
	);
};

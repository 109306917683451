import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import { Icon } from '../../../Misc';

export type DataDisplayIconCardVariant = 'color' | 'grayscale';

type DataDisplayIconCardProps = {
	variant?: DataDisplayIconCardVariant;
	icon: string;
};

export const DataDisplayIconCard: FC<PropsWithChildren<DataDisplayIconCardProps>> = ({
	variant = 'color',
	icon,
	children,
}) => {
	return (
		<div className='rounded-6 flex flex-row overflow-hidden'>
			<div
				className={classNames(
					'flex min-w-[44px] items-center justify-center p-12 md:min-w-[56px] md:py-16',
					variant === 'color' ? 'bg-primary100' : 'bg-grayscale100'
				)}>
				<Icon size='md' name={icon} color={variant === 'color' ? 'primaryMain' : 'grayscale600'} />
			</div>
			<div
				className={classNames(
					'flex w-full items-center p-12 md:py-16',
					variant === 'color' ? 'bg-primary50' : 'bg-grayscale50'
				)}>
				{children}
			</div>
		</div>
	);
};

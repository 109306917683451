import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, hydrate } from '@common/store';

export type QuotesState = {
	quotes: {
		[key: string]: {
			state: boolean;
		};
	};
};

// Initial state
const initialState: QuotesState = {
	quotes: {},
};

export const quotesSlice = createSlice({
	name: 'quotes',
	initialState,
	reducers: {
		openQuote(state, action: PayloadAction<string>) {
			state.quotes[action.payload] = { state: true };
		},
		closeQuote(state, action: PayloadAction<string>) {
			state.quotes[action.payload] = { state: false };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(hydrate, (state, action: PayloadAction<{ quotes: QuotesState }>) => ({
			...state,
			...action.payload.quotes,
		}));
	},
});

export const { closeQuote, openQuote } = quotesSlice.actions;

export const selectQuoteState = (quoteGuid: string) =>
	createSelector(
		(state: AppState) => state.quotes,
		({ quotes }) => quotes[quoteGuid]?.state
	);
